import { IProject } from "../../shared/models/project";
import { IProjectAction, ProjectTypes } from "../actions/project.action";

export interface IProjectState {
	loading: boolean
	projects?: IProject[]
	error?: any
}

const initState: IProjectState = {
	loading: false,
};

const projectState = (state = initState, action: IProjectAction): IProjectState => {
	switch (action.type) {
		case ProjectTypes.GET_ALL_PROJECT:
			return {
				...state,
				... {
					loading: true,
				}
			}
		case ProjectTypes.GET_ALL_PROJECT_SUCCESS:
			return {
				...state,
				...{
					loading: false,
					projects: action.projects,
				}
			}
		case ProjectTypes.GET_ALL_PROJECT_FAILED:
			return {
				...state,
				...{
					loading: false,
					error: action.error,
				}
			}
		default:
			return state;
	}
};

export default projectState;
