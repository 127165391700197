import { INotification } from "../../shared/models/notification";

export enum NotificationTypes {
    GET_DATA_NOTIFICATION = "GET_DATA_NOTIFICATION",
    GET_DATA_NOTIFICATION_SUCCESS = "GET_DATA_NOTIFICATION_SUCCESS",
    GET_DATA_NOTIFICATION_FAILED = "GET_DATA_NOTIFICATION_FAILED",
}

export interface INotificationAction {
    type: NotificationTypes;
    notifications?: INotification[];
    error?: any;
    userID?: string;
}

export const getDataNotificationAction = (
    userID: string
): INotificationAction => {
    return {
        type: NotificationTypes.GET_DATA_NOTIFICATION,
        userID,
    };
};

export const getDataNotificationSuccess = (
    notifications: INotification[]
): INotificationAction => {
    return {
        type: NotificationTypes.GET_DATA_NOTIFICATION_SUCCESS,
        notifications,
    };
};

export const getDataNotificationFailed = (error: any): INotificationAction => {
    return {
        type: NotificationTypes.GET_DATA_NOTIFICATION_FAILED,
        error,
    };
};
