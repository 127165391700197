import { FC } from "react";

const ErrorWidget:FC<{ error: any }> = ({ error }) => {
    return (
        <div style={{ color: "red", fontSize: 20 }}>
            ERROR: {error}
        </div>
    );
}

export default ErrorWidget;