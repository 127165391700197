const initState = '';
const SearchTasksInput = (state = initState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_TASKS_INPUT':
            return action.data;
        default:
            return state;
    };
};

export default SearchTasksInput;