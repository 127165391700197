const resultReducer = (state = { webs: [], flapps: [], rnapps: [] }, action) => {
    switch (action.type) {
        case 'UPDATE_WEB_RESULT':
            return { ...state, webs: action.payload };
        case 'UPDATE_WEB_AGGREGATE_RESULT':
            return {
                ...state, webs: state.webs.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, aggregateResult: action.payload };
                    }
                    return cat;
                })
            };
        case 'UPDATE_WEB_AGGREGATE_ITEM_IN_RESULT':
            return {
                ...state, webs: state.webs.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, aggregateResult: { ...cat.aggregateResult, [action.key]: action.value } };
                    }
                    return cat;
                })
            };
        case 'UPDATE_WEB_DAY_BY_DAY_RESULT':
            return {
                ...state, webs: state.webs.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, dayByDayResult: action.payload };
                    }
                    return cat;
                })
            };
        case 'UPDATE_WEB_REVENUE':
            return {
                ...state, webs: state.webs.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, [action.revenueType]: action.revenue};
                    }
                    return cat;
                })
            };
        case 'UPDATE_FL_APPS_RESULT':
            return { ...state, flapps: action.payload };
        case 'UPDATE_FL_APPS_AGGREGATE_ITEM_IN_RESULT':
            return {
                ...state, flapps: state.flapps.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, aggregateResult: { ...cat.aggregateResult, [action.key]: action.value } };
                    }
                    return cat;
                })
            };
        case 'UPDATE_FL_APP_DAY_BY_DAY_RESULT':
            return {
                ...state, flapps: state.flapps.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, dayByDayResult: action.payload };
                    }
                    return cat;
                })
            };
        case 'UPDATE_FL_APPS_REVENUE':
            return {
                ...state, flapps: state.flapps.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, ...action.revenue};
                    }
                    return cat;
                })
            };
        case 'UPDATE_RN_APPS_RESULT':
            return { ...state, rnapps: action.payload };
        case 'UPDATE_RN_APPS_AGGREGATE_ITEM_IN_RESULT':
            return {
                ...state, rnapps: state.rnapps.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, aggregateResult: { ...cat.aggregateResult, [action.key]: action.value } };
                    }
                    return cat;
                })
            };
        case 'UPDATE_RN_APPS_REVENUE':
            return {
                ...state, rnapps: state.rnapps.map(cat => {
                    if (cat.name === action.catName) {
                        return { ...cat, ...action.revenue};
                    }
                    return cat;
                })
            };
        case 'UPDATE_LEARN_ANSWERS':
            return {
                ...state, flapps: state.flapps.map(cat => {
                    if (cat.name === action.catName) {
                        return {...cat, learnAnswers: action.payload};
                    }
                    return cat;
                })
            };
        case 'UPDATE_TEST_ANSWERS':
            return {
                ...state, flapps: state.flapps.map(cat => {
                    if (cat.name === action.catName) {
                        return {...cat, testAnswers: action.payload};
                    }
                    return cat;
                })
            };
        default:
            return state;
    }
}

export default resultReducer;