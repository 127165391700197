import { IProblemReporting, ProblemReporting } from "../../shared/models/problemReporting"

export enum ProblemReportingTypes {
    ADD_PROBLEM = "ADD_PROBLEM",
    ADD_PROBLEM_SUCCESS = "ADD_PROBLEM_SUCCESS",
    UPDATE_PROBLEM = "UPDATE_PROBLEM",
    UPDATE_PROBLEM_SUCCESS = "UPDATE_PROBLEM_SUCCESS",
    DELETE_PROBLEM = "DELETE_PROBLEM",
    DELETE_PROBLEM_SUCCESS = "DELETE_PROBLEM_SUCCESS",
    GET_ALL_PROBLEM = "GET_ALL_PROBLEM",
    GET_ALL_PROBLEM_SUCCESS = "GET_ALL_PROBLEM_SUCCESS",

}

export interface IProblemReportingAction {
    type: ProblemReportingTypes;
    problem?: ProblemReporting;
    problems?: ProblemReporting[];
}

export const getAllProblemReporting = (): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.GET_ALL_PROBLEM
    }
}

export const getAllProblemReportingSuccess = (
    list: ProblemReporting[]
): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.GET_ALL_PROBLEM_SUCCESS,
        problems: list,
    }
}

export const addProblem = (problem: ProblemReporting): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.ADD_PROBLEM,
        problem,
    }
}

export const addProblemSuccess = (problem: ProblemReporting): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.ADD_PROBLEM_SUCCESS,
        problem,
    }
}

export const updateProblem = (problem: ProblemReporting): IProblemReportingAction => {
    return { 
        type: ProblemReportingTypes.UPDATE_PROBLEM,
        problem,
    }
    
}

export const updateProblemSuccess = (problem: ProblemReporting): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.UPDATE_PROBLEM_SUCCESS,
        problem,
    }
}

export const deleteProblem = (problem: ProblemReporting): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.DELETE_PROBLEM,
        problem,
    }
}

// export const deleteProblemSuccess = (problem: IProblemReporting): IProblemReportingAction => {
//     return {
//         type: ProblemReportingTypes.DELETE_PROBLEM_SUCCESS,
//         problem,
//     }
// }
export const deleteProblemSuccess = (
    problem: ProblemReporting
): IProblemReportingAction => {
    return {
        type: ProblemReportingTypes.DELETE_PROBLEM_SUCCESS,
        problem,
    }
}


