import { ISetting } from "../../shared/models/setting";
import { ISettingAction, SettingTypes } from "../actions/setting.action";

export interface ISettingState {
    loading: boolean;
    data?: ISetting
}

const initState: ISettingState = {
    loading: false,
};

const settingState = (state = initState, action: ISettingAction) => {
    switch (action.type) {
        case SettingTypes.GET_SETTING:
            return {
                loading: true
            };
        case SettingTypes.GET_SETTING_SUCCESS:
            return {
                loading: false,
                data: action.data
            };
        default:
            return state;
    }
};

export default settingState;
