import { FC, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../../constant";
import { getAllCampaign } from "../../../../redux/actions/campaign.action";
import { ICampaignState } from "../../../../redux/reducers/campaign_new.reducer";
import { ICampaign } from "../../../../shared/models/campaign";
import Config from "../../../../shared/models/config";
import { ISprint } from "../../../../shared/models/sprint";
import { ITask } from "../../../../shared/models/task";
import LoadingWidget from "../../../LoadingWidget";
import CampaignProgressReview from "../campaign-progress-review";
import "./styles.scss";
import CreateCampaignModal from "../create-campaign-modal";

type CampaignProps = {
    currentSprint: ISprint;
    currentProjectIds: string[];
    onShowTaskIds: (campaignIds: string[], onReload?: boolean) => void;
    archive?: boolean;
};

const CampaignProgressWidget: FC<CampaignProps> = (props) => {
    const dispatch = useDispatch();
    const campaignState: ICampaignState = useSelector((state: any) => state.campaignState);
    useEffect(() => {
        if (!campaignState.campaigns.length && !campaignState.loading) {
            dispatch(getAllCampaign());
        } // da luu roi thi khong can load lai nua, neu co update thi cung co update truc tiep vao redux luon, chi can load lan dau vao trang
    }, []);

    if (campaignState.loading || !campaignState.campaigns.length) {
        return <LoadingWidget />;
    }
    return <CampaignProgressContent {...props} campaigns={campaignState.campaigns} />;
};

const column = [0, 1, 2];
const COLUMN = 3;
const CampaignProgressContent: FC<CampaignProps & { campaigns: ICampaign[] }> = ({
    currentSprint,
    currentProjectIds,
    onShowTaskIds,
    campaigns,
    archive = false,
}) => {
    const [currentCampaignIds, setCurrentCampaignIds] = useState<string[]>([]);
    const [openEditCampaignDialog, setOpenEditCampaignDialog] = useState<ICampaign | null>(null);
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const calculateCurrentCampaignProgress = (campaign: ICampaign): ISprintCampaignProgress => {
        let countTaskDone = 0;
        let countTaskTodo = 0;
        let totalCurrentTask = 0;
        tasks.forEach((task) => {
            if (
                campaign.listTaskIDs.indexOf(task._id) !== -1 &&
                !(task.status?.shortId === Config.COLUMN_STATUS.DONE.id && task.sprintId !== currentSprint._id)
            ) {
                if (task.status?.shortId === Config.COLUMN_STATUS.DONE.id) {
                    countTaskDone += 1;
                }
                if (task.status?.shortId === Config.COLUMN_STATUS.TO_DO.id) {
                    countTaskTodo += 1;
                }
                totalCurrentTask++;
            }
        });
        let donePercent = 0;
        let inProgressPercent = 0;
        if (totalCurrentTask != 0) {
            donePercent = Math.round((countTaskDone / totalCurrentTask) * 100);
            let todoPercent = Math.round((countTaskTodo / totalCurrentTask) * 100);
            inProgressPercent = 100 - donePercent - todoPercent;
        }
        return {
            doneCurrentSprintPercent: donePercent,
            inProgressCurrentSprintPercent: inProgressPercent,
        };
    };
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let array = params.get(Constants.SHOW_CAMPAIGN_TASKS)?.split(",") ?? [];
        setCurrentCampaignIds(array);
        if (array?.length) onShowTaskIds(array, true);
    }, []);
    const checkProjectIds = (ids1: string[], ids2: string[]) => {
        for (let id of ids1) {
            if (ids2.includes(id)) {
                return true;
            }
        }
        return false;
    };
    let campaignsData = campaigns
        .filter((campaign) => {
            return (
                !(
                    Constants.STATUS_CAMPAIGN_COMPLETE.indexOf(campaign.status) !== -1 &&
                    campaign.sprintId !== currentSprint._id
                ) &&
                currentSprint.endDate &&
                campaign.startDate &&
                currentSprint.endDate > campaign.startDate &&
                (currentProjectIds.length === 0 || checkProjectIds(currentProjectIds, campaign.projectIds)) &&
                campaign.archive == archive
            );
        })
        .sort((a, b) => Constants.prioritySort.indexOf(a.priority) - Constants.prioritySort.indexOf(b.priority));
    return (
        <div className="campaign-gird">
            {column.map((index) => {
                return (
                    <div className="column" key={index}>
                        {campaignsData
                            .filter((campaigns, idx) => idx % COLUMN == index)
                            .map((campaign, i) => {
                                return (
                                    <div
                                        className="campaign-container"
                                        key={"campaign-" + campaign._id}
                                        style={{
                                            marginTop: i > 0 ? "16px" : "0",
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                <CampaignProgressReview
                                                    campaign={campaign}
                                                    openDialog={() => {
                                                        onShowTaskIds([campaign._id]);
                                                        setOpenEditCampaignDialog(campaign);
                                                    }}
                                                    selectedCampaignIds={currentCampaignIds}
                                                    onSelected={() => {
                                                        const index = currentCampaignIds.indexOf(campaign._id);
                                                        const array = [...currentCampaignIds];
                                                        if (index > -1) {
                                                            array.splice(index, 1);
                                                        } else {
                                                            array.push(campaign._id);
                                                        }
                                                        setCurrentCampaignIds(array);
                                                        onShowTaskIds(array);
                                                    }}
                                                    sprintProgress={calculateCurrentCampaignProgress(campaign)}
                                                    userId={campaign?.userId}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                    </div>
                );
            })}
            {openEditCampaignDialog && (
                <CreateCampaignModal
                    campaign={openEditCampaignDialog}
                    onHide={() => setOpenEditCampaignDialog(null)}
                    currentSprintId={currentSprint._id}
                />
            )}
        </div>
    );
};

export interface ISprintCampaignProgress {
    doneCurrentSprintPercent: number;
    inProgressCurrentSprintPercent: number;
}

export default CampaignProgressWidget;
