import './style.scss';
import Categories from './categories';
import { useState, useEffect } from 'react';
import axios from 'axios';
import IsLoading from '../IsLoading';

const Wiki = () => {
    const data = [
        {
            img: 'https://wiki.abc-elearning.org/wp-content/uploads/2021/06/human-resources-150x150.png',
            name: 'Human Resources',
            term_id: '11',
        },
        {
            img: 'https://wiki.abc-elearning.org/wp-content/uploads/2021/06/hacker-150x150.png',
            name: 'Kỹ thuật',
            term_id: '7',
        },
        {
            img: 'https://wiki.abc-elearning.org/wp-content/uploads/2021/06/marketing-150x150.png',
            name: 'Marketting and Content',
            term_id: '2',
        },
        {
            img: 'https://wiki.abc-elearning.org/wp-content/uploads/2021/06/process-150x150.png',
            name: 'Quy trình',
            term_id: '8',
        },
        {
            img: 'https://wiki.abc-elearning.org/wp-content/uploads/2021/06/project-150x150.png',
            name: 'Sản phẩm',
            term_id: '19',
        },
    ];

    const [categoryID, setCategoryID] = useState('11');
    const [dataWiki, setDataWiki] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let childCategory: any[] = [];

    const fetchAllWikiData = async () => {
        setIsLoading(true);
        const resWiki = await axios.post('https://wiki.abc-elearning.org/wp-json/passemall/v1/get-all-categories-and-posts');
        setDataWiki(resWiki.data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchAllWikiData();
    }, []);

    if (dataWiki.length > 0) {
        childCategory = dataWiki.filter((dt: any) => dt.term_id === categoryID);
    }

    return (
        <div className="wiki container">
            {isLoading && <IsLoading />}
            <h2>ABC Wiki</h2>
            <h3>Select to find related documentations</h3>
            <div className="documentations">
                {data.map((category, index) => (
                    <div className="document" onClick={() => setCategoryID(category.term_id)} key={index}>
                        <div className={category.term_id === categoryID ? "background-active" : "background"}>
                            <img src={category.img} />
                        </div>
                        <div className="name">{category.name}</div>
                    </div>
                ))}
            </div>
            {childCategory.length > 0 && (
                <Categories categories={childCategory[0].childCategories} />
            )}
        </div>
    )
}

export default Wiki;