import { convertDate, getUserFromJson } from "../utils";
import { IBasic } from "./basic";
import { ISprint, Sprint } from "./sprint";
import { IUserInfo } from "./userInfo";

export const getContributionTitleType = (value: number) : string => {
  if(value == 1) {
    return "Có đóng góp";
  }
  if(value == 2) {
    return "Đột phá";
  }
  return "Không có";
}

export interface IUserProgress extends IBasic {
  sprintId: string;
  sprint?: ISprint;
  assessorId: string; // người đánh giá
  assessor?: IUserInfo;
  ratedUserId: string; // người được đánh giá
  ratedUser?: IUserInfo;

  ratingDate: Date;

  operationProcess: number;
  techContributionDevNote: string
  prodContributionDev: number;
  prodContributionDevNote: string;
  leaderShip: number;
  supportSkill: number;
  deadlineConfirmation: number;
  attitude: number;
  responsibility: number;
  percentOfKPI: number;
  amountOfNewIdeaExcSuccess: number;
  effectOfExecutedIdea: string;
  processContributionContent: number;
  processContributionContentNote: string;
  prodContributionContent: number;
  prodContributionContentNote: string;
}

const getNumberValue = (value: any) => {
  if(!value) {
    return 0;
  }
  if(value == "") {
    return 0;
  }
  let result = [0, 1, 2, 3, 4].indexOf(value);
  if(result == -1) {
    result = ["0", "1", "2", "3", "4"].indexOf(value);
    if(result == -1) {
      result = ["", "kem", "tb", "kha", "tot"].indexOf(value?.toString()?.toLowerCase() ?? "");
      if(result == -1) {
        return 0;
      }
    }
  }
  return result;
}

export class UserProgress implements IUserProgress {
  _id: string;
  sprintId: string;
  sprint?: ISprint;
  assessorId: string; // người đánh giá
  assessor?: IUserInfo;
  ratedUserId: string; // người được đánh giá
  ratedUser?: IUserInfo;
  ratingDate: Date;

  operationProcess: number;
  techContributionDev: number;
  techContributionDevNote: string
  prodContributionDev: number;
  prodContributionDevNote: string;
  leaderShip: number;
  supportSkill: number;
  deadlineConfirmation: number;
  attitude: number;
  responsibility: number;
  percentOfKPI: number;
  amountOfNewIdeaExcSuccess: number;
  effectOfExecutedIdea: string;
  processContributionContent: number;
  processContributionContentNote: string;
  prodContributionContent: number;
  prodContributionContentNote: string;
  constructor(props: any = {}) {
    this._id = props._id;
    let _sprintId = "";
    if (props.sprint && typeof props.sprint === "string") {
      _sprintId = props.sprint;
    } else if (
      props.sprint &&
      props.sprint[0] &&
      typeof props.sprint[0] === "object"
    ) {
      this.sprint = new Sprint(props.sprint[0]);
      _sprintId = this.sprint._id;
    } else if (typeof props.sprintId === "string") {
      _sprintId = props.sprintId;
    }
    this.ratingDate = convertDate(props.ratingDate) ?? new Date();
    this.sprintId = _sprintId;
    this.assessorId = props.assessorId;
    this.assessor = getUserFromJson(props.assessor);
    this.ratedUserId = props.ratedUserId;
    this.ratedUser = getUserFromJson(props.ratedUser);
    if(props.operationProcess && new Date(props.operationProcess).getTime() == 986083200000) {
      this.operationProcess = 4;
    } else {
      this.operationProcess = getNumberValue(props.operationProcess);
    }
    this.techContributionDev = getNumberValue(props.techContributionDev);
    this.prodContributionDev = getNumberValue(props.prodContributionDev);
    this.techContributionDevNote = props.techContributionDevNote ?? "";
    this.prodContributionDevNote = props.prodContributionDevNote ?? "";
    this.leaderShip = getNumberValue(props.leaderShip);
    this.supportSkill = getNumberValue(props.supportSkill);
    this.deadlineConfirmation = getNumberValue(props.deadlineConfirmation);
    this.attitude = getNumberValue(props.attitude);
    this.responsibility = getNumberValue(props.responsibility);
    this.percentOfKPI = getNumberValue(props.percentOfKPI);
    this.amountOfNewIdeaExcSuccess = getNumberValue(props.amountOfNewIdeaExcSuccess);
    this.effectOfExecutedIdea = props.effectOfExecutedIdea ?? "";
    this.processContributionContent = getNumberValue(props.processContributionContent);
    this.prodContributionContent = getNumberValue(props.prodContributionContent);
    this.processContributionContentNote = props.processContributionContentNote ?? "";
    this.prodContributionContentNote = props.prodContributionContentNote ?? "";
  }
}
