import { ITask, Task } from "../../shared/models/task";
import { ITaskAction, TaskTypes } from "../actions/task.action";

export interface ITaskState {
    loading: boolean;
    tasks?: ITask[];
    error?: any;
    actor?: string;
    campaignTasks?: any;
    taskShown?: ITask | undefined;
    processing?: boolean;
    task?: ITask;
}
export const MY_SELF = "myself";
const initState: ITaskState = {
    loading: false,
    actor: MY_SELF,
    campaignTasks: {},
    processing: false,
    tasks: [],
};

const taskState = (state = initState, action: ITaskAction): ITaskState => {
    switch (action.type) {
        case TaskTypes.GET_ALL_TASK_BY_SPRINT:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case TaskTypes.UPDATE_PARENT_TASK:
            return {
                ...state,
                processing: true,
            };
        case TaskTypes.GET_ALL_TASK_BY_SPRINT_SUCCESS:
            let mTasks = action.tasks;
            return {
                ...state,
                ...{
                    loading: false,
                    tasks: mTasks,
                },
            };
        case TaskTypes.GET_ALL_TASK_BY_SPRINT_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case TaskTypes.UPDATE_TASK_SUCCESS:
            const task = action.task;
            let listTask = JSON.parse(JSON.stringify(state.tasks));
            if (task) {
                if (!listTask) {
                    listTask = [];
                }
                let index = listTask.findIndex((u: any) => u._id == task._id);
                if (index > -1) {
                    listTask[index] = task;
                } else {
                    listTask.push(task);
                }
            }
            listTask = listTask.map((task: any) => new Task(task)); // fix-bugs
            return {
                ...state,
                tasks: listTask,
                processing: false,
            };
        case TaskTypes.DELETE_TASK:
            let taskId = action.taskId;
            let tasks = state.tasks ?? [];
            tasks = tasks.filter((task) => task._id != taskId);
            return { ...state, tasks: tasks };
        case TaskTypes.UPDATE_CHILD_TASK_SPRINT_SUCCESS:
            let taskUpdate = action.taskUpdate ?? [];
            let newTasks = state.tasks ?? [];
            if (newTasks.length && taskUpdate.length) {
                for (let i = 0; i < newTasks.length; i++) {
                    let data = taskUpdate?.find((t) => t._id === newTasks[i]._id);
                    if (!!data) {
                        newTasks[i].statusId = data.status._id;
                        newTasks[i].histories = data.histories;
                        newTasks[i].status = data.status;
                    }
                }
            }
            state.tasks = newTasks;
            return { ...state };
        case TaskTypes.UPDATE_ACTOR_CHANGE_TASK: {
            return {
                ...state,
                actor: action.actor,
            };
        }
        // case TaskTypes.GET_TASKS_BY_IDS:
        //     return { ...state, loading: true };
        case TaskTypes.UPDATE_CAMPAIGN_TASKS:
            let dataCampaigns = action.campaignIds ?? [];
            let currentCampaignTasks = JSON.parse(JSON.stringify(state.campaignTasks));
            for (let cid in currentCampaignTasks) {
                let t = currentCampaignTasks[cid];
                currentCampaignTasks[cid] = t.map((_t: any) => new Task(_t));
            }
            dataCampaigns?.forEach((d: any) => {
                currentCampaignTasks[d.campaignId] = d.listTask;
            });
            return { ...state, campaignTasks: currentCampaignTasks };
        case TaskTypes.SET_TASK_SHOW:
            return { ...state, taskShown: action.task };
        case TaskTypes.UPDATE_TASK:
            return {
                ...state,
                processing: true,
            };
        case TaskTypes.UPDATE_TASK_FAILED:
            return {
                ...state,
                processing: false,
            };
        case TaskTypes.PENDING_PREPROCESSING_TASK_UPDATE:
            return {
                ...state,
                processing: true,
            };
        case TaskTypes.GET_TASK_BY_ID_SUCCESS:
            if (action.task) {
                console.log("tao task 3", action.task);
                let listTask = JSON.parse(JSON.stringify(state.tasks));
                listTask.push(action.task);
                return {
                    ...state,
                    tasks: listTask,
                };
            }
            return {
                ...state,
            };
        case TaskTypes.UPDATE_PARENT_TASK_SUCCESS:
            const listParentId = action.listParentId;
            const parentId = action.parentId;
            const listHistory = action.historiesUpdate;
            const typeTask = action.typeTask;
            let listTaskParent = JSON.parse(JSON.stringify(state.tasks));
            const mapTask: { [id: string]: ITask } = {};
            for (let t of state.tasks ?? []) {
                mapTask[t._id] = t;
            }
            if (listParentId && parentId && typeTask) {
                listTaskParent = listTaskParent.map((t: ITask) => {
                    if(typeTask == "move"){
                        if (listParentId.find((d) => d == t._id)) {
                            t.parent = mapTask[parentId];
                            t.parentId = parentId;
                        }
                    }
                    if(typeTask == "merge"){
                        if(listParentId.find((d) => d == t._id)){
                            t.parent = mapTask[parentId];
                            t.type = "Sub Task";
                            t.parentId = parentId;
                        }
                    }
                    listHistory.find((h : any) => {
                        if(h.id == t._id){
                            t.histories = h.history
                        }
                    })
                    return new Task(t);
                });
            }
            return {
                ...state,
                tasks: listTaskParent,
                processing: false,
            };
        case TaskTypes.UPDATE_PARENT_TASK_FAILED:
            return {
                ...state,
                processing: false,
            };
        default:
            return state;
    }
};

export default taskState;
