import { convertDate } from "../utils"
import { IBasic } from "./basic"

export type AppType = "flutter" | "native_ios" | "native_android" | "react_native";

export const getAppType = (value?: string | null): AppType => {
    switch (value) {
        case "flutter":
            return "flutter";
        case "native_ios":
                return "native_ios";
        case "native_android":
                return "native_android";
        case "react_native":
                return "react_native";
        default: 
            return "flutter";
    }
}

export interface InAppProduct {
    id: string
    name: string
    description: string
    duration: string
    subscription: boolean
    days: number
    price: number
}

export class InAppProduct implements InAppProduct {
    id: string
    name: string
    description: string
    duration: string
    subscription: boolean
    days: number
    price: number
    constructor(props: any = {}) {
        this.id = props.id ?? "";
        this.name = props.name ?? "";
        this.description = props.description ?? "";
        this.duration = props.duration ?? "";
        this.subscription = props.subscription ?? false;
        this.days = props.days ?? 0;
        this.price = props.price ?? 0;
    }
}

export interface IAppOS {
    name: string
    packageName: string
    adModId: string
    nativeId: string
    bannerId: string
    interstitialId: string
    openAdId: string
    rewardAdId: string
    dynamicLink: string
    appleId: string // only ios
    teamId: string // only ios
    inAppProductItems: InAppProduct[] // danh sách các in app product
    showAds: boolean // sử dụng quảng cáo
    inAppPurchase: boolean // sử dụng in app purchase
    hasLogin: boolean
    firebaseConfig: FirebaseConfig
}

export class AppOS implements IAppOS {
    name: string
    packageName: string
    adModId: string
    nativeId: string
    bannerId: string
    interstitialId: string
    openAdId: string
    rewardAdId: string
    dynamicLink: string
    appleId: string // only ios
    teamId: string // only ios
    inAppProductItems: InAppProduct[] // danh sách các in app product
    showAds: boolean // sử dụng quảng cáo
    inAppPurchase: boolean // sử dụng in app purchase
    hasLogin: boolean
    firebaseConfig: FirebaseConfig
    
    constructor(props: any = {}) {
        this.name = props.name ?? "";
        this.packageName = props.packageName ?? "";
        this.adModId = props.adModId ?? "";
        this.nativeId = props.nativeId ?? "";
        this.bannerId = props.bannerId ?? "";
        this.openAdId = props.openAdId ?? "";
        this.rewardAdId = props.rewardAdId ?? "";
        this.interstitialId = props.interstitialId ?? "";
        this.dynamicLink = props.dynamicLink ?? "";
        this.appleId = props.appleId ?? "";
        this.teamId = props.teamId ?? "";
        this.showAds = props.showAds ?? false;
        this.inAppPurchase = props.inAppPurchase ?? false;
        this.hasLogin = props.hasLogin ?? false;
        this.inAppProductItems = [];
        const items = props.inAppProductItems;
        if(items) {
            for(let item of items) {
                this.inAppProductItems.push(new InAppProduct(item));
            }
        }
        this.firebaseConfig = new FirebaseConfig(props.firebaseConfig ?? {});
    }
}

export interface IAppColor {
    primary: string
    secondary: string
    tertiary: string
}

export class AppColor implements IAppColor {
    primary: string
    secondary: string
    tertiary: string

    constructor(props: any = {}) {
        this.primary = props.primary ?? "";
        this.secondary = props.secondary ?? "";
        this.tertiary = props.tertiary ?? "";
    }
}

export interface IAppAssert {
}

export class AppAssert implements IAppAssert {
    constructor(props: any = {}) {
    }
}

export class FirebaseConfig {
    projectId: string
    datasetId: string
    packageId: string

    constructor(props: any = {}) {
        this.datasetId = props.datasetId ?? "";
        this.projectId = props.projectId ?? "";
        this.packageId = props.packageId ?? "";
    }
}


export interface IAppInfo extends IBasic {
    longId: number
    key: string
    shortName: string
    lastUpdate: Date | null
    hasState: boolean
    ios: IAppOS
    android: IAppOS
    colors: IAppColor
    assets: IAppAssert
    bucket: string
    dbVersion: number
    type: AppType
}

export class AppInfo implements IAppInfo {
    _id: any
    longId: number
    key: string
    shortName: string
    lastUpdate: Date | null
    hasState: boolean
    ios: IAppOS
    android: IAppOS
    colors: IAppColor
    assets: IAppAssert
    bucket: string
    dbVersion: number
    type: AppType

    constructor(props: any = {}) {
        setBooleanValue("hasLogin", props);
        setBooleanValue("showAds", props);
        setBooleanValue("inAppPurchase", props);
        this._id = props._id;
        this.longId = props.id ?? props.longId ?? -1;
        this.key = props.key ?? "";
        this.shortName = props.shortName ?? "";
        this.lastUpdate = convertDate(props.lastUpdate);
        this.hasState = !!props.hasState ?? false;
        this.bucket = props.bucket ?? "";
        this.ios = new AppOS(props.ios);
        this.android = new AppOS(props.android);
        this.colors = new AppColor(props.colors);
        this.assets = new AppAssert(props.assets);
        this.dbVersion = props.dbVersion ?? 1;
        this.type = getAppType(props.type);
    }
}

const setBooleanValue = (key: string, object: any) => {
    if(!object["android"]) {
        object["android"] = {};
    }
    if(!object["ios"]) {
        object["ios"] = {};
    }
    let updateAndroid = false, updatedIOS = false;
    if(typeof object["android"][key] !== 'undefined') {
        updateAndroid = true;
        object["android"][key] = !!object["android"][key];
    }
    if(typeof object["ios"][key] !== 'undefined') {
        updatedIOS = true;
        object["ios"][key] = !!object["ios"][key];
    }
    if(typeof object[key] !== "undefined") {
        if(!updateAndroid) {
            object["android"][key] = !!object[key];
        }
        if(!updatedIOS) {
            object["ios"][key] = !!object[key];
        }
    }
}