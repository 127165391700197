import axios from "axios";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { IUserProgress } from "../../shared/models/userProgress";
import Url from "../../util/url";
import { IRatingAction, RatingTypes, getRequestRatingSuccess } from "../actions/rating.action";

export async function getRequestRatingAPI(userId: string, sprintId: string): Promise<string[] | null> {
    return await axios.post(Url("rating/get-request"), { userId, sprintId }).then((res) => {
        if(res.status == 200 && res.data) {
            return res.data;
        }
    }).catch((e) => []);
}

export async function updateRatingAPI(data: IUserProgress): Promise<string[] | null> {
    return await axios
    .post(Url("user-progress/update"), {
        data,
    })
    .then((data) => {
        // console.log("updateRatingAPI", data.data)
        if(data.status == 200 && data.data) {
            return data.data.ratingUserIds;
        }
        return null;
    }).catch((e) => {
        console.error(e);
        return null;
    });
}

export function* updateRequestRatingSaga(action: IRatingAction) {
    const { userProgress } = action;
    if(userProgress) {
        console.log("updateRequestRatingSaga", userProgress)
        let userIds: string[] = yield call(updateRatingAPI, userProgress);
        if(userIds) {
            yield put(getRequestRatingSuccess(userIds));
            toast("Đã gửi đánh giá!", { type: "success" });
        } else {
            toast("Không thể gửi đánh giá! Liên hệ admin!", { type: "error" });
        }
    }
}

export function* getRequestRatingSaga(action: IRatingAction) {
    const { sprintId, userId } = action;
    if(sprintId && userId) {
        try {
            let userIds: string[] = yield call(getRequestRatingAPI, userId, sprintId);
            if (userIds) {
                console.log("getRequestRatingSuccess", userIds)
                yield put(getRequestRatingSuccess(userIds));
            }
        } catch (e) {}
    }
}

export function* watchRating() {
    yield takeLatest(
        RatingTypes.GET_REQUEST_RATING,
        getRequestRatingSaga
    );
    yield takeLatest(
        RatingTypes.UPDATE_REQUEST_RATING,
        updateRequestRatingSaga
    );
}
