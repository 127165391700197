import Constants from '../../../constant';

const filterReducer = (state = {
    webs: [],
    flapps: [],
    rnapps: [],
    activeSegment: Constants.DASHBOARD_SEGMENT_LIST[0].value
}, action) => {
    switch (action.type) {
        case 'SETUP_FILTER':
            return action.payload;
        case 'UPDATE_START_DATE':
            return { ...state, startDate: action.payload };
        case 'UPDATE_END_DATE':
            return { ...state, endDate: action.payload };
        case 'UPDATE_SELECTED_WEBS':
            return { ...state, webs: action.payload };
        case 'UPDATE_SELECTED_FL_APPS':
            return { ...state, flapps: action.payload };
        case 'UPDATE_SELECTED_RN_APPS':
            return { ...state, rnapps: action.payload };
        case 'UPDATE_ACTIVE_SEGMENT':
            return { ...state, activeSegment: action.payload };
        default:
            return state;
    }
}

export default filterReducer;