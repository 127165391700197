const Error = () => {
    return (
        <div className="error">
            <p
                className='error'
                style={{
                    marginTop: '10px',
                    textAlign: 'center',
                    fontStyle: 'bold',
                    color: '#a3a4ac',
                }}
            >
            No result here
            </p>
        </div>
    );
};
export default Error;
