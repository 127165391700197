import { Modal } from 'react-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuestionModal = ({question, type, hideModal}) => {
    return (
        <Modal show={true} onHide={hideModal} className='question-nps-details-modal'>
            <Modal.Header closeButton>
                <Modal.Title>{question.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'nps' ? (
                    <div>
                        {question.badRatings.map((rating, index) => (
                            <div key={index} className='rate'>
                                <div>
                                    <div className='date'>{new Date(rating.lastUpdate).getDate()}/{(new Date(rating.lastUpdate).getMonth() + 1)}/{new Date(rating.lastUpdate).getFullYear()}</div>
                                    <div className='star'>{rating.ratingScore} <FontAwesomeIcon icon={faStar} color='#ffb400' /></div>
                                </div>
                                <div className='detail'>{rating.detail}</div>
                            </div>
                        ))}
                        {question.mediumRatings.map((rating, index) => (
                            <div key={index} className='rate'>
                                <div>
                                    <div className='date'>{new Date(rating.lastUpdate).getDate()}/{(new Date(rating.lastUpdate).getMonth() + 1)}/{new Date(rating.lastUpdate).getFullYear()}</div>
                                    <div className='star'>{rating.ratingScore} <FontAwesomeIcon icon={faStar} color='#ffb400' /></div>
                                </div>
                                <div className='detail'>{rating.detail}</div>
                            </div>
                        ))}
                        {question.goodRatings.map((rating, index) => (
                            <div key={index} className='rate'>
                                <div>
                                    <div className='date'>{new Date(rating.lastUpdate).getDate()}/{(new Date(rating.lastUpdate).getMonth() + 1)}/{new Date(rating.lastUpdate).getFullYear()}</div>
                                    <div className='star'>{rating.ratingScore} <FontAwesomeIcon icon={faStar} color='#ffb400' /></div>
                                </div>
                                <div className='detail'>{rating.detail}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    question.reportedMistakes.map((reportedMistake, index) => (
                        <div key={index} className='reported-mistake'>
                            <div>
                                <div className='date'>{new Date(reportedMistake.lastUpdate).getDate()}/{(new Date(reportedMistake.lastUpdate).getMonth() + 1)}/{new Date(reportedMistake.lastUpdate).getFullYear()}</div>
                                {(reportedMistake.reason === 1 || reportedMistake.reasons.indexOf(1) > -1) && <div className='report-mistake-reason'>There's a mistake</div>}
                                {(reportedMistake.reason === 2 || reportedMistake.reasons.indexOf(2) > -1) && <div className='report-mistake-reason'>It's too difficult</div>}
                                {(reportedMistake.reason === 3 || reportedMistake.reasons.indexOf(3) > -1) && <div className='report-mistake-reason'>Misunderstood Explanation</div>}
                            </div>
                            <div className='detail'>{reportedMistake.detail}</div>
                        </div>
                    ))
                )}
            </Modal.Body>
        </Modal>
    )
}

export default QuestionModal;