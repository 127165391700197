import { IAppChannel } from "../../shared/models/appChannel";
import { AppChannelType, AppChannelAction } from "../actions/appChannel.action";

export interface IAppChannelState {
    loading: boolean;
    updateAppChannels?: IAppChannel[];
    updateAppChannel?: IAppChannel[];
    error?: any;
}

const initState: IAppChannelState = {
    loading: false,
    updateAppChannels: [],
};

const appChannelState = (
    state = initState,
    action: AppChannelAction
): IAppChannelState => {
    switch (action.type) {
        case AppChannelType.GET_APP_CHANNEL:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case AppChannelType.GET_APP_CHANNEL_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    updateAppChannels: action.updateAppChannels ?? [],
                },
            };
        case AppChannelType.GET_APP_CHANNEL_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case AppChannelType.UPDATE_APP_CHANNEL_SUCCESS:
            if (action.updateAppChannel && state.updateAppChannel) {
                let index = state.updateAppChannel.findIndex(
                    (item) => item._id == action.updateAppChannel?._id
                );
                if (index > -1) {
                    state.updateAppChannel[index] = action.updateAppChannel;
                } else {
                    state.updateAppChannel.push(action.updateAppChannel);
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default appChannelState;
