import React, { useState } from "react";
import MenuTab from "../../analytics/menu";
import CompareSession from "./session";
import CompareActiveUser from "./active-user";
import ComparePagesPerUser from "./pages-per-user";
import CompareRetention from "./retention";
import CompareStickiness from "./stickiness";

const MENU_LIST_TRAFFIC = [
    {
        title: "Active Users",
        tab: "active_users",
    },
    {
        title: "Sessions",
        tab: "sessions",
    },
    {
        title: "Pages per user",
        tab: "pages_per_user",
    },
    {
        title: "Churn Rate",
        tab: "churn_rate",
    },
    {
        title: "Stickiness",
        tab: "stickiness",
    },
    {
        title: "Retention",
        tab: "retention",
    },
];

const CompareTraffic = () => {
    const [tab, setTab] = useState(MENU_LIST_TRAFFIC[0].tab);

    return (
        <div>
            <div className="back-top">
                <p>Traffic </p>
            </div>

            <div className="question-quality-v2 web-app-index">
                <MenuTab
                    tab={tab}
                    setTab={setTab}
                    menuList={MENU_LIST_TRAFFIC}
                />
                {tab === "active_users" ? (
                    <>
                        <CompareActiveUser />
                    </>
                ) : tab === "sessions" ? (
                    <>
                        <CompareSession />
                    </>
                ) : tab === "pages_per_user" ? (
                    <>
                        <ComparePagesPerUser />
                    </>
                ) : tab === "stickiness" ? (
                    <>
                        <CompareStickiness />
                    </>
                ) : tab === "retention" ? (
                    <>
                        <CompareRetention />
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default CompareTraffic;
