import { IUserGroup } from "../../shared/models/userGroup";

export enum UserGroupTypes {
    GET_ALL_USER_GROUP = "GET_ALL_USER_GROUP",
    GET_ALL_USER_GROUP_SUCCESS = "GET_ALL_USER_GROUP_SUCCESS",
    GET_ALL_USER_GROUP_FAILED = "GET_ALL_USER_GROUP_FAILED",
    UPDATE_USER_GROUP = "UPDATE_USER_GROUP",
    UPDATE_USER_GROUP_SUCCESS = "UPDATE_USER_GROUP_SUCCESS",
    UPDATE_USER_GROUP_FAILED = "UPDATE_USER_GROUP_FAILED",
    DELETE_USER_GROUP = "DELETE_USER_GROUP",
    DELETE_USER_GROUP_SUCCESS = "DELETE_USER_GROUP_SUCCESS",
    DELETE_USER_GROUP_FAILED = "DELETE_USER_GROUP_FAILED",
}

export interface IUserGroupAction {
    type: UserGroupTypes;
    userGroups?: IUserGroup[];
    userGroup?: IUserGroup;
    error?: any;
}

export const getAllUserGroup = (): IUserGroupAction => {
    return {
        type: UserGroupTypes.GET_ALL_USER_GROUP,
    };
};

export const getAllUserGroupSuccess = (
    userGroups: IUserGroup[]
): IUserGroupAction => {
    return {
        type: UserGroupTypes.GET_ALL_USER_GROUP_SUCCESS,
        userGroups,
    };
};

export const getAllUserGroupFailed = (error: any): IUserGroupAction => {
    return {
        type: UserGroupTypes.GET_ALL_USER_GROUP_FAILED,
        error,
    };
};

export const updateUserGroup = (userGroup: IUserGroup): IUserGroupAction => {
    return {
        type: UserGroupTypes.UPDATE_USER_GROUP,
        userGroup,
    };
};

export const updateUserGroupSuccess = (
    userGroups: IUserGroup[]
): IUserGroupAction => {
    return {
        type: UserGroupTypes.UPDATE_USER_GROUP_SUCCESS,
        userGroups,
    };
};

export const updateUserGroupFailed = (error: any): IUserGroupAction => {
    return {
        type: UserGroupTypes.UPDATE_USER_GROUP_FAILED,
        error,
    };
};

export const deleteUserGroup = (userGroup: IUserGroup): IUserGroupAction => {
    return {
        type: UserGroupTypes.DELETE_USER_GROUP,
        userGroup,
    };
};

export const deleteUserGroupSuccess = (
    userGroups: IUserGroup []
): IUserGroupAction => {
    return {
        type: UserGroupTypes.DELETE_USER_GROUP_SUCCESS,
        userGroups,
    };
};

export const deleteUserGroupFailed = (error: any): IUserGroupAction => {
    return {
        type: UserGroupTypes.DELETE_USER_GROUP_FAILED,
        error,
    };
};
