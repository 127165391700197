import { Close } from "@material-ui/icons";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from "@mui/material";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    addTestSection,
    updateTestSections,
} from "../../../../../../redux/actions/testSection.action";
import { TestSection } from "../../../../../../shared/models/testSection";
import Url from "../../../../../../util/url";
import "./style.scss";

const TestSectionModal: FC<{
    open: boolean;
    onClose: any;
    editItem: any;
    taskId: string;
    permission: boolean;
}> = ({ open, onClose, editItem, taskId, permission }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const dispatch = useDispatch();

    // If edit item, then SET those value
    useEffect(() => {
        if (editItem) {
            setName(editItem.name);
            setDescription(editItem.description);
        }

        return () => {
            setName("");
            setDescription("");
        };
    }, [editItem]);

    const handleSubmit = async () => {
        const { _id } = JSON.parse(localStorage.getItem("userInfo") ?? "");
        let itemSubmit = new TestSection({
            name,
            description,
            lastUpdate: new Date().getTime(),
            userID: _id,
            taskID: taskId,
        });

        if (editItem) {
            itemSubmit = {
                ...itemSubmit,
                _id: editItem._id,
            };
        }

        try {
            if (editItem) {
                await axios.post(Url("test-case/updates-test-sections"), [
                    itemSubmit,
                ]);
                dispatch(updateTestSections([itemSubmit]));
                toast.success("Sửa thành công", {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            } else {
                // Add new item
                const { data } = await axios.post(
                    Url("test-case/inserts-test-sections"),
                    [itemSubmit]
                );
                dispatch(addTestSection(data[0]));
                toast.success("Thêm thành công", {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }

            setName("");
            setDescription("");
            onClose();
        } catch (e) {
            console.log("error", e);
        }
    };

    return (
        <>
            <Dialog
                className="add-edit-section"
                scroll="body"
                maxWidth="xl"
                open={open}
                onClose={onClose}
            >
                <DialogTitle className="title">
                    <span>{editItem ? "Edit Section" : "Add new Section"}</span>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="content">
                    <Box
                        className="container"
                        sx={{ width: 640, minHeight: 200 }}
                    >
                        <div className="row1">
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="dense"
                                label="Name"
                                type="text"
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="row2">
                            <TextField
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                margin="dense"
                                label="Description"
                                type="text"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={5}
                            />
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions className="actions">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {editItem ? "Save" : "Add Section"}
                    </Button>
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TestSectionModal;
