import Button from "@material-ui/core/Button";
import Container from "@mui/material/Container";
import BoltIcon from "@mui/icons-material/Bolt";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultAvatar from "../../assets/images/default-avatar.png";
import {
    getSprintReportV2Data,
    sendMailToMemberTeamDEVAction,
    updateSprintReport,
} from "../../redux/actions/sprintReport.action";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
import { ISprintState } from "../../redux/reducers/sprint.reducer";
import { ISprintReportState } from "../../redux/reducers/sprintReport.reducer";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
import { ISprint } from "../../shared/models/sprint";
import { ISprintReport, ISprintReportItem } from "../../shared/models/sprintReport";
import { IUserInfo } from "../../shared/models/userInfo";
import { formatDate, getFullName, timezoneSetHours } from "../../util";
import LoadingWidget from "../LoadingWidget";
import SelectSprintWidget from "../dashboard/task-preview/edit-task-modal/select-sprint";
import "./index.scss";
import { getAllWorkingTimeInfo } from "../../redux/actions/workingTime.action";
import { IWorkingTime } from "../../shared/models/workingTime";
import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import Config from "../../shared/models/config";
import ReactTooltip from "react-tooltip";
import { calculateDisciplinePoints, roleForPoint, roleViewForPoint } from "../../shared/utils";
import ButtonSendReportEmail from "./widget/ButtonSendEmail";
import { SelectTeamsWidget, SelectUserWidget } from "./widget/SelectWidget";
import ShowUserDetails from "./widget/UserDetail";
import { DetailDiscipline } from "./widget/DisciplineWidget";
import ListTask from "./widget/ListTasks";
import {
    convertSprintReportV2ItemToSprintReportItem,
    convertToOffDays,
    getUserDisciplineScore,
    getUserTaskScores,
} from "../../utils";
import { ITaskScores } from "../../shared/models/taskScore";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Redirect, useHistory } from "react-router";
import { getAllStatus } from "../../redux/actions/status.action";
import { IStatusState } from "../../redux/reducers/status.reducer";
import { IStatus } from "../../shared/models/status";

const ReportsPage = () => {
    const isAdmin = true;
    const dispatch = useDispatch();
    const sprintState: ISprintState = useSelector((state: any) => state.sprintState);
    const userInfoState: IUserInfoState = useSelector((state: any) => state.userInfoState);
    const statusState: IStatusState = useSelector((state: any) => state.statusState);
    useEffect(() => {
        dispatch(getAllUserInfo()); // 'user/not-disable'
        dispatch(getAllStatus());
    }, []);
    if (
        sprintState.loading ||
        !sprintState.sprints ||
        sprintState.sprints.length == 0 ||
        userInfoState.loading ||
        !userInfoState.userInfos ||
        userInfoState.userInfos.length == 0 ||
        !statusState.status ||
        statusState.status.length == 0
    ) {
        return <LoadingWidget />;
    }
    if (!isAdmin) {
        return <Container style={{ paddingTop: "20px" }}>Bạn không có quyền truy cập trang này!</Container>;
    }
    return <ReportsBody sprints={sprintState.sprints} statuses={statusState.status} />;
};

const ReportsBody: FC<{ sprints: ISprint[]; statuses: IStatus[] }> = ({ sprints, statuses }) => {
    const [selectUserId, setSelectUserId] = useState<string | "all">("all");
    const [selectTeamId, setSelectTeamId] = useState<string | "all">("all");
    const [selectSprintId, setSelectSprintId] = useState<string>(
        (sprints.find((sprint) => sprint.activeSprint) ?? sprints[0])._id
    );
    const userInfor: IUserInfo = useSelector((state: any) => state.authState.user);
    const history = useHistory();
    let currentSprint = sprints.find((sprint) => sprint._id === selectSprintId) ?? sprints[0];
    const [onlyActiveUsers, setOnlyActiveUsers] = useState(false);
    return (
        <Container maxWidth="lg" style={{ paddingTop: "20px" }} className="reports-page">
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    className="report-chart flex"
                    variant="outlined"
                    onClick={(e) => {
                        history.push({ pathname: "/reports-statistics" });
                        <Redirect to="/reports-statistics" />;
                    }}
                >
                    Biểu đồ
                    <BarChartIcon htmlColor={"#fff"} />
                </Button>
                <SelectSprintWidget sprintId={selectSprintId} onChange={setSelectSprintId} />
                <SelectTeamsWidget value={selectTeamId} onChange={setSelectTeamId} />
                <SelectUserWidget onlyActiveUsers={onlyActiveUsers} value={selectUserId} onChange={setSelectUserId} />
            </div>
            <div className="align-item-center boundary-btn-func">
                {userInfor.role == Config.USER_ROLE_ADMIN && <ButtonSendReportEmail sprint={currentSprint} />}
                <Button
                    className="filter-active-users flex"
                    variant="outlined"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOnlyActiveUsers(!onlyActiveUsers);
                    }}
                    style={{
                        backgroundColor: onlyActiveUsers ? "#3f51b5" : "transparent",
                        color: onlyActiveUsers ? "#fff" : "#212121",
                    }}
                    disabled={true}
                >
                    Only active users
                    <BoltIcon htmlColor={onlyActiveUsers ? "#fff" : "#212121"} />
                </Button>
            </div>
            <ReportsContentWrapper
                onlyActiveUsers={onlyActiveUsers}
                sprint={currentSprint}
                // sprints={sprints}
                userId={selectUserId}
                teamId={selectTeamId}
                statuses={statuses}
            />
        </Container>
    );
};

const ReportsContentWrapper: FC<{
    sprint: ISprint;
    userId: string;
    teamId: string;
    onlyActiveUsers: boolean;
    // sprints: ISprint[];
    statuses: IStatus[];
}> = ({ sprint, userId, teamId, onlyActiveUsers, statuses }) => {
    const sprintReportState: ISprintReportState = useSelector((state: any) => state.sprintReportState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateSprintReport(sprint)); // lay task trong nay // report cu, khong comment de sau so sanh
        if (parseInt(sprint.shortId) <= 67) {
            // tu 68 tro di moi tinh reportv2
            let _today = timezoneSetHours(new Date(), 23, 59, 59).getTime();
            let _endSprint = timezoneSetHours(sprint.endDate).getTime();
            let start = timezoneSetHours(sprint.startDate).getTime();
            let end = _endSprint > _today ? _today : _endSprint;
            dispatch(getAllWorkingTimeInfo({ start, end }));
        } else {
            dispatch(getSprintReportV2Data(sprint, []));
        }
    }, [sprint]);
    if (sprintReportState.loading) {
        return <LoadingWidget />;
    }
    if (!sprint.activeSprint ? sprintReportState?.sprintReports?.length == 0 : !sprintReportState?.newSprintReports) {
        return <div style={{ textAlign: "center", padding: 20 }}>Chưa có dữ liệu!</div>;
    }

    return (
        <ReportsContent
            sprint={sprint}
            teamId={teamId}
            userId={userId}
            data={sprintReportState.sprintReports ?? []}
            onlyActiveUsers={onlyActiveUsers}
            newReport={sprintReportState.newSprintReports}
            // sprints={sprints}
            statuses={statuses}
        />
    );
};

const ReportsContent: FC<{
    sprint: ISprint;
    userId: string;
    teamId: string;
    data: ISprintReport[];
    onlyActiveUsers: boolean;
    newReport: any;
    statuses: IStatus[];
}> = ({ sprint, userId, teamId, data, onlyActiveUsers, newReport, statuses }) => {
    const checkShowReport = (userId: string, teamId: string, report: ISprintReport) => {
        return (
            (!userId || userId === "all" || report.userId == userId) &&
            (!teamId || teamId === "all" || report.user?.teams == teamId)
        );
    };

    const mapStatus: Map<string, IStatus> = new Map();
    for (let status of statuses) {
        mapStatus.set(status._id, status);
    }

    const filterTask = () => {
        let tasks: ISprintReportItem[] = [];
        // if (parseInt(sprint.shortId) > 67) {
        // } else {
        data.forEach((report: ISprintReport) => {
            if (checkShowReport(userId, teamId, report)) {
                const ts = report.totalTasks.map((item: ISprintReportItem) => {
                    const finishedTask = report.finishedTasks.find((t: ISprintReportItem) => t.taskID === item.taskID);
                    if (finishedTask) {
                        item.status = "done";
                    } else if (!item.status) {
                        item.status = mapStatus.get(item.status ?? "")?.title ?? "";
                    }
                    item.assignee = report.user;
                    return item;
                });
                tasks.push(...ts);
            }
        });
        // }
        return tasks;
    };

    const allTasks: ISprintReportItem[] = filterTask();
    return (
        <div className="reports-page-content">
            {!!data && (
                <div>
                    <div className="tab-panel">
                        <ReportItem
                            onlyActiveUsers={onlyActiveUsers}
                            currentSprint={sprint}
                            reports={data.filter((item) => checkShowReport(userId, teamId, item))}
                            userId={userId}
                            teamId={teamId}
                            newReport={newReport}
                            // sprints={sprints}
                        />
                    </div>
                </div>
            )}
            <ButtonUpdateScore userId={userId} sprint={sprint} />
            <ListTask tasks={allTasks} mapStatus={mapStatus} currentSprint={sprint} />
        </div>
    );
};

const ButtonUpdateScore = ({ userId, sprint }: { userId: string; sprint: ISprint }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.authState.user);
    const members: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const member = members.find((e) => e._id == userId);
    return auth.positionId == Config.POSITIONS.LEAD_DEV.id &&
        member?._id &&
        member.teams == "technical" &&
        !sprint.activeSprint ? (
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                dispatch(sendMailToMemberTeamDEVAction({ sprint, userId }));
            }}
        >
            Update điểm
        </Button>
    ) : (
        <></>
    );
};

const ReportItem: FC<{
    onlyActiveUsers: boolean;
    reports: ISprintReport[];
    currentSprint: ISprint;
    userId: string;
    teamId: string;
    newReport: any;
    // sprints: ISprint[];
}> = ({ reports, currentSprint, onlyActiveUsers, userId, teamId, newReport }) => {
    const sprintActiveUser = useSelector((state: any) => state.reportState.sprintActiveUsers);
    const workingTimes: IWorkingTime[] = useSelector((state: any) => state.workingTime.workingTime);
    const offDaysWfhDays: IOffDaysWfhDays[] = useSelector((state: any) => state.workingTime.offDaysWfhDays);
    // const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const getDoneRate = (item: ISprintReport) => {
        const totalTasks = item.totalTasks.length ?? 0;
        const finishedTasks = item.finishedTasks.length ?? 0;
        const doneRateWithTasks = totalTasks == 0 ? 0 : Math.round((finishedTasks / totalTasks) * 100);
        return doneRateWithTasks;
    };
    const [selectedItem, setSelectedItem] = useState<ISprintReport | null>(null);
    const [detailDiscipline, setDetailDiscipline] = useState<DetailDiscipline>({
        offDaysWfhDay: {},
        score: 0,
        currentSprint: {},
        discipline: {},
    });
    const [userTaskScore, setUserTaskScore] = useState<any>({});
    const [userDisciplineScore, setUserDisciplineScore] = useState<any>({});
    // useEffect(() => {
    //     if (tasks && currentSprint?.activeSprint) {
    //         let data: any = getUserTaskScores({ tasks, currentSprint, users });
    //         setUserTaskScore(data);
    //     }
    // }, [tasks]);

    // useEffect(() => {
    //     if (currentSprint?.activeSprint && offDaysWfhDays && workingTimes) {
    //         let listDisciplineScore: any = getUserDisciplineScore({
    //             currentSprint,
    //             users,
    //             offDaysWfhDays,
    //             workingTimes,
    //         });
    //         setUserDisciplineScore(listDisciplineScore);
    //     }
    // }, [offDaysWfhDays, workingTimes]);

    useEffect(() => {
        // if (!currentSprint?.activeSprint) {
        let listTaskScore: any = getUserTaskScores({
            currentSprint,
            users,
            newSprintReports: newReport,
            onlyReports: true,
        });
        let listDisciplineScore: any = getUserDisciplineScore({
            currentSprint,
            users,
            newSprintReports: newReport,
            onlyReports: true,
        });
        setUserTaskScore(listTaskScore);
        setUserDisciplineScore(listDisciplineScore);
        // }
    }, [newReport]);
    const addMoreUsers = (reports: ISprintReport[]) => {
        const list = [...reports];
        let listUsers = users
            .filter((u) => {
                if (userId == "all") return true;
                else return userId === u._id;
            })
            .filter((u) => {
                if (teamId == "all") return true;
                else return teamId === u.teams;
            });

        for (let user of listUsers) {
            if (reports.findIndex((a) => a.userId == user._id) == -1) {
                list.push({
                    _id: "report-" + new Date().getTime(),
                    bugTasks: [],
                    finishedTasks: [],
                    lastUpdate: new Date(),
                    sprint: currentSprint._id,
                    totalTasks: [],
                    userId: user._id,
                    user: user,
                });
            }
        }
        return list;
    };
    return (
        <div>
            <table className="table table-bordered">
                {parseInt(currentSprint.shortId) > 67 ? (
                    <>
                        <thead>
                            <tr>
                                <td>Nhân sự</td>
                                <td>Số task hoàn thành</td>
                                <td>Số lượng task trong sprint</td>
                                <td>Số point hoàn thành</td>
                                <td>Tổng số point</td>
                                <td>Tỷ lệ hoàn thành task</td>
                                <td>Bugs</td>
                                <td>Kỷ luật</td>
                                <td>Chi tiết</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users //tu sprint 68 tro di
                                .filter((u) => {
                                    if (onlyActiveUsers ? sprintActiveUser[u._id] : true) return u.status == 1;
                                })
                                .filter((u) => {
                                    if (userId == "all") return true;
                                    else return userId === u._id;
                                })
                                .filter((u) => {
                                    if (teamId == "all") return true;
                                    else return teamId === u.teams;
                                })
                                .map((user) => {
                                    const avatar = user?.avatar && user?.avatar?.length ? user?.avatar : defaultAvatar;
                                    let taskScores: ITaskScores = userTaskScore[user._id];
                                    let discipline = userDisciplineScore[user._id];
                                    let score = !!discipline ? 100 - (discipline?.minusScore ?? 0) : 0;
                                    let offdayDetail = (
                                        <>
                                            {discipline?.offDaysUnauthorized?.length ?? 0}
                                            {discipline?.offDaysUnauthorized?.length > 0 &&
                                                discipline?.offDaysUnauthorized?.map((d: any, index: number) => (
                                                    <div key={index}>
                                                        &#x2022;
                                                        {`${formatDate(new Date(d.date), "dd/mm/yyyy")}: ${d.reason}`}
                                                    </div>
                                                ))}
                                        </>
                                    );
                                    let attendanceDetail = (
                                        <>
                                            {discipline?.workingTime?.length ?? 0}
                                            {(discipline?.workingTime ?? [])?.map((d: any, index: number) => (
                                                <div key={index}>
                                                    &#x2022;
                                                    {`${formatDate(new Date(d.date), "dd/mm/yyyy")}: ${d.reason}`}
                                                </div>
                                            ))}
                                        </>
                                    );
                                    return (
                                        <tr key={user._id} className={"color"}>
                                            <td>
                                                <span>
                                                    <img
                                                        src={avatar}
                                                        width={35}
                                                        height={35}
                                                        style={{
                                                            borderRadius: 35,
                                                        }}
                                                    />
                                                </span>
                                                &nbsp;&nbsp;
                                                <span>{getFullName(user)}</span>
                                            </td>
                                            <td>{taskScores?.tasksDone.value ?? 0}</td>
                                            <td>{taskScores?.totalTaskInSprint.value ?? 0}</td>
                                            <td>{taskScores?.pointDone.value ?? 0}</td>
                                            <td>{taskScores?.totalPoint?.value ?? 0}</td>
                                            <td>{taskScores?.taskDonePercentage.value ?? 0}%</td>
                                            <td>{taskScores?.bug?.value ?? 0}</td>
                                            <td
                                                data-tip
                                                data-for={"disciplineTip" + user._id}
                                                style={{
                                                    display: "inline-block",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {roleViewForPoint(user) ? score : "-"}
                                                {roleViewForPoint(user) && (
                                                    <ReactTooltip id={"disciplineTip" + user._id} place="top" effect="solid">
                                                        <div
                                                            style={{
                                                                display: "block",
                                                            }}
                                                        >
                                                            <div>Lỗi chấm công: {attendanceDetail}</div>
                                                            <div>Số lần nghỉ vi phạm: {offdayDetail}</div>
                                                        </div>
                                                    </ReactTooltip>
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={user.role === Config.USER_ROLE_ADMIN}
                                                    onClick={() => {
                                                        let item = convertSprintReportV2ItemToSprintReportItem(
                                                            taskScores,
                                                            currentSprint,
                                                            user
                                                        );
                                                        setSelectedItem(item);
                                                        let offDays = convertToOffDays(discipline.offDaysUnauthorized);
                                                        setDetailDiscipline({
                                                            offDaysWfhDay: {
                                                                offDays: offDays,
                                                            },
                                                            score,
                                                            currentSprint,
                                                            discipline,
                                                        });
                                                    }}
                                                >
                                                    Chi tiết
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </>
                ) : (
                    <>
                        <thead>
                            <tr>
                                <td>Nhân sự</td>
                                <td>Số task hoàn thành</td>
                                <td>Số lượng task</td>
                                <td>Số point hoàn thành</td>
                                <td>Tổng số point</td>
                                <td>Tỷ lệ hoàn thành task</td>
                                <td>Bugs</td>
                                <td>Kỷ luật</td>
                                <td>Chi tiết</td>
                            </tr>
                        </thead>
                        <tbody>
                            {addMoreUsers(reports) //truoc sprint 68
                                .filter((item: ISprintReport) => {
                                    if (onlyActiveUsers ? sprintActiveUser[item.userId] : true) return item.user?.status == 1;
                                })
                                .map((item: ISprintReport, index: number) => {
                                    let totalTasks = item.totalTasks?.length ?? 0;
                                    let finishedTasks = item.finishedTasks?.length ?? 0;
                                    const listTotalTasks = item.totalTasks ?? [];
                                    const listFinishedTasks = item.finishedTasks ?? [];
                                    let finishedPoints = !listFinishedTasks.length
                                        ? 0
                                        : listFinishedTasks.reduce(
                                              (prevValue: number, newValue: ISprintReportItem) =>
                                                  prevValue + (newValue.point ?? 0),
                                              0
                                          );
                                    let totalPoints = !listTotalTasks.length
                                        ? 0
                                        : listTotalTasks.reduce(
                                              (prevValue: number, newValue: ISprintReportItem) =>
                                                  prevValue + (newValue.point ?? 0),
                                              0
                                          );
                                    if (!totalTasks) {
                                        totalTasks = listTotalTasks.length;
                                    }
                                    if (!finishedTasks) {
                                        finishedTasks = listFinishedTasks.length;
                                    }
                                    let doneRateWithTasks = getDoneRate(item);
                                    const bugTasks = item.bugTasks.length;
                                    const avatar =
                                        item.user?.avatar && item.user?.avatar?.length ? item.user?.avatar : defaultAvatar;
                                    const workingTime = workingTimes.filter((w) => w.userId === item.userId);
                                    const offDaysWfhDay: any = offDaysWfhDays.find((w) => w.userId === item.userId) ?? null;
                                    let discipline = calculateDisciplinePoints(currentSprint, offDaysWfhDay, workingTime);
                                    let score = !!offDaysWfhDay || workingTime?.length > 0 ? 100 - discipline.minusScore : 0;
                                    let currentNbOffDays = discipline.offDaysUnauthorized;
                                    return (
                                        <tr key={item.userId} className={"color"}>
                                            <td>
                                                <span>
                                                    <img
                                                        src={avatar}
                                                        width={35}
                                                        height={35}
                                                        style={{
                                                            borderRadius: 35,
                                                        }}
                                                    />
                                                </span>
                                                &nbsp;&nbsp;
                                                <span>{getFullName(item.user)}</span>
                                            </td>
                                            <td>{finishedTasks}</td>
                                            <td>{totalTasks}</td>
                                            <td>{finishedPoints}</td>
                                            <td>{totalPoints}</td>
                                            <td>{doneRateWithTasks}%</td>
                                            <td>{bugTasks}</td>
                                            <td
                                                data-tip
                                                data-for={"disciplineTip" + item.userId}
                                                style={{
                                                    display: "inline-block",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {roleViewForPoint(item.user) ? score : "-"}
                                                {roleViewForPoint(item.user) && (
                                                    <ReactTooltip id={"disciplineTip" + item.userId} place="top" effect="solid">
                                                        <div
                                                            style={{
                                                                display: "block",
                                                            }}
                                                        >
                                                            <div>
                                                                Lỗi chấm công: {workingTime.length}
                                                                {discipline.workingTime.map((d: any, index: number) => (
                                                                    <div key={index}>
                                                                        &#x2022;
                                                                        {`${formatDate(new Date(d.date), "dd/mm/yyyy")}: ${
                                                                            d.reason
                                                                        }`}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                Số lần nghỉ vi phạm: {currentNbOffDays.length}
                                                                {discipline?.offDaysUnauthorized?.length > 0 &&
                                                                    discipline?.offDaysUnauthorized?.map(
                                                                        (d: any, index: number) => (
                                                                            <div key={index}>
                                                                                &#x2022;
                                                                                {`${formatDate(
                                                                                    new Date(d.date),
                                                                                    "dd/mm/yyyy"
                                                                                )}: ${d.reason}`}
                                                                            </div>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </ReactTooltip>
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={item.user?.role === Config.USER_ROLE_ADMIN}
                                                    onClick={() => {
                                                        setSelectedItem(item);
                                                        setDetailDiscipline({
                                                            offDaysWfhDay,
                                                            // workingTime,
                                                            score,
                                                            currentSprint,
                                                            discipline,
                                                        });
                                                    }}
                                                >
                                                    Chi tiết
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </>
                )}
            </table>
            {selectedItem && (
                <ShowUserDetails item={selectedItem} detailDiscipline={detailDiscipline} onHide={() => setSelectedItem(null)} />
            )}
        </div>
    );
};

export default ReportsPage;
