import './styles.scss';
import CreateBlogPage from "../create-blog-pop-up";

const CreateBlog = () => {
    return (
        <div className="wiki container">
            <div className="documentations">
                <CreateBlogPage/>
            </div>
        </div>
    )
}

export default CreateBlog;