import { BarChart } from "./BarChart";
import { StackedBarLineChart } from "./StackedBarLineChart";

export type ChartOptionConfig = {
    stackedX?: boolean;
    stackedY?: boolean;
    suggestedMax?: number;
    xTitle: string;
    yTitle: string;
    unit: string;
};

export const configTooltip = (unit: string) => {
    return {
        callbacks: {
            label: function (context: any) {
                let label = context.dataset.label || "";
                if (label) {
                    label += ": ";
                }
                if (context.parsed.y !== null) {
                    label += context.parsed.y + " " + unit;
                }
                return label;
            },
        },
    };
};

const baseClassname = "chart-widget";
export type Dataset = {
    label: string;
    dataSet: number[];
    type: "bar" | "line";
    color?: string;
}; //sau can them dang khac thi bo sung
const MyChart = ({
    title,
    type,
    xAxis,
    dataSet,
    stacked,
}: {
    title: string;
    type: "line" | "bar" | "stackedbar-line";
    xAxis: string[];
    dataSet: Dataset[];
    stacked?: ChartOptionConfig;
}) => {
    if (type === "bar") {
        return (
            <div className={baseClassname}>
                <BarChart title={title} xAxis={xAxis} dataset={dataSet} stacked={stacked} />
            </div>
        );
    }
    if (type === "line") {
        // return <LineChart dataset={}/>
    }
    if (type === "stackedbar-line") {
        return (
            <div className={baseClassname}>
                <StackedBarLineChart title={title} xAxis={xAxis} dataset={dataSet} stacked={stacked} />
            </div>
        );
    }
    return <div>{type}</div>;
};

export default MyChart;
