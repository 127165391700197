import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isEqual, format, closestIndexTo, compareAsc } from 'date-fns'
import CircularIndeterminate from '../../common/icon/Loading';
import './index.scss'
import { ArrowBackIos } from '@material-ui/icons';

interface IKeyword {
  _id: string,
  totalDownload: number
}

interface IDataDate {
  _id: string,
  totalDownload: number
}

const DATE_FORMAT = 'Y-M-d'

const TrendingKeywords = () => {
  const startTime = useSelector(
    (state: any) => state.dashBoardState.startTime
  );
  const endTime = useSelector((state: any) => state.dashBoardState.endTime);
  const [keywords, setKeywords] = useState<IKeyword[]>([]);
  const [loading, setLoading] = useState(false);
  const [openBarChart, setOpenBarChart] = useState(false);
  const [keyword, setKeyword] = useState<IKeyword>({
    _id: '',
    totalDownload: 0
  });

  useEffect(() => {
    fetchKeywords();
  }, [startTime, endTime])
  
  const fetchKeywords = async () => {
    setLoading(true);
    try {
      const startDate = (new Date(startTime)).toLocaleString()
      const endDate = (new Date((new Date(endTime)).setHours(5,0,0,0))).toLocaleString()
      const { data }: { data: IKeyword[] } = await axios.post(
        "https://api.worksheetzone.org/api/get-download-number-of-all-keyword",
        {
          startDate,
          endDate
        }
      )
    
      let keywords: IKeyword[] = data.sort((a: IKeyword, b: IKeyword) =>  b.totalDownload - a.totalDownload)
      
      setKeywords(keywords);
    } catch (error) {
      console.log(error);
    }
    
    setLoading(false);
  }

  const handleClickKeyword = (keyword: IKeyword) => {
    setKeyword(keyword);
    setOpenBarChart(true);
  }

  if(loading){
    return (
      <CircularIndeterminate />
    )
  }
  return (
    <div className="trending_keyword">
      {openBarChart ?
        <BarChart handleClose={() => setOpenBarChart(false)} keyword={keyword} startTime={startTime} endTime={endTime}/> :
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '60%'}} align="center">Keyword</TableCell>
                    <TableCell style={{width: '40%'}} align="left">Total Download</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {keywords?.map((row: IKeyword) => (
                      <TableRow
                          hover
                          key={row._id}
                          sx={{
                              cursor: 'pointer',
                              "&:last-child td, &:last-child th": {
                                  border: 0,
                              },
                          }}
                          onClick={() => handleClickKeyword(row)}
                      >
                          <TableCell align="center">
                              {row._id}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: '50px'}} align="left">
                              {row.totalDownload < 0 ? 0 : row.totalDownload}
                          </TableCell>
                      </TableRow>
                  ))}
              </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  )
}

const BarChart = ({
  handleClose, keyword, startTime, endTime
  }: {
    handleClose: () => void, keyword: IKeyword, startTime: string, endTime: string
  }) => {
  const [loading, setLoading] = useState(true);
  const [dataDateByKeyword, setDataDateByKeyword] = useState<IDataDate[]>([])

  useEffect(() => {
    fetchDateDataByKeyword();
  }, [keyword, startTime, endTime])
  
  const fetchDateDataByKeyword = async () => {
    setLoading(true);
    try {
      const startDate = (new Date(startTime)).toLocaleString()
      const endDate = (new Date((new Date(endTime)).setHours(5,0,0,0))).toLocaleString()
      
      const { data } = await axios.post(
        'https://api.worksheetzone.org/api/get-detail-download-of-keyword',
        {
          keyword: keyword._id,
          startDate,
          endDate,
        }
      )
      
      let dates = data.map((date: IDataDate) => (
        {
          ...date,
          _id: format(new Date(date._id), DATE_FORMAT)
        }
      )) 
      
      dates = getRangeDates(startTime, endTime, dates);
      
      dates = getDatesLost(dates).map(dateData => ({
        ...dateData,
        _id: format(new Date(dateData._id), 'LLL dd')
      }))

      setDataDateByKeyword(dates)
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  }

  const getRangeDates = (startTime: string, endTime: string, datesData: IDataDate[]) => {
    if(datesData.length === 0) {
      return [
        {
          _id: format(new Date(startTime), DATE_FORMAT),
          totalDownload: 0
        },  
        {
          _id: format(new Date(endTime), DATE_FORMAT),
          totalDownload: 0
        } 
      ]
    }
    const dates = datesData.map(dateData => new Date(dateData._id));
    const headIndex = closestIndexTo(new Date(startTime), dates)
    const tailIndex = closestIndexTo(new Date(endTime), dates)
    let datesAfter;   

    if(headIndex !== undefined && tailIndex !== undefined){
      datesAfter = datesData.splice(headIndex, tailIndex - headIndex + 1);
      const headStatus = compareAsc(new Date(startTime), new Date(datesAfter[0]._id));
      
      const tailStatus = compareAsc(new Date(endTime), new Date(datesAfter[datesAfter.length - 1]._id));
      if(headStatus === -1) {
        datesAfter.unshift({
          _id: format(new Date(startTime), DATE_FORMAT),
          totalDownload: 0
        })
      } else if (headStatus === 1){
        datesAfter[0] =  {
          _id: format(new Date(startTime), DATE_FORMAT),
          totalDownload: 0
        }
      }
      if(tailStatus === 1) {
        datesAfter.push({
          _id: format(new Date(endTime), DATE_FORMAT),
          totalDownload: 0
        })
      } else if (tailStatus === -1){
        datesAfter[0] = {
          _id: format(new Date(endTime), DATE_FORMAT),
          totalDownload: 0
        }
      }
    }
    
    return datesAfter;
  }

  const getDatesInRange = (startDate: Date, endDate: Date) => {
    const date = new Date(startDate.getTime());
  
    const dates = [];
  
    while (date <= endDate) {
      dates.push(format(new Date(date), DATE_FORMAT));
      date.setDate(date.getDate() + 1);
    }
  
    return dates;
  }

  const getDatesLost = (data: IDataDate[]) => {
    if(data.length === 1){
      return data;
    }

    const arrDates: string[] = getDatesInRange(new Date(data[0]._id), new Date(data[data.length - 1]._id))

    return arrDates.map((date: string) => {
      const result = data.find((d: IDataDate) => d._id === date);

      if(!result){
        return {
          _id: date,
          totalDownload: 0
        }
      }

      return result
    })
  }

  if(loading){
    return (
      <CircularIndeterminate />
    )
  }

  return (
    <div className="trending_keyword_chart">
      <div className="header">
        <IconButton onClick={handleClose} className='btn' color="primary">
          <ArrowBackIos />
        </IconButton>
        <h4>{keyword._id}</h4>
      </div>
      <ResponsiveContainer
          height={400}
          width="100%"
          className="chart"
      >
          <ComposedChart data={dataDateByKeyword}>
              <Bar
                  type="monotone"
                  dataKey="totalDownload"
                  fill="#0c6efd"
                  yAxisId="left"
              />
              <XAxis dataKey="_id" padding={{ left: 10 }} />
              <YAxis yAxisId="left" />
              <Tooltip
                  content={<CustomBarTooltip />}
                  cursor={{ fill: "rgba(0, 0, 0, 0)" }}
                  />
          </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

const CustomBarTooltip = ({ payload, label }: { payload?: any, label?: string}) => {
  return (
      (payload && payload.length) ?
      <div className="custom-tooltip">
        <div className="custom-tooltip-label">{label}</div>
        <div className="custom-tooltip-value">
            Số lượt download: {payload[0].payload.totalDownload}
        </div>
      </div> : null
  )
}

export default TrendingKeywords