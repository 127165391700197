import { ITask } from '../../../shared/models/task'

export default function FilterByAssignee({ versions, handleGetInfoUser, Constants, listTaskofCampaign, assigneechosen }: any) {
    return (
        versions?.map((version: any) => {
            const indexOfCampaign: number[] = []
            return (
                version.tasks?.map((task: ITask, index: number) => {
                    if (listTaskofCampaign.includes(task._id)) {
                        if (assigneechosen === Constants.ALL_ASSIGNEE) {
                            console.log("Check")
                            indexOfCampaign.push(index)
                            const firstIndexOfCampaign = indexOfCampaign[0]
                            console.log("Check")
                            return (
                                <tr key={task._id}>
                                    <td></td>
                                    <td>
                                        {index === firstIndexOfCampaign && version.version}
                                    </td>
                                    <td>{index === firstIndexOfCampaign && version.dateVersion}</td>
                                    <td>
                                        <a href={"https://hero.abc-elearning.org/task/" + task._id}>
                                            {task.title}
                                        </a>
                                    </td>
                                    <td
                                        className="avatar"
                                        onClick={() =>
                                            window.open("https://hero.abc-elearning.org/user/" + task.assignee)
                                        }
                                    >
                                        <img src={handleGetInfoUser(task.assignee, Constants.GET_FIELD_AVATAR).avatar} />
                                        <span>
                                            {handleGetInfoUser(task.assignee, Constants.GET_FIELD_FIRSTNAME).firstName}
                                        </span>
                                    </td>
                                </tr>
                            )
                        } else {
                            if (assigneechosen === task.assignee) {
                                indexOfCampaign.push(index)
                                const firstIndexOfCampaign = indexOfCampaign[0]
                                return (
                                    <tr key={task._id}>
                                        <td></td>
                                        <td>
                                            {index === firstIndexOfCampaign && version.version}
                                        </td>
                                        <td>{index === firstIndexOfCampaign && version.dateVersion}</td>
                                        <td>
                                            <a href={"https://hero.abc-elearning.org/task/" + task._id}>
                                                {task.title}
                                            </a>
                                        </td>
                                        <td
                                            className="avatar"
                                            onClick={() =>
                                                window.open("https://hero.abc-elearning.org/user/" + task.assignee)
                                            }
                                        >
                                            <img src={handleGetInfoUser(task.assignee, Constants.GET_FIELD_AVATAR).avatar} />
                                            <span>
                                                {handleGetInfoUser(task.assignee, Constants.GET_FIELD_FIRSTNAME).firstName}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                        }

                    }
                }
                ))
        }))
}