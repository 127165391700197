import { INotification } from "../../shared/models/notification";
import {
    INotificationAction,
    NotificationTypes,
} from "../actions/notification_hero.action";

export interface INotificationState {
    loading: boolean;
    notifications?: INotification[];
    error?: any;
}

const initState: INotificationState = {
    loading: false,
};
const notificationState = (
    state = initState,
    action: INotificationAction
): INotificationState => {
    switch (action.type) {
        case NotificationTypes.GET_DATA_NOTIFICATION:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case NotificationTypes.GET_DATA_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    notifications: action.notifications ?? [],
                },
            };
        case NotificationTypes.GET_DATA_NOTIFICATION_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

export default notificationState;
