import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import Config from "../../shared/models/config";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import Url from "../../util/url";
import { AuthTypes, IAuthAction, loginFailed, loginSuccess, logout } from "../actions/auth.action";

const key = "userInfo";

function* loginFromSessionSaga() {
    const userInfo = getUserInfo();
    if (userInfo && userInfo._id) {
        try {
            let newUserInfo: IUserInfo | null = yield call(getUserInfoByIdAPI, userInfo._id);
            if (newUserInfo && newUserInfo.status == Config.STATUS_PUBLIC) {
                setUserInfo(userInfo);
                yield put(loginSuccess(newUserInfo));
            } else {
                yield put(logout());
            }
        } catch (e) {
            yield put(loginFailed("login from session error: " + e));
        }
    } else {
        yield put(loginFailed(null)); // đăng nhập không thành công nhưng không hiện lỗi
    }
}

async function getUserInfoByIdAPI(userId: string) {
    return await axios.get(Url("user/info/" + userId)).then((data: any) => {
        return data?.data?.success && data?.data?.response ? new UserInfo(data?.data?.response[0]) : null;
    });
}

async function loginAPI(email: string, password: string) {
    return await axios.post(Url("user"), { email, password }).then((data: any) => {
        return data?.data?.success && data?.data?.response[0] ? new UserInfo(data?.data?.response[0]) : null;
    });
}

async function loginByTokenAPI(token: string) {
    return await axios.post(Url("user/login-by-token"), { token }).then((data: any) => {
        return data?.data?.success && data?.data?.response[0] ? new UserInfo(data?.data?.response[0]) : null;
    });
}

function* loginByTokenSaga(action: IAuthAction) {
    if (action.token) {
        try {
            const oldUser = getUserInfo();
            if (!oldUser) {
                const userInfo: IUserInfo | null = yield call(loginByTokenAPI, action.token);
                if (userInfo) {
                    if (userInfo.status !== Config.STATUS_PUBLIC) {
                        yield put(loginFailed("Account has been disabled!"));
                    } else {
                        setUserInfo(userInfo);
                        yield put(loginSuccess(userInfo, action.nextUrl));
                    }
                } else {
                    yield put(loginFailed("User not existed!"));
                }
            } else {
                const newUserInfo: IUserInfo | null = yield call(getUserInfoByIdAPI, oldUser._id);
                if (newUserInfo) {
                    yield put(loginSuccess(newUserInfo, action.nextUrl));
                } else {
                    console.error("ERROR: cannot load new user info!");
                    yield put(loginSuccess(oldUser, action.nextUrl));
                }
            }
        } catch (err) {
            yield put(loginFailed("loginByTokenSaga ERROR: " + err));
        }
    } else {
        yield put(loginFailed("token empty!"));
    }
}

function getUserInfo() {
    let data = localStorage.getItem(key);
    return data ? new UserInfo(JSON.parse(data)) : null;
}

function setUserInfo(userInfo: IUserInfo) {
    localStorage.setItem(key, JSON.stringify(new UserInfo(userInfo)));
}

function* loginSaga(action: IAuthAction) {
    if (action.email && action.password) {
        try {
            const userInfo: IUserInfo | null = yield call(loginAPI, action.email, action.password);
            if (userInfo) {
                if (userInfo.status !== Config.STATUS_PUBLIC) {
                    yield put(loginFailed("Account has been disabled!"));
                } else {
                    setUserInfo(userInfo);
                    yield put(loginSuccess(userInfo));
                }
            } else {
                yield put(loginFailed("User not existed!"));
            }
        } catch (err) {
            yield put(loginFailed("loginSaga ERROR: " + err));
        }
    } else {
        yield put(loginFailed("account or password empty!"));
    }
}

export function* watchAuth() {
    yield takeLatest(AuthTypes.LOGIN, loginSaga);
    yield takeLatest(AuthTypes.LOGIN_FROM_SESSION, loginFromSessionSaga);
    yield takeLatest(AuthTypes.LOGIN_BY_TOKEN, loginByTokenSaga);
    // yield takeLatest(AuthTypes.LOGOUT, loginFromSessionSaga);
    // yield takeLatest(AuthTypes.LOGOUT_SUCCESS, loginFromSessionSaga);
}
