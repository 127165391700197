import React from "react";
import CompareRevenue from "./compare-revenue";
import CompareTraffic from "./compare-traffic";

const CompareComponent = () => {
    return (
        <div className="right-content">
            <CompareTraffic />
            <CompareRevenue />
        </div>
    );
};

export default CompareComponent;
