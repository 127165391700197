import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Constants from "../../../constant";
import { ISprint } from "../../../shared/models/sprint";
import { IUserExpectation } from "../../../shared/models/userExpectation";
import { IUserInfo } from "../../../shared/models/userInfo";
import { getUserDisciplineScore } from "../../../utils";
import MyChart, { Dataset } from "./../chart/MyChart";
const DisciplineChartArea = ({
    user,
    mapSprint,
    userReportData,
    expectation,
}: {
    user: IUserInfo;
    mapSprint: { [key: string]: ISprint };
    userReportData: any[];
    expectation: IUserExpectation[];
}) => {
    let { label, badAttendanceDataset, badOffdayDataset, disciplineDataset } = getDisciplineDataset(
        user,
        mapSprint,
        userReportData,
        expectation
    );
    return (
        <div className="discipline-chart-area">
            <h3 className="area-title">Thống kê kỷ luật của {user?.firstName + " " + user?.lastName}</h3>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MyChart
                            title="Điểm kỷ luật"
                            type="stackedbar-line"
                            xAxis={label}
                            dataSet={disciplineDataset}
                            stacked={{
                                xTitle: "sprint",
                                suggestedMax: 100,
                                yTitle: "điểm số",
                                unit: "điểm",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MyChart
                            title="Điểm trừ nghỉ khẩn cấp, không phép"
                            type="bar"
                            xAxis={label}
                            dataSet={badOffdayDataset}
                            stacked={{
                                suggestedMax: 100,
                                xTitle: "sprint",
                                yTitle: "điểm",
                                unit: "điểm",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MyChart
                            title="Điểm trừ chấm công"
                            type="bar"
                            xAxis={label}
                            dataSet={badAttendanceDataset}
                            stacked={{
                                suggestedMax: 100,
                                xTitle: "sprint",
                                yTitle: "điểm",
                                unit: "điểm trừ",
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

const getDisciplineDataset = (
    user: IUserInfo,
    mapSprint: { [key: string]: ISprint },
    userReportData: any[],
    expectation: IUserExpectation[]
) => {
    let userId = user._id;
    let disciplineDataset: Dataset[] = [];
    let badOffdayDataset: Dataset[] = [];
    let badAttendanceDataset: Dataset[] = [];
    let label: string[] = [];
    try {
        if (userReportData && user?._id) {
            let KPIdiscipline: Dataset = {
                label: "Mục tiêu điểm kỷ luật",
                dataSet: [],
                type: "line",
                color: Constants.BAR_COLOR[2],
            };

            let discipline: Dataset = {
                label: "Điểm kỷ luật",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[2],
            };

            let badAttendance: Dataset = {
                label: "Lỗi chấm công",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[7],
            };

            let badOffday: Dataset = {
                label: "Nghỉ khẩn cấp, không phép",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[7],
            };
            for (let expect of expectation) {
                KPIdiscipline.dataSet.push(expect.disciplinePoints);
            }

            for (let item of userReportData) {
                label.push(mapSprint[item.sprintId].name);
                let userDiscipline = getUserDisciplineScore({
                    currentSprint: mapSprint[item.sprintId],
                    users: [user],
                    newSprintReports: { [userId]: userReportData },
                    onlyReports: true,
                });
                let userData = userDiscipline[userId];
                let offDaysUnauthorized = userData.offDaysUnauthorized;

                let badOffDayScore = 0;
                for (let day of offDaysUnauthorized) {
                    let reason = day.reason;
                    let part = reason.split(",");
                    let scoreField = part.pop();
                    let score = parseInt(scoreField?.trim().split(" ")[1] ?? "0");
                    badOffDayScore += score;
                }

                badOffday.dataSet.push(badOffDayScore);
                badAttendance.dataSet.push(userData.minusScore - badOffDayScore);
                discipline.dataSet.push(100 - userData.minusScore);
            }
            disciplineDataset = [KPIdiscipline, discipline];
            badOffdayDataset = [badOffday];
            badAttendanceDataset = [badAttendance];
        }
        return {
            label,
            badAttendanceDataset,
            badOffdayDataset,
            disciplineDataset,
        };
    } catch (e) {
        console.log(e);
        return {
            label: [],
            badAttendanceDataset: [],
            badOffdayDataset: [],
            disciplineDataset: [],
        };
    }
};

export default DisciplineChartArea;
