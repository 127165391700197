import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getChildTasks } from "../../components/dashboard/task-preview";
import Config from "../../shared/models/config";
import { IProject } from "../../shared/models/project";
import { ISprint } from "../../shared/models/sprint";
import { IStatus } from "../../shared/models/status";
import { ITask, Task, TaskHistory } from "../../shared/models/task";
import { IUserInfo } from "../../shared/models/userInfo";
import Url from "../../util/url";
import { preHandleTasks } from "../../utils";
import {
    ITaskAction,
    TaskTypes,
    TaskUpdate,
    getAllTaskFailed,
    getAllTaskSuccess,
    getTaskByIdSuccess,
    updateCampaignsTasks,
    updateChildTaskStatusSuccess,
    updateParentTaskFailed,
    updateParentTaskSuccess,
    updateTaskFailed,
    updateTaskSuccess,
} from "../actions/task.action";
import { createdNewTask } from "../actions/web.action";
import { checkTasksInSprint } from "../../shared/utils";
import { addTaskToCampaign } from "../actions/campaign.action";

export async function getTaskAPI(taskId: string): Promise<ITask | null> {
    return await axios.get(Url("task/" + taskId)).then((data: any) => {
        if (data?.data?.success && data?.data?.response && data?.data?.response[0]) {
            return new Task(data?.data?.response[0]);
        }
        return null;
    });
}

export async function getAllTaskAPI(sprintId: string): Promise<ITask[] | undefined> {
    return await axios.get(Url("task/sprint/" + sprintId)).then((data: any) => {
        let tasks = data?.data?.response;
        if (data?.data?.success && tasks) {
            // console.log(tasks[5]);
            return tasks;
        }
        return undefined;
    });
}
export async function updateTaskAPI(
    task: ITask,
    userID?: string,
    childTaskUpdate?: any,
    campaignIds?: string[]
): Promise<ITask | null> {
    if (!!!task.createdUserId) task.createdUserId = userID;
    const data = new Task(task).toJson();
    console.log("tao task cho problem 1", data);
    return await axios
        .post(Url("task/new-update"), {
            task: data,
            domain: window.location.origin,
            userID,
            childTaskUpdate,
            campaignIds: campaignIds,
        })
        .then((data: any) => {
            if (data?.data && data?.data) {
                console.log("tao task cho problem 2", data);
                return new Task(data.data);
            }
            return null;
        });
}
const listSprint = [
    Config.COLUMN_STATUS.TO_DO.mongoId, //0
    Config.COLUMN_STATUS.IN_PROGRESS.mongoId, //1
    Config.COLUMN_STATUS.QA_TESTING.mongoId, //2
    Config.COLUMN_STATUS.REVIEW_CODE.mongoId, //3
    Config.COLUMN_STATUS.TO_DEPLOY.mongoId, //4
    Config.COLUMN_STATUS.PROD_VALIDATION.mongoId, //5
    Config.COLUMN_STATUS.DONE.mongoId, //6
];
function* updateTasksSaga(action: ITaskAction) {
    let task = action.task;
    let campaignIds = action.addToCampaignIds;
    let updateStatus = task.updateStatus;
    let updateSprint = task.updateSprint;
    let childTaskUpdate: TaskUpdate[] = [];
    const tasks: ITask[] = yield select((state: any) => state.taskState.tasks);
    try {
        if (task) {
            if (
                task.finishedDate == -1 && // chua duoc đanh dau la done nhung duoc keo sang cot tinh là done thi gan finisedDate
                (task?.status?._id === Config.COLUMN_STATUS.DONE.mongoId ||
                    task?.status?._id === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId)
            ) {
                task.finishedDate = new Date().getTime();
            } else if (
                checkTasksInSprint(task, task.sprint) &&
                task?.status?._id !== Config.COLUMN_STATUS.DONE.mongoId &&
                task?.status?._id !== Config.COLUMN_STATUS.PROD_VALIDATION.mongoId
            ) {
                task.finishedDate = -1;
            }
            const newTask = !task._id;
            const userID: string = yield select((state: any) => state.authState.user?._id ?? "");
            if (updateStatus) {
                let child_task = tasks.filter((item) => item.parentId == task._id);
                let queue = [...child_task];

                while (queue.length > 0) {
                    let t = queue.shift();
                    let arr = tasks.filter((item) => item.parentId === t?._id);
                    if (arr.length > 0) {
                        queue = [...queue, ...arr];
                        child_task = [...child_task, ...arr];
                    }
                }
                if (child_task.length) {
                    child_task.forEach((child) => {
                        let parentId = child.parent?._id ?? child.parentId;
                        let parent = tasks.find((i) => i._id === parentId);
                        if (parent) {
                            let parentStatus = task.status;
                            let indexParentStatusId = listSprint.indexOf(parentStatus._id);
                            let indexChildStatusId = listSprint.indexOf(child.statusId);
                            if (
                                indexChildStatusId != Config.COLUMN_STATUS.DONE.id &&
                                child.point != undefined
                                // && child.point > 0
                                // && child.deadline //chi keo theo task set point va deadline
                            ) {
                                if (indexParentStatusId > Config.COLUMN_STATUS.QA_TESTING.id) {
                                    if (
                                        indexChildStatusId < indexParentStatusId &&
                                        child.sprint //sprint bat buoc phai co
                                    ) {
                                        let histories = child?.histories ?? [];
                                        histories.push(
                                            new TaskHistory({
                                                field: "status",
                                                from: child.statusId,
                                                to: parentStatus._id,
                                                userID: userID,
                                                time: new Date(),
                                            })
                                        );
                                        let _taskUpdate: TaskUpdate = {
                                            _id: child._id,
                                            status: parentStatus,
                                            sprint: child.sprint,
                                            histories,
                                        };
                                        childTaskUpdate.push(_taskUpdate);
                                    }
                                }
                            }
                        }
                    });
                }
            }
            if (updateSprint) {
                let child_task = getChildTasks(task, tasks);
                if (child_task.length) {
                    child_task.forEach((child) => {
                        let histories = child?.histories ?? [];
                        if (child.status && task.sprint != child.sprint) {
                            histories.push(
                                new TaskHistory({
                                    field: "sprint",
                                    from: child.sprintId,
                                    to: task.sprint._id,
                                    userID: userID,
                                    time: new Date(),
                                })
                            );
                            let _taskUpdate: TaskUpdate = {
                                _id: child._id,
                                status: child.status,
                                sprint: task.sprint,
                                histories,
                            };
                            childTaskUpdate.push(_taskUpdate);
                        }
                    });
                }
            }
            const taskUpdated: ITask | null = yield call(
                updateTaskAPI,
                task,
                userID,
                childTaskUpdate.map((c) => {
                    return {
                        ...c,
                        status: c.status._id,
                        sprint: c.sprint._id,
                    };
                }),
                campaignIds
            );
            if (taskUpdated) {
                if (newTask) {
                    yield put(createdNewTask(taskUpdated));
                    yield put(addTaskToCampaign(campaignIds ?? [], [taskUpdated?._id ?? ""]));
                }
                yield put(updateTaskSuccess(taskUpdated));
                if (childTaskUpdate.length) {
                    yield put(updateChildTaskStatusSuccess(childTaskUpdate));
                }
            } else {
                yield put(updateTaskFailed("updateTasksSaga ERROR: task updated null"));
            }
        } else {
            yield put(updateTaskFailed("updateTasksSaga ERROR: task not changed"));
        }
    } catch (err) {
        console.log(err);
        yield put(updateTaskFailed("updateTasksSaga ERROR: " + err));
    }
}

function* getAllTaskSaga(action: ITaskAction) {
    if (action.sprintId) {
        try {
            const users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos);
            const sprints: ISprint[] = yield select((state: any) => state.sprintState.sprints);
            const allStatus: IStatus[] = yield select((state: any) => state.statusState.status);
            const projects: IProject[] = yield select((state: any) => state.projectState.projects);

            let tasks: ITask[] = yield call(getAllTaskAPI, action.sprintId);
            tasks = preHandleTasks(tasks, users, sprints, allStatus, projects);
            if (tasks) {
                yield put(getAllTaskSuccess(tasks));
            } else {
                yield put(getAllTaskFailed("User not existed!"));
            }
        } catch (err) {
            yield put(getAllTaskFailed("getAllTaskFailed ERROR: " + err));
        }
    } else {
        yield put(getAllTaskFailed("getAllTaskFailed sprintId empty!"));
    }
}
async function getTasksByIdsAPI(ids: String[]): Promise<ITask[] | null> {
    return await axios
        .post(Url("task/tasks-by-ids"), { ids })
        .then((response) => {
            let tasks = response?.data?.response;
            if (response?.data?.success && tasks) {
                return tasks;
            }
            return undefined;
        })
        .catch((error) => {
            console.log(error);
        });
}
function* getTasksByIdsSaga(action: ITaskAction) {
    const users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos);
    const sprints: ISprint[] = yield select((state: any) => state.sprintState.sprints);
    const allStatus: IStatus[] = yield select((state: any) => state.statusState.status);
    const projects: IProject[] = yield select((state: any) => state.projectState.projects);
    let ids = action.taskIds;
    let campaignIds = action.campaignIds ?? [];
    if (ids?.length) {
        let result: ITask[] | null = yield call(getTasksByIdsAPI, ids);
        if (result) {
            result = preHandleTasks(result, users, sprints, allStatus, projects);
            for (let cp of campaignIds) {
                let value = cp.listTask;
                value = value.map((v: any) => result?.find((r) => r._id === v)).filter((v: any) => v);
                cp.listTask = value;
            }
            yield put(updateCampaignsTasks(campaignIds));
        }
    }
}

function* getTaskByIdSaga(action: ITaskAction) {
    let id = action.taskId;
    if (!!id) {
        let task: ITask = yield call(getTaskAPI, id);
        if (task) {
            console.log("get task by id 2", task);
            yield put(getTaskByIdSuccess(task));
        }
    }
}

async function updateParentAPI(listParentId: string[],parentId : string,historiesUpdate : any,type : string): Promise<{ success : boolean}> {
    return await axios.post(Url("task/update-parent-task"), { listParentId: listParentId , parentId  : parentId , historiesUpdate : historiesUpdate ,type : type }).then((res) => {
        if (res.status == 200 && res.data) {
            return res.data;
        }
        return {};
    });
}

function* updateParentTaskSaga(action: ITaskAction) {
    const listParentId = action.listParentId;
    const parentId = action.parentId;
    const historiesUpdate = action.historiesUpdate;
    const type = action.typeTask;
    if (listParentId && parentId && historiesUpdate && type) {
        try {
            const satusUpdate: { success : boolean} = yield call(updateParentAPI,listParentId,parentId,historiesUpdate,type);
            if (satusUpdate.success) {
                yield put(updateParentTaskSuccess(listParentId,parentId,historiesUpdate,type));
            } else {
                yield put(
                    updateParentTaskFailed("listParentIds not existed!")
                );
            }
        } catch (err) {
            yield put(
                updateParentTaskFailed("listParentIds ERROR: " + err)
            );
        }
    } else {
        yield put(
            updateParentTaskFailed(
                "update listParentIds ERROR: listParentIds not found!"
            )
        );
    }
}

export function* watchTask() {
    yield takeLatest(TaskTypes.GET_ALL_TASK_BY_SPRINT, getAllTaskSaga);
    yield takeLatest(TaskTypes.UPDATE_TASK, updateTasksSaga);
    yield takeLatest(TaskTypes.GET_TASKS_BY_IDS, getTasksByIdsSaga);
    yield takeLatest(TaskTypes.GET_TASK_BY_ID, getTaskByIdSaga);
    yield takeLatest(TaskTypes.UPDATE_PARENT_TASK, updateParentTaskSaga);
}
