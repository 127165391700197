import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import { IUserInfo } from "../../shared/models/userInfo";
import { IWfhDayOffDay } from "../../shared/models/wfhDayoffDayRegister";
import { IWorkingTime } from "../../shared/models/workingTime";

export enum WorkingTimeTypes {
    GET_WORKING_TIME = "GET_WORKING_TIME",
    GET_WORKING_TIME_FAILED = "GET_WORKING_TIME_FAILED",
    GET_WORKING_TIME_SUCCESS = "GET_WORKING_TIME_SUCCESS",
    UPDATE_WORKING_TIME_SUCCESS = "UPDATE_WORKING_TIME_SUCCESS",
    GET_ALL_TIME_ATTENDANCE_SUCCESS = "GET_ALL_TIME_ATTENDANCE_SUCCESS",
    ADD_DAYOFF_SUCCESS = "ADD_DAYOFF_SUCCESS",
    DELETE_DAYOFF_SUCCESS = "DELETE_DAYOFF_SUCCESS",
}
export interface IDuration {
    start: number;
    end: number;
}
export interface IWorkingTimeAction {
    type: WorkingTimeTypes;
    offDaysWfhDays?: IOffDaysWfhDays[];
    error?: any;
    data?: IDuration;
    workingTime?: IWorkingTime[];
    dayoffs?: IWfhDayOffDay;
    deletedList?: string[];
    typeData?: string;
    userInfo?: IUserInfo;
}

export const getAllWorkingTimeInfo = (data: IDuration, userInfo?: IUserInfo): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.GET_WORKING_TIME,
        data,
        userInfo,
    };
};

export const getAllWorkingTimeInfoSuccess = ({
    offDaysWfhDays,
    workingTime,
}: {
    offDaysWfhDays: IOffDaysWfhDays[];
    workingTime: IWorkingTime[];
}): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.GET_WORKING_TIME_SUCCESS,
        offDaysWfhDays,
        workingTime,
    };
};

export const getAllWorkingTimeInfoFailed = (error: any): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.GET_WORKING_TIME_FAILED,
        error,
    };
};

export const updateWorkingTimeSuccessAction = (workingTime: IWorkingTime[]): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.UPDATE_WORKING_TIME_SUCCESS,
        workingTime,
    };
};

export const getAllTimeAttendanceSuccess = (workingTime: IWorkingTime[]): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.GET_ALL_TIME_ATTENDANCE_SUCCESS,
        workingTime,
    };
};

export const addDayoffSuccess = (dayoffs: IWfhDayOffDay, typeData: string): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.ADD_DAYOFF_SUCCESS,
        dayoffs,
        typeData,
    };
};

export const deleteDayoffSuccess = (deletedList: string[], typeData: string): IWorkingTimeAction => {
    return {
        type: WorkingTimeTypes.DELETE_DAYOFF_SUCCESS,
        deletedList,
        typeData,
    };
};
