import { IBasic } from "./basic"

export interface IProject extends IBasic {
    title: string
    ownerID: string
}

export class Project implements IProject {
    _id: any
    title: string
    ownerID: string

    constructor(props: any = {}) {
        this._id = props._id ?? "";
        this.title = props.title ?? "";
        this.ownerID = props.ownerID ?? "";
    }

    static isObject(item: any): boolean {
        if(item) {
            if(item.constructor.name == this.name) {
                return true;
            }
            const list = ["_id", "title", "ownerID"];
            let count = 0;
            const itemList = Object.getOwnPropertyNames(item);
            for(let attr of itemList) {
                if(list.includes(attr)) {
                    count++;
                }
            }
            return count == list.length && itemList.length == count;
        }
        return false;
    }
}