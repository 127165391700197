import { FC } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import defaultAvatar from "../../../../../assets/images/default-avatar.png";
import { IUserGroup } from "../../../../../shared/models/userGroup";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import { SelectOption, convertUserOptions } from "../../../../../utils";
import MyAvatar from "../../../../avatar";

const noUserLabel = "No User";

interface SelectUserProps {
    ids: string[];
    onSelected: (ids: string[]) => void;
    isMulti?: boolean;
    require?: boolean;
    type?: "leaddev" | "assignee";
    disabled?: boolean;
}

interface SelectUserContentProps extends SelectUserProps {
    users?: IUserInfo[];
    userOptions: SelectOption[];
}

const SelectUserWidget: FC<SelectUserProps> = (props) => {
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups ?? []);
    const filterUsers = (user: IUserInfo) => {
        if (props.type == "assignee") {
            let check = 0;
            userGroups.forEach((group: IUserGroup) => {
                if (group.users.find((u) => u.userId === user._id) && group.users.find((u) => u.userId === userInfo._id))
                    check++;
            });
            if (check > 0) return true;
        } else if (props.type === "leaddev") {
            return user.position.toLowerCase() == "lead dev";
        } else return true;
        return false;
    };
    let userOptions = convertUserOptions(users.filter((u) => filterUsers(u)));
    if (!props.require) {
        userOptions = [
            {
                avatar: defaultAvatar,
                label: noUserLabel,
                value: "",
            },
            ...userOptions,
        ];
    }
    if (props.isMulti) {
        return <SelectMultiUser {...props} userOptions={userOptions} />;
    }
    return <SelectUser {...props} userOptions={userOptions} />;
};

const SelectUser: FC<SelectUserContentProps> = ({ userOptions, ids, onSelected, disabled = false }) => {
    return (
        <Select
            className="select-user"
            formatOptionLabel={getLabel}
            options={userOptions}
            isDisabled={disabled}
            onChange={(value) => {
                const _id = value?.value;
                if (_id) {
                    onSelected([_id]);
                }
            }}
            value={userOptions.find((user) => user.value === ids[0])}
        />
    );
};

const SelectMultiUser: FC<SelectUserContentProps> = ({ userOptions, ids, onSelected, disabled = false }) => {
    return (
        <Select
            isMulti
            isClearable={false}
            closeMenuOnSelect={false}
            className="select-user"
            formatOptionLabel={getLabel}
            options={userOptions}
            isDisabled={disabled}
            onChange={(newValue, action) => {
                if (action.action === "clear") {
                    onSelected([]);
                } else if (action.action === "remove-value") {
                    const index = ids.indexOf(action.removedValue.value);
                    if (index > -1) {
                        ids.splice(index, 1);
                        onSelected([...ids]);
                    }
                } else if (action.action === "select-option" && action.option) {
                    const index = ids.indexOf(action.option.value);
                    if (index == -1) {
                        onSelected([...ids, action.option.value]);
                    }
                }
            }}
            value={userOptions.filter((u) => ids.includes(u.value))}
        />
    );
};

const getLabel: FC<SelectOption> = ({ avatar, label }) => {
    let name: string | string[] = label?.split(" ") ?? "";
    const firstName = name.shift() ?? "";
    firstName && name.push(firstName);
    name = name.join(" ");
    return (
        <div style={{ alignItems: "center", display: "flex" }}>
            <span style={{ marginRight: "0.5em" }}>
                <MyAvatar url={avatar} size={20} />
            </span>
            <span style={{ fontSize: 14 }}>{label === noUserLabel ? noUserLabel : name}</span>
        </div>
    );
};

export default SelectUserWidget;
