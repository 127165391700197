const DiamondIcon = ({ fill = "#00FAFF" }) => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.00033 11.8327C2.77858 11.8327 0.166992 9.2211 0.166992 5.99935C0.166992 2.7776 2.77858 0.166016 6.00033 0.166016C9.22208 0.166016 11.8337 2.7776 11.8337 5.99935C11.8337 9.2211 9.22208 11.8327 6.00033 11.8327ZM6.00033 10.666C7.238 10.666 8.42499 10.1744 9.30016 9.29918C10.1753 8.42401 10.667 7.23703 10.667 5.99935C10.667 4.76167 10.1753 3.57469 9.30016 2.69952C8.42499 1.82435 7.238 1.33268 6.00033 1.33268C4.76265 1.33268 3.57566 1.82435 2.70049 2.69952C1.82532 3.57469 1.33366 4.76167 1.33366 5.99935C1.33366 7.23703 1.82532 8.42401 2.70049 9.29918C3.57566 10.1744 4.76265 10.666 6.00033 10.666V10.666ZM4.25033 3.66602H7.75033L9.20866 5.70768L6.00033 8.91602L2.79199 5.70768L4.25033 3.66602ZM4.85116 4.83268L4.31449 5.58518L6.00033 7.27102L7.68616 5.58518L7.14949 4.83268H4.85116V4.83268Z"
            fill={fill}
        />
    </svg>
);
export default DiamondIcon;
