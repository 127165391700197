import { IBasic } from "./basic";

export interface INotification extends IBasic {
    userID: string;
    title: string;
    shortID: string;
    longID: string;
    message: string;
    sprintID: string;
    timestamp: string;
}
export class Notification implements INotification {
    _id: string;
    userID: string;
    title: string;
    shortID: string;
    longID: string;
    message: string;
    sprintID: string;
    timestamp: string;
    constructor(props: any = {}) {
        this._id = props._id;
        this.userID = props.userID ?? "";
        this.title = props.title ?? "";
        this.shortID = props.shortID ?? "";
        this.longID = props.longID ?? "";
        this.message = props.message ?? "";
        this.sprintID = props.sprintID ?? "";
        this.timestamp = props.timestamp ?? "";
    }
}
