import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getFlatForm } from "../../../config_data_analytic";
import Constants from "../../../constant";
import { IAppInfo } from "../../../models/appinfo.model";
import { ICertificates } from "../../../models/certificates.model";
import WebAppIndex from "../webAppIndex";
import "./index.scss";

interface Data {
    name: string;
    questionNumber: number;
    proPrice: number;
    lockPart: number;
    lockTest: number;
    version: string;
}

const Infomation = ({ certificates }: { certificates: ICertificates }) => {
    // const listApp: IAppInfo[] = useSelector(
    //   (state: any) => state.dashBoardState.appInfos
    // );
    // useEffect(() => {
    //   dispatch(getInfoAppsAction(appInfo.appID));
    // }, []);

    function createData(
        name: string,
        questionNumber: number,
        proPrice: number,
        lockPart: number,
        lockTest: number,
        version: string
    ): Data {
        return { name, questionNumber, proPrice, lockPart, lockTest, version };
    }

    let rows = certificates.appInfos?.map((app: IAppInfo) => {
        return createData(
            app.name + " " + getFlatForm(app.platformId),
            certificates.nbQuestions,
            app.price,
            app.lockPart,
            app.lockTest,
            app.version
        );
    });

    return (
        <div className="infomation-component">
            <TableContainer component={Paper} className="table-infomation">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="app">
                                <img
                                    width={"24px"}
                                    height={"24px"}
                                    src={certificates.logo}
                                    alt="logo"
                                />
                                {certificates.name}
                            </TableCell>
                            <TableCell align="right">Tổng số câu hỏi</TableCell>
                            <TableCell align="right">Giá gói pro</TableCell>
                            <TableCell align="right">Khoá từ part</TableCell>
                            <TableCell align="right">Khoá từ test</TableCell>
                            <TableCell align="right">Version</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    {row.questionNumber}
                                </TableCell>
                                <TableCell align="right">
                                    {row.proPrice + " $"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.lockPart >= 0
                                        ? row.lockPart + 1
                                        : "Không khoá"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.lockTest > 0
                                        ? row.lockTest + 1
                                        : "Không khoá"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.version}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <WebAppIndex currentCertificates={certificates} /> */}
        </div>
    );
};

export default Infomation;
