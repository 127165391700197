import { Campaign, ICampaign } from "../../shared/models/campaign";
import { CampaignTypes, ICamPaignAction } from "../actions/campaign.action";

export interface ICampaignState {
    loading: boolean;
    error: any;
    campaigns: ICampaign[];
}

const initState: ICampaignState = {
    loading: false,
    error: null,
    campaigns: [],
};

const campaignState = (state = initState, action: ICamPaignAction): ICampaignState => {
    switch (action.type) {
        case CampaignTypes.GET_ALL_CAMPAIGN:
            return {
                ...initState,
                ...{
                    loading: true,
                },
            };
        case CampaignTypes.GET_ALL_CAMPAIGN_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    campaigns: action.campaigns ?? [],
                },
            };
        case CampaignTypes.GET_ALL_CAMPAIGN_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case CampaignTypes.UPDATE_CAMPAIGN_SUCCESS:
            if (action.campaigns) {
                for (let campaign of action.campaigns) {
                    let index = state.campaigns.findIndex((u) => u._id == campaign._id);
                    if (index > -1) {
                        state.campaigns[index] = new Campaign({
                            ...state.campaigns[index],
                            ...campaign,
                        });
                    } else {
                        state.campaigns.push(campaign);
                    }
                }
            }
            return {
                ...state,
            };
        case CampaignTypes.DELETE_CAMPAIGN_SUCCESS:
            if (action.campaigns) {
                for (let campaign of action.campaigns) {
                    let index = state.campaigns.findIndex((u) => u._id == campaign._id);
                    if (index > -1) {
                        state.campaigns.splice(index, 1);
                    }
                }
            }
            return {
                ...state,
            };
        case CampaignTypes.ARCHIVE_CAMPAIGN_SUCCESS:
            let archiveCampaignId = action.campaignId;
            let campaigns = state.campaigns;
            for (let campaign of campaigns) {
                if (campaign._id === archiveCampaignId) campaign.archive = !campaign.archive;
            }
            state.campaigns = campaigns;
            return { ...state };
        case CampaignTypes.ADD_TASK_TO_CAMPAIGN:
            let campaignData = state.campaigns;
            let campaignIds = action.campaignIds;
            let taskIds = action.taskIds;
            if (campaignIds && taskIds) {
                let updateCampaign = campaignData.map((c) => {
                    if (!!campaignIds?.find((cid) => cid === c._id)) {
                        taskIds?.forEach((t) => {
                            if (!c.listTaskIDs.find((tid) => tid === t)) {
                                c.listTaskIDs.push(t);
                            }
                        });
                    }
                    return c;
                });
                return { ...state, campaigns: updateCampaign };
            }
            return state;
        default:
            return state;
    }
};

export default campaignState;
