import { FC, useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ITask, ITaskHistory, Task, TaskHistory } from "../../../../../shared/models/task";
import Select from "react-select";
import { convertTaskParentOptions } from "../../../../../utils";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { updateParentTask } from "../../../../../redux/actions/task.action";
import { toast } from "react-toastify";
import "./style.scss";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import List from "@mui/material/List";

export interface SelectOption {
    value: string;
    label: string;
    avatar: string;
}
const ModalMoveSubtask: FC<{
    checkedItems: { [key: string]: boolean };
    onHide: () => void;
    listSubtask: ITask[];
    tasks: ITask[];
    task: ITask;
    clearDataSelect : () => void;
    onChange: (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isSelectAll: boolean;
    listSubtaskId: string[];
}> = ({ clearDataSelect,listSubtaskId, isSelectAll, handleSelectAllChange, onHide, listSubtask, tasks, task, onChange, checkedItems }) => {
    const [selected, setSelected] = useState<SelectOption | undefined>();
    const dispatch = useDispatch();
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);

    const handleSelectChange = (option: SelectOption | null) => {
        if (option === null) {
            setSelected(undefined);
        } else {
            setSelected(option);
        }
    };

    const listTasFilter: ITask[] = tasks.filter((task) => {
        return listSubtaskId.includes(task._id);
    });
    const taskFilter = tasks.find((task) => task._id == selected?.value);
    let listTasFilterName = listTasFilter.map((t) => t.title).join(", ");
    const getChangedInfo = (taskForm: ITask, taskTo: ITask) => {
        let changes: ITaskHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if (taskForm && taskTo) {
            changes.push(
                new TaskHistory({
                    field: "Move Task",
                    from: listTasFilterName,
                    to: taskFilter?.title,
                    userID,
                    time,
                })
            );
        }
        return changes;
    };

    const onSave = () => {
        let valueParent: string | undefined = selected?.value;
        if (!listSubtaskId[0]) {
            toast("Chưa chọn task cần chuyển !", { position: "top-center" });
            return;
        }
        if (!valueParent) {
            toast("Chưa chọn task chuyển đến !", { position: "top-center" });
            return;
        }

        if (taskFilter) {
            const changes = getChangedInfo(task, taskFilter);
            const historiesTaskForm = { history: (changes ?? []).concat(task.histories ?? []), id: task._id };
            const historiesTaskTo = { history: (changes ?? []).concat(taskFilter.histories ?? []), id: taskFilter._id };
            const historiesUpdate = [historiesTaskForm, historiesTaskTo];

            if (listSubtaskId && valueParent != undefined) {
                dispatch(updateParentTask(listSubtaskId, valueParent, historiesUpdate, "move"));
                toast("Chuyển task thành công !", { position: "top-center" });
                onChange("")
                onHide();
                clearDataSelect();
            }
        }
    };
    return (
        <Modal show={true} size="xl" onHide={onHide} centered>
            <Modal.Header>
                <Modal.Title>Chuyển Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        margin: "10px",
                    }}
                >
                    <div
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            marginBottom: "20px",
                            borderRadius: "10px",
                        }}
                    >
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <h5>Chọn Task Muốn Chuyển :</h5>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Chọn tất cả : </span>
                                    <Checkbox checked={isSelectAll} onChange={handleSelectAllChange}/>
                                </div>
                            </div>
                            <div style={{
                                width : "100%",
                                alignItems : "center",
                            }}>
                                <div style={{
                                    display : "flex",
                                    borderRadius: "6px",
                                    border: "1px solid gray",
                                    marginLeft : "15px",
                                    marginRight :" 30px",
                                    
                                }}>
                                    <h5 style={{paddingLeft : "250px",marginTop:"15px",marginBottom:"15px"}}>Tên task</h5>
                                    <h5 style={{paddingLeft : "475px",marginTop:"15px",marginBottom:"15px"}}>Trạng thái</h5>
                                </div>
                            </div>
                            <List sx={{ overflow: "auto", maxHeight: 420, padding: 2 }}>
                                {listSubtask?.map((subtask, index) => (
                                    <div
                                        key={index + subtask._id}
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            background: "white",
                                            alignItems: "center",
                                            borderRadius: "6px",
                                            border: "1px solid gray",
                                            marginBottom: "15px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Checkbox
                                                style={{padding : "10px"}}
                                                checked={checkedItems[subtask._id] || false}
                                                onChange={onChange(subtask._id)}
                                            />
                                            <span style={{ fontSize: "16px", padding: "10px" }}>
                                                {subtask.shortId} {": "} {subtask.title}
                                            </span>
                                        </div>
                                        <div style={{ width: "calc(200px - 10px)", alignItems: "start" }}>
                                            <span style={{ fontSize: "16px", padding: "10px", color: subtask?.status?.color }}>
                                                {subtask.status?.title}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </List>
                        </div>
                    </div>

                    {listSubtaskId.length > 0 && (
                        <div
                            style={{
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                borderRadius: "10px",
                            }}
                        >
                            <div style={{ padding: "15px" }}>
                                <h5>Chọn Task Chuyển Đến :</h5>
                                <div
                                    style={{
                                        margin: "20px",
                                    }}
                                >
                                    <Select
                                        value={selected}
                                        options={convertTaskParentOptions(tasks, task, listSubtaskId)}
                                        onChange={(newValue) => handleSelectChange(newValue)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <ModalFooter>
                <Button onClick={onSave}>Chuyển</Button>
                <Button onClick={onHide} variant="secondary">
                    Hủy
                </Button>
            </ModalFooter>
        </Modal>
    );
};
export default ModalMoveSubtask;
