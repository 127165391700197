import React from "react";

const SearchIconSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon"
    >
      <path
        d="M23.7099 22.29L19.9999 18.61C21.44 16.8144 22.1374 14.5353 21.9487 12.2413C21.76 9.94733 20.6996 7.81281 18.9854 6.27667C17.2713 4.74053 15.0337 3.91954 12.7328 3.9825C10.4319 4.04546 8.24263 4.98759 6.61505 6.61517C4.98747 8.24275 4.04534 10.4321 3.98237 12.7329C3.91941 15.0338 4.74041 17.2714 6.27655 18.9855C7.81269 20.6997 9.94721 21.7601 12.2412 21.9488C14.5352 22.1375 16.8143 21.4401 18.6099 20L22.2899 23.68C22.3829 23.7738 22.4935 23.8481 22.6153 23.8989C22.7372 23.9497 22.8679 23.9758 22.9999 23.9758C23.1319 23.9758 23.2626 23.9497 23.3845 23.8989C23.5063 23.8481 23.6169 23.7738 23.7099 23.68C23.8901 23.4936 23.9909 23.2444 23.9909 22.985C23.9909 22.7257 23.8901 22.4765 23.7099 22.29V22.29ZM12.9999 20C11.6154 20 10.2621 19.5895 9.11091 18.8203C7.95977 18.0511 7.06256 16.9579 6.53275 15.6788C6.00293 14.3997 5.86431 12.9923 6.13441 11.6344C6.4045 10.2765 7.07119 9.02925 8.05016 8.05028C9.02912 7.07131 10.2764 6.40463 11.6343 6.13453C12.9921 5.86443 14.3996 6.00306 15.6787 6.53287C16.9578 7.06268 18.051 7.95989 18.8202 9.11103C19.5894 10.2622 19.9999 11.6156 19.9999 13C19.9999 14.8565 19.2624 16.637 17.9497 17.9498C16.6369 19.2625 14.8564 20 12.9999 20V20Z"
        fill="#495467"
        fillOpacity="0.52"
      />
    </svg>
  );
};

export default SearchIconSvg;
