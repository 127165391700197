export enum TestSectionTypes {
    GET_ALL_TEST_SECTION= 'GET_ALL_TEST_SECTION',
    GET_ALL_TEST_SECTION_SUCCESS= 'GET_ALL_TEST_SECTION_SUCCESS',
    GET_ALL_TEST_SECTION_FAILED= 'GET_ALL_TEST_SECTION_FAILED',
    ADD_TEST_SECTION= 'ADD_TEST_SECTION',
    UPDATE_TEST_SECTIONS= 'UPDATE_TEST_SECTIONS',
    DELETE_TEST_SECTION= 'DELETE_TEST_SECTION'
}

export interface ITestSectionAction {
    type: TestSectionTypes;
    error?: any;
    data?: any;
}

export const getAllTestSection = (data: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.GET_ALL_TEST_SECTION,
        data,
    };
};

export const getAllTestSectionSuccess = (data: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.GET_ALL_TEST_SECTION_SUCCESS,
        data,
    };
};

export const getAllTestSectionFailed = (error: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.GET_ALL_TEST_SECTION_FAILED,
        error,
    };
};

export const addTestSection = (data: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.ADD_TEST_SECTION,
        data,
    };
};

export const updateTestSections = (data: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.UPDATE_TEST_SECTIONS,
        data,
    };
};

export const deleteTestSection = (data: any): ITestSectionAction => {
    return {
        type: TestSectionTypes.DELETE_TEST_SECTION,
        data,
    };
};
