import { FC, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { login, loginByToken, loginFromSession } from "../../redux/actions/auth.action";
import { IAuthState } from "../../redux/reducers/auth.reducer";
import { isValidateEmail, isValidatePassword } from "../../shared/utils";
import LoadingWidget from "../LoadingWidget";

// dispatch(loginFromSession());
//         const authState = useSelector((state) => state.authState);
//     console.log("authState", authState)

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(history.location.search);
    const token = urlSearchParams.get("token");
    const nextUrl = urlSearchParams.get("nextUrl");
    const authState: IAuthState = useSelector((state: any) => state.authState);
    const loggedIn = authState.status == "success" && !!authState.user && !!authState.user._id;
    // console.log("LoginPage loggedIn", loggedIn, token, nextUrl)
    useEffect(() => {
        if(!loggedIn) {
            if(token) {
                dispatch(loginByToken(token, nextUrl));
            } else {
                dispatch(loginFromSession());
            }
        }
    }, [token, nextUrl]);
    if(authState.status == "default" || authState.status == "loading") {
        return <LoadingWidget />
    }
    if(loggedIn) {
        // console.log("LoggedInView", authState.nextUrl)
        return <LoggedInView nextUrl={authState.nextUrl} />
    }
    return <NotLoggedInView errorStr={authState.error} />
}

const NotLoggedInView:FC<({ errorStr?: string })> = ({ errorStr }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(errorStr);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const handleLogin = () => {
        let email = emailRef?.current?.value ?? "";
        let password = passwordRef?.current?.value ?? "";
        if(isValidateEmail(email) && isValidatePassword(password)) {
            dispatch(login(email, password));
        } else {
            if(!isValidateEmail(email)) {
                setError("Email không đúng!");
            } else if(!isValidatePassword(email)) {
                setError("Mật khẩu không đúng!");
            }
        }
    }

    return (
        <div className="login">
            <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email" 
                        name="email" 
                        ref={emailRef}
                        onKeyDown={(e) => e.code == "Enter" && handleLogin()}
                     />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                >
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder="Password" 
                        name="password" 
                        ref={passwordRef} 
                        onKeyDown={(e) => e.code == "Enter" && handleLogin()} 
                    />
                </Form.Group> 
                <p style={{ color: "red", padding: "20px 0" }}>{error}</p>
                <Button variant="primary" type="submit" onClick={handleLogin}>
                    Đăng nhập
                </Button>
            </div>
        </div>
    );
}

const LoggedInView:FC<({ nextUrl?: string | null })> = ({ nextUrl }) => {
    useEffect(() => {
        if(nextUrl) {
            window.location.href = nextUrl;
        } else {
            window.location.href = "/";
        }
    }, [nextUrl]);
    return <div></div>
}

// const View:FC<({ userInfo: IUserInfo })> = ({ userInfo }) => {
//     const history = useHistory();
//     const dispatch = useDispatch();
//     const urlSearchParams = new URLSearchParams(history.location.search);
//     const token = urlSearchParams.get("token");
//     const nextUrl = urlSearchParams.get("nextUrl");
//     useEffect(() => {
//         if (token && nextUrl) {
//           if (userInfo._id) {
//             if(window?.location?.href) {
//                 window.location.href = nextUrl;
//             }
//           } else {
//             dispatch(loginByToken(token));
//             if(window?.location?.href) {
//                 window.location.href = nextUrl;
//             }
//           }
//         }
//     }, [token, nextUrl]);
//     const loggedIn = userInfo && userInfo._id;
//     return (
//         <div className="login">
//             {userId === null || userId === undefined ? (
                
//             ) : (
//                 <Redirect to={{ pathname: "/" }} />
//             )}
//         </div>
//     );
// }

// const _LoginWidget = () => {
//     const history = useHistory();
//     let userInfo = JSON.parse(localStorage.getItem("userInfo"));
//     const userId = userInfo?._id;
//     const [email, setEmail] = useState([]);
//     const [password, setPassword] = useState([]);
//     const [error, setError] = useState('');
//     const handleChangeEmail = (e) => {
//         setEmail(e.target.value);
//     };
//     const handleChangePw = (e) => {
//         setPassword(e.target.value);
//     };

//     const handleLogin = async (e) => {
//         e.preventDefault();
//         login(email, password);
//     };

//     const loginSuccess = (user, nextUrl) => {
//         const dataInfo = {
//             _id: user._id,
//             role: user.role,
//         };
//         localStorage.setItem("userInfo", JSON.stringify(dataInfo));
//         if (nextUrl) {
//             window.location.href = nextUrl;
//         } else {
//             if (isPartner(dataInfo)) {
//                 history.push({ pathname: "/dashboard-data-analytic", state: user });
//             } else if (isCreateBlog(dataInfo)) {
//                     history.push({ pathname: "/create-blog", state: user });
//                 } else {
//                     history.push({ pathname: "/", state: user });    
//                 }
//             window.location.reload(true);
//         }
//     };

//     const login = async (email, password) => {
//         const user = {
//             email: email,
//             password: password,
//         };
//         if (user) {
//             try {
//                 const res = await axios.post(Url("user"), user);
//                 if (res.data.success && res?.data?.response[0]) {
//                     if(res?.data?.response[0]?.disable){
//                         setError("Account has been disabled");
//                     } else {
//                         loginSuccess(res?.data?.response[0]);
//                     }
//                 } else {
//                     setError("Invalid Email address or Password");
//                 }
//             } catch (error) {
//                 console.log(error);
//             }
//         }
//     };
//     const urlSearchParams = new URLSearchParams(history.location.search);
//     const token = urlSearchParams.get("token");
//     const nextUrl = urlSearchParams.get("nextUrl");
    
//     useEffect(() => {
//       if (token) {
//         if (userId) {
//           window.location.href = nextUrl;
//         } else {
//           try {
//             axios
//               .post(Url("user/login-by-token"), { token })
//               .then((res) => {
//                 if (res.data.success && res?.data?.response[0]) {
//                   loginSuccess(res?.data?.response[0], nextUrl);
//                 } else {
//                   history.push({ search: "" });
//                   setError("Invalid Email address or Password");
//                 }
//               })
//               .catch((e) => {
//                 history.push({ search: "" });
//                 setError("Invalid Email address or Password");
//               });
//           } catch (error) {
//             history.push({ search: "" });
//             console.log(error);
//           }
//         }
//       }
//     }, [token, userId]);

//     return (
//         <div className="login">
//             {userId === null || userId === undefined ? (
//                 <div>
//                     <Form onSubmit={handleLogin}>
//                         <Form.Group className="mb-3" controlId="formBasicEmail">
//                             <Form.Label>Email address</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 placeholder="Enter email"
//                                 name="email"
//                                 onChange={handleChangeEmail}
//                             />
//                         </Form.Group>

//                         <Form.Group
//                             className="mb-3"
//                             controlId="formBasicPassword"
//                             name="password"
//                             onChange={handleChangePw}
//                         >
//                             <Form.Label>Password</Form.Label>
//                             <Form.Control type="password" placeholder="Password" />
//                         </Form.Group>
//                             <p style={{ color: "red" }}>{error}</p>
//                         <Button variant="primary" type="submit">
//                             Đăng nhập
//                         </Button>
//                     </Form>
//                 </div>
//             ) : (
//                 <Redirect to={{ pathname: "/" }} />
//             )}
//         </div>
//     );
// };

export default LoginPage;
