export interface IAppInfo {
    id: number;
    appID: number;
    name: string;
    appNameId: string;
    gaID: number;
    platformId: number;
    appStoreID: string;
    appShortName: string;
    packageNameAndroid: string;
    packageNameIOS: string;
    averageUserRating: number;
    logo: string;
    hasPro: boolean;
    darkMode: boolean;
    lockTest: number;
    lockPart: number;
    price: number;
    version: string;
    test_id: number;

    bucket: string;
    hasState: boolean;

    priceInfo: string;
    totalQuestions: number;
    versionWeb: string;
    versionIOS: string;
    versionAndroid: string;
    platform: number;

    // descriptionSEO: string;
    // keywordSEO: string;
    // title: string;
    // description: string;

    // usingFeaturePro: boolean;
    // usingMathJax: boolean;
    // parentId: number;
    // stateName?: string;
    // ip?: string;
}
export class AppInfo implements IAppInfo {
    id: number;
    appID: number;
    name: string;
    appNameId: string;
    gaID: number;
    platformId: number;
    appStoreID: string;
    appShortName: string;
    packageNameAndroid: string;
    packageNameIOS: string;
    averageUserRating: number;
    logo: string;
    hasPro: boolean;
    lockTest: number;
    lockPart: number;
    price: number;
    darkMode: boolean;
    version: string;
    test_id: number;

    bucket: string;
    hasState: boolean;

    priceInfo: string;
    totalQuestions: number;
    versionWeb: string;
    versionIOS: string;
    versionAndroid: string;
    platform: number;

    constructor(object: any = {}) {
        this.id = object.id;
        this.appID = object.appID ?? parseInt(object.appID + "");
        this.name = object.name ?? "";
        this.appNameId = object.appNameId ?? "";
        this.gaID = object.gaID ?? 0;
        this.platformId = object.platform_id ?? object.platformId ?? 0;
        this.appStoreID = object.appStoreID ?? "";
        this.appShortName = object.appShortName ?? "";
        this.packageNameIOS = object.packageNameIOS ?? "";
        this.packageNameAndroid = object.packageNameAndroid ?? "";
        this.averageUserRating = object.averageUserRating ?? 0;
        this.logo = object.logo ?? "";
        this.hasPro = object.hasPro ?? "";
        this.lockPart = object.lockPart ?? -1;
        this.lockTest = object.lockTest ?? -1;
        this.price = object.price ?? 0;
        this.darkMode = object.darkMode ?? "";
        this.version = object.version ?? "";
        this.test_id = object.test_id ?? "";

        this.bucket = object.bucket ?? "";
        this.hasState = object.hasState ?? "";
        // this.hasState = parseBoolean(object.hasState) ?? false;
        this.priceInfo = object.priceInfo ?? "";
        this.totalQuestions = object.totalQuestions ?? 0;
        this.versionWeb = object.versionWeb ?? "";
        this.versionIOS = object.versionIOS ?? "";
        this.versionAndroid = object.versionAndroid ?? "";
        this.platform = object.platform ?? "";
    }
}
