import Button from "@material-ui/core/Button";
import axios from "axios";
import { ISprint } from "../../../shared/models/sprint";
import Url from "../../../util/url";
import { toast } from "react-toastify";

const ButtonSendReportEmail = ({ sprint }: { sprint: ISprint }) => {
    const handleSendEmail = async () => {
        if (sprint) {
            let _result = await axios.post(Url("sprint-report-v2/send-email-report"), { sprintId: sprint._id });
            let _data = _result.data;
            if (_data === "ok") {
                toast("Gửi email thành công!", {
                    type: "success",
                    position: "top-center",
                });
            } else if (_data === "empty") {
                toast("Không có dữ liệu report của sprint này!", {
                    type: "warning",
                    position: "top-center",
                });
            } else {
                toast(_data, { type: "error", position: "top-center" });
            }
        }
    };
    return (
        <Button
            variant="outlined"
            color="primary"
            style={{
                marginRight: "6px",
                textTransform: "initial",
                height: "38px",
            }}
            disabled={sprint.activeSprint}
            onClick={() => {
                handleSendEmail();
            }}
        >
            Gửi email report của sprint
        </Button>
    );
};

export default ButtonSendReportEmail;
