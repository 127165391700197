import axios from "axios";
import Url from "../../util/url";

export const getTopicProgressAPI = async (userId) => {
    // http://localhost:4003/test-progress/get-progress?userId=nhungnguyen@abc-elearning.orgf
    const { data } = await axios.get(Url("topic-progress/get-progress?userId=" + userId));
    return data;
};

export const getTestProgressAPI = async (userId) => {
    // http://localhost:4003/test-progress/get-progress?userId=nhungnguyen@abc-elearning.orgf
    const { data } = await axios.get(Url("test-progress/get-progress?userId=" + userId));
    return data;
};

export const updateOnBoardingPass = async (email, status) => {
    // http://localhost:4003/test-progress/get-progress?userId=nhungnguyen@abc-elearning.orgf
    const { data } = await axios.patch(Url("user/accept-pass-training"), {
        email: email,
        onBoardingPass: status,
    });
    return data;
};
