import { DeleteOutlined } from "@material-ui/icons";
import { Button, Card, IconButton } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteCycle } from "../../../../../redux/actions/testCycle.action";
import { ITestCase } from "../../../../../shared/models/testCase";
import {
    ITestCycle,
    ITestCycleCases,
    TestCycle,
} from "../../../../../shared/models/testCycle";
import Url from "../../../../../util/url";
import DeleteDialog from "../components/DeleteDialog";
import AddEditAllCycles from "./add-edit-all-cycles";
import "./style.scss";

const AllCycles: FC<{
    tests: ITestCase[];
    cycles: ITestCycle[];
    taskId: string;
    permission: boolean;
    permissionForDev: boolean;
    firstTime: boolean;
}> = ({ tests, cycles, taskId, permission, permissionForDev, firstTime }) => {
    const [isEditPage, setIsEditPage] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [cycle, setCycle] = useState<ITestCycle>(new TestCycle());
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [itemDelete, setItemDelete] = useState<string | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (firstTime && cycles.length > 0 && tests) {
            handleClickItem(cycles[cycles.length - 1]);
        }
    }, [firstTime, cycles, tests]);

    const handleToggleEdit = () => {
        if (tests.length <= 0) {
            toast.error("Cần ít nhất 1  test case để tạo cycle", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            return;
        }

        if (cycles.length > 0) {
            // take latest cycle to compare cycle.cases with test cases
            setCycle(cycles[cycles.length - 1]);
        } 
        else {
            setCycle(new TestCycle({cases:[]}));
        }

        setIsEditPage(!isEditPage);
        setIsEdit(false);
    };

    const handleClickItem = (cycle: ITestCycle) => {
        setCycle(cycle);
        setIsEditPage(true);
        setIsEdit(true);
    };

    const handleDeleteCycle = async () => {
        console.log(itemDelete);
        try {
            await axios.delete(Url("test-case/deletes-test-cycles"), {
                data: [itemDelete],
            });
            dispatch(deleteCycle(itemDelete));
            setOpenDeleteDialog(false);
        } catch (error) {
            // console.log(error);
        }
    };

    return isEditPage ? (
        <AddEditAllCycles
            isEdit={isEdit}
            permission={permission}
            permissionForDev={permissionForDev}
            cycle={cycle}
            tests={tests}
            taskId={taskId}
            handleToggleEdit={handleToggleEdit}
        />
    ) : (
        <>
            <div className="all-cycles">
                <Button variant="contained" onClick={handleToggleEdit}>
                    New Cycle
                </Button>
                <div className="all-cycles__histories">
                    <h3 className="all-cycles__histories__title">Histories</h3>
                    {cycles
                        ?.map((cycleItem: ITestCycle, index: number) => {
                            const {
                                passPercent,
                                failPercent,
                                untestedPercent,
                                total,
                            } = getPercentsFromStatus(cycleItem.cases);
                            return (
                                <Card
                                    variant="outlined"
                                    key={cycleItem._id}
                                    className="all-cycles__histories__item"
                                    onClick={() => handleClickItem(cycleItem)}
                                >
                                    <div className="item-info">
                                        <div className="item-info__left">
                                            <h6>
                                                <span>
                                                    <b>Cycle {index + 1}: </b>
                                                </span>
                                                <span className="time">
                                                    <i>
                                                        {moment(
                                                            cycleItem.lastUpdate
                                                        ).format(
                                                            "HH:mm DD/MM/YYYY"
                                                        )}
                                                    </i>
                                                </span>
                                            </h6>
                                            <p>
                                                Total:{" "}
                                                <span style={{ color: "blue" }}>
                                                    {total}
                                                </span>{" "}
                                                test cases
                                            </p>
                                        </div>
                                        <div className="item-info__right">
                                            <p>
                                                <span
                                                    style={{ color: "green" }}
                                                >
                                                    <b>{passPercent}%</b>
                                                </span>{" "}
                                                pass
                                            </p>
                                            <p>
                                                <span style={{ color: "red" }}>
                                                    <b>{failPercent}%</b>
                                                </span>{" "}
                                                failed
                                            </p>
                                            <p>
                                                <span
                                                    style={{ color: "#607d8b" }}
                                                >
                                                    <b>{untestedPercent}%</b>
                                                </span>{" "}
                                                untested
                                            </p>
                                        </div>
                                        <IconButton
                                            className="delete_btn"
                                            color="error"
                                            component="span"
                                            disabled={!permission}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                setItemDelete(cycleItem._id);
                                                setOpenDeleteDialog(true);
                                            }}
                                        >
                                            <DeleteOutlined />
                                        </IconButton>
                                    </div>
                                </Card>
                            );
                        })
                        .reverse()}
                </div>
            </div>
            <DeleteDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                handleDelete={handleDeleteCycle}
            />
        </>
    );
};

export const getPercentsFromStatus = (arrayTests: ITestCycleCases[]) => {
    if (arrayTests.length <= 0 && Array.isArray(arrayTests)) {
        return {
            failPercent: 0,
            passPercent: 0,
            untestedPercent: 0,
            fail: 0,
            pass: 0,
            untested: 0,
            total: 0,
        };
    }
    const total = arrayTests.length;
    let pass = 0;
    let fail = 0;

    for (let test of arrayTests) {
        const status = test.status;
        if (status === 1) {
            pass++;
        } else {
            fail++;
        }
    }

    return {
        failPercent: parseInt(((fail / total) * 100).toFixed()),
        passPercent: parseInt(((pass / total) * 100).toFixed()),
        untestedPercent: parseInt(
            (((total - pass - fail) / total) * 100).toFixed()
        ),
        fail,
        pass,
        untested: total - fail - pass,
        total,
    };
};

export default AllCycles;
