import axios from "axios";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Constants from "../constant";
import Layout from "../layout";
import { loginFromSession } from "../redux/actions/auth.action";
import { IAuthState } from "../redux/reducers/auth.reducer";
import RouterConfig from "../routes";
import { IUserInfo } from "../shared/models/userInfo";
import Url from "../util/url";
import LoadingWidget from "./LoadingWidget";
import { getAllSprintPending, getAllSprintSuccess } from "../redux/actions/sprint.action";
import { ISprint, Sprint } from "../shared/models/sprint";

interface Iproject {
    sprint?: boolean;
    hideHeader?: boolean;
}
const AuthRoute: FC<RouteProps & { project?: Iproject }> = (props) => {
    const authState: IAuthState = useSelector((state: any) => state.authState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loginFromSession());
    }, []);
    const userInfo = authState.user;
    const loggedIn = !!userInfo && !!userInfo._id;
    // console.log("authState", authState)
    if (authState.status == "default" || authState.status == "loading") {
        return <LoadingWidget />;
    }
    if (!loggedIn) {
        return (
            <Redirect
                to={{
                    pathname: RouterConfig.LOGIN,
                    state: { from: props.location },
                }}
            />
        );
    }
    if (!userInfo.isChangePassword) {
        return (
            <Redirect
                to={{
                    pathname: RouterConfig.CHANGE_PASSWORD,
                    state: { from: props.location },
                }}
            />
        );
    }
    return (
        <Layout tempAttr={props.project?.hideHeader}>
            <AuthRouteX {...props} userInfo={userInfo} />
        </Layout>
    );
};

const AuthRouteX: FC<RouteProps & { userInfo: IUserInfo; project?: Iproject }> = (props) => {
    const userInfo = props.userInfo;
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchInfo = async () => {
            let activeSprintID;
            let startDateSprint;
            let endDateSprint;
            dispatch(getAllSprintPending(true));
            const resSprint = await axios.get(Url("sprint"));
            const sprints: ISprint[] = resSprint.data.response;
            dispatch(
                getAllSprintSuccess(
                    sprints.map((item: any) => {
                        return new Sprint(item);
                    })
                )
            );
            for (let sprint of sprints) {
                if (sprint.activeSprint === true) {
                    activeSprintID = sprint._id;
                    startDateSprint = new Date(sprint.startDate).getTime();
                    endDateSprint = new Date(sprint.endDate).getTime();
                }
            }
            //tam thoi khong dung den

            // const resReports = await axios.post(Url("sprint/reports"), {
            //     sprintID: activeSprintID,
            // });
            // const data = resReports.data.find((dt: any) => dt.user[0]?._id === userInfo._id) ?? null;
            // dispatch({
            //     type: "SET_HEADER_INFO",
            //     data: {
            //         user: userInfo,
            //         sprintReport: data,
            //         sprintTime: {
            //             startDate: startDateSprint,
            //             endDate: endDateSprint,
            //         },
            //     },
            // });
        };
        const fetchUserPermission = async () => {
            const baseUrl = Constants.API_DASHBOARD_URL;
            const url = baseUrl + (baseUrl.endsWith("/") ? "" : "/") + "get-user-group-permissions?email=" + userInfo.email;
            const res = await axios.get(url);
            dispatch({ type: "SET_USER_PERMISSIONS", data: res.data });
        };
        if (props.project?.sprint) fetchInfo();
        fetchUserPermission();
    }, [userInfo]);
    // const user: any = useSelector((state: any) => state.authState?.user);
    if (!userInfo) {
        return <LoadingWidget />;
    }
    return <Route {...props} />;
};

export default AuthRoute;
