import * as React from "react";
import DatatableGrid from "../../common/data-table-grid";
import "./style.scss";
import { TreeSelect } from "antd";
import "antd/dist/antd.min.css";
import {
    REVENUE_ADS_APP,
    REVENUE_ADS_WEB,
    REVENUE_ALL,
    REVENUE_PRO_APP,
    REVENUE_PRO_WEB,
    REVENUE_PRO_WEB_CARD_VISA,
    REVENUE_PRO_WEB_ONE_TIME,
    REVENUE_PRO_WEB_PAYPAL,
    REVENUE_PRO_WEB_SUBSCRIPTION,
} from "../../../config_data_analytic";
const { SHOW_PARENT } = TreeSelect;

const treeData = [
    {
        title: "Select All",
        value: REVENUE_ALL,
        key: REVENUE_ALL,
        children: [
            {
                title: "Revenue pro app",
                value: REVENUE_PRO_APP,
                key: REVENUE_PRO_APP,
            },
            {
                title: "Revenue ads app",
                value: REVENUE_ADS_APP,
                key: REVENUE_ADS_APP,
            },
            {
                title: "Revenue ads web",
                value: REVENUE_ADS_WEB,
                key: REVENUE_ADS_WEB,
            },
            {
                title: "Revenue pro web",
                value: REVENUE_PRO_WEB,
                key: REVENUE_PRO_WEB,
                children: [
                    {
                        title: "Revenue pro web one time",
                        value: REVENUE_PRO_WEB_ONE_TIME,
                        key: REVENUE_PRO_WEB_ONE_TIME,
                    },
                    {
                        title: "Revenue pro web subscription",
                        value: REVENUE_PRO_WEB_SUBSCRIPTION,
                        key: REVENUE_PRO_WEB_SUBSCRIPTION,
                    },
                    {
                        title: "Revenue pro web card visa",
                        value: REVENUE_PRO_WEB_CARD_VISA,
                        key: REVENUE_PRO_WEB_CARD_VISA,
                    },
                    {
                        title: "Revenue pro web paypal",
                        value: REVENUE_PRO_WEB_PAYPAL,
                        key: REVENUE_PRO_WEB_PAYPAL,
                    },
                ],
            },
        ],
    },
];

const OverViewDashBoard = () => {
    const [value, setValue] = React.useState<any>([REVENUE_ALL]);
    const onChange = (newValue: string[]) => {
        setValue(newValue);
        localStorage.setItem("revenue", JSON.stringify(newValue));
    };

    const tProps = {
        treeData,
        value,
        onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: "Please select",
        style: {
            width: "100%",
        },
    };
    React.useEffect(() => {
        let revenueStorage = localStorage.getItem("revenue");
        if (revenueStorage) {
            setValue(JSON.parse(revenueStorage));
        }
    }, []);
    return (
        <div className="question-quality-v2 overview">
            <TreeSelect {...tProps} />

            <DatatableGrid valueSelect={value} />
        </div>
    );
};
export default OverViewDashBoard;
