import { IUserExpectation } from "../../shared/models/userExpectation";
import {
    IUserExpectationAction,
    UserExpectationTypes,
} from "../actions/userExpectation.action";

export interface IUserExpectationState {
    data: IUserExpectation[];
}

const initState: IUserExpectationState = {
    data: [],
};

const userExpectationState = (
    state = initState,
    action: IUserExpectationAction
): IUserExpectationState => {
    switch (action.type) {
        case UserExpectationTypes.GET_ALL_EXPECTATIONS_SUCCESS:
            state.data = action.userExpectations ?? [];
            let expectation = action.userExpectations ?? [];
            let newData = state.data;
            let mapData: { [key: string]: IUserExpectation } = {};
            for (let item of newData) {
                mapData[item.sprintId + item.userId] = item;
            }

            for (let item of expectation) {
                if (!mapData[item.sprintId + item.userId]) newData.push(item);
            }
            return { ...state, data: newData };
        default:
            return state;
    }
};

export default userExpectationState;
