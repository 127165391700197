import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Setting } from '../../shared/models/setting';
import Url from '../../util/url';
import { SettingTypes, getSettingFailed, getSettingSuccess } from '../actions/setting.action';

async function getSettingAPI() {
	return await axios.get(Url('setting')).then((data) => data.data ? new Setting(data.data) : null);
}

function* getSettingSaga() {
	try {
		const data: Setting | null = yield call(getSettingAPI);
		if (data) {
			yield put(getSettingSuccess(data));
		} else {
			yield put(getSettingFailed("getSettingSaga data null"));
		}
	} catch (error) {
		yield put(getSettingFailed("getSettingSaga ERROR: " + error));
	}
}

export function* watchGetSetting() {
	yield takeLatest(SettingTypes.GET_SETTING, getSettingSaga);
}
