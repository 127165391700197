import { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITask, ITaskComment, TaskComment } from "../../../../../shared/models/task";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import UserAvatar from "../../../../user/UserAvatar";
import "./index.scss";
import { IconButton } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ListComment from "./list";
import { updateComment } from "../../../../../redux/actions/comment.action";

const CommentPanel: FC<{ task: ITask }> = ({ task }) => {
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const ref = useRef<HTMLTextAreaElement | null>(null);
    const dispatch = useDispatch();
    const onSendMessage = () => {
        const value = ref.current?.value ?? "";
        if (!value) {
            // toast("Content empty!");
            return;
        }
        const comment: ITaskComment = new TaskComment({
            userID: userInfo._id,
            createdDate: new Date(),
            content: value,
            taskId: task._id,
        });
        // console.log("create new comment", comment)
        dispatch(updateComment(comment));
        if (ref.current) {
            ref.current.value = "";
        }
    };
    return (
        <div className="comments-widget">
            <div className="add-comment">
                <UserAvatar avatar={userInfo.avatar} teams={""} />
                <textarea
                    ref={ref}
                    name="comment"
                    placeholder="Add a comment ..."
                    onKeyDown={(event) => {
                        if (event.code == "Enter" && !event.shiftKey) {
                            event.preventDefault();
                            onSendMessage();
                        }
                    }}
                />
                <div className="button">
                    <IconButton color="primary" onClick={() => onSendMessage()}>
                        <SendIcon />
                    </IconButton>
                </div>
            </div>
            <ListComment task={task} />
        </div>
    );
};

export default CommentPanel;
