import { combineReducers } from "redux";
import filterReducer from "../../components/dashboard-analytic/Filter/filter.reducer";
import resultReducer from "../../components/dashboard-analytic/Result/result.reducer";
import appState from "./app.reducer";
import appThemeState from "./appTheme.reducer";
import appThemeRootState from "./appThemeRoot.reducer";
import appVersionState from "./appVersion.reducer";
import authState from "./auth.reducer";
// import CampaignReducer from "./campaign.reducer";
import campaignState from "./campaign_new.reducer";
import CareerEvent from "./career-event.reducer";
import commentState from "./comment.reducer";
import Credit from "./credit.reducer";
import dashBoardState from "./dashboard.reducer";
import Feedback from "./feedback.reducer";
import gitHubState from "./github.reducer";
import HeaderInfo from "./headerInfo.reducer";
import UserInfoReducer from "./infoUser.reducer";
import Item from "./item.reducer";
import manageDeviceReducer from "./manageDevice.reducer";
import MyProfile from "./myProfile.reducer";
import MyItem from "./myitem.reducer";
import Notifications from "./notification.reducer";
import notificationState from "./notification_hero.reducer";
import Page from "./page.reducer";
import personalsGoalState from "./personalGoal.reducer";
import Point from "./point.reducer";
import problemReportingState from "./problemReporting.reducer";
import projectState from "./project.reducer";
import ratingState from "./rating.reducer";
import reportState from "./report.reducer";
import Request from "./request.reducer";
import rewardMinusExpState from "./rewardMinusExp.reducer";
import SearchTasksInput from "./searchTasksInput.reducer";
import settingState from "./setting.reducer";
import SprintProjectsReducer from "./sprint-projects.reducer";
import SprintStatusesReducer from "./sprint-statuses.reducer";
import sprintState from "./sprint.reducer";
import sprintReportState from "./sprintReport.reducer";
import SprintsReducer from "./sprints.reducer";
import statusState from "./status.reducer";
import taskState from "./task.reducer";
import testsReducer from "./testCase.reducer";
import cyclesReducer from "./testCycle.reducer";
import testSectionReducer from "./testSection.reducer";
import toolAnalysisState from "./toolAnalysis.reducer";
import User from "./user.reducer";
import userExpectationState from "./userExpectation.reducer";
import userGroupState from "./userGroup.reducer";
import userInfoState from "./userInfo.reducer";
import UserPermissions from "./userPermissions.reducer";
import userProgressState from "./userProgress.reducer";
import webReducer from "./web.reducer";
import WorkingTimeReducer from "./workingTime.reducer";
import sharingState from "./sharing.reducer";
import appStatisticsState from "./ratingApp.reducer";

import appChannelState from "./appChannel.reducer";
export default combineReducers({
    tests: testsReducer,
    cycles: cyclesReducer,
    setPoint: Point,
    testSections: testSectionReducer,
    item: Item,
    request: Request,
    notifications: Notifications,
    page: Page,
    userbonus: User,
    myitem: MyItem,
    user: UserInfoReducer,
    userInfo: User,
    myProfile: MyProfile,
    headerInfo: HeaderInfo,
    credit: Credit,
    feedback: Feedback,
    event: CareerEvent,
    sprintStatuses: SprintStatusesReducer,
    sprintProjects: SprintProjectsReducer,
    sprints: SprintsReducer,
    webReducer,
    userPermissions: UserPermissions,
    filterReducer: filterReducer,
    resultReducer: resultReducer,
    settingState,
    dashBoardState,
    // campaigns: CampaignReducer,
    manageDevice: manageDeviceReducer,
    workingTime: WorkingTimeReducer,
    searchTasksInput: SearchTasksInput,
    sprintReportState,
    userInfoState,
    authState,
    taskState,
    statusState,
    projectState,
    sprintState,
    campaignState,
    commentState,
    userExpectationState,
    userProgressState,
    reportState,
    gitHubState,
    userGroupState,
    ratingState,
    toolAnalysisState,
    appState,
    appVersionState,
    sharingState,
    rewardMinusExpState,
    personalsGoalState,
    notificationState,
    appThemeState,
    appThemeRootState,
    problemReportingState,
    appChannelState,
    appStatisticsState
});
