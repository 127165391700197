import { FC } from "react";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import { ISprintReportItem } from "../../../shared/models/sprintReport";
import { getFullName } from "../../../util";
import { IStatus } from "../../../shared/models/status";
import { Checkbox } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateCheckPointTaskInSprintAction, updateSprintReportSuccess } from "../../../redux/actions/sprintReport.action";
import { ISprint } from "../../../shared/models/sprint";
import { updateCheckpointTaskAPI } from "../../../redux/sagas/sprint.saga";

const ListTask: FC<{ tasks: ISprintReportItem[]; mapStatus: Map<string, IStatus>; currentSprint: ISprint }> = ({
    tasks,
    mapStatus,
    currentSprint,
}) => {
    const dispatch = useDispatch();
    if (!tasks.length) {
        return null;
    }
    return (
        <div>
            <hr />
            <table className="table table-bordered table-tasks-done">
                <thead>
                    <tr>
                        <td></td>
                        <td>Task</td>
                        <td>Trạng thái</td>
                        <td>Point</td>
                        <td>Assignee</td>
                        <td>CheckPoint</td>
                    </tr>
                </thead>
                <tbody>
                    {tasks
                        .filter((task: ISprintReportItem) => task && task.assignee && task.taskName)
                        .sort((a: ISprintReportItem, b: ISprintReportItem) =>
                            getFullName(a.assignee).localeCompare(getFullName(b.assignee))
                        )
                        .map((task: ISprintReportItem, index: number) => {
                            const avatar = task.assignee?.avatar ?? defaultAvatar;
                            const statusTitle =
                                task.status == "done" ? task.status : mapStatus.get(task.status ?? "")?.title ?? "";
                            const statusColor =
                                task.status == "done" ? "#50C98F" : mapStatus.get(task.status ?? "")?.color ?? "";
                            return (
                                <tr key={"task-" + (task._id + " - " + task.taskID)}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a href={"/task/" + task.taskID} target="_blank">
                                            {task.taskName}
                                        </a>
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                padding: 4,
                                                borderRadius: 4,
                                                color: "black",
                                                backgroundColor: statusColor,
                                            }}
                                        >
                                            {statusTitle}
                                        </span>
                                    </td>
                                    <td>{task.point}</td>
                                    <td className="avatar">
                                        <img src={avatar} />
                                        <span>{task.assignee?.firstName}</span>
                                    </td>
                                    <td>
                                        {task.assignee?.teams == "technical" ? (
                                            <Checkbox
                                                color="primary"
                                                // disabled={true}
                                                checked={task.checkPoint}
                                                onChange={async (e) => {
                                                    let checkPoint = !task.checkPoint;
                                                    dispatch(
                                                        updateCheckPointTaskInSprintAction({
                                                            userId: task.assignee?._id ?? "",
                                                            taskId: task.taskID,
                                                            currentSprint: currentSprint._id,
                                                            checkPoint,
                                                        })
                                                    );
                                                    updateCheckpointTaskAPI({
                                                        sprintId: currentSprint._id,
                                                        checkPoint,
                                                        taskId: task.taskID,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};
export default ListTask;
