import {
    Autocomplete,
    Button,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { MANAGE_DEVICE_TYPE } from "../../../redux/reducers/manageDevice.reducer";
import Url from "../../../util/url";
import AddEditManageDeviceModal from "./AddEditManageDeviceModal";
import { getTotalAmountUsed } from "./util";

const ManageDeviceModal = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [searchByUser, setSearchByUser] = useState(null);
    const [searchByType, setSearchByType] = useState(null);
    const users = useSelector((state) => state.user);
    const manageDevice = useSelector((state) => state.manageDevice.data);
    const userInfo = useSelector((state) => state.authState.user);
    const dispatch = useDispatch();
    let totalAmountUsed = 0;
    let totalAmountUsedByUser = 0;
    let totalAmountRemain = 0;
    let devices = [];
    let typeOptions = [];
    let lastIndex = 0;
    let permission = false;

    if (userInfo && userInfo.role === 1) {
        permission = true;
    }

    useEffect(() => {
        if (openEdit === false) {
            setEditItem(null);
        }
    }, [openEdit]);

    useEffect(() => {
        dispatch({ type: MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE });
        try {
            getAllUser();
        } catch (error) {
            // console.log(error);
        }
    }, []);

    const getAllUser = async () => {
        const resTotal = await axios.get(Url("point/rankings"));
        dispatch({
            type: "GET_ALL_USER",
            data: resTotal?.data?.response && resTotal?.data?.response,
        });
    };

    if (manageDevice) {
        manageDevice.forEach((item) => {
            if (item.index > lastIndex) {
                lastIndex = item.index;
            }

            if (item.type) {
                const findOption = typeOptions.find((option) => option.toLowerCase() === item.type.toLowerCase());
                if (!findOption) typeOptions.push(item.type);
            }
        });

        devices = manageDevice
            .filter((item) => {
                if (searchByUser) {
                    const foundItem = item.users.find((user) => user.userID === searchByUser._id);
                    if (!foundItem) {
                        return false;
                    }
                }
                if (searchByType) {
                    if (item.type) {
                        const foundItem = item.type.toLowerCase() === searchByType.toLowerCase();
                        if (!foundItem) {
                            return false;
                        }
                    }
                }

                return true;
            })
            .map((item) => {
                if (searchByUser) {
                    const foundItem = item.users.find((user) => user.userID === searchByUser._id);
                    if (foundItem) {
                        return {
                            ...item,
                            itemUsedByUser: foundItem.amountUsed,
                        };
                    }
                }

                return item;
            });

        devices.forEach((device) => {
            if (device.itemUsedByUser) {
                totalAmountUsedByUser += device.itemUsedByUser;
            }
            const amountUsedPerDevice = getTotalAmountUsed(device.users);
            totalAmountUsed += amountUsedPerDevice;
            totalAmountRemain += device.quantity - amountUsedPerDevice;
        });
    }

    const getUsersFromUsersIDArr = (array) => {
        return array.reduce((result, item) => {
            const user = users.find((user) => user._id === item.userID);
            if (user) {
                result.push({
                    ...user,
                    ...item,
                });
            }

            return result;
        }, []);
    };

    const handleClickAdd = () => {
        if (!permission) {
            toast.error("Bạn không có quyền thêm", {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });

            return;
        }
        setOpenEdit(true);
    };

    const handleClickEdit = (item) => {
        setEditItem(item);
        setOpenEdit(true);
    };

    return (
        <>
            <div className="manage-device-container" scroll="body">
                <DialogTitle className="title">
                    <span>Quản lý thiết bị</span>
                    <div className="search">
                        <Autocomplete
                            className="search-select"
                            value={searchByType}
                            onChange={(event, newValue) => {
                                setSearchByType(newValue);
                            }}
                            options={typeOptions}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField {...params} label="Tìm theo loại" variant="outlined" size="small" />
                            )}
                        />
                        <Autocomplete
                            className="search-select"
                            value={searchByUser}
                            onChange={(event, newValue) => {
                                setSearchByUser(newValue);
                            }}
                            options={users}
                            getOptionLabel={(option) => option.lastName + " " + option.firstName}
                            renderInput={(params) => (
                                <TextField {...params} label="Tìm theo người phụ trách" variant="outlined" size="small" />
                            )}
                        />
                    </div>
                    <div className="btn">
                        <Button disabled={!permission} variant="contained" onClick={() => handleClickAdd()}>
                            Thêm thiết bị mới
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent className="content">
                    <TableContainer component={Paper} className="table">
                        <Table sx={{ minWidth: 1200, minHeight: 100 }}>
                            <TableHead className="table__header">
                                <TableRow>
                                    <TableCell style={{ width: "36%" }}>Tên thiết thiết bị</TableCell>
                                    <TableCell align="center" style={{ width: "14%" }}>
                                        Số lượng đã dùng
                                    </TableCell>
                                    <TableCell align="center" style={{ width: "10%" }}>
                                        Tồn kho
                                    </TableCell>
                                    <TableCell align="center" style={{ width: "24%" }}>
                                        {" "}
                                        Người phụ trách
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table__body">
                                {devices &&
                                    devices.map((item) => (
                                        <TableRow hover onClick={() => handleClickEdit(item)} key={item._id}>
                                            <TableCell>
                                                <h6 className="name-device">{item.name}</h6>
                                            </TableCell>
                                            <TableCell align="center">
                                                {searchByUser ? item.itemUsedByUser : getTotalAmountUsed(item.users)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.quantity - getTotalAmountUsed(item.users)}
                                            </TableCell>
                                            <TableCell align="center" className="group_user">
                                                <div data-tip data-for={item._id}>
                                                    {getUsersFromUsersIDArr(item.users)
                                                        .filter((user) => {
                                                            if (searchByUser) {
                                                                if (user.userID === searchByUser._id) {
                                                                    return true;
                                                                }

                                                                return false;
                                                            }

                                                            return true;
                                                        })
                                                        .map((user, index) => (
                                                            <span
                                                                className={
                                                                    "user" +
                                                                    (searchByUser && user._id !== searchByUser._id
                                                                        ? " active"
                                                                        : "")
                                                                }
                                                                key={index}
                                                            >
                                                                {user.firstName}
                                                            </span>
                                                        ))
                                                        .slice(0, 3)}
                                                    {!searchByUser ? (item.users.length > 3 ? "..." : "") : ""}
                                                </div>
                                                <ReactTooltip id={item._id} type="info" aria-haspopup="true">
                                                    {getUsersFromUsersIDArr(item.users).map((user, index) => (
                                                        <div key={index}>
                                                            {`${user.lastName} ${user.firstName}: ${user.amountUsed}`}
                                                        </div>
                                                    ))}
                                                </ReactTooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                <TableRow>
                                    <TableCell align="left">
                                        <b>Tổng: </b>
                                    </TableCell>
                                    <TableCell align="center">
                                        <b>{searchByUser ? totalAmountUsedByUser : totalAmountUsed}</b>
                                    </TableCell>
                                    <TableCell align="center">
                                        <b>{totalAmountRemain}</b>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </div>
            {openEdit && (
                <AddEditManageDeviceModal
                    usersProps={users}
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    lastIndex={lastIndex}
                    editItem={editItem}
                    permission={permission}
                    typeOptions={typeOptions}
                />
            )}
        </>
    );
};

export default ManageDeviceModal;
