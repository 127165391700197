import {
    Add,
    Close,
    DynamicFeed,
    Spellcheck,
    Whatshot,
} from "@material-ui/icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {
    Button,
    DialogContentText,
    MenuItem,
    Select,
    Switch,
    Tooltip,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import "./index.scss";
import CircularIndeterminate from "../../../common/icon/Loading";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Url from "../../../../util/url";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const URL = "https://webpush.passemall.com";
// const URL = "http://192.168.2.189:3001";
// const URL = "http://localhost:3001";

const COMMON_QUESTION = "common";
const HOT_QUESTION = 1;
const OTHER_QUESTION = 0;

const FAQ = ({ certificates }) => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editFAQ, setEditFAQ] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [faqId, setFaqId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const allCertificates = useSelector(
        (state) => state.dashBoardState.certificates
    );

    useEffect(() => {
        fetchFAQs();
    }, [certificates]);

    const fetchFAQs = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                `${URL}/api/get-all-faq-by-app-name-id`,
                {
                    appNameId: [certificates.slug, COMMON_QUESTION],
                }
            );
            setFaqs(filterFaq(res.data.listFaq));
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const filterFaq = (faqs) => {
        if (!faqs) return [];
        return faqs.filter(
            (faq) =>
                faq.appNameId.includes(certificates.slug) ||
                faq.appNameId.includes(COMMON_QUESTION)
        );
    };

    const addNewItem = (item) => {
        setFaqs(filterFaq([...faqs, item]));
    };

    const updateItem = (item) => {
        const tempFAQ = faqs.slice();
        const index = tempFAQ.findIndex((faq) => faq._id === item._id);
        if (index !== -1) {
            tempFAQ[index] = item;
        }
        setFaqs(filterFaq(tempFAQ));
    };

    const handleDelete = async () => {
        try {
            const res = await axios.post(`${URL}/api/delete-faq`, {
                _id: faqId,
            });
            setFaqs(faqs.filter((faq) => faq._id !== faqId));
            setOpenDelete(false);
            toast.warn("Xóa thành công", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        } catch (error) {
            console.log(error);
            toast.error("Có lỗi xảy ra", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    };

    if (loading) {
        return <CircularIndeterminate />;
    }

    return (
        <>
            <div className="button-header">
                <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => {
                        setEditFAQ(null);
                        setOpenEdit(true);
                    }}
                >
                    Add New Faq
                </Button>
            </div>
            <div className="faq-container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{ width: "35%", fontWeight: 700 }}
                                >
                                    Question
                                </TableCell>
                                <TableCell
                                    style={{ width: "45%", fontWeight: 700 }}
                                >
                                    Explanation
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "5%", fontWeight: 700 }}
                                ></TableCell>
                                <TableCell
                                    align="center"
                                    style={{ width: "5%", fontWeight: 700 }}
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faqs.map((faq) => (
                                <TableRow
                                    className="row-item"
                                    hover
                                    key={faq._id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    onClick={() => {
                                        setEditFAQ(faq);
                                        setOpenEdit(true);
                                    }}
                                >
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: faq.question,
                                            }}
                                        ></div>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: faq.explanation,
                                            }}
                                        ></div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className="special">
                                            {(faq.appNameId.includes(
                                                COMMON_QUESTION
                                            ) ||
                                                faq.appNameId.length ===
                                                    allCertificates.length) && (
                                                <Tooltip
                                                    placement="top"
                                                    title="This question apply for all app"
                                                >
                                                    <Spellcheck
                                                        style={{
                                                            color: "blue",
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {faq.appNameId.length > 1 &&
                                                faq.appNameId.length !==
                                                    allCertificates.length && (
                                                    <Tooltip
                                                        placement="top"
                                                        title="This question apply for multiple app"
                                                    >
                                                        <DynamicFeed
                                                            style={{
                                                                color: "orange",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            {faq?.hotQuestion ===
                                                HOT_QUESTION && (
                                                <Tooltip
                                                    placement="top"
                                                    title="Hot question!"
                                                >
                                                    <Whatshot
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setFaqId(faq._id);
                                                setOpenDelete(true);
                                            }}
                                        >
                                            <DeleteOutlineIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {openEdit && (
                <EditFAQ
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    editItem={editFAQ}
                    addNewItem={addNewItem}
                    updateItem={updateItem}
                    certificates={certificates}
                    allCertificates={allCertificates}
                />
            )}
            {openDelete && (
                <DeleteDialog
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
};

const EditFAQ = ({
    open,
    onClose,
    editItem,
    addNewItem,
    updateItem,
    certificates,
    allCertificates,
}) => {
    const [question, setQuestion] = useState("");
    const [explanation, setExplanation] = useState("");
    const [hotQuestion, setHotQuestion] = useState(false);
    const [appNameId, setAppNameId] = useState([certificates.slug]);

    useEffect(() => {
        if (editItem) {
            setQuestion(editItem.question);
            setExplanation(editItem.explanation);
            setHotQuestion(
                editItem.hotQuestion === HOT_QUESTION ? true : false
            );
            setAppNameId(editItem.appNameId);
        }

        return () => {
            setQuestion("");
            setExplanation("");
            setHotQuestion(false);
            setAppNameId([certificates.slug]);
        };
    }, [editItem]);

    const handleSubmit = async () => {
        if (!question || !explanation) {
            const field = [];
            let message = "";
            if (!question) field.push("Question");
            if (!explanation) field.push("Explanation");
            message = `không được để trống ${
                field.length >= 2 ? "các" : ""
            } trường: ${field.join(", ")}`;
            toast.error(message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            return;
        }
        let item = {
            question,
            explanation,
            hotQuestion: hotQuestion ? HOT_QUESTION : OTHER_QUESTION,
            appNameId,
        };

        if (editItem) {
            item = {
                ...editItem,
                ...item,
            };
        }
        console.log(item);
        try {
            if (editItem) {
                const res = await axios.post(`${URL}/api/update-faq`, item);

                updateItem(item);
                toast.success("Sửa thành công", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            } else {
                const { data } = await axios.post(
                    `${URL}/api/create-new-faq`,
                    item
                );

                addNewItem(data);
                toast.success("Thêm thành công", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
            toast.error("Có lỗi xảy ra", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
        onClose();
    };

    return (
        <Dialog
            className="add-edit-faq-container"
            scroll="body"
            maxWidth="xl"
            open={open}
            onClose={onClose}
        >
            <DialogTitle className="title">
                <span style={{ marginLeft: 12 }}>
                    {editItem ? "Edit FAQ" : "Create FAQ"}
                </span>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className="content">
                <Box className="container" sx={{ width: 750, minHeight: 200 }}>
                    <div
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        className="row-input row-appName"
                    >
                        <div className="select-app">
                            <h5 className="text">Add Question for App :</h5>
                            <Select
                                style={{
                                    maxWidth: 280,
                                }}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    autoFocus: false,
                                }}
                                value={appNameId}
                                onChange={(e) => {
                                    if (
                                        e.target.value.includes(COMMON_QUESTION)
                                    ) {
                                        if (
                                            e.target.value.length - 1 ===
                                            allCertificates.length
                                        ) {
                                            setAppNameId([certificates.slug]);
                                            return;
                                        }

                                        setAppNameId(
                                            allCertificates.map((c) => c.slug)
                                        );
                                        return;
                                    }
                                    if (e.target.value.length === 0) {
                                        setAppNameId([certificates.slug]);
                                        return;
                                    }
                                    setAppNameId(e.target.value);
                                }}
                                multiple
                                renderValue={(selected) => {
                                    if (
                                        selected.length ===
                                        allCertificates.length
                                    ) {
                                        return "All App";
                                    }
                                    return selected.join(", ");
                                }}
                            >
                                <MenuItem
                                    style={{
                                        width: 280,
                                        maxWidth: 280,
                                    }}
                                    value={COMMON_QUESTION}
                                >
                                    <Checkbox
                                        checked={
                                            appNameId.length ===
                                            allCertificates.length
                                        }
                                        style={{
                                            marginRight: 10,
                                        }}
                                    />
                                    <ListItemText primary={"All App"} />
                                </MenuItem>
                                {allCertificates?.map((certificate) => (
                                    <MenuItem
                                        key={certificate.id}
                                        value={certificate.slug}
                                        style={{
                                            width: 280,
                                            maxWidth: 280,
                                        }}
                                    >
                                        <Checkbox
                                            checked={appNameId.includes(
                                                certificate.slug
                                            )}
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        <ListItemText
                                            primary={certificate.name}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hotQuestion}
                                    value={hotQuestion}
                                    onChange={() =>
                                        setHotQuestion(!hotQuestion)
                                    }
                                    color="primary"
                                />
                            }
                            label="Hot Question"
                        />
                    </div>
                    <div style={{ marginBottom: "40px" }} className="row-input">
                        <h5>Question</h5>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                ckfinder: {
                                    uploadUrl: Url("upload/editor"),
                                },
                                link: {
                                    addTargetToExternalLinks: true,
                                },
                            }}
                            data={question}
                            onReady={() => {
                                if (editItem) {
                                    setQuestion(editItem.question);
                                }
                            }}
                            onChange={(e, editor) =>
                                setQuestion(editor.getData())
                            }
                        />
                    </div>
                    <div className="row-input">
                        <h5>Explanation</h5>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                ckfinder: {
                                    uploadUrl: Url("upload/editor"),
                                },
                                link: {
                                    addTargetToExternalLinks: true,
                                },
                            }}
                            data={explanation}
                            onReady={() => {
                                if (editItem) {
                                    setExplanation(editItem.explanation);
                                }
                            }}
                            onChange={(e, editor) =>
                                setExplanation(editor.getData())
                            }
                        />
                    </div>
                </Box>
            </DialogContent>
            <DialogActions className="actions">
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            marginRight: "10px",
                        }}
                    >
                        {editItem ? "Save" : "Create"}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

const DeleteDialog = ({ open, onClose, handleDelete }) => {
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Delete ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to delete this?
                        <br />
                        <br />
                        This action can not be undo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FAQ;
