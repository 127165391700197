import moment from "moment";

const initState = {
    loading: false,
    data: [],
};

const CareerEvent = (state = initState, action) => {
    switch (action.type) {
        case "SET_CAREER_EVENT":
            return {
                ...state,
                data: action.data.map((item) => {
                    return {
                        ...item,
                        date: moment(item.date).format("YYYY-MM-DD"),
                    };
                }),
            };
        case "CREATE_CAREER_EVENT":
            return {
                ...state,
                loading: true,
            };
        case "CREATE_CAREER_EVENT_SUCCESS":
            return {
                loading: false,
                data: state.data.concat({
                    ...action.data,
                    date: moment(action.data.date).format("YYYY-MM-DD"),
                }),
            };
        case "CREATE_CAREER_EVENT_FAILED":
            return {
                ...state,
                loading: false,
            };
        case "UPDATE_CAREER_EVENT":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_CAREER_EVENT_SUCCESS":
            return {
                loading: false,
                data: state.data.map((feedback) =>
                    feedback._id === action.data._id ? action.data : feedback
                ),
            };
        case "UPDATE_CAREER_EVENT_FAILED":
            return {
                ...state,
                loading: false,
            };
        case "DELETE_CAREER_EVENT":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_CAREER_EVENT_SUCCESS":
            return {
                loading: false,
                data: state.data.filter(
                    (feedback) => feedback._id !== action.data
                ),
            };
        case "DELETE_CAREER_EVENT_FAILED":
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default CareerEvent;
