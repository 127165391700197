import { BorderColor } from '@material-ui/icons';
import { Box, Checkbox, Collapse, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TableCell, TableRow, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import Constants from '../../../../../../constant';
import { ITestCase } from '../../../../../../shared/models/testCase';
import { getPriority, getPriorityColor } from '../../../../../../util';
import Editor from '../../components/Editor';
import './style.scss';

const STATUS_VALUES = [
    { 
      color: '#4CAF50',
      colorMui: 'success',
      value: Constants.PASS,
      label: 'Pass'
    },
    { 
      color: '#D32F2F',
      colorMui: 'error',
      value: Constants.FAIL,
      label: 'Failed'
    },
    { 
      color: 'gray',
      colorMui: 'warning',
      value: Constants.UNTESTED,
      label: 'Untest'
    },
  ]
  
  const getColor: any = (value: any) => {
    return STATUS_VALUES.find(item => item.value === Number(value))
  }

const Row: FC<{ 
    row: any, 
    permission: boolean, 
    setFocusValue: any,
    handleOnBlur: Function,
    handleChange: Function,
    handleSelectChange: Function,
    handleCheckBoxChange: Function,
}> = ({ 
    row, 
    permission, 
    setFocusValue,
    handleOnBlur,
    handleChange,
    handleSelectChange,
    handleCheckBoxChange,
}) => {
    const [openDropDown, setOpenDropDown] = useState(false);
    const [toggleEditText, setToggleEditText] = useState(false);

    return (
        <>
            <TableRow hover onClick={() => setOpenDropDown(!openDropDown)} className='row-info' sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell size='small' component="th" scope="row">
                    {row.testIndex}
                </TableCell>
                <TableCell size='small'>{row.title}</TableCell>
                <TableCell size='small' align='right' sx={{ padding: 0}}>
                    <div className='status'>
                        <Tooltip 
                            arrow 
                            placement='left' 
                            title='Priority'
                        >
                            <span className='priority' style={{ border: `2px solid ${getPriorityColor(row.priority)}`}}>{getPriority(row.priority)}</span>
                        </Tooltip>
                        <FormControl  color={getColor(row.status).colorMui} focused={true}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                sx={{
                                    color: getColor(row.status).color
                                }}
                                size='small'
                                className='status__select'
                                value={row.status}
                                label="status"
                                inputProps={{ readOnly: !permission }}
                                onChange={(e) => handleSelectChange({ value: e.target.value, name: 'status'}, row._id)}
                                onClick={e => e.stopPropagation()}
                            >
                                {
                                    STATUS_VALUES.map((item, index) => (
                                        <MenuItem
                                            key={index} 
                                            value={item.value}
                                            sx={{
                                                color: item.color
                                            }}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            onClick={e => e.stopPropagation()}
                            label="Ready to test"
                            control={
                                <Checkbox 
                                    color="success" 
                                    checked={Boolean(row.devCheck)}
                                    onChange={(e) => handleCheckBoxChange({ value: Boolean(row.devCheck) ? 0 : 1, name: 'devCheck' }, row._id)}
                                    
                                />
                            }
                        />
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className='row-dropdown'>
                <TableCell size='small' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openDropDown} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div className='case__input__row'>
                                <InputLabel className='case__input__row__label'>
                                    Description:
                                </InputLabel>
                                {
                                toggleEditText ?  
                                <Editor
                                    onFocus={(event: any, editor: any) => setFocusValue(editor.getData())} 
                                    data={row.steps}
                                    onChange={(e: any, editor: any) =>handleChange({ value: editor.getData(), name: 'steps' }, row._id)}
                                    onBlur={(event: any, editor: any) => handleOnBlur(event, editor)}
                                /> :
                                <div dangerouslySetInnerHTML={{__html: row.steps}}></div>
                                }
                                <div className='info'>
                                    <IconButton
                                        color='primary'
                                        onClick={() => setToggleEditText(!toggleEditText)}
                                        component='span'
                                        size='small'
                                        disabled={!permission}
                                    >
                                        <BorderColor fontSize='small'/>
                                    </IconButton>
                                </div>
                            </div>
                            <div className='case__input__row'>
                                <InputLabel className='case__input__row__label'>
                                    Expected result:
                                </InputLabel>
                                {
                                toggleEditText ?  
                                <Editor
                                onFocus={(event: any, editor: any) => setFocusValue(editor.getData())} 
                                data={row.expectedResult}
                                onChange={(e: any, editor: any) =>handleChange({ value: editor.getData(), name: 'expectedResult' }, row._id)}
                                onBlur={(event: any, editor: any) => handleOnBlur(event, editor)}
                                /> :
                                <div dangerouslySetInnerHTML={{__html: row.expectedResult}}></div>
                                }
                            </div>
                            <div className='case__input__row'>
                                <InputLabel className='case__input__row__label'>
                                    Actual result:
                                </InputLabel>
                                {
                                toggleEditText ? 
                                <Editor
                                    onFocus={(event:any, editor: any) => setFocusValue(editor.getData())} 
                                    data={row.actualResult}
                                    onChange={(e: any, editor: any) =>handleChange({ value: editor.getData(), name: 'actualResult' }, row._id)}
                                    onBlur={(event: any, editor: any) => handleOnBlur(event, editor)}
                                /> :
                                <div dangerouslySetInnerHTML={{__html: row.actualResult}}></div>
                                }
                            </div>
                            <div className='case__input__row'>
                                <InputLabel className='case__input__row__label'>
                                    Note:
                                </InputLabel>
                                {
                                toggleEditText ? 
                                <Editor
                                    onFocus={(event: any, editor: any) => setFocusValue(editor.getData())} 
                                    data={row.note}
                                    onChange={(e: any, editor: any) =>handleChange({ value: editor.getData(), name: 'note' }, row._id)}
                                    onBlur={(event: any, editor: any) => handleOnBlur(event, editor)}
                                /> :
                                <div dangerouslySetInnerHTML={{__html: row.note}}></div>
                                }
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default Row