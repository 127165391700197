import { ITask } from "../../shared/models/task";

export enum WebTypes {
    RELOAD = "RELOAD",
    CREATED_NEW_TASK = "CREATED_NEW_TASK",
}

export interface IWebAction {
    type: WebTypes
    task?: ITask
    ratingUserIds?: string[]
}

export const reload = (): IWebAction => {
    return {
        type: WebTypes.RELOAD,
    }
}

export const createdNewTask = (task: ITask): IWebAction => {
    return {
        type: WebTypes.CREATED_NEW_TASK,
        task
    }
}