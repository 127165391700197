import axios from "axios";
import { useRef, useState } from "react";
import MButton from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { addRewardMinusExpSuccess } from "../../../redux/actions/sprintReport.action";
import Config from "../../../shared/models/config";
import { ISprint } from "../../../shared/models/sprint";
import { IUserInfo } from "../../../shared/models/userInfo";
import { roleForPoint } from "../../../shared/utils";
import { isAdmin, isLeader } from "../../../util";
import Url from "../../../util/url";
import DiamondIcon from "../../common/icon/DiamondIcon";
import MyTextBox from "../widgets/MyTextBox";
type EXPAction = 0 | 1 | -1;
const ExperienceProgress = ({
    user,
    isUserAuthStaff,
    exp,
    sprint,
}: {
    user: IUserInfo;
    isUserAuthStaff: boolean;
    exp: { prev: number; current: number };
    sprint: ISprint;
}) => {
    const [expActionModal, setExpActionModal] = useState<EXPAction>(0);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    if (!roleForPoint(user)) return <></>;
    const levelExp = user.standarExpPerLevel ?? Config.STANDAR_EXP;
    const levelByDay =
        (exp.prev + exp.current * (sprint.activeSprint ? 1 : 0)) / levelExp > 1
            ? 1
            : (exp.prev + exp.current * (sprint.activeSprint ? 1 : 0)) / levelExp;
    const levelProgress = exp.prev / levelExp > 1 ? 1 : exp.prev / levelExp;
    const canEditPoints = isAdmin(userInfo) || isLeader(userInfo);
    console.log("canEditPoints", canEditPoints, userInfo.role);
    
    return (
        <>
            <div className="user-experience">
                <span
                    className="white justify-content-center"
                    style={{
                        fontSize: "18px",
                        lineHeight: "32px",
                    }}
                >
                    Điểm kinh nghiệm
                </span>
                <div className="level-progress" data-tip data-for={"exp-point-" + user._id}>
                    <div className="level-progress-boundary align-item-center">
                        {!isUserAuthStaff ? (
                            <div
                                className="circle-level"
                                onClick={() => canEditPoints && setExpActionModal(-1)}
                                style={{
                                    cursor: "pointer",
                                }}
                                onMouseOut={(e) => {
                                    if(!canEditPoints) return;
                                    let element = document.getElementById("current-level");
                                    if (element) element.innerHTML = "" + user.level;
                                }}
                                onMouseOver={(e) => {
                                    if(!canEditPoints) return;
                                    let element = document.getElementById("current-level");
                                    if (element) element.innerHTML = "&minus;";
                                }}
                            >
                                <div className="current-level" id="current-level">
                                    {user.level}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                        <div
                            className="exp"
                            style={{
                                width: "calc(" + Math.round(levelByDay * 100) + `% - ${8 * levelByDay}px)`,
                                background: "#8AF3E3",
                            }}
                        ></div>
                        <div
                            className="exp"
                            style={{
                                width: "calc(" + Math.round(levelProgress * 100) + `% - ${8 * levelProgress}px)`,
                            }}
                        ></div>
                        <div className="align-item-center" style={{ zIndex: 1 }}>
                            <DiamondIcon fill="white" />
                            <span
                                className="white"
                                style={{
                                    marginLeft: "6px",
                                    fontSize: "16px",
                                }}
                            >
                                <span data-tip data-for="current-score">
                                    {exp.prev}
                                </span>
                                <ReactTooltip id="current-score" type="info">
                                    <div>Điểm tích luỹ trong level này</div>
                                </ReactTooltip>
                                <small>/</small>
                                <span style={{ opacity: 0.5 }} data-tip data-for="expect-score">
                                    {levelExp}
                                </span>
                                <ReactTooltip id="expect-score" type="info">
                                    <div>Điểm cần đạt được để tăng level</div>
                                </ReactTooltip>
                            </span>
                        </div>

                        {!isUserAuthStaff ? (
                            <div
                                className="circle-level"
                                onClick={() => canEditPoints && setExpActionModal(1)}
                                style={{
                                    cursor: "pointer",
                                }}
                                onMouseOut={(e) => {
                                    if(!canEditPoints) return;
                                    let element = document.getElementById("next-level");
                                    if (element) element.innerHTML = "" + (user.level + 1);
                                }}
                                onMouseOver={(e) => {
                                    if(!canEditPoints) return;
                                    let element = document.getElementById("next-level");
                                    if (element) element.innerHTML = "&plus;";
                                }}
                            >
                                <div className="next-level" id="next-level">
                                    {user.level + 1}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                <div
                    className="white justify-content-center"
                    style={{
                        marginTop: "4px",
                        fontSize: "12px",
                        color: "#8AF3E3",
                        alignItems: "flex-end",
                    }}
                >
                    <strong data-tip data-for="current-score-in-sprint">{`${
                        (exp.current >= 0 ? "+" : "") + exp.current
                    }`}</strong>
                    <small style={{ marginLeft: "4px" }}>{" (" + sprint.name.toLowerCase() + ")"}</small>
                </div>
                <ReactTooltip id="current-score-in-sprint" type="info">
                    <div>Điểm hiện đạt được trong {sprint.name.toLowerCase()}</div>
                </ReactTooltip>
            </div>
            {expActionModal ? (
                <ExpActionModal expActionModal={expActionModal} onHide={() => canEditPoints && setExpActionModal(0)} userId={user._id} />
            ) : (
                <></>
            )}
        </>
    );
};

const ExpActionModal = ({ expActionModal, onHide, userId }: { expActionModal: EXPAction; onHide: any; userId: string }) => {
    const expRef = useRef<HTMLInputElement | null>(null);
    const reasonRef = useRef<HTMLInputElement | null>(null);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const dispatch = useDispatch();
    const saveExpAction = () => {
        let exp = expRef.current?.value;
        let reason = reasonRef.current?.value;
        if (!exp || !reason || isNaN(parseFloat(exp)) || exp.indexOf("-") > -1 || exp.indexOf("e") > -1) {
            toast(() => <div>Dữ liệu không hợp lệ!</div>, {
                position: "top-center",
                type: "error",
            });
            return;
        }
        axios
            .post(Url("sprint-report-v2/create-reward-minus-exp"), {
                exp: expActionModal * parseFloat(exp),
                userId: userId,
                time: new Date(),
                reason: reason,
                by: userInfo._id,
            })
            .then((res) => {
                dispatch(addRewardMinusExpSuccess(res.data));
                onHide();
            })
            .catch((err) => {
                toast(() => <div>Có lỗi xảy ra</div>, {
                    position: "top-center",
                    type: "error",
                });
                console.log(err);
            });
    };
    let action = expActionModal == 1 ? "Reward" : expActionModal == -1 ? "Minus" : "";
    return (
        <Modal show={true} size="lg" centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{action.toLowerCase() == "minus" ? "Trừ " : "Thưởng "} kinh nghiệm</Modal.Title>
            </Modal.Header>
            <ModalBody className="">
                <div className="item title">
                    <h5>{"Điểm: *"}</h5>
                    <MyTextBox ref={expRef} text={""} type="numeric" title={action + " exp point:"} />
                </div>
                <div className="item reason">
                    <h5>Lý do: *</h5>
                    <MyTextBox ref={reasonRef} text={""} title={"Reason"} />
                </div>
            </ModalBody>
            <ModalFooter>
                <MButton
                    onClick={() => {
                        saveExpAction();
                    }}
                    style={{ background: "#4bb4ed", borderColor: "#4bb4ed" }}
                >
                    Lưu
                </MButton>
                <MButton className="close" aria-label="Close" onClick={onHide} variant="secondary">
                    Huỷ
                </MButton>
            </ModalFooter>
        </Modal>
    );
};

export default ExperienceProgress;
