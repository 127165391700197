import React, { useEffect, useState } from "react";
import "./style.scss";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
const WeekList = ({ weeks, addWeekArray, arrWeek }) => {
    const [monday, setMonday] = useState("");
    const [sunday, setSunday] = useState("");
    const mappedWeeks = weeks || [];
    const handleFormatDate = (date) => {
        const formattedDate = new Date(date);
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        const formattedDateString = formattedDate.toLocaleDateString("en-GB", options);
        return formattedDateString;
    };
    const getDateOnWeek = (object) => {
        const date = new Date(object.year, 0, 1);
        date.setDate(date.getDate() + (object.week - 1) * 7 - date.getDay() + 1);
        const endOfWeek = new Date(date);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        const startDate = handleFormatDate(date.toLocaleDateString());
        const endDate = handleFormatDate(endOfWeek.toLocaleDateString());

        return startDate + " - " + endDate;
    };

    return (
        <div className="knowledge-sharing-sidebar">
            <div className="list-week">
                <div className="name-list">
                    <p>Week</p>
                </div>
                {mappedWeeks.map((weekNumber, index) => (
                    <div className="list-week-item" key={index}>
                        <div
                            className="list-week-item-button"
                            style={
                                arrWeek.includes(weekNumber.week)
                                    ? {
                                          border: "2px solid #08adff",
                                          padding: "8px",
                                      }
                                    : {}
                            }
                            onClick={() => {
                                addWeekArray(weekNumber.week);
                            }}
                            key={weekNumber.week._id}
                        >
                            <ListItemText
                                primary={"Week " + weekNumber.week}
                                sx={
                                    arrWeek.includes(weekNumber.week)
                                        ? {
                                              color: "#08adff",
                                          }
                                        : {}
                                }
                            />
                            <div className="monday-to-sunday">
                                <ListItemText
                                    primary={getDateOnWeek(weekNumber)}
                                    sx={{
                                        color: arrWeek.includes(weekNumber.week)
                                            ? {
                                                  color: "#08adff",
                                              }
                                            : {},
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* <List>
                <ListItemText primary="Week" />
                {mappedWeeks.map((weeknumber, index) => (
                    <ListItem key={index}>
                        <ListItemButton
                            onClick={() => {
                                addWeekArray(weeknumber);
                            }}
                            key={weeknumber._id}
                            sx={
                                arrWeek.includes(weeknumber)
                                    ? {
                                          border: "2px solid #08adff",
                                          width: "49px",
                                          height: "49px",
                                      }
                                    : {}
                            }
                        >
                            <ListItemText
                                primary={weeknumber}
                                sx={
                                    arrWeek.includes(weeknumber)
                                        ? {
                                              color: "#0080f8   ",
                                          }
                                        : {}
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </div>
    );
};

export default WeekList;
