import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Tab from "@mui/material/Tab";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import { FC, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../shared/models/config";
import { IPersonalsGoal, PersonalsGoal } from "../../../shared/models/personalsGoal";
import { IUserGroup } from "../../../shared/models/userGroup";
import { IUserInfo } from "../../../shared/models/userInfo";
import { handleSortPriority } from "../../../utils";
import AddIcon from "@mui/icons-material/Add";
import PersonalsGoalModal from "../expectation-and-notification/GoalModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import { IPersonalsGoalState } from "../../../redux/reducers/personalGoal.reducer";
import { IAuthState } from "../../../redux/reducers/auth.reducer";
import DeleteGoalModal from "../expectation-and-notification/DeleteGoalModal";
import { ISprint } from "../../../shared/models/sprint";
const ShowGoals: FC<{ onHide: () => void; user: IUserInfo }> = ({ onHide, user }) => {
    const personalsGoal: IPersonalsGoal[] = useSelector((state: any) => state.personalsGoalState?.personalsGoal ?? []);
    return (
        <Modal show={true} centered onHide={onHide} size="lg">
            <Modal.Header>
                <Modal.Title>{user.firstName}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <ModalContent data={personalsGoal} userInfo={user} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const ModalContent: FC<{ data: IPersonalsGoal[]; userInfo: IUserInfo }> = ({ data, userInfo }) => {
    const [value, setValue] = useState(0);
    const [showItem, setShowItem] = useState<IPersonalsGoal | undefined>(undefined);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const activeSprint = sprints.find((sprint) => sprint.activeSprint === true);
    return (
        <Box sx={{ with: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab
                        style={{
                            width: "100%",
                        }}
                        label={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <div />
                                <span>Mục tiêu cá nhân</span>
                                <AddIcon
                                    onClick={() => {
                                        setShowItem(new PersonalsGoal());
                                    }}
                                />
                            </div>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        style={{
                            width: "100%",
                        }}
                        label={
                            <div
                                onClick={() => {
                                    setShowItem(undefined);
                                }}
                            >
                                Mục tiêu team
                            </div>
                        }
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ContentPersonalGoal data={data} userInfo={userInfo} showItem={showItem} activeSprint={activeSprint} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ContentTeamGoal data={data} userInfo={userInfo} activeSprint={activeSprint} />
            </TabPanel>
        </Box>
    );
};

const ContentTeamGoal: FC<{ data: IPersonalsGoal[]; userInfo: IUserInfo; activeSprint: ISprint | undefined }> = ({
    data,
    userInfo,
    activeSprint,
}) => {
    const [openDropDown, setOpenDropDown] = useState(-1);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    let isUserGroups: any[] = [];
    userGroups.forEach((groups) => {
        let users = groups.users;
        users.forEach((user) => {
            if (user.userId === userInfo._id) {
                isUserGroups.push(groups);
            }
        });
    });

    let nameTeam: any = isUserGroups.map((data, index) => {
        return data.name;
    });
    let filterPersonalsGoal = data.filter((item: IPersonalsGoal) => {
        let teams = item.teams ?? [];
        let check = false;
        teams.forEach((name) => {
            if (nameTeam.find((n: any) => n === name)) {
                check = true;
            }
        });
        return check;
    });
    let dem = 1;
    handleSortPriority(data);
    return (
        <>
            <div
                style={{
                    width: "100%",
                }}
            >
                {filterPersonalsGoal &&
                    filterPersonalsGoal
                        .filter((datas: IPersonalsGoal) => {
                            return datas.type === "team" && !(datas.status === "done" && datas.sprintID !== activeSprint?._id);
                        })
                        .map((dataMap: IPersonalsGoal, index: number) => (
                            <>
                                <TableRow
                                    sx={{ "& > *": { borderBottom: "unset" } }}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    hover
                                    onClick={() => setOpenDropDown(openDropDown === index ? -1 : index)}
                                    key={index + dataMap._id}
                                >
                                    <TableCell
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: " 100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "calc(100% - 150px)",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "20xp",
                                                    height: "20px",
                                                }}
                                                src={"/" + dataMap.priority + "_priority.svg"}
                                            />
                                            <div
                                                style={{
                                                    paddingLeft: "10px",
                                                    width: "100%",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <span>{dem++} . </span>
                                                <span>{dataMap.title}</span>
                                            </div>
                                        </div>

                                        {dataMap.status && (
                                            <div
                                                key={index + dataMap._id}
                                                style={{
                                                    color:
                                                        dataMap.status === "to do"
                                                            ? Config.COLOR_TODO_GOAL
                                                            : dataMap.status === "in progress"
                                                            ? Config.COLOR_IN_PROGRESS_GOAL
                                                            : Config.COLOR_DONE_GOAL,
                                                }}
                                            >
                                                ({dataMap.status})
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                                    <TableCell
                                        style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}
                                        colSpan={6}
                                    >
                                        <Collapse in={openDropDown === index} timeout="auto" unmountOnExit>
                                            <Box>
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            paddingTop: "15px",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <h6>Description :</h6>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: dataMap.description,
                                                            }}
                                                            style={{
                                                                paddingLeft: "10px",
                                                                wordWrap: "break-word",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
            </div>
        </>
    );
};
const ContentPersonalGoal: FC<{
    data: IPersonalsGoal[];
    userInfo: IUserInfo;
    showItem: IPersonalsGoal | undefined;
    activeSprint: ISprint | undefined;
}> = ({ data, userInfo, showItem, activeSprint }) => {
    const [openDropDown, setOpenDropDown] = useState(-1);
    const [dataItem, setDataItem] = useState<IPersonalsGoal | undefined>();
    const onHide = () => {
        setDataItem(undefined);
    };

    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    const personalsGoalState: IPersonalsGoalState = useSelector((state: any) => state.personalsGoalState);
    const personalsGoal = personalsGoalState?.personalsGoal ?? [];
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };
    const userInfoState: IAuthState = useSelector((state: any) => state.authState);

    const userInfoCreateGoal: any = userInfoState?.user ?? [];
    useEffect(() => {
        setDataItem(showItem);
    }, [showItem]);

    let isUserGroups: any[] = [];
    userGroups.forEach((groups) => {
        let users = groups.users;
        users.forEach((user) => {
            if (user.userId === userInfo._id) {
                isUserGroups.push(groups);
            }
        });
    });
    let dem = 1;
    const [dataDelete, setDataDelete] = useState();
    const handleDeleteItem = (data: any) => {
        setDataDelete(data);
        setShowDeleteModal(true);
    };
    handleSortPriority(data);

    const handleEditItem = (dataMap: IPersonalsGoal) => {
        setDataItem(dataMap);
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                }}
            >
                {data &&
                    data
                        .filter((datas: IPersonalsGoal) => {
                            return (
                                datas.userID === userInfo._id &&
                                datas.type === "individual" &&
                                !(datas.status === "done" && datas.sprintID !== activeSprint?._id)
                            );
                        })
                        .map((dataMap: IPersonalsGoal, index: number) => (
                            <>
                                <TableRow
                                    sx={{ "& > *": { borderBottom: "unset" } }}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    key={index + dataMap._id}
                                >
                                    <TableCell
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: " 100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                cursor: "pointer",
                                                width: "calc(100% - 150px)",
                                            }}
                                            onClick={() => setOpenDropDown(openDropDown === index ? -1 : index)}
                                        >
                                            <img
                                                style={{
                                                    width: "20xp",
                                                    height: "20px",
                                                }}
                                                src={"/" + dataMap.priority + "_priority.svg"}
                                            />
                                            <div
                                                style={{
                                                    paddingLeft: "10px",
                                                    width: "100%",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <span>{dem++} . </span>
                                                <span>{dataMap.title}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {dataMap.status && (
                                                <div
                                                    key={index + dataMap._id}
                                                    style={{
                                                        color:
                                                            dataMap.status === "to do"
                                                                ? Config.COLOR_TODO_GOAL
                                                                : dataMap.status === "in progress"
                                                                ? Config.COLOR_IN_PROGRESS_GOAL
                                                                : Config.COLOR_DONE_GOAL,
                                                    }}
                                                >
                                                    ({dataMap.status})
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    width: "50px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <ModeIcon
                                                    onClick={() => {
                                                        handleEditItem(dataMap);

                                                        console.log("dataMap", dataMap);
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />

                                                <DeleteForeverIcon
                                                    onClick={() => handleDeleteItem(dataMap)}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                {showDeleteModal && (
                                                    <DeleteGoalModal goal={dataDelete!} onHide={closeDeleteModal} />
                                                )}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                                    <TableCell
                                        style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}
                                        colSpan={6}
                                    >
                                        <Collapse in={openDropDown === index} timeout="auto" unmountOnExit>
                                            <Box>
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            paddingTop: "15px",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <h6>Mô tả :</h6>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: dataMap.description,
                                                            }}
                                                            style={{
                                                                paddingLeft: "10px",
                                                                wordWrap: "break-word",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                {dataItem && (
                    <>
                        <PersonalsGoalModal
                            onHide={onHide}
                            data={dataItem}
                            team={"individual"}
                            userID={userInfo._id}
                            creatorID={userInfoCreateGoal._id}
                        />
                    </>
                )}
            </div>
        </>
    );
};
export default ShowGoals;
