import { Ref, forwardRef } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ISprint } from "../../../../../shared/models/sprint";

interface Props {
    sprintId: string;
    onChange?: (id: string) => void;
    filterSprint?: (task: ISprint) => void;
    sortSprint?: (a: ISprint, b: ISprint) => number;
    disabled?: boolean;
}

const SelectSprintWidget = forwardRef<HTMLSelectElement, Props>(
    ({ sprintId, onChange, filterSprint, sortSprint, disabled }, ref: Ref<HTMLSelectElement>) => {
        const allSprint: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
        let sprints = allSprint;
        if (filterSprint) {
            sprints = sprints.filter(filterSprint);
        }
        if (sortSprint) {
            sprints = sprints.sort(sortSprint);
        }
        return (
            <Form.Select
                ref={ref}
                defaultValue={sprintId}
                onChange={(e) => onChange && onChange(e.target.value)}
                disabled={disabled}
            >
                {sprints.map((s) => (
                    <option key={s._id} value={s._id}>
                        {s.name} {s.activeSprint && "(active)"}
                    </option>
                ))}
            </Form.Select>
        );
    }
);

export default SelectSprintWidget;
