import './style.scss';
import Posts from '../posts';

const Categories = ({ categories }: any) => {

    return (
        <div className="categories">
            {categories.map((category: any, index: number) => {
                return (
                    <div className="category" key={index}>
                        <h4 dangerouslySetInnerHTML={{ __html: category.name }}></h4>
                        <Posts posts={category.posts} />
                    </div>
                )
            })}
        </div>
    )
}

export default Categories;