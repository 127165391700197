import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { AppVersion, IAppVersion } from "../../shared/models/appVersion";
import Url from "../../util/url";
import {
    AppVersionTypes,
    IAppVersionAction,
    deleteAppVersion,
    deleteAppVersionFailed,
    getAppVersionsFailed,
    getAppVersionsSuccess,
    updateAppVersionsFailed,
    updateAppVersionsSuccess,
    addInfoVersionActionSuccess,
    addInfoVersionActionFailed,
    getInfoVersionsSuccess,
    getinfoVersionsFailed,
    deleteInforVersionAction,
    deleteInfoVersionFailed,
    deleteInfoVersionSuccess,
    updateInfoVersionsSuccess,
    updateInfoVersionsFailed,
} from "../actions/appVersion.action";

async function getAppVersionsAPI(): Promise<IAppVersion[]> {
    return await axios.get(Url("app/getAppInfos")).then((data) => {
        if (data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new AppVersion(item);
            });
        }
        return [];
    });
}

async function getInfoVersionsAPI(versionId: number): Promise<IAppVersion[]> {
    return await axios
        .get(Url("appVesion/get-data?versionId=" + versionId))
        .then((data) => {
            if (data.status == 200 && data.data) {
                return data.data.map((item: any) => {
                    return new AppVersion(item);
                });
            }
            return [];
        });
}

function* getInfoVersionSaga(action: IAppVersionAction) {
    const versionId = action.versionId;
    // console.log("newAddInfoVersion", versionId);
    try {
        if (versionId) {
            const infoVersion: IAppVersion = yield call(
                getInfoVersionsAPI,
                versionId
            );
            if (infoVersion) {
                yield put(getInfoVersionsSuccess(infoVersion));
            } else {
                yield put(getinfoVersionsFailed("AppInfos not existed!"));
            }
        }
    } catch (err) {
        yield put(getinfoVersionsFailed("appSaga ERROR: " + err));
    }
}

function* appVersionSaga() {
    try {
        const appInfos: IAppVersion[] = yield call(getAppVersionsAPI);
        if (appInfos) {
            yield put(getAppVersionsSuccess(appInfos));
        } else {
            yield put(getAppVersionsFailed("AppInfos not existed!"));
        }
    } catch (err) {
        yield put(getAppVersionsFailed("appSaga ERROR: " + err));
    }
}

async function addAppInfoVersionsAPI(
    newAddInfoVersion: IAppVersion
): Promise<IAppVersion | null> {
    return await axios
        .post(Url("appVesion/create-data"), { data: newAddInfoVersion })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new AppVersion(data.data[0]);
            }
            return null;
        });
}

function* addInfoVersionSaga(action: IAppVersionAction) {
    const newAddInfoVersion = action.newAddInfoVersion;
    // console.log("newAddInfoVersion", newAddInfoVersion);
    if (newAddInfoVersion) {
        try {
            const newAppInfo: IAppVersion = yield call(
                addAppInfoVersionsAPI,
                newAddInfoVersion
            );
            if (newAppInfo) {
                yield put(addInfoVersionActionSuccess(newAddInfoVersion));
            } else {
                yield put(updateAppVersionsFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(
                addInfoVersionActionFailed("updateAppSaga ERROR: " + err)
            );
        }
    } else {
        yield put(
            addInfoVersionActionFailed(
                "updateAppSaga ERROR: appInfo not found!"
            )
        );
    }
}

async function updateAppVersionAPI(
    appVersion: IAppVersion
): Promise<IAppVersion | null> {
    return await axios
        .post(Url("app/update"), { data: appVersion })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new AppVersion(data.data[0]);
            }
            return null;
        });
}

async function updateInfoVersionAPI(
    appVersion: IAppVersion
): Promise<IAppVersion | null> {
    return await axios
        .post(Url("appVesion/update-data"), { data: appVersion })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new AppVersion(data.data[0]);
            }
            return null;
        });
}

function* updateInfoVersionSaga(action: IAppVersionAction) {
    const newAddInfoVersion = action.newAddInfoVersion;
    // console.log("newAddInfoVersion", newAddInfoVersion);
    if (newAddInfoVersion) {
        try {
            const newInfoVesion: IAppVersion = yield call(
                updateInfoVersionAPI,
                newAddInfoVersion
            );
            if (newInfoVesion) {
                yield put(updateInfoVersionsSuccess(newInfoVesion));
            } else {
                yield put(updateInfoVersionsFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(updateInfoVersionsFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            updateInfoVersionsFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

function* updateAppVersionSaga(action: IAppVersionAction) {
    const appVersion = action.appVersion;
    if (appVersion) {
        try {
            const newAppInfo: IAppVersion = yield call(
                updateAppVersionAPI,
                appVersion
            );
            if (newAppInfo) {
                yield put(updateAppVersionsSuccess(newAppInfo));
            } else {
                yield put(updateAppVersionsFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(updateAppVersionsFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            updateAppVersionsFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

async function deleteAppVersionAPI(appId: string): Promise<string | null> {
    return await axios.post(Url("app/delete"), { appId }).then((data) => {
        if (data.status == 200) {
            return appId;
        }
        return null;
    });
}

async function deleteInfoVersionAPI(appId: string): Promise<string | null> {
    return await axios
        .post(Url("appVesion/delete-data"), { appId })
        .then((data) => {
            if (data.status == 200) {
                return appId;
            }
            return null;
        });
}

function* deleteAInfoVersionSaga(action: IAppVersionAction) {
    const newAddInfoVersion = action.newAddInfoVersion;
    console.log("newAddInfoVersion._id", newAddInfoVersion?._id);
    if (newAddInfoVersion) {
        try {
            const id: string | null = yield call(
                deleteInfoVersionAPI,
                newAddInfoVersion._id
            );
            if (id) {
                yield put(deleteInfoVersionSuccess(newAddInfoVersion));
            } else {
                yield put(deleteInfoVersionFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(deleteInfoVersionFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            deleteInfoVersionFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

function* deleteAppVersionSaga(action: IAppVersionAction) {
    const appVersion = action.appVersion;
    if (appVersion) {
        try {
            const id: string | null = yield call(
                deleteAppVersionAPI,
                appVersion._id
            );
            if (id) {
                yield put(deleteAppVersion(appVersion));
            } else {
                yield put(deleteAppVersionFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(deleteAppVersionFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            deleteAppVersionFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

export function* watchAppVersion() {
    yield takeLatest(AppVersionTypes.GET_APP_VERSION, appVersionSaga);
    yield takeLatest(AppVersionTypes.UPDATE_APP_VERSION, updateAppVersionSaga);
    yield takeLatest(AppVersionTypes.DELETE_APP_VERSION, deleteAppVersionSaga);
    yield takeLatest(AppVersionTypes.ADD_INFO_VERSION, addInfoVersionSaga);
    yield takeLatest(AppVersionTypes.GET_INFO_VERSION, getInfoVersionSaga);
    yield takeLatest(
        AppVersionTypes.DELETE_INFO_VERSION,
        deleteAInfoVersionSaga
    );
    yield takeLatest(
        AppVersionTypes.UPDATE_INFO_VERSION,
        updateInfoVersionSaga
    );
}
