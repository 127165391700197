import Calendar from "react-calendar";
import "./styles.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Url from "../../util/url";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../shared/models/userInfo";
import { isIntern } from "../../shared/permissions";
import { Link, Redirect } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Config from "../../shared/models/config";

interface registerDatesProps {
    date: Date;
    slot: string;
    type: "onl" | "off";
    className?: string;
}

function WorkScheduleRegister() {
    const userInfo: IUserInfo = useSelector(
        (state: any) => state.authState.user
    );
    const { _id } = userInfo;

    const [selectSlotPopoverVisible, setSelectSlotPopoverVisible] =
        useState(false);
    const [selectSlotPopoverPosition, setSelectSlotPopoverPosition] =
        useState<any>(false);
    const [selectingDate, setSelectingDate] = useState(null);
    const [preRegisteredDates, setPreRegisteredDates] = useState<
        registerDatesProps[]
    >([]);

    // Call API lấy data
    const callApi = async () => {
        try {
            const response = await axios.get(Url(`register-schedule/${_id}`));
            return response.data;
        } catch (error) {
            alert("Lỗi Server!!!");
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await callApi();
                results.map((result: any) =>
                    // Set data
                    setPreRegisteredDates((preRegisteredDates) => [
                        ...preRegisteredDates,
                        {
                            date: new Date(result.date),
                            slot: result.slot,
                            type: result.type,
                        },
                    ])
                );
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const areSameDate = (date1: any, date2: any) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getYear() === date2.getYear()
        );
    };

    //     Click vào 1 ngày
    const bookADate = (date: any, event: any) => {
        setSelectSlotPopoverVisible(true);
        setSelectSlotPopoverPosition([
            event.target.offsetLeft + 50,
            event.target.offsetTop + 20,
        ]);

        setSelectingDate(date);
    };

    //     Xử lý select các option register
    const onSelectRegistrationDetail = (slot: any, type: any) => {
        if (selectingDate) {
            if (
                !preRegisteredDates.find((d: any) =>
                    areSameDate(d.date, selectingDate)
                )
            ) {
                // them vào
                if (slot !== "cancel") {
                    setPreRegisteredDates(
                        preRegisteredDates.concat({
                            date: selectingDate,
                            slot,
                            type,
                        })
                    );
                }
            } else if (slot !== "cancel") {
                //update
                setPreRegisteredDates(
                    preRegisteredDates.map((d: any) => {
                        if (areSameDate(d.date, selectingDate)) {
                            return { ...d, slot, type, className: "modify" };
                        }
                        return d;
                    })
                );
            } else {
                //xoa
                setPreRegisteredDates(
                    preRegisteredDates.filter(
                        (d: any) => !areSameDate(d.date, selectingDate)
                    )
                );
            }
        }
        setTimeout(() => {
            setSelectSlotPopoverVisible(false);
        }, 300);
    };

    //     Set Class cho các ô ngày
    const setTileClassName = (date: any) => {
        const preRegisteredDate: any = preRegisteredDates.find(
            (d: registerDatesProps) => areSameDate(d.date, date.date)
        );
        if (preRegisteredDate) {
            if (preRegisteredDate.type === Config.TYPE.offday) {
                return (
                    preRegisteredDate.slot + " " + preRegisteredDate.className
                );
            }
            // Nếu dky onl
            // if (preRegisteredDate.type === "onl")
            //     return (
            //         preRegisteredDate.slot +
            //         "-" +
            //         preRegisteredDate.type +
            //         " " +
            //         preRegisteredDate.className
            //     );
        }

        return "";
    };

    // Xử lý submit
    const handleSubmitRegistration = async () => {
        if (_id) {
            const dataSubmit: any = [];
            preRegisteredDates.forEach((d: registerDatesProps) => {
                dataSubmit.push({
                    userId: _id,
                    date: d.date,
                    slot: d.slot,
                    type: d.type,
                });
                d.className = "";
            });

            try {
                const result = await axios.post(
                    Url("register-schedule"),
                    dataSubmit
                );
                if (result) {
                    if (result.status === 200) {
                        alert(result.data.message);
                    }
                }
            } catch (error) {
                console.log(error);
                alert("Đăng ký thất bại");
            }
        }
    };

    return userInfo.role === Config.USER_ROLE_INTERN ? (
        <div className="work-schedule-register">
            <Modal.Header className="header">
                <Modal.Title className="text-white">
                    Đăng ký lịch làm việc
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Calendar
                    className="calendar"
                    tileClassName={setTileClassName}
                    minDate={new Date()}
                    onClickDay={(value, event) => bookADate(value, event)}
                />

                {/* Popover hiển thị khi click vào các ô ngày */}
                {selectSlotPopoverVisible && (
                    <div
                        className="registration-detail-dialog shadow"
                        style={{
                            top: selectSlotPopoverPosition[1],
                            left: selectSlotPopoverPosition[0],
                        }}
                    >
                        {[
                            {
                                label: "Cả ngày",
                                value: Config.ALL_DAY,
                                type: Config.TYPE.offday,
                            },
                            {
                                label: "Buổi sáng",
                                value: Config.MORNING,
                                type: Config.TYPE.offday,
                            },
                            {
                                label: "Buổi chiều",
                                value: Config.AFTERNOON,
                                type: Config.TYPE.offday,
                            },
                            // {
                            //     label: "Cả ngày (Online) ",
                            //     value: "allday",
                            //     type: "onl",
                            // },
                            // {
                            //     label: "Buổi sáng (Online)",
                            //     value: "morning",
                            //     type: "onl",
                            // },
                            // {
                            //     label: "Buổi chiều (Online)",
                            //     value: "afternoon",
                            //     type: "onl",
                            // },
                            {
                                label: "Bỏ đăng ký",
                                value: "cancel",
                                type: "",
                            },
                        ].map((option, index) => {
                            return (
                                <Form.Check
                                    type="radio"
                                    key={index}
                                    label={option.label}
                                    id={
                                        "registration-" +
                                        option.value +
                                        "-" +
                                        option.type +
                                        " " +
                                        ""
                                    }
                                    name="registration-detail"
                                    onChange={() =>
                                        onSelectRegistrationDetail(
                                            option.value,
                                            option.type
                                        )
                                    }
                                />
                            );
                        })}
                        <CloseIcon
                            className="closeIndices"
                            onClick={() => setSelectSlotPopoverVisible(false)}
                        />
                    </div>
                )}

                <div className="indices">
                    <div>
                        <div className="indice allday">
                            <span></span>
                            Cả ngày
                        </div>
                        <div className="indice morning">
                            <span></span>
                            Buổi sáng
                        </div>
                        <div className="indice afternoon">
                            <span></span>
                            Buổi chiều
                        </div>
                    </div>
                    {/* <div>
                        <div className="indice allday-onl">
                            <span></span>
                            Cả ngày (Online)
                        </div>
                        <div className="indice morning-onl">
                            <span></span>
                            Buổi sáng (Online)
                        </div>
                        <div className="indice afternoon-onl">
                            <span></span>
                            Buổi chiều (Online)
                        </div>
                    </div> */}
                </div>
            </Modal.Body>
            <div className="footer">
                <Link to="/dashboard">
                    <Button variant="primary" className="cancel-btn">
                        Trở lại
                    </Button>
                </Link>
                <Button
                    variant="success"
                    className="confirm-btn"
                    onClick={handleSubmitRegistration}
                >
                    Xác nhận
                </Button>
            </div>
        </div>
    ) : (
        <Redirect to={{ pathname: "/dashboard" }} />
    );
}

export default WorkScheduleRegister;
