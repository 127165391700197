import { memo, useEffect, useRef, useState } from "react";
import { Form, Modal, ModalFooter } from "react-bootstrap";
import MButton from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateDataPersonalsGoalAction } from "../../../redux/actions/personalsGoal.action";
import { IAuthState } from "../../../redux/reducers/auth.reducer";
import Config from "../../../shared/models/config";
import { IPersonalsGoal, PersonalsGoal } from "../../../shared/models/personalsGoal";
import EditorWidget from "../../ckeditor/editor-widget";
import MyTextBox from "../widgets/MyTextBox";
import Select, { ActionMeta, MultiValue } from "react-select";
import "./goals-modal.scss";
import { ISprint } from "../../../shared/models/sprint";
type OptionType = {
    value: string;
    label: string;
};
const PersonalsGoalModal = memo(
    ({
        onHide,
        data,
        team,
        nameTeam,
        userID,
        creatorID,
    }: {
        onHide: () => void;
        data: IPersonalsGoal;
        team?: String;
        nameTeam?: string[];
        userID: String;
        creatorID: String;
    }) => {
        const dispatch = useDispatch();
        const titleRef = useRef<HTMLInputElement | null>(null);
        const [priority, setPriority] = useState("");
        const [description, setDescription] = useState("");
        const [teams, setTeam] = useState<OptionType[]>([]);
        const [dataTeams, setDataTeam] = useState<string[]>([]);
        const [status, setStatus] = useState<string>("");
        const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
        const activeSprint = sprints.find((sprint) => sprint.activeSprint === true);
        const userInfoState: IAuthState = useSelector((state: any) => state.authState);
        const userInfo: any = userInfoState?.user ?? [];
        useEffect(() => {
            setPriority(data.priority ?? "");
            setDescription(data.description ?? "");
            setTeam(showOptions ?? []);
            setStatus(data.status ?? "");
            if (!data._id) {
                setStatus("to do");
                setPriority(Config.PRIORITY_POOL.MEDIUM);
            }
        }, [data]);
        const addPersonalsGoal = async () => {
            const newAddPersonalsGoal: IPersonalsGoal = new PersonalsGoal({
                type: team,
                userID: userID ?? "",
                title: titleRef.current?.value ?? "",
                priority: priority ?? "",
                description: description ?? "",
                teams: dataTeams ?? [],
                status: status ?? "",
                creatorID: creatorID ?? "",
                sprintID: activeSprint?._id ?? "",
            });
            if (!newAddPersonalsGoal.title) {
                toast("Chưa có mục tiêu !", { position: "top-center" });
                return;
            }
            if (!priority) {
                toast("Chưa có ưu tiên !", { position: "top-center" });
                return;
            }
            if (!status) {
                toast("Chưa có trạng thái !", { position: "top-center" });
                return;
            }
            if (team === "team" && newAddPersonalsGoal.teams.length == 0) {
                toast("Chưa có team!", { position: "top-center" });
                return;
            }
            if (status === "done") {
                toast("Không thể tạo mục tiêu trong trạng thái done!", {
                    position: "top-center",
                });
                return;
            }
            dispatch(updateDataPersonalsGoalAction(newAddPersonalsGoal));
            onHide();
        };
        const handleCloseEdit = () => {
            onHide();
        };
        const handleEditItem = async () => {
            const newEditPersonalsGoal: IPersonalsGoal = new PersonalsGoal({
                _id: data._id,
                userID: userID ?? "",
                type: team,
                title: titleRef.current?.value ?? "",
                priority: priority ?? "",
                description: description ?? "",
                teams: dataTeams ?? [],
                status: status ?? "",
                creatorID: data.creatorID ?? "",
                sprintID: data.sprintID ?? "",
            });
            if (!newEditPersonalsGoal.title) {
                toast("Chưa có mục tiêu !", { position: "top-center" });
                return;
            }
            if (!priority) {
                toast("Chưa có ưu tiên !", { position: "top-center" });
                return;
            }
            if (!status) {
                toast("Chưa có trạng thái !", { position: "top-center" });
                return;
            }
            if (team === "team" && newEditPersonalsGoal.teams.length == 0) {
                toast("Chưa có team!", { position: "top-center" });
                return;
            }
            dispatch(updateDataPersonalsGoalAction(newEditPersonalsGoal));
            onHide();
        };

        const options =
            userInfo.role === Config.USER_ROLE_ADMIN
                ? Config.TEAMS_GOALS
                : nameTeam!?.map((item) => ({ value: item, label: item }));
        let data_team: string[] = teams?.map((d) => d.value);

        useEffect(() => {
            setDataTeam(data_team ?? []);
        }, [teams]);

        let showOptions: OptionType[];
        if (data._id) {
            showOptions = data?.teams?.map((dt: any) => {
                return {
                    value: dt.replace(/\s+/g, ""),
                    label: dt.replace(/\s+/g, ""),
                };
            });
        }
        const setDisabled: boolean =
            !(userInfo.role === Config.USER_ROLE_ADMIN) && !(userInfo.role === Config.USER_ROLE_LEADER) && data.type === "team";

        return (
            <Modal show={true} size="lg" onHide={onHide} centered className="container-modal-goals">
                <Modal.Header>
                    {!data._id ? (
                        <Modal.Title>Thêm mới mục tiêu</Modal.Title>
                    ) : (
                        <div>
                            {!setDisabled ? (
                                <Modal.Title>Chỉnh sửa mục tiêu</Modal.Title>
                            ) : (
                                <Modal.Title>Xem mục tiêu</Modal.Title>
                            )}
                        </div>
                    )}
                </Modal.Header>
                <Modal.Body className="body-modal-goals">
                    <div className="item-modals-goals">
                        <h5
                            style={{
                                textAlign: "center",
                                margin: "10px",
                            }}
                        >
                            Mục Tiêu
                        </h5>
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            <MyTextBox ref={titleRef} text={data.title} type="numeric" title={""} disabled={setDisabled} />
                        </div>
                    </div>
                    <div className="item-modals-goals">
                        <h5
                            style={{
                                textAlign: "center",
                                margin: "10px",
                            }}
                        >
                            Ưu tiên
                        </h5>
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            <Form.Select value={priority} onChange={(e) => setPriority(e.target.value)} disabled={setDisabled}>
                                {["", ...Object.values(Config.PRIORITY_POOL)].map((p, index) => (
                                    <option key={index} value={p}>
                                        {p}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="item-modals-goals">
                        <h5
                            style={{
                                textAlign: "center",
                                margin: "10px",
                            }}
                        >
                            Mô tả
                        </h5>
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            {!setDisabled ? (
                                <EditorWidget
                                    buttonSave={false}
                                    defaultValue={description}
                                    onChange={(v) => {
                                        setDescription(v);
                                    }}
                                    isOnChange={true}
                                    // disabled={setDisabled}
                                />
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                    style={{
                                        paddingLeft: "10px",
                                        wordWrap: "break-word",
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                    {!(team === "individual") && (
                        <div className="item-modals-goals">
                            <h5
                                style={{
                                    textAlign: "center",
                                    margin: "10px",
                                }}
                            >
                                Nhóm
                            </h5>
                            {team === "team" && (
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                >
                                    <Select
                                        isMulti
                                        value={teams}
                                        options={options}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(selectedOptions) => setTeam(selectedOptions as OptionType[])}
                                        isDisabled={setDisabled}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="item-modals-goals">
                        <h5
                            style={{
                                textAlign: "center",
                                margin: "10px",
                            }}
                        >
                            Trạng thái
                        </h5>
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} disabled={setDisabled}>
                                {["", ...Object.values(Config.TARGET_STATUS)].map((status, index) => (
                                    <option key={index} value={typeof status === "string" ? status : status.name}>
                                        {typeof status === "string" ? status : status.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter>
                    {!data._id && !setDisabled ? (
                        <MButton onClick={() => addPersonalsGoal()}>Lưu</MButton>
                    ) : (
                        <div>{!setDisabled ? <MButton onClick={() => handleEditItem()}>Cập Nhật</MButton> : <div></div>}</div>
                    )}
                    <MButton className="close" aria-label="Close" onClick={() => handleCloseEdit()} variant="secondary">
                        Đóng
                    </MButton>
                </ModalFooter>
            </Modal>
        );
    }
);

export default PersonalsGoalModal;
