import { convertDate } from "../utils";
import { IBasic } from "./basic";

export type MediaType = 'image/*' | 'image/png' | 'image/jpg' | 'image/jpeg' | 'application/json' | '.plist' | '.realm' | '.db' | 'media_type' | 'image/jpg,image/gif';
export interface IAppThemeData {
    key: string
    values: string[]
    mediaType: MediaType
    multiple: boolean
    index: number
    targetPath: string[]
}

export const getListMediaType = (): MediaType[] => {
    return [
        'image/*', 'image/png' , 'image/jpg' , 'image/jpeg' , 'application/json' , '.plist' , '.realm' , '.db' , 'media_type', "image/jpg,image/gif"
    ];
}

export const getListTargetPath = () => {
    return [
        "assets/databases",
        "assets/splash",
        "assets/static",
        "assets/static/apps",
        "assets/static/icon",
        "assets/static/logo",
        "ios",
        "android",
        "android/app"
    ];
}

export class AppThemeData implements IAppThemeData {
    key: string
    values: string[]
    mediaType: MediaType
    multiple: boolean
    index: number
    targetPath: string[]

    constructor(props: any = {}) {
        this.key = props.key ?? "";
        this.values = props.values ?? [];
        this.mediaType = props.mediaType ?? "media_type";
        this.multiple = !!props.multiple;
        this.index = props.index ?? -1;
        this.targetPath = props.targetPath ?? [];
    }
}

export interface IAppTheme extends IBasic {
    appInfoId: string // mongo id
    lastUpdate: Date | null
    data: IAppThemeData[]
    root: boolean
}

export class AppTheme implements IAppTheme {
    _id: any
    appInfoId: string // mongo id
    lastUpdate: Date | null
    data: IAppThemeData[]
    root: boolean

    constructor(props: any = {}) {
        this._id = props._id;
        this.appInfoId = props.appInfoId;
        this.lastUpdate = convertDate(props.lastUpdate);
        this.data = [];
        for(let item of props.data ?? []) {
            this.data.push(new AppThemeData(item));
        }
        this.root = !!props.root;
    }
}