export enum SharingTypes {
    GET_SHARING = "GET_SHARING",
    GET_SHARING_SUCCESS = "GET_SHARING_SUCCESS",
    GET_SHARING_FAILED = "GET_SHARING_FAILED",
    CREATE_SHARING = "CREATE_SHARING",
    CREATE_SHARING_SUCCESS = "CREATE_SHARING_SUCCESS",
    DELETE_SHARING = "DELETE_SHARING",
    DELETE_SHARING_SUCCESS = "DELETE_SHARING_SUCCESS",

    GET_USER_DATA_SHARING = "GET_USER_DATA_SHARING",
    GET_USER_DATA_SHARING_SUCCESS = "GET_USER_DATA_SHARING_SUCCESS",
    ADD_SCORE_SHARING = "ADD_SCORE_SHARING",
    ADD_SCORE_SHARING_SUCCESS = "ADD_SCORE_SHARING_SUCCESS",
}

export interface ISharingAction {
    type: SharingTypes;
    sharingDatas?: any[];
    sharingData?: any;
    id?: any;
    userData?: any;
    userDatas?: any[];
    // loading: boolean;
}

export const getSharingSchedule = (): ISharingAction => {
    return {
        type: SharingTypes.GET_SHARING,
    };
};

export const getSharingScheduleSuccess = (sharingDatas: []): ISharingAction => {
    return {
        type: SharingTypes.GET_SHARING_SUCCESS,
        sharingDatas,
    };
};

export const createSharingSchedule = (sharingData: any, id?: any): ISharingAction => {
    return {
        type: SharingTypes.CREATE_SHARING,
        sharingData,
        id,
    };
};

export const createSharingScheduleSuccess = (sharingData: any, id?: any): ISharingAction => {
    return {
        type: SharingTypes.CREATE_SHARING_SUCCESS,
        sharingData,
        id,
    };
};

export const deleteSharingSchedule = (id: any, sharingData: any): ISharingAction => {
    return {
        type: SharingTypes.DELETE_SHARING,
        id,
        sharingData,
    };
};

export const deleteSharingScheduleSuccess = (id: any, sharingData: any): ISharingAction => {
    return {
        type: SharingTypes.DELETE_SHARING_SUCCESS,
        id,
        sharingData,
    };
};

export const getUserDataSharing = (): ISharingAction => {
    return {
        type: SharingTypes.GET_USER_DATA_SHARING,
    };
};

export const getUserDataSharingSuccess = (userDatas: any[]): ISharingAction => {
    return {
        type: SharingTypes.GET_USER_DATA_SHARING_SUCCESS,
        userDatas,
    };
};
export const addScoreSharing = (id: any, userData: any): ISharingAction => {
    return {
        type: SharingTypes.ADD_SCORE_SHARING,
        id,
        userData,
    };
};

export const addScoreSharingSuccess = (id: any, userData: any): ISharingAction => {
    return {
        type: SharingTypes.ADD_SCORE_SHARING_SUCCESS,
        id,
        userData,
    };
};

// export const getSharingScheduleSuccess = (): ISharingAction => {
//     return {
//         type: SharingTypes.GET_SHARING_SUCCESS,
//     };
// };
// export const getAllSharingScheduleFailed = (): ISharingAction => {
//     return {
//         type: SharingTypes.GET_SHARING_FAILED,
//     };
// };

// export const changeLoadingAction = (loading: boolean): ISharingAction => {
//     console.log(loading);

//     return {
//         type: SharingTypes.CHANGE_LOADING,
//         loading,
//     };
// };
