import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getAllTestSectionFailed,
    getAllTestSectionSuccess,
    TestSectionTypes,
} from "../actions/testSection.action";
import { ITestSection } from "../../shared/models/testSection";
import Url from "../../util/url";

export async function getTestSectionApi(id: any) {
    const { data } = await axios.get(
        Url("test-case/get-test-sections?taskID=" + id)
    );
    return data;
}

function* getTestSection(action: any) {
    try {
        const data: ITestSection[] = yield call(getTestSectionApi, action.data);
        yield put(getAllTestSectionSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getAllTestSectionFailed(error));
    }
}

export function* watchGetTestSection() {
    yield takeLatest(TestSectionTypes.GET_ALL_TEST_SECTION, getTestSection);
}
