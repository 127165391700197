import React from "react";
import Constants from "../../../../constant";
import { ICertificates } from "../../../../models/certificates.model";
import { hasAppFlutter } from "../../../../util";
import "./index.scss";
import RevenuePlatformView from "./revenue-platform-view";
import RevenuePlatformWebView from "./revenue-platform-web-view";
const RevenueView = ({ certificates }: { certificates: ICertificates }) => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            {hasAppFlutter(certificates) && (
                <div
                    style={{
                        width: "100%",
                    }}
                >
                    <RevenuePlatformView
                        certificates={certificates}
                        platformIds={[
                            Constants.PLATFORM_FLUTTER_ANDROID,
                            Constants.PLATFORM_FLUTTER_IOS,
                        ]}
                    />
                </div>
            )}
            <div
                style={{
                    width: "100%",
                }}
            >
                <RevenuePlatformWebView
                    certificates={certificates}
                    platformIds={[Constants.PLATFORM_WEB]}
                />
            </div>
            {/* <RevenuePlatformView
                certificates={certificates}
                platformIds={[
                    Constants.PLATFORM_REACT_NATIVE_ANDROID,
                    Constants.PLATFORM_REACT_NATIVE_IOS,
                ]}
            /> */}
        </div>
    );
};
export default RevenueView;
