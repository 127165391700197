import { useEffect, useState } from "react";
import Url from "../../util/url";
import { useDispatch, connect } from "react-redux";
import LeaderBoard from "../home/LeaderBoard";
import axios from "axios";
import ribbon from "../../assets/images/ribbon_leaderboard.png";
import NavBar from "../Navbar";
import Button from "react-bootstrap/Button";
import "react-calendar/dist/Calendar.css";
import Feedback from "../feedback";
import CareerEvent from "../career-event";
import moment from "moment";
// import ListItems from "../item/ListItems";
// import HistoryPointTable from "./HistoryPointTable";
// import RequestShoppingModal from "./RequestShopping/RequestShoppingModal";
// import MyItem from "../item/MyItems";
import "./home.scss";
import { Redirect, useHistory } from "react-router";
// import split from "../../assets/images/Split-shop.png";
import WorkingCalendar from "../working-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faLaptopHouse,
    faPhoneSlash,
    faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
// import UpdateWorkingTimeModal from "../update-working-time";
// import Request from "../request";
// import WorkingTimeWidget from "./WorkingTimeWidget";
// import Constants from "../../constant";
import ManageDevice from "./ManageDevice";
import { isAdmin } from "../../util";
// import { isIntern } from "../../shared/permissions";
import Config from "../../shared/models/config";

const Home = ({ users, feedbacks, events, page, bonuses, requests }) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const currentId = userInfo._id;
    const role = userInfo.role;
    const history = useHistory();
    const [leaderboard, setLeaderBoard] = useState("total");
    // const [errorNoData, setErrorNoData] = useState(false);
    // const [items, setItems] = useState("");
    // const [allItems, setAllItems] = useState("");
    // const [requestShoppingVisible, setRequestShoppingVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(async () => {
        try {
            leaderBoardByTotal();
            // getHistoryBonus();
            // const request = await getListRequest();
            // const allRequest = await getAllRequest();
            // setItems(request);
            // setAllItems(allRequest);
            dispatch({
                type: "SET_BONUS_BY_USER",
                data: [],
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // useEffect(() => {
    //     if (feedbacks.length) {
    //         console.log(feedbacks);
    //     }
    // }, [feedbacks]);

    const leaderBoardByTotal = async () => {
        const resTotal = await axios.get(Url("point/rankings"));
        dispatch({
            type: "GET_ALL_USER",
            data: resTotal?.data?.response && resTotal?.data?.response.filter((user) => user.role !== 1),
        });
    };

    // const getHistoryBonus = async () => {
    //     console.log("here1");
    //     const res = await axios.get(Url("point/user/") + currentId);
    //     const data = res?.data;
    //     const response = data?.response;
    //     if (data?.success) {
    //         const dataMap = response?.map((item, index) => {
    //             return {
    //                 id: item._id,
    //                 edit_date: moment(item.edit_date).format("DD/MM"),
    //                 point: item.point,
    //                 reason: item.reason,
    //                 name: item.firstName + " " + item.lastName,
    //                 position: item.position,
    //                 role: item.role,
    //                 avatar: item.avatar,
    //                 hide: item.hide,
    //                 seen: item.seen,
    //                 userId: item.userId,
    //             };
    //         });
    //         dispatch({
    //             type: "SET_DATA_NOTIFICATION",
    //             data: dataMap && dataMap,
    //         });
    //         return dataMap;
    //     } else {
    //         console.log('axios.get(Url("point/user/") + currentId) error');
    //         // setErrorNoData(true);
    //     }
    // };

    // const getListRequest = async () => {
    //     let url = Url("request/" + currentId);
    //     const res = await axios.get(url);
    //     if (res.data.success) {
    //         const dataResquest = res.data?.response.map((item, index) => {
    //             return {
    //                 id: item._id,
    //                 userId: item.userId,
    //                 username: item.firstName + " " + item.lastName,
    //                 avatar: item.avatar,
    //                 item: item.item,
    //                 status: item.status,
    //                 description: item.description,
    //                 price: item.price,
    //                 date: moment(item.date).format("ll"),
    //             };
    //         });
    //         dispatch({ type: "SET_MY_ITEM", data: dataResquest });
    //         return dataResquest;
    //     }
    // };

    // const getAllRequest = async () => {
    //     let url = Url("request");
    //     const res = await axios.get(url);
    //     if (res.data.success) {
    //         const dataResquest = res.data?.response.map((item, index) => {
    //             return {
    //                 id: item._id,
    //                 userId: item.userId,
    //                 name: item.firstName + " " + item.lastName,
    //                 imgItem: item.item[0].item,
    //                 status: item.status,
    //                 description: item.item[0].description,
    //                 avatar: item.avatar,
    //                 date: moment(item.date).format("DD/MM"),
    //                 price: item.item[0].price,
    //                 seen: item.seen,
    //             };
    //         });
    //         dispatch({
    //             type: "SET_REQUEST",
    //             data: dataResquest && dataResquest,
    //         });
    //         return dataResquest;
    //     } else {
    //         // setErrorNoData(true);
    //     }
    // };

    useEffect(async () => {
        const res = await axios.get(Url("comment/get-comment/" + currentId));
        res.data.success && dispatch({ type: "SET_USER_FEEDBACK", data: res.data.response });
    }, []);

    useEffect(async () => {
        const res = await axios.get(Url("item"));
        if (res.data?.success) {
            const data = res.data?.response.map((item, index) => {
                return {
                    id: item._id,
                    index: index + 1,
                    item: item.item,
                    description: item.description,
                    price: item.price,
                };
            });
            dispatch({ type: "SET_ITEM", data });
        } else {
            console.log('axios.get(Url("comment/get-comment/" + currentId)) error');
            // setErrorNoData(true);
        }
    }, []);

    return (
        <>
            {/* {page && ( */}
            <div className="container home">
                <div className="box">
                    <div className="wrap-leaderboard">
                        <div className="ribbon">
                            <img src={ribbon} alt="ribbon" />
                            <p className="title">Tháng 12</p>
                        </div>
                        <div className="leaderboard">
                            {leaderboard === "total" ? (
                                <div className="view-leaderboard">
                                    <LeaderBoard chart={users} />
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="right outoffice-registration-container">
                        <div className="navbar-position">
                            <NavBar page={page} />
                        </div>
                        <div className="body">
                            {/* {page === "Home" || page === "Dashboard" ? ( */}
                            <div className="dashboard">
                                {feedbacks && role !== 1 ? (
                                    <div className="feedback">
                                        <Feedback userId={currentId} feedback={feedbacks} />
                                    </div>
                                ) : null}
                                {events && role !== 1 && (
                                    <div className="event">
                                        <CareerEvent userId={currentId} events={events} />
                                    </div>
                                )}

                                <div className="outoffice-registration">
                                    <div className="services">
                                        {/* <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname:
                                                                "/wfh-request",
                                                        });
                                                        <Redirect to="/wfh-request" />;
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLaptopHouse}
                                                    />
                                                    Đăng kí làm Online
                                                </Button> */}
                                        {userInfo.role === Config.USER_ROLE_INTERN ? (
                                            <Button
                                                variant="info"
                                                className="text-white"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/work-schedule-register",
                                                    });
                                                    <Redirect to="/work-schedule-register" />;
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCalendarWeek} />
                                                Đăng kí lịch làm việc
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="info"
                                                className="text-white"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/schedule-intern-view",
                                                    });
                                                    <Redirect to="/schedule-intern-view" />;
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCalendarWeek} />
                                                Xem lịch làm việc (Intern)
                                            </Button>
                                        )}
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/day-off-request",
                                                });
                                                <Redirect to="/day-off-request" />;
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPhoneSlash} />
                                            Đăng kí nghỉ phép
                                        </Button>
                                        {/* <WorkingTimeWidget /> */}
                                        <Button
                                            className="request-shopping-btn"
                                            variant="primary"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/shopping-request",
                                                });
                                                <Redirect to="/shopping-request" />;
                                            }}
                                        >
                                            Yêu cầu mua sắm
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/sprint/active",
                                                });
                                                <Redirect to="/sprint/" />;
                                            }}
                                            style={{
                                                background: "#8536CD",
                                                border: "#8536CD",
                                            }}
                                        >
                                            Active Sprint
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/wiki",
                                                });
                                                <Redirect to="/wiki" />;
                                            }}
                                            style={{
                                                background: "rgb(240, 144, 101)",
                                                border: "rgb(240, 144, 101)",
                                            }}
                                        >
                                            Wiki
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/question-quality",
                                                });
                                                <Redirect to="/question-quality/" />;
                                            }}
                                            style={{
                                                background: "#56d382",
                                                border: "#56d382",
                                            }}
                                        >
                                            Question Quality
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/create-blog",
                                                });
                                                <Redirect to="/create-blog" />;
                                            }}
                                            style={{
                                                background: "#56d382",
                                                border: "#56d382",
                                            }}
                                        >
                                            Create Blog
                                        </Button>
                                        {isAdmin(userInfo) && (
                                            <Button
                                                variant="danger"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/dashboard-data-analytic",
                                                    });
                                                    <Redirect to="/dashboard-data-analytic" />;
                                                }}
                                            >
                                                Dashboard Analytic
                                            </Button>
                                        )}
                                        <ManageDevice />
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/work-schedule",
                                                });
                                                <Redirect to="/work-schedule" />;
                                            }}
                                            style={{
                                                background: "#6699ff",
                                                border: "#6699ff",
                                            }}
                                        >
                                            Schedule
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/holiday-request",
                                                });
                                                <Redirect to="/holiday-request" />;
                                            }}
                                            style={{
                                                background: "#2d69df",
                                                border: "#2d69df",
                                            }}
                                        >
                                            Đăng kí ngày nghỉ lễ
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/complementday-request",
                                                });
                                                <Redirect to="/complementday-request" />;
                                            }}
                                            style={{
                                                background: "#A8A325",
                                                border: "#A8A325",
                                            }}
                                        >
                                            Đăng kí ngày làm bù
                                        </Button>
                                    </div>
                                </div>

                                <WorkingCalendar />
                            </div>
                            {/* ) : null} */}

                            {/* {page === "Shop" ? (
                                    <div>
                                        <div className="list-item-exchange">
                                            <ListItems />
                                        </div>
                                        <img className="split" src={split} />
                                        <p className="text">Item đã đổi</p>
                                        <div className="my-exchanged-item">
                                            <MyItem id={currentId} />
                                        </div>
                                    </div>
                                ) : null}

                                {page === "Notifications" ? (
                                    <div className="notifications">
                                        <HistoryPointTable
                                            bonus={bonuses}
                                            role={role}
                                            actionShow={true}
                                        />
                                        {requests && (
                                            <Request request={requests} />
                                        )}
                                    </div>
                                ) : null} */}
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

function mapStateToProps(state, props) {
    return {
        users: state.user,
        feedbacks: state.feedback.data,
        events: state.event.data,
        page: state.page,
        bonuses: state.notifications.data,
        requests: state.request.data,
    };
}

export default connect(mapStateToProps, {})(Home);
