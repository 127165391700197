const Notification = ({ haveNotification }: { haveNotification: boolean }) => {
    // let { haveNotification } = props;
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity={0.4}
                d="M19.3399 14.4904L18.3399 12.8304C18.1299 12.4604 17.9399 11.7604 17.9399 11.3504V8.82039C17.9399 5.56039 15.2899 2.90039 12.0199 2.90039C8.74994 2.90039 6.09994 5.56039 6.09994 8.82039V11.3504C6.09994 11.7604 5.90994 12.4604 5.69994 12.8204L4.68994 14.4904C4.28994 15.1604 4.19994 15.9004 4.44994 16.5804C4.68994 17.2504 5.25994 17.7704 5.99994 18.0204C7.93994 18.6804 9.97994 19.0004 12.0199 19.0004C14.0599 19.0004 16.0999 18.6804 18.0399 18.0304C18.7399 17.8004 19.2799 17.2704 19.5399 16.5804C19.7999 15.8904 19.7299 15.1304 19.3399 14.4904Z"
                fill="white"
            />
            <path
                d="M14.2498 3.32C13.5598 3.05 12.8098 2.9 12.0198 2.9C11.2398 2.9 10.4898 3.04 9.7998 3.32C10.2298 2.51 11.0798 2 12.0198 2C12.9698 2 13.8098 2.51 14.2498 3.32Z"
                fill="white"
            />
            <path
                d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                fill="white"
            />
            {haveNotification ? (
                <circle cx={19} cy={17} r={3} fill="#F06666" />
            ) : (
                <></>
            )}
        </svg>
    );
};
export default Notification;
