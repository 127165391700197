import { IPersonalsGoal } from "../../shared/models/personalsGoal";
import {
    IPersonalsGoalAction,
    PersonalsGoalTypes,
} from "../actions/personalsGoal.action";

export interface IPersonalsGoalState {
    loading: boolean;
    personalsGoal: IPersonalsGoal[];
    error?: any;
}

const initState: IPersonalsGoalState = {
    loading: false,
    personalsGoal: [],
};
const personalsGoalState = (
    state = initState,
    action: IPersonalsGoalAction
): IPersonalsGoalState => {
    switch (action.type) {
        case PersonalsGoalTypes.GET_DATA_PERSONALS:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case PersonalsGoalTypes.GET_DATA_PERSONALS_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    // personalsGoal: [],
                    personalsGoal: action.personalsGoals ?? [],
                },
            };
        case PersonalsGoalTypes.GET_DATA_PERSONALS_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case PersonalsGoalTypes.DELETE_DATA_PERSONALS:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case PersonalsGoalTypes.DELETE_DATA_PERSONALS_SUCCESS:
            if (action.personalsGoal && state.personalsGoal) {
                state.personalsGoal = state.personalsGoal.filter(
                    (item: any) => item._id != action.personalsGoal?._id
                );
            }
            return {
                ...state,
                ...{
                    loading: false,
                },
            };
        case PersonalsGoalTypes.DELETE_DATA_PERSONALS_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                },
            };
        case PersonalsGoalTypes.UPDATE_DATA_PERSONALS:
            return {
                ...state,
            };
        case PersonalsGoalTypes.UPDATE_DATA_PERSONALS_SUCCESS:
            if (action.personalsGoal && state.personalsGoal) {
                let index = state.personalsGoal.findIndex(
                    (item: any) => item._id == action.personalsGoal?._id
                );
                if (index > -1) {
                    state.personalsGoal[index] = action.personalsGoal;
                } else {
                    state.personalsGoal.push(action.personalsGoal);
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default personalsGoalState;
