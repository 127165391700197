import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IUserProgress, UserProgress } from "../../shared/models/userProgress";
import Url from "../../util/url";
import {
    IUserProgressAction,
    UserProgressTypes,
    updateUserProgressFailed,
    updateUserProgressSuccess,
} from "../actions/userProgress.action";

export async function getRatingAPI(userId: string, sprintId: string): Promise<UserProgress[]> {
    return await axios
    .post(Url("user-progress/get"), {
        userId, sprintId
    })
    .then((data) => {
        if(data.status == 200 && data.data) {
            return data.data.map((item: any) => new UserProgress(item));
        }
        return null;
    }).catch((e) => {
        console.error(e);
        return null;
    });
}

export async function updateRatingAPI(data: IUserProgress): Promise<string[] | null> {
    return await axios
    .post(Url("user-progress/update"), {
        data,
    })
    .then((data) => {
        if(data.status == 200 && data.data?.response) {
            return data.data.ratingUserIds;
        }
        return null;
    }).catch((e) => {
        console.error(e);
        return null;
    });
}

export function* updateUserProgressSaga(action: IUserProgressAction) {
    let data = action.data;
    if (data) {
        try {
            let userProgress: IUserProgress = yield call(
                updateRatingAPI,
                data
            );
            if (userProgress) {
                yield put(updateUserProgressSuccess(userProgress));
            } else {
                yield put(
                    updateUserProgressFailed(
                        "updateUserProgressFailed: userProgress available"
                    )
                );
            }
        } catch (e) {
            yield put(
                updateUserProgressFailed("updateUserProgressFailed: " + e)
            );
        }
    } else {
        yield put(
            updateUserProgressFailed(
                "updateUserProgressFailed: user progress empty"
            )
        );
    }
}

export function* watchUserProgress() {
    yield takeLatest(
        UserProgressTypes.UPDATE_USER_PROGRESS,
        updateUserProgressSaga
    );
}
