import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportSprint } from "../../redux/actions/report.action";
import { getAllSprint } from "../../redux/actions/sprint.action";
import { IReportState } from "../../redux/reducers/report.reducer";
import { ISprintState } from "../../redux/reducers/sprint.reducer";
import { ISprint } from "../../shared/models/sprint";
import LoadingWidget from "../LoadingWidget";
import SelectSprintWidget from "../dashboard/task-preview/edit-task-modal/select-sprint";

const UserStatisticScreen = () => {
    return <>This page is under maintenance (UserStatisticScreen)</>;
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getAllSprint());
    // }, []);
    // const sprintState: ISprintState = useSelector((state: any) => state.sprintState);
    // const [sprintId, setSprintId] = useState<string>("");
    // const currentSprint = sprintState.sprints
    //     ? sprintState.sprints.find((a) => a.activeSprint) ?? sprintState.sprints[0]
    //     : undefined;
    // useEffect(() => {
    //     if (currentSprint?._id) {
    //         setSprintId(currentSprint?._id);
    //     }
    // }, [currentSprint?._id]);
    // if (sprintState.loading || !sprintState.sprints) {
    //     return <LoadingWidget />;
    // }
    // return (
    //     <div>
    //         <Header sprints={sprintState.sprints} currentSprintId={sprintId} onChangeSprint={setSprintId} />
    //         <Content currentSprintId={sprintId} />
    //     </div>
    // );
};

const Content: FC<{ currentSprintId: string }> = ({ currentSprintId }) => {
    const reportState: IReportState = useSelector((state: any) => state.reportState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReportSprint(currentSprintId));
    }, [currentSprintId]);
    if (reportState.loading || !reportState.reports) {
        return <LoadingWidget />;
    }
    const reports = reportState.reports;
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <td>STT</td>
                        <td>Tên</td>
                        <td>Số bug</td>
                        <td>Tổng số point</td>
                        <td>Tổng số point hoàn thành</td>
                        <td>Tổng số task</td>
                        <td>Tổng số task hoàn thành</td>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{report.user?.firstName + " " + report.user?.lastName}</td>
                                <td>{report.numberOfBugs}</td>
                                <td>{report.totalPoints}</td>
                                <td>{report.numberOfTasksDone}</td>
                                <td>{report.totalTasks}</td>
                                <td>{report.numberOfTasksDone}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const Header: FC<{ sprints: ISprint[]; currentSprintId: string; onChangeSprint: (value: string) => void }> = ({
    sprints,
    currentSprintId,
    onChangeSprint,
}) => {
    return (
        <div style={{ width: "300px" }}>
            <SelectSprintWidget
                filterSprint={(task) => !task.backLog}
                sortSprint={(a, b) => b.name.localeCompare(a.name)}
                sprintId={currentSprintId}
                onChange={(value) => onChangeSprint(value)}
            />
        </div>
    );
};

export default UserStatisticScreen;
