import ManageDevice from '../home/ManageDevice/ManageDeviceModal';

const ManageDevicePage = () => {
    return (
        <div className="wiki container">
            <div className="documentations">
                <ManageDevice/>
            </div>
        </div>
    )
}

export default ManageDevicePage;