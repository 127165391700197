import { EditOutlined, DeleteOutlined } from '@material-ui/icons'
import axios from 'axios';
import { FormHelperText, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from '../Row'
import Url from '../../../../../../util/url';
import './style.scss'
import DeleteDialog from '../../components/DeleteDialog';
import { ITestSection } from '../../../../../../shared/models/testSection';
import { updateTests } from '../../../../../../redux/actions/testCase.action';
import { deleteTestSection } from '../../../../../../redux/actions/testSection.action';

const TableSection: FC<{ 
  tests: any, 
  permission: boolean,  
  section: ITestSection, 
  editSection:Function,
  setFocusValue: any,
  handleOnBlur: Function,
  handleChange: Function,
  handleSelectChange: Function,
  handleCheckBoxChange: Function,
}>= ({ 
    tests, 
    permission,  
    section, 
    editSection,
    setFocusValue,
    handleOnBlur,
    handleChange,
    handleSelectChange,
    handleCheckBoxChange,
  }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async () => {
      try {
          await axios.delete(Url('test-case/deletes-test-sections'),{data: [section._id]})
          if(tests && tests.length > 0){
            const tempTests = tests.map((item: any) => ({
                ...item,
                sectionID: 'other'
            }))
            await axios.post(Url('test-case/updates-test-cases'), tempTests)
            dispatch(updateTests(tempTests))
          }
          dispatch(deleteTestSection(section))
          setOpenDeleteModal(false);
      } catch (error) {
          // console.log(error);
      }
  }

  return (
    <div className='section-container'>
        <div >
          <div className='section-title'>
            <h4 className='title' aria-describedby="component-helper-text">{section.name}</h4>
            <span className='number-tests'>{tests ? tests.length : 0}</span>
            <IconButton
                size='small'
                color='info'
                onClick={() => editSection(section)}
                component='span'
                disabled={section.name === 'Other' ? true : false }
                >
                <EditOutlined  fontSize="small"/>
            </IconButton>
            <IconButton
                color='error'
                onClick={() => setOpenDeleteModal(true)}
                component='span'
                disabled={!permission}
            >
                <DeleteOutlined />
            </IconButton>
          </div>
          <FormHelperText id="component-helper-text">
            {section.description}
          </FormHelperText>
        </div>
        <TableContainer component={Paper}  className="section-content">
          <Table>
              <TableHead className='table-header'>
                <TableRow>
                    <TableCell style={{ width: '6%'}}>ID</TableCell>
                    <TableCell style={{ width: '50%'}}>Title</TableCell>
                    <TableCell align='center' style={{ width: '30%'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='table-body'>
                {tests?.map((test: any, index: number) => (
                    <Row 
                      key={index} 
                      row={test} 
                      permission={permission}
                      setFocusValue={setFocusValue}
                      handleOnBlur={handleOnBlur}
                      handleChange={handleChange}
                      handleSelectChange={handleSelectChange}
                      handleCheckBoxChange={handleCheckBoxChange}
                    />
                ))}
              </TableBody>
          </Table>
        </TableContainer>

        <DeleteDialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} handleDelete={handleDelete}/>
    </div>
  )
}

export default TableSection