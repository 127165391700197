import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect, FC } from "react";
import WorkingTimeDetail from "../working-time-detail";
import { useDispatch, useSelector } from "react-redux";
import { getAllWorkingTimeInfo } from "../../../redux/actions/workingTime.action";
import { IOffDaysWfhDays } from "../../../shared/models/offDaysWfhDays";
import { IWorkingTime } from "../../../shared/models/workingTime";
import Config from "../../../shared/models/config";
import { timezoneSetHours } from "../../../util";
import { handleWorkingDay } from "../../../shared/utils";

const COLUMNS = [
    "Nhân viên",
    "Số ngày nghỉ",
    // "Số ngày làm online",
    "Lỗi chấm công",
];
const WorkingTimeTable: FC<{
    startTime: any;
    endTime: any;
    filteredUserIDs: any;
}> = ({ startTime, endTime, filteredUserIDs }) => {
    const [showWorkingDayDetailModal, setShowWorkingDayDetailModal] = useState(false);
    const [dataTable, setDataTable] = useState<any[]>([]);
    const [dataDetail, setDataDetail] = useState({ field: "", userId: "" });
    const [allowAdd, setAllow] = useState("");
    let offDaysWfhDays: IOffDaysWfhDays[] = useSelector((state: any) => state.workingTime.offDaysWfhDays);
    const workingTime: IWorkingTime[] = useSelector(
        //data trả về chỉ gồm dữ liệu ngày có đi làm của cty, không có ngày nghỉ
        (state: any) => state.workingTime.workingTime
    );
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        const handleDateData = async () => {
            try {
                if (startTime && endTime && startTime <= endTime) {
                    let start = timezoneSetHours(startTime).getTime();
                    let end = timezoneSetHours(endTime, 23, 59, 59).getTime(); //vi end convert sang ISO roi chi lay phan ngay-thang-nam nen se lech 1 ngay *** chu y bug phan nay
                    dispatch(getAllWorkingTimeInfo({ start, end }));
                } else {
                    setDataTable([]);
                    alert("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
                }
            } catch (error) {
                console.log(error);
            }
        };
        handleDateData();
    }, [startTime, endTime]);

    useEffect(() => {
        const handleDataTable = async () => {
            let data = offDaysWfhDays.map((user) => {
                let attendance = getAttendance(user); //list of late date
                return { ...user, late: attendance };
            });

            if (filteredUserIDs.length > 0) {
                let result = data.filter((e: IOffDaysWfhDays) => filteredUserIDs.indexOf(e.userId) !== -1);
                setDataTable(result);
            } else {
                setDataTable(data);
            }
        };
        handleDataTable();
    }, [filteredUserIDs, offDaysWfhDays, workingTime]);

    const handleOpenDetail = (field: string, userId: string) => {
        setShowWorkingDayDetailModal(true);
        setDataDetail({ field: field, userId: userId });
    };

    const getAttendance = (user: IOffDaysWfhDays) => {
        let userId = user.userId;
        let userAttendance = handleWorkingDay(workingTime, [user])
            .filter((x: any) => {
                if (x.userId !== userId) return false;
                return (
                    x.date.getTime() <= timezoneSetHours(endTime, 23, 59, 59).getTime() &&
                    x.date.getTime() >= timezoneSetHours(startTime).getTime()
                );
            })
            .map((x: any) => {
                return { ...x, reason: x.checkin + " - " + x.checkout };
            });
        return userAttendance;
    };
    return (
        <>
            <div className="manage-device-container" data-scroll="body">
                <DialogContent className="content" style={{ padding: 0 }}>
                    <TableContainer component={Paper} className="table">
                        <Table sx={{ minWidth: 1100, minHeight: 100 }}>
                            <TableHead className="table__header">
                                <TableRow>
                                    {COLUMNS.map((label, index) => {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    width: `${100 / COLUMNS.length}%`,
                                                }}
                                            >
                                                {label}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody className="table__body">
                                {dataTable &&
                                    dataTable.map(
                                        (
                                            item: IOffDaysWfhDays & {
                                                late: any[];
                                            }
                                        ) => (
                                            <TableRow key={item.userId}>
                                                <TableCell>
                                                    <h6 className="name-device">{item.fullName}</h6>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={() => {
                                                        handleOpenDetail("offDays", item.userId);
                                                        setAllow(Config.TYPE.offday);
                                                        setUser(item);
                                                    }}
                                                >
                                                    {item.numberOffDay}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={() => {
                                                        handleOpenDetail("late", item.userId);
                                                        setAllow("");
                                                        setUser(item);
                                                    }}
                                                >
                                                    {item?.late?.length}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                {(!dataTable || dataTable.length === 0) && (
                                    <TableRow key={0}>
                                        <TableCell align="center">Không có dữ liệu</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                {showWorkingDayDetailModal && (
                    <WorkingTimeDetail
                        onHide={() => setShowWorkingDayDetailModal(false)}
                        data={dataTable.find((data) => data.userId === dataDetail.userId)[dataDetail.field]}
                        type={allowAdd}
                        user={user}
                    />
                )}
            </div>
        </>
    );
};

export default WorkingTimeTable;
