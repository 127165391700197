import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import Url from "../../util/url";
const UploadPanel = ({ image, uploadSuccess = (url) => {}, uploadFailed = () => {} }) => {

    const [ uploadStatus, setUploadStatus ] = useState(0);

    const handleUploadClick = (event) => {
        let file = event.target.files[0];
        setUploadStatus(1);
        let data = new FormData();
        data.append('file', file);
        axios.post(Url('upload/avatar'), data, {
        })
        .then(res => {
            event.target.value = null;
            if(res.status == 200 && res.data) {
                setUploadStatus(2);
                uploadSuccess(res.data)
            } else {
                uploadFailed('Failed!');
                setUploadStatus(3);
            }
        }).catch((e) => {
            event.target.value = null;
            setUploadStatus(3);
            uploadFailed('Failed! ' + e);
            alert('upload failed ' + e);
        })
    };

    return (
        <div>
            <input
                style={{ display: 'none' }}
                accept="image/*"
                id="contained-button-file"
                // multiple
                type="file"
                onChange={handleUploadClick}
            />
            <div style={{height: '10px'}}></div>
            {uploadStatus == 1 ? <span>Uploading...</span> : <label htmlFor="contained-button-file" style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faFileUpload} size="lg" />&nbsp;&nbsp;
                <span>Upload</span>
            </label>}
        </div>
    );
}

export default UploadPanel;