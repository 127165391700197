import { IBasic } from "./basic";
import { ITask, Task } from "./task";

export interface ITestCycleCases extends IBasic {
    note: string;
    status: number;
    devCheck: number;
    actualResult: string;
}

export interface ITestCycle extends IBasic {
    cases: ITestCycleCases[];
    taskID: string;
    task?: ITask;
    lastUpdate: number;
    note: string;
}

export class TestCycle implements ITestCycle {
    _id: string;
    cases: ITestCycleCases[];
    taskID: string;
    task?: ITask;
    lastUpdate: number;
    note: string;
    constructor(object: any = {}) {
        this._id = object._id ?? object.id;
        this.cases = object.cases ?? [];
        let taskID: string = "";
        if (typeof object.task === "string") {
            taskID = object.task;
        } else if (typeof object.task === "object") {
            this.task = new Task(object.task);
            taskID = this.task._id;
        } else if (typeof object.taskID === "string") {
            taskID = object.taskID;
        }
        this.taskID = taskID;
        this.lastUpdate = object.lastUpdate;
        this.note = object.note ?? "";
    }
}
