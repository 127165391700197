import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { ITestCase } from "../../shared/models/testCase";
import Url from "../../util/url";
import {
    getTestsFailed,
    getTestsSuccess,
    TestCaseTypes,
} from "../actions/testCase.action";

export async function getTestsApi(id: any) {
    const { data } = await axios.get(
        Url("test-case/get-test-cases-sprint?sprintID=" + id)
    );
    return data;
}

function* getTests(action: any) {
    try {
        const data: ITestCase[] = yield call(getTestsApi, action.data);
        yield put(getTestsSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getTestsFailed(error));
    }
}

export function* watchGetTests() {
    yield takeLatest(TestCaseTypes.GET_TESTS, getTests);
}
