import { IBasic } from "./basic";
export interface IPersonalsGoal extends IBasic {
  userID: string;
  type: string;
  title: string;
  priority: string;
  description: string;
  teams: string[];
  status: string;
  creatorID: string;
  sprintID: string;
}

export class PersonalsGoal implements IPersonalsGoal {
  _id: string;
  userID: string;
  type: string;
  title: string;
  priority: string;
  description: string;
  teams: string[];
  status: string;
  creatorID: string;
  sprintID: string;
  constructor(props: any = {}) {
    this._id = props._id;
    this.userID = props.userID ?? "";
    this.type = props.type ?? "";
    this.title = props.title ?? "";
    this.priority = props.priority ?? "";
    this.description = props.description ?? "";
    if (props.teams) {
      if (typeof props.teams === "string") {
        this.teams = [props.teams];
      } else this.teams = props.teams;
    } else this.teams = [];
    this.status = props.status ?? "";
    this.creatorID = props.creatorID ?? "";
    this.sprintID = props.sprintID ?? "";
  }
}
