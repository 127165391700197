import { FC, memo, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ModalFooter from "react-bootstrap/ModalFooter";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemove from "@mui/icons-material/PlaylistRemove";
import Config from "../../../shared/models/config";
import axios from "axios";
import Url from "../../../util/url";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoDisturbOnSharpIcon from "@mui/icons-material/DoDisturbOnSharp";
import { useDispatch, useSelector } from "react-redux";
import { addDayoffSuccess, deleteDayoffSuccess } from "../../../redux/actions/workingTime.action";
import moment from "moment-timezone";
import MyTextBox from "../../dashboard/widgets/MyTextBox";
import { IOffDaysWfhDays } from "../../../shared/models/offDaysWfhDays";
import { toast } from "react-toastify";
import { IWfhDayOffDay } from "../../../shared/models/wfhDayoffDayRegister";
import { removeNumberSignOfReason } from "../../../shared/utils";
import { timezoneSetHours } from "../../../util";
const formatDate = (data: any) => {
    let yyyy: any = moment(data).tz(Config.TIMEZONE).year();
    let mm: any = moment(data).tz(Config.TIMEZONE).month() + 1;
    let dd: any = moment(data).tz(Config.TIMEZONE).date();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    return dd + "/" + mm + "/" + yyyy;
};
let convertSlot = {
    [Config.ALL_DAY]: Config.ALL_DAY_VNESE,
    [Config.MORNING]: Config.MORNING_VNESE,
    [Config.AFTERNOON]: Config.AFTERNOON_VNESE,
};
const WorkingTimeDetail: FC<{
    data: any; // *** tai sao dataShow thay doi thi data cung bi thay doi theo, trong khi dataShow la ban copy cua data
    onHide: () => void;
    type: string;
    user: any;
}> = ({ data, onHide, type, user }) => {
    const [addData, setAddData] = useState(false);
    const [dayOff, setDayOff] = useState<IWfhDayOffDay>({
        userId: user.userId,
        date: new Date(new Date().setHours(7, 0, 0, 0) - Config.ONE_DAY_MILLISECONDS), // chech lech mui gio vi server dat ben My *** Chu y bug phan nay
        slot: "",
        offType: Config.OFF_TYPE_AUTHORIZED,
        reason: "",
        type: type,
        _id: "",
    });
    const [dataShow, setDataShow] = useState<any[]>([]);
    useEffect(() => {
        let cloneData: any = JSON.stringify(data);
        cloneData = JSON.parse(cloneData);
        setDataShow(cloneData);
    }, [data]);
    const [deleteData, setDeleteData] = useState<string[]>([]);
    const dispatch = useDispatch();
    let offDaysWfhDay: IOffDaysWfhDays = useSelector(
        (state: any) => state.workingTime.offDaysWfhDays?.find((u: IOffDaysWfhDays) => u.userId === user.userId) ?? []
    );
    const addDay = async () => {
        let daysData: any = [];
        let checkExist = false;
        if (type === Config.TYPE.offday) {
            daysData = offDaysWfhDay.wfhDays;
        } else if (type == Config.TYPE.wfhday) {
            daysData = offDaysWfhDay.offDays;
        }
        daysData.forEach((d: any) => {
            if (
                d.userId === dayOff.userId &&
                new Date(d.date).getTime() === dayOff.date.getTime() &&
                d.slot === convertSlot[dayOff.slot]
            )
                checkExist = true;
        });
        if (!dayOff.slot) {
            toast(
                () => {
                    return (
                        <div className="task-toastify">
                            <p>Cần phải chọn ca</p>
                        </div>
                    );
                },
                { position: "top-center", type: "error" }
            );
            return;
        }
        if (!checkExist) {
            await axios
                .patch(Url("working-time/add-dayoff"), {
                    dayOff: {
                        ...dayOff,
                        reason:
                            (type == Config.TYPE.offday
                                ? dayOff.offType === Config.OFF_TYPE_UNAUTHORIZED
                                    ? "Không phép, "
                                    : "Có phép, "
                                : "") + dayOff.reason,
                    },
                    type,
                })
                .then((response) => {
                    if (response.data !== "not ok") dispatch(addDayoffSuccess(response.data, type));
                })
                .catch((err) => {});
        } else {
            toast(
                () => {
                    return (
                        <div className="task-toastify">
                            <p>
                                {"Đã xin " +
                                    (type == Config.TYPE.offday ? "làm onl" : "nghỉ") +
                                    ", không thể xin " +
                                    (type == Config.TYPE.wfhday ? "làm onl" : "nghỉ")}
                            </p>
                        </div>
                    );
                },
                { position: "top-center", type: "error" }
            );
        }
        setAddData(false);
    };
    const deleteDay = async () => {
        await axios
            .patch(Url("working-time/delete-dayoff"), {
                deleteData,
                type,
            })
            .then((response) => {
                if (response.data == "ok") dispatch(deleteDayoffSuccess(deleteData, type));
            })
            .catch((err) => {});

        setDeleteData([]);
    };
    return (
        <Modal show={true} centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Chi Tiết - {user.fullName}</Modal.Title>

                <div
                    style={{ marginLeft: "6px", cursor: "pointer" }}
                    onClick={() => {
                        if (!deleteData.length) setAddData(!addData);
                        else {
                            setDeleteData([]);
                            let newData = dataShow.map((d: any) => {
                                if (d.pendingDelete) d.pendingDelete = false;
                                return d;
                            });
                            setDataShow(newData);
                        }
                    }}
                >
                    {type && (addData || deleteData.length ? <PlaylistRemove /> : <PlaylistAddIcon htmlColor="red" />)}
                </div>
            </Modal.Header>
            <ModalBody className="create-task-modal">
                <WorkingTimeTable
                    data={dataShow}
                    addData={addData}
                    setDayOff={setDayOff}
                    dayOff={dayOff}
                    setDeleteDate={(id: string) => {
                        let countPending: string[] = [];
                        let newData = [
                            ...dataShow.map((d: any) => {
                                if (d._id === id) d.pendingDelete = !d?.pendingDelete;
                                if (d.pendingDelete) countPending.push(d._id);
                                return d;
                            }),
                        ];
                        setDeleteData(countPending);
                        setDataShow(newData);
                    }}
                    addType={type}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    className="close"
                    aria-label="Close"
                    style={{
                        backgroundColor: addData || deleteData.length ? "red" : "#5b6369",
                        borderColor: addData || deleteData.length ? "red" : "#5b6369",
                    }}
                    onClick={() => {
                        if (!addData && !deleteData.length) onHide();
                        else {
                            if (!deleteData.length) {
                                addDay();
                            } else {
                                deleteDay();
                            }
                        }
                    }}
                    variant="secondary"
                >
                    {addData || deleteData.length ? "Lưu" : "Huỷ"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
export const WorkingTimeTable = ({
    data,
    addData,
    dayOff,
    setDayOff,
    setDeleteDate,
    addType,
    inReport = false,
}: {
    data: any;
    addData?: boolean;
    dayOff?: any;
    setDayOff?: any;
    setDeleteDate?: any;
    addType?: string;
    inReport?: boolean;
}) => {
    let idx = data.length;
    // console.log(data, timezoneSetHours(dayOff.date).toISOString(), dayOff);
    let addday = timezoneSetHours(dayOff?.date).toISOString();
    let disable0 = false;
    let disable1 = false;
    // nếu đã đăng ký xin nghỉ rồi thì không cho thêm thủ công nữa (cẩm đảm bảo điều này)
    for (let item of data) {
        if (item.date == addday) {
            if (!(item.offType == Config.OFF_TYPE_AUTHORIZED || item.offType == Config.OFF_TYPE_UNAUTHORIZED)) {
                // nếu slot nghỉ này không phải được thêm thủ công bởi admin
                if (item.type == Config.TYPE.offday) {
                    // nếu là xin nghỉ
                    if (item.slot == Config.MORNING_VNESE) disable0 = true; // khôgn cho thêm thủ côgn vào buổi sáng
                    if (item.slot == Config.AFTERNOON_VNESE) disable1 = true; // không cho thêm thủ công vào buổi chiều
                }
            }
        }
    }
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ngày</th>
                    <th>Slot</th>
                    <th>Lý do</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((item: any, index: number) => {
                    let isDelete = item.pendingDelete;

                    return (
                        <tr
                            key={index}
                            style={{
                                position: "relative",
                            }}
                        >
                            <td>{index + 1}</td>
                            <td>{formatDate(item.date)}</td>
                            <td>{item.slot}</td>
                            <td>{removeNumberSignOfReason(item.reason)}</td>
                            {isDelete && (
                                <hr
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        left: "-5px",
                                        background: "black",
                                        padding: "1px 5px",
                                        opacity: 0.6,
                                    }}
                                />
                            )}
                            {(item.offType == Config.OFF_TYPE_AUTHORIZED || item.offType == Config.OFF_TYPE_UNAUTHORIZED) &&
                                !addData &&
                                !inReport && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            backgroundColor: "white",
                                            borderTopRightRadius: "5px",
                                            borderBottomRightRadius: "5px",
                                            top: "-2px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            if (item.offType != undefined && !addData) {
                                                if (isDelete) {
                                                    setDeleteDate(item._id);
                                                } else {
                                                    setDeleteDate(item._id);
                                                }
                                            }
                                        }}
                                    >
                                        {!isDelete ? <DoDisturbOnSharpIcon /> : <CloseSharpIcon />}
                                    </div>
                                )}
                        </tr>
                    );
                })}
                {addData && (
                    <tr key={idx} className="insert">
                        <td>{idx}</td>
                        <td>
                            <input
                                type="date"
                                value={new Date(dayOff.date).toISOString().substr(0, 10)}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setDayOff({
                                            ...dayOff,
                                            date: new Date(new Date(e.target.value).setHours(7, 0, 0, 0)),
                                            slot: "",
                                        });
                                }}
                                max={new Date().toISOString().substr(0, 10)}
                            />
                        </td>
                        <td>
                            <select
                                value={dayOff.slot}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setDayOff({
                                            ...dayOff,
                                            slot: e.target.value,
                                        });
                                }}
                            >
                                <option value={""}></option>
                                {/* <option value={Config.ALL_DAY} disabled={disable0 || disable1}>
                                    {Config.ALL_DAY_VNESE}
                                </option> */}
                                <option value={Config.AFTERNOON} disabled={disable1}>
                                    {Config.AFTERNOON_VNESE}
                                </option>
                                <option value={Config.MORNING} disabled={disable0}>
                                    {Config.MORNING_VNESE}
                                </option>
                            </select>
                        </td>
                        <td>
                            {addType == Config.TYPE.offday ? (
                                <select
                                    value={dayOff.offType}
                                    onChange={(e) => {
                                        setDayOff({
                                            ...dayOff,
                                            offType: e.target.value,
                                        });
                                    }}
                                >
                                    <option value={Config.OFF_TYPE_UNAUTHORIZED}>Không phép</option>
                                    <option value={Config.OFF_TYPE_AUTHORIZED}>Có phép</option>
                                </select>
                            ) : (
                                <></>
                            )}
                            <MyTextBox
                                text={""}
                                onChanging={(v) => {
                                    setDayOff({
                                        ...dayOff,
                                        reason: v,
                                    });
                                }}
                                title="Reason"
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};
export default memo(WorkingTimeDetail);
