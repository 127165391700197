import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
    mergeData,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { ICertificates } from "../../../../models/certificates.model";
import {
    getAdsRevenueWebAPI,
    getProRevenueWebAPI,
} from "../../../../services/traffic.service";
import { formatDate, formatPrice } from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import CustomBarChart from "../../charts/bar-chart";
import PlatformTemplate from "../platform-template";

const RevenuePlatformWebView = ({
    certificates,
    platformIds,
}: {
    certificates: ICertificates;
    platformIds: any;
}) => {
    const [proWebValues, setProWebValues] = useState<any>();
    const [proWebSubsValues, setProWebSubsValues] = useState<any>();
    const [proWebSellOneShotNumber, setProWebSellOneShotNumber] =
        useState<any>();
    const [proWebSellSubsNumber, setProWebSellSubsNumber] = useState<any>();
    const [proWebOneShotValues, setProWebOneShotValues] = useState<any>();
    const [adsWebValues, setAdsWebValues] = useState<any>();
    const [proFormatValues, setProFormatValues] = useState<any>();
    const [proAppChildOneShot, setProAppChildOneShot] = useState<any>();
    const [proAppChildSubs, setProAppChildSubs] = useState<any>();
    const [proWebPaypalOneShot, setProWebPaypalOneShot] = useState<any>();
    const [proWebCardOneShot, setProWebCardOneShot] = useState<any>();
    const [proWebSellPaypalOneShot, setProWebSellPaypalOneShot] =
        useState<any>();
    const [proWebSellCardOneShot, setProWebSellCardOneShot] = useState<any>();
    const [proWebPaypalSubs, setProWebPaypalSubs] = useState<any>();
    const [proWebCardSubs, setProWebCardSubs] = useState<any>();
    const [proWebSellPaypalSubs, setProWebSellPaypalSubs] = useState<any>();
    const [proWebTransactionSubject, setProWebTransactionSubject] =
        useState<any>();
    const [proWebSellCardSubs, setProWebSellCardSubs] = useState<any>();
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [totalRevenue, setTotalRevenue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let newPlatform: number[] = [...platformIds];

    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    useEffect(() => {
        const getDataFc = async () => {
            let dataPro: any = {};
            let dataApp: any = {};
            let dataProSubs: any = {};
            let dataProOneShot: any = {};
            let dataAds: any = {};
            let dataSellOneShotNumber: any = {};
            let dataSellSubsNumber: any = {};
            let dataProAppChildOneShot: any = {};
            let slug = certificates.slug;
            let dataPaypalOneShot: any = {};
            let dataCardOneShot: any = {};
            let dataSellNbPaypalOneShot: any = {};
            let dataSellNbCardOneShot: any = {};
            let dataPaypalSubs: any = {};
            let dataCardSubs: any = {};
            let dataSellNbPaypalSubs: any = {};
            let dataSellNbCardSubs: any = {};
            let dataProTransactionSubject: any = {};
            setIsLoading(true);
            let resDataProWeb = await getProRevenueWebAPI(
                slug,
                formatDate(new Date(startTime), "YYYY-MM-DD"),
                formatDate(new Date(endTime), "YYYY-MM-DD")
            );

            let resDataAdsWeb = await getAdsRevenueWebAPI(
                [slug],
                formatDate(new Date(startTime), "YYYY-MM-DD"),
                formatDate(new Date(endTime), "YYYY-MM-DD")
            );

            setIsLoading(false);
            let formatResDataProWeb: any = {};
            let formatResDataPaymentPaypalOneShot: any = {};
            let formatResDataPaymentCardOneShot: any = {};
            let paypalSellNumberOneShot: any = {};
            let cardSellNumberOneShot: any = {};
            let formatResDataPaymentPaypalSubs: any = {};
            let formatResDataPaymentCardSubs: any = {};
            let paypalSellNumberSubs: any = {};
            let cardSellNumberSubs: any = {};
            let formatResDataProChildOneShot: any = {};
            let formatResDataProChildSubs: any = {};
            let formatResDataProWebOneShot: any = {};
            let sellNumberOneShot: any = {};
            let sellNumberSubs: any = {};
            let formatResDataProWebSubs: any = {};
            let formatDataProTransactionSubject: any = {};
            let formatSubTransactionSubject: any = {};

            resDataProWeb.forEach((item: any, index: number) => {
                let month = String(new Date(item.createdTime).getMonth() + 1);
                if (month.length < 2) {
                    month = "0" + month;
                }
                let transactionSubject = item.transactionSubject.replace(
                    / /g,
                    "_"
                );
                transactionSubject = transactionSubject.replace(/-/g, "_");
                let sum = formatResDataProWeb[month] ?? 0;
                const amount = Number(item.amount);
                sum = Number(sum) + amount;
                formatResDataProWeb[month] = sum;
                let isPayPal = item.paymentSource == "paypal"; //card

                if (item.transactionSubject == "one_shot") {
                    let sumOneShot = formatResDataProWebOneShot[month] ?? 0;
                    sumOneShot = Number(sumOneShot) + amount;
                    formatResDataProWebOneShot[month] = sumOneShot;

                    let sumSellNumber = sellNumberOneShot[month] ?? 0;
                    sumSellNumber += 1;
                    sellNumberOneShot[month] = sumSellNumber;

                    if (isPayPal) {
                        let paypalAmout =
                            formatResDataPaymentPaypalOneShot[month] ?? 0;
                        paypalAmout = Number(paypalAmout) + amount;
                        formatResDataPaymentPaypalOneShot[month] = paypalAmout;

                        let sumPaypalOneShot =
                            paypalSellNumberOneShot[month] ?? 0;
                        sumPaypalOneShot += 1;
                        paypalSellNumberOneShot[month] = sumPaypalOneShot;
                    } else {
                        let cardAmout =
                            formatResDataPaymentCardOneShot[month] ?? 0;
                        cardAmout = Number(cardAmout) + amount;
                        formatResDataPaymentCardOneShot[month] = cardAmout;

                        let sumCardOneShot = cardSellNumberOneShot[month] ?? 0;
                        sumCardOneShot += 1;
                        cardSellNumberOneShot[month] = sumCardOneShot;
                    }
                } else {
                    let sumSubs = formatResDataProWebSubs[month] ?? 0;
                    sumSubs = Number(sumSubs) + amount;
                    formatResDataProWebSubs[month] = sumSubs;

                    let sumSellNumber = sellNumberSubs[month] ?? 0;
                    sumSellNumber += 1;
                    sellNumberSubs[month] = sumSellNumber;
                    let subTransactionSubject = 0;
                    if (formatDataProTransactionSubject[month]) {
                        subTransactionSubject =
                            formatDataProTransactionSubject[month][
                                transactionSubject
                            ] ?? 0;
                        subTransactionSubject =
                            Number(subTransactionSubject) + amount;
                    } else {
                        subTransactionSubject = amount;
                        formatSubTransactionSubject = {};
                    }
                    formatSubTransactionSubject[transactionSubject] =
                        subTransactionSubject;

                    formatDataProTransactionSubject[month] =
                        formatSubTransactionSubject;

                    if (isPayPal) {
                        let paypalAmout =
                            formatResDataPaymentPaypalSubs[month] ?? 0;
                        paypalAmout = Number(paypalAmout) + amount;
                        formatResDataPaymentPaypalSubs[month] = paypalAmout;

                        let sumPaypalSubs = paypalSellNumberSubs[month] ?? 0;
                        sumPaypalSubs += 1;
                        paypalSellNumberSubs[month] = sumPaypalSubs;
                    } else {
                        let cardAmout =
                            formatResDataPaymentCardSubs[month] ?? 0;
                        cardAmout = Number(cardAmout) + amount;
                        formatResDataPaymentCardSubs[month] = cardAmout;

                        let sumCardSubs = cardSellNumberSubs[month] ?? 0;
                        sumCardSubs += 1;
                        cardSellNumberSubs[month] = sumCardSubs;
                    }
                }

                let slugAppChild = item?.transactionChildApp;

                if (slugAppChild && slug == "passemall") {
                    let isOneShot = item.transactionSubject == "one_shot";

                    let objOfMonth = formatResDataProChildSubs[month];
                    if (isOneShot) {
                        objOfMonth = formatResDataProChildOneShot[month];
                    }

                    let transChildAppAmount = amount;
                    let packs = 0;
                    if (objOfMonth) {
                        let amountSlug = objOfMonth[slugAppChild];

                        const myArray = amountSlug?.split("|");

                        if (myArray) {
                            packs += Number(myArray[1] ?? 0);
                            transChildAppAmount += Number(myArray[0] ?? 0);
                        }
                    }
                    packs = packs + 1;

                    let obj: any = {};
                    let rs = transChildAppAmount + " | " + packs;
                    obj[slugAppChild] = rs;

                    if (isOneShot) {
                        if (formatResDataProChildOneShot[month]) {
                            formatResDataProChildOneShot[month] = Object.assign(
                                formatResDataProChildOneShot[month],
                                obj
                            );
                        } else {
                            formatResDataProChildOneShot[month] = obj;
                        }
                    } else {
                        if (formatResDataProChildSubs[month]) {
                            formatResDataProChildSubs[month] = Object.assign(
                                formatResDataProChildSubs[month],
                                obj
                            );
                        } else {
                            formatResDataProChildSubs[month] = obj;
                        }
                    }
                }
            });

            let resDataFormatAdsWeb: any = {};

            resDataAdsWeb.forEach((item: any) => {
                const month = item.date.substring(5, 7);
                let sum = resDataFormatAdsWeb[month] ?? 0;
                sum = Number(sum) + Number(item.value);
                resDataFormatAdsWeb[month] = sum;
            });
            let dataProAppChildSubs: any = {};
            dataAds[Constants.PLATFORM_WEB] = resDataFormatAdsWeb;
            dataPro[Constants.PLATFORM_WEB] = formatResDataProWeb;
            dataProSubs[Constants.PLATFORM_WEB] = formatResDataProWebSubs;
            dataProOneShot[Constants.PLATFORM_WEB] = formatResDataProWebOneShot;
            dataSellOneShotNumber[Constants.PLATFORM_WEB] = sellNumberOneShot;
            dataSellSubsNumber[Constants.PLATFORM_WEB] = sellNumberSubs;
            dataProAppChildOneShot[Constants.PLATFORM_WEB] =
                formatResDataProChildOneShot;
            dataProAppChildSubs[Constants.PLATFORM_WEB] =
                formatResDataProChildSubs;
            dataPaypalOneShot[Constants.PLATFORM_WEB] =
                formatResDataPaymentPaypalOneShot;
            dataCardOneShot[Constants.PLATFORM_WEB] =
                formatResDataPaymentCardOneShot;
            dataSellNbCardOneShot[Constants.PLATFORM_WEB] =
                cardSellNumberOneShot;
            dataSellNbPaypalOneShot[Constants.PLATFORM_WEB] =
                paypalSellNumberOneShot;

            dataPaypalSubs[Constants.PLATFORM_WEB] =
                formatResDataPaymentPaypalSubs;
            dataCardSubs[Constants.PLATFORM_WEB] = formatResDataPaymentCardSubs;
            dataSellNbCardSubs[Constants.PLATFORM_WEB] = cardSellNumberSubs;
            dataSellNbPaypalSubs[Constants.PLATFORM_WEB] = paypalSellNumberSubs;

            dataProTransactionSubject[Constants.PLATFORM_WEB] =
                formatDataProTransactionSubject;

            setProWebValues(dataPro);
            setAdsWebValues(dataAds);
            setProWebOneShotValues(dataProOneShot);
            setProWebSubsValues(dataProSubs);
            setProWebSellOneShotNumber(dataSellOneShotNumber);
            setProWebSellSubsNumber(dataSellSubsNumber);
            setProAppChildOneShot(dataProAppChildOneShot);
            setProAppChildSubs(dataProAppChildSubs);
            setProWebCardOneShot(dataCardOneShot);
            setProWebPaypalOneShot(dataPaypalOneShot);
            setProWebSellCardOneShot(dataSellNbCardOneShot);
            setProWebSellPaypalOneShot(dataSellNbPaypalOneShot);
            setProWebCardSubs(dataCardSubs);
            setProWebPaypalSubs(dataPaypalSubs);
            setProWebSellCardSubs(dataSellNbCardSubs);
            setProWebSellPaypalSubs(dataSellNbPaypalSubs);
            setProWebTransactionSubject(dataProTransactionSubject);
        };
        getDataFc();
    }, [startTime, endTime, certificates.id]);
    useEffect(() => {
        if (proWebValues && adsWebValues) {
            let newDataPro: any = mergeData(proWebValues, newPlatform, true);
            let newDataProSellSubs: any = mergeData(
                proWebSellSubsNumber,
                newPlatform,
                true
            );
            let newDataProSellOneShot: any = mergeData(
                proWebSellOneShotNumber,
                newPlatform,
                true
            );

            let newDataProSubs: any = mergeData(
                proWebSubsValues,
                newPlatform,
                true
            );

            let newDataProOneShot: any = mergeData(
                proWebOneShotValues,
                newPlatform,
                true
            );
            let newDataProPaypalOneShot: any = mergeData(
                proWebPaypalOneShot,
                newPlatform,
                true
            );
            let newDataProCardOneShot: any = mergeData(
                proWebCardOneShot,
                newPlatform,
                true
            );
            let newDataProSellCardOneShot: any = mergeData(
                proWebSellCardOneShot,
                newPlatform,
                true
            );
            let newDataProSellPaypalOneShot: any = mergeData(
                proWebSellPaypalOneShot,
                newPlatform,
                true
            );

            let newDataProPaypalSubs: any = mergeData(
                proWebPaypalSubs,
                newPlatform,
                true
            );
            let newDataProCardSubs: any = mergeData(
                proWebCardSubs,
                newPlatform,
                true
            );
            let newDataProSellCardSubs: any = mergeData(
                proWebSellCardSubs,
                newPlatform,
                true
            );
            let newDataProSellPaypalSubs: any = mergeData(
                proWebSellPaypalSubs,
                newPlatform,
                true
            );

            let newDataAds: any = mergeData(adsWebValues, newPlatform, true);
            let newProAppChildOneShot: any = mergeData(
                proAppChildOneShot,
                newPlatform,
                true,
                true
            );

            let newProAppChildSubs: any = mergeData(
                proAppChildSubs,
                newPlatform,
                true,
                true
            );
            let newProWebTransactionSubject: any = mergeData(
                proWebTransactionSubject,
                newPlatform,
                true,
                true
            );

            const format = (data: any, key: string) => {
                if (data?.length > 0)
                    return data?.map((item: any) => {
                        let date = item.date;
                        let value = item.value;

                        return {
                            date,
                            [key]: value,
                        };
                    });
                return null;
            };
            // newProWebTransactionSubject = format(
            //     newProWebTransactionSubject,
            //     ""
            // );

            newDataPro = format(newDataPro, "pro");
            newDataProSubs = format(newDataProSubs, "proSubs");
            newDataProOneShot = format(newDataProOneShot, "proOneShot");
            newDataAds = format(newDataAds, "ads");
            newProAppChildOneShot = format(newProAppChildOneShot, "appChild");
            newDataProSellSubs = format(newDataProSellSubs, "sellSubsNumber");

            newDataProSellOneShot = format(
                newDataProSellOneShot,
                "sellOneShotNumber"
            );

            newDataProPaypalOneShot = format(
                newDataProPaypalOneShot,
                "proPaypalOneShot"
            );
            newDataProCardOneShot = format(
                newDataProCardOneShot,
                "proCardOneShot"
            );
            newDataProSellCardOneShot = format(
                newDataProSellCardOneShot,
                "proSellCardOneShot"
            );
            newDataProSellPaypalOneShot = format(
                newDataProSellPaypalOneShot,
                "proSellPaypalOneShot"
            );

            newDataProPaypalSubs = format(
                newDataProPaypalSubs,
                "proPaypalSubs"
            );
            newDataProCardSubs = format(newDataProCardSubs, "proCardSubs");
            newDataProSellCardSubs = format(
                newDataProSellCardSubs,
                "proSellCardSubs"
            );
            newDataProSellPaypalSubs = format(
                newDataProSellPaypalSubs,
                "proSellPaypalSubs"
            );
            newProAppChildSubs = format(newProAppChildSubs, "appChildSubs");
            newProWebTransactionSubject = format(
                newProWebTransactionSubject,
                "value"
            );

            let newArr = newDataPro;

            if (newDataProOneShot) {
                newArr = newArr?.concat(newDataProOneShot);
            }
            if (newDataProPaypalOneShot) {
                newArr = newArr?.concat(newDataProPaypalOneShot);
            }
            if (newDataProCardOneShot) {
                newArr = newArr?.concat(newDataProCardOneShot);
            }
            if (newDataProSellOneShot) {
                newArr = newArr?.concat(newDataProSellOneShot);
            }
            if (newDataProSellCardOneShot) {
                newArr = newArr?.concat(newDataProSellCardOneShot);
            }
            if (newDataProSellPaypalOneShot) {
                newArr = newArr?.concat(newDataProSellPaypalOneShot);
            }

            if (newProAppChildOneShot) {
                newArr = newArr?.concat(newProAppChildOneShot);
            }
            if (newDataProSubs) {
                newArr = newArr?.concat(newDataProSubs);
            }
            if (newDataProSellSubs) {
                newArr = newArr?.concat(newDataProSellSubs);
            }
            if (newDataProPaypalSubs) {
                newArr = newArr?.concat(newDataProPaypalSubs);
            }
            if (newDataProCardSubs) {
                newArr = newArr?.concat(newDataProCardSubs);
            }
            if (newDataProSellCardSubs) {
                newArr = newArr?.concat(newDataProSellCardSubs);
            }
            if (newDataProSellPaypalSubs) {
                newArr = newArr?.concat(newDataProSellPaypalSubs);
            }
            if (newProAppChildSubs) {
                newArr = newArr?.concat(newProAppChildSubs);
            }

            if (newDataAds) {
                newArr = newArr?.concat(newDataAds);
            }

            let arrTransactionSubject: any = [];
            if (newProWebTransactionSubject?.length) {
                newProWebTransactionSubject.forEach((element: any) => {
                    if (element.value) {
                        Object.keys(element.value)?.forEach((key) => {
                            let data: any = {};
                            data["date"] = element.date;
                            data[key] = element.value[key];
                            arrTransactionSubject.push(data);
                        });
                    }
                });
            }

            if (arrTransactionSubject?.length) {
                newArr = newArr?.concat(arrTransactionSubject);
            }

            const groupByDate = newArr?.reduce((group: any, item: any) => {
                const { date } = item;
                group[date] = group[date] ?? [];

                group[date].push(item);
                return group;
            }, {});
            let dataFormat: any = [];
            groupByDate &&
                Object.keys(groupByDate)?.forEach((key) => {
                    let obj = Object.assign({}, ...groupByDate[key]);
                    dataFormat = [...dataFormat, obj];
                });

            setProFormatValues(dataFormat);
        }
    }, [
        optionValue,
        proWebValues,
        adsWebValues,
        proWebOneShotValues,
        proWebSubsValues,
        proWebSellOneShotNumber,
        proWebSellSubsNumber,
        proAppChildOneShot,
        proAppChildSubs,
        proWebCardOneShot,
        proWebPaypalOneShot,
        proWebCardSubs,
        proWebPaypalSubs,
        proWebTransactionSubject,
    ]);
    useEffect(() => {
        let sum = 0;
        proFormatValues?.forEach((element: any) => {
            sum = sum + Number(element?.ads ?? 0) + Number(element?.pro ?? 0);
        });
        setTotalRevenue(sum);
    }, [proFormatValues]);

    return (
        <div className="question-quality-v2 revenue">
            {isLoading ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <PlatformTemplate
                        platformIds={platformIds}
                        optionValue={optionValue}
                        setOptionValue={(value: any) => setOptionValue(value)}
                    />
                    <div className="total-revenue">
                        Total revenue: ${formatPrice(totalRevenue)}
                    </div>
                    <div
                        className={
                            "list-chart-revenue " +
                            certificates?.name?.toLowerCase()
                        }
                    >
                        {proFormatValues?.length > 0 ? (
                            <CustomBarChart
                                data={proFormatValues}
                                dataKeyXAxis="date"
                                dataKeyYAxis={["pro", "ads"]}
                                month={true}
                            />
                        ) : (
                            certificates.name
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export default RevenuePlatformWebView;
