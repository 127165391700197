import { ITask } from '../../../shared/models/task'

export default function FilterByAssignee({ versions, handleGetInfoUser, Constants, assigneechosen }: any) {
    return (
        versions?.map((version: any) => {
            const indexOfAssignee: number[] = []
            return (
                version.tasks?.map((task: ITask, index: number) => {
                    if (assigneechosen === task.assignee) {
                        indexOfAssignee.push(index)
                        const firstIndexOfAssignee = indexOfAssignee[0]
                        return (
                            <tr key={task._id}>
                                <td></td>
                                <td>
                                    {index === firstIndexOfAssignee && version.version}
                                </td>
                                <td>{index === firstIndexOfAssignee && version.dateVersion}</td>
                                <td>
                                    <a href={"https://hero.abc-elearning.org/task/" + task._id}>
                                        {task.title}
                                    </a>
                                </td>
                                <td
                                    className="avatar"
                                    onClick={() =>
                                        window.open("https://hero.abc-elearning.org/user/" + task.assignee)
                                    }
                                >
                                    <img src={handleGetInfoUser(task.assignee, Constants.GET_FIELD_AVATAR).avatar} />
                                    <span>
                                        {handleGetInfoUser(task.assignee, Constants.GET_FIELD_FIRSTNAME).firstName}
                                    </span>
                                </td>
                            </tr>
                        )
                    }
                }
                ))
        }))
}