import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getFlatForm } from "../../../config_data_analytic";
import Constants from "../../../constant";
import { IAbTest } from "../../../models/abtest.model";
import { ICertificates } from "../../../models/certificates.model";
import { getAbtestBySlugAPI } from "../../../services/traffic.service";
import CompareAbTestVersion from "../compareAbtest-Version";
import "./style.scss";
const AbTest = ({ certificate }: { certificate: ICertificates }) => {
    const [abTestDatas, setAbTestDatas] = useState<IAbTest[]>([]);
    const [optionValue, setOptionValue] = useState(Constants.PLATFORM_WEB);
    const [followReGet, setFollowReGet] = useState("")
    const statusAbTest = [1, 0]
    const platformIds = [
        Constants.PLATFORM_WEB,
        Constants.PLATFORM_FLUTTER_ANDROID,
        Constants.PLATFORM_FLUTTER_IOS,
        Constants.PLATFORM_REACT_NATIVE_ANDROID,
        Constants.PLATFORM_REACT_NATIVE_IOS,
    ];
    useEffect(() => {
        const getData = async () => {
            const slug = certificate.slug;
            const response = await getAbtestBySlugAPI(slug);
            setAbTestDatas(response);
        };
        getData();
    }, [certificate.id, followReGet]);

    return (
        <div className="ab-test">
            <Form.Select onChange={(e: any) => setOptionValue(e.target.value)}>
                {platformIds.map((op: any) => (
                    <option selected={optionValue == op} value={op} key={op}>
                        {getFlatForm(op).toUpperCase()}
                    </option>
                ))}
            </Form.Select>
            <CompareAbTestVersion abTestData={null} currentApp={optionValue} handleFollowReGet={setFollowReGet}/>
            {statusAbTest.map((status) => {
                return (
                    <div className="ab-test-pass">
                        <h2 style={status === 1 ? { color: '#e62e00' } : { color: '#269900'}}>{status === 1 ? 'Active' : 'Pass'}</h2>
                        <div className="ab-test-sort">
                        {abTestDatas &&
                            abTestDatas.map((abTestData) => {
                                if (abTestData.appData.platform == optionValue && (status === 1 ? abTestData.active === true : abTestData.active === false))
                                return (
                                    <div className="ab-test-content">
                                            <h5 style={{ marginBottom: 10 }}>{abTestData.title}</h5>
                                            <div style={{ marginBottom: 10 }}>{moment(abTestData.startDate).format('DD/MM/YYYY')} ~ {moment(abTestData.endDate).format('DD/MM/YYYY')}</div>
                                            <div>
                                                {abTestData.versions?.map((item: any, index: any) => {
                                                    return (
                                                        <div style={{ display: 'flex', marginLeft: 65, marginBottom: 8 }}>
                                                            <div style={{ marginRight: 2 }}>
                                                                <strong>Version {index + 1}:</strong>
                                                            </div>
                                                            <div style={{ padding: 2, marginRight: 4 }}>
                                                                Basic: {item.priceBasic}$, 
                                                            </div>
                                                            <div style={{ padding: 2, marginRight: 4 }}>
                                                                Pro: {item.pricePro}$,
                                                            </div>
                                                            <div style={{ padding: 2, marginRight: 4 }}>
                                                                Percent: {item.percentTest}%,
                                                            </div>
                                                            <div style={{ padding: 2 }}>
                                                                Conversion Rate: {item.conversionRate}
                                                            </div>
                                                        </div> 
                                                )})}
                                            </div>
                                            <CompareAbTestVersion abTestData={abTestData} currentApp={optionValue} handleFollowReGet={setFollowReGet}/>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AbTest;
