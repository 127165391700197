import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    IUserExpectationAction,
    UserExpectationTypes,
    getAllExpectationsSuccess,
} from "../actions/userExpectation.action";
import {
    IUserExpectation,
    UserExpectation,
} from "../../shared/models/userExpectation";
import Url from "../../util/url";
// async function getAllUserExpectationsAPI(): Promise<IUserExpectation[] | null> {
//     try {
//         let result = await axios.get(
//             Url("user-expectation/get-all-user-expectations")
//         );
//         return result?.data?.map((item: any) => new UserExpectation(item));
//     } catch (err) {
//         console.log(err);
//         return null;
//     }
// }
// export function* getAllUserExpectationsSaga(action: IUserExpectationAction) {
//     // let userInfo: IUserInfo = yield select((state: any) => state.authState.user)
//     //tam thoi lay tat ca, sau co the chi lay nhung user can thiet (theo role)
//     let result: IUserExpectation[] | null = yield call(
//         getAllUserExpectationsAPI
//     );
//     if (result) {
//         yield put(getAllExpectationsSuccess(result));
//     }
// }
export function* watchUserExpectation() {
    // yield takeLatest(
    //     UserExpectationTypes.GET_ALL_EXPECTATIONS,
    //     getAllUserExpectationsSaga
    // );
}
