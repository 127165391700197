import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { urlDashboard } from "../../util/url";
import {
    getPurchaseInfomationByEmailSuccess,
    getPurchaseInfomationByTransactionSuccess,
    IToolAnalysisAction,
    setDataProcessingAction,
    setMesage,
    ToolAnalysisTypes,
    updatePurchaseSuccessAction,
} from "../actions/toolAnalysis.action";

async function getPurchaseInfomationByEmailAPI(
    email: string
): Promise<any[] | null> {
    let result: any[] = [];
    await axios.get(urlDashboard + `get-user-device-login?email=${email}`).then(
        (response) => {
            result = response?.data;
        },
        (e) => console.log("Get purchase data by email error", e)
    );
    return result;
}
function* getPurchaseInfomationByEmailSaga(action: IToolAnalysisAction) {
    try {
        let email = action.email;
        if (!!email) {
            let result: any[] | null = yield call(
                getPurchaseInfomationByEmailAPI,
                email
            );

            if (!!result) {
                yield put(getPurchaseInfomationByEmailSuccess(result));
            }
        }
        yield put(setDataProcessingAction(false));
    } catch (e) {
        console.log(e);
        yield put(setMesage("Error! check log for datail", "error"));
        yield put(setDataProcessingAction(false));
    }
}

async function getPurchaseInfomationByTransactionAPI(
    inputTransaction: any
): Promise<any[] | null> {
    let result: any[] = [];
    await axios
        .post(urlDashboard + "get-transaction-info", inputTransaction)
        .then(
            (response) => {
                result = response?.data?.transaction_details;
            },
            (e) => console.log("Get purchase data by transactioin error", e)
        );
    return result;
}
function* getPurchaseInfomationByTransactionSaga(action: IToolAnalysisAction) {
    try {
        let inputTransaction = action.inputTransaction;
        if (!!inputTransaction) {
            let result: any[] | null = yield call(
                getPurchaseInfomationByTransactionAPI,
                inputTransaction
            );
            if (!!result) {
                yield put(getPurchaseInfomationByTransactionSuccess(result));
            }
            yield put(setDataProcessingAction(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setMesage("Error! check log for datail", "error"));
        yield put(setDataProcessingAction(false));
    }
}

async function updatePurchaseInfomationAPI(purchase: any): Promise<any | null> {
    let result: any = {};
    let updateObject = {};
    Object.keys(purchase).forEach((k) => {
        if (k !== "row_id")
            updateObject = { ...updateObject, [k]: purchase[k] };
    });
    await axios
        .post(urlDashboard + "update-entity-by-id", {
            kind: "UserDeviceLogin",
            rowId: purchase.row_id,
            updateObject: updateObject,
        })
        .then(
            (response) => {
                result = response?.data;
            },
            (e) => console.log("update purchase error", e)
        );

    return result;
}
function* updatePurchaseSaga(action: IToolAnalysisAction) {
    try {
        let result: {} = yield call(
            updatePurchaseInfomationAPI,
            action.purchase
        );
        if (Object.keys(result).length > 0) {
            yield put(updatePurchaseSuccessAction(result));
        }
        yield put(setDataProcessingAction(false));
        yield put(setMesage("Update successful", "success"));
    } catch (e) {
        console.log(e);
        yield put(setMesage("Error! check log for detail", "error"));
        yield put(setDataProcessingAction(false));
    }
}

export function* watchToolAnalysis() {
    yield takeLatest(
        ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_EMAIL,
        getPurchaseInfomationByEmailSaga
    );

    yield takeLatest(
        ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_TRANSACTION,
        getPurchaseInfomationByTransactionSaga
    );
    yield takeLatest(
        ToolAnalysisTypes.UPDATE_PURCHASE_ACTION,
        updatePurchaseSaga
    );
}
