import React, { FC } from "react";
import Header from "./components/Header";

const Layout: FC<{ children: React.ReactNode; tempAttr?: boolean }> = ({
    children,
    tempAttr = false,
}) => {
    return (
        <div className={"app-main " + (tempAttr ? "new-color" : "")}>
            {!tempAttr ? <Header /> : <></>}
            {children}
        </div>
    );
};

export default Layout;
