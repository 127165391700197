import Config from "./models/config";
import { IOffDaysWfhDays, OffDaysWfhDays } from "./models/offDaysWfhDays";
import { Project } from "./models/project";
import { ISprint, Sprint } from "./models/sprint";
import { ISprintTask, SprintTask } from "./models/sprintReport";
import { Status } from "./models/status";
import { ITask, Task } from "./models/task";
import { ITaskScores, TaskScore } from "./models/taskScore";
import { IUserInfo, UserInfo } from "./models/userInfo";
import { IWfhDayOffDay, WfhDayOffDay } from "./models/wfhDayoffDayRegister";
import { IWorkingTime } from "./models/workingTime";
import moment from "moment-timezone";

export function convertDate(object: any): Date | null {
    if (typeof object === "string" || typeof object === "number") {
        return new Date(object);
    } else if (typeof object === "object") {
        return object as Date;
    }
    return null;
}

export function isValidateEmail(email: string | undefined): boolean {
    return (
        !!email &&
        !!String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    );
}

export function isValidatePassword(password: string | undefined): boolean {
    return !!password && password.length > 0;
}

export function replaceSpecialChar(str: string) {
    return toLowerCaseNonAccentVietnamese(str).replace(/[^a-zA-Z0-9]/g, " ");
}

export function toLowerCaseNonAccentVietnamese(str: string) {
    str = str.toLowerCase();
    //     We can also use this instead of from line 11 to line 17
    //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
    //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
    //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
    //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
    //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
    //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
    //     str = str.replace(/\u0111/g, "d");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}

// This function keeps the casing unchanged for str, then perform the conversion
export function toNonAccentVietnamese(str: string) {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/g, "E");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}

export const compareArray = (a: any[], b: any[]) => {
    if (a.length != b.length) {
        return a.length - b.length;
    }
    let count = 0;
    for (let i of a) {
        if (b.includes(i)) count++;
    }
    return count - a.length;
};

export const getUserFromJson = (data: any): IUserInfo | undefined => {
    if (data) {
        let name = "";
        try {
            name = data.constructor.name;
        } catch (e) {}
        if (name === "UserInfo" || name === "IUserInfo") {
            return data;
        }
        if (name === "Array") {
            if (!data[0]) {
                return undefined;
            }
            return new UserInfo(data[0]);
        }
        if (name === "Object") {
            return new UserInfo(data);
        }
        if (typeof data === "object") {
            return new UserInfo(data);
        }
        if (data[0] && typeof data[0] === "object") {
            return new UserInfo(data[0]);
        }
    }
    return;
};

export const getProjectFromJson = (data: any): Project | undefined => {
    if (data) {
        let name = "";
        try {
            name = data.constructor.name;
        } catch (e) {}
        if (name === "Project" || name === "IProject") {
            return data;
        }
        if (name === "Array") {
            if (!data[0]) {
                return undefined;
            }
            return new Project(data[0]);
        }
        if (name === "Object") {
            return new Project(data);
        }
        if (typeof data === "object") {
            return new Project(data);
        }
        if (data[0] && typeof data[0] === "object") {
            return new Project(data[0]);
        }
    }
    return;
};

export const getSprintFromJson = (data: any): Sprint | undefined => {
    if (data) {
        let name = "";
        try {
            name = data.constructor.name;
        } catch (e) {}
        if (name === "Sprint" || name === "ISprint") {
            return data;
        }
        if (name === "Array") {
            if (!data[0]) {
                return undefined;
            }
            return new Sprint(data[0]);
        }
        if (name === "Object") {
            return new Sprint(data);
        }
        if (typeof data === "object") {
            return new Sprint(data);
        }
        if (data[0] && typeof data[0] === "object") {
            return new Sprint(data[0]);
        }
    }
    return;
};

export const getStatusFromJson = (data: any): Status | undefined => {
    if (data) {
        let name = "";
        try {
            name = data.constructor.name;
        } catch (e) {}
        if (name === "Status" || name === "IStatus") {
            return data;
        }
        if (name === "Array") {
            if (!data[0]) {
                return undefined;
            }
            return new Status(data[0]);
        }
        if (name === "Object") {
            return new Status(data);
        }
        if (typeof data === "object") {
            return new Status(data);
        }
        if (data[0] && typeof data[0] === "object") {
            return new Status(data[0]);
        }
    }
    return;
};

export const getTaskFromJson = (data: any): Task | undefined => {
    if (data) {
        let name = "";
        try {
            name = data.constructor.name;
        } catch (e) {}
        if (name === "Status" || name === "IStatus") {
            return data;
        }
        if (name === "Array" && data[0]) {
            return new Task(data[0]);
        }
        if (name === "Object") {
            return new Task(data);
        }
        if (typeof data === "object") {
            return new Task(data);
        }
        if (data[0] && typeof data[0] === "object") {
            return new Task(data[0]);
        }
    }
    return;
};

export function convertToJSONObject(model: any) {
    return JSON.parse(JSON.stringify(model));
}

export interface OrganizationInfo {
    id: number;
    name: string;
    organization: string[];
}

export const getOrganizationInfo = (): Map<string, OrganizationInfo> => {
    const map = new Map<string, OrganizationInfo>();
    map.set("61ebb0a21d081e33539091fd", {
        id: map.size,
        name: "App",
        organization: ["abc-elearning-app"],
    });
    map.set("61ebb0941d081e33539091f0", {
        id: map.size,
        name: "Web",
        organization: ["super-web-app"],
    });
    map.set("61ebb0a91d081e335390920b", {
        id: map.size,
        name: "Operation",
        organization: ["abc-hero"],
    });
    map.set("61ebb0b21d081e3353909219", {
        id: map.size,
        name: "Worksheet",
        organization: [],
    });
    map.set("61ebb0cf1d081e335390923d", {
        id: map.size,
        name: "TestHub",
        organization: [],
    });
    map.set("6272bbec8adfec3d1e7f81e8", {
        id: map.size,
        name: "Dashboard",
        organization: [],
    });
    return map;
};

export const getOrganizationWithProjectId = (projectId: string): string[] => {
    const map = getOrganizationInfo();
    return map.get(projectId)?.organization ?? [];
};

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getTimeFixBug = (value: any) => {
    if (typeof value === "string" && typeof value === "number") {
        return new Date(value).getTime();
    }
    return value?.getTime();
};

export const getLocalDateTimeFixBug = (value: any) => {
    if (typeof value === "string" && typeof value === "number") {
        return new Date(value).toLocaleDateString();
    }
    return value?.toLocaleDateString();
};

// quyền hiển thị thông tin điểm
export const roleForPoint = (user?: IUserInfo) => {
    return user?._id?.toString() != '6157c696e40d4cf6e89ff757';
};

// quyền xem thông tin điểm
export const roleViewForPoint = (user?: IUserInfo) => {
    const role = user?.role;
    return role === Config.USER_ROLE_STAFF 
        || role === Config.USER_ROLE_INTERN 
        || role === Config.USER_ROLE_MID_LEADER;
};

export const calculateTaskScoreFromSprintReport = (sprintTasks: ISprintTask[]): ITaskScores => {
    let pointDone = 0;
    let expTask = 0;
    let totalPoint = 0;
    sprintTasks?.forEach((s) => {
        if (s.state == 1 || s.state == 2 || s.checkPoint) {
            pointDone += s.point;
            expTask += s.point * s.coef;
        }
        // if (s.state !== -1) {
        //vi task o TO DO van duoc luu point
        totalPoint += s.point;
        // }
    });
    let tasksDone = sprintTasks?.filter((s) => s.state == 1 || s.state == 2 || s.checkPoint)?.length ?? 0;
    let totalTaskInSprint = sprintTasks?.filter((s) => s.state != -1)?.length ?? 0;
    let taskDoneInDeadline = sprintTasks?.filter((s) => s.state == 2)?.length ?? 0;
    let totalTasks = sprintTasks?.length;
    let bug = sprintTasks?.filter((s) => s.taskType === "Bug")?.length ?? 0;
    let { taskDonePercentage, pointDonePercentage, taskDoneInDeadlinePercentage, doneRate } = calculatePercentage(
        totalTaskInSprint,
        tasksDone,
        pointDone,
        taskDoneInDeadline
    );
    return new TaskScore({
        tasksDone: tasksDone,
        pointDone: pointDone,
        totalTaskInSprint: totalTaskInSprint,
        taskDoneInDeadline: taskDoneInDeadline,
        expTask: expTask,
        totalTasks: totalTasks,
        taskDonePercentage: taskDonePercentage,
        pointDonePercentage: pointDonePercentage,
        taskDoneInDeadlinePercentage: taskDoneInDeadlinePercentage,
        doneRate: doneRate,
        bug: bug,
        totalPoint: totalPoint,
    });
};

export const calculatePercentage = (
    totalTaskInSprint: number,
    tasksDone: number,
    pointDone: number,
    taskDoneInDeadline: number
) => {
    let taskDonePercentage = totalTaskInSprint == 0 ? 0 : Math.round((tasksDone / totalTaskInSprint) * 100);
    let pointDonePercentage = Math.round((pointDone / Config.DEFAULT_POINT) * 100);
    let taskDoneInDeadlinePercentage = totalTaskInSprint == 0 ? 0 : Math.round((taskDoneInDeadline / totalTaskInSprint) * 100);
    return {
        taskDonePercentage: taskDonePercentage,
        pointDonePercentage: pointDonePercentage,
        taskDoneInDeadlinePercentage: taskDoneInDeadlinePercentage,
        doneRate: Math.round(
            ((pointDone / Config.DEFAULT_POINT) * 100 +
                (totalTaskInSprint == 0 ? 0 : (taskDoneInDeadline / totalTaskInSprint) * 100)) /
                2
        ),
    };
};
/**
 * Cẩn thận logic hàm này/ (mục đích hàm này để check một task có được tính điểm vào một sprint hay không)
 * ban đầu coi các task không ở todo và có ngày khởi tạo nằm trong phạm vi thời gian của sprint đang xét (như thế thì các task TODO ở sprint trước khi sang sprint sau sẽ bị check fail)
 * [Bổ sung check finishedDate cho các task được create ở sprint trước, nếu finishedDate nằm ngoài sprint thì là nó không được tính, nằm trong thì vẫn tính]
 * @param task task được xét
 * @param sprint sprint được xét
 * @returns
 */
export const checkTasksInSprint = (task: ITask, sprint: ISprint) => {
    // trường hợp ở sprint để TO DO rồi sang sprint sau mới kéo thì hàm này check sai
    let check = false;
    // trước tiên cần check task phải nằm ngoài TO DO
    if (task.statusId !== Config.COLUMN_STATUS.TO_DO.mongoId) {
        // trường hợp task được tạo trong sprint đang xét => true
        if (
            new Date(task.createdTime).getTime() >= new Date(sprint.startDate).getTime() &&
            new Date(task.createdTime).getTime() <= new Date(sprint.endDate).getTime()
        ) {
            check = true;
        }
        // trường hợp task được tạo ngoài sprint (xử lý cho trường hợp task được tạo ở sprint trước nhưng để ở TO DO sang sprint này mới kéo để làm)
        else if (
            new Date(task.createdTime).getTime() < //tạo ở sprint trước
                new Date(sprint.startDate).getTime() &&
            (task.finishedDate == -1 || //chưa được đánh dấu là finish
                new Date(task.finishedDate).getTime() >= // đánh dấu là finish sau khi sprint đang xét bắt đầu (có thể finish trong sprint đang xét hoặc sau đó (thì tại sprint đang xét vẫn sẽ được tín))
                    new Date(task.createdTime).getTime())
        ) {
            check = true;
        }
    }
    return check;
};
export const convertTaskToReport = (task: any, currentSprint: ISprint): ISprintTask => {
    let taskState: -1 | 0 | 1 | 2 = 0;
    let point = 0;
    task.statusId = task?.statusId ?? task.status;
    if (!checkTasksInSprint(task, currentSprint)) {
        //task khong duoc tinh vao sprint thi point = 0 ? nam o TO DO?
        taskState = -1;
        if (task.status === Config.COLUMN_STATUS.TO_DO.mongoId) point = task.point ?? 0;
    } else {
        //check task done or not
        if (
            task.statusId === Config.COLUMN_STATUS.DONE.mongoId ||
            task.statusId === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId
        ) {
            //done
            let timeDone = new Date(task.finishedDate);
            if (timeDone && !!task.deadline) {
                if (timeDone.getTime() <= new Date(task.deadline).getTime()) {
                    //done in deadline
                    taskState = 2;
                } else taskState = 1;
            } else taskState = 1;
        } else taskState = 0; //chua done
        point = task.point ?? 0;
    }
    return new SprintTask({
        taskID: task._id,
        taskName: "",
        point: point,
        coef: task.coef,
        state: taskState,
        status: task.status,
        taskType: task.type,
        checkPoint: task.checkPoint, // nếu có checkpoint thì tính điểm
    });
};

//x: IWorkingtime
export const getReason = (x: IWorkingTime) => {
    let slot = x.slot;
    let [hi, mi] = x.checkin.split(":");
    let [ho, mo] = x.checkout.split(":");
    let reason = "";
    let subPoint = 0;
    if (!hi || !ho) {
        reason += (!hi ? "không checkin, " : "") + (!ho ? "không checkout, " : "");
        subPoint = subPoint + (!hi ? Config.LATE.notcheck : 0) + (!ho ? Config.LATE.notcheck : 0);
    }
    let timeIn = parseInt(hi) * 60 + parseInt(mi);
    let lateIn = 0;
    let isEarlyOut = false;
    if (slot === Config.ALL_DAY_VNESE) {
        lateIn = timeIn - Config.LATE.timeInMorning;
        isEarlyOut = x.checkout < Config.CHECKOUT_AFTERNOON && !!x.checkout;
    } else if (slot === Config.MORNING_VNESE) {
        lateIn = timeIn - Config.LATE.timeInMorning;
        isEarlyOut = x.checkout < Config.CHECKOUT_MORNING && !!x.checkout;
    } else if (slot === Config.AFTERNOON_VNESE) {
        lateIn = timeIn - Config.LATE.timeInAfternoon;
        isEarlyOut = x.checkout < Config.CHECKOUT_AFTERNOON && !!x.checkout;
    }
    if (lateIn >= 0) {
        reason += "muộn " + lateIn + " phút, ";
        if (lateIn >= Config.LATE.lte10.gt && lateIn < Config.LATE.lte10.lte) {
            subPoint += Config.LATE.lte10.des;
        }
        if (lateIn >= Config.LATE.gt10lte20.gt && lateIn < Config.LATE.gt10lte20.lte) {
            subPoint += Config.LATE.gt10lte20.des;
        }
        if (lateIn >= Config.LATE.gt20.gt) {
            subPoint += Config.LATE.gt20.des;
        }
    }
    if (isEarlyOut) {
        reason += "checkout sớm, ";
        subPoint += Config.LATE.notcheck;
    }
    return {
        reason: slot + " (" + x.checkin + " - " + x.checkout + ") " + reason + "trừ " + subPoint + " điểm",
        point: subPoint,
    };
};

/** update ngày 27/4/2023 (sprint 88):
 ** nghỉ bao nhiêu phút trừ bấy nhiêu điểm, tối đa 60 điểm (2h => sau 10h30 sáng hoặc sau 15h30 chiều) (tương đương trường hợp không checkin)
 ** không checkout (~ checkout sớm (trước 10h00 sáng hoặc trước 16h00 chiều)) trừ 15 điểm 
 @returns lý do và số điểm bị trừ*/
export const getReason2 = (x: IWorkingTime) => {
    let slot = x.slot;
    let [hi, mi] = x.checkin.split(":");
    let [ho, mo] = x.checkout.split(":");
    let reason = "";
    let subPoint = 0;
    if (!hi || !ho) {
        reason += (!hi ? "không checkin, " : "") + (!ho ? "không checkout, " : "");
        subPoint = subPoint + (!hi ? Config.LATE88.notCheckin : 0) + (!ho ? Config.LATE88.notCheckout : 0);
    }
    let timeIn = parseInt(hi) * 60 + parseInt(mi);
    let lateIn = 0;
    let isEarlyOut = false;
    if (slot === Config.ALL_DAY_VNESE) {
        // trường hợp làm cả ngày
        lateIn = timeIn - Config.LATE88.timeInMorning; // thời gian checkin trễ buổi sáng
        isEarlyOut = x.checkout < Config.CHECKOUT_AFTERNOON && !!x.checkout; // checkout sớm
    } else if (slot === Config.MORNING_VNESE) {
        // trường hợp chỉ làm sáng
        lateIn = timeIn - Config.LATE88.timeInMorning; // thời gian đi trễ buổi chiều
        isEarlyOut = x.checkout < Config.CHECKOUT_MORNING && !!x.checkout; // checkout sơm
    } else if (slot === Config.AFTERNOON_VNESE) {
        // trường hợp chỉ làm chiều
        lateIn = timeIn - Config.LATE88.timeInAfternoon; // thời gian đi trễ buổi chiều
        isEarlyOut = x.checkout < Config.CHECKOUT_AFTERNOON && !!x.checkout; // checkout sớm
    }
    if (lateIn >= 0) {
        reason += "muộn " + lateIn + " phút, ";

        if (lateIn >= Config.LATE88.gt60.gt) {
            // muộn quá 60p thì chỉ trừ 60 điểm thôi
            subPoint += Config.LATE88.gt60.des;
        } else {
            subPoint += lateIn; // muộn bnh phút trừ bấy nhiêu điểm
        }
    }
    if (isEarlyOut) {
        reason += "checkout sớm, ";
        subPoint += Config.LATE88.notCheckout;
    }
    return {
        reason: slot + " (" + x.checkin + " - " + x.checkout + ") " + reason + "trừ " + subPoint + " điểm",
        point: subPoint,
    };
};
/**
 * Tien xu ly du lieu chấm công, matching với dữ liệu xin nghỉ/ nghỉ lễ, làm bù ,...
 * @param workingDayList array[], truong 'date' phai duoc convert theo timezone truoc khi truyen vao
 * @param offDaysWfhDays array[], truong 'date' phai duoc convert theo timezone truoc khi truyen vao
 * @returns
 */
const CHECKIN_TO_CHECKOUT_MORNING = "11:00";
const CHECKIN_TO_CHECKOUT_AFTERNOON = "17:00";
export const handleWorkingDay = (workingDayList: IWorkingTime[], offDaysWfhDays: IOffDaysWfhDays[]) => {
    return workingDayList
        .map((item: any) => {
            let userData = offDaysWfhDays.find((r: any) => r?.userId?.toString() === item?.userId?.toString());
            if (userData) {
                let res: any = {};
                for (let key in item) {
                    res[key] = item[key];
                }

                let offday = userData.offDays.filter(
                    (od: any) => new Date(od?.date).getTime() == new Date(item?.date).getTime()
                );
                // xin nghỉ được lưu riêng từng buổi (không lưu allday nữa nha)
                if (offday.length) {
                    // co nghi
                    // if (offday.slot === Config.ALL_DAY_VNESE) return null; // nghi ca ngay thi khong check di muon
                    // else if (offday.slot === Config.MORNING_VNESE) {
                    //     // nghi sang thi check di muon chieu
                    //     res["slot"] = Config.AFTERNOON_VNESE;
                    //     let check = checkLate(item.checkin, 1, item.checkout);
                    //     if (!check) return null;
                    // } else if (offday.slot === Config.AFTERNOON_VNESE) {
                    //     //nghi chieu thi check di muon sang
                    //     res["slot"] = Config.MORNING_VNESE;
                    //     let check = checkLate(item.checkin, 0, item.checkout);
                    //     if (!check) return null;
                    // }
                    if (offday.length == 2) return null; // nghi ca ngay thi khong check di muon
                    else if (offday.length == 1) {
                        if (offday[0].slot === Config.MORNING_VNESE) {
                            // nghi sang thi check di muon chieu
                            res["slot"] = Config.AFTERNOON_VNESE;
                            let check = checkLate(item.checkin, 1, item.checkout);
                            if (!check) return null;
                        } else if (offday[0].slot === Config.AFTERNOON_VNESE) {
                            //nghi chieu thi check di muon sang
                            res["slot"] = Config.MORNING_VNESE;
                            let check = checkLate(item.checkin, 0, item.checkout);
                            if (!check) return null;
                        } else if (offday[0].slot === Config.ALL_DAY_VNESE) return null; // nghi ca ngay thi khong check di muon // vẫn còn trường hợp nghỉ cả ngày là intern
                    }
                } else {
                    //*_bug_* khong nghi = mac dinh la di lam ca ngay !!! sai !!! vi co the cty di lam sang/ chieu
                    let session = 0;
                    if (item.slot === Config.AFTERNOON) session = 1;
                    let check = checkLate(item.checkin, session, item.checkout);
                    res["slot"] = Config.ALL_DAY_VNESE;
                    if (!check) return null;
                }
                //---------------------- không còn wfh nữa nên cũng không cần quan tâm đoạn dưới này
                let wfhday = userData?.wfhDays?.find(
                    (wfh: any) => new Date(wfh?.date).getTime() == new Date(item?.date).getTime()
                );
                if (wfhday) {
                    //co lam online
                    if (wfhday.slot === Config.ALL_DAY_VNESE) return null; // onl ca ngay thi khong check di muon
                    else if (wfhday.slot === Config.MORNING_VNESE) {
                        // onl sang thi check di muon chieu
                        if (res?.slot === Config.MORNING_VNESE) return null; //trung lich lam onl thi khong can xet di muon
                        res["slot"] = Config.AFTERNOON_VNESE;
                        let check = checkLate(item.checkin, 1, item.checkout);
                        if (!check) return null;
                    } else if (wfhday.slot === Config.AFTERNOON_VNESE) {
                        //onl chieu thi check di muon sang
                        if (res?.slot === Config.AFTERNOON_VNESE) return null; //trung lich lam onl thi khong can xet di muon
                        res["slot"] = Config.MORNING_VNESE;
                        let check = checkLate(item.checkin, 0, item.checkout);
                        if (!check) return null;
                    }
                } else {
                    // da check o phan offday
                }
                return res;
            }
            return null;
        })
        .filter((item: any) => !!item)
        .map((item) => {
            let slot = item.slot;
            // xử lý trường hợp quên checkin nma có checkout, thì máy ghi nhận là checkin (vào giờ checkout) mà không có checkout
            // chỉ xét chuyển đổi trong thời gian 1 tiếng cuối cùng của ca làm (vd trường hợp sáng ghi nhận checkin sau 11h mới được coi là checkout...)
            if (!!item.checkin || !item.checkout) {
                if (slot === Config.ALL_DAY_VNESE || slot === Config.AFTERNOON_VNESE) {
                    // trường hợp làm cả ngày hoặc chỉ làm chiều
                    if (item.checkin > CHECKIN_TO_CHECKOUT_AFTERNOON) {
                        item.checkout = item.checkin;
                        item.checkin = "";
                    }
                } else if (slot === Config.MORNING_VNESE) {
                    // trường hợp chỉ làm sáng
                    if (item.checkin > CHECKIN_TO_CHECKOUT_MORNING) {
                        item.checkout = item.checkin;
                        item.checkin = "";
                    }
                }
            }
            return item;
        });
};

const checkLate = (checkin: any, session = 0 | 1, checkout: any) => {
    //1: chieu
    //0: sang
    if (!checkin || !checkout) return true;
    let [h, m] = checkin.split(":");
    h = parseInt(h);
    m = parseInt(m);
    if (session == 0) {
        if (h * 60 + m > Config.LATE.timeInMorning) return true;
    } else if (session == 1) {
        if (h * 60 + m > Config.LATE.timeInAfternoon) return true;
    }
    return false;
};
/** điểm bị trừ theo loại lỗi
 * nếu có dấu - trong offType thì mặc định trừ thêm 30 điểm nữa
 */
export const calculateDisciplinePoints = (sprint: ISprint, offDaysWfhDay?: IOffDaysWfhDays, workingTime?: IWorkingTime[]) => {
    let score = 0;
    // let onlDay = 0;
    sprint = new Sprint(sprint);

    let numOfOffDay = 0;
    if (workingTime?.length) {
        workingTime = workingTime.map((x) => {
            let milestone = new Date("05/01/2024").getTime();
            let { reason, point } = x.date.getTime() < milestone ? getReason(x) : getReason2(x);
            score += point;
            return {
                ...x,
                reason: reason,
            };
        });
    }

    let offday: IWfhDayOffDay[] = [];
    if (offDaysWfhDay?.offDays?.length) {
        //nghi khong phep
        offday = offDaysWfhDay.offDays;
        let firstUrgent = true;
        let countSlot = 0;
        let offsetScore = 0;
        offday = offday
            .map((d) => {
                let _score = 0;
                let _offsetScore = 0;
                if (!d?.offType) return new WfhDayOffDay();
                if (
                    new Date(d.date).getTime() >= new Date(sprint?.startDate).getTime() &&
                    new Date(d.date).getTime() <= new Date(sprint?.endDate).getTime()
                ) {
                    let _offType = d?.offType?.replace("-", "");
                    let num = offday.filter(
                        (_d) => new Date(d.date).getTime() == new Date(_d.date).getTime() && d.offType === _d.offType
                    ).length;
                    if (Config.OFF_TYPE[_offType]) {
                        // xử lý trường hợp chung (trừ điểm theo lỗi của lần nghỉ đầu tiên)
                        _score = Config.OFF_TYPE[_offType];
                        if (parseInt(sprint.shortId) > 81) {
                            _score = _score / num;
                        } else {
                            _offsetScore = _score / 2; // vì duyệt theo từng buổi nên chỗ này bị /2
                            if (d.offType == Config.OFF_TYPE_UNAUTHORIZED) {
                                // bổ sung theo logic mới ngày 12/12/2023: không lưu allday được nữa mà luật nghỉ không phép là trừ 30 điểm 1 LẦN nên trường hợp nghỉ cả ngày sẽ lưu thành 2 lần
                                // nên check lại chỗ này để chia đều số điểm bị trừ cho số slot trong 1 ngày đó
                                let _num = offday.filter(
                                    (_d) =>
                                        new Date(d.date).getTime() == new Date(_d.date).getTime() &&
                                        d.offType === Config.OFF_TYPE_UNAUTHORIZED
                                ).length;
                                _score = _score / _num;
                            }
                        }
                    }
                    if (d.slot !== Config.ALL_DAY_VNESE) numOfOffDay += 0.5;
                    else {
                        numOfOffDay += 1;
                        if (d.offType !== Config.OFF_TYPE_UNAUTHORIZED) _score *= 2;
                    }
                    if (parseInt(sprint.shortId) > 81) {
                        // xử lý trường hợp nghỉ từ lần thứ 2 trở đi (có dấu - trong offType)
                        // xét riêng cho update ngày 1/2/2023 (ứng vs sprint 82)
                        // offType sẽ có thêm dấu - để nhận biết ngày nghỉ này có bị trừ điểm hay không (ở tragn đăng ký đã check r, có dấu - thì trừ thêm 30 điểm)
                        // trừ theo LẦN nhé, và duyệt trong 1 tháng nên cần xử lý lại chỗ này
                        if (d?.offType.includes("-")) {
                            _score += Config.OFF_TYPE[Config.OFF_TYPE_DEFAULT] / num;
                        } else {
                            // trường hợp intern không đăng ký hoặc trường hợp xin nghỉ lần đầu tiên và trước 17h thì không ghi lỗi (vì offType == '')
                            if (!d.offType || d.offType === Config.OFF_TYPE_AUTHORIZED) return new WfhDayOffDay();
                        }
                    } else {
                        if (
                            (d?.offType?.includes(Config.OFF_TYPE_URGENT_1) ||
                                d?.offType?.includes(Config.OFF_TYPE_URGENT_2)) &&
                            firstUrgent
                        ) {
                            //bo qua lan vi pham dau tien
                            let isOk = true;
                            let slotsOfDay = offday.filter((_d) => new Date(d.date).getTime() == new Date(_d.date).getTime());
                            if (slotsOfDay.length == 1)
                                // nếu chỉ nghỉ 1 buổi trong ngày hôm đó thì nó cũng là lần đầu tiên cho phép luôn
                                firstUrgent = false;
                            else if (slotsOfDay.length == 2) {
                                // nếu nghỉ 2 buổi thì cần phải xem 2 buổi này có xin cùng nhau không hay buổi nào xin sau sẽ bị tính, căn cứ vào timeStamp trong reason
                                let timeStamp1 = slotsOfDay[0].reason.split("#").pop();
                                let timeStamp2 = slotsOfDay[1].reason.split("#").pop();
                                let isNotOkTimeStamp;

                                if (timeStamp1 && timeStamp2) {
                                    // nếu cả 2 có timeStamp
                                    if (timeStamp1 !== timeStamp2) {
                                        // 2 timeStamp khác nhau thì 1 trong 2 slot sẽ là trừ điểm (cái lớn hơn)
                                        isNotOkTimeStamp = timeStamp1?.localeCompare(timeStamp2) > 0 ? timeStamp1 : timeStamp2;

                                        if (d.reason.includes(isNotOkTimeStamp)) {
                                            isOk = false;
                                        }
                                    }
                                } else if (timeStamp1 || timeStamp2) {
                                    // trường hợp 1 trong 2 slot có timeStamp (logic mới thì mới có) thì coi như nó là lần đăng ký quá lượt rồi
                                    isNotOkTimeStamp = timeStamp1 ?? timeStamp2 ?? "#";
                                    if (d.reason.includes(isNotOkTimeStamp)) {
                                        isOk = false;
                                    }
                                }
                                // trong ngày hôm đó dù thế nào thì cũng phỉa xét xong cả ngày mới cần gán firstUrgent nên viết ra ngoài cùng như này
                                countSlot++;
                                if (countSlot == 2) firstUrgent = false;
                            }
                            if (isOk) {
                                _score = 0;
                                return new WfhDayOffDay();
                            }
                        }
                    }
                    score += _score;
                }
                let offType = "";
                if (d?.offType && Config.OFF_TYPE[d?.offType.replace("-", "")] && d?.offType !== Config.OFF_TYPE_UNAUTHORIZED) {
                    if (d?.offType?.includes(Config.OFF_TYPE_URGENT_1)) offType = "Nghỉ khẩn cấp mức 1";
                    if (d?.offType?.includes(Config.OFF_TYPE_URGENT_2)) offType = "Nghỉ khẩn cấp mức 2";
                    if (d?.offType?.includes("-")) offType += "-";
                    offType += ", ";
                }
                return {
                    ...d,
                    reason: d.slot + ", " + offType + removeNumberSignOfReason(d.reason) + ", trừ " + _score + " điểm",
                };
            })
            .filter(
                (d) =>
                    !!d._id &&
                    d.offType &&
                    d.offType !== Config.OFF_TYPE_AUTHORIZED &&
                    new Date(d.date).getTime() >= new Date(sprint?.startDate).getTime() &&
                    new Date(d.date).getTime() <= new Date(sprint?.endDate).getTime()
            );
        score -= offsetScore;
    }
    return {
        minusScore: score,
        offDaysUnauthorized: offday,
        workingTime: workingTime ?? [],
        offDay: numOfOffDay,
    };
};

export const formatDate = (date: Date, format = "default") => {
    if (!date) {
        return "";
    }
    let year: any = moment(date).tz(Config.TIMEZONE).year();
    let months: any = moment(date).tz(Config.TIMEZONE).month() + 1;
    let day: any = moment(date).tz(Config.TIMEZONE).date();
    var stringTime = "";
    var stringMonths = "";
    var stringDay = "";
    if (months < 10) {
        stringMonths = "0" + months.toString();
    } else {
        stringMonths = months.toString();
    }
    if (day < 10) {
        stringDay = "0" + day.toString();
    } else {
        stringDay = day.toString();
    }
    if (format === "dd/mm/yyyy") {
        stringTime = stringDay + "/" + stringMonths + "/" + year;
    } else if (format === "yyyy/mm/dd") {
        stringTime = year + "/" + stringMonths + "/" + stringDay;
    } else {
        stringTime = year + "-" + stringMonths + "-" + stringDay;
    }
    return stringTime;
};

export const converDisciplineDataToReport = (sprint: ISprint, offDaysWfhDay: OffDaysWfhDays, workingTime: IWorkingTime[]) => {
    let discipline = calculateDisciplinePoints(sprint, offDaysWfhDay, workingTime);
    let result = {
        minusScore: discipline.minusScore,
        offDaysUnauthorized: discipline.offDaysUnauthorized.map(
            (day: any) => formatDate(new Date(day.date), "yyyy/mm/dd") + ": " + day.reason
        ),
        workingTime: discipline.workingTime.map((day: any) => formatDate(new Date(day.date), "yyyy/mm/dd") + ": " + day.reason),
    };
    return result;
};

export const CHANNEL_IDS: any = {
    "test-bot": "1039727280076881961",
    "working-calendar": "895978400777383956",
    app: "869053905093419038",
};

export const removeNumberSignOfReason = (reason: string) => {
    let indexOfNumberSign = reason.lastIndexOf("#"); // index of # in reason - dấu # được thêm vào để check xem 2 slot trong cùng 1 ngày có được đk cugnf 1 lần hay không (được thêm khi xử lý data để gửi api dưới client)
    let _reason = reason;
    if (indexOfNumberSign > -1) _reason = _reason.slice(0, indexOfNumberSign); // cắt bỏ phần timeStamp đi
    return _reason;
};
