import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TYPE_ANALYTIC_INFO } from "../../../config_data_analytic";
import { ICertificates } from "../../../models/certificates.model";
import { setCertificateCompareAction } from "../../../redux/actions/dashboard.action";
import EyesIconSvg from "../../common/icon/EyesIcon";
import "./index.scss";
const LeftMenu = ({
    listcertificate,
    setShowAppInfo,
    showCompareRevenue,
}: {
    listcertificate: ICertificates[];
    setShowAppInfo: Function;
    showCompareRevenue: boolean;
}) => {
    const currentCertificate: ICertificates = useSelector(
        (state: any) => state.dashBoardState.currentCertificates
    );
    const refScroll = useRef<null | HTMLElement>(null);
    useEffect(() => {
        if (currentCertificate.id && refScroll.current) {
            refScroll.current?.scrollIntoView();
        }
    }, []);
    return (
        <div>
            <div className="item-certificate">
                {listcertificate.length > 0 &&
                    listcertificate.map(
                        (certificate: ICertificates, index: number) => {
                            return (
                                <MenuApp
                                    showCompareRevenue={showCompareRevenue}
                                    key={index}
                                    certificate={certificate}
                                    index={index}
                                    refScroll={
                                        currentCertificate.id === certificate.id
                                            ? refScroll
                                            : null
                                    }
                                    setShowAppInfo={(
                                        value: ICertificates,
                                        type: string
                                    ) => setShowAppInfo(value, type)}
                                />
                            );
                        }
                    )}
            </div>
        </div>
    );
};

const MenuApp = ({
    certificate,
    index,
    setShowAppInfo,
    showCompareRevenue,
    refScroll,
}: {
    showCompareRevenue: boolean;
    certificate: ICertificates;
    index: number;
    setShowAppInfo: Function;
    refScroll: any;
}) => {
    const dispatch = useDispatch();
    const currentCertificatesType = localStorage.getItem(
        "currentCertificatesType"
    );

    const [lightEye, setLightEye] = useState(false);
    let certificatesSelect: ICertificates[] = useSelector(
        (state: any) => state.dashBoardState.certificatesSelect
    );
    let find = certificatesSelect?.find(
        (e: ICertificates) => e.id == certificate.id
    );

    useEffect(() => {
        if (showCompareRevenue && find?.id == certificate.id) {
            setLightEye(showCompareRevenue);
        }
    }, [showCompareRevenue, find]);

    useEffect(() => {
        if (find?.id == certificate.id) {
            setLightEye(true);
        } else {
            setLightEye(false);
        }
    }, [find]);

    const selectAppFc = (certificate: ICertificates, type: string) => {
        setShowAppInfo(certificate, type);
    };

    const selectAppsCompare = (certificate: ICertificates) => {
        dispatch(setCertificateCompareAction(certificate));
        setLightEye(!lightEye);
    };

    return (
        <div ref={refScroll} style={{ background: refScroll ? "#E5E5E5" : "" }}>
            <div className="item-certificate-name">
                <div
                    className="group-start"
                    onClick={() => {
                        if (showCompareRevenue) {
                            selectAppsCompare(certificate);
                        } else {
                            selectAppFc(
                                certificate,
                                !!currentCertificatesType
                                    ? currentCertificatesType
                                    : TYPE_ANALYTIC_INFO
                            );
                        }
                    }}
                >
                    {showCompareRevenue && (
                        <div>
                            <EyesIconSvg lightEye={lightEye} />
                        </div>
                    )}
                    <p>{certificate.name.toUpperCase()}</p>
                </div>
            </div>
        </div>
    );
};

export default LeftMenu;
