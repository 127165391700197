import { ICampaign } from "../../shared/models/campaign";

export enum CampaignTypes {
    GET_ALL_CAMPAIGN = "GET_ALL_CAMPAIGN",
    GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS",
    GET_ALL_CAMPAIGN_FAILED = "GET_ALL_CAMPAIGN_FAILED",
    UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN",
    UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS",
    UPDATE_CAMPAIGN_FAILED = "UPDATE_CAMPAIGN_FAILED",
    DELETE_CAMPAIGN = "DELETE_CAMPAIGN",
    DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS",
    DELETE_CAMPAIGN_FAILED = "DELETE_CAMPAIGN_FAILED",
    ARCHIVE_CAMPAIGN_SUCCESS = "ARCHIVE_CAMPAIGN_SUCCESS",
    ADD_TASK_TO_CAMPAIGN = "ADD_TASK_TO_CAMPAIGN",
}

export interface ICamPaignAction {
    type: CampaignTypes;
    campaigns?: ICampaign[];
    error?: any;
    campaignId?: string;
    updateTasks?: boolean;
    parentId?: string;
    taskIds?: string[];
    campaignIds?: string[];
}

export const getAllCampaign = (): ICamPaignAction => {
    return {
        type: CampaignTypes.GET_ALL_CAMPAIGN,
    };
};

export const getAllCampaignSuccess = (campaigns: ICampaign[]): ICamPaignAction => {
    return {
        type: CampaignTypes.GET_ALL_CAMPAIGN_SUCCESS,
        campaigns,
    };
};

export const getAllCampaignFailed = (error: any): ICamPaignAction => {
    return {
        type: CampaignTypes.GET_ALL_CAMPAIGN_FAILED,
        error,
    };
};

export const updateCampaign = (campaign: ICampaign, updateTasks?: boolean): ICamPaignAction => {
    return {
        type: CampaignTypes.UPDATE_CAMPAIGN,
        campaigns: [campaign],
        updateTasks,
    };
};

export const updateCampaigns = (campaigns: ICampaign[]): ICamPaignAction => {
    return {
        type: CampaignTypes.UPDATE_CAMPAIGN,
        campaigns: campaigns,
    };
};

export const updateCampaignSuccess = (campaigns: ICampaign[]): ICamPaignAction => {
    return {
        type: CampaignTypes.UPDATE_CAMPAIGN_SUCCESS,
        campaigns,
    };
};

export const updateCampaignFailed = (error: any): ICamPaignAction => {
    return {
        type: CampaignTypes.UPDATE_CAMPAIGN_FAILED,
        error,
    };
};

export const deleteCampaign = (campaign: ICampaign): ICamPaignAction => {
    return {
        type: CampaignTypes.DELETE_CAMPAIGN,
        campaigns: [campaign],
    };
};

export const deleteCampaignSuccess = (campaigns: ICampaign[]): ICamPaignAction => {
    return {
        type: CampaignTypes.DELETE_CAMPAIGN_SUCCESS,
        campaigns,
    };
};

export const deleteCampaignFailed = (error: any): ICamPaignAction => {
    return {
        type: CampaignTypes.DELETE_CAMPAIGN_FAILED,
        error,
    };
};

export const archiveCampaignSuccess = (campaignId: string): ICamPaignAction => {
    return { type: CampaignTypes.ARCHIVE_CAMPAIGN_SUCCESS, campaignId };
};

export const addTaskToCampaign = (campaignIds: string[], taskIds: string[]): ICamPaignAction => {
    return { type: CampaignTypes.ADD_TASK_TO_CAMPAIGN, taskIds, campaignIds };
};
