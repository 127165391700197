import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import Url from "../../util/url";
import { RatingApp } from "../../shared/models/ratingApp";
import { RatingAppHistory } from "../../shared/models/ratingApp";
import {RatingAppTypes, IRatingAppAction, updateRatingAppFailed, updateRatingAppSuccess,getRatingAppSuccess, updateRatingHistoriesAppSuccess, updateRatingHistoriesAppFailed, getHistoriesRatingSuccess, getHistoriesRatingFailed, deleteDataratingAppHistorySuccess, deleteDataratingAppHistoryFailed } from "../actions/ratingApp.action";


async function updateRatingHistoriesAppAPI(historiesRating: RatingAppHistory): Promise<RatingAppHistory | null> {
    return await axios.post(Url("historiesRating/update-data"), { data: historiesRating }).then((data) => {
        if (data.status == 200 && data.data && data.data[0]) {
            return new RatingAppHistory(data.data[0]);
        }
        return null;
    });
}
function* updateRatingHistoriesAppSaga(action: IRatingAppAction) {
    const historiesRating = action.ratingAppHistory;
    if (historiesRating) {
        try {
            const historiesRatings: RatingAppHistory = yield call(updateRatingHistoriesAppAPI, historiesRating);
            if (historiesRatings) {
                yield put(updateRatingHistoriesAppSuccess(historiesRatings));
            } else {
                yield put(updateRatingHistoriesAppFailed("appInfo not existed!"));
            }
        } catch (err) {
            console.log("err",err)
            yield put(updateRatingAppFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(updateRatingAppFailed("updateAppSaga ERROR: appInfo not found!"));
    }
}
async function deleteRatingHistoriesAPI(id: string): Promise<string | null> {
    return await axios
        .post(Url("historiesRating/delete-data"), { id })
        .then((data) => {
            if (data.status == 200) {
                return id;
            }
            return null;
        });
}
function* deleteRatingHistoriesSaga(action: IRatingAppAction) {
    const histories = action.ratingAppHistory;
    if (histories) {
        try {
            const id: string | null = yield call(
                deleteRatingHistoriesAPI,
                histories._id
            );
            if (id) {
                yield put(deleteDataratingAppHistorySuccess(histories));
            } else {
                yield put(
                    deleteDataratingAppHistoryFailed("Histories not existed!")
                );
            }
        } catch (err) {
            yield put(
                deleteDataratingAppHistoryFailed("Histories ERROR: " + err)
            );
        }
    } else {
        yield put(
            deleteDataratingAppHistoryFailed(
                "update personalsGoals ERROR: appInfo not found!"
            )
        );
    }
}
async function getHistoriesRatingAPI(): Promise<RatingAppHistory[]> {
    return await axios.get(Url("historiesRating/get-data")).then((data) => {
        if (data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new RatingAppHistory(item);
            });
        }
        return [];
    });
}
function* getHistoriesRatingSaga(action: IRatingAppAction) {
    try {
        let dataHistories: RatingAppHistory[] = yield call(
            getHistoriesRatingAPI
        );
        if (dataHistories) {
            yield put(getHistoriesRatingSuccess(dataHistories));
        } else {
            yield put(getHistoriesRatingFailed("userID not existed!"));
        }
    } catch (err) {
        yield put(getHistoriesRatingFailed("userID ERROR: " + err));
    }
}
async function updateRatingAppAPI(ratingApp: RatingApp): Promise<RatingApp | null> {
    return await axios.post(Url("rating/update-data"), { data: ratingApp }).then((data) => {
        if (data.status == 200 && data.data && data.data[0]) {
            return new RatingApp(data.data[0]);
        }
        return null;
    });
}

function* updateRatingAppSaga(action: IRatingAppAction) {
    const ratingApp = action.ratingApp;
    if (ratingApp) {
        try {
            const ratingApps: RatingApp = yield call(updateRatingAppAPI, ratingApp);
            if (ratingApps) {
                yield put(updateRatingAppSuccess(ratingApps));
            } else {
                yield put(updateRatingAppFailed("appInfo not existed!"));
            }
        } catch (err) {
            console.log("err",err)
            yield put(updateRatingAppFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(updateRatingAppFailed("updateAppSaga ERROR: appInfo not found!"));
    }
}
async function getRatingAppAPI(startTime: number, finishTime: number): Promise<any> {
    let result = await axios.post(Url(`rating/app-rating-statistics?startTime=${startTime}&finishTime=${finishTime}`));
    return result?.data ?? null;
}
export function* getRatingAppSaga(action: IRatingAppAction) {
    let startTime = action.startTime ?? 0
    let finishTime = action.finishTime ?? 0
        let data: any[] = yield call(getRatingAppAPI,startTime,finishTime);
        if (data) {
            yield put(getRatingAppSuccess(data));
        }
}
export function* watchAppStatistics() {
    yield takeLatest(RatingAppTypes.UPDATE_RATING_APP, updateRatingAppSaga);
    yield takeLatest(RatingAppTypes.UPDATE_HISTORIES_RATING_APP, updateRatingHistoriesAppSaga);
    yield takeLatest(RatingAppTypes.GET_RATING_APP, getRatingAppSaga);
    yield takeLatest(RatingAppTypes.GET_HISTORIES_RATING_APP, getHistoriesRatingSaga);
    yield takeLatest(RatingAppTypes.DELETE_HISTORIES_RATING_APP, deleteRatingHistoriesSaga);
}
