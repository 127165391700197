import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import Url from "../../util/url";
import { IOffDaysWfhDays, OffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import {
    getAllWorkingTimeInfoFailed,
    getAllWorkingTimeInfoSuccess,
    IDuration,
    IWorkingTimeAction,
    WorkingTimeTypes,
} from "../actions/workingTime.action";
import { IWorkingTime, WorkingTime } from "../../shared/models/workingTime";
import { IUserInfo } from "../../shared/models/userInfo";
import { handleWorkingDay } from "../../shared/utils";

async function getWorkingTimesAPI(
    duration: IDuration,
    userInfo?: IUserInfo
): Promise<{ offDaysWfhDays: OffDaysWfhDays[]; workingTime: WorkingTime[] } | undefined> {
    return await axios
        .post(Url("working-time/get-all-working-times"), {
            startDuration: duration.start,
            endDuration: duration.end,
            userId: userInfo?._id,
        })
        .then((response) => {
            return preHandleData(response);
        });
}

const preHandleData = (response: any) => {
    let offDaysWfhDays = response?.data?.offDaysWfhDays.map((item: any) => new OffDaysWfhDays(item));
    let workingTime = response?.data?.workingTime.map((item: any) => new WorkingTime(item));
    workingTime = handleWorkingDay(workingTime, offDaysWfhDays);
    return { offDaysWfhDays: offDaysWfhDays, workingTime: workingTime };
};
export function* getWorkingTimeSaga(action: IWorkingTimeAction) {
    let duration = action.data;
    let userInfo = action.userInfo;
    if (duration) {
        const workingTimeReducer: {
            offDaysWfhDays: IOffDaysWfhDays[];
            workingTime: IWorkingTime[];
        } = yield call(getWorkingTimesAPI, duration, userInfo);
        try {
            if (workingTimeReducer) {
                yield put(getAllWorkingTimeInfoSuccess(workingTimeReducer));
            } else {
                yield put(getAllWorkingTimeInfoFailed("Working Time Data is null"));
            }
        } catch (e) {
            yield put(getAllWorkingTimeInfoFailed(e));
        }
    } else {
        yield put(getAllWorkingTimeInfoFailed("duration not found!"));
    }
}

export function* watchGetWorkingTime() {
    yield takeLatest(WorkingTimeTypes.GET_WORKING_TIME, getWorkingTimeSaga);
}
