import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import axios from "axios";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import filter_icon from "../../assets/icon/filter_icon.png";
import { getAllCampaign } from "../../redux/actions/campaign.action";
import { getAllProject } from "../../redux/actions/project.action";
import { getAllStatus } from "../../redux/actions/status.action";
import { getAllTask, getTasksByIds } from "../../redux/actions/task.action";
import { getTestsFailed, getTestsSuccess } from "../../redux/actions/testCase.action";
import { getCyclesFailed, getCyclesSuccess } from "../../redux/actions/testCycle.action";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
import { ICampaignState } from "../../redux/reducers/campaign_new.reducer";
import { IProjectState } from "../../redux/reducers/project.reducer";
import { ISprintState } from "../../redux/reducers/sprint.reducer";
import { IStatusState } from "../../redux/reducers/status.reducer";
import { ITaskState } from "../../redux/reducers/task.reducer";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
// import { getTaskAPI } from "../../redux/sagas/task.saga";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import { useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { toast } from "react-toastify";
import Constants from "../../constant";
import { getDataPersonalsGoalAcation } from "../../redux/actions/personalsGoal.action";
import { getSprintReportV2Data } from "../../redux/actions/sprintReport.action";
import { getAllUserGroup } from "../../redux/actions/userGroup.action";
import { getAllWorkingTimeInfo } from "../../redux/actions/workingTime.action";
import { IUserGroupState } from "../../redux/reducers/userGroup.reducer";
import Config from "../../shared/models/config";
import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import { IProject } from "../../shared/models/project";
import { ISprint } from "../../shared/models/sprint";
import { IStatus } from "../../shared/models/status";
import { ITask } from "../../shared/models/task";
import { IUserGroup } from "../../shared/models/userGroup";
import { ICoefficients, IUserInfo, UserCoefficient } from "../../shared/models/userInfo";
import { IWorkingTime } from "../../shared/models/workingTime";
import { isLeaderEditPoint } from "../../shared/permissions";
import { roleForPoint, roleViewForPoint } from "../../shared/utils";
import { converDataCampaign, isAdmin, timezoneSetHours } from "../../util";
import Url from "../../util/url";
import { getUserDisciplineScore, getUserTaskScores } from "../../utils";
import LoadingWidget from "../LoadingWidget";
import CampaignProgressWidget from "./campaign/campaign-progress";
import ExpectationAndNotificationComponent from "./expectation-and-notification";
import MergeTaskWidget from "./merge-task";
import DashboardProjectsFilter from "./projects-filter";
import DashboardSidebarV2 from "./sidebar/sidebarV2";
import DashboardSprintColumnsV2 from "./sprint-columns/DashboardSprintColumnsV2";
import "./style.scss";
import DashboardUsersFilter from "./users-filter/UserFilterWidget";
const DashboardV2 = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUserInfo());
        dispatch(getAllStatus());
        dispatch(getAllProject());
    }, []);
    const statusState: IStatusState = useSelector((state: any) => state.statusState);
    const sprintState: ISprintState = useSelector((state: any) => state.sprintState);
    const projectState: IProjectState = useSelector((state: any) => state.projectState);
    const userInfoState: IUserInfoState = useSelector((state: any) => state.userInfoState);
    if (
        statusState.loading ||
        !statusState.status ||
        sprintState.loading ||
        !sprintState.sprints ||
        projectState.loading ||
        !projectState.projects ||
        userInfoState.loading ||
        !userInfoState.userInfos
    ) {
        return <LoadingWidget />;
    }
    return (
        <DashboardView
            projects={projectState.projects}
            sprints={sprintState.sprints}
            status={statusState.status}
            users={userInfoState.userInfos}
        />
    );
};

type DashboardProps = {
    sprints: ISprint[];
    status: IStatus[];
    projects: IProject[];
    users: IUserInfo[];
};
const DashboardView: FC<DashboardProps> = memo(
    (props) => {
        // const params: any = useParams();
        // const { taskID } = params; //task/id....
        // const searchParams = new URLSearchParams(window.location.search);
        // let taskIDSearchParam = searchParams.get("taskID"); // /.../?taskID=...
        const dispatch = useDispatch();
        // const [sprintId, setSprintId] = useState<string | undefined>();
        useEffect(() => {
            dispatch(getDataPersonalsGoalAcation());
        }, []);
        const userGroupState: IUserGroupState = useSelector((state: any) => state.userGroupState);
        useEffect(() => {
            // console.log("window.location.pathname", window.location.pathname)
            if (!userGroupState.loading && userGroupState.userGroups.length == 0 && window.location.pathname != "/user-group") {
                dispatch(getAllUserGroup());
            }
        }, []);
        // useEffect(() => {
        //     let taskId = taskID ?? taskIDSearchParam ?? "";
        //     if (taskId) {
        //         getTaskAPI(taskId)
        //             .then((task) => {
        //                 // setSprintId(task?.sprintId ?? "");
        //                 if (task) dispatch(setTaskShown(task));
        //             })
        //             .catch((e) => {
        //                 // setSprintId("");
        //             });
        //     } else {
        //         // setSprintId("");
        //     }
        // }, [taskID, taskIDSearchParam]);
        // if (typeof sprintId === "undefined") {
        //     return <LoadingWidget />;
        // }
        const isMobile = useMediaQuery("(max-width:780px)");
        return (
            <Container
                sx={{
                    maxWidth: "unset !important",
                    padding: isMobile ? "4px !important" : "16px 56px !important",
                }}
                className="dashboard-v2-container"
            >
                <div className="dashboard-grid">
                    <DashboardViewContent
                        {...props}
                        //  sprintId={sprintId}
                    />
                </div>
            </Container>
        );
    },
    (p, n) => {
        if (
            p.projects.length != n.projects.length ||
            p.sprints.length != n.sprints.length ||
            p.status.length != n.status.length ||
            p.users.length != n.users.length
        )
            return false;
        return true;
    }
);

const DashboardViewContent: FC<DashboardProps> = memo(
    (props) => {
        const params: any = useParams();
        const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
        const { sprints, users } = props;
        const { id } = params;
        const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
        const newSprintReports: any = useSelector((state: any) => state.sprintReportState.newSprintReports);
        const workingTimes: IWorkingTime[] = useSelector((state: any) => state.workingTime.workingTime);
        const offDaysWfhDays: IOffDaysWfhDays[] = useSelector((state: any) => state.workingTime.offDaysWfhDays);
        const [userTaskScore, setUserTaskScore] = useState<any>({});
        const [userDisciplineScore, setUserDisciplineScore] = useState<any>({});
        const [coef, setCoef] = useState<{ [userId: string]: ICoefficients }>({});
        let _currentSprint: ISprint | undefined = undefined;
        // if (sprintId) {
        //     let showCampaignTasks = window.location.search.indexOf(Constants.SHOW_CAMPAIGN_TASKS) != -1;
        //     if (!showCampaignTasks) {
        //         _currentSprint = sprints.find((sprint) => sprint._id == sprintId);
        //     }
        // }
        if (id) {
            _currentSprint = sprints.find(
                (sprint) => sprint._id == id || sprint.shortId == id || (id == "active" && sprint.activeSprint)
            );
        }
        if (!_currentSprint) {
            _currentSprint = sprints.find((sprint) => sprint.activeSprint);
        }
        const currentSprint = _currentSprint ?? sprints[0];
        const history = useHistory();
        const getSearchParams = () => {
            let searchParams = new URLSearchParams(history.location.search);
            let userStr = searchParams.get("user");
            let projectStr = searchParams.get("project");
            return {
                userIds: userStr?.split(",") ?? [],
                projectIds: projectStr?.split(",") ?? [],
            };
        };
        const filterUserWithSearchParams = (ids: string[]): string[] => {
            // bỏ những user_id không có trong users
            return ids
                .map((id) => {
                    return users.find((u) => u._id == id)?._id ?? "";
                })
                .filter((id) => !!id);
        };
        const searchParams = getSearchParams();
        const [currentUserIds, setCurrentUserIds] = useState<string[]>(filterUserWithSearchParams(searchParams.userIds));
        const dispatch = useDispatch();
        const getTestCaseTestCycle = async () => {
            await axios
                .get(Url("test-case/get-test-cases-test-cycles-sprint?sprintID=" + currentSprint._id))
                .then((response) => {
                    let { testCases, testCycles } = response.data;
                    dispatch(getTestsSuccess(testCases));
                    dispatch(getCyclesSuccess(testCycles));
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(getTestsFailed(e));
                    dispatch(getCyclesFailed(e));
                });
        };
        useEffect(() => {
            if (currentSprint) {
                dispatch(getAllTask(currentSprint._id));
                getTestCaseTestCycle();
                let csp = JSON.parse(JSON.stringify(currentSprint));
                if (csp.activeSprint) {
                    // sprint active thi moi can lay data ve, con khong thi data lay bang getSprintReportV2Data
                    let _startSprint = timezoneSetHours(csp.startDate).getTime();
                    let _endSprint = timezoneSetHours(csp.endDate, 23, 59, 59).getTime();
                    // let _today = timezoneSetHours(new Date(), 23, 59, 59).getTime();
                    let start = _startSprint;
                    let end = _endSprint; // query dư ra 1 ngày để lấy trường hợp nghỉ khẩn cấp

                    if (csp) {
                        if (roleViewForPoint(userInfo)) {
                            dispatch(getAllWorkingTimeInfo({ start, end }, userInfo));
                        } else if (userInfo.role === Config.USER_ROLE_ADMIN) {
                            let start = _startSprint;
                            // let end = _endSprint > _today ? _today : _endSprint;
                            dispatch(getAllWorkingTimeInfo({ start, end }));
                        }
                    }
                    let coefs: { [key: string]: ICoefficients } = {};
                    for (let user of users) {
                        if (roleViewForPoint(user)) {
                            coefs[user._id] = new UserCoefficient(user);
                        }
                    }
                    setCoef(coefs);
                } else {
                    dispatch(getSprintReportV2Data(currentSprint)); // neu la sprint cu thi expectation di kem trong nay vi sprintExpData chi co du lieu cua cac sprint truoc do
                }
            }
        }, [currentSprint]);
        useEffect(() => {
            if (tasks?.length && currentSprint?.activeSprint) {
                let data: any = getUserTaskScores({
                    tasks,
                    currentSprint,
                    users: users.filter((u) => {
                        if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                        else return true;
                    }),
                });
                if (JSON.stringify(data).localeCompare(JSON.stringify(userTaskScore)) != 0) {
                    setUserTaskScore(data);
                }
            }
        }, [tasks]);
        useEffect(() => {
            if (
                currentSprint?.activeSprint &&
                offDaysWfhDays &&
                workingTimes &&
                (offDaysWfhDays?.length || workingTimes?.length)
            ) {
                let listDisciplineScore: any = getUserDisciplineScore({
                    currentSprint,
                    users: users.filter((u) => {
                        if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                        else return true;
                    }),
                    workingTimes,
                    offDaysWfhDays,
                });
                if (JSON.stringify(listDisciplineScore).localeCompare(JSON.stringify(userDisciplineScore)) != 0) {
                    setUserDisciplineScore(listDisciplineScore);
                }
            }
        }, [offDaysWfhDays, workingTimes]);
        useEffect(() => {
            if (!currentSprint?.activeSprint) {
                if (Object.keys(newSprintReports)?.length) {
                    let listTaskScore: any = getUserTaskScores({
                        newSprintReports,
                        currentSprint,
                        users: users.filter((u) => {
                            if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                            else return true;
                        }),
                    });
                    let listDisciplineScore: any = getUserDisciplineScore({
                        currentSprint,
                        users: users.filter((u) => {
                            if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                            else return true;
                        }),
                        newSprintReports,
                    });
                    let coefs: { [key: string]: ICoefficients } = {};
                    for (let key in newSprintReports) {
                        coefs[key] = new UserCoefficient(newSprintReports[key][0]?.coefficients);
                    }
                    setCoef(coefs);
                    setUserTaskScore(listTaskScore);
                    setUserDisciplineScore(listDisciplineScore);
                }
            }
        }, [newSprintReports]);
        return (
            <>
                <div className="background-color-2 radius-12 min-width" style={{ padding: 0, height: "fit-content" }}>
                    <DashboardSidebarV2
                        currentSprint={currentSprint}
                        backButton={false}
                        currentUsersInfo={currentUserIds
                            .filter((userId) => {
                                let user = users.find((u) => u._id === userId);
                                if (user && roleForPoint(user)) {
                                    return true;
                                }
                                return false;
                            })
                            .map((userId) => {
                                let user = users.find((u) => u._id === userId);
                                if (user) return user;
                            })}
                        coefs={coef}
                        userTaskScore={userTaskScore}
                        userDisciplineScore={userDisciplineScore}
                    />
                </div>
                <div style={{ padding: 0, overflowX: "hidden" }}>
                    <div className="grid-main-content">
                        <ExpectationAndNotificationComponent
                            currentSprint={currentSprint}
                            taskScores={userTaskScore[userInfo._id]}
                            disciplinePoints={userDisciplineScore[userInfo._id]}
                        />
                        <DashboardTaskView
                            {...props}
                            currentSprint={currentSprint}
                            currentUserIds={currentUserIds}
                            setCurrentUserIds={setCurrentUserIds}
                            userTaskScore={userTaskScore}
                        />
                    </div>
                </div>
            </>
        );
    },
    (p, n) => {
        if (
            p.projects.length != n.projects.length ||
            p.sprints.length != n.sprints.length ||
            p.status.length != n.status.length ||
            p.users.length != n.users.length
        )
            return false;
        return true;
    }
);

const DashboardTaskView: FC<
    DashboardProps & {
        currentSprint: ISprint;
        currentUserIds: string[];
        setCurrentUserIds: any;
        userTaskScore: any;
    }
> = memo(
    ({ projects, currentSprint, currentUserIds, setCurrentUserIds, userTaskScore }) => {
        const history = useHistory();
        const campaignState: ICampaignState = useSelector((state: any) => state.campaignState);
        const [selectedFilter, setSelectedFilter] = useState<ISelectFilter>("");
        const [showAllTasks, setShowAllTasks] = useState(
            !!localStorage.getItem("showAllTasks") ? (localStorage.getItem("showAllTasks") == "true" ? true : false) : false
        );
        const [showButtonArchiveCampaign, setShowButtonArchiveCampaign] = useState(false);
        const [openArchive, setOpenArchive] = useState(false);
        const [showModalMergeTask, setShowModalMergeTask] = useState(false);
        const [showCheckBox, setShowCheckBox] = useState(false);
        const [listId, setListId] = useState<string[]>([]);
        const getSearchParams = () => {
            let searchParams = new URLSearchParams(history.location.search);
            let userStr = searchParams.get("user");
            let projectStr = searchParams.get("project");
            return {
                userIds: userStr?.split(",") ?? [],
                projectIds: projectStr?.split(",") ?? [],
            };
        };
        const campaignTasks = useSelector((state: any) => state.taskState.campaignTasks);
        const user = useSelector((state: any) => state.authState.user);
        const filterProjectWithSearchParams = (ids: string[]): string[] => {
            // chuyển tên project thành id và bỏ những tên hoặc id không có trong projects
            return ids
                .map((id) => {
                    return projects.find((p) => p._id == id || p.title == id)?._id ?? "";
                })
                .filter((id) => !!id);
        };

        const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
        const searchParams = getSearchParams();
        const [currentProjectIds, setCurrentProjectIds] = useState<string[]>(
            filterProjectWithSearchParams(searchParams.projectIds)
        );

        const pushSearchParams = (projectIds: string[], userIds: string[]) => {
            if (projectIds.length && userIds.length) {
                history.push(`?user=${userIds.join(",")}&project=${projectIds.join(",")}`);
            } else if (projectIds.length && !userIds.length) {
                history.push(`?project=${projectIds.join(",")}`);
            } else if (!projectIds.length && userIds.length) {
                history.push(`?user=${userIds.join(",")}`);
            } else {
                history.push(window.location.pathname); /////////////////////////////////////// check
            }
        };
        const onSelectedProject = (id: string) => {
            let newList = [...currentProjectIds];
            let index = newList.indexOf(id);
            if (index == -1) {
                newList.push(id);
            } else {
                newList.splice(index, 1);
            }
            setCurrentProjectIds(newList);
            pushSearchParams(newList, currentUserIds);
        };
        const loadCampaignTasks = async (campaignIds: string[]) => {
            let campaigns = campaignIds
                .map((c) => {
                    if (!campaignTasks[c]) {
                        let campaign = campaignState?.campaigns?.find((cp) => cp._id === c);
                        if (campaign) {
                            return campaign;
                        }
                    }
                    return null;
                })
                .filter((c) => c);
            let { listTaskIds, campaignObj } = converDataCampaign(campaigns);
            dispatch(getTasksByIds(listTaskIds, campaignObj));
        };
        const onSelectedUser = (id: string) => {
            let newList = [...currentUserIds];
            let index = newList.indexOf(id);
            if (index == -1) {
                newList.push(id);
            } else {
                newList.splice(index, 1);
            }
            setCurrentUserIds(newList);
            pushSearchParams(currentProjectIds, newList);
        };
        const clearFilter = () => {
            setCurrentProjectIds([]);
            setCurrentUserIds([]);
            pushSearchParams([], []);
            setForceCurrentCampaignIds(null);
            setSelectedFilter("");
        };
        // hiện thị những task có currentTaskIds, nếu currentTaskIds trống thì hiện hết
        // const [currentTaskIds, setCurrentTaskIds] = useState<string[]>([]);
        // hiện thị những task theo campaign
        const [forceCurrentCampaignIds, setForceCurrentCampaignIds] = useState<string[] | null>(null);
        const _displayedCampaign = localStorage.getItem("showCampaigns")?.startsWith("true") ?? true;
        const dispatch = useDispatch();
        useEffect(() => {
            if (!_displayedCampaign && !campaignState.campaigns.length) {
                // da luu roi thi khong can load lai nua, neu co update thi cung co update truc tiep vao redux luon, chi can load lan dau vao trang
                dispatch(getAllCampaign());
            }
        }, [_displayedCampaign]);
        const getMemberInGroupWhenImLead = (userGroups: IUserGroup[], user: IUserInfo) => {
            let setMemberGroupIds = new Set<string>();
            userGroups.forEach((group) => {
                let isLeader = false;
                group.users.forEach((u) => {
                    if (u.role === "lead" && u.userId === user._id) {
                        isLeader = true;
                    }
                });
                if (isLeader) {
                    group.users.forEach((u) => {
                        if (u.role !== "lead") setMemberGroupIds.add(u.userId);
                    });
                }
            });
            // if (setMemberGroupIds.size > 0) ///////////////////////////////// nếu sau có vđề gì về việc hiện thanh user owr member thì chính là chỗ này nha
            setMemberGroupIds.add(user._id);
            return setMemberGroupIds;
        };
        const [activeCampains, setActiveCampains] = useState(_displayedCampaign);
        const setMemberGroupIds: Set<string> = getMemberInGroupWhenImLead(userGroups, user);
        const loadingTasks: ITaskState = useSelector((state: any) => state.taskState.loading);
        if (loadingTasks) {
            return <LoadingWidget />;
        }
        const onHide = () => {
            setShowModalMergeTask(false);
        };
        return (
            <div>
                <div className="main-body">
                    <div>
                        <SelectFilter
                            value={selectedFilter}
                            onChange={setSelectedFilter}
                            setShowAllTasks={() => {
                                setShowAllTasks(!showAllTasks);
                                localStorage.setItem("showAllTasks", showAllTasks ? "false" : "true");
                            }}
                            showAllTasks={showAllTasks}
                        />
                        {isLeaderEditPoint(user) && (
                            <div>
                                <div className={!(listId.length > 0) ? "btn-merge-task" : "btn-click-merge-task"}>
                                    {!(listId.length > 0) ? (
                                        <Button
                                            onClick={() => {
                                                setShowCheckBox(true);
                                                if (!(listId.length > 0)) {
                                                    toast("Vui lòng chọn task muốn merge !", { position: "top-center" });
                                                }
                                            }}
                                        >
                                            <span>Merge Task</span>
                                        </Button>
                                    ) : (
                                        <Button style={{ marginLeft: "15px" }} onClick={() => setShowModalMergeTask(true)}>
                                            <span>Merge</span>
                                        </Button>
                                    )}
                                </div>
                                <div className="btn-merge-task">
                                    {showCheckBox && (
                                        <Button style={{ marginLeft: "15px" }} onClick={() => setShowCheckBox(false)}>
                                            <span>Hủy</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="background-color-2 radius-12 align-item-center column">
                            <div className={"body-grid-area campaigns " + (activeCampains ? "active-campaigns" : "")}>
                                <div className="background-color-2">
                                    <Button
                                        variant="text"
                                        className="show-campaigns-button"
                                        onClick={() => {
                                            setActiveCampains(!activeCampains);
                                            localStorage.setItem("showCampaigns", !activeCampains + "");
                                        }}
                                    >
                                        <span>Campaigns</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="wrap-icon-archive">
                                <Collapse in={showButtonArchiveCampaign}>
                                    <div
                                        className="justify-content-center"
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "8px",
                                        }}
                                        onClick={() => {
                                            setOpenArchive(!openArchive);
                                        }}
                                    >
                                        <Inventory2RoundedIcon htmlColor={openArchive ? "#fff" : "rgba(255,255,255,0.6)"} />
                                    </div>
                                </Collapse>
                                <img
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShowButtonArchiveCampaign(!showButtonArchiveCampaign);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    src={showButtonArchiveCampaign ? "/hide-campaign.svg" : "/show-campaign.svg"}
                                />
                            </div>
                        </div>

                        <div className="body-grid-area projects-filter" style={{ cursor: "pointer" }} onClick={() => {}}>
                            <DashboardProjectsFilter
                                currentProjectIds={currentProjectIds}
                                onSelectedProject={onSelectedProject}
                                isNewVersion={true}
                            />
                        </div>
                    </div>

                    <div className="body-grid-area tasks">
                        {setMemberGroupIds.size > 0 || isAdmin(user) ? (
                            <div className="filter-user-container" style={{ marginBottom: "5px" }}>
                                <DashboardUsersFilter
                                    currentUserIds={currentUserIds}
                                    onSelectedUser={onSelectedUser}
                                    setMemberGroupIds={setMemberGroupIds}
                                    currentSprintId={currentSprint._id}
                                    userTaskScore={userTaskScore}
                                />
                            </div>
                        ) : (
                            <div style={{ height: "66px" }}></div>
                        )}
                        {activeCampains && (
                            <div style={{ marginBottom: "16px" }}>
                                <CampaignProgressWidget
                                    currentSprint={currentSprint}
                                    currentProjectIds={currentProjectIds}
                                    onShowTaskIds={(campaignIds: string[], onReload?: boolean) => {
                                        if (!onReload) {
                                            clearFilter();
                                        }
                                        if (campaignIds?.length) {
                                            loadCampaignTasks(campaignIds);
                                            history.push(`?${Constants.SHOW_CAMPAIGN_TASKS}=${campaignIds.join(",")}`);
                                        } else history.push(window.location.pathname);
                                        setForceCurrentCampaignIds(campaignIds.length ? campaignIds : null);
                                    }}
                                    archive={openArchive}
                                />
                            </div>
                        )}
                        <DashboardSprintColumnsV2
                            currentProjectIds={currentProjectIds}
                            currentUserIds={currentUserIds}
                            showAllTasks={showAllTasks}
                            forceCurrentCampaignIds={forceCurrentCampaignIds}
                            selectedFilter={selectedFilter}
                            setMembersInGroup={Array.from(setMemberGroupIds)}
                            isShowCheckBox={showCheckBox}
                            handleGetIds={setListId}
                        />
                    </div>
                </div>
                {showModalMergeTask && <MergeTaskWidget onHide={onHide} listId={listId} setShowCheckBox={setShowCheckBox} />}
            </div>
        );
    },
    (prevProps, nextProps) => {
        if (
            JSON.stringify(prevProps.projects)?.localeCompare(JSON.stringify(nextProps.projects)) != 0 ||
            JSON.stringify(prevProps.currentSprint._id)?.localeCompare(JSON.stringify(nextProps.currentSprint._id)) != 0 ||
            JSON.stringify(prevProps.currentUserIds)?.localeCompare(JSON.stringify(nextProps.currentUserIds)) != 0 ||
            JSON.stringify(prevProps.userTaskScore)?.localeCompare(JSON.stringify(nextProps.userTaskScore)) != 0
        ) {
            // console.log("re-render");
            return false;
        }
        return true;
    }
);

export type ISelectFilter = "Reporter" | "Tester" | "Bug" | "Inform" | "Leader" | "Follower" | "Assignee" | "TestToday" | "";

const SelectFilter: FC<{
    value: ISelectFilter;
    onChange: (value: ISelectFilter) => void;
    setShowAllTasks: () => void;
    showAllTasks: boolean;
}> = ({ value, onChange, setShowAllTasks, showAllTasks }) => {
    const [openFilter, setOpenFilter] = useState(false);
    const handleToggleOff = () => {
        setOpenFilter(false);
    };
    const ref = useRef<HTMLDivElement | null>(null);
    const items: ISelectFilter[] = ["Bug", "Reporter", "Tester", "Inform", "Leader", "Follower", "Assignee", "TestToday"];
    return (
        <div className={"folter-btn-wrapper radius-12 " + (showAllTasks ? "active" : "")} title="Chọn bộ lọc">
            <div className="radius-12 background-btn-filter">
                <div
                    ref={ref}
                    className={
                        "select-filter radius-12 align-item-center justify-content-center " +
                        (!!value || openFilter ? "active" : "")
                    }
                    data-tip
                    data-for="clearAllFiltersTip_y"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <img className="icon" src={filter_icon} style={{ width: "18px", height: "18px" }} />
                    {!!value && (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange("");
                            }}
                            style={{
                                position: "absolute",
                                bottom: "-6px",
                                right: "-6px",
                            }}
                        >
                            <NotInterestedIcon htmlColor="#eee" />
                        </div>
                    )}
                </div>
            </div>

            <div
                style={{
                    fontSize: "16px",
                    textAlign: "center",
                }}
                onClick={(e) => {
                    setShowAllTasks();
                }}
                className={"show-all-tasks " + (showAllTasks ? "active color-5" : "white")}
            >
                All Tasks
            </div>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={ref?.current}
                open={openFilter}
                onClose={handleToggleOff}
                TransitionComponent={Fade}
            >
                {items.map((item: ISelectFilter) => {
                    return (
                        !!item && (
                            <MenuItem
                                key={item}
                                style={{
                                    display: "block",
                                    padding: "4px 12px",
                                }}
                                onClick={() => onChange(item)}
                            >
                                <Radio name="ISelectFilter" checked={value === item} id={item} style={{ padding: 0 }} />
                                <label style={{ marginLeft: 10 }}>{item}</label>
                            </MenuItem>
                        )
                    );
                })}
            </Menu>
        </div>
    );
};
export default DashboardV2;
