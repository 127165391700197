import { BACK } from "../../config_data_analytic";
import { IAppInfo } from "../../models/appinfo.model";
import { Certificates, ICertificates } from "../../models/certificates.model";
import { getDateTime } from "../../util";
import { dashboardTypes, IDashBoardAction } from "../actions/dashboard.action";

interface IDashBoardState {
    loading: boolean;
    error: boolean;
    certificates: ICertificates[];
    appInfos: IAppInfo[];
    certificatesSelect: ICertificates[];
    currentCertificates: ICertificates;
    typeInfo: string;
    startTime: number;
    endTime: number;
    ranking: any[];
    search: string;
}

const initState: IDashBoardState = {
    loading: false,
    error: false,
    certificates: [],
    appInfos: [],
    certificatesSelect: [],
    currentCertificates: new Certificates(),
    typeInfo: BACK,
    startTime: getDateTime() - 30 * 24 * 60 * 60 * 1000,
    endTime: getDateTime() + 1 * 24 * 60 * 60 * 1000 - 1000,
    ranking: [],
    search: "",
};

const dashBoardState = (state = initState, action: IDashBoardAction) => {
    switch (action.type) {
        case dashboardTypes.GET_INFO_CERTIFICATE_ALL_APP_SUCCESS:
            return {
                ...state,
                certificates: action.certificates,
                loading: false,
            };
        case dashboardTypes.GET_INFO_APPS_SUCCESS:
            return {
                ...state,
                appInfos: action.appInfos,
                loading: false,
            };
        case dashboardTypes.SET_CURRENT_CERTIFICATES:
            if (action.currentCertificates?.id) {
                let index = state.certificatesSelect.findIndex(
                    (e) => e.id == action.currentCertificates?.id
                );
                // if (index == -1) {
                //   state.certificatesSelect.push(action.currentCertificates);
                // }
            }
            state.endTime = getDateTime() + 1 * 24 * 60 * 60 * 1000 - 1000; //set t/g về cuối ngày
            // if (!!!action.typeInfo) {
            // } else {
            //     state.endTime = getDateTime();
            // }
            return {
                ...state,
                currentCertificates: action.currentCertificates,
                typeInfo: action.typeInfo,
                // certificatesSelect: [action.currentCertificates],
            };
        case dashboardTypes.SET_CERTIFICATE_COMPARE:
            let arrCertificates = state.certificatesSelect;
            if (action.certificate?.id) {
                let index = arrCertificates.findIndex(
                    (e) => e.id == action.certificate?.id
                );

                if (index == -1) {
                    arrCertificates = [...arrCertificates, action.certificate];
                } else {
                    arrCertificates = arrCertificates.filter(
                        (e) => e.id != action.certificate?.id
                    );
                }
            }

            return {
                ...state,
                certificatesSelect: arrCertificates,
            };
        case dashboardTypes.UPDATE_DATE_TIME:
            console.log(action);

            return {
                ...state,
                startTime: action.startTime,
                endTime: action.endTime,
            };

        case dashboardTypes.GET_RANKING_SUCCESS:
            return {
                ...state,
                ranking: action.data,
            };
        case dashboardTypes.UNSET_COMPARE:
            return {
                ...state,
                certificatesSelect: [],
                typeInfo: BACK,
            };
        case dashboardTypes.REMOVE_CERTIFICATE:
            return {
                ...state,
                certificatesSelect: state.certificatesSelect.filter(
                    (item: ICertificates) =>
                        item.appID != action.certificate?.appID
                ),
            };
        case dashboardTypes.SEARCH_APP:
            return {
                ...state,
                search: action.appName,
            };
        default:
            return state;
    }
};

export default dashBoardState;
