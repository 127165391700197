import { IBasic } from "./basic";
import { IWfhDayOffDay } from "./wfhDayoffDayRegister";
export interface IOffDaysWfhDays extends IBasic {
    fullName: string;
    numberOffDay: number;
    numberWfhDay: number;
    offDays: IWfhDayOffDay[];
    userId: string;
    wfhDays: IWfhDayOffDay[];
}
export class OffDaysWfhDays implements IOffDaysWfhDays {
    _id: any;
    fullName: string;
    numberOffDay: number;
    numberWfhDay: number;
    offDays: IWfhDayOffDay[];
    userId: string;
    wfhDays: IWfhDayOffDay[];
    constructor(props: any = {}) {
        this._id = props.id;
        this.fullName = props.fullName ?? "";
        this.numberOffDay = props.numberOffDay ?? 0;
        this.numberWfhDay = props.numberWfhDay ?? 0;
        this.offDays = props.offDays ?? [];
        this.userId = props.userId ?? "";
        this.wfhDays = props.wfhDays ?? [];
    }
}
