import { ISprint } from "../../shared/models/sprint";

export enum SprintTypes {
    GET_ALL_SPRINT = "GET_ALL_SPRINT",
    GET_ALL_SPRINT_SUCCESS = "GET_ALL_SPRINT_SUCCESS",
    GET_ALL_SPRINT_FAILED = "GET_ALL_SPRINT_FAILED",
    GET_ALL_SPRINT_PENDING = "GET_ALL_SPRINT_PENDING",
}

export interface ISprintAction {
    type: SprintTypes;
    sprints?: ISprint[];
    error?: any;
    loading?: boolean;
}

export const getAllSprint = (): ISprintAction => {
    return {
        type: SprintTypes.GET_ALL_SPRINT,
    };
};

export const getAllSprintSuccess = (sprints: ISprint[]): ISprintAction => {
    return {
        type: SprintTypes.GET_ALL_SPRINT_SUCCESS,
        sprints,
    };
};

export const getAllSprintFailed = (error: any): ISprintAction => {
    return {
        type: SprintTypes.GET_ALL_SPRINT_FAILED,
        error,
    };
};

export const getAllSprintPending = (loading: boolean): ISprintAction => {
    return {
        type: SprintTypes.GET_ALL_SPRINT_PENDING,
        loading: loading,
    };
};
