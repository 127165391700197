import { GitHubRepository } from "../../shared/models/gitHubRepository"

export enum GitHubTypes {
    GET_REPOSITORIES = "GET_REPOSITORIES",
    GET_REPOSITORIES_SUCCESS = "GET_REPOSITORIES_SUCCESS",
    GET_REPOSITORIES_FAILED = "GET_REPOSITORIES_FAILED",
}

export interface IGitHubAction {
    type: GitHubTypes
    repositories?: GitHubRepository[]
    organizationId?: string
    error?: any
}

export const getGitHubRepositories = (organizationId: string): IGitHubAction => {
    return {
        type: GitHubTypes.GET_REPOSITORIES,
        organizationId
    }
}

export const getGitHubRepositoriesSuccess = (repositories: GitHubRepository[]): IGitHubAction => {
    return {
        type: GitHubTypes.GET_REPOSITORIES_SUCCESS,
        repositories,
    }
}

export const getGitHubRepositoriesFailed = (error: any): IGitHubAction => {
    return {
        type: GitHubTypes.GET_REPOSITORIES_FAILED,
        error,
    }
}