import { Button } from "@material-ui/core";
import { Container } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppInfos } from "../../redux/actions/app.action";
import { IAppState } from "../../redux/reducers/app.reducer";
import { IAppInfo, getAppType } from "../../shared/models/appInfo";
import LoadingWidget from "../LoadingWidget";
import "./index.scss";

import { useLocation } from "react-router";
import AppItemWidget, { AppItemTitleWidget } from "./app-info-item";
import AppThemeManagementWidget from "./app-theme";
import AppVersionManagementWidget from "./app-version-management";
import CreateAppInfoModal from "./create-app-info";
import RemoveAppModal from "./remove-app-info";

const AppManagement = () => {
    useEffect(() => {
        window.location.href = "https://cms-v2-dot-micro-enigma-235001.appspot.com/app-config-management";
    }, []);
    return <LoadingWidget />
    // return (
    //     <Container>
    //         <AppsContent />
    //     </Container>
    // );
};

const AppsContent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const type = urlSearchParams.get("type");
    useEffect(() => {
        dispatch(getAppInfos(getAppType(type)));
    }, [type]);
    const appState: IAppState = useSelector((state: any) => state.appState);
    const appInfos = appState.appInfos;
    if (appState.loading || !appInfos) {
        return <LoadingWidget />;
    }
    return <AppsWidget appInfos={appInfos} />;
};

const AppsWidget: FC<{ appInfos: IAppInfo[] }> = ({ appInfos }) => {
    const [deleteApp, setDeleteApp] = useState<IAppInfo | null>(null);
    const [editAppVersion, setEditAppVersion] = useState<IAppInfo | null>(null);
    const [editAppTheme, setEditAppTheme] = useState<IAppInfo | null>(null);
    const [openCreateAppModal, setOpenCreateAppModal] = useState(false);
    const onHide = () => {
        setOpenCreateAppModal(false);
    };
    const onAddAppInfo = () => {
        setOpenCreateAppModal(true);
    };
    return (
        <div>
            <AppItemTitleWidget />
            {appInfos
                .sort((a, b) => (b.lastUpdate?.getTime() ?? 0) - (a.lastUpdate?.getTime() ?? 0))
                .map((appInfo) => {
                    return (
                        <AppItemWidget
                            key={"app-" + appInfo._id}
                            appInfo={appInfo}
                            onDeleteApp={() => {
                                setDeleteApp(appInfo);
                            }}
                            onShowAppTheme={() => {
                                setEditAppTheme(appInfo);
                            }}
                            onShowAppVersion={() => {
                                setEditAppVersion(appInfo);
                            }}
                        />
                    );
                })}
            {deleteApp && <RemoveAppModal appInfo={deleteApp} onHide={() => setDeleteApp(null)} />}
            {editAppTheme && <AppThemeManagementWidget appInfo={editAppTheme} onHide={() => setEditAppTheme(null)} />}
            {editAppVersion && <AppVersionManagementWidget appInfo={editAppVersion} onHide={() => setEditAppVersion(null)} />}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                }}
            >
                <Button color="primary" variant="contained" onClick={onAddAppInfo}>
                    Add App
                </Button>
            </div>
            <CreateAppInfoModal open={openCreateAppModal} onHide={onHide} />
        </div>
    );
};

export default AppManagement;
