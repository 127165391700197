import { IUserInfo } from "../../shared/models/userInfo";

export enum AuthTypes {
    LOGIN = "LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILED = "LOGIN_FAILED",
    LOGIN_FROM_SESSION = "LOGIN_FROM_SESSION",
    LOGOUT = "LOGOUT",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGIN_BY_TOKEN = "LOGIN_BY_TOKEN",
}

export interface IAuthAction {
    type: AuthTypes
    user?: IUserInfo
    error?: any
    email?: string
    password?: string
    token?: string
    nextUrl?: string | null
}

export const login = (email: string, password: string): IAuthAction => {
    return {
        type: AuthTypes.LOGIN,
        email,
        password
    };
}

export const loginSuccess = (user: IUserInfo, nextUrl?: string | null): IAuthAction => {
    return {
        type: AuthTypes.LOGIN_SUCCESS,
        user,
        nextUrl
    };
}

export const loginFailed = (error: any): IAuthAction => {
    return {
        type: AuthTypes.LOGIN_FAILED,
        error
    };
}

export const loginFromSession = (): IAuthAction => {
    return {
        type: AuthTypes.LOGIN_FROM_SESSION,
    };
}

export const logout = (): IAuthAction => {
    return {
        type: AuthTypes.LOGOUT,
    };
}

export const logoutSuccess = (): IAuthAction => {
    return {
        type: AuthTypes.LOGOUT_SUCCESS,
    };
}

export const loginByToken = (token: string, nextUrl?: string | null): IAuthAction => {
    return {
        type: AuthTypes.LOGIN_BY_TOKEN,
        token,
        nextUrl
    };
}
