import { AlertColor } from "@mui/material";

export enum ToolAnalysisTypes {
    GET_PURCHASE_INFORMATION_BY_EMAIL = "GET_PURCHASE_INFORMATION_BY_EMAIL",
    GET_PURCHASE_INFORMATION_BY_EMAIL_SUCCESS = "GET_PURCHASE_INFORMATION_BY_EMAIL_SUCCESS",
    SET_DATA_PROCESSING = "SET_DATA_PROCESSING",
    GET_PURCHASE_INFORMATION_BY_TRANSACTION = "GET_PURCHASE_INFORMATION_BY_TRANSACTION",
    GET_PURCHASE_INFORMATION_BY_TRANSACTION_SUCCESS = "GET_PURCHASE_INFORMATION_BY_TRANSACTION_SUCCESS",
    UPDATE_PURCHASE_ACTION = "UPDATE_PURCHASE_ACTION",
    UPDATE_PURCHASE_SUCCESS_ACTION = "UPDATE_PURCHASE_SUCCESS_ACTION",
    SET_MESSAGE = "SET_MESSAGE",
    REMOVE_MESSAGE = "REMOVE_MESSAGE",
}

export interface IToolAnalysisAction {
    type: ToolAnalysisTypes;
    email?: string;
    getDataProcessing?: boolean;
    purchases?: any;
    inputTransaction?: {
        transactionId: string;
        start_date: string;
        end_date: string;
    };
    purchase?: any;
    message?: string;
    severity?: AlertColor;
}

export const getPurchaseInfomationByEmail = (
    email: string
): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_EMAIL,
        email,
    };
};
export const getPurchaseInfomationByTransactionId = (inputTransaction: {
    transactionId: string;
    start_date: any;
    end_date: any;
}): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_TRANSACTION,
        inputTransaction,
    };
};
export const setDataProcessingAction = (
    getDataProcessing: boolean
): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.SET_DATA_PROCESSING,
        getDataProcessing,
    };
};

export const getPurchaseInfomationByEmailSuccess = (
    purchases: any
): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_EMAIL_SUCCESS,
        purchases,
    };
};

export const getPurchaseInfomationByTransactionSuccess = (
    purchases: any
): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_TRANSACTION_SUCCESS,
        purchases,
    };
};

export const updatePurchaseAction = (purchase: any): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.UPDATE_PURCHASE_ACTION,
        purchase,
    };
};

export const updatePurchaseSuccessAction = (
    purchase: any
): IToolAnalysisAction => {
    return {
        type: ToolAnalysisTypes.UPDATE_PURCHASE_SUCCESS_ACTION,
        purchase,
    };
};

export const setMesage = (message: string, severity: AlertColor) => {
    return {
        type: ToolAnalysisTypes.SET_MESSAGE,
        message,
        severity,
    };
};
export const removeMesage = () => {
    return {
        type: ToolAnalysisTypes.REMOVE_MESSAGE,
    };
};
