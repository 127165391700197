import { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.css";
import { Form, Input, Select, Modal, Button } from "antd";
import { addProblem, updateProblem } from "../../redux/actions/problemReporting.action";
import { IProblemReporting, ProblemReporting } from "../../shared/models/problemReporting";
import moment from "moment";
import { IAppInfo } from "../../shared/models/appInfo";
import SelectNew from "react-select";
import Config from "../../shared/models/config";
import { ISprint, Sprint } from "../../shared/models/sprint";
import { IUserInfo } from "../../shared/models/userInfo";
import { SelectOption } from "../../utils";
import defaultAvatar from "../../assets/images/default-avatar.png";
import { ITask, Task } from "../../shared/models/task";
import { getTaskById, updateTask } from "../../redux/actions/task.action";
import problemReportingState from "../../redux/reducers/problemReporting.reducer";
import { updateTaskAPI } from "../../redux/sagas/task.saga";
import { getTaskAPI } from "../../redux/sagas/task.saga";
import axios from "axios";
import Url from "../../util/url";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const EditProblemReportingModal: FC<{
    problem: ProblemReporting;
    onHide: () => void;
    listApp: IAppInfo[];
    sprintList: ISprint[];
    userList: IUserInfo[];
}> = ({ problem, onHide, listApp, sprintList, userList }) => {
    const newProblem = !problem._id;
    const [formData, setFormData] = useState({
        ...problem,
        reportingDate: problem.reportingDate ? problem.reportingDate : moment().format("DD/MM/YYYY"),
    });
    const defType = Config.PROBLEM_TYPE_OPTION.find((t) => t.label.includes(problem.type))?.value;
    const [problemType, setProblemType] = useState<string>(defType ? defType : "");
    const [problemFile, setProblemFile] = useState<FileList | null>();
    const [problemUrl, setProblemUrl] = useState<string[]>(problem.attachment);
    useEffect(() => {}, [problemUrl]);

    const handleInputChange = (name: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    let app = listApp.find((a) => a.shortName == problem.appWebName);

    const dispatch = useDispatch();
    const taskList: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of userList) {
        mapUser.set(user._id, user);
    }
    const getListUser = (ids: string[]) => {
        const list: IUserInfo[] = [];
        for (let id of ids) {
            const user = mapUser.get(id);
            if (user) {
                list.push(user);
            }
        }
        return list;
    };
    const mapSprint: Map<string, ISprint> = new Map();
    for (let sprint of sprintList) {
        mapSprint.set(sprint._id, sprint);
    }
    let urls: string[];
    const handleInputFile = async (files: FileList | null) => {
        if (files && files.length > 0 && app) {
            const fileData = new FormData();
            fileData.append("key", "upload");
            fileData.append("bucket", "hero-reporting");
            for (let i = 0; i < files.length; i++) {
                fileData.append(files[i].name, files[i]);
            }
            const result = await axios
                .post(Url("api/upload/files"), fileData)
                .then((response) => {
                    console.log("upload file 1", response);
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
            urls.push(result);
            console.log("urls", urls);
        }
    };

    const handleOk = async () => {
        if (!formData._id) {
            const problemReportingInstance = new ProblemReporting(formData);
            problemReportingInstance.reportingDate = moment().format("DD-MM-YYYY");
            problemReportingInstance.lastUpdateDate = moment().format("DD-MM-YYYY");
            const currentSprint = sprintList?.find((sprint) => sprint.activeSprint === true);
            problemReportingInstance.sprint = currentSprint?.shortId ? currentSprint?.shortId : "xx";
            if (!formData.assigneeId) {
                    if (formData.type === "dev" || formData.type === "other") {
                        problemReportingInstance.assigneeId = "6157c7cce40d4cf6e89ff7de"
                    } else if (formData.type === "content") {
                        problemReportingInstance.assigneeId = "6157d201e40d4cf6e89ffba5"
                    } else {
                        problemReportingInstance.assigneeId = "6157c891e40d4cf6e89ff82a"
                    }
            }

            if(!formData.state) {
                problemReportingInstance.state = "in_progress";
            }
            
            if (problemFile) {
                await handleInputFile(problemFile);
            }
            if (urls && problemUrl) {
                problemReportingInstance.attachment = [...problemUrl, ...urls];
            } else if (problemUrl) {
                problemReportingInstance.attachment = [...problemUrl];
            }
            else if (urls) {
                problemReportingInstance.attachment = [...urls];
            }

            if (!formData.title || !formData.appWebName) {
                return window.alert("Vui lòng không để trống các trường Title, App/Web Name, Loại và Trạng thái!");
            } else dispatch(addProblem(problemReportingInstance));
        } else {
            const problemReportingInstance = new ProblemReporting(formData);
            problemReportingInstance.lastUpdateDate = moment().format("DD-MM-YYYY");
            if (problemFile) {
                await handleInputFile(problemFile);
            }
            if (urls && problemUrl) {
                problemReportingInstance.attachment = [...problemUrl, ...urls];
            } else if (problemUrl) {
                problemReportingInstance.attachment = [...problemUrl];
            }
            else if (urls) {
                problemReportingInstance.attachment = [...urls];
            }
            if (!formData.title || !formData.state) {
                return window.alert("Vui lòng không để trống trường Title và Trạng thái!");
            } else dispatch(updateProblem(problemReportingInstance));
        }
        onHide();
    };

    const handleCreateTask = () => {
        const _sprintId = sprintList.find((s) => s.shortId === problem.sprint)?._id;

        const newTask: ITask = new Task({
            assigneeId: problem.assigneeId,
            assignee: mapUser.get(problem.assigneeId),
            deadline: "",
            description: problem.problems,
            priority: problem.priority,
            leadDevId: "",
            leadDev: mapUser.get(""),
            point: parseInt(""),
            reporterId: problem.assigneeId,
            reporter: mapUser.get(problem.assigneeId),
            testerIds: "",
            testers: getListUser([""]),
            sprintId: _sprintId,
            sprint: mapSprint.get(_sprintId ? _sprintId : ""),
            type: "Task",
            title: problem.title,
            createdTime: new Date(),
            statusView: 1,
            coef: parseFloat("1"),
        });

        updateTaskAPI(newTask)
            .then((task) => {
                if (task) {
                    problem.problem_taskId = task._id;
                    dispatch(updateProblem(problem));
                    taskList.push(task);
                }
            })
            .catch((e) => {
                console.log("getTaskAPI err:", e);
            });
    };
    const { TextArea } = Input;

    const handleDeleteUrl = (url: string) => {
        setProblemUrl((prevData) => prevData.filter((data) => encodeURIComponent(data) !== encodeURIComponent(url)));
    };

    return (
        <Modal
            title={newProblem ? "Tạo mới" : "Cập nhật vấn đề"}
            okText="Lưu"
            cancelText="Huỷ"
            open={true}
            onOk={handleOk}
            onCancel={onHide}
            centered
        >
            <div className="edit-problem-modal-scroll">
                <Form
                    // ref={ref}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                >
                    <MyFormItem label="Title">
                        <Input
                            defaultValue={problem?.title ?? undefined}
                            onChange={(event) => handleInputChange("title", event.target.value)}
                            name="title"
                        />
                    </MyFormItem>
                    <MyFormItem label="App/Web Name">
                        <SelectApp
                            value={problem.appWebName}
                            listApp={listApp}
                            onChange={(value) => {
                                handleInputChange("appWebName", value);
                            }}
                        />
                    </MyFormItem>
                    <MyFormItem label="Loại">
                        <SelectType
                            value={problem.type}
                            onChange={(value) => {
                                handleInputChange("type", value);
                                setProblemType(value);
                            }}
                        ></SelectType>
                    </MyFormItem>
                    <MyFormItem label="Nội dung">
                        <TextArea
                            rows={4}
                            defaultValue={problem?.problems ?? undefined}
                            onChange={(event) => handleInputChange("problems", event.target.value)}
                            name="problems"
                        />
                    </MyFormItem>
                    {(problemType === "dev" || problemType === "other") && (
                        <>
                            <MyFormItem label="Assignee">
                                <SelectAssignee
                                    value={problem.assigneeId ? problem.assigneeId : "6157c7cce40d4cf6e89ff7de"}
                                    type="assignee"
                                    onChange={(value) => {
                                        handleInputChange("assigneeId", value);
                                    }}
                                    userList={userList}
                                />
                            </MyFormItem>
                        </>
                    )}
                    {(problemType === "content") && (
                        <>
                            <MyFormItem label="Assignee">
                                <SelectAssignee
                                    value={problem.assigneeId ? problem.assigneeId : "6157d201e40d4cf6e89ffba5"}
                                    type="assignee"
                                    onChange={(value) => {
                                        handleInputChange("assigneeId", value);
                                    }}
                                    userList={userList}
                                />
                            </MyFormItem>
                        </>
                    )}
                    {(problemType === "design") && (
                        <>
                            <MyFormItem label="Assignee">
                                <SelectAssignee
                                    value={problem.assigneeId ? problem.assigneeId : "6157c891e40d4cf6e89ff82a"}
                                    type="assignee"
                                    onChange={(value) => {
                                        handleInputChange("assigneeId", value);
                                    }}
                                    userList={userList}
                                />
                            </MyFormItem>
                        </>
                    )}
                    <MyFormItem label="Priority">
                        <SelectPriority
                            value={problem.priority ? problem.priority : "Medium"}
                            onChange={(value) => {
                                handleInputChange("priority", value);
                            }}
                        />
                    </MyFormItem>
                    <MyFormItem label="Đính kèm">
                        <input multiple type="file" onChange={(e) => setProblemFile(e.target.files)} name="attachment" />
                    </MyFormItem>
                    {problemUrl && (
                        <MyFormItem label="">
                            {problemUrl.map((url) => (
                                <div>
                                    <a key={url} href={url} target="_blank">
                                        <img src={url} style={{ width: "141px" }} />
                                    </a>
                                    <HighlightOffIcon
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeleteUrl(url);
                                        }}
                                        style={{
                                            width: "24px",
                                            position: "absolute",
                                            transform: "translateX(-10px)",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            ))}
                        </MyFormItem>
                    )}
                    <MyFormItem label="Trạng thái">
                        <SelectStatus
                            value={problem.state ? problem.state : "in_progress"}
                            onChange={(value) => {
                                handleInputChange("state", value);
                            }}
                        />
                    </MyFormItem>
                    {!problem._id && (
                        <MyFormItem label="Task tương ứng">
                            <Button type="primary" disabled>
                                <p>Thêm task</p>
                            </Button>
                        </MyFormItem>
                    )}
                    {!!problem._id && !problem.problem_taskId && (
                        <MyFormItem label="Task tương ứng">
                            <Button type="primary" onClick={() => handleCreateTask()}>
                                <p>Thêm task</p>
                            </Button>
                        </MyFormItem>
                    )}
                    {!!problem._id && problem.problem_taskId && (
                        <MyFormItem label="Task tương ứng">
                            <a
                                // className="problem-task-shortId"
                                // style={{ marginBottom: '0px' }}
                                href={"/task/" + problem.problem_taskId}
                                target="_blank"
                            >
                                <p style={{ marginBottom: "0px" }}>Xem task</p>
                            </a>
                        </MyFormItem>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

const MyFormItem: FC<{ label: string; children: ReactNode }> = ({ label, children }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "120px", textAlign: "right" }}>{label}</div>
            <div style={{ width: "calc(90% - 120px)", padding: "4px 11px", marginBottom: "5px", marginTop: "5px" }}>
                {children}
            </div>
        </div>
    );
};

const SelectApp: FC<{ listApp: IAppInfo[]; value: string; onChange: (value: string) => void }> = ({
    listApp,
    value,
    onChange,
}) => {
    const options = listApp.map((item) => {
        return {
            label: item.shortName,
            value: item.key,
        };
    });
    const defaultValue = options.find((option) => option.value == value);
    return (
        <Select
            style={{ width: "100%" }}
            defaultValue={value}
            options={options}
            onChange={(value) => {
                value && onChange(value);
            }}
            className="appWebName"
        ></Select>
    );
};

const SelectType: FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const options = Config.PROBLEM_TYPE_OPTION;
    const defaultType = options.find((option) => option.value == value);
    return (
        <Select
            style={{ width: "100%" }}
            defaultValue={value}
            options={options}
            onChange={(value) => value && onChange(value)}
            className="type"
        ></Select>
    );
};

const SelectStatus: FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const options = Config.PROBLEM_STATE_OPTION;
    return (
        <Select
            style={{ width: "100%" }}
            defaultValue={value}
            options={options}
            onChange={(value) => onChange(value)}
            className="state"
        ></Select>
    );
};

const SelectPriority: FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const priorityOptions = Object.values(Config.PRIORITY_POOL).map((p, index) => ({
        value: index.toString(),
        label: p,
    }));
    return (
        <Select
            style={{ width: "100%" }}
            defaultValue={value}
            options={priorityOptions}
            onChange={(value) => {
                onChange(value);
            }}
            className="state"
        ></Select>
    );
};

const SelectAssignee: FC<{ value: string; type: string; userList: IUserInfo[]; onChange: (value: string) => void }> = ({
    value,
    type,
    userList,
    onChange,
}) => {
    const [assigneeIdChanged, setAssigneeIdChanged] = useState(value);
    const noUserLabel = "No User";
    let userOptions: SelectOption[] = userList.map((u) => {
        return {
            avatar: u.avatar ?? "",
            label: u.firstName + " " + u.lastName,
            value: u._id,
        };
    });
    userOptions = [
        {
            avatar: defaultAvatar,
            label: noUserLabel,
            value: "",
        },
        ...userOptions,
    ];

    const getLabel: FC<SelectOption> = ({ avatar, label }) => {
        let name: string | string[] = label?.split(" ") ?? "";
        const firstName = name.shift() ?? "";
        firstName && name.push(firstName);
        name = name.join(" ");
        return (
            <div style={{ alignItems: "center", display: "flex" }}>
                <span style={{ marginRight: "0.5em" }}>
                    <img
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "20px",
                            objectFit: "cover",
                        }}
                        src={avatar}
                    />
                </span>
                <span style={{ fontSize: 14 }}>{label === "No User" ? "No User" : name}</span>
            </div>
        );
    };
    return (
        <SelectNew
            formatOptionLabel={getLabel}
            options={userOptions}
            onChange={(value) => {
                const _id = value?.value;
                if (_id) {
                    onChange(_id);
                    setAssigneeIdChanged(value.value);
                }
            }}
            value={userOptions.find((user) => user.value === assigneeIdChanged)}
        />
    );
};
