import { callApi } from ".";
import APIConfig from "../util/APIConfig";

export const createBlogApi = (data) => {
    return callApi({
        method: "POST",
        url: APIConfig.CREATE_BLOG,
        params: data,
    });
};
export const getBlogByIdApi = (data) => {
    return callApi({
        method: "POST",
        url: APIConfig.GET_BLOG_BY_ID,
        params: data,
    });
};
export const updateStatusByIdApi = (data) => {
    return callApi({
        method: "POST",
        url: APIConfig.UPDATE_STATUS,
        params: data,
    });
};

export const getCategoriesApi = (url) => {
    return callApi({
        method: "get",
        baseURl: url,
    });
};
