import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { format, closestIndexTo, compareAsc } from 'date-fns'
import CircularIndeterminate from '../../common/icon/Loading'
import './index.scss'

interface IIndexed {
    _id: string,
    totalIndexed: number
}

interface ITotalCreateCollection {
  collectionK5Number: number,
  collectionCDLNumber: number
}

interface IIndexedCollections {
  indexedK5Collection: IIndexed[],
  indexedOtherCollections: IIndexed[],
}

interface IData {
  _id: string,
  indexedK5Collection: number,
  indexedOtherCollections: number,
}

const DATE_FORMAT = 'Y-M-d'

const IndexProgress = () => {
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IData[]>([])
    const [totalCreateCollections, setTotalCreateCollections] = useState<ITotalCreateCollection>({
      collectionK5Number: 0,
      collectionCDLNumber: 0
    })

    useEffect(() => {
      fetchData();
    }, [ startTime, endTime])
    
    const fetchData = async () => {
      setLoading(true);
      try {
        const startDate = (new Date(startTime)).toLocaleString()
        const endDate = (new Date((new Date(endTime)).setHours(23,59,59,59))).toLocaleString()
        let totalIndexedAPI: IIndexedCollections = {
          indexedK5Collection: [],
          indexedOtherCollections: []
        };
        
        const { data } = await axios.post(
          'https://api.worksheetzone.org/api/get-indexed-by-source',
          {
            startDate,
            endDate,
          }
        )

        const { data: totalCreateCollections } = await axios.post(
          'https://api.worksheetzone.org/api/get-create-collection-number'
        )

        setTotalCreateCollections(totalCreateCollections)
        
        totalIndexedAPI.indexedK5Collection = data.indexedK5Collection.map((collection: IIndexed) =>(
          {
            ...collection,
            _id: format(new Date(collection._id), DATE_FORMAT)
          }
        ))      
        totalIndexedAPI.indexedOtherCollections = data.indexedOtherCollections.map((collection: IIndexed) =>(
          {
            ...collection,
            _id: format(new Date(collection._id), DATE_FORMAT)
          }
        ))      
        
        totalIndexedAPI.indexedK5Collection = getRangeDates(startTime, endTime, totalIndexedAPI.indexedK5Collection);
        totalIndexedAPI.indexedOtherCollections = getRangeDates(startTime, endTime, totalIndexedAPI.indexedOtherCollections);
        
        totalIndexedAPI.indexedK5Collection = getDatesLost(totalIndexedAPI.indexedK5Collection)
        totalIndexedAPI.indexedOtherCollections = getDatesLost(totalIndexedAPI.indexedOtherCollections)

        const finalData: IData[] = totalIndexedAPI.indexedK5Collection.map((item, index) => ({
          ...item,
          indexedK5Collection: item.totalIndexed,
          indexedOtherCollections: totalIndexedAPI.indexedOtherCollections[index].totalIndexed
        }))

        setData(finalData);
      } catch (error) {
        // console.log(error);
      }
      setLoading(false);
    }
  
    const getRangeDates = (startTime: string, endTime: string, datesData: IIndexed[]) => {
      if(datesData.length === 0) {
        return [
          {
            _id: format(new Date(startTime), DATE_FORMAT),
            totalIndexed: 0
          },  
          {
            _id: format(new Date(endTime), DATE_FORMAT),
            totalIndexed: 0
          } 
        ]
        
      }
      const dates = datesData.map(dateData => new Date(dateData._id));
      const headIndex = closestIndexTo(new Date(startTime), dates)
      const tailIndex = closestIndexTo(new Date(endTime), dates)
      let datesAfter: IIndexed[] = [];   
  
      if(headIndex !== undefined && tailIndex !== undefined){
        datesAfter = datesData.splice(headIndex, tailIndex - headIndex + 1);
        const headStatus = compareAsc(new Date(startTime), new Date(datesAfter[0]._id));
        const tailStatus = compareAsc(new Date(endTime), new Date(datesAfter[datesAfter.length - 1]._id));

        if(headStatus === -1) {
            datesAfter.unshift({
                _id: format(new Date(startTime), DATE_FORMAT),
                totalIndexed: 0
            })
        } else if (headStatus === 1){
            datesAfter[0] = {
                _id: format(new Date(startTime), DATE_FORMAT),
                totalIndexed: 0
            }
        }
        if(tailStatus === 1) {
            datesAfter.push({
                _id: format(new Date(endTime), DATE_FORMAT),
                totalIndexed: 0
            })
        } else if (tailStatus === -1){
            datesAfter[0] = {
                _id: format(new Date(endTime), DATE_FORMAT),
                totalIndexed: 0
            }
        }
      }
      
      return datesAfter;
    }
  
    const getDatesInRange = (startDate: Date, endDate: Date) => {
      const date = new Date(startDate.getTime());
    
      const dates = [];
    
      while (date <= endDate) {
        dates.push(format(new Date(date), DATE_FORMAT));
        date.setDate(date.getDate() + 1);
      }
    
      return dates;
    }
  
    const getDatesLost = (data: IIndexed[]) => {
      if(data.length === 1) return data;
  
      const arrDates: string[] = getDatesInRange(new Date(data[0]._id), new Date(data[data.length - 1]._id))
  
      const arrDatesWithIndex: IIndexed[] = arrDates.map((date: string) => {
        const result = data.find((d: IIndexed) => d._id === date);
  
        if(!result){
          return {
              _id: date,
              totalIndexed: 0
          }
            
        }
  
        return result
      })

      let max = 0;

      return arrDatesWithIndex.map((date, index) => {
        if(date.totalIndexed > max){
          max = date.totalIndexed;
        }

        if(date.totalIndexed < max){
          return {
            _id: format(new Date(date._id), 'LLL dd'),
            totalIndexed: max
          }
        }

        return {
          ...date,
          _id: format(new Date(date._id), 'LLL dd')
        };
      })
    }

    if(loading){
        return (
          <CircularIndeterminate />
        )
    }

    return (
    <div className="index_progress_chart">
        <div className="total_create">
          <h6>Total collections K5 created: <span style={{ color: "#3878ed"}}>{totalCreateCollections.collectionK5Number}</span></h6>
          <h6>Total collections Other created: <span style={{ color: "#990099"}}>{totalCreateCollections.collectionCDLNumber}</span></h6>
        </div>
        <ResponsiveContainer
            height={550}
            width="100%"
            className="chart"
        >
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <Line type="monotone" dataKey="indexedK5Collection" stroke="#3878ed" yAxisId="left" activeDot={{ r: 6 }} />
                <Line type="monotone" dataKey="indexedOtherCollections" stroke="#990099" yAxisId="left" activeDot={{ r: 6 }} />
                <XAxis dataKey="_id" padding={{ right: 30 }} />
                <YAxis yAxisId="left" />
                <Legend formatter={(value: string, entry: any) => {
                  if(value === 'indexedK5Collection') return 'Total indexed K5 collections'
                  if(value === 'indexedOtherCollections') return 'Total indexed Other collections'

                  return value
                }}/>
                <Tooltip
                    content={<CustomBarTooltip />}
                    cursor={{ fill: "rgba(0, 0, 0, 0)" }}
                />
            </LineChart>
        </ResponsiveContainer>
    </div>
    )
}

const CustomBarTooltip = ({ payload, label }: { payload?: any, label?: string}) => {
    return (
        (payload && payload.length) ?
        <div className="custom-tooltip" style={{
            padding: '10px',
            background: '#fff',
            margin: 0
        }}>
            <div className="custom-tooltip-label">{label}</div>
            <div className="custom-tooltip-value" style={{
                    color:'#3878ed'
                }}
            >
                Total index K5 collections: {payload[0].payload.indexedK5Collection}
            </div>
            <div className="custom-tooltip-value" 
                style={{
                    color:'#990099'
                }}
            >
                Total index other collections: {payload[0].payload.indexedOtherCollections}
            </div>
        </div> : null
    )
  }

export default IndexProgress