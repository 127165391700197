import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import Url from "../../../util/url";

async function createCareerEventAPI(event) {
    const res = await axios.post(Url(`career-path/create-event`), event);
    return res?.data?.response?.response[0] ?? null;
}

export function* createCareerEvent(action) {
    const event = action.data;
    if (event) {
        try {
            const newEvent = yield call(createCareerEventAPI, event);
            if (newEvent) {
                yield put({
                    type: "CREATE_CAREER_EVENT_SUCCESS",
                    data: newEvent,
                });
            } else {
                yield put({
                    type: "CREATE_CAREER_EVENT_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

async function updateCareerEventAPI(event) {
    await axios.patch(Url(`career-path/update-event/${event._id}`), event);
    return event ?? null;
}

export function* updateCareerEvent(action) {
    const event = action.data;
    if (event) {
        try {
            const newEvent = yield call(updateCareerEventAPI, event);
            if (newEvent) {
                yield put({
                    type: "UPDATE_CAREER_EVENT_SUCCESS",
                    data: newEvent,
                });
            } else {
                yield put({
                    type: "UPDATE_CAREER_EVENT_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

async function deleteCareerEventAPI(id) {
    await axios.delete(Url(`career-path/delete-event/${id}`));
    return id ?? null;
}

export function* deleteCareerEvent(action) {
    const event = action.data;
    if (event) {
        try {
            const res = yield call(deleteCareerEventAPI, event._id);
            if (res) {
                yield put({
                    type: "DELETE_CAREER_EVENT_SUCCESS",
                    data: res,
                });
            } else {
                yield put({
                    type: "DELETE_CAREER_EVENT_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

export function* watchCreateCareerEvent() {
    yield takeEvery("CREATE_CAREER_EVENT", createCareerEvent);
}

export function* watchUpdateCareerEvent() {
    yield takeEvery("UPDATE_CAREER_EVENT", updateCareerEvent);
}

export function* watchDeleteCareerEvent() {
    yield takeEvery("DELETE_CAREER_EVENT", deleteCareerEvent);
}
