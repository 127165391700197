import { ITask } from "../../shared/models/task";
import { IWebAction, WebTypes } from "../actions/web.action";

export interface IWebState {
    reload?: boolean
    task?: ITask,
}
const initState: IWebState = {
    reload: false
};

const webState = (state = initState, action: IWebAction) => {
    switch (action.type) {
        case WebTypes.RELOAD:
            return {
                ...state,
                ...{
                    reload: true
                }
            };
        case WebTypes.CREATED_NEW_TASK:
            return {
                ...state,
                ...{
                    task: action.task
                }
            };
        default:
            return state;
    }
};

export default webState;
