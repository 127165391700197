import BugReportIcon from "@material-ui/icons/BugReport";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { FC, useState } from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import days_1 from "../../../assets/icon/days_1.svg";
import days_12_19 from "../../../assets/icon/days_from_12_to_19.svg";
import days_3_4 from "../../../assets/icon/days_from_3_to_4.svg";
import days_5_7 from "../../../assets/icon/days_from_5_to_7.svg";
import days_8_11 from "../../../assets/icon/days_from_8_to_11.svg";
import days_2 from "../../../assets/icon/days_more_then_2.svg";
import days_20 from "../../../assets/icon/days_more_then_20.svg";
import bugIcon from "../../../assets/images/BugIcon.svg";
import CloseTaskButton from "../../../assets/images/CloseTaskButton.svg";
import subTaskIcon from "../../../assets/images/SubTask.svg";
import TaskButton from "../../../assets/images/TaskButton.svg";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import Config from "../../../shared/models/config";
import { IProject } from "../../../shared/models/project";
import { IStatus } from "../../../shared/models/status";
import { ITask } from "../../../shared/models/task";
import { getTimeFromStartTask } from "../../../util/index";
import "./style.scss";
import SubTasksPreview from "./sub-tasks-preview";
import TestCycleModalWidget from "./test-cycle-modal";
import { getPercentsFromStatus } from "./test-cycle-modal/all-cycles";
import { hexToRgbA } from "../../../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NewProgressBar from "../expectation-and-notification/NewProgressBar";
import { Checkbox } from "@mui/material";
import MyAvatar from "../../avatar";

const TaskPreview: FC<{
    task: ITask;
    onSelectedTask: (task: ITask) => void;
    showAllTasks?: boolean;
    handleCheckboxChange? : (id : string) => void;
    selectedTaskIds?: string[];
    isShowCheckBox?: boolean;
}> = ({ task, onSelectedTask, showAllTasks ,handleCheckboxChange = () => {}, selectedTaskIds = [],isShowCheckBox}) => {
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks ?? []);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const statuses: IStatus[] = useSelector((state: any) => state.statusState.statuses ?? []);
    const [showSubTasks, setShowSubTasks] = useState(false);
    const [open, setOpen] = useState(false);
    const tests = useSelector((state: any) => state.tests.data);
    const cycles = useSelector((state: any) => state.cycles.data);

    let percent: any = 0;
    let badgeContent = 0;
    let isShowAll = false;
    let countChildTask = 0;
    let countDoneChildTask = 0;
    let progressiveTask: number = 0;
    let avatarChildTask: string[] = [];
    let STATUS_TASK_TO_DO_ID: string = "";
    let STATUS_TASK_IN_PROGRESS_ID: string = "";
    let STATUS_TASK_PROD_VALIDATION_ID: string = "";
    let STATUS_TASK_DONE_ID: string = "";

    const getStatusID = () => {
        for (let status of statuses) {
            switch (status.shortId) {
                case Config.COLUMN_STATUS.TO_DO.id:
                    STATUS_TASK_TO_DO_ID = status._id;
                    break;
                case Config.COLUMN_STATUS.IN_PROGRESS.id:
                    STATUS_TASK_IN_PROGRESS_ID = status._id;
                    break;
                case Config.COLUMN_STATUS.PROD_VALIDATION.id:
                    STATUS_TASK_PROD_VALIDATION_ID = status._id;
                    break;
                case Config.COLUMN_STATUS.DONE.id:
                    STATUS_TASK_DONE_ID = status._id;
                    break;
                default:
                    break;
            }
        }
    };
    getStatusID();
    const getStartDateTask = (task: ITask) => {
        if (task?.histories?.length) {
            let i = task.histories.length - 1;
            while (i >= 0) {
                if (task.histories[i]) {
                    if (
                        task.histories[i].field === "status" &&
                        task.histories[i].from === STATUS_TASK_TO_DO_ID &&
                        task.histories[i].to === STATUS_TASK_IN_PROGRESS_ID
                    ) {
                        return task.histories[i].time;
                    }
                }
                i--;
            }
        }
    };

    const getEndDateTask = (task: ITask) => {
        if (task?.histories?.length) {
            let i = 0;
            while (i < task.histories.length) {
                if (task.histories[i]) {
                    if (
                        task.histories[i].field === "status" &&
                        task.histories[i].from === STATUS_TASK_PROD_VALIDATION_ID &&
                        task.histories[i].to === STATUS_TASK_DONE_ID
                    ) {
                        return task.histories[i].time;
                    }
                }
                i++;
            }
        }
    };

    const startDateTask = getStartDateTask(task);
    const endDateTask = getEndDateTask(task);
    const timeFromStartTask = getTimeFromStartTask(startDateTask, endDateTask);

    if (tests && cycles) {
        let selectedTests = tests.filter((test: any) => test.taskID === task._id);
        let selectedCycles = cycles.filter((cycle: any) => cycle.taskID === task._id);

        if (selectedTests && selectedCycles) {
            let lastCycleCases = selectedCycles.length > 0 ? selectedCycles[selectedCycles.length - 1].cases : [];
            if (selectedCycles.length > 0 && lastCycleCases.length > 0) {
                let minCycleCases = selectedCycles[0].cases.length;
                badgeContent = selectedTests.length - minCycleCases;

                percent = getPercentsFromStatus(lastCycleCases);
                isShowAll = true;
            }
        }
    }
    
    const handleClickOpen = (event: any) => {
        setOpen(true);
        event.stopPropagation();
    };

    const handleClose = (event: any) => {
        setOpen(false);
    };

    if (tasks) {
        let child_task = getChildTasks(task, tasks);
        child_task.forEach((item) => {
            if (item.parentId == task._id) {
                countChildTask++;
                if (item.status?._id == Config.COLUMN_STATUS.DONE.mongoId) countDoneChildTask++;
            }
            let avatar = item.assignee?.avatar;
            if (avatar) {
                if (avatar !== task.assignee?.avatar) {
                    if (!avatarChildTask.includes(avatar)) {
                        avatarChildTask.push(avatar);
                    }
                }
            }
        });
        progressiveTask = countDoneChildTask / countChildTask;
    }

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "TASK",
            item: task,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [task]
    );
    let parentTask = tasks.find((t) => t._id === task.parentId);
    return (
        <div
            className="wrap-task background-white-008"
            ref={drag}
            style={{
                opacity: isDragging ? 0 : 1,
                border: showSubTasks && !showAllTasks ? "1px solid #fff" : "none",
                transition: "0.2s all",
            }}
        >
             {(!task.parent && isShowCheckBox ) && <div className="select-task"><Checkbox  checked={selectedTaskIds.includes(task._id)} onChange={(e) => handleCheckboxChange(task._id)}/></div> }
            <div
                className={"task-box " + (showSubTasks && !showAllTasks ? "background-white-008" : "")}
                onClick={() => {
                    onSelectedTask(task);
                }}
            >
                <div className="task-content">
                    <div className="line">
                        <div className="line-1">
                            <div className="left">
                                {(task.type !== "Sub Task" || showAllTasks) && (
                                    <div className="type">
                                        <img
                                            className="task-icon"
                                            src={"/" + (task.priority ? task.priority : "Lowest") + "_priority.svg"}
                                        />
                                    </div>
                                )}
                                {!!task.point && <div className="point">{task.point}</div>}
                                {task.checkPoint && (
                                    <div
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            marginLeft: "5px",
                                            borderRadius: "50%",
                                            backgroundColor: "rgba(25, 171, 103, 1)",
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex">
                                {task.type !== "Task" && (
                                    <div
                                        className="color-5 background-white-008 align-item-center"
                                        style={{
                                            borderRadius: "20px",
                                            padding: "0 8px",
                                            height: "24px",
                                            marginRight: "4px",
                                        }}
                                    >
                                        {task.type === "Bug" && (
                                            <div className="type">
                                                <img className="bug-icon" src={bugIcon} />
                                            </div>
                                        )}
                                        {task.type === "Sub Task" && (
                                            <div className="type">
                                                <img className="subtask-icon" src={subTaskIcon} />
                                            </div>
                                        )}
                                        {task.type === "Sub Task" && parentTask && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={"parent-" + task.shortId.replaceAll(" ", "")}>
                                                        <div className="parent-info">
                                                            {parentTask.shortId + ": " + parentTask.title}
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <div
                                                    data-tip
                                                    data-for={"parent-" + task.shortId.replaceAll(" ", "")}
                                                    style={{
                                                        fontSize: "12px",
                                                        textDecoration: "underline",
                                                        marginLeft: "4px",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (parentTask) onSelectedTask(parentTask);
                                                    }}
                                                >
                                                    {parentTask.shortId.replaceAll(" ", "")}
                                                </div>
                                            </OverlayTrigger>
                                        )}
                                    </div>
                                )}

                                {task.type === "Sub Task" && !showAllTasks && (
                                    <div
                                        className="status-subtask"
                                        style={{
                                            backgroundColor: hexToRgbA(task.status?.color ?? "#fff", 0.3),
                                            color: task.status?.color,
                                            fontSize: "12px",
                                        }}
                                    >
                                        {task.status?.title}
                                    </div>
                                )}
                                <div className="wrap-project">
                                    <div className="project">{projects.find((p) => p._id === task.projectId)?.title}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="line-2"
                        >
                            <div className="title flex">
                                {task.type === "Sub Task" && <div style={{ marginRight: "4px" }}>&bull;</div>}
                                <div
                                    style={{
                                        fontSize: task.type === "Sub Task" ? "12px" : "14px",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {task.type !== "Sub Task" && (showAllTasks || showSubTasks) && (
                                        <span className="color-5">{task.shortId.replaceAll(" ", "") + ": "}</span>
                                    )}
                                    {task.title}
                                </div>
                            </div>
                            <div className="right">
                                {task.type === "Task" ? (
                                    <div className="assignee">
                                        <MyAvatar url={task.assignee?.avatar} />
                                        {avatarChildTask.length > 0 &&
                                            avatarChildTask.map((item, index) => (
                                                <img
                                                    key={item + "-" + index}
                                                    className="child-task-avatar"
                                                    src={item ?? defaultAvatar}
                                                    style={{
                                                        transform: `translateX(${-(index + 1) * 10}px)`,
                                                    }}
                                                />
                                            ))}
                                    </div>
                                ) : (
                                    <div className="assignee">
                                        <MyAvatar url={task.assignee?.avatar} />
                                    </div>
                                )}
                                {timeFromStartTask !== undefined && (
                                    <div>
                                        <div className="days-in-progress" title={timeFromStartTask + " days"}>
                                            {timeFromStartTask < 2 && <img src={days_1} />}
                                            {timeFromStartTask === 2 && <img src={days_2} />}
                                            {timeFromStartTask > 2 && timeFromStartTask < 5 && <img src={days_3_4} />}
                                            {timeFromStartTask > 4 && timeFromStartTask < 8 && <img src={days_5_7} />}
                                            {timeFromStartTask > 7 && timeFromStartTask < 12 && <img src={days_8_11} />}
                                            {timeFromStartTask > 11 && timeFromStartTask < 20 && <img src={days_12_19} />}
                                            {timeFromStartTask > 19 && <img src={days_20} />}
                                        </div>
                                    </div>
                                )}
                                <div className="wrap-point">
                                    {task.point !== null ? (
                                        <div className="point">{task.point}</div>
                                    ) : (
                                        <div className="point">-</div>
                                    )}
                                </div>

                                {/* Button test cycle */}
                                <div className="task-test">
                                    {isShowAll
                                        ? task.status?.title !== "to do" &&
                                          task.status?.title !== "done" &&
                                          percent && (
                                              <Badge
                                                  className="task-test__badge"
                                                  overlap="circular"
                                                  badgeContent={
                                                      badgeContent ? (
                                                          <div>
                                                              <div
                                                                  title={`${badgeContent} new test cases`}
                                                                  data-for="badge_icon"
                                                              >
                                                                  <ReportProblemIcon className="badge_icon" color="error" />
                                                              </div>
                                                          </div>
                                                      ) : (
                                                          badgeContent
                                                      )
                                                  }
                                              >
                                                  <div className="task-test__container">
                                                      <OverlayTrigger
                                                          placement="bottom"
                                                          overlay={
                                                              <Tooltip id={"bug-" + task._id}>
                                                                  <span
                                                                      style={{
                                                                          fontSize: "12px",
                                                                      }}
                                                                  >{`${percent.passPercent}% passed, ${percent.failPercent}% failed, ${percent.untestedPercent}% untested`}</span>
                                                              </Tooltip>
                                                          }
                                                      >
                                                          <div data-tip data-for={"bug-" + task._id}>
                                                              <CircularProgress
                                                                  thickness={4}
                                                                  size={28}
                                                                  className="task-test__progress"
                                                                  variant="determinate"
                                                                  value={percent.passPercent}
                                                                  color="success"
                                                              />
                                                              <IconButton
                                                                  color="primary"
                                                                  component="span"
                                                                  size="small"
                                                                  onClick={handleClickOpen}
                                                                  className="task-test__btn"
                                                              >
                                                                  <BugReportIcon
                                                                      className="task-test__icon"
                                                                      htmlColor="#F06666"
                                                                  />
                                                              </IconButton>
                                                          </div>
                                                      </OverlayTrigger>
                                                  </div>
                                              </Badge>
                                          )
                                        : task.status?.title === "QA testing" && (
                                              <div className="task-test__container">
                                                  <div data-tip="Create cycle test !!">
                                                      <IconButton
                                                          color="error"
                                                          component="span"
                                                          size="small"
                                                          onClick={handleClickOpen}
                                                      >
                                                          <BugReportIcon className="task-test__icon" htmlColor="#F06666" />
                                                      </IconButton>
                                                  </div>
                                              </div>
                                          )}
                                </div>
                            </div>
                        </div>
                        {countChildTask > 0 && (task.type !== "Sub Task" || showAllTasks) && (
                            <div className="line-3">
                                <NewProgressBar
                                    current={
                                        task.status?.shortId === Config.COLUMN_STATUS.DONE.id ? 100 : progressiveTask * 100
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!showAllTasks && task.type === "Sub Task" && (
                    <hr
                        style={{
                            width: "80%",
                            margin: "0 auto",
                            color: "rgba(255,255,255,0.52)",
                        }}
                    />
                )}
            </div>

            {/* Modal test cycle */}
            {open && <TestCycleModalWidget task={task} open={open} handleClose={handleClose} />}
            {!showAllTasks && (
                <>
                    <Collapse in={showSubTasks}>
                        <SubTasksPreview
                            parentTask={task}
                            location="board"
                            onSelectedTask={(t: ITask) => {
                                onSelectedTask(t);
                            }}
                        />
                    </Collapse>
                    {task.type !== "Sub Task" && countChildTask > 0 && (
                        <div
                            className="task-btn"
                            onClick={() => {
                                setShowSubTasks(!showSubTasks);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <img src={showSubTasks ? CloseTaskButton : TaskButton} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TaskPreview;

export const getChildTasks = (task: ITask, tasks: ITask[]): ITask[] => {
    let child_task = tasks.filter((item) => item.parentId == task._id);
    let queue = [...child_task];

    while (queue.length > 0) {
        let t = queue.shift();
        let arr = tasks.filter((item) => item.parentId === t?._id);
        if (arr.length > 0) {
            queue = [...queue, ...arr];
            child_task = [...child_task, ...arr];
        }
    }
    return child_task;
};
