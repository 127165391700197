import { ISprintReport } from "../../shared/models/sprintReport";
import { UserCoefficient } from "../../shared/models/userInfo";
import { ISprintReportAction, SprintReportTypes } from "../actions/sprintReport.action";

export interface ISprintReportState {
    loading: boolean;
    sprintReports?: ISprintReport[];
    error?: any;
    newSprintReports?: any; //{userId: [{sprintId, sprintTasks, discipline, coefficients}]}
}

const initState: ISprintReportState = {
    loading: false,
    newSprintReports: {},
};

const sprintReportState = (state = initState, action: ISprintReportAction): ISprintReportState => {
    switch (action.type) {
        case SprintReportTypes.UPDATE_REPORT:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case SprintReportTypes.GET_SPRINT_REPORT_V2_DATA:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };

        case SprintReportTypes.UPDATE_CHECKPOINT_TASK_IN_SPRINT:
            let newSprint: any = { ...state.newSprintReports };
            let sprint = newSprint[action.userId ?? ""]?.find((s: any) => s.sprintId == action.currentSprint);
            if (sprint) {
                let task = sprint.sprintTasks.find((t: any) => t.taskID == action.taskId);
                if (task) {
                    task.checkPoint = action.checkPoint;
                }
            }
            let sprints: any = [...(state.sprintReports ?? [])];
            sprint = sprints.find((s: any) => s.userId == action.userId);
            if (sprint) {
                let task1 = sprint.finishedTasks.find((t: any) => t.taskID == action.taskId);
                if (task1) {
                    task1.checkPoint = action.checkPoint;
                }

                let task2 = sprint.totalTasks.find((t: any) => t.taskID == action.taskId);
                if (task2) {
                    task2.checkPoint = action.checkPoint;
                }
                let task3 = sprint.bugTasks.find((t: any) => t.taskID == action.taskId);
                if (task3) {
                    task3.checkPoint = action.checkPoint;
                }
            }
            return {
                ...state,
                newSprintReports: newSprint,
            };
        case SprintReportTypes.UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    sprintReports: action.sprintReports,
                },
            };
        case SprintReportTypes.UPDATE_REPORT_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case SprintReportTypes.GET_SPRINT_REPORT_V2_DATA_SUCCESS:
            let data = action.newSprintReports;
            let mapData: any = {};
            data.forEach((d: any) => {
                if (!mapData[d.user]) mapData[d.user] = [];
                let discipline = d.discipline;
                if (Array.isArray(d.discipline)) discipline = d.discipline[0]; //old data
                mapData[d.user].push({
                    sprintId: d.sprint,
                    sprintTasks: d.sprintTasks ?? {},
                    discipline: discipline ?? {},
                    coefficients: new UserCoefficient(d?.coefficients),
                });
            });
            return { ...state, loading: false, newSprintReports: mapData };
        case SprintReportTypes.GET_SPRINT_REPORT_V2_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default sprintReportState;
