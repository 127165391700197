import { IUserInfo } from "../../shared/models/userInfo";

export enum UserInfoTypes {
    GET_ALL_USER_INFO = "GET_ALL_USER_INFO",
    GET_ALL_USER_INFO_SUCCESS = "GET_ALL_USER_INFO_SUCCESS",
    GET_ALL_USER_INFO_FAILED = "GET_ALL_USER_INFO_FAILED",
    UPDATE_USERS = "UPDATE_USERS",
    UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS",
    UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED",
}

export interface IUserInfoAction {
    type: UserInfoTypes;
    userInfos?: IUserInfo[];
    error?: any;
    newUser?: boolean;
}

export const getAllUserInfo = (): IUserInfoAction => {
    return {
        type: UserInfoTypes.GET_ALL_USER_INFO,
    };
};

export const getAllUserInfoSuccess = (userInfos: IUserInfo[]): IUserInfoAction => {
    return {
        type: UserInfoTypes.GET_ALL_USER_INFO_SUCCESS,
        userInfos,
    };
};

export const getAllUserInfoFailed = (error: any): IUserInfoAction => {
    return {
        type: UserInfoTypes.GET_ALL_USER_INFO_SUCCESS,
        error,
    };
};

export const updateUserInfo = (userInfos: IUserInfo[]): IUserInfoAction => {
    return {
        type: UserInfoTypes.UPDATE_USERS,
        userInfos,
    };
};

export const updateUserInfoSuccess = (userInfos: IUserInfo[]): IUserInfoAction => {
    return {
        type: UserInfoTypes.UPDATE_USERS_SUCCESS,
        userInfos,
    };
};

export const updateUserInfoFailed = (error: any): IUserInfoAction => {
    return {
        type: UserInfoTypes.UPDATE_USERS_FAILED,
        error,
    };
};
