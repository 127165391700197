import { ITestCaseAction, TestCaseTypes } from "../actions/testCase.action";

export interface ITestCaseState {
	loading: boolean
	data?: any
	error?: any
}

const initState: ITestCaseState = {
  loading: false,
  error: false,
  data: [],
};

const testsReducer = (state = initState, action: ITestCaseAction) => {
  switch (action.type) {
    case TestCaseTypes.GET_TESTS:
      return {
        ...state,
        loading: true,
      };
    case TestCaseTypes.GET_TESTS_SUCCESS:
      action.data.sort((a: any,b: any) => b.priority - a.priority);
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case TestCaseTypes.GET_TESTS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case TestCaseTypes.ADD_TEST:
      const data = [...state.data, action.data];
      data.sort((a,b) => b.priority - a.priority);
      return {
        ...state,
        data,
      };
    case TestCaseTypes.UPDATE_TEST:
      const index = state.data.findIndex((test: any) => test._id === action.data._id)
      const tempArr = [...state.data];
      const testTemp: any = tempArr.splice(index,1, action.data);
      if(testTemp.priority !== action.data.priority) {
        tempArr.sort((a,b) => b.priority - a.priority);
      }
      return {
        ...state,
        data: tempArr,
      };
    case TestCaseTypes.UPDATE_TESTS:
      let tempArr1 = [...state.data];
      tempArr1 = tempArr1.map(item => {
        if(item.taskID === action.data[0].taskID) {
          let test = action.data.find((test: any) => test._id === item._id);
          if(test){
            return {
              ...item, 
              steps: test.steps,
              expectedResult: test.expectedResult,
              sectionID: test.sectionID
            }
          }
        }
        return item;
      })
      
      return {
        ...state,
        data: tempArr1
      }
    case TestCaseTypes.DELETE_TEST:
      const indexDeleteItem = state.data.findIndex((test: any) => test._id === action.data._id)
      const tempArrDeleteItem = state.data.slice()
      tempArrDeleteItem.splice(indexDeleteItem,1);
      return{
        ...state,
        data: tempArrDeleteItem,
      }
    case TestCaseTypes.SET_TEST:
      return {
        ...state,
        data: action.data
      }
    default:
      return state;
  }
};

export default testsReducer;
