import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    mergeData,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { ICertificates } from "../../../../models/certificates.model";
import {
    getAdsRevenueAppAPI,
    getProRevenueAppAPI,
} from "../../../../services/traffic.service";
import {
    formatDataProRevenueApp,
    formatDate,
    formatPrice,
} from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import CustomBarChart from "../../charts/bar-chart";
import PlatformTemplate from "../platform-template";

const RevenuePlatformView = ({
    certificates,
    platformIds,
}: {
    certificates: ICertificates;
    platformIds: any;
}) => {
    const [proValues, setProValues] = useState<any>();
    const [proFormatValues, setProFormatValues] = useState<any>();
    const [adsValues, setAdsValues] = useState<any>();
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [totalRevenue, setTotalRevenue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let newPlatform: number[] = [...platformIds];

    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    useEffect(() => {
        const getDataFc = async () => {
            let dataPro: any = {};
            let dataApp: any = {};
            let appIds: any = certificates.appInfos
                ?.filter((app) => platformIds.includes(app.platformId))
                ?.map((app) => {
                    return app.appID;
                });
            let apps = certificates.appInfos
                ?.filter((app) => platformIds.includes(app.platformId))
                ?.map((app) => {
                    return {
                        type: app.platformId,
                        streamId: app.gaID + "",
                    };
                });
            let dupAppIds = appIds.filter((c: any, index: any) => {
                return appIds.indexOf(c) !== index;
            });
            dupAppIds = dupAppIds.length > 0 ? dupAppIds : appIds;

            setIsLoading(true);

            let resDataProApp = await getProRevenueAppAPI(
                dupAppIds,
                new Date(startTime).getTime(),
                new Date(endTime).getTime()
            );
            let resDataAdsApp: any = {};
            if (apps?.length) {
                resDataAdsApp = await getAdsRevenueAppAPI(
                    apps,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );
            }

            setIsLoading(false);

            let resDataAdsFormatApp: any = {};

            let { formatResDataProAppIos, formatResDataProAppAndroid } =
                formatDataProRevenueApp(resDataProApp, certificates);

            dataApp[Constants.PLATFORM_FLUTTER_IOS] = formatResDataProAppIos;
            dataApp[Constants.PLATFORM_FLUTTER_ANDROID] =
                formatResDataProAppAndroid;

            setProValues(dataApp);

            Object.keys(resDataAdsApp).forEach((key) => {
                let data = resDataAdsApp[key];
                let resultMonth: any = {};
                data?.forEach((item: any) => {
                    let month = item.date.substr(4, 2);
                    let sum = resultMonth[month] ?? 0;
                    sum += Number(item.value);
                    resultMonth[month] = sum;
                });
                const ordered = Object.keys(resultMonth)
                    .sort()
                    .reduce((obj: any, key: any) => {
                        obj[key] = resultMonth[key];
                        return obj;
                    }, {});
                resDataAdsFormatApp[key] = ordered;
            });
            setAdsValues(resDataAdsFormatApp);
        };
        getDataFc();
    }, [startTime, endTime, certificates.id]);
    useEffect(() => {
        if (proValues && adsValues) {
            let newDataPro: any = mergeData(proValues, newPlatform, true);
            let newDataAds: any = mergeData(adsValues, newPlatform, true);
            newDataPro = newDataPro?.map((item: any) => {
                let date = item.date;
                let value = item.value?.toFixed(1);
                return {
                    date,
                    pro: value,
                };
            });

            newDataAds = newDataAds?.map((item: any) => {
                let date = item.date;
                let value = item.value?.toFixed(1);
                return {
                    date,
                    ads: value,
                };
            });
            let newArr = newDataPro?.concat(newDataAds);
            const groupByDate = newArr?.reduce((group: any, item: any) => {
                const { date } = item;
                group[date] = group[date] ?? [];

                group[date].push(item);
                return group;
            }, {});
            let dataFormat: any = [];
            Object.keys(groupByDate)?.forEach((key) => {
                let obj = Object.assign({}, ...groupByDate[key]);
                dataFormat = [...dataFormat, obj];
            });

            setProFormatValues(dataFormat);
        }
    }, [optionValue, proValues, adsValues]);

    useEffect(() => {
        let sum = 0;
        proFormatValues?.forEach((element: any) => {
            sum = sum + Number(element.ads ?? 0) + Number(element.pro ?? 0);
        });
        setTotalRevenue(sum ?? 0);
    }, [proFormatValues]);

    return (
        <div className="question-quality-v2 revenue">
            {isLoading ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <PlatformTemplate
                        platformIds={platformIds}
                        optionValue={optionValue}
                        setOptionValue={(value: any) => setOptionValue(value)}
                    />
                    <div className="total-revenue">
                        Total revenue: $
                        {totalRevenue && formatPrice(totalRevenue)}
                    </div>
                    <div className="list-chart-revenue">
                        {proFormatValues?.length > 0 && (
                            <CustomBarChart
                                data={proFormatValues}
                                dataKeyXAxis="date"
                                dataKeyYAxis={["pro", "ads"]}
                                month={true}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export default RevenuePlatformView;
