import { Button } from "@material-ui/core";
import { FC } from "react";
import { Modal, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteDataPersonalsGoalAction } from "../../../redux/actions/personalsGoal.action";
import { IPersonalsGoal } from "../../../shared/models/personalsGoal";
const DeleteGoalModal: FC<{ goal: IPersonalsGoal; onHide: () => void }> = ({
    goal,
    onHide,
}) => {
    const dispatch = useDispatch();
    const onDelete = () => {
        dispatch(deleteDataPersonalsGoalAction(goal));
        onHide();
    };
    return (
        <>
            <div onClick={onHide}>
                <Modal centered show onHide={() => onHide}>
                    <ModalHeader>
                        <ModalTitle>
                            {" "}
                            Bạn có chắc muốn xoá mục tiêu: "{goal.title}" ?
                        </ModalTitle>
                    </ModalHeader>
                    <ModalFooter>
                        <Button
                            onClick={onDelete}
                            variant="contained"
                            color="secondary"
                        >
                            Xóa
                        </Button>
                        <div style={{ width: 8 }}></div>
                        <Button
                            aria-label="Close"
                            onClick={onHide}
                            color="secondary"
                            variant="outlined"
                        >
                            Đóng
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
};

export default DeleteGoalModal;
