import React from "react";
import Constants from "../../../../../constant";
import { ICertificates } from "../../../../../models/certificates.model";
import { hasAppReactNative } from "../../../../../util";
import ColumnView from "../column";
import "./index.scss";
const UninstallDownloads = ({
    certificates,
}: {
    certificates: ICertificates;
}) => {
    return (
        <div>
            <div className="list-chart">
                {/* <ColumnView
                    platformIds={[Constants.PLATFORM_WEB]}
                    certificates={certificates}
                    eventName="app_remove"
                /> */}
                <ColumnView
                    platformIds={[
                        Constants.PLATFORM_FLUTTER_ANDROID,
                        Constants.PLATFORM_FLUTTER_IOS,
                    ]}
                    eventName="app_remove"
                    certificates={certificates}
                />
                {hasAppReactNative(certificates) && (
                    <ColumnView
                        platformIds={[
                            Constants.PLATFORM_REACT_NATIVE_ANDROID,
                            Constants.PLATFORM_REACT_NATIVE_IOS,
                        ]}
                        eventName="app_remove"
                        certificates={certificates}
                    />
                )}
            </div>
        </div>
    );
};

export default UninstallDownloads;
