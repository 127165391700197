import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IProblemReportingState } from "../../redux/reducers/problemReporting.reducer";
import { getAllProblemReporting } from "../../redux/actions/problemReporting.action";
import { IProblemReporting, ProblemReporting } from "../../shared/models/problemReporting";
import { EditProblemReportingModal } from "./edit-problem-reporting-modal";
import { DeleteProblemReportingModal } from "./delete";
import { IAppState } from "../../redux/reducers/app.reducer";
import LoadingWidget from "../LoadingWidget";
import { getAppInfos } from "../../redux/actions/app.action";
import Config from "../../shared/models/config";
import { getAllSprint } from "../../redux/actions/sprint.action";
import { ISprintState } from "../../redux/reducers/sprint.reducer";
import { IUserInfo } from "../../shared/models/userInfo";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";

export const ProblemReportingComponent: React.FC = () => {
    const [deleteProblem, setDeleteProblem] = useState<ProblemReporting | null>(null);
    const [updateProblem, setUpdateProblem] = useState<ProblemReporting | null>(null);

    const handleCancelUpdate = () => {
        setUpdateProblem(null);
    };
    const handleCancelDelete = () => {
        setDeleteProblem(null);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllProblemReporting());
        dispatch(getAppInfos("flutter"));
        dispatch(getAllSprint());
        dispatch(getAllUserInfo());
    }, []);
    const appState: IAppState = useSelector((state: any) => state.appState);
    const appInfos = appState.appInfos;
    const problemReportingState: IProblemReportingState = useSelector((state: any) => state.problemReportingState);
    const sprintState: ISprintState = useSelector((state: any) => state.sprintState);
    const userInfoState: IUserInfoState = useSelector((state: any) => state.userInfoState);
    const userList: IUserInfo[] = userInfoState.userInfos;

    const priorityOptions = Object.values(Config.PRIORITY_POOL).map((p, index) => ({
        value: index.toString(),
        label: p,
    }));

    if (
        problemReportingState.loading ||
        appState.loading ||
        !appInfos ||
        sprintState.loading ||
        !sprintState.sprints ||
        userInfoState.loading ||
        !userInfoState.userInfos
    ) {
        return <div>Loading...</div>;
    }

    const listApp = appInfos
        .filter((item) => item.key !== "")
        .sort((a, b) => {
            const appA = a.shortName.toLowerCase();
            const appB = b.shortName.toLowerCase();

            if (appA < appB) {
                return -1;
            }
            if (appA > appB) {
                return 1;
            }
            return 0;
        });
    const customFilterIcon = (filtered: boolean) => (
        <FilterAltIcon style={{ color: filtered ? "#1976d2" : undefined, height: "18px" }} />
    );

    const columns: ColumnsType<ProblemReporting> = [
        {
            title: "Sprint",
            dataIndex: "sprint",
            key: "sprint",
        },
        {
            title: "Last updated",
            dataIndex: "lastUpdateDate",
            key: "lastUpdateDate",
            defaultSortOrder: "descend",
            sorter: (a, b) => {
                const dayA = dayjs(a.lastUpdateDate, "DD/MM/YYYY");
                const dayB = dayjs(b.lastUpdateDate, "DD/MM/YYYY");

                if (dayA.isBefore(dayB)) {
                    return -1;
                }
                if (dayA.isAfter(dayB)) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text, record) => {
                let probAssignee = userList.find((user) => user._id === record.assigneeId);
                return (
                    <div className="problem-title-comp">
                        <div className="prob-pri-assignee">
                            <img
                                className="problem-priority-icon"
                                src={"/" + (record.priority ? record.priority : "Lowest") + "_priority.svg"}
                            />
                            <img className="problem-assignee-avatar" src={probAssignee?.avatar} />
                        </div>
                        <div
                            className="problem-title"
                            // style={{ minHeight: "24px", maxHeight: "45px", overflow: "auto", maxWidth: "300px" }}
                        >
                            {record.title}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "App/Web name",
            dataIndex: "appWebName",
            key: "appWebName",
            filters: listApp.map((item) => {
                return {
                    text: item.shortName,
                    value: item.shortName,
                };
            }),
            onFilter: (value: any, record) => record.appWebName.includes(value),
            filterIcon: customFilterIcon,
        },
        {
            title: "Loại",
            dataIndex: "type",
            key: "type",
            filters: Config.PROBLEM_TYPE_OPTION.map((item) => {
                return {
                    text: item.label,
                    value: item.label,
                };
            }),
            onFilter: (value: any, record) => record.type.includes(value),
            filterIcon: customFilterIcon,
        },
        {
            title: "Nội dung",
            dataIndex: "problems",
            key: "problems",
            render: (text, record) => (
                <div style={{ minHeight: "24px", maxHeight: "45px", overflow: "auto", padding: "0px" }}>{record.problems}</div>
            ),
        },
        {
            title: "Trạng thái",
            dataIndex: "state",
            key: "state",
            filters: [
                {
                    text: "Done",
                    value: "Done",
                },
                {
                    text: "In progress",
                    value: "In progress",
                },
            ],
            onFilter: (value: any, record) => record.state.includes(value),
            filterIcon: customFilterIcon,
        },
        {
            title: "",
            key: "update",
            render: (text, record) => (
                <button style={{ border: "none", backgroundColor: "white" }}>
                    <BorderColorIcon
                        color="primary"
                        sx={{ fontSize: 18 }}
                        onClick={() => {
                            setUpdateProblem(record);
                        }}
                    />
                </button>
            ),
        },
        {
            title: "",
            key: "delete",
            render: (text, record) => (
                <button style={{ border: "none", backgroundColor: "white" }}>
                    <DeleteIcon
                        color="primary"
                        sx={{ fontSize: 18 }}
                        onClick={() => {
                            setDeleteProblem(record);
                        }}
                    />
                </button>
            ),
        },
    ];

    const problemData = problemReportingState.problemReportings.map((e) => {
        let i = appInfos.find((option) => option.key.includes(e.appWebName));
        let s = Config.PROBLEM_STATE_OPTION.find((s) => s.value.includes(e.state));
        let t = Config.PROBLEM_TYPE_OPTION.find((t) => t.value.includes(e.type));
        let p = priorityOptions.find((p) => p.value.includes(e.priority));
        if (i) {
            e.appWebName = i.shortName;
        }
        if (s) {
            e.state = s.label;
        }
        if (t) {
            e.type = t.label;
        }
        if (p) {
            e.priority = p.label;
        }
        e.lastUpdateDate = dayjs(e.lastUpdateDate, "DD/MM/YYYY").format("DD-MM-YYYY");
        return e;
    });

    return (
        <div>
            <button
                style={{
                    border: "1.5px solid #1890FF",
                    borderRadius: "5px",
                    margin: "0.5em",
                    padding: "0.3em",
                    backgroundColor: "#1890FF",
                    color: "#fff",
                }}
                onClick={() => {
                    setUpdateProblem(new ProblemReporting({}));
                }}
            >
                Thêm mới
            </button>
            <Table 
            className="problem-table" 
            rowKey={(e) => "xxx-" + e._id}
            columns={columns}
             dataSource={[...problemData]}
              />
            {!!updateProblem && (
                <EditProblemReportingModal
                    problem={updateProblem}
                    onHide={handleCancelUpdate}
                    listApp={listApp}
                    sprintList={sprintState.sprints}
                    userList={userList}
                />
            )}
            {!!deleteProblem && <DeleteProblemReportingModal problem={deleteProblem} onHide={handleCancelDelete} />}
        </div>
    );
};
