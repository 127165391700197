import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    getAppInfoGa,
    mergeData,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { Certificates } from "../../../../models/certificates.model";
import { getAvgSessionAPI } from "../../../../services/traffic.service";
import {
    formatCurrency,
    formatDate,
    hasAppReactNative,
    hasAppWeb,
} from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import CustomLineChart from "../../charts";
import PlatformTemplate from "../platform-template";
// import "./index.scss";

const SessionsView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-active-user">
            <div className="list-chart">
                {hasAppWeb(certificates) && (
                    <ViewApp
                        certificates={certificates}
                        platformIds={[Constants.PLATFORM_WEB]}
                        showTotal={true}
                        showAvg={true}
                    />
                )}

                <ViewApp
                    certificates={certificates}
                    platformIds={[
                        Constants.PLATFORM_FLUTTER_ANDROID,
                        Constants.PLATFORM_FLUTTER_IOS,
                    ]}
                    showTotal={true}
                    showAvg={true}
                />
                {hasAppReactNative(certificates) && (
                    <ViewApp
                        certificates={certificates}
                        platformIds={[
                            Constants.PLATFORM_REACT_NATIVE_ANDROID,
                            Constants.PLATFORM_REACT_NATIVE_IOS,
                        ]}
                        showTotal={true}
                        showAvg={true}
                    />
                )}
            </div>
        </div>
    );
};

const ViewApp = ({
    certificates,
    platformIds,
    showTotal,
    showAvg,
}: {
    certificates: Certificates;
    platformIds: number[];
    showTotal?: boolean;
    showAvg?: boolean;
}) => {
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [appValues, setAppValues] = useState(null);
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    useEffect(() => {
        async function getDataFC() {
            setAppValues(null);

            let apps = getAppInfoGa(certificates, platformIds);
            if (apps?.length) {
                let resData = await getAvgSessionAPI(
                    apps,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );
                setAppValues(resData);
            } else {
                let obj: any = {};
                setAppValues(obj);
            }
        }
        getDataFC();
    }, [startTime, endTime, certificates.id]);
    let newPlatform: number[] = [...platformIds];
    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    let newData = mergeData(appValues, newPlatform);
    const platform: any = PLATFORM_CONFIG.filter((platform) =>
        platformIds.includes(platform.type)
    )[0];
    const total = newData
        ? newData.reduce((acc, data) => {
              return acc + data.value;
          }, 0)
        : 0;
    const avg = newData ? total / newData.length : 0;
    return (
        <div className="active-user-app">
            {appValues ? (
                Object.keys(appValues).length > 0 ? (
                    <>
                        <PlatformTemplate
                            platformIds={platformIds}
                            optionValue={optionValue}
                            setOptionValue={(value: any) =>
                                setOptionValue(value)
                            }
                            total={avg}
                        />
                        <div className="flex">
                            {showAvg && (
                                <div className="total">
                                    Avg <span> {formatCurrency(avg)}</span>
                                </div>
                            )}
                            {showTotal && (
                                <div className="total">
                                    Total <span> {formatCurrency(total)}</span>
                                </div>
                            )}
                        </div>
                        <CustomLineChart data={newData} dataKeyX="date" />
                    </>
                ) : (
                    <></>
                )
            ) : (
                <CircularIndeterminate />
            )}
        </div>
    );
};

export default SessionsView;
