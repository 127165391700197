import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import TextArea from "antd/lib/input/TextArea";
import { FC, useState } from "react";
import Config from "../../../shared/models/config";
import { RoleGroup } from "../../../shared/models/userGroup";

export interface RatingOption {
    id: number
    title: string
}
export interface IRatingItem {
    id: number
    title: string
    description: string
    roleDisplayed: RoleGroup[]
    listOption?: RatingOption[]
    teamDisplayed?: number
    userNote?: boolean
    required?: boolean
}

export const RATING_ITEM_IDS = {
    operationProcess: {
        id: 1,
        name: "Quy trình hoạt động trong team ntn?"
    },
    techContributionDev: {
        id: 2,
        name: "Có đóng góp cải tiến sản phẩm?"
    },
    prodContributionDev: {
        id: 3, 
        name: "Có đóng góp cải tiến công nghệ?",
    },
    leaderShip: {
        id: 4, 
        name: "Khả năng training ntn?",
    },
    supportSkill: {
        id: 5,
        name: "Khả năng support các bạn khác?"
    },
    deadlineConfirmation:{
        id: 6,
        name: "Khả năng đảm bảo deadline?",
    },
    attitude: {
        id: 7,
        name: "Thái độ trong công việc?",
    },
    responsibility: {
        id: 8,
        name: "Trách nhiệm trong công việc?",
    },
    percentOfKPI:{
        id: 9,
        name: "Đạt bao nhiêu % KPI cá nhân?",
    },
    amountOfNewIdeaExcSuccess: {
        id: 10,
        name: "Số lượng idea mới được triển khai thành công?",
    },
    effectOfExecutedIdea: {
        id: 11,
        name: "Mức độ ảnh hưởng của idea đã được triển khai?",
    },
    prodContributionContent: {
        id: 12,
        name: "Có đóng góp cải tiến sản phẩm?",
    },
    processContributionContent: {
        id: 13,
        name: "Có đóng góp cải tiến quy trình?",
    }
}

const listValueDefault: RatingOption[] = [
    { id: 1, title: "Kém" },
    { id: 2, title: "TB" },
    { id: 3, title: "Khá" },
    { id: 4, title: "Tốt" }
];
const listValueDefault2: RatingOption[] = [{ id: 0, title: "Không có" }, { id: 1, title: "Có đóng góp" }, { id: 2, title: "Đột phá" }];

export const ratingItems: IRatingItem[] = [
    {
        id: RATING_ITEM_IDS.operationProcess.id,
        title: RATING_ITEM_IDS.operationProcess.name,
        description: "",
        roleDisplayed: ["lead", "intern", "member"],
        listOption: listValueDefault,
        required: true
    },
    {
        id: RATING_ITEM_IDS.techContributionDev.id,
        title: RATING_ITEM_IDS.techContributionDev.name,
        description: "",
        roleDisplayed: ["lead"],
        listOption: listValueDefault2,
        userNote: true
    },
    {
        id: RATING_ITEM_IDS.prodContributionDev.id,
        title: RATING_ITEM_IDS.prodContributionDev.name,
        description: "",
        roleDisplayed: ["lead"],
        listOption: listValueDefault2,
        userNote: true
    },
    {
        id: RATING_ITEM_IDS.leaderShip.id,
        title: RATING_ITEM_IDS.leaderShip.name,
        description: "",
        roleDisplayed: ["intern", "member"],
        listOption: listValueDefault,
        required: true
    },
    {
        id: RATING_ITEM_IDS.supportSkill.id,
        title: RATING_ITEM_IDS.supportSkill.name,
        description: "",
        roleDisplayed: ["lead", "intern", "member"],
        listOption: listValueDefault
    },
    {
        id: RATING_ITEM_IDS.deadlineConfirmation.id,
        title: RATING_ITEM_IDS.deadlineConfirmation.name,
        description: "",
        roleDisplayed: ["lead", "intern", "member"],
        listOption: listValueDefault,
        required: true
    },
    {
        id: RATING_ITEM_IDS.attitude.id,
        title: RATING_ITEM_IDS.attitude.name,
        description: "",
        roleDisplayed: ["lead", "intern", "member"],
        listOption: listValueDefault,
        required: true
    },
    {
        id: RATING_ITEM_IDS.responsibility.id,
        title: RATING_ITEM_IDS.responsibility.name,
        description: "",
        roleDisplayed: ["lead", "intern", "member"],
        listOption: listValueDefault,
        required: true
    },
    {
        id: RATING_ITEM_IDS.percentOfKPI.id,
        title: RATING_ITEM_IDS.percentOfKPI.name,
        description: "",
        roleDisplayed: ["lead"],
        teamDisplayed: Config.TEAMS.MARKETING.id,
        required: true
    },
    {
        id: RATING_ITEM_IDS.amountOfNewIdeaExcSuccess.id,
        title: RATING_ITEM_IDS.amountOfNewIdeaExcSuccess.name,
        description: "",
        roleDisplayed: ["lead"],
        teamDisplayed: Config.TEAMS.MARKETING.id
    },
    {
        id: RATING_ITEM_IDS.effectOfExecutedIdea.id,
        title: RATING_ITEM_IDS.effectOfExecutedIdea.name,
        description: "",
        roleDisplayed: ["lead"],
        teamDisplayed: Config.TEAMS.MARKETING.id
    },
    {
        id: RATING_ITEM_IDS.prodContributionContent.id,
        title: RATING_ITEM_IDS.prodContributionContent.name,
        description: "",
        roleDisplayed: ["lead"],
        teamDisplayed: Config.TEAMS.CONTENT.id
    },
    {
        id: RATING_ITEM_IDS.processContributionContent.id,
        title: RATING_ITEM_IDS.processContributionContent.name,
        description: "",
        roleDisplayed: ["lead"],
        teamDisplayed: Config.TEAMS.CONTENT.id
    }
];

export const SelectRatingOption: FC<({
    value: string
    onChange: (value: string, note?: string) => void, 
    listValue: RatingOption[], 
    label: string, 
    useNote?: boolean
    note?: string
})> = ({ onChange, listValue, label, value, useNote, note }) => {
    const name = new Date().getTime();
    const [text, setText] = useState(note ?? "");
    return (
        <div className="rating-options">
            <FormControl fullWidth>
                <FormLabel>{label}</FormLabel>
                <RadioGroup
                    row
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    value={useNote && !value ? "0" : value}
                >
                    { listValue.map((item: RatingOption, index: number) => {
                        return (
                            <FormControlLabel
                                key={"SelectRatingOptionItem-" + index}
                                value={item.id}
                                control={<Radio />}
                                label={item.title}
                            />
                        );
                    }) }
                </RadioGroup>
            </FormControl>
            { useNote && !! value && value != "0" && (
                <TextArea
                    id={name + "-input"}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onBlur={(e) => {
                        onChange(value, text);
                    }}
                    placeholder="Note"
                />
            ) }
        </div>
    );
}