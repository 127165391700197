import { IGitHubRepository } from "../../shared/models/gitHubRepository";
import { GitHubTypes, IGitHubAction } from "../actions/github.action";

export interface IGitHubState {
	loading: boolean
	repositories?: IGitHubRepository[]
	error?: any
}

const initState: IGitHubState = {
	loading: false,
};

const gitHubState = (state = initState, action: IGitHubAction): IGitHubState => {
	switch (action.type) {
		case GitHubTypes.GET_REPOSITORIES:
			return {
				...state,
				... {
					loading: true,
				}
			}
		case GitHubTypes.GET_REPOSITORIES_SUCCESS:
			return {
				...state,
				...{
					loading: false,
					repositories: action.repositories,
				}
			}
		case GitHubTypes.GET_REPOSITORIES_FAILED:
			return {
				...state,
				...{
					loading: false,
					error: action.error,
				}
			}
		default:
			return state;
	}
};

export default gitHubState;
