import comingSoon from "../../assets/images/coming-soon.png";
import nhanThuong from "../../assets/images/NhanThuong.svg";
import diamond from "../../assets/images/Rectangle.svg";
import split from "../../assets/images/Split-shop.png";
import energy from "../../assets/images/thunder_leaderboard.png";
import tongQua from "../../assets/images/TongQua.svg";
import top1tuan from "../../assets/images/Top1tuan.svg";
import top3tuan from "../../assets/images/Top3tuan.svg";
import totalCreditIcon from "../../assets/images/totalCredit.svg";
import totalPointIcon from "../../assets/images/totalPoint.svg";
import UserAvatar from "./UserAvatar";
import moment from "moment";

const InfoUser = ({myProfile, showDetail}) => {
    const userBirthday = moment(new Date(myProfile?.birth)).format('LL');

    const daysInMonth = () => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        return new Date(year, month, 0).getDate();
    };

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    const nDays = daysInMonth();
    const today = new Date().getDate();
    const currentLevel = monthDiff(new Date(myProfile?.startDate), new Date());
    const nextLevel = currentLevel + 1;
    const levelByDay = today / nDays;

    return (
        <div>
            {myProfile && (
                <div className="user-template">
                    <div className="user-item">
                        <div className="user-item-avatar">
                            <UserAvatar avatar={myProfile.avatar} />
                        </div>

                        <div className="user-item-info">
                            <div className="username">
                                {myProfile.firstName + " " + myProfile.lastName}
                            </div>
                            <div className="position">{myProfile.position}</div>
                        </div>
                    </div>

                    <img className="split" src={split} />

                    <div className="user-level">
                        <div className="level current-level">
                            <p className="text-lv">Lv</p>
                            <p className="lv">{currentLevel}</p>
                        </div>
                        <div className="level-in-month">
                            <div
                                className="level-by-date"
                                style={{ width: `${levelByDay * 100}%` }}
                            ></div>
                        </div>
                        <div className="level next-level">
                            <p className="text-lv">Lv</p>
                            <p className="lv">{nextLevel}</p>
                        </div>
                    </div>

                    {!showDetail && (
                        <div className="userInfo">
                            {myProfile?.emailActive === false ? (<span>Email: {myProfile.email} (chưa Active)</span>) : (<span>Email: {myProfile.email}</span>)}
                            <span>Giới tính: {myProfile.gender ?? "Chưa cập nhật!"}</span>
                            <span>Sinh nhật: {userBirthday}</span>
                            <span>Team: {myProfile.teams?.toUpperCase() ?? "Chưa cập nhật!"}</span>
                            {myProfile.role === 0 ? <span>Chức vụ: Nhân viên</span> : <span>Chức vụ: Quản lý</span>}
                            <span>Discord ID: {myProfile.discordID ?? "Chưa cập nhật!"}</span> 
                            <span>Start date: {moment(myProfile.startDate).format('LL') ?? "Chưa cập nhật!"}</span>
                            <span>Address: {myProfile.address ?? "Chưa cập nhật!"}</span> 
                        </div>
                    )}

                    {showDetail && (
                        <div>
                            <div className="coming-soon">
                                <img src={comingSoon} />
                            </div>

                            <div className="user-point-info">
                                <div className="line-1">
                                    <div className="bonus-history-icon">
                                        <img src={nhanThuong} />
                                    </div>
                                    <div className="bonus-history">
                                        <div className="text">
                                            <p>Nhận thưởng</p>
                                        </div>
                                        <div className="data-bonus-history">Updating...</div>
                                    </div>
                                    <div className="top3-icon">
                                        <img src={top3tuan} />
                                    </div>
                                    <div className="top3">
                                        <div className="text">
                                            <p>Top 3 tuần</p>
                                        </div>
                                        <div className="data-top3">Updating...</div>
                                    </div>
                                    <div className="top1-icon">
                                        <img src={top1tuan} />
                                    </div>
                                    <div className="top1">
                                        <div className="text">
                                            <p>Top 1 tuần</p>
                                        </div>
                                        <div className="data-top1">Updating...</div>
                                    </div>
                                </div>

                                <div className="line-2">
                                    <div className="present-icon">
                                        <img src={tongQua} />
                                    </div>
                                    <div className="present">
                                        <div className="text">
                                            <p>Tổng quà</p>
                                        </div>
                                        <div className="data-present">Updating...</div>
                                    </div>
                                    <div className="point-icon">
                                        <img src={totalPointIcon} />
                                    </div>
                                    <div className="point">
                                        <div className="text">
                                            <p>Tổng</p>
                                        </div>
                                        <div className="images">
                                            <img src={energy} />
                                        </div>
                                        <div className="data-point">{myProfile.point}</div>
                                    </div>
                                    <div className="credit-icon">
                                        <img src={totalCreditIcon} />
                                    </div>
                                    <div className="credit">
                                        <div className="text">
                                            <p>Tổng</p>
                                        </div>
                                        <div className="images">
                                            <img src={diamond} />
                                        </div>
                                        <div className="data-credit">{myProfile.credit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InfoUser;
