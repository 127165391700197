const Url = (endpoint) => {
    const domain =
        process.env.REACT_APP_API_URL ??
        "https://hero-dev-api.abc-elearning.org";
    return domain + "/" + endpoint;
};

export default Url;
export const urlDashboard =
    process.env.REACT_APP_DASHBOARD ??
    "https://dashboard-api2.abc-elearning.org/";
// "http://localhost:8000/";
