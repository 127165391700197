import { FC, useRef, useState } from "react";
import { Button, Form, FormControl, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Campaign, ICampaign } from "../../../../shared/models/campaign";
import { ITask } from "../../../../shared/models/task";
import "./styles.scss";
import moment from "moment";
import Constants from "../../../../constant";
import { archiveCampaignSuccess, deleteCampaign, updateCampaign } from "../../../../redux/actions/campaign.action";
import { convertTaskOptions, onGetOptionsValue } from "../../../../utils";
import EditorWidget from "../../../ckeditor/editor-widget";
import TaskPreview from "../../task-preview";
import SelectProjectWidget from "../../task-preview/edit-task-modal/select-project";
import SelectUserWidget from "../../task-preview/edit-task-modal/select-user";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import { toast } from "react-toastify";
import { archiveCampaign } from "../campaign-progress-review";
// import { isAdmin } from "../../../../util";
import { IUserGroup } from "../../../../shared/models/userGroup";
import Config from "../../../../shared/models/config";
const CreateCampaignModal: FC<{
    onHide: () => void;
    currentSprintId: string;
    campaign: ICampaign;
}> = ({ onHide, currentSprintId, campaign }) => {
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const mapTask: Map<string, ITask> = new Map();
    const campaignTasks = useSelector((state: any) => state.taskState.campaignTasks);
    for (let task of tasks) {
        mapTask.set(task._id, task);
    }
    Object.values(campaignTasks).forEach((cpt: any) => {
        if (cpt?.length) {
            cpt.forEach((t: any) => {
                if (t?.type?.toLowerCase() !== "sub task") mapTask.set(t._id, t);
            });
        }
    });
    const isEditCampaign = !!campaign._id;
    const titleRef = useRef<HTMLInputElement | null>(null);
    const [description, setDescription] = useState(campaign.description ?? "");
    const priorityRef = useRef<HTMLSelectElement | null>(null);
    const targetRef = useRef<HTMLInputElement | null>(null);
    const startDateRef = useRef<HTMLInputElement | null>(null);
    const deadlineRef = useRef<HTMLInputElement | null>(null);
    const [status, setStatus] = useState<string>(campaign.status);
    const userInfo = useSelector((state: any) => state.authState.user);
    const [selectedUserId, setSelectedUserId] = useState<string>(campaign.reporterId ?? "");
    const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>(campaign.listTaskIDs);
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>(campaign.projectIds);
    const isDisableDate = Constants.STATUS_CAMPAIGN_COMPLETE.indexOf(status) !== -1;
    const [archive, setArchive] = useState(!!campaign.archive);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups ?? []);
    const validateData = (campaign: ICampaign) => {
        if (!campaign.title) {
            toast("Chưa có tiêu đề!", { position: "top-center" });
            return false;
        }
        if (!campaign.reporterId) {
            toast("Chưa có reporter!", { position: "top-center" });
            return false;
        }
        if (!(campaign.startDate instanceof Date) || isNaN(campaign.startDate.getTime())) {
            toast("Hãy chọn ngày bắt đầu!", { position: "top-center" });
            return false;
        }
        if (campaign.startDate && campaign.deadline && campaign.startDate > campaign.deadline) {
            toast("Ngày bắt đầu phải trước deadline!", {
                position: "top-center",
            });
            return false;
        }
        return true;
    };
    const getCampaignInfo = () => {
        return new Campaign({
            ...campaign,
            sprintId: currentSprintId,
            title: titleRef.current?.value ?? "",
            description,
            priority: priorityRef.current?.value ?? "",
            target: targetRef.current?.value ?? "",
            startDate: startDateRef.current?.value ?? "",
            deadline: deadlineRef.current?.value ?? "",
            status,
            reporterId: selectedUserId,
            listTaskIDs: selectedTaskIds,
            projectIds: selectedProjectIds,
            userId : campaign.userId ? campaign.userId : userInfo._id
        });
    };
    const dispatch = useDispatch();
    const isUserGroups: IUserGroup[] = userGroups.filter((group) => group.users.some((user) => user.userId === userInfo._id)); //check xem user co trong group khong
    let checkPermissionAdd: boolean | undefined;
    isUserGroups.map((data, index) => {
        data?.users?.forEach((d) => {
            d?.permission?.find((dt) => {
                if(d.permission !== undefined){
                    if (d?.permission.length > 0 && dt === Config.PERMISSION_EDITOR_CAMPAIGN) {
                        checkPermissionAdd = true;
                    }
                }
            });
        });
    }); // check quyen cua user
    const checkRoleToAddCampaign = () => {
        if (checkPermissionAdd) {
            return true;
        }
        return false;
    };

    const onUpdateCampaign = (att: any, updateTasks?: boolean) => {
        if (checkRoleToAddCampaign()) {
            let campaign: any = getCampaignInfo();
            if (campaign._id && validateData(campaign)) {
                // console.log("update now ", campaign);
                for (let key in att) {
                    campaign = { ...campaign, [key]: att[key] };
                }
                dispatch(updateCampaign( {...campaign}, !!updateTasks));
            }
        } else {
            alert("Bạn không có quyền chỉnh sửa campaign");
            onHide();
        }
    };

    const onAddCampaign = (updateTasks?: boolean) => {
        let campaign = getCampaignInfo();
        if (validateData(campaign)) {
            dispatch(updateCampaign(campaign));
            toast(() => <>Tạo campaign thành công</>, {
                type: "success",
                position: "top-center",
                autoClose: 2000,
            });
            onHide();
        }
    };
    const onDelete = () => {
        if (checkRoleToAddCampaign()) {
            if (window.confirm("Are you sure you want to delete this campaign?")) {
                dispatch(deleteCampaign(campaign));
                toast(() => <div>Campaign đã bị xoá!</div>, {
                    position: "top-center",
                    type: "warning",
                });
            }
        } else {
            alert("Bạn không có quyền xóa campaign");
        }
    };
    const callbackArchive = (isOk: boolean, isArchive: boolean) => {
        if (isOk) {
            if (isArchive)
                toast(() => <>Lưu campaign thành công</>, {
                    type: "success",
                    autoClose: 500,
                    position: "top-center",
                });
            dispatch(archiveCampaignSuccess(campaign._id));
        } else {
            setArchive(archive);
            toast("Lưu không thành công", {
                type: "error",
                autoClose: 500,
                position: "top-center",
            });
        }
    };
    const onArchiveCampaign = () => {
        setArchive(!archive);
        setTimeout(() => {
            archiveCampaign(userInfo._id, campaign._id, !campaign?.archive, callbackArchive);
        }, 300);
    };
    const handleClickDelete = () => {
        onDelete();
        onHide();
    };

    const handleClickCreate = () => {
        onAddCampaign();
    };
    return (
        <>
            <Modal show={true} size="xl" centered onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="flex">
                            <div>{isEditCampaign ? "Cập nhật campaign" : "Tạo mới campaign"}</div>
                            {isEditCampaign && (
                                <div style={{ cursor: "pointer" }} onClick={onArchiveCampaign}>
                                    {archive ? <InventoryIcon /> : <Inventory2OutlinedIcon />}
                                </div>
                            )}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <ModalBody className="create-campaign-modal">
                    <div className="left">
                        <div className="title">
                            <h5>Tiêu đề</h5>
                            <FormControl
                                defaultValue={campaign.title ?? ""}
                                ref={titleRef}
                                type="text"
                                name="title"
                                onBlur={() => {
                                    onUpdateCampaign({});
                                }}
                            />
                        </div>
                        <div className="description">
                            <div className="description-line-1">
                                <h5>Mô tả</h5>
                            </div>
                            <EditorWidget
                                defaultValue={description}
                                onChange={(value) => {
                                    setDescription(value);
                                    onUpdateCampaign({ description: value });
                                }}
                            />
                        </div>
                        <div className="field">
                            <h5>Mục tiêu</h5>
                            <FormControl
                                ref={targetRef}
                                defaultValue={campaign.target ?? ""}
                                type="text"
                                name="target"
                                onBlur={() => {
                                    onUpdateCampaign({});
                                }}
                            />
                        </div>
                        <div className="field">
                            <h5>Tasks</h5>
                            <div className="form-multi-select">
                                <SelectTasksWidget
                                    taskIdsSelected={selectedTaskIds}
                                    onSelectedTaskIds={(ids) => {
                                        setSelectedTaskIds(ids);
                                        onUpdateCampaign({ listTaskIDs: ids }, true);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            {selectedTaskIds.map((taskId) => {
                                let task = mapTask.get(taskId);
                                if (!task) {
                                    return null;
                                }
                                return (
                                    <div key={task._id}>
                                        <TaskPreview task={task} onSelectedTask={() => {}} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="right">
                        <div className="field">
                            <h5>Project</h5>
                            <div className="form-multi-select">
                                <SelectProjectWidget
                                    isMulti={true}
                                    ids={selectedProjectIds}
                                    onSelected={(value) => {
                                        setSelectedProjectIds(value);
                                        onUpdateCampaign({ projectIds: value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <h5>Priority</h5>
                            <Form.Select
                                defaultValue={campaign.priority}
                                ref={priorityRef}
                                onChange={() => {
                                    onUpdateCampaign({});
                                }}
                            >
                                {Constants.priorityPool.map((p, index) => (
                                    <option key={index} value={p ?? ""}>
                                        {p}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="field reporter">
                            <h5>
                                <strong>Reporter*</strong>
                            </h5>
                            <SelectUserWidget
                                ids={[selectedUserId]}
                                onSelected={(value) => {
                                    setSelectedUserId(value[0]);
                                    onUpdateCampaign({ reporterId: value[0] });
                                }}
                            />
                        </div>
                        <div className="field">
                            <h5>Status</h5>
                            <Form.Select
                                defaultValue={campaign.status}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                    onUpdateCampaign({
                                        status: e.target.value,
                                    });
                                }}
                            >
                                {Constants.statusCampaigns.map((p, index) => (
                                    <option key={index} value={p}>
                                        {p}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="field ">
                            <h5>
                                <strong>Start Date*</strong>
                            </h5>
                            <FormControl
                                ref={startDateRef}
                                type="date"
                                name="startdate"
                                defaultValue={moment(campaign.startDate).format("YYYY-MM-DD")}
                                onChange={() => {
                                    onUpdateCampaign({});
                                }}
                                disabled={isDisableDate}
                            />
                        </div>
                        <div className="field deadline">
                            <h5>Deadline</h5>
                            <FormControl
                                ref={deadlineRef}
                                type="date"
                                name="deadline"
                                defaultValue={moment(campaign.deadline).format("YYYY-MM-DD")}
                                onChange={() => {
                                    onUpdateCampaign({});
                                }}
                                disabled={isDisableDate}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!isEditCampaign && (
                        <Button
                            onClick={handleClickCreate}
                            style={{
                                background: "#4bb4ed",
                                borderColor: "#4bb4ed",
                            }}
                        >
                            Tạo
                        </Button>
                    )}
                    {isEditCampaign && (
                        <Button
                            onClick={handleClickDelete}
                            style={{
                                background: "#ed3f3f",
                                borderColor: "#ed3f3f",
                            }}
                        >
                            Xoá
                        </Button>
                    )}
                    <Button className="close" aria-label="Close" onClick={onHide} variant="secondary">
                        Huỷ
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const SelectTasksWidget: FC<{
    taskIdsSelected: string[];
    onSelectedTaskIds: (tasks: string[]) => void;
}> = ({ taskIdsSelected, onSelectedTaskIds }) => {
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const campaignTasks = useSelector((state: any) => state.taskState.campaignTasks);

    const mapTask = new Map<string, ITask>();
    for (let task of tasks) {
        mapTask.set(task._id, task);
    }
    Object.values(campaignTasks).forEach((cpt: any) => {
        if (cpt?.length) {
            cpt.forEach((t: any) => {
                mapTask.set(t._id, t);
            });
        }
    });
    const tasksSelected: ITask[] = [];
    for (let taskId of taskIdsSelected) {
        let task = mapTask.get(taskId);
        if (task) {
            tasksSelected.push(task);
        }
    }
    const [selected, setSelected] = useState(convertTaskOptions(tasksSelected));
    return (
        <Select
            value={selected}
            isMulti
            options={convertTaskOptions(tasks)}
            className="basic-multi-select"
            onChange={(newValue, actionMeta) => {
                let list = onGetOptionsValue(selected, actionMeta);
                setSelected(list);
                onSelectedTaskIds(list.map((t) => t.value));
            }}
        />
    );
};

export default CreateCampaignModal;
