import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import Url from "../../util/url";
import Feedback from "../feedback";
import CareerEvent from "../career-event";
import ChangePointModal from "../home/ChangePointModal";
import Progress from "./progress/progress";
import InfoUser from "./TemplateInfoUser";
import EvaluationModal from "./evaluation-modal/EvaluationModal";
import EventModal from "./event-modal";

const UserScreen = ({ userInfoState, feedbacks, events }) => {
    const dispatch = useDispatch();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const currentId = userInfo._id;
    const role = userInfo.role;
    const userId = useParams().id;
    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = useState(false);
    const [email, setEmail] = useState("");
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);

    useEffect(async () => {
        try {
            getUserInfo();
            getFeedback();
            getEvent();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getUserInfo = async () => {
        dispatch({ type: "SET_USER_INFO", data: [] });

        const res = await axios.get(Url("user/info/") + userId);
        if (res?.data?.success) {
            const dataMap = res?.data?.response;
            if (dataMap[0]?.email) {
                setEmail(dataMap[0].email);
                dispatch({ type: "SET_USER_INFO", data: dataMap[0] });
            }
        }
    };

    const getFeedback = async () => {
        const res = await axios.get(Url("comment/get-comment/") + userId);
        const data = res?.data;
        const response = data?.response;
        // console.log(response);
        if (data?.success) {
            const feedback =
                role === 1 || currentId === userId
                    ? response
                    : response?.filter((element) => !element.hide);
            const feedbackMap =
                res?.data?.success &&
                feedback?.map((item) => {
                    return {
                        strength: item.strength,
                        weakness: item.weakness,
                        target: item.target,
                        review: item.review,
                        opinion: item.opinion,
                        month: item.month,
                        year: item.year,
                        _id: item._id,
                        userId: item.userId,
                    };
                });
            dispatch({ type: "SET_USER_FEEDBACK", data: response });
        } else {
            dispatch({ type: "SET_USER_FEEDBACK", data: [] });
        }
    };

    const getEvent = async () => {
        const res = await axios.get(Url("career-path/get-event/" + userId));
        const data = res?.data;
        const response = data?.response;
        if (data?.success) {
            const event =
                role === 1 || currentId === userId
                    ? response
                    : response?.filter((element) => !element.hide);
            dispatch({ type: "SET_CAREER_EVENT", data: event });
        } else {
            dispatch({ type: "SET_CAREER_EVENT", data: [] });
        }
    };

    const handleClick = (show, type) => {
        setModalShow(show);
        setType(type);
    };

    const handleShowFeedbackModal = () => {
        setShowFeedbackModal(!showFeedbackModal);
    };

    const handleShowEventModal = () => {
        setShowEventModal(!showEventModal);
    };

    return (
        <>
            <EvaluationModal
                showModal={showFeedbackModal}
                handleShowModal={handleShowFeedbackModal}
            />
            <EventModal
                showModal={showEventModal}
                handleShowModal={handleShowEventModal}
            />
            {role === 1 && (
                <div className="btn-mode">
                    <div style={{ textAlign: "center" }}>
                        <Button onClick={handleShowEventModal}>Event</Button>
                        <Button onClick={handleShowFeedbackModal}>Form</Button>
                        {/* <Button onClick={() => handleClick(true, true)}>
                            Thưởng
                        </Button>
                        <Button onClick={() => handleClick(true, false)}>
                            Phạt
                        </Button> */}
                    </div>
                </div>
            )}
            <div className="profile container">
                <div className="info-user">
                    <InfoUser myProfile={userInfoState} showDetail={false} />
                    {(role === 1 || currentId === userId) && (
                        <div className="career-event">
                            <CareerEvent userId={userId} events={events} />
                        </div>
                    )}
                </div>
                {role === 1 || currentId === userId ? (
                    <>
                        <div className="user-feedback">
                            <Feedback userId={userId} feedback={feedbacks} />
                        </div>
                    </>
                ) : null}

                <ChangePointModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    type={type}
                    userId={userId}
                    data={userInfoState}
                    action={true}
                />
            </div>
            <div>
                {role === 1 && email && <Progress email={email} role={role} />}
            </div>
        </>
    );
};

function mapStateToProps(state, props) {
    return {
        userInfoState: state.myProfile.userInfo,
        feedbacks: state.feedback.data,
        events: state.event.data,
    };
}

export default connect(mapStateToProps, {})(UserScreen);
