import { IBasic } from "./basic";
import Config from "./config";
export interface IUserExpectation extends IBasic, IExpectations {
    // chu y dong bo voi schema
    userId: string;
    lastUpdated?: Date;
    sprintId: string;
}

export interface IExpectations {
    doneTasksPercentage: number;
    pointDone: number;
    doneTasksInDeadlinePercentage: number;
    disciplinePoints: number;
}

export class UserExpectation implements IUserExpectation {
    _id: any;
    userId: string;
    doneTasksPercentage: number;
    pointDone: number;
    doneTasksInDeadlinePercentage: number;
    lastUpdated?: Date;
    disciplinePoints: number;
    sprintId: string;
    constructor(props: any = {}) {
        this._id = props._id;
        this.userId = props.userId ?? "";
        this.doneTasksPercentage = props.doneTasksPercentage ?? 80;
        this.pointDone =
            props.pointDone ??
            props.donePointPercentage ??
            Config.DEFAULT_POINT;
        this.doneTasksInDeadlinePercentage =
            props.doneTasksInDeadlinePercentage ?? 60;
        this.lastUpdated = props.lastUpdated ?? null;
        this.disciplinePoints = props.disciplinePoints ?? 100;
        this.sprintId = props.sprintId ?? "";
    }
}
