import { ITaskComment } from "../../shared/models/task";

export enum CommentTypes {
    GET_COMMENT = "GET_COMMENT",
    GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS",
    GET_COMMENT_FAILED = "GET_COMMENT_FAILED",
    UPDATE_COMMENT = "UPDATE_COMMENT",
    UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS",
    UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED",
    DELETE_COMMENT = "DELETE_COMMENT",
    DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED",
}

export interface ICommentAction {
    type: CommentTypes;
    comments?: ITaskComment[];
    error?: any;
    taskId?: string;
    commentIds?: string[];
}

export const getComment = (taskId: string): ICommentAction => {
    return {
        type: CommentTypes.GET_COMMENT,
        taskId,
    };
};

export const getCommentSuccess = (comments: ITaskComment[]): ICommentAction => {
    return {
        type: CommentTypes.GET_COMMENT_SUCCESS,
        comments,
    };
};

export const getCommentFailed = (error: any): ICommentAction => {
    return {
        type: CommentTypes.GET_COMMENT_FAILED,
        error,
    };
};

export const updateComment = (comment: ITaskComment): ICommentAction => {
    return {
        type: CommentTypes.UPDATE_COMMENT,
        comments: [comment],
    };
};

// export const updateComments = (
//     taskId: string,
//     comments: ITaskComment[]
// ): ICommentAction => {
//     return {
//         type: CommentTypes.UPDATE_COMMENT,
//         comments: comments,
//         taskId,
//     };
// };

export const updateCommentSuccess = (
    comments: ITaskComment[]
): ICommentAction => {
    return {
        type: CommentTypes.UPDATE_COMMENT_SUCCESS,
        comments,
    };
};

export const updateCommentFailed = (error: any): ICommentAction => {
    return {
        type: CommentTypes.UPDATE_COMMENT_FAILED,
        error,
    };
};

export const deleteComment = (comment: ITaskComment): ICommentAction => {
    return {
        type: CommentTypes.DELETE_COMMENT,
        comments: [comment],
    };
};

export const deleteCommentSuccess = (commentIds: string[]): ICommentAction => {
    return {
        type: CommentTypes.DELETE_COMMENT_SUCCESS,
        commentIds,
    };
};

export const deleteCommentFailed = (error: any): ICommentAction => {
    return {
        type: CommentTypes.DELETE_COMMENT_FAILED,
        error,
    };
};
