import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IStatus, Status } from "../../shared/models/status";
import Url from "../../util/url";
import { StatusTypes, getAllStatusFailed, getAllStatusSuccess } from "../actions/status.action";

async function getAllStatusAPI(): Promise<IStatus[] | null> {
    return await axios.get(Url("status")).then((data: any) => {
        if(data?.data?.success && data?.data?.response) {
            return data.data.response.map((item: any) => {
                return new Status(item);
            })
        }
        return null;
    });
}

function* getAllStatusSaga() {
    try {
        const status: IStatus[] | null = yield call(getAllStatusAPI);
        if(status) {
            yield put(getAllStatusSuccess(status));
        } else {
            yield put(getAllStatusFailed("getAllStatusSaga status undefined!"));
        }
    } catch (err) {
        yield put(getAllStatusFailed("getAllStatusSaga ERROR: " + err));
    }

}

export function* watchStatus() {
    yield takeLatest(StatusTypes.GET_ALL_STATUS, getAllStatusSaga);
}
