import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ICertificates } from "../../../models/certificates.model";
import { formatDate } from "../../../util";
import EmailFeedbacks from "../../dashboard-analytic/question-quality/email-feedbacks";
import FAQ from "../../dashboard-analytic/question-quality/faq";
import MessengerFeedbacks from "../../dashboard-analytic/question-quality/messenger-feedbacks";
import NPSAndReportMitstakes from "../../dashboard-analytic/question-quality/nps-and-report-mistakes";
import Reviews from "../../dashboard-analytic/question-quality/reviews";
import MenuTab from "../analytics/menu";
import "./index.scss";
const MENU_LIST = [
    //   {
    //     title: "Info",
    //     tab: "info",
    //   },
    {
        title: "NPS",
        tab: "nps",
    },
    {
        title: "Reported Mistakes",
        tab: "reported-mistakes",
    },
    {
        title: "Ratings & Reviews",
        tab: "ratings-reviews",
    },
    {
        title: "Messenger Feedbacks",
        tab: "messenger-feedbacks",
    },
    {
        title: "Email Feedbacks",
        tab: "email-feedbacks",
    },
    {
        title: "FAQ",
        tab: "faq",
    },
];

const QuestionQualityV2 = ({
    certificates,
}: {
    certificates: ICertificates;
}) => {
    const [tab, setTab] = useState("nps");
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);

    let appID = certificates.appID;
    let appIDs = certificates.appInfos?.map((app) => {
        return app.appID;
    });
    return (
        <div className="question-quality-v2">
            <MenuTab tab={tab} setTab={setTab} menuList={MENU_LIST} />
            {tab === "nps" && (
                <NPSAndReportMitstakes
                    showNPS={true}
                    appID={appID}
                    startDate={startTime}
                    endDate={endTime}
                    appIDs={appIDs}
                />
            )}
            {tab === "reported-mistakes" && (
                <NPSAndReportMitstakes
                    showNPS={false}
                    appID={appID}
                    startDate={startTime}
                    endDate={endTime}
                    appIDs={appIDs}
                />
            )}
            {tab === "ratings-reviews" && (
                <Reviews
                    startDate={formatDate(new Date(startTime), "YYYY-MM-DD")}
                    endDate={formatDate(new Date(endTime), "YYYY-MM-DD")}
                    testID={certificates.id}
                />
            )}
            {tab === "messenger-feedbacks" && (
                <MessengerFeedbacks testID={certificates.id} />
            )}
            {tab === "email-feedbacks" && (
                <EmailFeedbacks testID={certificates.id} />
            )}
            {tab === "faq" && (
                <FAQ certificates={certificates}/>
            )}
        </div>
    );
};

export default QuestionQualityV2;
