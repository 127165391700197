import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Constants from "../../../../constant";
import { IUserInfo } from "../../../../shared/models/userInfo";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SelectReporters: React.FC<{
    statusReporters: any;
    userList: IUserInfo[];
    personsReporters: any;
    setPersonsReporters: any;
    teamsReporters: any;
    setTeamsReporters: any;
    channelsReporters: any;
    setChannelsReporters: any;
}> = ({
    statusReporters,
    userList,
    personsReporters,
    setPersonsReporters,
    teamsReporters,
    setTeamsReporters,
    channelsReporters,
    setChannelsReporters,
}) => {
    let defaultTeams = userList.map((user: IUserInfo) => user.teams);
    defaultTeams = defaultTeams.filter(
        (team: any, index: number) => defaultTeams.indexOf(team) === index
    );

    const handleGetNameUser = (userId: any) => {
        let nameUser = "";
        const user = userList.find((user) => user._id === userId);
        if (user) {
            nameUser = `${user.lastName} ${user.firstName}`;
        }
        return nameUser;
    };

    const handleChangePersons = (event: any) => {
        const {
            target: { value },
        } = event;
        setPersonsReporters(value);
    };
    const handleChangeTeams = (event: any) => {
        const {
            target: { value },
        } = event;
        setTeamsReporters(value);
    };
    const handleChangeChannels = (event: any) => {
        const {
            target: { value },
        } = event;
        setChannelsReporters(value);
    };

    return (
        <div>
            {statusReporters === Constants.PERSONS && (
                <FormControl sx={{ m: 2, width: 460 }}>
                    <InputLabel id="multiple-chip-label-persons">
                        {statusReporters}
                    </InputLabel>
                    <Select
                        labelId="multiple-chip-label-persons"
                        id="multiple-chip-persons"
                        multiple
                        value={personsReporters}
                        onChange={handleChangePersons}
                        input={
                            <OutlinedInput
                                id="select-multiple-chip-persons"
                                label={statusReporters}
                            />
                        }
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                }}
                            >
                                {selected.map((value: any, index: number) => (
                                    <Chip
                                        key={index}
                                        label={handleGetNameUser(value)}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {userList.map((user, index) => (
                            <MenuItem key={index} value={user._id}>
                                {handleGetNameUser(user._id)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {statusReporters === Constants.TEAMS && (
                <FormControl sx={{ m: 2, width: 460 }}>
                    <InputLabel id="multiple-chip-label-teams">
                        {statusReporters}
                    </InputLabel>
                    <Select
                        labelId="multiple-chip-label-teams"
                        id="multiple-chip-teams"
                        multiple
                        value={teamsReporters}
                        onChange={handleChangeTeams}
                        input={
                            <OutlinedInput
                                id="select-multiple-chip-teams"
                                label={statusReporters}
                            />
                        }
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                }}
                            >
                                {selected.map((value: any, index: number) => (
                                    <Chip key={index} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {defaultTeams.map((team: any, index: number) => (
                            <MenuItem key={index} value={team}>
                                {team}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {statusReporters === Constants.CHANNELS && (
                <FormControl sx={{ m: 2, width: 460 }}>
                    <InputLabel id="multiple-chip-label-channels">
                        {statusReporters}
                    </InputLabel>
                    <Select
                        labelId="multiple-chip-label-channels"
                        id="multiple-chip-channels"
                        multiple
                        value={channelsReporters}
                        onChange={handleChangeChannels}
                        input={
                            <OutlinedInput
                                id="select-multiple-chip-channels"
                                label={statusReporters}
                            />
                        }
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                }}
                            >
                                {selected.map((value: any, index: number) => (
                                    <Chip key={index} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {Constants.DISCORD_CHANNELS_LIST.map(
                            (channel, index) => (
                                <MenuItem key={index} value={channel}>
                                    {channel}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            )}
        </div>
    );
};

export default SelectReporters;
