import React, { useEffect, useState } from "react";
import {
    ACTIVE_USER,
    AVG_PAGE_USER,
    AVG_SESSION,
    CHURN_RATE,
    DOWNLOAD_USER,
    FIRST_DOWNLOAD_USER,
    MENU_LIST,
    MENU_LIST_WORKSHEET,
    RETENTION,
    STICKINESS,
} from "../../../config_data_analytic";
import { isWebWorksheet } from "../../../config_web";
import { ICertificates } from "../../../models/certificates.model";
import ActiveUserView from "./active-user";
import SessionsView from "./sessions";
import MenuTab from "./menu";
import {
    PagesPerUserView,
    ChurnRateView,
    RetentionView,
    StickinessView,
    TotalDownloadUserView,
    TotalStepToDownloadView,
} from "./retention";

const AnalyticsView = ({ certificates }: { certificates: ICertificates }) => {
    const isWS = isWebWorksheet(certificates.slug);
    const CURRENT_MENU = isWS ? MENU_LIST_WORKSHEET : MENU_LIST;
    const [tab, setTab] = useState(CURRENT_MENU[0].tab);
    useEffect(() => {
        setTab(CURRENT_MENU[0].tab);
    }, [certificates.id]);
    certificates.appInfos?.sort((a, b) => a.platformId - b.platformId);
    return (
        <div className="question-quality-v2">
            <MenuTab tab={tab} setTab={setTab} menuList={CURRENT_MENU} />
            {tab === AVG_SESSION ? (
                <SessionsView certificates={certificates} tab={tab} />
            ) : tab == AVG_PAGE_USER ? (
                <PagesPerUserView certificates={certificates} tab={tab} />
            ) : tab == ACTIVE_USER ? (
                <ActiveUserView certificates={certificates} />
            ) : tab == STICKINESS ? (
                <StickinessView certificates={certificates} tab={tab} />
            ) : tab == CHURN_RATE ? (
                <ChurnRateView certificates={certificates} tab={tab} />
            ) : tab == RETENTION ? (
                <RetentionView certificates={certificates} tab={tab} />
            ) : tab == DOWNLOAD_USER ? (
                <TotalDownloadUserView certificates={certificates} tab={tab} />
            ) : tab == FIRST_DOWNLOAD_USER ? (
                <TotalStepToDownloadView
                    certificates={certificates}
                    tab={tab}
                />
            ) : (
                <div>Comming soon</div>
            )}
        </div>
    );
};

export default AnalyticsView;
