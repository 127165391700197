import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { FC, Fragment, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ISelectFilter } from "../dashboardV2";
import Config from "../../../shared/models/config";
import { IStatus } from "../../../shared/models/status";
import { ITask, Task } from "../../../shared/models/task";
import { IUserInfo } from "../../../shared/models/userInfo";
import Column from "../Column";
import TaskPreview, { getChildTasks } from "../task-preview";
import NewEditTaskModal from "../task-preview/edit-task-modal/new_edit_task";
import TestCycleModalWidget from "../task-preview/test-cycle-modal";
import "./style.scss";
import { hexToRgbA } from "../../../utils";
// import socket from "../../../util/socket";
// import { deleteTaskAction, updateActorChangeTask, updateTaskSuccess } from "../../../redux/actions/task.action";
// import { toast } from "react-toastify";
import Constants from "../../../constant";
// import { setTaskShown } from "../../../redux/actions/task.action";
// import { updateCampaignSuccess } from "../../../redux/actions/campaign.action";
// import { MY_SELF } from "../../../redux/reducers/task.reducer";
import { getTaskAPI } from "../../../redux/sagas/task.saga";

export const checkContainsArray = (arr1: string[], arr2: string[]) => {
    if (!arr1.length || !arr2.length) return false;
    for (let item of arr1) {
        if (arr2.includes(item)) {
            return true;
        }
    }
    return false;
};
export const checkRelatedToTask = (task: ITask, users: string[], allTasks: ITask[]) => {
    let check0 = checkContainsArray(
        [task.assigneeId, task.reporterId, ...task.testerIds, task.leadDevId, ...task.inform],
        users
    );
    //assignee, reporter, inform, tester
    let childTasks = getChildTasks(task, allTasks);
    let check = false;
    if (childTasks.length > 0) {
        for (let t of childTasks) {
            if (!check) {
                check = checkContainsArray(users, [t.assigneeId, t.reporterId, ...t.inform, ...t.testerIds]);
            }
        }
    }
    return check || check0;
};
const DashboardSprintColumnsV2: FC<{
    currentProjectIds: string[];
    currentUserIds: string[];
    showAllTasks: boolean;
    forceCurrentCampaignIds: string[] | null;
    selectedFilter: ISelectFilter;
    setMembersInGroup: string[];
    isShowCheckBox? : boolean;
    handleGetIds? : (listId : string[]) => void
}> = ({ currentProjectIds, currentUserIds, showAllTasks, forceCurrentCampaignIds, selectedFilter, setMembersInGroup,isShowCheckBox,handleGetIds = () => {} }) => {
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const isAdmin = userInfo.role == 1;
    const status: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    const allTasks: ITask[] = useSelector((state: any) => state.taskState.tasks ?? []);
    // const taskShown: ITask | undefined = useSelector((state: any) => state.taskState.taskShown);
    const [taskShown, setTaskShown] = useState<ITask | null>(null);
    const mapTasks: Map<string, ITask[]> = new Map();
    for (let task of allTasks) {
        if (!mapTasks.has(task.statusId)) {
            mapTasks.set(task.statusId, []);
        }
        mapTasks.get(task.statusId)?.push(task);
    }
    const campaignTasks = useSelector((state: any) => state.taskState.campaignTasks);
    // const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement | null>(null);
    // const getListCampaignTasks = () => {
    //     let listTasks: ITask[] = [];
    //     for (let campaignTask of campaignTasks) {
    //         campaignTask.forEach((element: ITask) => {
    //             if (listTasks.find((item) => item._id === element._id)) return;
    //             listTasks.push(element);
    //         });
    //     }
    //     return [];
    // };
    let today = new Date();
    
    const filterTasks = (tasks: ITask[], statusId: string) => {
        if (forceCurrentCampaignIds) {
            let listTasks: ITask[] = [];
            forceCurrentCampaignIds.forEach((id: string) => {
                let campaignTask = campaignTasks[id] ?? [];
                campaignTask.forEach((element: ITask) => {
                    if (listTasks.find((item) => item._id === element._id)) return;
                    if (element.statusId === statusId) listTasks.push(element);
                });
            });
            return listTasks;
        }
        return tasks.filter((task) => {
            if (task.type === "Sub Task" && !showAllTasks) return;
            if (task.statusView !== Config.STATUS_PUBLIC) return;
            
            if (selectedFilter) {
                let listUsers = !currentUserIds.length ? [userInfo._id] : currentUserIds;
                let istaskChange : boolean = false;
                task.histories.forEach((item) => {
                    if((item.to == "616eabdaccb9dac54a200237" || item.to == "616eabf9ccb9dac54a200240") && (today.getDate() == item.time.getDate() && today.getMonth() == item.time.getMonth() && today.getFullYear() == item.time.getFullYear())){
                        istaskChange = true;
                    }
                })
                return (
                    ((selectedFilter === "Reporter" &&
                        (!listUsers.length || checkContainsArray([task.reporterId], listUsers))) ||
                        (selectedFilter === "Tester" && (!listUsers.length || checkContainsArray(task.testerIds, listUsers))) ||
                        (selectedFilter === "Bug" &&
                            task.type === "Bug" &&
                            (!listUsers.length || checkContainsArray(task.testerIds, listUsers))) ||
                        (selectedFilter === "Inform" && (!listUsers.length || checkContainsArray(task.inform, listUsers))) ||
                        (selectedFilter === "Leader" &&
                            (!listUsers.length || checkContainsArray([task.leadDevId], listUsers))) ||
                        // (selectedFilter === "Follower" &&
                        //     (!listUsers.length || checkContainsArray(task.followerIds, listUsers))) ||
                         (selectedFilter === "TestToday" &&
                            (task.status?._id == "616eabdaccb9dac54a200237" || task.status?._id == "616eabf9ccb9dac54a200240") && istaskChange ) ||
                        (selectedFilter === "Assignee" &&
                            (!listUsers.length ||
                                checkContainsArray(
                                    [task.assigneeId, ...getChildTasks(task, allTasks).map((t) => t.assigneeId)],
                                    listUsers
                                )))) &&
                    (!currentProjectIds.length || checkContainsArray([task.projectId], currentProjectIds))
                );
            }
            const checkProject = !currentProjectIds.length || currentProjectIds.includes(task.projectId);

            /*
            - Admin: all tasks
              lead: all tasks related to members in group and themself
              member: all task related to themself
            - Selected: all tasks related to themself
            */
            const checkUser = !currentUserIds.length // don't select users
                ? isAdmin || task.createdUserId == userInfo._id //isAdmin: show all tasks
                    ? true
                    : setMembersInGroup.length // is leader: show tasks which related to members in group and themself
                    ? checkRelatedToTask(task, setMembersInGroup, allTasks)
                    : //is member: show tasks which related to themself
                      checkRelatedToTask(task, [userInfo._id], allTasks)
                : //selected users
                  checkRelatedToTask(task, currentUserIds, allTasks) && // kiem tra xem task nay co lien quan den currentUserIds khong (task cha va task con)
                  (isAdmin
                      ? true
                      : checkRelatedToTask(task, setMembersInGroup, allTasks) || // else: show tasks which related to members in group and selected user
                        task.createdUserId == userInfo._id);
            const checkForceTask = true;
            // !forceCurrentTaskIds || forceCurrentTaskIds.includes(task._id);
            return checkProject && checkUser && checkForceTask;
        });
    };
    const [taskSelected, setTaskSelected] = useState<ITask | null>(null);
    const [openTestCase, setOpenTestCase] = useState<boolean>(false);
    // const [actor, setActor] = useState(MY_SELF);
    // const [taskDeletedId, setTaskDeletedId] = useState("");
    const history = useHistory();
    const onSelectedTask = (task: ITask) => {
        let currentPathname = window.location.pathname;
        if (currentPathname === "/") {
            let pathname = "/task/" + task._id;
            history.push({ pathname: pathname });
        } else {
            history.push(`?taskID=${task._id}`);
        }
        if (!!forceCurrentCampaignIds?.length)
            history.push(`?${Constants.SHOW_CAMPAIGN_TASKS}=${forceCurrentCampaignIds.join(",")}`);
        // dispatch(setTaskShown(task));
        setTaskShown(task);
    };
    const params: any = useParams();
    const taskID = params?.taskID ?? "";
    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get("taskID");
    useEffect(() => {
        let _taskId = taskId ?? taskID ?? "";
        // console.log(_taskId, "-", taskID, "-", taskId, taskID ?? taskId);
        if (_taskId) {
            getTaskAPI(_taskId)
                .then((task) => {
                    // setSprintId(task?.sprintId ?? "");
                    if (task) {
                        // dispatch(setTaskShown(task));
                        setTaskShown(task);
                    }
                })
                .catch((e) => {
                    // setSprintId("");
                    console.log("getTaskAPI err:", e);
                });
        } else {
            // setSprintId("");
        }
    }, [taskID, taskId]);
    const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
    // useEffect(() => {
    //     if (taskID) {
    //         let task = allTasks.find((task) => task._id == taskID);
    //         if (!task) task && setTaskSelected(task);
    //     }
    // }, [taskID]);

    // useEffect(() => {
    //     const [taskIdListener, deletedBy] = taskDeletedId.split(":");
    //     // console.log(
    //     //     taskIdListener,
    //     //     deletedBy,
    //     //     taskIdListener === taskID || taskIdListener === taskId,
    //     //     taskId,
    //     //     taskID
    //     // );
    //     if (taskIdListener && deletedBy && (taskIdListener === taskID || taskIdListener === taskId)) {
    //         setTaskSelected(null);
    //         toast(() => <div>Task đã bị xoá!</div>, {
    //             position: "top-center",
    //             type: "warning",
    //         });
    //         history.push(window.location.pathname);
    //     }
    //     // }
    //     dispatch(deleteTaskAction(taskIdListener));
    // }, [taskDeletedId]);
    // useEffect(() => {
    // socket.on("task-changed-listener", (args: any) => {
    //     let { task, userId } = args;
    //     // if (!!userId && !!task && userId !== userInfo._id) {
    //     let t = new Task(task);
    //     dispatch(
    //         updateActorChangeTask(
    //             userId === userInfo._id ? MY_SELF : userId
    //         )
    //     );
    //     dispatch(updateTaskSuccess(t));
    //     // }
    // });
    // socket.on("task-deleted", (args: any) => {
    //     let { taskId, deletedBy } = args;
    //     setTaskDeletedId(taskId + ":" + deletedBy);
    // });
    // socket.on("updated-campaign", (args: any) => {
    //     let { campaign } = args;
    //     if (campaign) dispatch(updateCampaignSuccess([campaign]));
    // });
    // }, []);
    useEffect(() => {
        if (taskShown) {
            setTaskSelected(taskShown);
        }
    }, [taskShown?._id]);
    const handleCheckboxChange = (taskId: string) =>{
    
        if(selectedTaskIds.includes(taskId)){
            setSelectedTaskIds(selectedTaskIds.filter((s) => s !== taskId))
        }
        else{
            setSelectedTaskIds([...selectedTaskIds,taskId])
        }
    };
    
    useEffect(() => {
        setSelectedTaskIds([])
    },[!isShowCheckBox])
    
    handleGetIds(isShowCheckBox ? selectedTaskIds : []);
        return (
        <div className="dashboard-sprint-columns-parent">
            <IconButton
                className="button-scroll left"
                onClick={() => ref.current?.scroll({ behavior: "smooth", left: 0 })}
                disableRipple
            >
                <ArrowBackIosIcon htmlColor="white" />
            </IconButton>
            <div className="status-bar"></div>
            <div className="dashboard-sprint-columns" ref={ref}>
                {status
                    .filter((s) => {
                        if (
                            [
                                Config.COLUMN_STATUS.TO_DO.mongoId,
                                Config.COLUMN_STATUS.IN_PROGRESS.mongoId,
                                Config.COLUMN_STATUS.QA_TESTING.mongoId,
                                Config.COLUMN_STATUS.DONE.mongoId,
                            ].includes(s._id) ||
                            isAdmin
                        )
                            return true;

                        const _tasks = mapTasks.get(s._id) ?? [];
                        let index = _tasks.findIndex((task) => {
                            let res = checkContainsArray(
                                [task.reporterId, task.assigneeId, task.leadDevId, ...task.testerIds, ...task.inform],
                                [...currentUserIds, userInfo._id]
                            );
                            return res;
                        });
                        return ["technical", "manager", "hr"].includes(userInfo.teams) || index > -1;
                    })
                    .map((status, index) => {
                        const _tasks = filterTasks(mapTasks.get(status._id) ?? [], status._id)
                            .map((task) => {
                                //prepare for sort task by change-time
                                let orderTime = -1;
                                if (!!task.createdTime) {
                                    // co createdTime
                                    let t = new Date(task.createdTime).getTime();
                                    if (task.histories.length) {
                                        let t1 = new Date(task.histories[0].time).getTime();
                                        orderTime = t1 > t ? t1 : t;
                                    } else orderTime = t;
                                } else {
                                    if (task.histories.length) {
                                        let t1 = new Date(task.histories[0].time).getTime();
                                        orderTime = t1;
                                    }
                                }
                                if (!task.priority) task.priority = "Lowest";
                                return { ...task, orderTime: orderTime };
                            })
                            .sort((a, b) => {
                                return b.orderTime - a.orderTime;
                            })
                            .sort((a, b) => {
                                let priorityPool = Object.values(Config.PRIORITY_POOL);
                                let orderA = priorityPool.findIndex((p) => p.toLowerCase() === a.priority.toLowerCase());
                                let orderB = priorityPool.findIndex((p) => p.toLowerCase() === b.priority.toLowerCase());
                                if (orderA < 0) orderA = 6;
                                if (orderB < 0) orderB = 6;
                                return orderA - orderB;
                            });
                        return (
                            <Column key={index} columnId={status._id}>
                                <div className="box-status">
                                    <StatusV2
                                        status={status}
                                        number={_tasks.filter((t) => t.type !== "Sub Task" || showAllTasks).length}
                                    />
                                </div>
                                {_tasks.map(
                                    (task) =>
                                        (task.type !== "Sub Task" || showAllTasks) && (
                                            <Fragment key={task._id}>
                                                <TaskPreview
                                                    task={task}
                                                    onSelectedTask={(t) => onSelectedTask(t)}
                                                    showAllTasks={showAllTasks}
                                                    handleCheckboxChange={handleCheckboxChange}
                                                    selectedTaskIds={selectedTaskIds}
                                                    isShowCheckBox={isShowCheckBox}
                                                />
                                            </Fragment>
                                        )
                                )}
                            </Column>
                        );
                    })
                    .filter((c) => !!c)}
            </div>
            <IconButton
                className="button-scroll right"
                disableRipple
                onClick={() =>
                    ref.current?.scroll({
                        behavior: "smooth",
                        left: ref.current?.clientWidth ?? 1000,
                    })
                }
            >
                <ArrowForwardIosIcon htmlColor="white" />
            </IconButton>
            {taskSelected && !openTestCase && (
                <NewEditTaskModal
                    onOpenTestCase={() => {
                        setOpenTestCase(true);
                        // if (taskID) {
                        //     //task/id....
                        //     history.push({ pathname: "/" });
                        // } else history.push(window.location.pathname); // /.../?taskID=...
                    }}
                    onHide={() => {
                        setTaskSelected(null);
                        // dispatch(setTaskShown());
                        setTaskShown(null);
                        let id = taskID ?? taskId ?? "";
                        if (taskID) {
                            //task/id....
                            const task = allTasks.find((task) => task._id == id);
                            let pathname = "/";
                            if (task?.sprint?.shortId && !task?.sprint.activeSprint) {
                                pathname = "/sprint/" + task?.sprint?.shortId;
                            }
                            history.push({ pathname: pathname });
                        } else if (taskId) {
                            // /.../?taskID=...
                            history.push(window.location.pathname);
                        }
                        if (forceCurrentCampaignIds?.length)
                            history.push(`?${Constants.SHOW_CAMPAIGN_TASKS}=${forceCurrentCampaignIds.join(",")}`);
                    }}
                    task={allTasks.find((t) => t._id == taskSelected._id) ?? taskSelected} //taskSelected chi dc thay doi khi chon task khac, nen khong nhan biet duoc noi dung thay doi ben trong no
                />
            )}
            {taskSelected && openTestCase && (
                <TestCycleModalWidget
                    task={taskSelected}
                    open={openTestCase}
                    handleClose={() => {
                        setOpenTestCase(false);
                        // setTaskSelected(null);
                    }}
                />
            )}
        </div>
    );
};

export default memo(DashboardSprintColumnsV2, (prev, next) => {
    if (
        prev.currentProjectIds.length != next.currentProjectIds.length ||
        prev.currentUserIds.length != next.currentUserIds.length ||
        prev.showAllTasks != next.showAllTasks ||
        prev.forceCurrentCampaignIds?.length != next.forceCurrentCampaignIds?.length ||
        JSON.stringify(prev.selectedFilter).localeCompare(JSON.stringify(next.selectedFilter)) != 0 ||
        prev.setMembersInGroup.length != next.setMembersInGroup.length ||
        prev.isShowCheckBox != next.isShowCheckBox
    ) {
        return false;
    }
    return true;
});

const StatusV2: FC<{ status: IStatus; number: number }> = ({ status, number }) => {
    const { title, color } = status;
    return (
        <>
            <div className="status-v2 white align-items-center justify-content-center">
                {title}
                <div
                    className="align-items-center justify-content-center number-of-tasks"
                    style={{
                        color: color,
                        background: hexToRgbA(color, 0.2),
                    }}
                >
                    {number}
                </div>
            </div>
        </>
    );
};
