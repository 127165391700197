import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import Url from "../../util/url";
import {
    ISharingAction,
    SharingTypes,
    getSharingScheduleSuccess,
    createSharingScheduleSuccess,
    deleteSharingScheduleSuccess,
    getUserDataSharingSuccess,
    addScoreSharingSuccess,
} from "../actions/sharing.action";

// viet rieng file API

async function getAllSharingSchedule() {
    const { data: response } = await axios.get(Url("sharing/get-sharing"));
    return response;
}
function* getAllSharingScheduleSaga() {
    const data: { response: [] } = yield call(getAllSharingSchedule);
    if ({ data: { response: [] } }) {
        yield put(getSharingScheduleSuccess(data.response));
    }
}
async function createSharingAPI(sharingData: any) {
    return await axios.post(Url("sharing/create"), sharingData);
}

async function updateSharingAPI(sharingData: any, id: any) {
    return await axios.patch(Url("sharing/" + id), sharingData);
}

function* createSharingSaga(action: ISharingAction) {
    const sharingData = action.sharingData;

    try {
        if (action.id) {
            const { data: response } = yield call(updateSharingAPI, sharingData, action.id);
            if (response) {
                yield put(createSharingScheduleSuccess(sharingData, action.id));
            }
        } else {
            const { data: response } = yield call(createSharingAPI, sharingData);

            if (response) {
                yield put(createSharingScheduleSuccess(response));
            }
        }
    } catch (error) {
        console.error(error);
    }
}

async function deleteSharingAPI(sharingID: any, sharingData: any) {
    return await axios.patch(Url("sharing/" + sharingID), sharingData);
}

function* deleteSharingSaga(action: ISharingAction) {
    const sharingData = action.sharingData;

    try {
        const { data: response } = yield call(deleteSharingAPI, action.id, sharingData);
        if (response) {
            yield put(deleteSharingScheduleSuccess(action.id, sharingData));
        }
    } catch (error) {
        console.error(error);
    }
}

async function getUserDataSharingAPI() {
    const { data: response } = await axios.get(Url("user/userFilter"));
    return response;
}

function* getUserDataSharingSaga() {
    try {
        const data: { response: [] } = yield call(getUserDataSharingAPI);
        yield put(getUserDataSharingSuccess(data.response));
    } catch (error) {
        console.error(error);
    }
}

async function addScoreSharingAPI(id: string, status: any) {
    return await axios.patch(Url("user/update-score-sharing"), {
        id,
        status,
    });
}

function* addScoreSharingSaga(action: ISharingAction) {
    try {
        const { data: response } = yield call(addScoreSharingAPI, action.id, action.userData);
        if (response) {
            yield put(addScoreSharingSuccess(action.id, action.userData));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetSharing() {
    yield takeLatest(SharingTypes.GET_SHARING, getAllSharingScheduleSaga);
    yield takeLatest(SharingTypes.CREATE_SHARING, createSharingSaga);
    yield takeLatest(SharingTypes.DELETE_SHARING, deleteSharingSaga);
    yield takeLatest(SharingTypes.GET_USER_DATA_SHARING, getUserDataSharingSaga);
    yield takeLatest(SharingTypes.ADD_SCORE_SHARING, addScoreSharingSaga);
}
