import {
    EditOutlined,
    DeleteOutlined,
    PlaylistAddCheck,
    Add,
} from "@material-ui/icons";
import axios from "axios";
import {
    Button,
    FormHelperText,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import Row from "../Row";
import "./style.scss";
import Url from "../../../../../../util/url";
import { useDispatch } from "react-redux";
import { FC, useState } from "react";
import DeleteDialog from "../../components/DeleteDialog";
import Constants from "../../../../../../constant";
import { ITestCase } from "../../../../../../shared/models/testCase";
import { ITestCycle } from "../../../../../../shared/models/testCycle";
import { ITestSection } from "../../../../../../shared/models/testSection";
import { addTest, updateTests } from "../../../../../../redux/actions/testCase.action";
import { deleteTestSection } from "../../../../../../redux/actions/testSection.action";

const TableSection: FC<{
    tests: ITestCase[];
    handleClick: any;
    permission: boolean;
    cycles: ITestCycle[];
    section: ITestSection;
    editSection: any;
    handleClickNewCase: any;
    taskId: string;
    lastIndex: number;
}> = ({
    tests,
    handleClick,
    permission,
    cycles,
    section,
    editSection,
    handleClickNewCase,
    taskId,
    lastIndex,
}) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddFastNewCase, setOpenAddFastNewCase] = useState(false);
    const [titleTestCase, setTitleTestCase] = useState("");
    const dispatch = useDispatch();

    const handleDelete = async () => {
        try {
            await axios.delete(Url("test-case/deletes-test-sections"), {
                data: [section._id],
            });
            if (tests && tests.length > 0) {
                const tempTests = tests.map((item) => ({
                    ...item,
                    sectionID: "other",
                }));
                await axios.post(
                    Url("test-case/updates-test-cases"),
                    tempTests
                );
                dispatch(updateTests(tempTests));
            }
            dispatch(deleteTestSection(section));
            setOpenDeleteModal(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleAddNewCase = async (e: any) => {
        e.preventDefault();
        if (titleTestCase === "") return;
        const { _id } = JSON.parse(localStorage.getItem("userInfo") || "");
        let newTest = {
            title: titleTestCase,
            priority: Constants.MEDIUM,
            sectionID: section._id ? section._id : "other",
            steps: "",
            expectedResult: "",
            note: "",
            taskID: taskId,
            userID: _id,
            lastUpdate: new Date().getTime(),
            testIndex: lastIndex,
        };

        try {
            const { data } = await axios.post(
                Url("test-case/inserts-test-cases"),
                [newTest]
            );
            dispatch(addTest(data[0]));
            setTitleTestCase("");
        } catch (error) {
            // console.log(error);
        }
    };

    return (
        <div className="section-container">
            <div className="section-header">
                <div>
                    <div className="section-title">
                        <h4
                            className="title"
                            aria-describedby="component-helper-text"
                        >
                            {section.name}
                        </h4>
                        <span className="number-tests">
                            {tests ? tests.length : 0}
                        </span>
                        <IconButton
                            size="small"
                            color="info"
                            onClick={() => editSection(section)}
                            component="span"
                            disabled={section.name === "Other" ? true : false}
                        >
                            <EditOutlined fontSize="small" />
                        </IconButton>
                        <IconButton
                            color="error"
                            onClick={() => setOpenDeleteModal(true)}
                            component="span"
                            disabled={!permission}
                        >
                            <DeleteOutlined />
                        </IconButton>
                    </div>
                    <FormHelperText id="component-helper-text">
                        {section.description}
                    </FormHelperText>
                </div>
                <div>
                    <Button
                        variant="outlined"
                        className="btn-new-case"
                        onClick={() =>
                            setOpenAddFastNewCase(!openAddFastNewCase)
                        }
                        startIcon={<PlaylistAddCheck />}
                    >
                        Add quick
                    </Button>
                    <Button
                        variant="outlined"
                        className="btn-new-case"
                        onClick={() => handleClickNewCase(section)}
                        startIcon={<Add />}
                    >
                        New Case
                    </Button>
                </div>
            </div>
            <TableContainer component={Paper} className="section-content">
                <Table>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell style={{ width: "10%" }}>ID</TableCell>
                            <TableCell style={{ width: "55%" }}>
                                Title
                            </TableCell>
                            <TableCell align="center" style={{ width: "15%" }}>
                                Priority
                            </TableCell>
                            <TableCell style={{ width: "20%" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                        {tests?.map((test, index) => (
                            <Row
                                key={index}
                                row={test}
                                handleClick={handleClick}
                                permission={permission}
                                cycles={cycles}
                            />
                        ))}
                        {openAddFastNewCase && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <form onSubmit={(e) => handleAddNewCase(e)}>
                                        <TextField
                                            value={titleTestCase}
                                            onChange={(e) =>
                                                setTitleTestCase(e.target.value)
                                            }
                                            placeholder="Add new case"
                                            type="text"
                                            variant="outlined"
                                            // inputProps={{readOnly: !permission}}
                                            size="small"
                                            fullWidth
                                            sx={{
                                                "& label.Mui-focused": {
                                                    display: "none",
                                                },
                                                "& legend": {
                                                    display: "none",
                                                },
                                            }}
                                            autoFocus={true}
                                        />
                                        <div className="btn-fast-new-case">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="btn"
                                            >
                                                Add
                                            </Button>
                                            <Button
                                                variant="text"
                                                className="btn"
                                                onClick={() =>
                                                    setOpenAddFastNewCase(false)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </form>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <DeleteDialog
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default TableSection;
