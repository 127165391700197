import { FormControl, Input, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ANALYTICS_DATA,
    BACK,
    TYPE_ANALYTIC_AB_TEST,
    TYPE_ANALYTIC_DAILY_COUNT,
    TYPE_ANALYTIC_INDEX_PROGRESS,
    TYPE_ANALYTIC_KEYWORD_PERFORMANCE,
    TYPE_ANALYTIC_TRENDING_KEYWORD,
    TYPE_REVENUE,
} from "../../../config_data_analytic";
import { Certificates, ICertificates } from "../../../models/certificates.model";
import { removeCertificateAction, setCurrentCertificatesAction } from "../../../redux/actions/dashboard.action";
import { getAbtestBySlugAPI } from "../../../services/traffic.service";
import { hasRevenue, isAdmin, isPartner } from "../../../util";
import DateRangView from "../../common/date-rang";
import CloseIconSvg from "../../common/icon/CloseIcon";
import EyesIconSvg from "../../common/icon/EyesIcon";
import MultiSquareSvg from "../../common/icon/MultiSquare";
import SearchIconSvg from "../../common/icon/SearchIcon";
import LeftMenu from "../left-menu";
import "./index.scss";
import { isWebWorksheet } from "../../../config_web";
const CertificateSelect = ({
    showCompareRevenue,
    setShowCompareRevenue,
    listcertificate,
}: {
    showCompareRevenue: boolean;
    setShowCompareRevenue: Function;
    listcertificate: ICertificates[];
}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.authState.user);

    const currentCertificatesTypeLocal = useSelector((state: any) => state.dashBoardState.typeInfo);
    const currentCertificates: ICertificates = useSelector((state: any) => state.dashBoardState.currentCertificates);

    let certificatesSelect: ICertificates[] = useSelector((state: any) => state.dashBoardState.certificatesSelect);
    // let listcertificate: ICertificates[] = useSelector(
    //     (state: any) => state.dashBoardState.certificates
    // );
    const [certificatesConvert, setCertificatesConvert] = useState<ICertificates[]>([]);
    const [searchText, setSearchText] = useState("");
    const [showTabAbTest, setShowTabAbTest] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        const getData = async () => {
            const slug = currentCertificates.slug;
            const response = await getAbtestBySlugAPI(slug);
            if (response.length > 0) {
                setShowTabAbTest(true);
            }
        };
        getData();
    }, [currentCertificates.id]);
    useEffect(() => {
        setCertificatesConvert(listcertificate);
    }, [listcertificate]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setCertificatesConvert(listcertificate);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeText = (e: any) => {
        const value = e.target.value;
        listcertificate = listcertificate.filter((item: ICertificates) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        setCertificatesConvert(listcertificate);
        setSearchText(value);
    };
    certificatesConvert.sort(function (a: ICertificates, b: ICertificates) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return (
        <div className="nav">
            <div className="nav-left">
                <div className="certificate-qty" onClick={(event: any) => handleClick(event)}>
                    <MultiSquareSvg />
                    <div>
                        <p>{listcertificate.length} Certificate </p>
                    </div>
                </div>
                <Menu id="left-menu-drop" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem>
                        <div className="left-menu">
                            <div className="top-left-menu">
                                <div
                                    className={"compare" + (showCompareRevenue ? " active" : "")}
                                    onClick={() => setShowCompareRevenue(!showCompareRevenue)}
                                >
                                    <EyesIconSvg lightEye={showCompareRevenue} />
                                    <p>Compare</p>
                                </div>
                                <div className="form-search">
                                    <FormControl variant="standard">
                                        <Input
                                            placeholder="Search on certificate"
                                            className="input-with-icon-adornment"
                                            endAdornment={<SearchIconSvg />}
                                            onChange={(event: any) => handleChangeText(event)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <LeftMenu
                                listcertificate={certificatesConvert}
                                setShowAppInfo={(value: ICertificates, type: string) => {
                                    dispatch(setCurrentCertificatesAction(value, type));
                                    localStorage.setItem("currentCertificatesId", JSON.stringify(value.id));
                                    localStorage.setItem("currentCertificatesType", type);
                                    handleClose();
                                }}
                                showCompareRevenue={showCompareRevenue}
                            />
                        </div>
                    </MenuItem>
                </Menu>

                {
                    <div className="list-apps-select">
                        {certificatesSelect.length > 0 &&
                            certificatesSelect?.map((certificate: ICertificates, index: number) => {
                                return (
                                    <AppSelectNav
                                        key={index}
                                        certificate={certificate}
                                        index={index}
                                        showCompareRevenue={showCompareRevenue}
                                        setShowCompareRevenue={(x: any) => setShowCompareRevenue(x)}
                                    />
                                );
                            })}
                    </div>
                }
                {!showCompareRevenue && currentCertificatesTypeLocal != BACK && (
                    <div className="list-apps-select">
                        {currentCertificates.id && (
                            <AppSelectNav
                                certificate={currentCertificates}
                                setShowCompareRevenue={(x: any) => setShowCompareRevenue(x)}
                            />
                        )}
                    </div>
                )}
            </div>
            {/* <div className="nav-right"> */}
            {currentCertificatesTypeLocal != BACK && (
                <div className="tab-nav">
                    {ANALYTICS_DATA.map((data: any, index: number) => {
                        if (
                            data.type === TYPE_REVENUE &&
                            ((!isAdmin(userInfo) &&
                                hasRevenue(currentCertificates.name.toLowerCase()) &&
                                !isPartner(userInfo)) ||
                                isWebWorksheet(currentCertificates.slug))
                        ) {
                            return null;
                        }
                        if (data.type == TYPE_ANALYTIC_AB_TEST && (!showTabAbTest || isPartner(userInfo))) {
                            return null;
                        }
                        if (
                            (!isWebWorksheet(currentCertificates.slug) &&
                                (data.type === TYPE_ANALYTIC_TRENDING_KEYWORD || data.type === TYPE_ANALYTIC_INDEX_PROGRESS)) ||
                            (!isWebWorksheet(currentCertificates.slug) && data.type === TYPE_ANALYTIC_KEYWORD_PERFORMANCE) ||
                            (!isWebWorksheet(currentCertificates.slug) && data.type === TYPE_ANALYTIC_DAILY_COUNT)
                        ) {
                            return null;
                        }
                        return (
                            <div
                                className={"tab-nav-item " + (data.type === currentCertificatesTypeLocal ? " active" : "")}
                                key={index + "-" + data.type}
                                onClick={() => {
                                    dispatch(setCurrentCertificatesAction(currentCertificates, data.type));
                                    localStorage.setItem("currentCertificatesType", data.type);
                                }}
                            >
                                <p>{data.name}</p>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="date">
                <DateRangView />
            </div>
            {/* </div> */}
        </div>
    );
};

const AppSelectNav = ({
    certificate,
    index,
    showCompareRevenue,
    setShowCompareRevenue,
}: {
    certificate: ICertificates;
    index?: number;
    showCompareRevenue?: boolean;
    setShowCompareRevenue: Function;
}) => {
    const dispatch = useDispatch();
    let certificatesSelect: ICertificates[] = useSelector((state: any) => state.dashBoardState.certificatesSelect);
    const removeApp = (certificate: ICertificates, certificatesSelect: ICertificates[]) => {
        if (certificatesSelect.length == 1) {
            setShowCompareRevenue(false);
            localStorage.setItem("currentCertificatesId", JSON.stringify(null));
        }
        dispatch(removeCertificateAction(certificate));
    };
    const a = () => {
        let certificate = new Certificates();
        dispatch(setCurrentCertificatesAction(certificate, BACK));
    };
    return (
        <div className="app-select" key={index}>
            <div>
                <img width="24px" height={"24px"} src={certificate.logo} alt="logo" />
                <p>{certificate.name.toUpperCase()}</p>
            </div>

            <div
                className="close-icon"
                onClick={() => {
                    showCompareRevenue ? removeApp(certificate, certificatesSelect) : a();
                }}
            >
                <CloseIconSvg />
            </div>
        </div>
    );
};
export default CertificateSelect;
