import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ICertificates } from "../../models/certificates.model";
import { getInfoCertificateAllAppAction, unsetCompareAction } from "../../redux/actions/dashboard.action";
import { isPartner } from "../../util";
import CertificateSelect from "./certificate-select";
import CompareComponent from "./compare";
import "./index.scss";
import RightContentPanel from "./right-content-panel";

const DashBoardAnalyticV2 = () => {
    const dispatch = useDispatch();
    const [showCompareRevenue, setShowCompareRevenue] = useState(false);
    const listcertificate: ICertificates[] = useSelector((state: any) => state.dashBoardState.certificates);

    const userCertificates = useSelector((state: any) => state.authState.user?.listCertificates) ?? [];

    const userInfo = useSelector((state: any) => state.authState.user);

    const getListCertificates = (listUserCertificates: any[], listCertificates: ICertificates[]) => {
        let list: ICertificates[] = [];
        listUserCertificates.forEach((uc) => {
            listCertificates.forEach((c) => {
                if (c.slug === uc.slug) {
                    list.push(c);
                }
            });
        });
        return list;
    };

    let listUserCertificates = userCertificates && listcertificate && getListCertificates(userCertificates, listcertificate);

    useEffect(() => {
        dispatch(getInfoCertificateAllAppAction());
    }, []);

    useEffect(() => {
        if (!showCompareRevenue) {
            dispatch(unsetCompareAction());
        }
    }, [showCompareRevenue]);

    return (
        <>
            <Container fluid className="main-dashboard-data-analytic-v2">
                {listcertificate?.length > 0 && (
                    <Row className="content-container">
                        <CertificateSelect
                            showCompareRevenue={showCompareRevenue}
                            setShowCompareRevenue={(value: boolean) => setShowCompareRevenue(value)}
                            listcertificate={isPartner(userInfo) ? listUserCertificates : listcertificate}
                        />
                        <div className="content">{showCompareRevenue ? <CompareComponent /> : <RightContentPanel />}</div>
                    </Row>
                )}
            </Container>
        </>
    );
};

export default DashBoardAnalyticV2;
