import { CyclesTypes, ICyclesAction } from "../actions/testCycle.action";

export interface ICyclesState {
    loading: boolean;
    error: any;
    data: any;
}

const initState: ICyclesState = {
    loading: false,
    error: false,
    data: [],
};

const cyclesReducer = (state = initState, action: ICyclesAction) => {
    switch (action.type) {
        case CyclesTypes.GET_CYCLES:
            return {
                ...state,
                loading: true,
            };
        case CyclesTypes.GET_CYCLES_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        case CyclesTypes.GET_CYCLES_FAILED:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case CyclesTypes.ADD_CYCLE:
            return {
                ...state,
                data: [...state.data, action.data],
            };
        case CyclesTypes.UPDATE_CYCLE:
            const index = state.data.findIndex(
                (cycle: any) => cycle._id === action.data._id
            );
            const tempArr = [...state.data];
            tempArr.splice(index, 1, action.data);
            return {
                ...state,
                data: tempArr,
            };
        case CyclesTypes.UPDATE_CYCLES:
            const tempArrCycle = state.data.map((item: any) => {
                if (item.taskID === action.data[0].taskID) {
                    let cycle = action.data.find(
                        (cycle: any) => cycle._id === item._id
                    );
                    if (cycle) {
                        return cycle;
                    }
                }

                return item;
            });
            return {
                ...state,
                data: tempArrCycle,
            };

        case CyclesTypes.DELETE_CYCLE:
            const newData = state.data.filter(
                (cycle: any) => cycle._id !== action.data
            );
            return {
                ...state,
                data: newData,
            };

        default:
            return state;
    }
};

export default cyclesReducer;
