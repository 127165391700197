import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    mergeData,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../../config_data_analytic";
import Constants from "../../../../../constant";
import { ICertificates } from "../../../../../models/certificates.model";
import { getUserGrowthRateAPI } from "../../../../../services/traffic.service";
import {
    formatCurrency,
    formatDate,
    hasAppReactNative,
} from "../../../../../util";
import CircularIndeterminate from "../../../../common/icon/Loading";
import CustomLineChart from "../../../charts";
import PlatformTemplate from "../../platform-template";
import "./index.scss";
const UserGrowthRate = ({ certificates }: { certificates: ICertificates }) => {
    return (
        <div className="user-growth-rate">
            <div className="list-chart">
                {/* <UserGrowthRateView
                    platformIds={[Constants.PLATFORM_WEB]}
                    certificates={certificates}
                /> */}

                <UserGrowthRateView
                    platformIds={[
                        Constants.PLATFORM_FLUTTER_ANDROID,
                        Constants.PLATFORM_FLUTTER_IOS,
                    ]}
                    certificates={certificates}
                />
                {hasAppReactNative(certificates) && (
                    <UserGrowthRateView
                        platformIds={[
                            Constants.PLATFORM_REACT_NATIVE_ANDROID,
                            Constants.PLATFORM_REACT_NATIVE_IOS,
                        ]}
                        certificates={certificates}
                    />
                )}
            </div>
        </div>
    );
};
const UserGrowthRateView = ({
    platformIds,
    certificates,
}: {
    platformIds: number[];
    certificates: ICertificates;
}) => {
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [appValues, setAppValues] = useState(null);
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    useEffect(() => {
        async function getDataFC() {
            setAppValues(null);
            let apps = certificates.appInfos
                ?.filter((app) => platformIds.includes(app.platformId))
                ?.map((app) => {
                    // return { type: app.platformId, streamId: 1541835138 + "" };

                    return { type: app.platformId, streamId: app.gaID + "" };
                });
            if (apps?.length) {
                let resData = await getUserGrowthRateAPI(
                    apps,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );
                const result: any = {};
                Object.keys(resData).forEach((key) => {
                    const a = resData[key]?.map((item: any) => {
                        const key = item.date;
                        const value = item.total;
                        return [key, value];
                    });

                    const obj = Object.fromEntries(a);
                    result[key] = obj;
                });

                setAppValues(result);
            } else {
                let obj: any = {};
                setAppValues(obj);
            }
        }
        getDataFC();
    }, [startTime, endTime, certificates.id]);
    let newPlatform: number[] = [...platformIds];
    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    let newData = mergeData(appValues, newPlatform);

    const total = newData
        ? newData.reduce((acc, data) => {
              return acc + data.value;
          }, 0)
        : 0;
    return (
        <div className="col">
            {appValues ? (
                Object.keys(appValues).length > 0 ? (
                    <>
                        <PlatformTemplate
                            platformIds={platformIds}
                            optionValue={optionValue}
                            setOptionValue={(value: any) =>
                                setOptionValue(value)
                            }
                        />
                        <div className="total">
                            Total <span>{formatCurrency(total)}</span>
                        </div>
                        <CustomLineChart data={newData} dataKeyX="date" />
                    </>
                ) : (
                    <></>
                )
            ) : (
                <CircularIndeterminate />
            )}
        </div>
    );
};
export default UserGrowthRate;
