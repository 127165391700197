import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUserInfo, updateUserInfo } from "../../redux/actions/userInfo.action";
import { IAuthState } from "../../redux/reducers/auth.reducer";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
import Config from "../../shared/models/config";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import ErrorWidget from "../ErrorWidget";
import LoadingWidget from "../LoadingWidget";
import UserAvatar from "../user/UserAvatar";
import EditUserDialog from "./EditUserDialog";
import UserCoefficientDialog from "./UserCoefficientDialog";
import UserExpectationDialog from "./UserExpectationDialog";
import { getAllUserGroup } from "../../redux/actions/userGroup.action";
import { IUserGroupState } from "../../redux/reducers/userGroup.reducer";
import { IUserGroup } from "../../shared/models/userGroup";
const UserManagement = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUserInfo());
        dispatch(getAllUserGroup());
    }, []);

    const userGroupState: IUserGroupState = useSelector((state: any) => state.userGroupState);

    const userInfoState: IUserInfoState = useSelector((state: any) => state.userInfoState);
    const authState: IAuthState = useSelector((state: any) => state.authState);
    const userInfo = authState.user;
    if (userInfoState.loading) {
        return <LoadingWidget />;
    }
    if (userInfoState.error) {
        return <ErrorWidget error={userInfoState.error} />;
    }
    if (userInfo == null || userInfo.role != Config.USER_ROLE_ADMIN) {
        return <div>You Do Not Have Permission!</div>;
    }
    return <View userInfos={userInfoState.userInfos} userGroup={userGroupState.userGroups} />;
};

const View: FC<{
    userInfos: IUserInfo[];
    userGroup: IUserGroup[];
}> = ({ userInfos, userGroup }) => {
    const [userDelete, setUserDelete] = useState<IUserInfo | null>(null);
    const [userEditor, setUserEditor] = useState<IUserInfo | null>(null);
    const dispatch = useDispatch();
    const onUpdate = (newUserInfo: IUserInfo) => {
        dispatch(updateUserInfo([newUserInfo]));
    };
    return (
        <div
            style={{
                padding: "0 20px",
            }}
            className="new-dashboard justify-content-center"
        >
            <div
                style={{
                    maxWidth: "1200px",
                    width: "100%",
                    marginBottom: "60px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton
                        title="Add User"
                        onClick={() => {
                            setUserEditor(new UserInfo());
                        }}
                    >
                        <FontAwesomeIcon icon={faUserPlus as IconProp} />
                    </IconButton>
                </div>

                <List
                    sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        borderRadius: "12px",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {userInfos
                        .filter((a: IUserInfo) => a.status !== Config.STATUS_DELETED)
                        .sort((a, b) => {
                            let fullNameA = a.firstName + " " + a.lastName;
                            let fullNameB = b.firstName + " " + b.lastName;
                            return fullNameA.localeCompare(fullNameB);
                        })
                        .map((userInfo) => {
                            return (
                                <Item
                                    key={userInfo._id}
                                    userInfo={userInfo}
                                    onDelete={(user) => setUserDelete(user)}
                                    onEdit={(user) => setUserEditor(user)}
                                    onUpdate={onUpdate}
                                />
                            );
                        })}
                </List>
                {userDelete && (
                    <RemoveUserDialog
                        userInfo={userDelete}
                        handleClose={() => {
                            setUserDelete(null);
                        }}
                    />
                )}
                {userEditor && (
                    <EditUserDialog
                        users={userInfos}
                        usersGroups={userGroup}
                        userInfo={userEditor}
                        handleClose={() => {
                            setUserEditor(null);
                        }}
                        onUpdate={onUpdate}
                    />
                )}
            </div>
        </div>
    );
};

const Item: FC<{
    userInfo: IUserInfo;
    onEdit: (userInfo: IUserInfo) => void;
    onDelete: (userInfo: IUserInfo) => void;
    onUpdate: any;
}> = ({ userInfo, onEdit, onDelete, onUpdate }) => {
    const fullName = userInfo.firstName + " " + userInfo.lastName;
    const [userExpectationDialog, setUserExpectationDialog] = useState(false);
    const [expDialog, setExpDialog] = useState(false);
    useEffect(() => {
        let element = document.getElementById("exp-" + userInfo._id);
        if (element) element.innerHTML = userInfo.standarExpPerLevel + " exp/level";
    }, [userInfo]);
    return (
        <>
            <ListItem
                style={{
                    borderBottom: "1px solid #ddd",
                    opacity: userInfo.status === Config.STATUS_DISABLE ? 0.5 : 1,
                }}
            >
                <Link to={`/user/${userInfo?._id}`}>
                    <ListItemAvatar>
                        <UserAvatar avatar={userInfo?.avatar} teams={""} />
                    </ListItemAvatar>
                </Link>
                <ListItemText primary={fullName} />
                {userInfo?.role != Config.USER_ROLE_ADMIN ? (
                    <>
                        <IconButton
                            id={"exp-" + userInfo._id}
                            sx={{
                                borderRadius: "8px",
                                fontSize: "16px",
                                marginRight: "30px",
                                width: "150px",
                                border: "1px solid",
                            }}
                            onClick={() => {
                                setExpDialog(true);
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.innerHTML = userInfo.standarExpPerLevel + " exp/level";
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.innerHTML = "Cài đặt hệ số";
                            }}
                        >
                            {userInfo.standarExpPerLevel + " exp/level"}
                        </IconButton>
                        <IconButton
                            sx={{
                                borderRadius: "8px",
                                fontSize: "16px",
                                marginRight: "30px",
                                border: "1px solid",
                            }}
                            onClick={() => {
                                setUserExpectationDialog(true);
                            }}
                        >
                            Đặt mục tiêu
                        </IconButton>
                    </>
                ) : (
                    <div></div>
                )}
                <IconButton
                    title="Edit User"
                    onClick={() => {
                        onEdit(userInfo);
                    }}
                    sx={{
                        borderRadius: "8px",
                        width: "40px",
                        height: "40px",
                        marginRight: "30px",
                    }}
                >
                    <FontAwesomeIcon icon={faEdit as IconProp} />
                </IconButton>
                <IconButton
                    title="Delete User"
                    onClick={() => {
                        onDelete(userInfo);
                    }}
                    sx={{
                        borderRadius: "8px",
                        width: "40px",
                        height: "40px",
                        marginRight: "30px",
                    }}
                >
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                </IconButton>
            </ListItem>
            <UserExpectationDialog
                open={userExpectationDialog}
                onClose={() => {
                    setUserExpectationDialog(false);
                }}
                userInfo={userInfo}
                onUpdate={onUpdate}
            />
            <UserCoefficientDialog
                open={expDialog}
                onClose={() => {
                    setExpDialog(false);
                }}
                userInfo={userInfo}
                onUpdate={onUpdate}
            />
        </>
    );
};

const RemoveUserDialog: FC<{
    userInfo: IUserInfo;
    handleClose: () => void;
}> = ({ userInfo, handleClose }) => {
    const dispatch = useDispatch();
    const onDelete = () => {
        dispatch(
            updateUserInfo([
                {
                    ...userInfo,
                    ...{
                        status: Config.STATUS_DELETED,
                    },
                },
            ])
        );
        handleClose();
    };
    return (
        <Dialog
            open={!!userInfo}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title2"
            aria-describedby="alert-dialog-description2"
        >
            <DialogTitle id="alert-dialog-title2">
                Bạn có muốn xóa người dùng "{userInfo?.firstName + " " + userInfo?.lastName}"?
            </DialogTitle>
            <DialogActions>
                <Button onClick={onDelete} autoFocus>
                    Xóa
                </Button>
                <Button onClick={handleClose}>Hủy</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserManagement;
