import { TextField } from "@mui/material"

const TextFieldWithLabel = ({label, value, handleChange, placeholder, permission, type = 'text', ...props}) => {
  return (
    <>
        <h5>{label}</h5>
        <TextField 
            value={value}
            onChange={handleChange}
            placeholder={`${placeholder}`}
            type={type}
            variant="outlined"
            inputProps={{readOnly: !permission}}
            size='small'
            sx={{
                width: "80%",
                "& label.Mui-focused": {
                    display: "none"
                },
                "& legend": {
                    display: "none"
                }
            }}
            {...props}
        />
    </>
  )
}

export default TextFieldWithLabel