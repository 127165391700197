import React, { useEffect, useState } from "react";
import moment from "moment";
import { Form, Table } from "react-bootstrap";
import axios from "axios";
import Url from "../../util/url";
import { IUserInfo } from "../../shared/models/userInfo";
import { useSelector } from "react-redux";
import { isAdmin } from "../../util";
import { Redirect } from "react-router-dom";
import Config from "../../shared/models/config";

interface userProps {
    _id: string;
    firstName: string;
    lastName: string;
    employeeCode: string;
}

interface dataProps {
    slot: string;
    type: string;
    date: Date | string;
}

interface resultProps {
    info: userProps;
    data: dataProps[];
}

function ScheduleInternView() {
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const [selectedMonth, setSelectedMonth] = useState(moment().format("M").toString());
    const [selectedYear, setSelectedYear] = useState(moment().year().toString());
    const [daysInMonth, setDaysInMonth] = useState(0);
    const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
    const [result, setResult] = useState<resultProps[]>([]);
    const [resultDisplayed, setResultDisplayed] = useState<resultProps[]>([]);
    const [listUser, setListUser] = useState<string[]>([]);
    const [existsData, setExistsData] = useState(true);

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const month = event.target.value;
        setSelectedMonth(month);
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = event.target.value;
        setSelectedYear(year);
    };

    // Tính toán số ngày, thứ trong tháng khi select tháng, năm
    useEffect(() => {
        const daysInSelectedMonth = moment(`${selectedYear}-${selectedMonth}-01`).daysInMonth();
        const updatedDaysOfWeek: string[] = [];

        for (let day = 1; day <= daysInSelectedMonth; day++) {
            const dayOfWeek = moment(`${selectedYear}-${selectedMonth}-${day}`).format("ddd");
            updatedDaysOfWeek.push(dayOfWeek);
        }

        setDaysInMonth(daysInSelectedMonth);
        setDaysOfWeek(updatedDaysOfWeek);
    }, [selectedMonth, selectedYear]);

    // Render ra số ngày trong tháng
    const renderHeaders = () => {
        const headers = [];

        for (let day = 1; day <= daysInMonth; day++) {
            headers.push(<th key={day}>{day}</th>);
        }

        return headers;
    };

    // Call API lấy data
    const callApi = async () => {
        try {
            const response = await axios.get(Url(`register-schedule/all`));
            return response.data;
        } catch (error) {
            alert("Lỗi Server!!!");
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await callApi();
                setResult(results);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [selectedMonth, selectedYear]);

    const format = (type: string, slot: string) => {
        // if (type === "onl" && slot === "allday") return "SC (OL)";
        // if (type === "onl" && slot === "morning") return "S (OL)";
        // if (type === "onl" && slot === "afternoon") return "C (OL)";
        if (type === Config.TYPE.offday && slot === Config.ALL_DAY) return "SC";
        if (type === Config.TYPE.offday && slot === Config.MORNING) return "S";
        if (type === Config.TYPE.offday && slot === Config.AFTERNOON) return "C";
    };

    // kiểm tra data trong tháng và năm có tồn tại hay k
    const checkExistsData = () => {
        setExistsData(false);

        // list user để lọc theo user
        setListUser([]);
        setResultDisplayed([]);

        result.forEach((data) => {
            let addUser = false;

            data.data.forEach((item) => {
                if (
                    moment(item.date).format("M").toString() === selectedMonth &&
                    moment(item.date).year().toString() === selectedYear
                ) {
                    setExistsData(true);
                    addUser = true;
                    return;
                }
            });

            if (addUser) {
                {
                    setListUser((prev) => [...prev, `${data.info.lastName} ${data.info.firstName}`]);
                    setResultDisplayed((prev) => [...prev, data]);
                }
            }
        });
    };

    useEffect(() => {
        if (!!result) {
            checkExistsData();
        }
    }, [result, selectedMonth, selectedYear]);

    const handleFilterUser = (userName: string) => {
        const selectedData = result.find((data) => `${data.info.lastName} ${data.info.firstName}` === userName);
        if (selectedData) setResultDisplayed([selectedData]);
        else checkExistsData();
    };

    return userInfo.role !== Config.USER_ROLE_INTERN ? (
        <div className="bg-light position-relative" style={{ minHeight: "85vh", padding: "20px" }}>
            <div className="mb-4 d-flex justify-content-center">
                {existsData && (
                    <div className="position-absolute top-4 start-0" style={{ width: "15%" }}>
                        <Form.Select
                            className="ms-4"
                            // style={{ width: "15%" }}
                            id="userSelect"
                            // value={selectedYear}
                            onChange={(e) => handleFilterUser(e.target.value)}
                        >
                            <option value="">ALL</option>
                            {listUser.map((user, index) => (
                                <option key={index} value={user}>
                                    {user}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                )}
                <Form.Select style={{ width: "15%" }} id="monthSelect" value={selectedMonth} onChange={handleMonthChange}>
                    <option value="1">Tháng 1</option>
                    <option value="2">Tháng 2</option>
                    <option value="3">Tháng 3</option>
                    <option value="4">Tháng 4</option>
                    <option value="5">Tháng 5</option>
                    <option value="6">Tháng 6</option>
                    <option value="7">Tháng 7</option>
                    <option value="8">Tháng 8</option>
                    <option value="9">Tháng 9</option>
                    <option value="10">Tháng 10</option>
                    <option value="11">Tháng 11</option>
                    <option value="12">Tháng 12</option>
                </Form.Select>

                <Form.Select
                    className="ms-4"
                    style={{ width: "15%" }}
                    id="yearSelect"
                    value={selectedYear}
                    onChange={handleYearChange}
                >
                    <option value="2023">Năm 2023</option>
                    <option value="2024">Năm 2024</option>
                    <option value="2025">Năm 2025</option>
                    <option value="2026">Năm 2026</option>
                    <option value="2027">Năm 2027</option>
                    <option value="2028">Năm 2028</option>
                    <option value="2029">Năm 2029</option>
                    <option value="2030">Năm 2030</option>
                </Form.Select>
            </div>

            {existsData ? (
                <Table bordered className="mx-1 text-center border border-secondary" responsive>
                    <thead>
                        <tr>
                            <th rowSpan={2}>STT</th>
                            <th rowSpan={2}>Họ và tên</th>
                            <th rowSpan={2}>Mã nhân viên</th>
                            {renderHeaders()}
                        </tr>
                        <tr>
                            {Array.from({ length: daysInMonth }).map((_, index) => (
                                <th key={index}>{daysOfWeek[index]}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {resultDisplayed.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {item.info.lastName} {item.info.firstName}{" "}
                                </td>
                                <td>{item.info.employeeCode}</td>
                                {Array.from({ length: daysInMonth }).map((_, dayIndex) => {
                                    const currentDate = moment(`${selectedYear}-${selectedMonth}-${dayIndex + 1}`).format(
                                        "YYYY-MM-DD"
                                    );
                                    const schedule = item.data.find(
                                        (scheduleItem) => moment(scheduleItem.date).format("YYYY-MM-DD") === currentDate
                                    );

                                    return <td key={dayIndex}>{schedule ? format(schedule.type, schedule.slot) : "-"}</td>;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <h1 className="mt-5 text-center">Không có dữ liệu</h1>
            )}
        </div>
    ) : (
        <Redirect to={{ pathname: "/dashboard" }} />
    );
}

export default ScheduleInternView;
