import { IBasic } from "./basic";

export interface IGitHubRepository extends IBasic {
    id: number
    name: string
    full_name: string
    url: string
    html_url: string
    clone_url: string
    owner: any
    projectId: string
    taskIds: string[]
}

export class GitHubRepository implements IGitHubRepository {
    _id: any
    id: number
    name: string
    full_name: string
    url: string
    html_url: string
    clone_url: string
    owner: any
    projectId: string
    taskIds: string[]

    constructor(props: any = {}) {
        this._id = props._id;
        this.id = props.id;
        this.url = props.url;
        this.name = props.name;
        this.full_name = props.full_name;
        this.clone_url = props.clone_url;
        this.html_url = props.html_url;
        this.projectId = props.projectId;
        this.taskIds = props.taskIds;
    }
}