import { callApi } from ".";
import Constants from "../constant";
import { urlDashboard } from "../util/url";

export const getRetentionAPI = (
    gaID: number,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-traffic/get-retention?viewID=" + gaID;
    url += "&startDate=" + startDate + "&endDate=" + endDate;
    // Constants.API_DASHBOARD_URL +
    // "/email-feedbacks?test=" +
    // testID +
    // "&category=content"
    return callApi({
        method: "GET",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
    });
};

export const getRetentionAppAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-retention-app";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getChurnRateAppAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-churn-rate";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getStickinessAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-stickiness";
    let data: any = { startDate, endDate, appType: appType };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getAVGUserPageViewAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-avg-page-view-user";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getActiveUserAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-dau-active-user";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getAvgSessionAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-avg-session";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getTotalDownloadUserAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-total-user-download-ws";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getUserGrowthRateAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-app-index/get-user-growth-rate";
    let data: any = { startDate, endDate, appType: appType };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getInfoWebAppByEventNameAPI = (
    appType: any,
    eventName: string,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-app-index/get-info-by-event-name";
    let data: any = {
        startDate,
        endDate,
        appType: appType,
        eventName: eventName,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getTotalStepUserDownloadAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "traffic/get-total-step-to_first_download";
    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getAdsRevenueAppAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "app-revenue/get-ads-revenue-app";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getProRevenueWebAPI = (
    slug: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-revenue/get-pro-revenue-web";

    let data: any = {
        startDate,
        endDate,
        slug: slug,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getProRevenueAppAPI = (
    appIds: any,
    startDate: number,
    endDate: number
) => {
    let url =
        "https://dev-dot-micro-enigma-235001.appspot.com/dataapi?type=get-in-app-purchase";

    let data: any = {
        startTime: startDate,
        endTime: endDate,
        appIds: appIds,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getCompareActiveUserAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "compare/traffic/active-user";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getComparePagesPerUserAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "compare/traffic/page-view-user";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getCompareSessionAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "compare/traffic/session";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getCompareStickinessAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "compare/traffic/stickiness";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};
export const getCompareRetentionAPI = (
    appType: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "compare/traffic/retention-app";

    let data: any = {
        startDate,
        endDate,
        appType: appType,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getCompareTotalRevenueAPI = (
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "app-revenue/get-total-revenue-app";

    let data: any = {
        startDate,
        endDate,
        appType: [
            { type: 2 },
            { type: 3 },
            { type: 1 },
            { type: 4 },
            { type: 5 },
        ],
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getTotalAdsRevenueAppAPI = (
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "app-revenue/get-total-ads-revenue-app";

    let data: any = {
        startDate,
        endDate,
        appType: [
            { type: Constants.PLATFORM_FLUTTER_ANDROID },
            { type: Constants.PLATFORM_FLUTTER_IOS },
            { type: Constants.PLATFORM_REACT_NATIVE_IOS },
            { type: Constants.PLATFORM_REACT_NATIVE_ANDROID },
        ],
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getTotalProWebRevenueAPI = (
    slugs: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-revenue/get-total-pro-revenue-web";

    let data: any = {
        startDate,
        endDate,
        slugs: slugs,
    };

    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};

export const getAbtestBySlugAPI = (slug: string) => {
    let url = urlDashboard;
    url += "abtests/get-abtest-by-slug?slug=" + slug;

    return callApi({
        method: "GET",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
    });
};

export const getAdsRevenueWebAPI = (
    slugs: any,
    startDate: string,
    endDate: string
) => {
    let url = urlDashboard;
    url += "web-revenue/get-ads-revenue-web";
    let month = (new Date(startDate).getMonth() + 1).toString();
    let year = new Date(startDate).getFullYear();
    if (month.length == 1) {
        month = "0" + month;
    }
    startDate = year + "-" + month + "-" + "01";

    let data: any = {
        startDate,
        endDate,
        slugs,
    };
    return callApi({
        method: "POST",
        url: url,
        baseURl: Constants.API_DASHBOARD_URL,
        params: data,
    });
};
