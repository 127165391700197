import { ITaskComment, TaskComment } from "../../shared/models/task";
import { CommentTypes, ICommentAction } from "../actions/comment.action";

export interface ICommentState {
    loading: boolean;
    error: any;
    comments: ITaskComment[];
    currentTaskId: string;
}

const initState: ICommentState = {
    loading: false,
    error: null,
    comments: [],
    currentTaskId: "",
};

const commentState = (
    state = initState,
    action: ICommentAction
): ICommentState => {
    switch (action.type) {
        case CommentTypes.GET_COMMENT:
            return {
                ...initState,
                currentTaskId: action.taskId ?? "",
                loading: true,
            };
        case CommentTypes.GET_COMMENT_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    comments: action.comments ?? [],
                },
            };
        case CommentTypes.GET_COMMENT_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case CommentTypes.UPDATE_COMMENT_SUCCESS:
            if (action.comments) {
                for (let comment of action.comments) {
                    if (comment.taskId === state.currentTaskId) {
                        let index = state.comments.findIndex(
                            (u) => u._id == comment._id
                        );
                        if (index > -1) {
                            state.comments[index] = new TaskComment({
                                ...state.comments[index],
                                ...comment,
                            });
                        } else {
                            state.comments.push(comment);
                        }
                    }
                }
            }
            return {
                ...state,
            };
        case CommentTypes.DELETE_COMMENT_SUCCESS:
            if (action.commentIds) {
                for (let commentId of action.commentIds) {
                    let index = state.comments.findIndex(
                        (u) => u._id == commentId
                    );
                    if (index > -1) {
                        state.comments.splice(index, 1);
                    }
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default commentState;
