import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DeleteDialog = ({open, onClose, handleDelete}) => {
  return (
    <>
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Xóa ?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                Bạn có chắc chắn muốn xóa mục này ?
                <br />
                <br />
                Việc này không thể hoàn tác.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='error' onClick={onClose}>Không</Button>
                <Button variant='contained' color='primary' onClick={handleDelete} autoFocus>
                    Có
                </Button>
            </DialogActions>
        </Dialog>   
    </>
  )
}

export default DeleteDialog