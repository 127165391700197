import { IProject } from "../../shared/models/project";

export enum ProjectTypes {
    GET_ALL_PROJECT = "GET_ALL_PROJECT",
    GET_ALL_PROJECT_SUCCESS = "GET_ALL_PROJECT_SUCCESS",
    GET_ALL_PROJECT_FAILED = "GET_ALL_PROJECT_FAILED",
}

export interface IProjectAction {
    type: ProjectTypes
    projects?: IProject[]
    error?: any
}

export const getAllProject = (): IProjectAction => {
    return {
        type: ProjectTypes.GET_ALL_PROJECT,
    }
}

export const getAllProjectSuccess = (projects: IProject[]): IProjectAction => {
    return {
        type: ProjectTypes.GET_ALL_PROJECT_SUCCESS,
        projects,
    }
}

export const getAllProjectFailed = (error: any): IProjectAction => {
    return {
        type: ProjectTypes.GET_ALL_PROJECT_FAILED,
        error,
    }
}