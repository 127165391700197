import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import Config from "../../../shared/models/config";
import { useDispatch, useSelector } from "react-redux";
import { deleteSharingSchedule } from "../../../redux/actions/sharing.action";
const PopupDelete = ({
    openPopup,
    setOpenPopup,
    handleDelete,
    idDelete,
    setOpenDialog,
    handleUpdateSharingScore,
    // currentUser,
}) => {
    const dispatch = useDispatch();
    return (
        <div>
            <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                <DialogTitle>{"Bạn muốn xóa chứ!"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenPopup(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            dispatch(deleteSharingSchedule(idDelete, { status: Config.DELETED_SHARING }));
                            // handleDelete(idDelete);
                            setOpenPopup(false);
                            setOpenDialog(false);
                            // handleUpdateSharingScore(
                            //     currentUser?._id,
                            //     Config.SUBTRACT_SHARING_SCORE
                            // );
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PopupDelete;
