import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Tooltip,
    Zoom,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Config from "../../../shared/models/config";
import { createSharingSchedule } from "../../../redux/actions/sharing.action";
import "./style.scss";

const TableData = ({
    showTable,
    setShowTable,
    setOpenDialog,
    setDataEdit,
    setOpenPopup,
    setIdDelete,
    currentUser,
    handleUpdateSharingScore,
    dispatch,
}) => {
    const [open, setOpen] = useState(false);
    const [valueSummary, setValueSummary] = useState("");
    const handleFormatDate = (date) => {
        const formattedDate = new Date(date);
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        const formattedDateString = formattedDate.toLocaleDateString("en-GB", options);
        return formattedDateString;
    };
    // useEffect(() => {
    //     const arr = showTable.map((item) => ({
    //         _id: item._id,
    //         completed: item.completed,
    //     }));

    //     setCheckedList(arr);
    //     console.log(checkedList);
    // }, [showTable]);

    const handleChecked = (newCheckedState, id, title, schedule, assignee) => {
        const update = showTable.map((item) => {
            if (item._id === id) {
                return { ...item, completed: newCheckedState };
            }
            return item;
        });
        setShowTable(update);

        if (newCheckedState) {
            handleUpdateSharingScore(assignee, Config.ADD_SHARING_SCORE);
        } else {
            handleUpdateSharingScore(assignee, Config.SUBTRACT_SHARING_SCORE);
        }

        dispatch(
            createSharingSchedule(
                {
                    title: title,
                    schedule: schedule,
                    assignee: assignee,
                    completed: newCheckedState,
                },
                id
            )
        );
    };
    return (
        <div className="table-data">
            <Table sx={{ minWidth: 10 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Schedule</TableCell>
                        <TableCell align="center">Assignee</TableCell>
                        <TableCell align="center">Option</TableCell>
                        <TableCell align="center">Complete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {showTable?.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell
                                className="title-row"
                                align="center"
                                onClick={() => {
                                    setOpen(true);
                                    setValueSummary(data.summary);
                                }}
                            >
                                {data.title}
                            </TableCell>

                            <TableCell align="center">{handleFormatDate(data.schedule)}</TableCell>

                            <TableCell align="center">{data.assigneeDetails[0]?.firstName}</TableCell>

                            <TableCell align="center">
                                <div
                                    className="cluster-btn"
                                    style={{
                                        pointerEvents: currentUser._id === data.assignee ? "auto" : "none",
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            if (currentUser?._id !== data.assignee) {
                                            } else {
                                                setOpenDialog(true);
                                                setDataEdit(data);
                                            }
                                        }}
                                        className={"btn-edit btn-on-table " + (data.completed ? "completed" : "")}
                                        style={{
                                            border: "1px solid #FCCF79",
                                            backgroundColor: currentUser._id === data.assignee ? "#fff0d2" : "#FCCF79",
                                            color: currentUser._id === data.assignee ? "#C68335" : "#FCCF79",
                                        }}
                                    >
                                        <span>Edit</span>
                                    </button>
                                    <button
                                        className="btn-edit btn-delete btn-on-table"
                                        style={{
                                            border: "1px solid #F87171",
                                            backgroundColor: currentUser._id === data.assignee ? "#ffd5db" : "#F87171",
                                            color: currentUser._id === data.assignee ? "#991B1B" : "#F87171",
                                        }}
                                        onClick={() => {
                                            if (currentUser?._id !== data.assignee) {
                                            } else {
                                                setOpenPopup(true);
                                                setIdDelete(data._id);
                                            }
                                        }}
                                    >
                                        <span>Delete</span>
                                    </button>
                                </div>
                            </TableCell>
                            <TableCell align="center">
                                <Checkbox
                                    checked={showTable.find((item) => item._id === data._id)?.completed || false}
                                    onChange={(event) => {
                                        if (currentUser?.position === "Lead Dev") {
                                            handleChecked(
                                                event.target.checked,
                                                data._id,
                                                data.title,
                                                data.schedule,
                                                data.assignee
                                            );
                                        } else {
                                            alert("You don't Lead Dev");
                                        }
                                    }}
                                    icon={<BookmarkBorderIcon />}
                                    checkedIcon={<BookmarkIcon />}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>{"Summary"}</DialogTitle>
                <DialogContent>{valueSummary}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TableData;
