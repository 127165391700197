import React from "react";

const EyesIconSvg = ({ lightEye, color, opacity, size }: {size?: number, lightEye?: boolean, color?: string, opacity?: number }) => {
  return (
    <>
      <svg
        width={size ?? "24"}
        height={size ?? "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-eye"
      >
        <path
          opacity={opacity ?? "0.4"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7366 6.04606C19.4439 7.36388 20.8976 9.29455 21.9415 11.7091C22.0195 11.8924 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8924 2.05854 11.7091C4.14634 6.87903 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12Z"
          fill={color ?? (lightEye ? "#3878ED" : "#342E51")}
          fillOpacity={opacity ?? (lightEye ? "1" : "0.24")}
        />
        <path
          d="M14.4308 11.9969C14.4308 13.3254 13.3381 14.4114 12.0015 14.4114C10.6552 14.4114 9.5625 13.3254 9.5625 11.9969C9.5625 11.832 9.58201 11.6778 9.61128 11.5227H9.66006C10.743 11.5227 11.621 10.6694 11.6601 9.60172C11.7674 9.5833 11.8845 9.57263 12.0015 9.57263C13.3381 9.57263 14.4308 10.6587 14.4308 11.9969Z"
          fill={color ?? (lightEye ? "#3878ED" : "#342E51")}
          fillOpacity={opacity ?? (lightEye ? "1" : "0.24")}
        />
      </svg>
    </>
  );
};

export default EyesIconSvg;
