import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.scss";
import AuthRoute from "./components/AuthRoute";
import ChangePassword from "./components/change-password/index";
import CreateBlog from "./components/create-blog/index";
// import DashboardDataAnalytic from "./components/dashboard-analytic/index";
import QuestionQuality from "./components/dashboard-analytic/question-quality";
import DayOffRequest from "./components/day-off-request/index";
import Home from "./components/home";
import Items from "./components/item/index";
import LoginPage from "./components/login";
import ManageDevice from "./components/manage-device/index";
import ReportsPage from "./components/report/index";
import ShoppingRequest from "./components/shopping-request/index";
import UserScreen from "./components/user/UserScreen";
import Profile from "./components/user/index";
import Wiki from "./components/wiki";
import ShowPost from "./components/wiki/showPost";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppManagement from "./components/app-management";
import DashBoardAnalyticV2 from "./components/dashboard-analytic-v2";
import DashboardV2 from "./components/dashboard/dashboardV2";
import HolidayRequest from "./components/holiday-request";
import InfoRecuimentPage from "./components/info-recuiment-page/index";
import RecruitmentPage from "./components/recruitment-page/index";
import ReleasesPage from "./components/releases";
import UserStatisticScreen from "./components/statistics";
import ToolPage from "./components/tool";
import UserGroup from "./components/user-group";
import UserManagement from "./components/user-management";
import SimpleMap from "./components/user-map";
import WorkSchedule from "./components/work-schedule";
import WorkingTime from "./components/working-time";
import { getSetting } from "./redux/actions/setting.action";
import { IWebState } from "./redux/reducers/web.reducer";
import RouterConfig from "./routes";
import "./styles/index.css";
// import "./util/socket"; // import SOCKET for active ** DONT DELETE
import ShowInfoRating from "./components/info-rating-review-app";
import WorkScheduleRegister from "./components/work-schedule-register";
import ScheduleInternView from "./components/work-schedule-intern-view/ScheduleInternView";
import { ProblemReportingComponent } from "./components/problem-reporting";
import ExcelTool from "./components/excel-tool";
import ReportStatistic from "./components/report/ReportStatistic";
import KnowledgeSharing from "./components/knowledge-sharing";
import AppStatisticsPage from "./components/app-management/app-statistics";
import ComplementDayRequest from "./components/complementDay-request";
const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSetting());
    }, []);

    return (
        <div className="App">
            <Router>
                <div className="app-main">
                    <Switch>
                        <Route exact path={RouterConfig.LOGIN} component={LoginPage} />
                        {/* <Route exact path="/dashboard-analytic-old" component={DashboardDataAnalytic} /> */}
                        <Route exact path={RouterConfig.CHANGE_PASSWORD} component={ChangePassword} />
                        <AuthRoute exact path="/profile" component={Profile} />
                        <AuthRoute exact path="/item" component={Items} />
                        <AuthRoute exact path="/" component={DashboardV2} project={{ sprint: true, hideHeader: true }} />
                        <AuthRoute exact path="/home" component={Home} />
                        <AuthRoute exact path="/dashboard" component={Home} />
                        <AuthRoute exact path="/user/:id" component={UserScreen} />
                        <AuthRoute exact path="/shop" component={Home} />
                        <AuthRoute
                            exact
                            path="/sprint/:id"
                            component={DashboardV2}
                            project={{ sprint: true, hideHeader: true }}
                        />
                        <AuthRoute
                            exact
                            path="/task/:taskID"
                            component={DashboardV2}
                            project={{ sprint: true, hideHeader: true }}
                        />
                        <AuthRoute exact path="/user-management/:isShowEditUser" component={UserManagement} />{" "}
                        <AuthRoute exact path="/wiki" component={Wiki} />
                        <AuthRoute exact path="/app-web-problem-reporting" component={ProblemReportingComponent} />
                        <AuthRoute exact path="/wiki/showpost/:id" component={ShowPost} />
                        <AuthRoute exact path="/question-quality" component={QuestionQuality} />
                        <AuthRoute exact path="/dashboard-data-analytic" component={DashBoardAnalyticV2} />
                        <AuthRoute exact path="/reports" component={ReportsPage} project={{ sprint: true }} />
                        <AuthRoute exact path="/reports-statistics" component={ReportStatistic} project={{ sprint: true }} />
                        <AuthRoute exact path="/day-off-request" component={DayOffRequest} project={{ sprint: true }} />
                        <AuthRoute exact path="/dayoff-request" component={DayOffRequest} project={{ sprint: true }} />
                        <AuthRoute exact path="/shopping-request" component={ShoppingRequest} />
                        <AuthRoute exact path="/manage-device" component={ManageDevice} />
                        <AuthRoute exact path="/create-blog" component={CreateBlog} />
                        <AuthRoute exact path="/working-time" component={WorkingTime} />
                        <AuthRoute exact path="/holiday-request" component={HolidayRequest} project={{ sprint: true }} />
                        <AuthRoute
                            exact
                            path="/complementday-request"
                            component={ComplementDayRequest}
                            project={{ sprint: true }}
                        />
                        <AuthRoute exact path="/work-schedule" component={WorkSchedule} />
                        <AuthRoute exact path="/releases" component={ReleasesPage} project={{ sprint: true }} />
                        <AuthRoute exact path="/SimpleMap" component={SimpleMap} />
                        <AuthRoute exact path="/user-statistic" component={UserStatisticScreen} />
                        <AuthRoute exact path="/user-group" component={UserGroup} />
                        <AuthRoute exact path="/recruitment-page" component={RecruitmentPage} />{" "}
                        <AuthRoute exact path="/info-recuiment-page" component={InfoRecuimentPage} />
                        <AuthRoute exact path="/tool" component={ToolPage} />
                        <AuthRoute exact path="/app-management" component={AppManagement} />
                        <AuthRoute exact path="/info-rating-review-app" component={ShowInfoRating} />{" "}
                        <AuthRoute exact path="/work-schedule-register" component={WorkScheduleRegister} />{" "}
                        <AuthRoute exact path="/schedule-intern-view" component={ScheduleInternView} />{" "}
                        <AuthRoute exact path="/excel-tool" component={ExcelTool} />
                        <AuthRoute exact path="/knowledge-sharing" component={KnowledgeSharing} />
                        <AuthRoute exact path="/app-statistics" component={AppStatisticsPage} />
                    </Switch>
                </div>
                <ToastContainer></ToastContainer>
            </Router>
            <CheckReload />
        </div>
    );
};

const CheckReload = () => {
    const webState: IWebState = useSelector((state: any) => state.webReducer);
    useEffect(() => {
        if (webState.reload) {
            window.location.reload();
        }
    }, [webState.reload]);
    return <></>;
};

export default App;
