import { IBasic } from "./basic";

export interface IFailedReason extends IBasic {
    description: string
    name: string
}

export class FailedReason implements IFailedReason {
    _id: any
    name: string
    description: string

    constructor(props: any) {
        this._id = props._id;
        this.name = props.name ?? "";
        this.description = props.description ?? "";
    }

    static isObject(item: any): boolean {
        if(item) {
            if(item.constructor.name == this.name) {
                return true;
            }
            const list = ["_id", "name", "description"];
            let count = 0;
            const itemList = Object.getOwnPropertyNames(item);
            for(let attr of itemList) {
                if(list.includes(attr)) {
                    count++;
                }
            }
            return count == list.length && itemList.length == count;
        }
        return false;
    }
}

export interface ISetting extends IBasic {
    failedReasons: IFailedReason[]
}

export class Setting implements ISetting {
    _id: any
    failedReasons: IFailedReason[]

    constructor(props: any = {}) {
        this._id = props.id;
        let _failedReasons = [];
        if(typeof props.failedReasons === "string") {
            _failedReasons = JSON.parse(props.failedReasons);
        } else if(typeof props.failedReasons === "object") {
            _failedReasons = props.failedReasons;
        }
        this.failedReasons = _failedReasons.map((item: any) => {
            return new FailedReason(item);
        });
    }
}