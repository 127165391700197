import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
    getPurchaseInfomationByEmailSuccess,
    setDataProcessingAction,
    updatePurchaseAction,
} from "../../redux/actions/toolAnalysis.action";
import { convertDate } from "../../shared/utils";
/**
 * @param type {0: by Email | 1: by TransactionId}
 * @returns
 */
const exceptFiled = [
    "notificationEnabled",
    "status",
    "declinedTimestamp",
    "ratedTimestamp",
    "themeMode",
    "progressData",
    "notificationTime",
    "eventCountKey",
    "currentApp",
    "inAppPurcharedName",
    "currentState",
    "osVersion",
    "row_id",
];
const MAX_LENGTH_ID = 16;
export const SelectableField = ["buyPro", "buyBasic"];
export const EditableField = ["appShortName", "appId"];
const optionEdit = ["Not Purchased", "PURCHASED"];
const stickyCellStyle = {
    position: "sticky",
    zIndex: 5,
    backgroundColor: "#e5e5e5",
    padding: "4px 8px",
    minWidth: "40px",
};

const Orders = ({ type = 0 }: { type: number }) => {
    const dispatch = useDispatch();
    const purchases = useSelector((state: any) => state.toolAnalysisState?.purchases[type] ?? {});
    const getDataProcessing: boolean = useSelector((state: any) => state.toolAnalysisState.getDataProcessing);
    let [label, setLabel] = React.useState<any[]>([]);
    const [editableRow, seteditableRow] = React.useState(-1);
    const [tmpText, setTmpText] = React.useState("");
    /**
     * item in recordUpdated has form:
     * rowId: string{
     *              buyPro: number: 0|1,
     *              buyBasic: number: 0|1,
     *              appShortName: string,
     *              appId: number
     *      }
     */
    const [recordUpdated, setRecordUpdated] = React.useState<any>([]);
    React.useEffect(() => {
        seteditableRow(-1);
        if (Object.keys(purchases).length > 0) {
            let lb: any[] = [];
            let editField = ["row_id", ...EditableField, ...SelectableField];
            for (let key in purchases) {
                if (!exceptFiled.includes(key)) lb = [...lb, ...[key]];

                for (let i = 0; i < purchases[key].length; i++) {
                    if (!!!recordUpdated[i]) recordUpdated[i] = {};
                    if (editField.includes(key)) {
                        recordUpdated[i] = {
                            ...recordUpdated[i],
                            [key]: purchases[key][i],
                        };
                    }
                }
            }
            if (Object.keys(recordUpdated).length > 0) setRecordUpdated(recordUpdated);
            setLabel(lb);
        }
    }, [purchases]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Recent Orders
            </Typography>
            {Object.keys(purchases).length == 0 && <span>Have no data...</span>}
            <TableContainer
                className="table-container"
                sx={{
                    border: "1px solid #e5e5e5",
                    borderRadius: 2,
                    maxHeight: 500,
                }}
            >
                <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...stickyCellStyle, left: 0 }}></TableCell>
                            {Object.keys(purchases).length > 0 &&
                                label?.map((l, index) => {
                                    return (
                                        <TableCell key={index} sx={{ fontWeight: "600" }}>
                                            {l}
                                        </TableCell>
                                    );
                                })}
                            <TableCell
                                sx={{
                                    ...stickyCellStyle,
                                    right: 0,
                                    textAlign: "right",
                                }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchases[label[0]]?.map((i: any, index: number) => {
                            // only use index for this map()
                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            ...stickyCellStyle,
                                            left: 0,
                                            padding: "4px 8px",
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                fontSize: "10px",
                                                padding: "4px 8px",
                                                minWidth: "40px",
                                            }}
                                            disabled={
                                                (editableRow > -1 && editableRow != index) || getDataProcessing || type == 1
                                            }
                                            onClick={() => {
                                                if (editableRow == index) seteditableRow(-1);
                                                else if (editableRow == -1) seteditableRow(index);
                                            }}
                                        >
                                            {getDataProcessing ? (
                                                <CircularProgress
                                                    sx={{
                                                        width: "10px !important",
                                                        height: "10px !important",
                                                    }}
                                                />
                                            ) : editableRow == index ? (
                                                "Cancel"
                                            ) : (
                                                "Edit"
                                            )}
                                        </Button>
                                    </TableCell>
                                    {label?.map((l, idx) => {
                                        if (editableRow == index) {
                                            if (SelectableField.includes(l))
                                                return (
                                                    <TableCell key={idx}>
                                                        <select
                                                            style={{
                                                                padding: "4px",
                                                                border: "1px solid rgba(33, 33, 33, 0.32)",
                                                                borderRadius: "4px",
                                                            }}
                                                            onChange={(e) => {
                                                                let newRecordUpdated = [...recordUpdated];
                                                                newRecordUpdated[index][l] = parseInt(e.target.value);

                                                                setRecordUpdated(newRecordUpdated);
                                                            }}
                                                            value={recordUpdated[index][l]}
                                                        >
                                                            {optionEdit.map((item, i) => {
                                                                return (
                                                                    <option value={i} key={i}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                );
                                            if (EditableField.includes(l)) {
                                                return (
                                                    <TableCell key={idx}>
                                                        <input
                                                            type={l == "appShortName" ? "text" : "number"}
                                                            onChange={(e) => {
                                                                let newRecordUpdated = [...recordUpdated];
                                                                let v = e.target.value;
                                                                if (l == "appId") {
                                                                    if (e.target.value.length > MAX_LENGTH_ID) return;
                                                                    newRecordUpdated[index][l] = parseInt(v); /// big number 9999 9999 9999 9999
                                                                } else newRecordUpdated[index][l] = v;

                                                                setRecordUpdated(newRecordUpdated);
                                                            }}
                                                            value={recordUpdated[index][l]}
                                                        />
                                                    </TableCell>
                                                );
                                            }
                                        }

                                        return (
                                            <TableCell key={idx}>
                                                <CellData data={purchases[l][index]} label={l} />
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell
                                        sx={{
                                            ...stickyCellStyle,
                                            right: 0,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                fontSize: "10px",
                                                minWidth: "40px",
                                                padding: "4px 8px",
                                            }}
                                            disabled={editableRow != index || getDataProcessing || type == 1}
                                            onClick={() => {
                                                dispatch(setDataProcessingAction(true));
                                                dispatch(updatePurchaseAction(recordUpdated[index]));
                                                seteditableRow(-1);
                                            }}
                                        >
                                            {getDataProcessing ? (
                                                <CircularProgress
                                                    sx={{
                                                        width: "10px !important",
                                                        height: "10px !important",
                                                    }}
                                                />
                                            ) : true ? (
                                                "Save"
                                            ) : (
                                                "-"
                                            )}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {label.length > 0 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        variant="text"
                        sx={{
                            marginTop: "16px",
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                        onClick={() => {
                            dispatch(getPurchaseInfomationByEmailSuccess([]));
                        }}
                    >
                        Clear
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

export const CellData = ({ data, label }: { data: any; label: string }) => {
    if (label.search("buy") == 0 || label == "inAppPurchared") {
        if (data == "0") data = "Not Purchased";
        else if (data == "1") data = "PURCHASED";
    }
    if (label == "type") {
        if (data == "0") data = "One-time";
        else if (data == "1") data = "Subscription";
    }
    if (label == "lastUpdate" || label == "inAppPurcharedTime") {
        if (!!data) data = convertDate(data)?.toLocaleString().toString();
    }
    return <span>{data}</span>;
};
export default React.memo(Orders);
