import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    getAppInfoGa,
    mergeData,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { ICertificates } from "../../../../models/certificates.model";
import { getInfoWebAppByEventNameAPI } from "../../../../services/traffic.service";
import { formatCurrency, formatDate } from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import CustomLineChart from "../../charts";
import PlatformTemplate from "../platform-template";

const ColumnView = ({
    platformIds,
    certificates,
    eventName,
}: {
    platformIds: number[];
    certificates: ICertificates;
    eventName: string;
}) => {
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [appValues, setAppValues] = useState(null);
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    useEffect(() => {
        async function getDataFC() {
            let apps = getAppInfoGa(certificates, platformIds);
            // let apps = certificates.appInfos
            //     ?.filter((app) => platformIds.includes(app.platformId))
            //     ?.map((app) => {
            //         return { type: app.platformId, streamId: app.gaID + "" };
            //     });
            if (apps?.length) {
                let resData = await getInfoWebAppByEventNameAPI(
                    apps,
                    eventName,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );

                setAppValues(resData);
            } else {
                let obj: any = {};
                setAppValues(obj);
            }
        }
        getDataFC();
    }, [startTime, endTime, certificates.id]);
    let newPlatform: number[] = [...platformIds];
    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    let newData = mergeData(appValues, newPlatform);

    const total = newData
        ? newData.reduce((acc, data) => {
              return acc + data.value;
          }, 0)
        : 0;

    return (
        <div className="col">
            {appValues ? (
                <>
                    <PlatformTemplate
                        platformIds={platformIds}
                        optionValue={optionValue}
                        setOptionValue={(value: any) => setOptionValue(value)}
                    />
                    <div className="total">
                        Total <span>{formatCurrency(total)}</span>
                    </div>
                    <CustomLineChart data={newData} dataKeyX="date" />
                </>
            ) : (
                <CircularIndeterminate />
            )}
        </div>
    );
};

export default ColumnView;
