import { connect } from 'react-redux';
import Error from '../Error';

const MyItem = ({myItems}) => {

    return (
        <div>
                <div className='my-item-template'>
                    <div className='my-item-content'>
                        {myItems.length === 0 ? (
                            <Error />
                        ) : (
                            myItems.map((item, index) => (
                                <div className='card-my-item' key={index}>
                                    <div className='card-description'>
                                        <img src={item.item}></img>
                                        <div className='date'>
                                            <p>{item.date}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
        </div>
    );
};

function mapStateToProps(state, props) {
    return {
        myItems: state.myitem,
    }
}

export default connect(mapStateToProps, {})(MyItem);
