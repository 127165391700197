import { AppTheme, IAppTheme } from "../../shared/models/appTheme";
import { AppThemeTypes, IAppThemeAction } from "../actions/appTheme.action";

export interface IAppThemeState {
    loading: boolean;
    appTheme: IAppTheme;
    error?: any;
}

const initState: IAppThemeState = {
    loading: false,
    appTheme: new AppTheme()
};

const appThemeState = (
    state = initState,
    action: IAppThemeAction
): IAppThemeState => {
    switch (action.type) {
        case AppThemeTypes.GET_APP_THEME:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case AppThemeTypes.GET_APP_THEME_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    appTheme: action.appTheme ?? new AppTheme()
                },
            };
        case AppThemeTypes.GET_APP_THEME_FAILED:
            return {
                ...state,
                ...{
                    error: action.error
                }
            };
        default:
            return state;
    }
};

export default appThemeState;
