import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    getAppInfoGa,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { Certificates } from "../../../../models/certificates.model";
import { getActiveUserAPI } from "../../../../services/traffic.service";
import {
    formatCurrency,
    formatDate,
    formatDateToDayAndMonth,
    hasAppReactNative,
    hasAppWeb,
} from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import CustomLineChart, { COLORS_LINE_CHART } from "../../charts";
import "./index.scss";

const METRICS_ACTIVE = [
    "active1DayUsers",
    "active7DayUsers",
    "active28DayUsers",
];

const mergeData = (resData: any, platformIds: number[]) => {
    if (resData && platformIds?.length) {
        let result: any = {};
        platformIds.forEach((platformId) => {
            if (resData[platformId]) {
                Object.keys(resData[platformId]).forEach((date) => {
                    let value = result[date] ?? {};
                    Object.keys(resData[platformId][date]).forEach((key) => {
                        value[key] =
                            resData[platformId][date][key] + (value[key] ?? 0);
                    });

                    result[date] = value;
                });
            }
        });
        return Object.keys(result).map((date) => {
            return {
                date: formatDateToDayAndMonth(date, false, true),
                ...result[date],
            };
        });
    }
};

const ActiveUserView = ({ certificates }: { certificates: Certificates }) => {
    return (
        <div className="main-active-user">
            <div className="list-chart">
                {hasAppWeb(certificates) && (
                    <ViewApp
                        certificates={certificates}
                        platformIds={[Constants.PLATFORM_WEB]}
                    />
                )}
                <ViewApp
                    certificates={certificates}
                    platformIds={[
                        Constants.PLATFORM_FLUTTER_ANDROID,
                        Constants.PLATFORM_FLUTTER_IOS,
                    ]}
                />
                {hasAppReactNative(certificates) && (
                    <ViewApp
                        certificates={certificates}
                        platformIds={[
                            Constants.PLATFORM_REACT_NATIVE_ANDROID,
                            Constants.PLATFORM_REACT_NATIVE_IOS,
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

const ViewApp = ({
    certificates,
    platformIds,
}: {
    certificates: Certificates;
    platformIds: number[];
}) => {
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [appValues, setAppValues] = useState(null);
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    useEffect(() => {
        async function getDataFC() {
            setAppValues(null);
            let apps = getAppInfoGa(certificates, platformIds);
            // certificates.appInfos
            //     ?.filter((app) => platformIds.includes(app.platformId))
            //     ?.map((app) => {
            //         return { type: app.platformId, streamId: app.gaID + "" };
            //     });
            if (apps?.length) {
                let resData = await getActiveUserAPI(
                    apps,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );
                setAppValues(resData);
            } else {
                let obj: any = {};
                setAppValues(obj);
            }
        }
        getDataFC();
    }, [startTime, endTime, certificates.id]);
    let newPlatform: number[] = [...platformIds];
    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    let newData = mergeData(appValues, newPlatform);
    const platform: any = PLATFORM_CONFIG.filter((platform) =>
        platformIds.includes(platform.type)
    )[0];
    // const totalDay = newData?.reduce((acc, data) => {
    //     return acc + data[METRICS_ACTIVE[0]];
    // }, 0);
    // const totalWeek = newData?.reduce((acc, data) => {
    //     return acc + data[METRICS_ACTIVE[1]];
    // }, 0);
    // const totalMonth = newData?.reduce((acc, data) => {
    //     return acc + data[METRICS_ACTIVE[2]];
    // }, 0);
    // const formatValue = (value: number) => {
    //     if (value < 1000) {
    //         return value;
    //     } else {
    //         let i = parseInt((value / 1000).toString());
    //         return i + " N";
    //     }
    // };

    return (
        <div className="active-user-app">
            {appValues ? (
                Object.keys(appValues).length > 0 ? (
                    <>
                        <div className="main-header">
                            <div className="left">
                                <div className="name">
                                    {platform?.frameworkName}
                                </div>
                                {/* <div style={{ color: COLORS_LINE_CHART[0] }}>
                                    1 day &nbsp;&nbsp;
                                    <span>{formatValue(totalDay)}</span>
                                </div>
                                <div style={{ color: COLORS_LINE_CHART[1] }}>
                                    7 day &nbsp;&nbsp;
                                    <span>{formatValue(totalWeek)}</span>
                                </div>
                                <div style={{ color: COLORS_LINE_CHART[2] }}>
                                    30 day &nbsp;&nbsp;
                                    <span>{formatValue(totalMonth)}</span>
                                </div> */}
                            </div>
                            {platform?.category != Constants.CATEGORY_WEB && (
                                <div className="right">
                                    <Form.Select
                                        onChange={(e) =>
                                            setOptionValue(e.target.value)
                                        }
                                    >
                                        {OPTIONS_PLATFORM.map((op) => (
                                            <option
                                                selected={optionValue == op}
                                                value={op}
                                                key={op}
                                            >
                                                {op.toUpperCase()}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            )}
                        </div>
                        <div className="chart">
                            <CustomLineChart
                                data={newData}
                                dataKeyX="date"
                                lineKeys={METRICS_ACTIVE}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )
            ) : (
                <CircularIndeterminate />
            )}
        </div>
    );
};

export default ActiveUserView;
