import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import bell from "../assets/icon/bell.png";
import warning from "../assets/icon/warning.png";
import logo from "../assets/images/logo.svg";
// import split from "../assets/images/split-credit.svg";
import Constants from "../constant";
import RouterConfig from "../routes";
import { ITask } from "../shared/models/task";
import { IUserInfo } from "../shared/models/userInfo";
import { isAdmin, isCreateBlog, isPartner, isStaff } from "../util/index";
import UserAvatar from "./user/UserAvatar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
// import Config from "../shared/models/config";

const Header = () => {
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const currentId = userInfo?._id;
    const sprintUserTasks: ITask[] = useSelector(
        (state: any) => state.taskState.tasks?.filter((t: any) => t.assigneeId === currentId) ?? []
    );

    const listWarningTasks = (sprintUserTasks: ITask[]) => {
        let listUnEstimatedTasks = [];
        let listImportantTasks = [];
        if (sprintUserTasks.length > 0) {
            for (let task of sprintUserTasks) {
                if (
                    task.statusId !== Constants.COLUMN_TASK_DONE &&
                    task.statusId !== Constants.COLUMN_TASK_TO_DO &&
                    !task.point
                ) {
                    listUnEstimatedTasks.unshift(task);
                } else {
                    if (
                        task.statusId === Constants.COLUMN_TASK_TO_DO &&
                        (task.priority === Constants.PRIORITY_TASK_HIGH || task.priority === Constants.PRIORITY_TASK_HIGHEST)
                    ) {
                        listImportantTasks.unshift(task);
                    }
                }
            }
        }
        const list: IWarningTasks = {
            listUnEstimatedTasks: listUnEstimatedTasks,
            listImportantTasks,
        };
        return list;
    };

    // const calculateDoneSprintTask = (SprintReport: any) => {
    //     if (isStaff(userInfo)) {
    //         if (!SprintReport) {
    //             return 0;
    //         } else {
    //             let finishedPoints = 0;
    //             let totalPoints = 0;
    //             SprintReport.finishedPoint?.forEach((task: any) => {
    //                 finishedPoints = finishedPoints + task?.point;
    //             });
    //             SprintReport.totalPoint?.forEach((task: any) => {
    //                 totalPoints = totalPoints + task?.point;
    //             });
    //             return Math.round((finishedPoints / totalPoints) * 100) / 100;
    //         }
    //     }
    //     return 0;
    // };

    // const calculateSprintDate = (sprintTime: any) => {
    //     const date = new Date().getTime();
    //     return (date - sprintTime.startDate) / (sprintTime.endDate - sprintTime.startDate);
    // };

    return (
        <>
            {currentId ? (
                <Container maxWidth="xl" style={{ height: 120 }}>
                    <div className="top-bar">
                        <div className="left">
                            <a className="logo" href="/">
                                <img src={logo} />
                            </a>

                            {/* {userInfo && (
                                <div
                                    className={
                                        userInfo.role !== Config.USER_ROLE_STAFF || userInfo.role !== Config.USER_ROLE_LEADER
                                            ? // (USER_ROLE_STAFF || USER_ROLE_LEADER)
                                              "rating-done-current-tasks"
                                            : "credit"
                                    }
                                >
                                    <div className="nav-credit">
                                        <div
                                            className="sprint-rating"
                                            style={{
                                                width: `${calculateDoneSprintTask(sprintReport) * 100}%`,
                                            }}
                                        ></div>
                                        <div
                                            className={
                                                calculateDoneSprintTask(sprintReport) < calculateSprintDate(sprintTime)
                                                    ? "sprint-date-active"
                                                    : "sprint-date-non-active"
                                            }
                                            style={{
                                                width: `${calculateSprintDate(sprintTime) * 100}%`,
                                                maxWidth: "100%",
                                            }}
                                        ></div>
                                        <div className="split">
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                                                return <img key={`split-${item}`} className={`split-${item}`} src={split} />;
                                            })}
                                        </div>
                                        <div className="surplus-total-pool-credit">
                                            Tỉ lệ hoàn thành task trong sprint hiện tại:{" "}
                                            {(calculateDoneSprintTask(sprintReport) * 1000) / 10}%
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </div>

                        <div className="right" style={{ zIndex: "2" }}>
                            <div className="dashboard">
                                <a href="https://abc-elearning.org/dashboard" target="_blank">
                                    Dashboard
                                </a>
                            </div>
                            <div className="bell-notification">
                                <WarningTasksMenu warningTasks={listWarningTasks(sprintUserTasks)} />
                            </div>
                            <div className="user-area">
                                <BasicMenu userInfo={userInfo} />
                            </div>
                        </div>
                    </div>
                </Container>
            ) : null}
        </>
    );
};

export function BasicMenu({ userInfo }: { userInfo: IUserInfo }) {
    const history = useHistory();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);
    const clickProfile = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `/profile` });
        handleClose(event);
    };

    const clickChangePassword = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: RouterConfig.CHANGE_PASSWORD });
        handleClose(event);
    };

    const logOut = (event: Event | React.SyntheticEvent) => {
        window.localStorage.removeItem("userInfo");
        window.location.href = "/";
        handleClose(event);
    };

    const gotoUserManagement = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `/user-management/false` });
        handleClose(event);
    };

    const gotoWorkingTimeManagement = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `/working-time` });
        handleClose(event);
    };
    const gotoKnowledgeSharing = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `knowledge-sharing` });
        handleClose(event);
    };

    const gotoMapUser = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `/SimpleMap` });
        handleClose(event);
    };

    const gotoUserGroup = (event: Event | React.SyntheticEvent) => {
        history.push({ pathname: `/user-group` });
        handleClose(event);
    };

    return (
        <div style={{ zIndex: 11 }}>
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <UserAvatar avatar={userInfo.avatar} />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
                sx={{ zIndex: 9999 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-end" ? "right top" : "left top",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                                    {!isPartner(userInfo) && !isCreateBlog(userInfo) && (
                                        <MenuItem onClick={clickProfile}>Trang cá nhân</MenuItem>
                                    )}
                                    {!isPartner(userInfo) && (
                                        <MenuItem onClick={gotoKnowledgeSharing}>Knowledge Sharing</MenuItem>
                                    )}
                                    {isAdmin(userInfo) && <MenuItem onClick={gotoUserManagement}>Quản lý thành viên</MenuItem>}
                                    {isAdmin(userInfo) && <MenuItem onClick={gotoWorkingTimeManagement}>Chấm công</MenuItem>}
                                    {isAdmin(userInfo) && <MenuItem onClick={gotoUserGroup}>Quản lý nhóm</MenuItem>}
                                    {isAdmin(userInfo) && <MenuItem onClick={gotoMapUser}>Địa chỉ thành viên</MenuItem>}
                                    {!isPartner(userInfo) && <MenuItem onClick={clickChangePassword}>Đổi mật khẩu</MenuItem>}
                                    <MenuItem onClick={logOut}>Thoát</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

interface IWarningTasks {
    listUnEstimatedTasks: ITask[];
    listImportantTasks: ITask[];
}

function WarningTasksMenu({ warningTasks }: { warningTasks: IWarningTasks }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setShortIDTasksSearchInput = (listTasks: ITask[]) => {
        let listShortIDs: string[] = [];
        listTasks.map((task) => {
            listShortIDs.push(task.shortId);
        });
        let dataSearchInput = "shortID: " + listShortIDs;
        dispatch({
            type: "SET_SEARCH_TASKS_INPUT",
            data: dataSearchInput,
        });
    };

    return (
        <div>
            <Button
                id="basic-button-warning"
                aria-controls={open ? "basic-menu-warning" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <img className="bell" src={bell} />
                {warningTasks.listUnEstimatedTasks.length === 0 && warningTasks.listImportantTasks.length === 0 ? (
                    ""
                ) : (
                    <img className="warning" src={warning} />
                )}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {warningTasks.listUnEstimatedTasks.length === 0 && warningTasks.listImportantTasks.length === 0 && (
                    <MenuItem>Không có thông báo nào!</MenuItem>
                )}
                {warningTasks.listUnEstimatedTasks.length > 0 && (
                    <MenuItem onClick={() => setShortIDTasksSearchInput(warningTasks.listUnEstimatedTasks)}>
                        <div className="menu-item">
                            <p>Các task hiện tại chưa được estimate point: </p>
                            {warningTasks.listUnEstimatedTasks.map((task) => {
                                return (
                                    <p key={"listUnestimatedTasks_" + task.shortId}>
                                        - {task.shortId} ({task.type})
                                    </p>
                                );
                            })}
                        </div>
                    </MenuItem>
                )}
                {warningTasks.listImportantTasks.length > 0 && (
                    <MenuItem onClick={() => setShortIDTasksSearchInput(warningTasks.listImportantTasks)}>
                        <div className={"menu-item"}>
                            <p>Các task quan trọng ở cột To Do: </p>
                            {warningTasks.listImportantTasks.map((task) => {
                                return (
                                    <p key={"listImportantTasks_" + task.shortId}>
                                        - {task.shortId} ({task.type})
                                    </p>
                                );
                            })}
                        </div>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

export default Header;
