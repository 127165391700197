import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IAppChannel, AppChannel } from "../../shared/models/appChannel";
import Url from "../../util/url";
import {
    AppChannelType,
    AppChannelAction,
    updateAppChannelSuccess,
    updateAppChannelFailed,
    getAppChannelSuccess,
    getAppChannelFailed,
} from "../actions/appChannel.action";

async function getAppChannelAPI(
    offset: number,
    limit: number,
    rateFilter: number,
    platformFilter: string,
    typeFilter: string,
    dateStart: string,
    dateEnd: string,
    appId: string
) {
    return await axios
        .get(
            Url(
                `app-channel/get-data?offset=${offset}&limit=${limit}&rating=${rateFilter}&platform=${platformFilter}&types=${typeFilter}&sort=asc&dateStart=${dateStart}&dateEnd=${dateEnd}&appId=${appId}`
            )
        )
        .then((data) => {
            if (data.status == 200 && data.data) {
                return data.data.map((item: any) => {
                    return new AppChannel(item);
                });
            }
            return [];
        });
}
function* getAppChannelSaga(action: AppChannelAction) {
    const offset = action.offset ?? 0;
    const limit = action.limit ?? 20;
    const rateFilter = action.rateFilter ?? 0;
    const platformFilter = action.platformFilter ?? "";
    const typeFilter = action.typeFilter ?? "";
    const dateStart = action.dateStart ?? "2021-05-04";
    const dateEnd = action.dateEnd ?? new Date().toString();
    const appId = action.appId ?? "";
    try {
        const appChannel: IAppChannel[] = yield call(() =>
            getAppChannelAPI(
                offset,
                limit,
                rateFilter,
                platformFilter,
                typeFilter,
                dateStart,
                dateEnd,
                appId
            )
        );
        if (appChannel) {
            yield put(getAppChannelSuccess(appChannel));
        } else {
            yield put(getAppChannelFailed("AppChannel not existed!"));
        }
    } catch (err) {
        yield put(getAppChannelFailed("AppChannelSaga ERROR: " + err));
    }
}

async function updateAppInfoAPI(
    appChannel: IAppChannel
): Promise<IAppChannel | null> {
    return await axios
        .post(Url("app-channel/update-data-appChannel"), { data: appChannel })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new AppChannel(data.data[0]);
            }
            return null;
        });
}
function* updateAppSaga(action: AppChannelAction) {
    const appChannel = action.updateAppChannel;
    if (appChannel) {
        try {
            const newAppInfo: IAppChannel | null = yield call(
                updateAppInfoAPI,
                appChannel
            );
            if (newAppInfo) {
                yield put(updateAppChannelSuccess(newAppInfo));
            } else {
                yield put(updateAppChannelFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(updateAppChannelFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            updateAppChannelFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}
export function* watchAppChannel() {
    yield takeLatest(AppChannelType.UPDATE_APP_CHANNEL, updateAppSaga);
    yield takeLatest(AppChannelType.GET_APP_CHANNEL, getAppChannelSaga);
}
