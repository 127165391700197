import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import Constants from "../../../constant";
import { hexToRgbA } from "../../../utils";
import { ChartOptionConfig, Dataset, configTooltip } from "./MyChart";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const convertData = (labels: string[], data: Dataset[]) => {
    let _data = {
        labels,
        datasets: data.map((item, index) => {
            return {
                type: item.type,
                label: item.label,
                borderColor: item?.color ?? Constants.BAR_COLOR[index],
                backgroundColor: hexToRgbA(item?.color ?? Constants.BAR_COLOR[index], 0.5),
                data: item.dataSet,
            };
        }),
    };
    return _data;
};

const getOption = (
    title: string,
    config: ChartOptionConfig = {
        stackedX: false,
        stackedY: false,
        suggestedMax: 100,
        xTitle: "",
        yTitle: "",
        unit: "",
    }
) => {
    return {
        plugins: {
            title: {
                display: true,
                text: title,
            },
            tooltip: configTooltip(config.unit),
        },
        scales: {
            x: {
                stacked: config.stackedX,
                title: {
                    display: true,
                    text: config.xTitle,
                },
            },
            y: {
                stacked: config.stackedY,
                suggestedMax: config.suggestedMax,
                title: {
                    display: true,
                    text: config.yTitle,
                },
            },
        },
    };
};

export function StackedBarLineChart({
    title,
    xAxis,
    dataset,
    stacked,
}: {
    title: string;
    xAxis: string[];
    dataset: Dataset[];
    stacked?: ChartOptionConfig;
}) {
    const _data = convertData(xAxis, dataset);
    return <Chart type="bar" options={getOption(title, stacked)} data={_data} />;
}
