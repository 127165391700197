export enum TestCaseTypes {
    GET_TESTS = "GET_TESTS",
    GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS",
    GET_TESTS_FAILED = "GET_TESTS_FAILED",
    SET_TEST = "SET_TEST",
    ADD_TEST = "ADD_TEST",
    UPDATE_TEST = "UPDATE_TEST",
    UPDATE_TESTS = "UPDATE_TESTS",
    DELETE_TEST = "DELETE_TEST",
}

export interface ITestCaseAction {
    type: TestCaseTypes;
    error?: any;
    data?: any;
}

export const getTests = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.GET_TESTS,
        data,
    };
};

export const getTestsSuccess = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.GET_TESTS_SUCCESS,
        data,
    };
};

export const getTestsFailed = (error: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.GET_TESTS_FAILED,
        error,
    };
};

export const setTest = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.SET_TEST,
        data,
    };
};

export const addTest = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.ADD_TEST,
        data,
    };
};

export const updateTest = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.UPDATE_TEST,
        data,
    };
};

export const updateTests = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.UPDATE_TESTS,
        data,
    };
};
export const deleteTest = (data: any): ITestCaseAction => {
    return {
        type: TestCaseTypes.DELETE_TEST,
        data,
    };
};
