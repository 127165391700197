import { IStatus } from "../../shared/models/status";
import { IStatusAction, StatusTypes } from "../actions/status.action";

export interface IStatusState {
	loading: boolean
	status?: IStatus[]
	error?: any
}

const initState: IStatusState = {
	loading: false,
};

const statusState = (state = initState, action: IStatusAction): IStatusState => {
	switch (action.type) {
		case StatusTypes.GET_ALL_STATUS:
			return {
				...state,
				... {
					loading: true,
				}
			}
		case StatusTypes.GET_ALL_STATUS_SUCCESS:
			return {
				...state,
				...{
					loading: false,
					status: action.status,
				}
			}
		case StatusTypes.GET_ALL_STATUS_FAILED:
			return {
				...state,
				...{
					loading: false,
					error: action.error,
				}
			}
		default:
			return state;
	}
};

export default statusState;
