import { AlertColor } from "@mui/material";
import { EditableField, SelectableField } from "../../components/tool/OrdersPanel";
import { convertDate } from "../../shared/utils";
import { IToolAnalysisAction, ToolAnalysisTypes } from "../actions/toolAnalysis.action";

interface IToolAnalysisState {
    purchases: any;
    getDataProcessing: boolean;
    message?: string;
    severity?: AlertColor;
}
const initState: IToolAnalysisState = {
    purchases: {}, //0 : email | 1 : transactionId
    getDataProcessing: false,
    message: "",
    severity: "success",
};

const toolAnalysisState = (state = initState, action: IToolAnalysisAction) => {
    switch (action.type) {
        case ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_EMAIL_SUCCESS:
            /**[{
                "type": 0, 
                "buyPro": 1,
                "inAppPurchared": 1, 
                "lastUpdate": 1677657508329 -> ngày,
                "buyStudyGuide": 0, 
                "buyCustomTest": 0,
                "appVersion": "", 
                "deviceId": "", 
                "buyBasic": 0, 
                "inAppPurcharedTime": 1677656284569 -> ngày, 
                "paymentId": "",
                "appShortName": "ged",
                "buyFullTest": 0,
                "appId": 5296397775536128,
                "orderId": "9G674214CD4629532",
                "amount": 5.99,
                "buyPrintTest": 0,
                "paymentSource": "paypal",
                "userId": "thanhtom.26122000@gmail.com",
                "emailAddress": "sb-43lc5f8692405@personal.example.com",
                "buyPrintTopic": 0,
            },]**/
            let data: any[] = action.purchases;
            let purchases: any = {};
            if (data.length > 0) {
                data.forEach((record) => {
                    for (let i in record) {
                        if (!!!purchases[i]) purchases[i] = [];
                        // if (i.search("buy") == 0 || i == "inAppPurchared") {
                        //     if (record[i] == "0") record[i] = "Not Purchased";
                        //     else if (record[i] == "1") record[i] = "PURCHASED";
                        // }
                        // if (i == "type") {
                        //     if (record[i] == "0") record[i] = "One-time";
                        //     else if (record[i] == "1")
                        //         record[i] = "Subscription";
                        // }
                        // if (i == "lastUpdate" || i == "inAppPurcharedTime") {
                        //     if (!!record[i])
                        //         record[i] = convertDate(record[i])
                        //             ?.toLocaleString()
                        //             .toString();
                        // }
                        purchases[i] = [...purchases[i], ...[record[i]]];
                    }
                });
            }
            state.purchases[0] = purchases;
            return {
                ...state,
            };
        case ToolAnalysisTypes.GET_PURCHASE_INFORMATION_BY_TRANSACTION_SUCCESS:
            /**
                 [
                    {
                        "transaction_info": {
                            "paypal_account_id": "8P995E5KZDJ5E",
                            "transaction_id": "92518438L73024633",
                            "paypal_reference_id": "I-ANNXGSKE0N4R",
                            "paypal_reference_id_type": "SUB",
                            "transaction_event_code": "T0002",
                            "transaction_initiation_date": "2023-03-20T11:34:26+0000",
                            "transaction_updated_date": "2023-03-20T11:34:26+0000",
                            "transaction_amount": {
                                "currency_code": "USD",
                                "value": "2.99"
                            },
                            "fee_amount": {
                                "currency_code": "USD",
                                "value": "-0.43"
                            },
                            "transaction_status": "S",
                            "transaction_subject": "Servsafe 7-day subscription",
                            "protection_eligibility": "02"
                        }
                    },
                ]
                */
            let transaction_details: any[] = action.purchases;
            let transaction_data: any = {};
            if (transaction_details.length > 0) {
                transaction_details.forEach((rcd) => {
                    let record = rcd.transaction_info;
                    for (let i in record) {
                        if (!!!transaction_data[i]) transaction_data[i] = [];
                        if (i == "transaction_amount" || i == "fee_amount")
                            record[i] = record[i].currency_code + " : " + record[i].value;
                        if (i == "transaction_initiation_date" || i == "transaction_updated_date")
                            record[i] = convertDate(record[i])?.toLocaleString().toString();
                        transaction_data[i] = [...transaction_data[i], ...[record[i]]];
                    }
                });
            }
            state.purchases[1] = transaction_data;
            return {
                ...state,
            };
        case ToolAnalysisTypes.SET_DATA_PROCESSING:
            return {
                ...state,
                getDataProcessing: action.getDataProcessing,
            };

        case ToolAnalysisTypes.UPDATE_PURCHASE_SUCCESS_ACTION:
            let currentPurchase = state.purchases[0];
            let index = currentPurchase?.orderId?.findIndex((i: any) => i === action.purchase.orderId);
            let editedField = [...SelectableField, ...EditableField];
            for (let key in currentPurchase) {
                if (editedField.includes(key)) {
                    let field = currentPurchase[key];
                    field[index] = action.purchase[key];
                    currentPurchase[key] = field;
                }
            }
            state.purchases[0] = currentPurchase;
            return {
                ...state,
            };
        case ToolAnalysisTypes.SET_MESSAGE:
            state.message = action.message ?? "";
            state.severity = action.severity ?? "success";
            return { ...state };
        case ToolAnalysisTypes.REMOVE_MESSAGE:
            state.message = "";
            return { ...state };
        default:
            return state;
    }
};

export default toolAnalysisState;
