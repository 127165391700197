import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import { List } from "@mui/material";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { IAuthState } from "../../../redux/reducers/auth.reducer";
import { IPersonalsGoalState } from "../../../redux/reducers/personalGoal.reducer";
import Config from "../../../shared/models/config";
import { IPersonalsGoal, PersonalsGoal } from "../../../shared/models/personalsGoal";
import { ISprint } from "../../../shared/models/sprint";
import { ITaskScores } from "../../../shared/models/taskScore";
import { IUserExpectation } from "../../../shared/models/userExpectation";
import { isAdmin, isLeader } from "../../../shared/permissions";
import { handleSortPriority } from "../../../utils";
import PersonalsGoalModal from "./GoalModal";
import PersonalExpectationItem from "./PersonalExpectationItem";
import DeleteGoalModal from "./DeleteGoalModal";

const PersonalGoalWidget = memo(
    ({
        title,
        taskScores,
        expectation,
        currentSprint,
        disciplinePoints,
    }: {
        title: string;
        taskScores: ITaskScores;
        expectation: IUserExpectation;
        currentSprint: ISprint;
        disciplinePoints: any;
    }) => {
        const [dataItem, setDataItem] = useState<IPersonalsGoal | undefined>();
        const closeDeleteModal = () => {
            setShowDeleteModal(false);
        };
        const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
        const activeSprint = sprints.find((sprint) => sprint.activeSprint === true);
        const userInfoState: IAuthState = useSelector((state: any) => state.authState);
        const userInfo: any = userInfoState?.user ?? [];
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const personalsGoalState: IPersonalsGoalState = useSelector((state: any) => state.personalsGoalState);
        const personalsGoal = personalsGoalState?.personalsGoal ?? [];

        const onHide = () => {
            setDataItem(undefined);
        };
        const [dataDelete, setDataDelete] = useState();
        const handleDeleteItem = (data: any) => {
            setDataDelete(data);
            setShowDeleteModal(true);
        };

        const handleEditItem = (data: IPersonalsGoal) => {
            setDataItem(data);
        };
        let dem = 1;
        handleSortPriority(personalsGoal);
        return (
            <div className="entity-expected background-white-008 radius-8">
                <div className=" white background-white-008 radius-8 label align-item-center">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            width: "100%",
                        }}
                    >
                        <div></div>
                        <span>{title}</span>
                        <div
                            style={{
                                alignItems: "end",
                                cursor: "pointer",
                            }}
                        >
                            <AddIcon
                                onClick={() => {
                                    setDataItem(new PersonalsGoal());
                                }}
                            />
                        </div>
                    </div>
                </div>
                <List sx={{ overflow: "auto", maxHeight: 120 }} className="scrollbarStyles">
                    {!(isAdmin(userInfo) || isLeader(userInfo)) && (
                        <div>
                            <PersonalExpectationItem
                                taskScores={taskScores}
                                expectation={expectation}
                                currentSprint={currentSprint}
                                disciplinePoints={disciplinePoints}
                            />
                            <div
                                style={{
                                    width: "100%",
                                    padding: "15px",
                                    borderBottom: "1px solid #565680",
                                }}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            color: "white",
                            width: "100%",
                            padding: "0 8px",
                        }}
                    >
                        {personalsGoal
                            .filter((d) => {
                                return (
                                    d.userID === userInfo._id &&
                                    d.type === "individual" &&
                                    !(d.status === "done" && d.sprintID !== activeSprint?._id)
                                );
                            })
                            .map((data, index) => (
                                <div
                                    key={index + ""}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px 0",
                                        borderBottom: "1px solid #565680 ",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "calc(100% - 150px)",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "20xp",
                                                height: "20px",
                                            }}
                                            src={"/" + data.priority + "_priority.svg"}
                                        />
                                        <div
                                            style={{
                                                paddingLeft: "10px",
                                                width: "100%",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <span>{dem++} . </span>
                                            <span>{data.title}</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        {data && (
                                            <span
                                                style={{
                                                    color:
                                                        data.status === "to do"
                                                            ? Config.COLOR_TODO_GOAL
                                                            : data.status === "in progress"
                                                            ? Config.COLOR_IN_PROGRESS_GOAL
                                                            : Config.COLOR_DONE_GOAL,
                                                }}
                                            >
                                                ({data.status})
                                            </span>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <ModeIcon
                                                onClick={() => handleEditItem(data)}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            />

                                            {!(
                                                data.type === "individual" &&
                                                data.creatorID !== data.userID &&
                                                userInfo._id !== data.creatorID
                                            ) ? (
                                                <DeleteForeverIcon
                                                    onClick={() => handleDeleteItem(data)}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            ) : (
                                                <DeleteForeverIcon
                                                    style={{
                                                        color: "gray",
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {showDeleteModal && <DeleteGoalModal goal={dataDelete!} onHide={closeDeleteModal} />}
                    </div>
                </List>
                {dataItem && (
                    <>
                        <PersonalsGoalModal
                            onHide={onHide}
                            data={dataItem}
                            team={"individual"}
                            userID={userInfo._id}
                            creatorID={userInfo._id}
                        />
                    </>
                )}
            </div>
        );
    }
);
export default PersonalGoalWidget;
