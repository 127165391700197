import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import { List } from "@mui/material";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAuthState } from "../../../redux/reducers/auth.reducer";
import { IPersonalsGoalState } from "../../../redux/reducers/personalGoal.reducer";
import Config from "../../../shared/models/config";
import { IPersonalsGoal, PersonalsGoal } from "../../../shared/models/personalsGoal";
import { IUserGroup } from "../../../shared/models/userGroup";
import { handleSortPriority } from "../../../utils";
import PersonalsGoalModal from "./GoalModal";
import DeleteGoalModal from "./DeleteGoalModal";
import { ISprint } from "../../../shared/models/sprint";

const TeamGoalWidget = memo(({ title }: { title: string }) => {
    const [dataItem, setDataItem] = useState<IPersonalsGoal | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    const onHide = () => {
        setDataItem(undefined);
    };
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const activeSprint = sprints.find((sprint) => sprint.activeSprint === true);
    const userInfoState: IAuthState = useSelector((state: any) => state.authState);
    const userInfo: any = userInfoState?.user ?? [];
    const personalsGoalState: IPersonalsGoalState = useSelector((state: any) => state.personalsGoalState);
    const personalsGoal = personalsGoalState?.personalsGoal ?? [];
    const handleEditItem = (data: IPersonalsGoal) => {
        setDataItem(data);
    };
    const isUserGroups: IUserGroup[] = userGroups.filter((group) => group.users.some((user) => user.userId === userInfo._id));
    let nameTeam: string[] = isUserGroups.map((data, index) => {
        return data.name;
    });
    let filterPersonalsGoal: PersonalsGoal[] = personalsGoal;
    if (!(userInfo.role === Config.USER_ROLE_ADMIN)) {
        filterPersonalsGoal = personalsGoal.filter((item) => {
            let teams = item.teams ?? [];
            let check = false;
            teams.forEach((name) => {
                if (nameTeam.find((n) => n === name)) {
                    /// name la co ton tai
                    check = true;
                }
            });
            return check;
        });
    }
    const [dataDelete, setDataDelete] = useState();
    const handleDeleteItem = (data: any) => {
        setDataDelete(data);
        setShowDeleteModal(true);
    };
    let dem = 1;
    handleSortPriority(personalsGoal);
    const setDisabled: boolean = !(userInfo.role === Config.USER_ROLE_ADMIN) && !(userInfo.role === Config.USER_ROLE_LEADER);
    return (
        <div className="entity-expected background-white-008 radius-8">
            <div className=" white background-white-008 radius-8 label align-item-center">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                    }}
                >
                    <div></div>
                    <span>{title}</span>
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        {(userInfo.role === Config.USER_ROLE_ADMIN || userInfo.role === Config.USER_ROLE_LEADER) && (
                            <AddIcon
                                onClick={() => {
                                    setDataItem(new PersonalsGoal());
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className=" white ">
                <List sx={{ overflow: "auto", maxHeight: 140 }} className="scrollbarStyles">
                    <div
                        style={{
                            padding: "0 8px",
                        }}
                    >
                        {filterPersonalsGoal
                            .filter((dt) => {
                                return (
                                    (!(dt.status === "done" && dt.sprintID !== activeSprint?._id) && dt.type === "team") ||
                                    (dt.type === "team" &&
                                        userInfo.role === Config.USER_ROLE_ADMIN &&
                                        !(dt.status === "done" && dt.sprintID !== activeSprint?._id))
                                );
                            })
                            .map((data, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px 0",
                                        borderBottom: "1px solid #565680 ",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            cursor: "pointer",
                                            width: "calc(100% - 150px)",
                                        }}
                                        onClick={() => (!setDisabled && data.type === "team" ? undefined : setDataItem(data))}
                                    >
                                        <img
                                            style={{
                                                width: "20xp",
                                                height: "20px",
                                            }}
                                            src={"/" + data.priority + "_priority.svg"}
                                        />
                                        <div
                                            style={{
                                                paddingLeft: "10px",
                                                width: "100%",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <span>{dem++} . </span>
                                            <span>{data.title}</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: " flex",
                                        }}
                                    >
                                        {data.status && (
                                            <span
                                                style={{
                                                    color:
                                                        data.status === "to do"
                                                            ? Config.COLOR_TODO_GOAL
                                                            : data.status === "in progress"
                                                            ? Config.COLOR_IN_PROGRESS_GOAL
                                                            : Config.COLOR_DONE_GOAL,
                                                }}
                                            >
                                                ({data.status})
                                            </span>
                                        )}

                                        {(userInfo.role === Config.USER_ROLE_ADMIN ||
                                            userInfo.role === Config.USER_ROLE_LEADER) && (
                                            <div
                                                style={{
                                                    width: "50px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <ModeIcon
                                                    onClick={() => handleEditItem(data)}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />

                                                <DeleteForeverIcon
                                                    onClick={() => handleDeleteItem(data)}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        {showDeleteModal && <DeleteGoalModal goal={dataDelete!} onHide={closeDeleteModal} />}
                    </div>
                </List>
            </div>

            {dataItem && (
                <>
                    <PersonalsGoalModal
                        onHide={onHide}
                        data={dataItem}
                        team={"team"}
                        nameTeam={nameTeam}
                        userID={userInfo._id}
                        creatorID={userInfo._id}
                    />
                </>
            )}
        </div>
    );
});
export default TeamGoalWidget;
