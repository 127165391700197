import './styles.scss';
import ShoppeingRequest from '../home/RequestShopping/RequestShoppingModal';

const ShoppingRequest = () => {
    return (
        <div className="wiki container">
            <div className="documentations">
                <ShoppeingRequest/>
            </div>
        </div>
    )
}

export default ShoppingRequest;