import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { GitHubRepository, IGitHubRepository } from "../../shared/models/gitHubRepository";
import Url from "../../util/url";
import { GitHubTypes, IGitHubAction, getGitHubRepositoriesFailed, getGitHubRepositoriesSuccess } from "../actions/github.action";

export async function createBranchGitHubAPI({
    url, name, branch, organization 
}: { 
    url: string, name: string, branch: string, organization: string 
}): Promise<string> {
    return await axios.post(Url("github/create-branch"), {
        url, name, branch, organization
    }).then((data) => {
        if(data.status == 200 && data.data) {
            return data.data;
        }
        return "";
    }).catch((e) => {
        console.error("createBranchGitHubAPI error", e);
        return "";
    });
}

async function getGitHubRepositoriesAPI(organizationId: string): Promise<IGitHubRepository[] | null> {
    return await axios.get(Url("github/get-repositories?organization=" + organizationId)).then((data) => {
        if(data.status == 200 && data.data) {
            return data.data.map((item: any) => new GitHubRepository(item));
        }
        return [];
    }).catch((e) => {
        console.error("getGitHubRepositoriesAPI error", e);
        return [];
    });
}

function* getGitHubRepositoriesSaga(action: IGitHubAction) {
    if(action.organizationId) {
        try {
            const repositories: IGitHubRepository[] | null = yield call(getGitHubRepositoriesAPI, action.organizationId);
            console.log("repositories", repositories)
            if(repositories) {
                yield put(getGitHubRepositoriesSuccess(repositories));
            } else {
                yield put(getGitHubRepositoriesFailed("getGitHubRepositoriesSaga: repositories undefined!"));
            }
        } catch (err) {
            yield put(getGitHubRepositoriesFailed("getGitHubRepositoriesSaga ERROR: " + err));
        }
    } else {
        yield put(getGitHubRepositoriesFailed("getGitHubRepositoriesSaga projectId Params not found!"));
    }
}

export function* watchGitHub() {
    yield takeLatest(GitHubTypes.GET_REPOSITORIES, getGitHubRepositoriesSaga);
}
