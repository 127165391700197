import './style.scss';
import { useRef, useEffect, useState } from 'react';

const ZoomImage = ({url, closeZoomImage}) => {
    const [handleClick, setHandleClick] = useState(null);
    const ref = useRef();

    useEffect(() => {
        if(ref?.current){
            ref.current.addEventListener('click', (event) => {
                setHandleClick(event);
            })
        }
    }, [handleClick]);

    useEffect(() => {
        if (handleClick) {
            if (handleClick.target.localName !== 'img') {
                closeZoomImage();
            }
        }
    }, [handleClick]);

    return (
        <div ref={ref} className="zoom-image-modal">
            <span className="close-image-modal" onClick={closeZoomImage}>&times;</span>
            <img className="modal-image-content" src={url} />
        </div>
    )
}

export default ZoomImage;