import { convertDate } from "../utils"
import { IBasic } from "./basic"

export type AppPlatform = "content" | "android" | "ios" | "web";

export interface IAppVersion extends IBasic {
    appInfoId: string // mongo id
    appId: number // number id
    platform: AppPlatform
    version: string
    lastUpdate: Date | null
    updateInfo: string
    note: string
}

export class AppVersion implements IAppVersion {
    _id: any
    appInfoId: string // mongo id
    appId: number // number id
    platform: AppPlatform
    version: string
    lastUpdate: Date | null
    updateInfo: string
    note: string

    constructor(props: any = {}) {
        this._id = props._id;
        this.appInfoId = props.appInfoId;
        this.appId = props.appId;
        this.platform = props.platform;
        this.version = props.version;
        this.lastUpdate = convertDate(props.lastUpdate);
        this.updateInfo = props.updateInfo;
        this.note = props.note;
    }
}