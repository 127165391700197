import { IBasic } from "./basic";
import { ITask, Task } from "./task";
import { IUserInfo, UserInfo } from "./userInfo";

export interface ITestCase extends IBasic {
    title: string;
    userID: string;
    user?: IUserInfo;
    taskID: string;
    task?: ITask;
    sectionID: string;
    lastUpdate: number;
    priority: number;
    steps: string;
    expectedResult: string;
    note: string;
    testIndex: number;
}

export class TestCase implements ITestCase {
    _id: string;
    title: string;
    userID: string;
    user?: IUserInfo;
    taskID: string;
    task?: ITask;
    sectionID: string;
    lastUpdate: number;
    priority: number;
    steps: string;
    expectedResult: string;
    note: string;
    testIndex: number;
    constructor(object: any = {}) {
        this._id = object._id ?? object.id;
        this.title = object.title;
        let userID: string = "";
        if (typeof object.user === "string") {
            userID = object.user;
        } else if (typeof object.user === "object") {
            this.user = new UserInfo(object.user);
            userID = this.user._id;
        } else if (typeof object.userID === "string") {
            userID = object.userID;
        }
        this.userID = userID;
        let taskID: string = "";
        if (typeof object.task === "string") {
            taskID = object.task;
        } else if (typeof object.task === "object") {
            this.task = new Task(object.task);
            taskID = this.task._id;
        } else if (typeof object.taskID === "string") {
            taskID = object.taskID;
        }
        this.taskID = taskID;
        this.sectionID = object.sectionID;
        this.lastUpdate = object.lastUpdate;
        this.priority = object.priority ?? 1;
        this.steps = object.steps ?? "";
        this.expectedResult = object.expectedResult ?? "";
        this.note = object.note ?? "";
        this.testIndex = object.testIndex ?? 0;
    }
}
