import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import Url from "../../util/url";
import {
    PersonalsGoal,
    IPersonalsGoal,
} from "../../shared/models/personalsGoal";
import {
    IPersonalsGoalAction,
    PersonalsGoalTypes,
    deleteDataPersonalsGoalFailed,
    deleteDataPersonalsGoalSuccess,
    getDataPersonalsGoalFailed,
    getDataPersonalsGoalSuccess,
    updateDataPersonalsGoalFailed,
    updateDataPersonalsGoalSuccess,
} from "../actions/personalsGoal.action";

async function getDataPersonalsGoalAPI(): Promise<IPersonalsGoal[]> {
    return await axios.get(Url("personalsGoal/get-data")).then((data) => {
        if (data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new PersonalsGoal(item);
            });
        }
        return [];
    });
}
function* getDataPersonalsGoalSaga(action: IPersonalsGoalAction) {
    try {
        let dataPersonalGoal: IPersonalsGoal[] = yield call(
            getDataPersonalsGoalAPI
        );
        if (dataPersonalGoal) {
            yield put(getDataPersonalsGoalSuccess(dataPersonalGoal));
        } else {
            yield put(getDataPersonalsGoalFailed("userID not existed!"));
        }
    } catch (err) {
        yield put(getDataPersonalsGoalFailed("userID ERROR: " + err));
    }
}
async function deletePersonalsGoalAPI(id: string): Promise<string | null> {
    return await axios
        .post(Url("personalsGoal/delete-data"), { id })
        .then((data) => {
            if (data.status == 200) {
                return id;
            }
            return null;
        });
}
function* deletePersonalsGoalSaga(action: IPersonalsGoalAction) {
    const personalsGoal = action.personalsGoal;
    if (personalsGoal) {
        try {
            const personalsGoals: string | null = yield call(
                deletePersonalsGoalAPI,
                personalsGoal._id
            );
            if (personalsGoals) {
                yield put(deleteDataPersonalsGoalSuccess(personalsGoal));
            } else {
                yield put(
                    deleteDataPersonalsGoalFailed("personalsGoals not existed!")
                );
            }
        } catch (err) {
            yield put(
                deleteDataPersonalsGoalFailed("personalsGoals ERROR: " + err)
            );
        }
    } else {
        yield put(
            deleteDataPersonalsGoalFailed(
                "update personalsGoals ERROR: appInfo not found!"
            )
        );
    }
}
async function updatePersonalsGoalAPI(
    personalsGoal: IPersonalsGoal
): Promise<IPersonalsGoal | null> {
    return await axios
        .post(Url("personalsGoal/update-data"), { data: personalsGoal })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new PersonalsGoal(data.data[0]);
            }
            return null;
        });
}
function* updatePersonalsGoalSaga(action: IPersonalsGoalAction) {
    const personalsGoal = action.personalsGoal;
    if (personalsGoal) {
        try {
            const personalsGoals: IPersonalsGoal = yield call(
                updatePersonalsGoalAPI,
                personalsGoal
            );
            if (personalsGoals) {
                yield put(updateDataPersonalsGoalSuccess(personalsGoals));
            } else {
                yield put(
                    updateDataPersonalsGoalFailed("personalsGoal not existed!")
                );
            }
        } catch (err) {
            yield put(
                updateDataPersonalsGoalFailed("personalsGoalSaga ERROR: " + err)
            );
        }
    } else {
        yield put(
            updateDataPersonalsGoalFailed(
                "update personalsGoalSaga ERROR: appInfo not found!"
            )
        );
    }
}
export function* watchPersonalsGoal() {
    yield takeLatest(
        PersonalsGoalTypes.GET_DATA_PERSONALS,
        getDataPersonalsGoalSaga
    );
    yield takeLatest(
        PersonalsGoalTypes.DELETE_DATA_PERSONALS,
        deletePersonalsGoalSaga
    );
    yield takeLatest(
        PersonalsGoalTypes.UPDATE_DATA_PERSONALS,
        updatePersonalsGoalSaga
    );
}
