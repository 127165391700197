import { FC } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ICampaign } from "../../../../../shared/models/campaign";
import { convertCampaignOptions } from "../../../../../utils";

const SelectCampaignsWidget: FC<{
    ids: string[];
    onChange: (ids: string[]) => void;
}> = ({ ids, onChange }) => {
    const campaigns: ICampaign[] = useSelector((state: any) => state.campaignState.campaigns ?? []);
    const options = convertCampaignOptions(campaigns);
    return (
        <Select
            isMulti
            isClearable={false}
            closeMenuOnSelect={false}
            className="select-campaign"
            options={options}
            onChange={(newValue, action) => {
                if (action.action === "clear") {
                    onChange([]);
                } else if (action.action === "remove-value") {
                    const index = ids.indexOf(action.removedValue.value);
                    if (index > -1) {
                        ids.splice(index, 1);
                        onChange([...ids]);
                    }
                } else if (action.action === "select-option" && action.option) {
                    const index = ids.indexOf(action.option.value);
                    if (index == -1) {
                        onChange([...ids, action.option.value]);
                    }
                }
            }}
            value={options.filter((u) => ids.includes(u.value))}
        />
    );
};

export default SelectCampaignsWidget;
