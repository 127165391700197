import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Constants from "../../../constant";
import { ISprint } from "../../../shared/models/sprint";
import { IUserExpectation } from "../../../shared/models/userExpectation";
import { IUserInfo } from "../../../shared/models/userInfo";
import { getUserTaskScores } from "../../../utils";
import MyChart, { Dataset } from "./../chart/MyChart";
const WorkingChartArea = ({
    user,
    mapSprint,
    userReportData,
    expectation,
}: {
    user: IUserInfo;
    mapSprint: { [key: string]: ISprint };
    userReportData: any[];
    expectation: IUserExpectation[];
}) => {
    let { label, taskDataset, pointDataset, taskRateDataset } = getTaskDataset(
        user,
        mapSprint,
        userReportData,
        expectation.filter((item) => item.userId === user._id)
    );
    return (
        <div className="working-chart-area">
            <h3 className="area-title">Thống kê tình hình công việc của {user?.firstName + " " + user?.lastName}</h3>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MyChart
                            title="Task"
                            type="bar"
                            xAxis={label}
                            dataSet={taskDataset}
                            stacked={{
                                xTitle: "sprint",
                                yTitle: "task",
                                unit: "",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MyChart
                            title="Point"
                            type="stackedbar-line"
                            xAxis={label}
                            dataSet={pointDataset}
                            stacked={{
                                stackedX: true,
                                suggestedMax: 100,
                                xTitle: "sprint",
                                yTitle: "point",
                                unit: "point",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MyChart
                            title="Tỷ lệ hoàn thành"
                            type="stackedbar-line"
                            xAxis={label}
                            dataSet={taskRateDataset}
                            stacked={{
                                suggestedMax: 100,
                                xTitle: "sprint",
                                yTitle: "%",
                                unit: "%",
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

const getTaskDataset = (
    user: IUserInfo,
    mapSprint: { [key: string]: ISprint },
    userReportData: any[],
    expectation: IUserExpectation[]
) => {
    let userId = user._id;
    let taskDataset: Dataset[] = [];
    let pointDataset: Dataset[] = [];
    let taskRateDataset: Dataset[] = [];
    let label: string[] = [];
    try {
        if (userReportData && user?._id) {
            let totalTasksPerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
            };
            let tasksDonePerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
            };
            let taskDoneInDeadlinePerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
            };
            let KPIpointPerSprint: Dataset = {
                label: "Mục tiêu point hoàn thành",
                dataSet: [],
                type: "line",
                color: Constants.BAR_COLOR[0],
            };
            let pointDonePerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[0],
            };

            let pointPerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
            };

            let KPIdoneTasksPercentage: Dataset = {
                label: "Mục tiêu tỷ lệ hoàn thành task",
                dataSet: [],
                type: "line",
                color: Constants.BAR_COLOR[2],
            };

            let KPItaskDoneInDeadlinePercentage: Dataset = {
                label: "Mục tiêu tỷ lệ hoàn thành task đúng deadline",
                dataSet: [],
                type: "line",
                color: Constants.BAR_COLOR[3],
            };

            let doneTasksPercentagePerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[2],
            };

            let taskDoneInDeadlinePercentagePerSprint: Dataset = {
                label: "",
                dataSet: [],
                type: "bar",
                color: Constants.BAR_COLOR[3],
            };
            for (let expect of expectation) {
                KPIpointPerSprint.dataSet.push(expect.pointDone);
                KPIdoneTasksPercentage.dataSet.push(expect.doneTasksPercentage);
                KPItaskDoneInDeadlinePercentage.dataSet.push(expect.doneTasksInDeadlinePercentage);
            }

            for (let item of userReportData) {
                label.push(mapSprint[item.sprintId].name);

                let userTaskScore = getUserTaskScores({
                    currentSprint: mapSprint[item.sprintId],
                    users: [user],
                    newSprintReports: { [userId]: userReportData },
                    onlyReports: true,
                });
                let taskScores = userTaskScore[userId];
                totalTasksPerSprint.label = taskScores.totalTaskInSprint.label;
                totalTasksPerSprint.dataSet.push(taskScores.totalTaskInSprint.value);
                tasksDonePerSprint.label = taskScores.tasksDone.label;
                tasksDonePerSprint.dataSet.push(taskScores.tasksDone.value);
                taskDoneInDeadlinePerSprint.label = taskScores.taskDoneInDeadline.label;
                taskDoneInDeadlinePerSprint.dataSet.push(taskScores.taskDoneInDeadline.value);

                pointDonePerSprint.label = taskScores.pointDone.label;
                pointDonePerSprint.dataSet.push(taskScores.pointDone.value);

                pointPerSprint.label = taskScores?.totalPoint?.label ?? "";
                pointPerSprint.dataSet.push(taskScores?.totalPoint?.value ?? 0);

                doneTasksPercentagePerSprint.label = taskScores?.taskDonePercentage?.label ?? "";
                doneTasksPercentagePerSprint.dataSet.push(taskScores?.taskDonePercentage?.value ?? 0);
                taskDoneInDeadlinePercentagePerSprint.label = taskScores?.taskDoneInDeadlinePercentage?.label ?? "";
                taskDoneInDeadlinePercentagePerSprint.dataSet.push(taskScores?.taskDoneInDeadlinePercentage?.value ?? 0);
            }

            taskDataset = [totalTasksPerSprint, tasksDonePerSprint, taskDoneInDeadlinePerSprint];

            pointDataset = [KPIpointPerSprint, pointDonePerSprint, pointPerSprint];

            taskRateDataset = [
                KPIdoneTasksPercentage,
                KPItaskDoneInDeadlinePercentage,
                doneTasksPercentagePerSprint,
                taskDoneInDeadlinePercentagePerSprint,
            ];
        }
        return { label, taskDataset, pointDataset, taskRateDataset };
    } catch (e) {
        return {
            label: [],
            taskDataset: [],
            pointDataset: [],
            taskRateDataset: [],
        };
    }
};

export default WorkingChartArea;
