import { convertDate } from "../utils";
import { IBasic } from "./basic";

export interface IAppChannelHistory extends IBasic {
    userID: string;
    from: string;
    to: string;
    field: string;
    time: Date;
}
export class AppChannelHistory implements IAppChannelHistory {
    _id: any;
    userID: string;
    from: string;
    to: string;
    field: string;
    time: Date;
    constructor(props: any = {}) {
        this._id = props.id ?? props._id ?? null;
        this.field = props.field ?? "";
        this.to = props.to ?? "";
        this.from = props.from ?? "";
        this.userID = props.userID ?? "";
        this.time = convertDate(props.time) ?? new Date();
    }
}

export interface IAppChannel extends IBasic {
    messageId: string;
    types: string[];
    title: string;
    content: string;
    appName: string;
    appId: string;
    platform: string;
    rating: number;
    messageShortId: number;
    createDate: Date;
    lastUpdate: string;
    status: string;
    manager: string[];
    histories: AppChannelHistory[];
}
export class AppChannel implements IAppChannel {
    _id: any;
    messageId: string;
    types: string[];
    title: string;
    content: string;
    appName: string;
    appId: string;
    platform: string;
    rating: number;
    messageShortId: number;
    createDate: Date;
    lastUpdate: string;
    status: string;
    manager: string[];
    histories: AppChannelHistory[];
    constructor(props: any = {}) {
        this._id = props._id;
        this.messageId = props.messageId ?? "";
        this.types = props.types ?? "";
        this.title = props.title ?? "";
        this.content = props.content ?? "";
        this.appName = props.appName ?? "";
        this.appId = props.appId ?? "";
        this.platform = props.platform ?? "";
        this.rating = props.rating ?? 0;
        this.messageShortId = props.messageShortId ?? 0;
        this.createDate = convertDate(props.createDate) ?? new Date();
        this.lastUpdate = props.lastUpdate ?? "";
        this.status = props.status ?? "";
        this.manager = props.manager ?? [];
        this.histories = [];
        if (typeof props.histories === "object") {
            for (let history of props.histories) {
                this.histories.push(new AppChannelHistory(history));
            }
        }
    }
}
