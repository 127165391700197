import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import Url from "../../util/url";
import {
    IUserInfoAction,
    UserInfoTypes,
    getAllUserInfoFailed,
    getAllUserInfoSuccess,
    updateUserInfoFailed,
    updateUserInfoSuccess,
} from "../actions/userInfo.action";
import { getAllExpectationsSuccess } from "../actions/userExpectation.action";
import { roleForPoint } from "../../shared/utils";
import {
    IUserExpectation,
    UserExpectation,
} from "../../shared/models/userExpectation";
import { toast } from "react-toastify";

async function updateUserInfosAPI(
    userInfos: IUserInfo[]
): Promise<IUserInfo[]> {
    let userInfosUpdated: IUserInfo[] = [];
    for (let userInfo of userInfos) {
        let user = await axios
            .patch(Url("user/update"), {
                userInfo,
            })
            .then((data) => {
                return data?.data?.response
                    ? new UserInfo(data?.data?.response)
                    : null;
            });
        user && userInfosUpdated.push(user);
    }
    return userInfosUpdated;
}

export function* updateUsersInfoSaga(action: IUserInfoAction) {
    let userInfos = action.userInfos;
    if (userInfos) {
        try {
            let newUsers: IUserInfo[] = yield call(
                updateUserInfosAPI,
                userInfos
            );
            if (newUsers.length) {
                yield put(updateUserInfoSuccess(newUsers));
                // for (let user of newUsers) {
                //     if (user?.status !== 0) {
                //         window.open(`/user/${user._id}`);
                //     }
                // }
                toast("Cập nhật thành công", { position: "top-center" });
            } else {
                yield put(
                    updateUserInfoFailed("updateUserInfoFailed: newUsers empty")
                );
            }
        } catch (e) {
            yield put(updateUserInfoFailed("updateUserInfoFailed: " + e));
        }
    } else {
        yield put(
            updateUserInfoFailed("updateUserInfoFailed: userInfos empty")
        );
    }
}

async function getAllUserInfo() {
    // user/total
    return await axios.get(Url("user/not-disable")).then((data: any) => {
        return data?.data?.response?.map((item: any) => new UserInfo(item));
    });
}

function* getUserInfosSaga() {
    try {
        let userInfos: IUserInfo[] = yield call(getAllUserInfo);
        yield put(getAllUserInfoSuccess(userInfos));
        let expectations: IUserExpectation[] = userInfos
            .filter((u) => roleForPoint(u))
            .map((user) => {
                return new UserExpectation({
                    userId: user._id,
                    doneTasksPercentage: user.doneTasksPercentageExpect,
                    pointDone: user.pointDoneExpect,
                    doneTasksInDeadlinePercentage:
                        user.doneTasksInDeadlinePercentageExpect,
                    disciplinePoints: user.disciplinePointExpect,
                    // standarExpPerLevel: user.standarExpPerLevel,
                });
            });
        yield put(getAllExpectationsSuccess(expectations));
    } catch (err) {
        yield put(getAllUserInfoFailed(err));
    }
}

export function* watchUserInfo() {
    yield takeLatest(UserInfoTypes.UPDATE_USERS, updateUsersInfoSaga);
    yield takeLatest(UserInfoTypes.GET_ALL_USER_INFO, getUserInfosSaga);
}
