import { useState, useEffect } from "react";
import { Calendar } from "react-calendar";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./style.scss";
import { createSharingSchedule } from "../../../redux/actions/sharing.action";
import Config from "../../../shared/models/config";

const FormEdit = ({ setOpenDialog, dataEdit, setOpenPopup, setIdDelete, currentUser, dispatch }) => {
    const [dataForm, setDataForm] = useState({});
    const [openWarning, setOpenWaring] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setDataForm(dataEdit);
    }, []);

    const handleEdit = async (id) => {
        dispatch(
            createSharingSchedule(
                {
                    title: dataForm.title,
                    schedule: dataForm.schedule,
                    summary: dataForm.summary,
                },
                id
            )
        );
    };
    const handleCreate = async () => {
        dispatch(
            createSharingSchedule({
                assignee: currentUser?._id,
                title: dataForm.title,
                schedule: dataForm.schedule,
                summary: dataForm.summary,
                completed: "false",
                status: Config.EXISTING_SHARING, //existing schedule
            })
        );
    };
    const checkIdExists = (id) => {
        if (dataEdit.assignee !== "") {
            handleEdit(id);
        } else {
            handleCreate();
        }
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={() => {
                    setOpenDialog(false);
                }}
                PaperProps={{ style: { borderRadius: 20 } }}
                className="form-edit-sharing"
            >
                <DialogTitle>{"Edit schedule"}</DialogTitle>

                <DialogContent>
                    <div className="edit-knowledge-sharing">
                        <TextField
                            className="text-field"
                            type="text"
                            label="Title"
                            fullWidth
                            helperText="Please enter your title"
                            onChange={(e) => {
                                setDataForm({
                                    ...dataForm,
                                    title: e.target.value,
                                });
                            }}
                            value={dataForm.title}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                flexDirection: "column",
                            }}
                        >
                            <h6 className="text-field">Schedule:</h6>
                            <Calendar
                                locale="en-GB"
                                showWeekNumbers
                                onChange={(value) => {
                                    const today = new Date();

                                    const clickDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
                                    if (clickDate - today.setHours(0, 0, 0, 0) >= 0) {
                                        setDataForm({
                                            ...dataForm,
                                            schedule: value,
                                        });
                                    } else {
                                        setOpenWaring(true);
                                        setMessage("Đó là quá khứ rồi! ");
                                        // toast(
                                        //     <div>
                                        //         You do not have permission
                                        //     </div>
                                        // );
                                    }
                                }}
                                value={new Date(dataForm.schedule)}
                            />
                        </div>
                        <TextField
                            className="text-field"
                            label="Summary"
                            multiline
                            rows={6}
                            variant="outlined"
                            fullWidth
                            helperText="Summarize your topic here"
                            onChange={(e) => {
                                setDataForm({
                                    ...dataForm,
                                    summary: e.target.value,
                                });
                            }}
                            value={dataForm.summary}
                        />
                        <DialogActions
                            style={{
                                margin: "15px",
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <div style={{ display: currentUser?._id !== dataForm.assignee ? "none" : "block" }}>
                                <button
                                    className="btn-edit btn-delete"
                                    style={{
                                        backgroundColor: "#FF3131",
                                    }}
                                    onClick={() => {
                                        setOpenPopup(true);
                                        if (dataForm._id === "") setIdDelete(dataForm._id);
                                    }}
                                >
                                    <span>Delete</span>
                                </button>
                            </div>
                            <button
                                className="btn-edit"
                                style={{ backgroundColor: "GrayText" }}
                                onClick={() => {
                                    setOpenDialog(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn-edit"
                                style={{
                                    backgroundColor: "#38B6FF",
                                }}
                                onClick={() => {
                                    if (dataForm.title === "") {
                                        setMessage("Title không thể trống");
                                        setOpenWaring(true);
                                    } else {
                                        checkIdExists(dataForm._id);
                                        setOpenDialog(false);
                                    }
                                }}
                            >
                                <span>Submit</span>
                            </button>
                        </DialogActions>
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={openWarning}
                autoHideDuration={750}
                onClose={() => {
                    setOpenWaring(false);
                }}
            >
                <MuiAlert severity="warning" variant="filled">
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default FormEdit;
