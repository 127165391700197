import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TableCell,
    TableRow,
} from "@mui/material";
import axios from "axios";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateCycles } from "../../../../../../redux/actions/testCycle.action";
import { deleteTest } from "../../../../../../redux/actions/testCase.action";
import { ITestCase } from "../../../../../../shared/models/testCase";
import { ITestCycle } from "../../../../../../shared/models/testCycle";
import { getPriority, getPriorityColor } from "../../../../../../util";
import Url from "../../../../../../util/url";
import "./style.scss";

const Row: FC<{
    row: ITestCase;
    handleClick: any;
    permission: boolean;
    cycles: ITestCycle[];
}> = ({ row, handleClick, permission, cycles }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openDropDown, setOpenDropDown] = useState<boolean | undefined>(
        false
    );
    const [deleteItem, setDeleteItem] = useState<ITestCase | null>(null);
    const dispatch = useDispatch();

    const deleteCaseinCycles = (id: any) => {
        const tempCycles = cycles.slice();
        return tempCycles.map((cycle) => ({
            ...cycle,
            cases: cycle.cases.filter((testCase) => testCase._id !== id),
        }));
    };

    // Handle Delete Test
    const handleDelete = async () => {
        try {
            const arrayChange = deleteCaseinCycles(deleteItem?._id);
            await axios.delete(Url("test-case/deletes-test-cases"), {
                data: [deleteItem?._id],
            });
            if (arrayChange.length > 0) {
                await axios.post(
                    Url("test-case/updates-test-cycles"),
                    arrayChange
                );
                dispatch(updateCycles(arrayChange));
            }
            dispatch(deleteTest(deleteItem));
            setOpenDeleteModal(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleClickOpen = (deleteItem: ITestCase) => {
        if (!permission) {
            toast("Bạn không có quyền xóa test case này", {
                position: "top-center",
            });
            return;
        }
        setOpenDeleteModal(true);
        setDeleteItem(deleteItem);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    return (
        <>
            <TableRow
                hover
                onClick={() => setOpenDropDown(!openDropDown)}
                className="row-info"
                sx={{ "& > *": { borderBottom: "unset" } }}
            >
                <TableCell component="th" scope="row">
                    {row.testIndex}
                </TableCell>
                <TableCell className="name">{row.title}</TableCell>
                <TableCell align="center">
                    <div
                        className="priority"
                        style={{
                            border: `2px solid ${getPriorityColor(
                                row.priority
                            )}`,
                        }}
                    >
                        <span>{getPriority(row.priority)}</span>
                    </div>
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        color="info"
                        onClick={() => handleClick(row)}
                        component="span"
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleClickOpen(row)}
                        component="span"
                        disabled={!permission}
                    >
                        <DeleteOutlined />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className="row-dropdown">
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={openDropDown} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <>
                                <div className="row_2">
                                    <h6>Description: </h6>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: row.steps,
                                        }}
                                    ></div>
                                </div>

                                <div className="row_3">
                                    <h6>Expected Result: </h6>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: row.expectedResult,
                                        }}
                                    ></div>
                                </div>
                            </>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {deleteItem && (
                <Dialog open={openDeleteModal} onClose={handleClose}>
                    <DialogTitle>Are you sure ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete test case "
                            <b>{deleteItem.title}</b>" ?
                            <br />
                            <br />
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default Row;
