import {
    getUserFromJson,
} from "../utils";
import { ITask } from "./task";

export interface IProblemReporting {
    _id: any;
    reportingDate: string;
    lastUpdateDate: string;
    title: string;
    appWebName: string;
    type: string;
    problems: string;
    attachment: string[];
    state: string;
    priority: string;
    sprint: string;
    assigneeId: string;
    problem_taskId: string;

}

export class ProblemReporting implements IProblemReporting {
    _id: any;
    reportingDate: string;
    lastUpdateDate: string;
    title: string;
    appWebName: string;
    type: string;
    problems: string;
    attachment: string[];
    state: string;
    priority: string;
    sprint: string;
    assigneeId: string;
    problem_taskId: string;

    constructor(props: any) {
        this._id = props._id;
        this.appWebName = props.appWebName;
        this.attachment = props.attachment;
        this.title  = props.title;
        this.problems = props.problems;
        this.reportingDate = props.reportingDate;
        this.lastUpdateDate = props.lastUpdateDate;
        this.type   = props.type;
        this.state = props.state;
        this.priority = props.priority;
        this.sprint = props.sprint;
        this.assigneeId = props.assigneeId;
        this.problem_taskId = props.problem_taskId;
    }

    toJson(): any {
        // convert về dạng dữ liệu cũ
        return {
            _id: this._id,
        appWebName: this.appWebName,
        attachment: this.attachment,
        title: this.title,
        problems: this.problems,
        reportingDate: this.reportingDate,
        lastUpdateDate: this.lastUpdateDate,
        type: this.type,
        state: this.state,
        priority: this.priority,
        sprint: this.sprint,
        assigneeId: this.assigneeId,
        problem_taskId: this.problem_taskId,
        }
    }
}

