import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartOptionConfig, Dataset, configTooltip } from "./MyChart";
import Constants from "../../../constant";
import { hexToRgbA } from "../../../utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getOption = (
    title: string,
    config: ChartOptionConfig = {
        stackedX: false,
        stackedY: false,
        suggestedMax: 100,
        xTitle: "",
        yTitle: "",
        unit: "",
    }
) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: configTooltip(config.unit),
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: config.xTitle,
                },
            },
            y: {
                suggestedMax: config.suggestedMax,
                title: {
                    display: true,
                    text: config.yTitle,
                },
            },
        },
    };
};

const convertData = (labels: string[], data: Dataset[]) => {
    let _data = {
        labels,
        datasets: data.map((item, index) => {
            return {
                label: item.label,
                data: item.dataSet,
                borderColor: item?.color ?? Constants.BAR_COLOR[index],
                backgroundColor: hexToRgbA(item?.color ?? Constants.BAR_COLOR[index], 0.8),
            };
        }),
    };
    return _data;
};

export function BarChart({
    title,
    xAxis,
    dataset,
    stacked,
}: {
    title: string;
    xAxis: string[];
    dataset: Dataset[];
    stacked?: ChartOptionConfig;
}) {
    const _data = convertData(xAxis, dataset);
    return <Bar options={getOption(title, stacked)} data={_data} />;
}
