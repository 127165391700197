import { useEffect, useState } from "react";
import { IconButton, Grid } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import "./style.scss";
import FormEdit from "./edit";
import WeekList from "./week-list";
import AssigneeList from "./assignee-list";
import PopupDelete from "./popup-delete";
import TableData from "./table-data";
import { useDispatch, useSelector } from "react-redux";
import { getSharingSchedule, getUserDataSharing, addScoreSharing } from "../../redux/actions/sharing.action";
import Config from "../../shared/models/config";

const KnowledgeSharing = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.authState?.user);
    const dataTable = useSelector((state) => state.sharingState?.sharingDatas);

    const [weeks, setWeeks] = useState([]); //repository data Week
    const [idDelete, setIdDelete] = useState([]);
    const [showTable, setShowTable] = useState([]);
    const [dataEdit, setDataEdit] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [arrAssignee, setArrAssignee] = useState([]);
    const [arrWeek, setArrWeek] = useState([]);

    // useEffect
    useEffect(() => {
        dispatch(getSharingSchedule());
        dispatch(getUserDataSharing());
    }, []);
    useEffect(() => {
        showWeeks();
        handleFilter(arrAssignee, arrWeek);
    }, [arrAssignee, arrWeek, dataTable]);

    const showWeeks = () => {
        const arr = dataTable.map((item) => {
            const date = new Date(item.schedule);
            const year1 = date.getFullYear();
            return { year: year1, week: item.week };
        });
        const uniqueWeeksSet = new Set();
        const uniqueObjects = [];
        arr.forEach((item) => {
            if (!uniqueWeeksSet.has(item.week)) {
                uniqueWeeksSet.add(item.week);
                uniqueObjects.push(item);
            }
        });
        const sortedArray = uniqueObjects.slice().sort((a, b) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            } else {
                return a.week - b.week;
            }
        });
        setWeeks(sortedArray);
    };

    const handleUpdateSharingScore = async (id, status) => {
        dispatch(addScoreSharing(id, status));
    };

    const addAssigneeArray = (useId) => {
        const cloneList = [...arrAssignee];
        const index = arrAssignee.indexOf(useId);
        if (index > -1) {
            cloneList.splice(index, 1);
        } else {
            cloneList.push(useId);
        }
        setArrAssignee(cloneList);
    };
    const addWeekArray = (weekNumber) => {
        const cloneList = [...arrWeek];
        const index = arrWeek.indexOf(weekNumber);
        if (index > -1) {
            cloneList.splice(index, 1);
        } else {
            cloneList.push(weekNumber);
        }
        setArrWeek(cloneList);
    };
    const handleFilter = (arrayA, arrayW) => {
        let filteredResult = dataTable;
        if (arrayW.length != 0) {
            filteredResult = filteredResult.filter((item) => arrayW.includes(item.week));
        } else {
            setShowTable(dataTable);
        }
        if (arrayA.length != 0) {
            filteredResult = filteredResult.filter((item) => arrayA.includes(item.assignee));
        } else {
            setShowTable(dataTable);
        }
        setShowTable(filteredResult);
    };
    useEffect(() => {
        const arr = dataTable.map((item) => {
            const date = new Date(item.schedule);
            const year1 = date.getFullYear();
            return { year: year1, week: item.week };
        });
        const uniqueWeeksSet = new Set();
        const uniqueObjects = [];
        arr.forEach((item) => {
            if (!uniqueWeeksSet.has(item.week)) {
                uniqueWeeksSet.add(item.week);
                uniqueObjects.push(item);
            }
        });
        const sortedArray = uniqueObjects.slice().sort((a, b) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            } else {
                return a.week - b.week;
            }
        });
    }, [dataTable]);

    return (
        <div>
            <div className="new-knowledge-sharing">
                <div className="cluster-btn-create">
                    <IconButton
                        size="large"
                        aria-label="add"
                        className="btn-create"
                        onClick={() => {
                            setOpenDialog(true);
                            const currentDate = new Date();
                            if (currentDate.getDay() === 5) {
                                currentDate.setHours(0, 0, 0, 0);
                                setDataEdit({
                                    title: "",
                                    schedule: currentDate,
                                    assignee: "",
                                });
                            } else {
                                const correctedDate = new Date(
                                    currentDate.setDate(currentDate.getDate() + ((5 - currentDate.getDay() + 7) % 7))
                                );
                                correctedDate.setHours(0, 0, 0, 0);
                                setDataEdit({
                                    title: "",
                                    schedule: correctedDate,
                                    assignee: "",
                                });
                            }
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <p>Create</p>
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2}>
                        <WeekList
                            weeks={weeks}
                            dataTable={dataTable}
                            handleFilter={handleFilter}
                            addWeekArray={addWeekArray}
                            arrWeek={arrWeek}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <AssigneeList
                            handleFilter={handleFilter}
                            showTable={showTable}
                            arrAssignee={arrAssignee}
                            addAssigneeArray={addAssigneeArray}
                        />
                        <TableData
                            showTable={showTable}
                            setShowTable={setShowTable}
                            setOpenDialog={setOpenDialog}
                            setDataEdit={setDataEdit}
                            setOpenPopup={setOpenPopup}
                            setIdDelete={setIdDelete}
                            currentUser={currentUser}
                            handleUpdateSharingScore={handleUpdateSharingScore}
                            dispatch={dispatch}
                            dataTable={dataTable}
                        />
                    </Grid>
                </Grid>
            </div>
            {openDialog && (
                <FormEdit
                    setOpenPopup={setOpenPopup}
                    setOpenDialog={setOpenDialog}
                    openDialog={openDialog}
                    dataEdit={dataEdit}
                    setIdDelete={setIdDelete}
                    currentUser={currentUser}
                    weeks={weeks}
                    setWeeks={setWeeks}
                    dispatch={dispatch}
                    dataTable={dataTable}
                />
            )}
            <PopupDelete
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                idDelete={idDelete}
                setOpenDialog={setOpenDialog}
                handleUpdateSharingScore={handleUpdateSharingScore}
                currentUser={currentUser}
            />
        </div>
    );
};

export default KnowledgeSharing;
