import { IUserExpectation } from "../../shared/models/userExpectation";

export enum UserExpectationTypes {
    GET_ALL_EXPECTATIONS_SUCCESS = "GET_ALL_EXPECTATIONS_SUCCESS",
}

export interface IUserExpectationAction {
    type: UserExpectationTypes;
    userExpectations?: IUserExpectation[];
}

export const getAllExpectationsSuccess = (
    userExpectations: IUserExpectation[]
): IUserExpectationAction => {
    return {
        type: UserExpectationTypes.GET_ALL_EXPECTATIONS_SUCCESS,
        userExpectations,
    };
};
