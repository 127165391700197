import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import Url from "../../util/url";
import { INotification, Notification } from "../../shared/models/notification";
import {
    INotificationAction,
    NotificationTypes,
    getDataNotificationFailed,
    getDataNotificationSuccess,
} from "../actions/notification_hero.action";

async function getDataNotificationAPI(
    userID: string
): Promise<INotification[]> {
    return await axios
        .get(Url("notification/get-data-notificationHero?userID=" + userID))
        .then((data) => {
            if (data.status === 200 && data.data) {
                return data.data.map((item: any) => {
                    return new Notification(item);
                });
            }
            return [];
        });
}

function* getDataNotificationSaga(action: INotificationAction) {
    const userID = action.userID;
    try {
        if (userID) {
            const dataNotification: INotification[] = yield call(
                getDataNotificationAPI,
                userID
            );

            if (dataNotification) {
                yield put(getDataNotificationSuccess(dataNotification));
            } else {
                yield put(getDataNotificationFailed("userID not existed!"));
            }
        }
    } catch (err) {
        yield put(getDataNotificationFailed("userID ERROR : " + err));
    }
}

export function* watchNotification() {
    yield takeLatest(
        NotificationTypes.GET_DATA_NOTIFICATION,
        getDataNotificationSaga
    );
}
