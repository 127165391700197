import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TYPE_ANALYTIC_AB_TEST,
    TYPE_ANALYTIC_CONTENT,
    TYPE_ANALYTIC_DAILY_COUNT,
    TYPE_ANALYTIC_INDEX_PROGRESS,
    TYPE_ANALYTIC_INFO,
    TYPE_ANALYTIC_KEYWORD_PERFORMANCE,
    TYPE_ANALYTIC_TRAFFIC,
    TYPE_ANALYTIC_TRENDING_KEYWORD,
    TYPE_REVENUE,
} from "../../../config_data_analytic";
import { isWebWorksheet } from "../../../config_web";
import { Certificates, ICertificates } from "../../../models/certificates.model";
import { searchAppAction, setCurrentCertificatesAction } from "../../../redux/actions/dashboard.action";
import { isAdmin, isLeader } from "../../../util";
import AbTest from "../ab-test";
import AnalyticsView from "../analytics";
import RevenueView from "../analytics/revenue";
import WebAppIndexView from "../analytics/web-app-index/index";
import DailyCountWorksheetzone from "../daily-count-worksheetzone";
import IndexProgress from "../index-progress";
import KeywordPerformanceChart from "../keyword-performance";
import OverViewDashBoard from "../overview";
import TrendingKeywords from "../trending-keywords";
import Infomation from "./../infomation";
import QuestionQualityV2 from "./../question-quality";
import "./index.scss";
// const KeywordPerformanceChart = React.lazy(
//     () => import("../keyword-performance")
// );
// const IndexProgress = React.lazy(() => import("../index-progress"));
// const TrendingKeywords = React.lazy(() => import("../trending-keywords"));
// const Infomation = React.lazy(() => import("./../infomation"));
const RightContentPanel = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.authState.user);
    const currentCertificates = useSelector((state: any) => state.dashBoardState.currentCertificates);
    const certificates: ICertificates[] = useSelector((state: any) => state.dashBoardState.certificates);
    const typeInfo = useSelector((state: any) => state.dashBoardState.typeInfo);
    const currentCertificatesId = localStorage.getItem("currentCertificatesId");
    const currentCertificatesType = localStorage.getItem("currentCertificatesType");

    useEffect(() => {
        if (currentCertificatesId && currentCertificatesType) {
            let certificate = new Certificates(
                certificates.find((item: ICertificates) => item.id == JSON.parse(currentCertificatesId))
            );

            dispatch(setCurrentCertificatesAction(certificate, currentCertificatesType));
        }
    }, [currentCertificatesId, currentCertificatesType]);

    switch (typeInfo) {
        case TYPE_ANALYTIC_INFO:
            return (
                <RightContentPanelContent title="Information" children={<Infomation certificates={currentCertificates} />} />
            );
        case TYPE_ANALYTIC_CONTENT:
            return (
                <RightContentPanelContent title="Content" children={<QuestionQualityV2 certificates={currentCertificates} />} />
            );
        case TYPE_ANALYTIC_TRAFFIC:
            return (
                <>
                    <RightContentPanelContent
                        title="Engagement"
                        children={<AnalyticsView certificates={currentCertificates} />}
                    />
                    {!isWebWorksheet(currentCertificates.slug) && (
                        <RightContentPanelContent
                            title="Index"
                            children={<WebAppIndexView certificates={currentCertificates} />}
                        />
                    )}
                </>
            );
        case TYPE_REVENUE:
            return <RightContentPanelContent title="Revenue" children={<RevenueView certificates={currentCertificates} />} />;
        case TYPE_ANALYTIC_AB_TEST:
            return <RightContentPanelContent title="Ab test" children={<AbTest certificate={currentCertificates} />} />;

        case TYPE_ANALYTIC_TRENDING_KEYWORD:
            return <RightContentPanelContent title="Trending Keywords" children={<TrendingKeywords />} />;

        case TYPE_ANALYTIC_INDEX_PROGRESS:
            return <RightContentPanelContent title="Index Progress" children={<IndexProgress />} />;
        case TYPE_ANALYTIC_KEYWORD_PERFORMANCE:
            return <RightContentPanelContent title="Keyword Performance" children={<KeywordPerformanceChart />} />;
        case TYPE_ANALYTIC_DAILY_COUNT:
            return <RightContentPanelContent title="Daily Count Worksheetzone" children={<DailyCountWorksheetzone />} />;
        default:
            return isAdmin(userInfo) || isLeader(userInfo) ? (
                <RightContentPanelContent title="Overview" children={<OverViewDashBoard />} searchApp={true} />
            ) : null;
    }
};
const RightContentPanelContent = ({ children, title, searchApp }: { children: any; title: string; searchApp?: boolean }) => {
    const typeInfo = useSelector((state: any) => state.dashBoardState.typeInfo);
    const dispatch = useDispatch();
    const [valueSearch, setValueSearch] = React.useState<any>("");
    const searchAppValue = useSelector((state: any) => state.dashBoardState.search);
    console.log("searchAppValue", searchAppValue);

    useEffect(() => {
        let searchAppValue = localStorage.getItem("searchApp");
        if (searchAppValue) dispatch(searchAppAction(searchAppValue));
    }, []);
    return (
        <>
            <div className={"right-content -" + (typeInfo ? typeInfo : "")}>
                {title?.length > 0 && (
                    <div className="back-top">
                        <p>{title}</p>
                        {searchApp && (
                            <div>
                                <TextField
                                    label="Search app"
                                    value={searchAppValue}
                                    onChange={(e: any) => {
                                        let value = e.target.value;
                                        dispatch(searchAppAction(value));
                                        localStorage.setItem("searchApp", value);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {children}
            </div>
        </>
    );
};
export default RightContentPanel;
