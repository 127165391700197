import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteComment,
    getComment,
    updateComment,
} from "../../../../../../redux/actions/comment.action";
import { ICommentState } from "../../../../../../redux/reducers/comment.reducer";
import { ITask, ITaskComment } from "../../../../../../shared/models/task";
import LoadingWidget from "../../../../../LoadingWidget";
import "./index.scss";
import CommentItem from "./item";

const ListComment: FC<{ task: ITask }> = ({ task }) => {
    const commentState: ICommentState = useSelector(
        (state: any) => state.commentState
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getComment(task._id));
    }, [task._id]);
    if (commentState.loading) {
        return <LoadingWidget />;
    }
    const comments = commentState.comments ?? [];
    if (!commentState.comments || commentState.comments.length == 0) {
        return <div></div>;
    }

    const onEditComment = (comment: ITaskComment) => {
        dispatch(updateComment(comment));
    };

    const onDeleteComment = (comment: ITaskComment) => {
        if (window.confirm("Are you sure you want to delete this comment?")) {
            dispatch(deleteComment(comment));
        }
    };

    return (
        <div className="list-comment-item">
            {comments.map((comment) => {
                return (
                    <CommentItem
                        key={"comment_item_" + comment._id}
                        comment={comment}
                        onEditComment={onEditComment}
                        onDeleteComment={onDeleteComment}
                    />
                );
            })}
        </div>
    );
};

export default ListComment;
