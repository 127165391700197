import { FC, useEffect, useRef, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import Calendar from "react-calendar";
import "./OutOfficeRegistration.scss";
import Config from "../../shared/models/config";
import { getDate, timezoneSetHours } from "../../util";
import axios from "axios";
import Url from "../../util/url";
import FmdBadRoundedIcon from "@mui/icons-material/FmdBadOutlined";
import { useSelector } from "react-redux";
import { ISprint } from "../../shared/models/sprint";
import moment from "moment-timezone";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import { removeNumberSignOfReason } from "../../shared/utils";
import { Grid, IconButton } from "@mui/material";
import { ArrowBackRounded } from "@material-ui/icons";

const currentDate = new Date();

interface ISelectingDate {
    date: string;
    slot: string; // gồm 3 thành phần: morning/afternoon [registed] [cancel]
    _id: string;
    type: string;
    offType?: string;
    reason?: string;
}
interface ITemplate {
    [date: string]: ISelectingDate[];
}

const _ICON = (className: string) => [
    <div className="tile-icon align-item-center">
        <EventBusyRoundedIcon className={"icon " + className} sx={{ width: "16px !important", height: "16px !important" }} />
    </div>,
    <div className="tile-icon align-item-center">
        <EventAvailableRoundedIcon
            className={"icon " + className}
            sx={{ width: "16px !important", height: "16px !important" }}
        />
    </div>,
    <div className="tile-icon align-item-center">
        <ThumbDownRoundedIcon className={"icon " + className} sx={{ width: "16px !important", height: "16px !important" }} />
    </div>,
    <div className="tile-icon align-item-center">
        <ThumbUpRoundedIcon className={"icon " + className} sx={{ width: "16px !important", height: "16px !important" }} />
    </div>,
];

// default date format MM/DD/YYYY
/*
Đối với lần nghỉ phép đầu tiên trong tháng:
+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: không bị trừ điểm kỷ luật.
+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 15 điểm kỷ luật.
+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 30 điểm kỷ luật.

Tất cả các ngày xin nghỉ phép từ lần thứ 2 trở đi trong tháng số điểm bị trừ sẽ tăng thêm 30 điểm kỷ luật/lần so với lần đầu tiên:
+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: trừ 30 điểm.
+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 45 điểm. 
+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 60 điểm. 

Trường hợp nghỉ không lý do : trừ 90 điểm
*/
const OutOfficeRegistration = ({ type }: { type: string }) => {
    const user = useSelector((state: any) => state.authState.user);
    const reasonTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const [selectingDates, setSelectingDates] = useState<ITemplate>({});
    const [offDayWfhDayResponses, setOffDayWfhDayResponses] = useState([]);
    const [holidayComplementDayResponses, setHolidayComplementDayResponse] = useState([]);
    const [offDayWfhDay, setOffDayWfhDay] = useState<ITemplate>({});
    const [holidayComplementDay, setHolidayComplementDay] = useState<ITemplate>({});
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const mapDataRegisted: ITemplate = _type1.includes(type) ? offDayWfhDay : _type2.includes(type) ? holidayComplementDay : {}; //  dữ liệu đã đăng ký
    const onlyView = !(user.role === Config.USER_ROLE_ADMIN || user.role === Config.USER_ROLE_LEADER) && _type2.includes(type);
    const [processing, setProcessing] = useState(false);
    // console.log(mapDataRegisted, selectingDates);

    useEffect(() => {
        const fetchAllOutOfficeRegistrations = async () => {
            const res = await axios.get(
                Url("outoffice/" + "?userId=" + user._id + (_type1.includes(type) ? "&personal=true" : ""))
            );
            if (res.data.success) {
                let { offDayWfhDay, holidayComplementDay } = res.data.response;
                if (offDayWfhDay?.length) {
                    setOffDayWfhDayResponses(offDayWfhDay);
                }
                if (holidayComplementDay?.length) {
                    setHolidayComplementDayResponse(holidayComplementDay);
                }
            }
        };
        fetchAllOutOfficeRegistrations();
    }, []);

    useEffect(() => {
        if (!!offDayWfhDayResponses?.length) setOffDayWfhDay(convertData(offDayWfhDayResponses));
    }, [offDayWfhDayResponses]);
    useEffect(() => {
        if (!!holidayComplementDayResponses?.length) setHolidayComplementDay(convertData(holidayComplementDayResponses));
    }, [holidayComplementDayResponses]);

    const setTileClassName = (date: any) => {
        // gán class để config css
        let dateTime = new Date(date.date).toISOString();
        let today = daysDiff(currentDate, date.date) == 0;
        let currentData = { ...mapDataRegisted, ...selectingDates };
        const preRegisteredDate = currentData[dateTime];
        if (preRegisteredDate) {
            return (
                (!!mapDataRegisted[dateTime] ? " registed " + mapDataRegisted[dateTime].map((r) => r.slot).join(" ") : "") +
                (!!selectingDates[dateTime] ? " pending " : " ") +
                (today ? " now " : " ") +
                dateTime +
                " " +
                type
            );
        }
        return (today ? " now " : " ") + dateTime;
    };

    const updateSelectingDates = (date: string, dateData: ISelectingDate[]) => {
        let _ = { ...selectingDates, [date]: dateData };
        setSelectingDates(_);
    };

    const isNonWorkDay = (date: Date) => {
        const dateTime = getDate(date);
        if (
            (date.getDay() === 0 &&
                holidayComplementDay[dateTime]?.findIndex((d) => d.type == Config.TYPE.complementDay) == -1) || // CN va khong co lich lam bu
            daysDiff(date, currentDate) < 0 //qua khu
            // ngày lễ thì vẫn cho select vào vì có thể nghỉ lễ 1 buổi thôi, sẽ disable ở option
        ) {
            return true;
        }
        return false;
    };

    const isTileDisabled = (date: any) => {
        let isDisabled = false;
        if (_type1.includes(type)) {
            if (isNonWorkDay(date.date)) {
                isDisabled = true;
            }
        }
        return isDisabled || onlyView;
    };

    const bookADate = (date: Date, event: any) => {
        let _date = date.toISOString();
        if (!selectingDates[_date]) {
            let _d: ISelectingDate[] = [];
            if (!!mapDataRegisted[_date]) {
                // nếu đã có dữ liệu đang ký của ngày được chọn rồi
                _d = mapDataRegisted[_date];
                _d = _d.map((_) => {
                    return { ..._, slot: _.slot + " registed" };
                });
            }
            updateSelectingDates(_date, _d);
        }
    };

    const getCurrentSprintRemainingDayoff = () => {
        // hàm này chỉ là check trong (sprint => tháng) HIỆN TẠI còn bao nhiêu lần nghỉ mà không bị trừ điểm
        // vì khẩn cấp chỉ trong phạm vi 1 ngày kể từ lúc đk nên không cần xét phức tạp như trước
        // và cũng không thể đk ngày nghỉ cho những ngày đã trôi qua => chỉ nhìn vào tương lai
        // khẩn cấp tinh theo lần đăng ký (1 lần có thể đk nhiều ngày được)
        // chú ý ở đây 1 ngày có thể đk riêng từng buổi => tối đa có 2 bản ghi đăng ký xin nghỉ nên nếu như đk lẻ từng buổi thì có thể buổi được đăng ký sau sẽ bị tính trừ điểm khẩn cấp
        // chỉ xét trong những ngày đã đăng ký (thì mới hợp lệ cho tình huống đk 1 ngày 2 lần sáng - chiều)
        let count = 0;
        let sprint = sprints.find((s) => s.activeSprint);
        if (!sprint) return Config.DEFAULT_URGENT_OFFDAY_PER_MONTH;
        // let startSprint = getDate(sprint.startDate);
        // let endSprint = getDate(sprint.endDate);
        const currentDate = new Date();
        const startOfMonth = getDate(
            timezoneSetHours(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0))
        );
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const endOfMonth = getDate(timezoneSetHours(new Date(nextMonth.getTime() - 1)));

        let dayList: ISelectingDate[][];
        dayList = Object.values(mapDataRegisted).filter((d) => d.length > 0);
        dayList.sort((a, b) => new Date(a[0]?.date).getTime() - new Date(b[0]?.date).getTime());
        for (let day of dayList) {
            for (let d of day) {
                let thisDate = getDate(d?.date);
                if (
                    // thisDate.localeCompare(startSprint) >= 0 &&
                    // thisDate.localeCompare(endSprint) <= 0 &&
                    thisDate.localeCompare(startOfMonth) >= 0 &&
                    thisDate.localeCompare(endOfMonth) <= 0 &&
                    d.type === Config.TYPE.offday
                    // (d.offType?.includes(Config.OFF_TYPE_URGENT_1) || d.offType?.includes(Config.OFF_TYPE_URGENT_2))
                ) {
                    count++;
                    // đoạn này không cần thiết phải biết xem 2 buổi trong 1 ngày đó có đk cùng  lúc hay không vì chỉ cần có 1 buổi thì tức là hết lượt rồi
                }
            }
        }
        return Config.DEFAULT_URGENT_OFFDAY_PER_MONTH - count;
    };

    const setTileContent: FC<{
        activeStartDate: any;
        date: Date;
        view: any;
    }> = ({ activeStartDate, date, view }) => {
        const dateTime = new Date(date).toISOString();
        const _date = holidayComplementDay[dateTime];
        if (view === "month") {
            let holiday = _date?.filter((d) => d.type == "holiday");
            let complementDay = _date?.filter((d) => d.type == "complementDay");
            return (
                <>
                    {holiday?.length > 0 ? (
                        <div className="holiday-icon">
                            {_ICON(holiday.map((h) => h.slot).join(" "))[0]}
                            <span className="reason">
                                {holiday
                                    .map((h) => {
                                        let r = "";
                                        if (h.slot.includes("morning")) r = "Sáng: " + removeNumberSignOfReason(h.reason ?? "");
                                        if (h.slot.includes("afternoon"))
                                            r = "Chiều: " + removeNumberSignOfReason(h.reason ?? "");
                                        return r;
                                    })
                                    .join(" | ")}
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                    {complementDay?.length > 0 ? (
                        <div className="complementday-icon">
                            {_ICON(complementDay.map((h) => h.slot).join(" "))[1]}
                            <span className="reason">
                                {complementDay
                                    .map((h) => {
                                        let r = "";
                                        if (h.slot.includes("morning")) r = "Sáng: " + removeNumberSignOfReason(h.reason ?? "");
                                        if (h.slot.includes("afternoon"))
                                            r = "Chiều: " + removeNumberSignOfReason(h.reason ?? "");
                                        return r;
                                    })
                                    .join(" | ")}
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            );
        }
        return null;
    };

    const confirmRegistration = async () => {
        let arr = Object.values(selectingDates).filter((v) => v);
        let reason = reasonTextAreaRef.current?.value;
        if (!arr.length) alert("Bạn cần phải chọn ngày");
        else if (reason === null || reason === undefined || reason.length === 0) {
            alert("Bạn cần phải nhập lý do");
        } else {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].length == 0) {
                    alert("Bạn cần phải chọn buổi cho các ngày muốn đăng ký");
                    return;
                } else {
                    arr[i] = arr[i].filter((_i) => !isTileDisabled({ date: new Date(arr[i][0].date) })); // lọc bỏ những ngày bị bị disable
                    arr[i] = arr[i].filter((_i) => !(_i.slot.includes("registed") && !_i.slot.includes("cancel"))); // lọc bỏ những ngày có đăng ký và không có thay đổi gì
                }
            }
            setProcessing(true);
            arr = arr.filter((i) => i.length > 0);

            if (arr.length > 0) {
                let endPoint = "";
                if (_type1.includes(type)) endPoint = "outoffice";
                else if (_type2.includes(type)) endPoint = "create-holiday-and-complementDay";
                else return;
                await axios
                    .post(
                        Url(endPoint),
                        arr.map((day) => {
                            // vì dữ liệu trong 1 ngày được lưu thành mảng,
                            // 1 ngày đó có các thông tin chung là offtype, type, date, userId, reason, urgent
                            // thông tin riêng là _id và slot
                            let urgent = "";
                            let remain = 1;
                            let data = day
                                .map((d) => {
                                    if (_type1.includes(type)) {
                                        if (!d.slot.includes("cancel")) {
                                            if (d.slot.includes("registed")) {
                                                // trường hợp slot đã đk đc thêm vào để hiện lên giao diện thôi nhưng lại không có thay đổi gì nó thì lọc bỏ đi
                                                return null;
                                            } else {
                                                // trường hợp slot muốn nghỉ chưa được đk thì mới xét đến urgent
                                                urgent = getUrgentType(d.date); //luc select thi co the chua muon nhung lucs gui yc thi da muon roi
                                                // ugent ở đây sẽ lấy theo giá trị của slot cuối cùng nhưng trong tình huống này vẫn đúng vì trong cùng 1 lần đk thì các slot đều cùng 1 loại ugent thôi
                                            }
                                        }
                                    }
                                    return { slot: d.slot, _id: d._id }; // ********
                                })
                                .filter((d) => d);
                            // if (urgent) {
                            // nếu ngày hôm đó có đk khẩn cấp thì check còn bao nhiêu lần được phép, và trừ 1 lần của khi đó
                            remain = getCurrentSprintRemainingDayoff();
                            remain -= 1;
                            // }
                            return {
                                type: type,
                                date: day[0].date,
                                data: data, // ********
                                remain: remain, // vai trò của biến này là để gửi tin nhắn thông báo còn bao nhiêu lần khẩn cấp hay bị trừ điểm mà thôi, nên chỉ cần check nguyên cái ngày bị khẩn cấp thôi nha
                                offType: urgent + (remain < 0 ? "-" : ""), // update ngày 1/2/2023, thêm dấu - để biết là cái nào bị trừ cái nào k bị
                                userId: user._id,
                                reason: reason + "#" + Date.now(), // thêm timeStamp vào để phân biệt 2 buổi trong cùng 1 ngày có đk cùng 1 lần hay không
                            };
                        })
                    )
                    .then((response) => {
                        let data = response.data;
                        if (data) {
                            let oldData = JSON.parse(
                                _type1.includes(type)
                                    ? JSON.stringify(offDayWfhDayResponses)
                                    : JSON.stringify(holidayComplementDayResponses)
                            );
                            data.forEach((item: any) => {
                                let pending = oldData.find((d: any) => d._id === item._id);
                                // logic mới là chia ra đăng ký từng buổi nên 1 buổi trong 1 ngày chỉ có thể được thêm mới hoặc xoá đi chứ không có trường hợp sửa
                                if ((item.slot as string).includes("cancel")) {
                                    // xoá
                                    if (!!pending) {
                                        // response về mà có cancel thì là đã huỷ thành công, loại bỏ luôn
                                        oldData = oldData.filter((d: any) => d._id !== item._id);
                                    }
                                } else if (!item.slot) {
                                } else {
                                    // thêm mới
                                    if (!!pending) {
                                        // trường hợp pending tồn tại (so sánh data cũ và mới trùng id thì chí có thể rơi vào trường hợp cancel thôi
                                    } else oldData.push(item);
                                }
                            });
                            setSelectingDates({});
                            // console.log(oldData);

                            if (_type1.includes(type)) {
                                setOffDayWfhDayResponses(oldData);
                            } else if (_type2.includes(type)) {
                                setHolidayComplementDayResponse(oldData);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                alert("Thông tin đăng ký của bạn đã được gửi đến quản lý.");
            } else {
                alert("Bạn cần đăng ký ít nhất một ngày");
            }
            setTimeout(() => {
                setProcessing(false);
                if (reasonTextAreaRef.current) reasonTextAreaRef.current.value = "";
            }, 300);
        }
    };

    let _selectingDates = Object.keys(selectingDates).sort((a, b) => a.localeCompare(b));

    return (
        <div className="registration-container">
            <div className="title">
                <IconButton onClick={() => window.history.back()}>
                    <ArrowBackRounded htmlColor="white" />
                </IconButton>
                <div className={type}>Đăng ký {getLabel(type)}</div>
                <div></div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <div className="-calendar">
                        <Calendar
                            tileContent={setTileContent}
                            // tileDisabled={setTileDisabled}
                            tileClassName={setTileClassName}
                            onClickDay={(value, event) => {
                                if (!onlyView) bookADate(value, event);
                            }}
                        />
                        <div className="flex" style={{ paddingTop: "8px" }}>
                            <div>
                                <div className="align-item-center">
                                    <div className="note -morning"></div> Sáng
                                </div>
                                <div className="align-item-center">
                                    <div className="note -afternoon"></div> Chiều
                                </div>
                                <div className="align-item-center">
                                    <div className="note -allday"></div> Cả ngày
                                </div>
                            </div>
                            <div style={{ marginLeft: "32px" }}>
                                <div className="align-item-center">{_ICON("")[0]} Nghỉ lễ</div>
                                <div className="align-item-center">{_ICON("")[1]} Làm bù</div>
                                {_type1.includes(type) && (
                                    <>
                                        <div className="align-item-center">{_ICON("")[2]} Không phép, thêm bởi admin</div>
                                        <div className="align-item-center">{_ICON("")[3]} Có phép, thêm bởi admin</div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* {_type1.includes(type) && (
                            <div className="warning">
                                <p>
                                    <FmdBadRoundedIcon />
                                    Đối với lần nghỉ phép đầu tiên trong tháng:{" "}
                                </p>
                                <p>+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: không bị trừ điểm kỷ luật. </p>
                                <p>+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 15 điểm kỷ luật. </p>
                                <p>+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 30 điểm kỷ luật. </p>
                                <p>
                                    Tất cả các ngày xin nghỉ phép từ lần thứ 2 trở đi trong tháng số điểm bị trừ sẽ tăng thêm 30
                                    điểm kỷ luật/lần so với lần đầu tiên:
                                </p>
                                <p>+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: trừ 30 điểm. </p>
                                <p>+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 45 điểm.</p>
                                <p>+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 60 điểm. </p>
                                <p>Trường hợp nghỉ không lý do: trừ 90 điểm.</p>
                                <p>
                                    Lưu ý : Trong trường hợp cần xin nghỉ khẩn cấp nếu không bị trừ điểm yêu cầu mọi người cung cấp
                                    thông tin chính xác và đáng tin cậy, không chấp nhận lý do ngoài lề, chỉ chấp nhận lý do khẩn
                                    cấp như nhập viện, cấp cứu, ma chay hoặc con ốm.
                                </p>
                                {getCurrentSprintRemainingDayoff() === 1 && <p>Bạn còn 1 lần phép miễn phí trong tháng này.</p>}
                                {getCurrentSprintRemainingDayoff() < 1 && <p>Bạn đã hết số lần nghỉ miễn phí</p>}
                            </div>
                        )} */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {!onlyView && (
                        <div className="registration-0">
                            {_selectingDates.map((_date, index) => {
                                return (
                                    <div key={index} className="selecting-date-boundary">
                                        <div className="selecting-date title align-item-center">
                                            <div>
                                                Ngày: <i>{getDate(_date)}</i>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    delete selectingDates[_date];
                                                    setSelectingDates({ ...selectingDates });
                                                }}
                                            >
                                                <RemoveCircleOutlineRoundedIcon
                                                    htmlColor="#ccc"
                                                    sx={{ width: "20px", height: "20px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="slot-tag flex">
                                            {selectingDates[_date].map((d) => {
                                                let isRegistered = d.slot.includes("registed");
                                                let _disabled =
                                                    (_type2.includes(type) && d.type != type) || // trong trang nghỉ lễ thì không cancel được làm bù, và ngược lại
                                                    (_type1.includes(type) && // nếu là urgen thì không cho phép cancel
                                                        (d.offType?.includes(Config.OFF_TYPE_URGENT_1) ||
                                                            d.offType?.includes(Config.OFF_TYPE_URGENT_2) ||
                                                            d.offType?.includes(Config.OFF_TYPE_AUTHORIZED) ||
                                                            d.offType?.includes(Config.OFF_TYPE_UNAUTHORIZED)));
                                                return (
                                                    <div
                                                        className={`slot-tag-selected align-item-center ${d.slot} ${
                                                            _disabled ? "-disabled" : ""
                                                        }`}
                                                        key={d.slot}
                                                    >
                                                        {_OPTIONS.find((o) => d.slot.includes(o.value))?.label}{" "}
                                                        {_type2.includes(type) && _disabled && isRegistered ? ( // hiện icon khi disable cho dễ hiểu
                                                            d.type == Config.TYPE.holiday ? (
                                                                _ICON("")[0]
                                                            ) : (
                                                                _ICON("")[1]
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {((_type2.includes(type) && d.type == type) || _type1.includes(type)) && (
                                                            <div
                                                                className="--cancel  align-item-center"
                                                                onClick={() => {
                                                                    // loại bỏ slot ra khỏi ds lựa chọn
                                                                    let _dateData = selectingDates[_date];
                                                                    if (isRegistered) {
                                                                        return; //không cho huỷ đăng ký nữa vì trừ điểm
                                                                        // if (_disabled) return;
                                                                        // // nếu đã đk thì sẽ là yêu cầu huỷ, nên không xoá luôn được
                                                                        // _dateData = _dateData.map((_s) => {
                                                                        //     if (_s.slot == d.slot) {
                                                                        //         _s.slot = _s.slot + " cancel";
                                                                        //     }
                                                                        //     return _s;
                                                                        // });
                                                                    } else _dateData = _dateData.filter((_s) => d.slot != _s.slot);
                                                                    updateSelectingDates(_date, _dateData);
                                                                }}
                                                            >
                                                                {isRegistered &&
                                                                (d.offType?.includes(Config.OFF_TYPE_URGENT_1) ||
                                                                    d.offType?.includes(Config.OFF_TYPE_URGENT_2)) ? (
                                                                    <FmdBadRoundedIcon
                                                                        sx={{
                                                                            width: "16px !important",
                                                                            height: "16px !important",
                                                                        }}
                                                                    />
                                                                ) : isRegistered && d.offType == Config.OFF_TYPE_AUTHORIZED ? (
                                                                    _ICON("")[3]
                                                                ) : isRegistered && d.offType == Config.OFF_TYPE_UNAUTHORIZED ? (
                                                                    _ICON("")[2]
                                                                ) : isRegistered ? ( // không cho huỷ nữa vì mặc định trừ điểm
                                                                    <></>
                                                                ) : (
                                                                    <CloseRoundedIcon
                                                                        sx={{
                                                                            width: "12px !important",
                                                                            height: "12px !important",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <hr />
                                        <div className="flex">
                                            {!isTileDisabled({ date: new Date(_date) }) ? (
                                                _OPTIONS.map((opt) => {
                                                    let holiday_complementday = holidayComplementDay[_date];
                                                    let _disable = false;
                                                    let _enable = false;
                                                    if (opt.value !== "allday") {
                                                        _disable =
                                                            _type1.includes(type) &&
                                                            !!holiday_complementday?.find(
                                                                (d) => d.slot.includes(opt.value) && d.type == "holiday" // nếu slot này đã được đk là ngày nghỉ thì disable
                                                            );
                                                        _enable =
                                                            _type1.includes(type) &&
                                                            !!holiday_complementday?.find(
                                                                (d) => d.slot.includes(opt.value) && d.type == "complementDay" // nếu slot này đã được đk là làm bù thì enable
                                                            );
                                                    } else {
                                                        _disable =
                                                            _type1.includes(type) &&
                                                            !!holiday_complementday?.find(
                                                                (d) => d.type == "holiday" // nếu ngày hôm đó có  slot đã được đk là ngày nghỉ thì disable
                                                            );
                                                        _enable =
                                                            _type1.includes(type) &&
                                                            !!(
                                                                holiday_complementday?.filter(
                                                                    (d) => d.type == "complementDay" // nếu ngày hôm đó có 2 slot đã được đk là làm bù thì enable
                                                                ).length == 2
                                                            );
                                                    }

                                                    return (
                                                        <div
                                                            key={opt.value}
                                                            className={`opt-btn align-item-center justify-content-center ${
                                                                opt.value
                                                            } ${_disable ? "-disabled" : ""}`}
                                                            onClick={() => {
                                                                if (_disable) return;
                                                                if (opt.value == "reset") {
                                                                    let _d: ISelectingDate[] = [];
                                                                    // giống với bookADate
                                                                    if (!!mapDataRegisted[_date]) {
                                                                        // nếu đã có dữ liệu đang ký của ngày được chọn rồi
                                                                        _d = mapDataRegisted[_date];
                                                                        _d = _d.map((_) => {
                                                                            return { ..._, slot: _.slot + " registed" };
                                                                        });
                                                                    }
                                                                    updateSelectingDates(_date, _d);
                                                                    return;
                                                                }
                                                                if (opt.value === "allday") {
                                                                    for (let s of [Config.MORNING, Config.AFTERNOON]) {
                                                                        if (
                                                                            !selectingDates[_date].find((d) => d.slot.includes(s))
                                                                        ) {
                                                                            // chưa chọn slot này thì thêm vào
                                                                            let _dateDate = selectingDates[_date];
                                                                            let _id =
                                                                                mapDataRegisted[_date]?.find((_d) =>
                                                                                    _d.slot.includes(s)
                                                                                )?._id ?? "";

                                                                            _dateDate.push({
                                                                                date: _date,
                                                                                slot: s,
                                                                                _id: _id,
                                                                                type: "",
                                                                            });
                                                                            updateSelectingDates(_date, _dateDate);
                                                                        }
                                                                    }
                                                                    return;
                                                                }
                                                                if (
                                                                    !selectingDates[_date].find((d) => d.slot.includes(opt.value))
                                                                ) {
                                                                    // chưa chọn slot này thì thêm vào
                                                                    let _dateDate = selectingDates[_date];
                                                                    let _id =
                                                                        mapDataRegisted[_date]?.find((_d) =>
                                                                            _d.slot.includes(opt.value)
                                                                        )?._id ?? "";

                                                                    _dateDate.push({
                                                                        date: _date,
                                                                        slot: opt.value,
                                                                        _id: _id,
                                                                        type: "",
                                                                    });
                                                                    updateSelectingDates(_date, _dateDate);
                                                                }
                                                            }}
                                                        >
                                                            {opt.label}
                                                            {_disable ? _ICON("")[0] : <></>}
                                                            {_enable ? _ICON("")[1] : <></>}
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <>Không thể đăng ký cho những ngày đã trôi qua</>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="reason">
                                <label className=" title">Lý do:</label>
                                <textarea ref={reasonTextAreaRef} />
                            </div>
                            <button
                                className="submit"
                                onClick={confirmRegistration}
                                disabled={Object.values(selectingDates).filter((d) => !!d).length == 0 || processing}
                            >
                                Gửi yêu cầu
                            </button>
                        </div>
                    )}
                </Grid>
            </Grid>
            {_type1.includes(type) && (
                <div className="warning">
                    <strong>
                        <FmdBadRoundedIcon />
                        Đối với lần nghỉ phép đầu tiên trong tháng:{" "}
                    </strong>
                    <p>+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: không bị trừ điểm kỷ luật. </p>
                    <p>+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 15 điểm kỷ luật. </p>
                    <p>+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 30 điểm kỷ luật. </p>
                    <strong>
                        Tất cả các ngày xin nghỉ phép từ lần thứ 2 trở đi trong tháng số điểm bị trừ sẽ tăng thêm 30 điểm kỷ
                        luật/lần so với lần đầu tiên:
                    </strong>
                    <p>+ Nếu xin nghỉ phép trước 17h00 ngày hôm trước: trừ 30 điểm. </p>
                    <p>+ Nếu xin nghỉ từ 17h00 ngày hôm trước đến 7h00 ngày hôm nghỉ: trừ 45 điểm.</p>
                    <p>+ Nếu xin sau 7h00 ngày hôm nghỉ: trừ 60 điểm. </p>
                    <p>Trường hợp nghỉ không lý do: trừ 90 điểm.</p>
                    <p>
                        <strong>Lưu ý:</strong> Trong trường hợp cần xin nghỉ khẩn cấp nếu không bị trừ điểm yêu cầu mọi người
                        cung cấp thông tin chính xác và đáng tin cậy, không chấp nhận lý do ngoài lề, chỉ chấp nhận lý do khẩn
                        cấp như nhập viện, cấp cứu, ma chay hoặc con ốm.
                    </p>
                    {getCurrentSprintRemainingDayoff() === 1 && <strong>Bạn còn 1 lần phép miễn phí trong tháng này.</strong>}
                    {getCurrentSprintRemainingDayoff() < 1 && <strong>Bạn đã hết số lần nghỉ miễn phí</strong>}
                </div>
            )}
        </div>
    );
};

const _type1 = Config.TYPE.offday + "_" + Config.TYPE.wfhday;
const _type2 = Config.TYPE.holiday + "_" + Config.TYPE.complementDay;
const getLabel = (type: string) => {
    switch (type) {
        case Config.TYPE.wfhday:
            return "làm online";
        case Config.TYPE.offday:
            return "nghỉ phép";
        case Config.TYPE.holiday:
            return "nghỉ lễ";
        case Config.TYPE.complementDay:
            return "làm bù";
        case _type1:
            return "ngày nghỉ";
        case _type2:
            return "nghỉ lễ/ làm bù";
        default:
            break;
    }
};
const _OPTIONS = [
    {
        label: "Cả ngày",
        value: "allday",
    },
    {
        label: "Buổi sáng",
        value: "morning",
    },
    {
        label: "Buổi chiều",
        value: "afternoon",
    },
    {
        label: "Huỷ thay đổi",
        value: "reset",
    },
];

/** chú ý chỗ này sẽ lưu dạng [date] : data[] */
const convertData = (data: any[]): ITemplate => {
    // update ngày 12/12/2023: trường hợp đk 2 buổi trong 1 ngày nhưng vào 2 lần đk khác nhau thì theo logic cũ là ghi đè luôn nhưng thực tế không thể như vậy
    // nên value của mapDate cần lưu dạng mảng, dữ liệu khi đăng ký đã được (và phải được đảm bảo) xử lý bên server rồi, về client thì phải là dữ liệu hợp lệ
    let mapData: { [d: string]: any[] } = {};
    if (data?.length) {
        data.forEach((d) => {
            let _date = timezoneSetHours(d.date).toISOString();
            if (!mapData[_date]) mapData[_date] = [];
            mapData[_date].push({
                ...d,
                date: timezoneSetHours(d.date),
            });
        });
    }
    return mapData;
};

const getUrgentType = (date: any) => {
    // trả ra loại khẩn cấp cho ngày muốn đăng ký
    // chú ý logic này chỉ đúng với ngày muốn đăng ký
    let urgent = 0;
    let _d = moment(date).tz(Config.TIMEZONE).format(); //YYYY-MM-DDThh:mm:ss+07:00
    let workStartTime = _d.substring(0, 11) + Config.TIME_TO_WORK + _d.substring(19, _d.length); //YYYY-MM-DDT08:30:00+07:00
    let remainingTime = new Date(workStartTime).getTime() - Date.now(); // ktra xem khoảng cách thời gian giữa hiện tại (lúc đăng ký) với thời điểm bắt đầu làm việc của ngày muốn đăng ký
    if (remainingTime < 0) urgent = 2; // đăng ký sau khi bắt đầu làm việc của ngày muốn nghỉ
    else if (remainingTime < Config.URGENT_TIME) urgent = 1;
    if (urgent == 1) return Config.OFF_TYPE_URGENT_1;
    else if (urgent == 2) return Config.OFF_TYPE_URGENT_2;
    return "";
};

const daysDiff = (d1: any, d2: any) => {
    try {
        let date1 = getDate(d1);
        let date2 = getDate(d2);
        return date1.localeCompare(date2);
    } catch (e) {
        console.log(e);
        return -1;
    }
};
export default OutOfficeRegistration;
