import { memo } from "react";
import { useSelector } from "react-redux";
import { ISprint } from "../../../shared/models/sprint";
import { ITaskScores } from "../../../shared/models/taskScore";
import {
    IUserExpectation,
    UserExpectation,
} from "../../../shared/models/userExpectation";
import { IUserInfo } from "../../../shared/models/userInfo";
import Notifications from "../../Notifications/Notifications";
import Notification from "../../common/icon/Notification";
import PersonalGoalWidget from "./PersonalGoal";
import TeamGoalWidget from "./TeamGoal";

const ExpectationAndNotificationComponent = memo(
    ({
        currentSprint,
        taskScores,
        disciplinePoints,
    }: {
        currentSprint: ISprint;
        taskScores: ITaskScores;
        disciplinePoints: any;
    }) => {
        const userInfo: IUserInfo = useSelector(
            (state: any) => state.authState.user
        );
        const userExpectations: IUserExpectation[] = useSelector(
            (state: any) => state.userExpectationState.data
        );
        let expectation =
            userExpectations?.find((e: any) => e.userId === userInfo._id) ??
            new UserExpectation({ userId: userInfo._id });

        return (
            <div className="main-top">
                <div className="expected background-color-2 radius-12">
                    <PersonalGoalWidget
                        title="Mục tiêu cá nhân"
                        taskScores={taskScores}
                        expectation={expectation}
                        currentSprint={currentSprint}
                        disciplinePoints={disciplinePoints}
                    />
                    <div style={{ width: "8px" }} />

                    <TeamGoalWidget title="Mục tiêu team" />
                </div>
                <div className={"notification background-white-008 radius-12 white"}>
                    <div
                        className=" white background-white-008 radius-12 flex align-item-center"
                        style={{
                            height: "56px",
                            fontSize: "18px",
                            justifyContent: "space-between",
                            padding: "16px",
                        }}
                    >
                        <span>Thông báo</span>
                        <Notification haveNotification={true} />
                    </div>
                    <Notifications />
                </div>
            </div>
        );
    }
);

export default ExpectationAndNotificationComponent;
