import { IAppInfo } from "../../models/appinfo.model";
import { ICertificates } from "../../models/certificates.model";

export const METRIC_TYPES = {
  METRIC_TYPE_REPORTS: "METRIC_TYPE_REPORTS",
  METRIC_TYPE_REVIEWS: "METRIC_TYPE_REVIEWS",
  METRIC_TYPE_NPS: "METRIC_TYPE_NPS",
  METRIC_TYPE_HEPL_DESK: "METRIC_TYPE_HEPL_DESK",
  METRIC_TYPE_AVG_QUESTION_USER: "METRIC_TYPE_AVG_QUESTION_USER",
};

export enum dashboardTypes {
  CHANGE_METRIC_EVENT = "CHANGE_METRIC_EVENT",
  GET_INFO_CERTIFICATE_ALL_APP = "GET_INFO_CERTIFICATE_ALL_APP",
  GET_INFO_CERTIFICATE_ALL_APP_SUCCESS = "GET_INFO_CERTIFICATE_ALL_APP_SUCCESS",
  GET_INFO_APPS = "GET_INFO_APPS",
  GET_INFO_APPS_SUCCESS = "GET_INFO_APPS_SUCCESS",
  SET_CURRENT_CERTIFICATES = "SET_CURRENT_CERTIFICATES",
  SET_CERTIFICATE_COMPARE = "SET_CERTIFICATE_COMPARE",
  UPDATE_DATE_TIME = "UPDATE_DATE_TIME",
  GET_RANKING = "GET_RANKING",
  GET_RANKING_SUCCESS = "GET_RANKING_SUCCESS",
  GET_RANKING_FLUTER_SUCCESS = "GET_RANKING_FLUTER_SUCCESS",
  UNSET_COMPARE = "UNSET_COMPARE",
  REMOVE_CERTIFICATE = "REMOVE_CERTIFICATE",
  SEARCH_APP = "SEARCH_APP",
}

export interface IDashBoardAction {
  type: dashboardTypes;
  appName?: string;
  data?: any;
  appID?: number;
  appInfos?: IAppInfo[];
  certificates?: ICertificates[];
  certificate?: ICertificates;
  currentCertificates?: ICertificates;
  typeInfo?: string;
  startTime?: number;
  endTime?: number;
}
export const changeMetricAction = (metric: string) => {
  return { type: dashboardTypes.CHANGE_METRIC_EVENT, metric };
};

export const getInfoCertificateAllAppAction = (appName?: string) => {
  return {
    type: dashboardTypes.GET_INFO_CERTIFICATE_ALL_APP,
    appName: appName,
  };
};

export const getInfoCertificateAllAppSuccessAction = (
  certificates: ICertificates[]
) => {
  return {
    type: dashboardTypes.GET_INFO_CERTIFICATE_ALL_APP_SUCCESS,
    certificates: certificates,
  };
};

export const getInfoAppsAction = (appID?: number) => {
  return { type: dashboardTypes.GET_INFO_APPS, appID: appID };
};

export const getInfoAppsSuccessAction = (appInfos: IAppInfo[]) => {
  return { type: dashboardTypes.GET_INFO_APPS_SUCCESS, appInfos: appInfos };
};

export const setCurrentCertificatesAction = (
  certificates: ICertificates,
  typeInfo: string
) => {
  return {
    type: dashboardTypes.SET_CURRENT_CERTIFICATES,
    currentCertificates: certificates,
    typeInfo: typeInfo,
  };
};

export const setCertificateCompareAction = (
  certificates: ICertificates,
) => {
  return {
    type: dashboardTypes.SET_CERTIFICATE_COMPARE,
    certificate: certificates,
  };
}
export const updateDateTimeAction = (startTime: number, endTime: number) => {
  return { type: dashboardTypes.UPDATE_DATE_TIME, startTime, endTime };
};

export const getRankingAppAction = (appID?: number) => {
  return { type: dashboardTypes.GET_RANKING, appID: appID };
};

export const getRankingAppSuccessAction = (data: any) => {
  return { type: dashboardTypes.GET_RANKING_SUCCESS, data: data };
};

export const getRankingAppFluterSuccessAction = (data: any[]) => {
  return { type: dashboardTypes.GET_RANKING_FLUTER_SUCCESS, data: data };
};

export const unsetCompareAction = () => {
  return { type: dashboardTypes.UNSET_COMPARE };
};

export const removeCertificateAction = (certificate: ICertificates) => {
  return {
    type: dashboardTypes.REMOVE_CERTIFICATE, certificate: certificate
  };
};

export const searchAppAction = (appName: string) => {
  return {
    type: dashboardTypes.SEARCH_APP, appName: appName
  };
};
