import { IUserProgress } from "../../shared/models/userProgress";
import {
    IUserProgressAction,
    UserProgressTypes,
} from "../actions/userProgress.action";

interface IUserProgressState {
    userProgress: IUserProgress[];
    loading: boolean;
    error: any;
}
const initState: IUserProgressState = {
    userProgress: [],
    loading: false,
    error: null,
};

const userProgressState = (
    state = initState,
    action: IUserProgressAction
) => {
    switch (action.type) {
        case UserProgressTypes.UPDATE_USER_PROGRESS_SUCCESS:
            if (action.userProgress) {
                let index = state.userProgress.findIndex(u => u._id == action.userProgress?._id);
                if(index > -1) {
                    state.userProgress[index] = action.userProgress;
                } else {
                    state.userProgress.push(action.userProgress);
                }
            }
            return {
                ...state,
            };
        case UserProgressTypes.UPDATE_USER_PROGRESS_FAILED:
            return {
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default userProgressState;
