import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "./style.scss";

function EvaluationModal({ showModal, handleShowModal, externalData }) {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [data, setData] = useState({
        userId: externalData?.userId ?? id,
        month: externalData?.month ?? new Date().getMonth() + 1,
        year: externalData?.year ?? new Date().getFullYear(),
        strength: externalData?.strength ?? "",
        weakness: externalData?.weakness ?? "",
        review: externalData?.review ?? "",
        opinion: externalData?.opinion ?? "",
        target: externalData?.target ?? "",
    });

    useEffect(() => {
        if (externalData) {
            setData(externalData);
        }
    }, [externalData]);

    const fields = ["month", "year", "strength", "weakness", "review", "opinion", "target"];

    const headers = ["Tháng", "Năm", "Điểm mạnh", "Điểm yếu", "Review công việc", "Ý kiến cá nhân", "Mục tiêu đề ra"];

    const resetState = () => {
        setData({
            userId: id,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            strength: "",
            weakness: "",
            review: "",
            opinion: "",
            target: "",
        });
    };

    const onChangeTextField = (e, field) => {
        setData((prevState) => ({
            ...prevState,
            [field]: e.target.value,
        }));
    };

    const handleSubmitForm = () => {
        if (data._id) {
            dispatch({ type: "UPDATE_USER_FEEDBACK", data });
        } else {
            dispatch({ type: "CREATE_USER_FEEDBACK", data });
        }
        handleShowModal();
        resetState();
    };

    const handleDeleteForm = () => {
        dispatch({ type: "DELETE_USER_FEEDBACK", data });
        handleShowModal();
        resetState();
    };

    return (
        <Modal show={showModal} onHide={handleShowModal} size="lg" className="evaluationModal">
            <Modal.Header closeButton>
                <Modal.Title>Form đánh giá nhân viên</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fields.map((field, index) => (
                    <div className="field" key={index}>
                        <h5>{headers[index]}</h5>
                        {field === "month" || field === "year" ? (
                            <FormControl
                                type="number"
                                name={field}
                                value={data[field]}
                                onChange={(e) => onChangeTextField(e, field)}
                            />
                        ) : (
                            <FormControl
                                as="textarea"
                                rows={2}
                                name={field}
                                value={data[field]}
                                onChange={(e) => onChangeTextField(e, field)}
                            />
                        )}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleShowModal}>
                    Đóng
                </Button>
                <Button variant="success" onClick={handleSubmitForm}>
                    Lưu
                </Button>
                {data._id && (
                    <Button variant="danger" onClick={handleDeleteForm}>
                        Xoá
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default EvaluationModal;
