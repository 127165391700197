import { IPersonalsGoal } from "../../shared/models/personalsGoal";

export enum PersonalsGoalTypes {
    GET_DATA_PERSONALS = "GET_DATA_PERSONALS",
    GET_DATA_PERSONALS_SUCCESS = "GET_DATA_PERSONALS_SUCCESS",
    GET_DATA_PERSONALS_FAILED = "GET_DATA_PERSONALS_FAILED",
    DELETE_DATA_PERSONALS = "DELETE_DATA_PERSONALS",
    DELETE_DATA_PERSONALS_SUCCESS = "DELETE_DATA_PERSONALS_SUCCESS",
    DELETE_DATA_PERSONALS_FAILED = "DELETE_DATA_PERSONALS_FAILED",
    UPDATE_DATA_PERSONALS = "UPDATE_DATA_PERSONALS",
    UPDATE_DATA_PERSONALS_SUCCESS = "UPDATE_DATA_PERSONALS_SUCCESS",
    UPDATE_DATA_PERSONALS_FAILED = "UPDATE_DATA_PERSONALS_FAILED",
}
export interface IPersonalsGoalAction {
    type: PersonalsGoalTypes;
    personalsGoal?: IPersonalsGoal;
    personalsGoals?: IPersonalsGoal[];
    error?: any;
    userID?: string;
    nameTeam?: any;
}
export const getDataPersonalsGoalAcation = (): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.GET_DATA_PERSONALS,
    };
};
export const getDataPersonalsGoalSuccess = (
    personalsGoals: IPersonalsGoal[]
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.GET_DATA_PERSONALS_SUCCESS,
        personalsGoals,
    };
};
export const getDataPersonalsGoalFailed = (
    error: any
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.GET_DATA_PERSONALS_FAILED,
        error,
    };
};
export const deleteDataPersonalsGoalAction = (
    personalsGoal: any
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.DELETE_DATA_PERSONALS,
        personalsGoal,
    };
};
export const deleteDataPersonalsGoalSuccess = (
    personalsGoal: any
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.DELETE_DATA_PERSONALS_SUCCESS,
        personalsGoal,
    };
};
export const deleteDataPersonalsGoalFailed = (
    error: any
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.DELETE_DATA_PERSONALS_FAILED,
        error,
    };
};
export const updateDataPersonalsGoalAction = (
    personalsGoal: IPersonalsGoal
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.UPDATE_DATA_PERSONALS,
        personalsGoal,
    };
};
export const updateDataPersonalsGoalSuccess = (
    personalsGoal: IPersonalsGoal
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.UPDATE_DATA_PERSONALS_SUCCESS,
        personalsGoal,
    };
};
export const updateDataPersonalsGoalFailed = (
    error: any
): IPersonalsGoalAction => {
    return {
        type: PersonalsGoalTypes.DELETE_DATA_PERSONALS_FAILED,
        error,
    };
};
