import { ITask } from '../../../shared/models/task'

export default function Filter({ versions, handleGetInfoUser, Constants }: any) {
    return (
        versions?.map((version: any) =>
            version.tasks?.map((task: ITask, index: number) =>
                <tr key={task._id}>
                    <td></td>
                    <td>
                        {index === 0 && version.version}
                    </td>
                    <td>{index === 0 && version.dateVersion}</td>
                    <td>
                        <a href={"https://hero.abc-elearning.org/task/" + task._id}>
                            {task.title}
                        </a>
                    </td>
                    <td
                        className="avatar"
                        onClick={() =>
                            window.open("https://hero.abc-elearning.org/user/" + task.assignee)
                        }
                    >
                        <img src={handleGetInfoUser(task.assignee, Constants.GET_FIELD_AVATAR).avatar} />
                        <span>
                            {handleGetInfoUser(task.assignee, Constants.GET_FIELD_FIRSTNAME).firstName}
                        </span>
                    </td>
                </tr>)
        ))
}