import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { ActionMeta, MultiValue } from "react-select";
import { updateCampaigns } from "../../../../redux/actions/campaign.action";
import { ICampaignState } from "../../../../redux/reducers/campaign_new.reducer";
import { ICampaign } from "../../../../shared/models/campaign";
import { ITask } from "../../../../shared/models/task";
import { SelectOption, convertCampaignOptions } from "../../../../utils";
import LoadingWidget from "../../../LoadingWidget";
import "./index.scss";
import { getChildTasks } from "..";

const SelectCampaignWidget: FC<{ task: ITask }> = ({ task }) => {
    const campaignState: ICampaignState = useSelector((state: any) => state.campaignState);
    const allTasks: ITask[] = useSelector((state: any) => state.taskState.tasks ?? []);
    const dispatch = useDispatch();
    const campaigns = campaignState.campaigns ?? [];
    if (campaignState.loading) {
        return <LoadingWidget />;
    }
    if (campaigns.length == 0) {
        return <div>Campaign empty!</div>;
    }
    const campaignOptions = convertCampaignOptions(campaigns);
    const campaignIdsSelected =
        campaigns.filter((campaign: ICampaign) => campaign.listTaskIDs.includes(task._id))?.map((x) => x._id) ?? [];
    const value = campaignOptions.filter((c) => campaignIdsSelected.includes(c.value));

    const child_tasks = getChildTasks(task, allTasks);

    const updateCampaign = (newValue: MultiValue<SelectOption>, action: ActionMeta<SelectOption>) => {
        // console.log("newValue: ", newValue);
        // console.log("action: ", action)
        let listCampaignsUpdate: ICampaign[] = [];
        if (action.action === "clear") {
            for (let campaign of campaigns) {
                const index = campaign.listTaskIDs.indexOf(task._id);
                if (index > -1) {
                    campaign.listTaskIDs.splice(index, 1);
                    listCampaignsUpdate.push(campaign);

                    // Kiem tra child task
                    for (const child_task of child_tasks) {
                        const index = campaign.listTaskIDs.indexOf(child_task._id);
                        if (index > -1) {
                            campaign.listTaskIDs.splice(index, 1);
                        }
                    }
                }
            }
        } else {
            if (action.action === "select-option") {
                for (let option of newValue) {
                    const campaign = campaigns.find((c) => c._id === option.value);
                    if (campaign) {
                        const index = campaign.listTaskIDs.indexOf(task._id);
                        if (index == -1) {
                            campaign.listTaskIDs.push(task._id);
                            listCampaignsUpdate.push(campaign);

                            // Kiem tra child task
                            for (const child_task of child_tasks) {
                                const index = campaign.listTaskIDs.indexOf(child_task._id);
                                if (index == -1) {
                                    campaign.listTaskIDs.push(child_task._id);
                                }
                            }
                        }
                    }
                }
            } else if (action.action === "remove-value") {
                const campaign = campaigns.find((c) => c._id === action.removedValue.value);
                if (campaign) {
                    const index = campaign.listTaskIDs.indexOf(task._id);
                    if (index > -1) {
                        campaign.listTaskIDs.splice(index, 1);
                        listCampaignsUpdate.push(campaign);

                        // Kiem tra child task
                        for (const child_task of child_tasks) {
                            const index = campaign.listTaskIDs.indexOf(child_task._id);
                            if (index > -1) {
                                campaign.listTaskIDs.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }
        if (listCampaignsUpdate.length) {
            dispatch(updateCampaigns(listCampaignsUpdate));
        }
    };

    return (
        <div className="select-campaign">
            <Select value={value} isMulti options={campaignOptions} className="basic-multi-select" onChange={updateCampaign} />
        </div>
    );
};

export default SelectCampaignWidget;
