import { TableFooter, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { priceProApp } from "../../../config_web";
import Constants from "../../../constant";
import { IAppInfo } from "../../../models/appinfo.model";
import {
    Certificates,
    ICertificates,
} from "../../../models/certificates.model";
import {
    getPercentRealPrice,
    getPlatformName,
    REVENUE_ADS_APP,
    REVENUE_ADS_WEB,
    REVENUE_ALL,
    REVENUE_PRO_APP,
    REVENUE_PRO_WEB,
    REVENUE_PRO_WEB_CARD_VISA,
    REVENUE_PRO_WEB_ONE_TIME,
    REVENUE_PRO_WEB_PAYPAL,
    REVENUE_PRO_WEB_SUBSCRIPTION,
    TYPE_ANALYTIC_CONTENT,
    TYPE_ANALYTIC_TRAFFIC,
    TYPE_REVENUE,
    getFlatForm,
} from "../../../config_data_analytic";
import { setCurrentCertificatesAction } from "../../../redux/actions/dashboard.action";
import {
    getAdsRevenueWebAPI,
    getTotalProWebRevenueAPI,
    getProRevenueAppAPI,
    getTotalAdsRevenueAppAPI,
} from "../../../services/traffic.service";
import { formatDate, formatPrice, groupArrayOfObjects } from "../../../util";
import { urlDashboard } from "../../../util/url";
// import Certificates from "../../dashboard-analytic/question-quality/test-info";
import CircularIndeterminate from "../icon/Loading";
//   valueGetter: (params: GridValueGetterParams) =>
//             `${params.row.firstName || ""} ${params.row.lastName || ""}`,
interface Data {
    name: string;
    // avgPositionRank: number;
    churnRate: number;
    nbActiveUser: number;
    nbQuestionUser: number;
    nbRating: number;
    nbUserStickiness: number;
    nps: number;
    reportedMistake: number;
    retentionRate: number;
    revenueActiveUser: number;
    totalRevenue: number;
    certificate: any;

    proApp: number;
    adsApp: number;
    proWeb: number;
    adsWeb: number;
}

function createData(
    name: string,
    // avgPositionRank: number,
    churnRate: number,
    nbActiveUser: number,
    nbQuestionUser: number,
    nbRating: number,
    nbUserStickiness: number,
    nps: number,
    reportedMistake: number,
    retentionRate: number,
    revenueActiveUser: number,
    totalRevenue: number,
    certificate: any,

    proApp: number,
    adsApp: number,
    proWeb: number,
    adsWeb: number
): Data {
    return {
        name,
        // avgPositionRank,
        churnRate,
        nbActiveUser,
        nbQuestionUser,
        nbRating,
        nbUserStickiness,
        nps,
        reportedMistake,
        retentionRate,
        revenueActiveUser,
        totalRevenue,
        certificate,
        proApp,
        adsApp,
        proWeb,
        adsWeb,
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "App/Web",
    },
    {
        id: "totalRevenue",
        numeric: true,
        disablePadding: false,
        label: "Total revenue ($)",
    },
    {
        id: "proApp",
        numeric: true,
        disablePadding: false,
        label: "Total Pro App ($)",
    },
    {
        id: "adsApp",
        numeric: true,
        disablePadding: false,
        label: "Total Ads App ($)",
    },
    {
        id: "proWeb",
        numeric: true,
        disablePadding: false,
        label: "Total Pro Web ($)",
    },
    {
        id: "adsWeb",
        numeric: true,
        disablePadding: false,
        label: "Total Ads Web ($)",
    },

    // {
    //     id: "avgPositionRank",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Avg Position Rank",
    // },
    // {
    //     id: "churnRate",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Churn Rate",
    // },
    // {
    //     id: "nbActiveUser",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Number Active User",
    // },
    // {
    //     id: "nbQuestionUser",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Number Question / User",
    // },
    // {
    //     id: "nbRating",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Number Rating < 3",
    // },
    // {
    //     id: "nbUserStickiness",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "User stickiness",
    // },
    // {
    //     id: "nps",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Nps",
    // },
    // {
    //     id: "reportedMistake",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Number reported mistakes",
    // },
    // {
    //     id: "retentionRate",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Retention Rate",
    // },
    // {
    //     id: "revenueActiveUser",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Revenue / active User",
    // },
];

function EnhancedTableHead({
    order,
    orderBy,
    onRequestSort,
}: {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
}) {
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            {/* <TableRow>
                <TableCell align="center" colSpan={3}></TableCell>
                <TableCell align="center" colSpan={2}>
                    Pro revenue
                </TableCell>
                <TableCell align="center" colSpan={1}></TableCell>
            </TableRow> */}
            <TableRow>
                {headCells.map((headCell, index: number) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "center" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const DatatableGrid = ({ valueSelect }: { valueSelect: any[] }) => {
    const [rows, setRows] = useState<any>([]);
    const [rowsData, setRowsData] = useState<any>([]);
    const [onlyOneApp, setOnlyOneApp] = useState<any>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("totalRevenue");
    const [allResultRevenue, setAllResultRevenue] = useState<any>();
    const [allResultOverview, setAllResultOverview] = useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sumValue, setSumValue] = useState<any>(0);
    const [revenue, setRevenue] = useState<any>([]);
    const dispatch = useDispatch();
    const certificates: ICertificates[] = useSelector(
        (state: any) => state.dashBoardState.certificates
    );

    const p = [
        Constants.PLATFORM_REACT_NATIVE_ANDROID,
        Constants.PLATFORM_REACT_NATIVE_IOS,
    ];

    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const searchText: string = useSelector(
        (state: any) => state.dashBoardState.search
    );
    const createDataDashboard = async (startDate: any, endDate: any) => {
        let twoDaysAgo = endDate;

        try {
            const resListApp = await axios.get(
                urlDashboard + "app/show-certificate-information"
            );
            let listApp = resListApp.data;
            // listApp.map((item: any) => {
            //     console.log(item);
            // });
            const res = await Promise.all([
                // axios.get(
                //     urlDashboard +
                //         "overview?startDate=" +
                //         startDate +
                //         "&endDate=" +
                //         endDate
                // ),
                // axios.get(
                //     urlDashboard +
                //         "overview/update-data-in-ga?startDate=" +
                //         startDate +
                //         "&=" +
                //         endDate
                // ),
                // axios.get(
                //     urlDashboard +
                //         "overview/update-data-in-report?startDate=" +
                //         startDate +
                //         "&endDate=" +
                //         endDate
                // ),
                // axios.get(
                //     urlDashboard +
                //         "overview/update-data-revenue?startDate=" +
                //         startDate +
                //         "&endDate=" +
                //         endDate
                // ),
                // listApp.map((app: any) => {
                //     return axios.get(
                //         urlDashboard +
                //             "overview/update-data-in-bigquery-app?startDate=" +
                //             startDate +
                //             "&endDate=" +
                //             twoDaysAgo +
                //             "&id=" +
                //             app.id
                //     );
                // }),
            ]);
            if (res) {
                return await getDataDashboard(startDate, endDate);
            }
        } catch (error) {
            console.log("error createDataDashboard", error);
        }

        // // for (const app of listApp) {

        // //   }
    };
    const getDataDashboard = async (startDate: any, endDate: any) => {
        try {
            const resData = await axios.get(
                urlDashboard +
                    "get-overview?startDate=" +
                    startDate +
                    "&endDate=" +
                    endDate
            );
            return resData.data;
        } catch (error) {
            console.log("error getDataDashboard", error);
        }
    };
    useEffect(() => {
        let startDate = formatDate(new Date(startTime), "YYYY-MM-DD");
        let endDate = formatDate(new Date(endTime), "YYYY-MM-DD");
        // console.log('start', startDate, 'end', endDate);

        let list = certificates.map((certificate) => {
            let appInfos = certificate.appInfos?.filter(
                (app) => !p.includes(app.platformId)
            );
            return {
                ...certificate,
                appInfos,
            };
        });

        const getDataFc = async () => {
            setIsLoading(true);

            // let dataRevenueApps = await getDataRevenueApps();

            let overviews = await getDataDashboard(startDate, endDate);
            if (overviews.length === 0) {
                overviews = await createDataDashboard(startDate, endDate);
            }
            let x = groupArrayOfObjects(overviews ?? [], "appId");
            let formatDateOverview = Object.keys(x)?.map((element: any) => {
                const overviewApp = x[element];
                let data: any = [];
                let appData = overviewApp[0].appData;
                let certificate = list.find((i) =>
                    i.appIDs.includes(String(appData.id))
                );

                let appName =
                    appData.name + " " + getFlatForm(appData.platform);
                let avgPositionRank = 0;
                let churnRate = 0;
                let nbActiveUser = 0;
                let nbQuestionUser = 0;
                let nbRating = 0;
                let nbUserStickiness = 0;
                let nps = 0;
                let reportedMistake = 0;
                let retentionRate = 0;
                let revenueActiveUser = 0;
                let totalRevenue = 0;
                let npsValue: any;
                overviewApp.forEach((i: any, index: number) => {
                    let month = new Date(i.createDate).getMonth() + 1;
                    const value2 = {
                        month,
                        nbQuestionUser: i.nbQuestionUser ?? 0,
                    };
                    const find = data.findIndex(
                        (object: any) => object.month === value2.month
                    );

                    if (find === -1) {
                        data.push(value2);
                    }
                    avgPositionRank += i.avgPositionRank;
                    churnRate = churnRate + i.churnRate ?? 0;
                    nbActiveUser = nbActiveUser + i.nbActiveUser ?? 0;
                    nbRating = nbRating + i.nbRating ?? 0;
                    nbUserStickiness =
                        nbUserStickiness + i.nbUserStickness ?? 0;
                    npsValue = {
                        value: npsValue ? npsValue.value + i.nps : i.nps,
                        count: npsValue ? npsValue.value + 1 : 1,
                    };
                    reportedMistake = reportedMistake + i.reportedMistake ?? 0;
                    retentionRate = retentionRate + i.retentionRate ?? 0;
                    revenueActiveUser =
                        revenueActiveUser + i.revenueActiveUser ?? 0;
                    totalRevenue = totalRevenue + i.totalRevenue ?? 0;
                });
                // let resultRevenue = dataRevenueApps.find(
                //     (app: any) => app.id == appData.id
                // );
                // if (appData.platform != 1) {
                //     totalRevenue = resultRevenue?.totalRevenue ?? 0;
                // }
                nbQuestionUser = data.reduce(function (
                    sum: number,
                    current: any
                ) {
                    return sum + current.nbQuestionUser;
                },
                0);
                nps = npsValue.value / npsValue.count;
                return {
                    appName: appName ?? "",
                    id: appData?.id,
                    churnRate: churnRate,
                    nbActiveUser: nbActiveUser,
                    nbQuestionUser: nbQuestionUser,
                    nbRating: nbRating,
                    nbUserStickiness: nbUserStickiness,
                    nps: nps,
                    reportedMistake: reportedMistake,
                    retentionRate: retentionRate,
                    revenueActiveUser: revenueActiveUser,
                    totalRevenue: totalRevenue,
                    certificate: certificate,
                };
            });
            const slugs = list
                .map((certificate) => {
                    return certificate.slug;
                })
                .filter((slug) => !!slug);
            let allResult = await Promise.all([
                // getProRevenueAppAPI(
                //     [-1],
                //     new Date(startTime).getTime(),
                //     new Date(endTime).getTime()
                // ),
                {},
                getTotalAdsRevenueAppAPI(startDate, endDate),
                getTotalProWebRevenueAPI(
                    slugs.filter((slug) => slug != "passemall"),
                    startDate,
                    endDate
                ),
                getAdsRevenueWebAPI(
                    slugs,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                ),
            ]);
            setAllResultOverview(formatDateOverview);
            setAllResultRevenue(allResult);

            setIsLoading(false);
        };

        if (new Date(startDate).getTime() != new Date(endDate).getTime()) {
            getDataFc();
        }
    }, [certificates, startTime, endTime]);

    useEffect(() => {
        let list = certificates.map((certificate) => {
            let appInfos = certificate.appInfos?.filter(
                (app) => !p.includes(app.platformId)
            );
            return {
                ...certificate,
                appInfos,
            };
        });
        list = list.filter(
            (certificate) =>
                !(certificate.slug == "pmp" || certificate.slug == "aws")
        );
        let allResult = allResultRevenue;

        if (allResultRevenue && allResultRevenue.length > 0) {
            //  let resDataProApps = allResult[0];

            //  let resDataAdsApps = allResult[1];

            //  let resDataProWebs = allResult[2];
            //  let resDataAdsWebs = allResult[3];
            let resDataProApps =
                valueSelect.includes(REVENUE_ALL) ||
                valueSelect.includes(REVENUE_PRO_APP)
                    ? allResult[0]
                    : null;
            let resDataAdsApps =
                valueSelect.includes(REVENUE_ALL) ||
                valueSelect.includes(REVENUE_ADS_APP)
                    ? allResult[1]
                    : null;

            let resDataProWebs =
                valueSelect.includes(REVENUE_ALL) ||
                valueSelect.includes(REVENUE_PRO_WEB) ||
                valueSelect.includes(REVENUE_PRO_WEB_ONE_TIME) ||
                valueSelect.includes(REVENUE_PRO_WEB_SUBSCRIPTION) ||
                valueSelect.includes(REVENUE_PRO_WEB_PAYPAL) ||
                valueSelect.includes(REVENUE_PRO_WEB_CARD_VISA)
                    ? allResult[2]
                    : null;

            let resDataAdsWebs =
                valueSelect.includes(REVENUE_ALL) ||
                valueSelect.includes(REVENUE_ADS_WEB)
                    ? allResult[3]
                    : null;

            resDataProApps =
                resDataProApps &&
                Object.keys(resDataProApps).map((key) => {
                    const appData = resDataProApps[key];

                    var groupedDatas = groupArrayOfObjects(appData, "appId");
                    return Object.keys(groupedDatas).map((key: string) => {
                        const dataApp = groupedDatas[key];

                        const appPrices: any = {};
                        certificates
                            .find(
                                (c: ICertificates) =>
                                    c.appID?.toString() === key
                            )
                            ?.appInfos?.forEach((app) => {
                                if (app.platformId == 2) {
                                    appPrices["android"] = app.price;
                                } else if (app.platformId == 3) {
                                    appPrices["ios"] = app.price;
                                }
                            });
                        var groupedDataApp = groupArrayOfObjects(
                            dataApp,
                            "osType"
                        );

                        return Object.keys(groupedDataApp).map((osTypeKey) => {
                            const appId = key;
                            let price: number = 0;
                            let priceConfigApp: number =
                                appPrices[osTypeKey] ?? 0;
                            if (priceConfigApp == 0) {
                                priceConfigApp = priceProApp()[appId] ?? 0;
                            }
                            const dataOsType = groupedDataApp[osTypeKey];
                            dataOsType.map((item: any) => {
                                price +=
                                    priceConfigApp *
                                    getPercentRealPrice(osTypeKey); // Number(item.price);
                            });
                            return {
                                appId,
                                price,
                                osTypeKey,
                            };
                        });
                    });
                });

            resDataProApps = resDataProApps?.flat()?.flat(1);
            resDataAdsApps =
                resDataAdsApps &&
                Object.keys(resDataAdsApps).map((gaIdKey) => {
                    const value = resDataAdsApps[gaIdKey];
                    return {
                        gaId: gaIdKey,
                        value,
                    };
                });

            resDataAdsWebs =
                resDataAdsWebs && groupArrayOfObjects(resDataAdsWebs, "slug");
            resDataAdsWebs =
                resDataAdsWebs &&
                Object.keys(resDataAdsWebs).map((slugKey) => {
                    const datas = resDataAdsWebs[slugKey];
                    let value = 0;
                    datas.forEach((ele: any) => {
                        value += Number(ele.value);
                    });
                    return {
                        slug: slugKey,
                        value,
                    };
                });
            const rowData = list?.map((certificate: ICertificates) => {
                const appInfos = certificate.appInfos;
                const slug = certificate.slug;
                const data = appInfos?.map((app: IAppInfo) => {
                    const platformId = app.platformId;
                    const appName =
                        app.name + " " + getPlatformName(platformId);
                    let adsRevenue = 0;
                    let proRevenue = 0;
                    let proPayment: any = {};
                    let subscriptionRevenue = 0;
                    let oneTimePaymentRevenue = 0;
                    let cardRevenue = 0;
                    let paypalRevenue = 0;
                    let appId = app.appID;
                    appId = !!appId ? appId : certificate.appID;
                    let id = app.id;

                    let osType =
                        platformId == 1
                            ? "web"
                            : platformId == 2 || platformId == 4
                            ? "android"
                            : "ios";
                    let totalProApp: number = 0;
                    let totalAdsApp: number = 0;
                    let totalProWeb: number = 0;
                    let totalAdsWeb: number = 0;
                    if (platformId == Constants.PLATFORM_WEB) {
                        const proWeb =
                            resDataProWebs &&
                            resDataProWebs?.find(
                                (item: any) => item.slug == slug
                            );

                        const adsWeb = resDataAdsWebs?.find(
                            (item: any) => item.slug == slug
                        );
                        let payment = proWeb?.transactionPaymentSouce;
                        let transactionSj = proWeb?.transactionSubject;
                        if (payment) {
                            proPayment[REVENUE_PRO_WEB_PAYPAL] =
                                payment?.paypal ?? 0;
                            proPayment[REVENUE_PRO_WEB_CARD_VISA] =
                                payment?.card ?? 0;
                        }

                        if (transactionSj) {
                            proPayment[REVENUE_PRO_WEB_SUBSCRIPTION] =
                                transactionSj.subscription ?? 0;
                            proPayment[REVENUE_PRO_WEB_ONE_TIME] =
                                transactionSj.oneShot ?? 0;
                        }

                        if (
                            valueSelect.includes(REVENUE_ALL) ||
                            valueSelect.includes(REVENUE_PRO_WEB)
                        ) {
                            proRevenue = proWeb
                                ? proWeb?.amount?.toFixed(1)
                                : 0;
                        } else {
                            valueSelect.forEach((vl: any) => {
                                proRevenue += Number(proPayment[vl] ?? 0);
                            });
                        }
                        adsRevenue = adsWeb ? adsWeb?.value?.toFixed(1) : 0;

                        totalProWeb += Number(Number(proRevenue)?.toFixed(1));
                        totalAdsWeb += Number(Number(adsRevenue)?.toFixed(1));
                    } else {
                        let proPrice = resDataProApps?.find(
                            (item: any) =>
                                item.appId == appId && item.osTypeKey == osType
                        );

                        proRevenue = proPrice
                            ? Number(Number(proPrice.price).toFixed(1))
                            : 0;
                        let adsValue = resDataAdsApps?.find(
                            (item: any) => app.gaID == item.gaId
                        );
                        adsRevenue = adsValue
                            ? Number(Number(adsValue.value)?.toFixed(1))
                            : 0;
                        totalProApp += proRevenue;
                        totalAdsApp += adsRevenue;
                    }
                    const totalRevenue = Number(
                        (Number(adsRevenue) + Number(proRevenue))?.toFixed(1)
                    );
                    let overview = allResultOverview.find(
                        (i: any) => i.id == id
                    );

                    // return { appName, totalRevenue, certificate };
                    return createData(
                        appName,
                        overview?.churnRate ?? null,
                        overview?.nbActiveUser ?? null,
                        overview?.nbQuestionUser ?? null,
                        overview?.nbRating ?? null,
                        overview?.nbUserStickiness ?? null,
                        overview?.nps ?? null,
                        overview?.reportedMistake ?? null,
                        overview?.retentionRate ?? null,
                        overview?.revenueActiveUser ?? null,
                        totalRevenue ?? null,
                        certificate,
                        totalProApp,
                        totalAdsApp,
                        totalProWeb,
                        totalAdsWeb
                    );
                });
                return data;
            });
            setRowsData(rowData?.flat());
        }
    }, [allResultRevenue, certificates, valueSelect, allResultOverview]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        let sumTotalRevenue = 0;
        let sumProApp = 0;
        let sumAdsApp = 0;
        let sumProWeb = 0;
        let sumAdsWeb = 0;

        let sumAvgPositionRank = 0;
        let sumChurnRate = 0;
        let sumNbActiveUser = 0;
        let sumNbQuestionUser = 0;
        let sumNbRating = 0;
        let sumNbUserStickiness = 0;
        let sumNps = 0;
        let sumReportedMistake = 0;
        let sumRetentionRate = 0;
        let sumRevenueActiveUser = 0;
        rows?.forEach((row: Data) => {
            sumTotalRevenue = (sumTotalRevenue ?? 0) + Number(row.totalRevenue);
            sumProApp = (sumProApp ?? 0) + Number(row.proApp);
            sumAdsApp = (sumAdsApp ?? 0) + Number(row.adsApp);
            sumProWeb = (sumProWeb ?? 0) + Number(row.proWeb);
            sumAdsWeb = (sumAdsWeb ?? 0) + Number(row.adsWeb);
            // sumAvgPositionRank =
            //     (sumAvgPositionRank ?? 0) + Number(row.avgPositionRank);
            // sumChurnRate = (sumChurnRate ?? 0) + Number(row.churnRate);
            // sumNbActiveUser = (sumNbActiveUser ?? 0) + Number(row.nbActiveUser);
            // sumNbQuestionUser =
            //     (sumNbQuestionUser ?? 0) + Number(row.nbQuestionUser);
            // sumNbRating = (sumNbRating ?? 0) + Number(row.nbRating);
            // sumNbUserStickiness =
            //     (sumNbUserStickiness ?? 0) + Number(row.nbUserStickiness);
            // sumNps = (sumNps ?? 0) + Number(row.nps);
            // sumReportedMistake =
            //     (sumReportedMistake ?? 0) + Number(row.reportedMistake);
            // sumRetentionRate =
            //     (sumRetentionRate ?? 0) + Number(row.retentionRate);
            // sumRevenueActiveUser =
            //     (sumRevenueActiveUser ?? 0) +
            //     (row.revenueActiveUser == 0 ||
            //     row.nbActiveUser == 0 ||
            //     !row.revenueActiveUser ||
            //     !row.nbActiveUser
            //         ? 0
            //         : Number(row.revenueActiveUser) / Number(row.nbActiveUser));
        });
        setSumValue(sumTotalRevenue);
        setRevenue([
            formatPrice(sumTotalRevenue),
            formatPrice(sumProApp),
            formatPrice(sumAdsApp),
            formatPrice(sumProWeb),
            formatPrice(sumAdsWeb),
            // formatPrice(sumAvgPositionRank),
            // formatPrice(sumChurnRate),
            // formatPrice(sumNbActiveUser),
            // formatPrice(sumNbQuestionUser),
            // formatPrice(sumNbRating),
            // formatPrice(sumNbUserStickiness),
            // formatPrice(sumNps),
            // formatPrice(sumReportedMistake),
            // formatPrice(sumRetentionRate),
            // sumRevenueActiveUser.toFixed(1),
        ]);
    }, [rows]);

    useEffect(() => {
        let filterRows = [...rowsData];

        if (!!searchText) {
            filterRows = filterRows.filter((row: Data) => {
                return (
                    row.name.toLocaleLowerCase().includes(searchText) ||
                    row?.certificate?.parent
                        ?.toLocaleLowerCase()
                        .includes(searchText)
                );
            });
        }
        setRows(filterRows);
    }, [searchText, rowsData]);

    const selectApp = (item: any, type: any) => {
        let certificate = new Certificates(item);
        dispatch(setCurrentCertificatesAction(certificate, type));
        localStorage.setItem("currentCertificatesType", type);
        localStorage.setItem(
            "currentCertificatesId",
            JSON.stringify(certificate.id)
        );
    };

    useEffect(() => {
        let list = rows.map((i: any) => {
            return i.certificate;
        });
        list = new Set(list);
        list = Array.from(list);

        if (list.length == 1) {
            setOnlyOneApp(list[0]);
        } else {
            setOnlyOneApp("");
        }
    }, [rows]);
    const formatNumber = (nb: any) => {
        return Number(nb).toFixed(1);
    };
    return (
        <>
            {isLoading ? (
                <CircularIndeterminate />
            ) : rows.length == 0 ? null : (
                <>
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table aria-labelledby="tableTitle">
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(
                                            rows,
                                            getComparator(order, orderBy)
                                        )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.name}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            onClick={() =>
                                                                selectApp(
                                                                    row.certificate,
                                                                    TYPE_ANALYTIC_TRAFFIC
                                                                )
                                                            }
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            onClick={() => {
                                                                selectApp(
                                                                    row.certificate,
                                                                    TYPE_REVENUE
                                                                );
                                                            }}
                                                        >
                                                            {formatPrice(
                                                                row.totalRevenue ??
                                                                    0
                                                            )}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {formatPrice(
                                                                row.proApp ?? 0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatPrice(
                                                                row.adsApp ?? 0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatPrice(
                                                                row.proWeb ?? 0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatPrice(
                                                                row.adsWeb ?? 0
                                                            )}
                                                        </TableCell>

                                                        {/* <TableCell align="center">
                                                            {formatNumber(
                                                                row.churnRate ??
                                                                    0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatPrice(
                                                                row.nbActiveUser ??
                                                                    0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.nbQuestionUser ??
                                                                0}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.nbRating ?? 0}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatNumber(
                                                                row.nbUserStickiness ??
                                                                    0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatNumber(
                                                                row.nps ?? 0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.reportedMistake ??
                                                                0}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {formatNumber(
                                                                row.retentionRate ??
                                                                    0
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(
                                                                Number(
                                                                    row.totalRevenue
                                                                ) /
                                                                Number(
                                                                    row.nbActiveUser
                                                                )
                                                            ).toFixed(2) ?? 0}
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                    <TableFooter>
                                        {sumValue && (
                                            <>
                                                <TableCell
                                                    align="left"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        !!onlyOneApp &&
                                                            selectApp(
                                                                onlyOneApp,
                                                                TYPE_ANALYTIC_TRAFFIC
                                                            );
                                                    }}
                                                >
                                                    Tổng
                                                </TableCell>

                                                {revenue.map(
                                                    (i: any, index: number) => {
                                                        return (
                                                            <TableCell
                                                                align="center"
                                                                key={index}
                                                            >
                                                                {i}
                                                            </TableCell>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={1}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </>
            )}
        </>
    );
};
export default DatatableGrid;
