import { Person } from "@material-ui/icons";
import { FC } from "react";
import defaultAvatar from "../../assets/images/default-avatar.png";

const MyAvatar:FC<({ url?: string, size?: number })> = ({ url, size }) => {
    if(!url || url.length === 0) {
        return (
            <img src={defaultAvatar} width={size ?? 32} height={size ?? 32} style={{ borderRadius: size ?? 32 }} />
        );
    }
    return (
        <img src={url} width={size ?? 32} height={size ?? 32} style={{ borderRadius: size ?? 32 }} />
    );
}

export default MyAvatar;