import { call, put, takeLatest } from "redux-saga/effects";
import {
    IProblemReportingAction,
    ProblemReportingTypes,
    addProblemSuccess,
    updateProblemSuccess,
    deleteProblemSuccess,
    getAllProblemReportingSuccess,
} from "../actions/problemReporting.action";
import {
    IProblemReporting,
    ProblemReporting,
} from "../../shared/models/problemReporting";
import axios from "axios";
import Url from "../../util/url";

async function getAllProblemReportingAPI(): Promise<IProblemReporting[]> {
    return axios.get(Url("problem-reporting/get-all")).then((data) => {
        if (data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new ProblemReporting(item);
            });
        }
        return [];
    });
}

function* getAllProblemSaga(action: IProblemReportingAction) {
    const list: ProblemReporting[] = yield call(getAllProblemReportingAPI);
    yield put(getAllProblemReportingSuccess(list));
}


async function updateProblemReportingAPI(
    problem: IProblemReporting
): Promise<IProblemReporting | null> {
    const item = new ProblemReporting(problem)
    return axios
        .post(Url("problem-reporting/update-data/"), item.toJson())
        .then((data) => {
            if (data.status == 200 && data.data) {
                const problemReportingObject = data.data;
                const problemReportingInstance = new ProblemReporting(problemReportingObject);
                return problemReportingInstance;
            }
            return null;
        });
}

function* addProblemSaga(action: IProblemReportingAction) {
    if (action.problem) {
        const problem: ProblemReporting = yield call(updateProblemReportingAPI, action.problem);
        yield put(addProblemSuccess(problem));
    }
}

function* updateProblemSaga(action: IProblemReportingAction) {
    if (action.problem) {
        const problem: IProblemReporting = yield call(updateProblemReportingAPI, action.problem);
        yield put(updateProblemSuccess(action.problem));
    }
}

async function deleteProblemReportingAPI(problem: IProblemReporting): Promise<boolean> {
    return axios
    .delete(Url("problem-reporting/delete-data/" + problem._id))
    .then((data) => {
        if (data.status == 200) {
            return true;
        }
        return false;
    });
}

function* deleteProblemSaga(action: IProblemReportingAction) {
    if (action.problem) {
        const status:boolean = yield call(deleteProblemReportingAPI, action.problem);
        if (status) {
            yield put(deleteProblemSuccess(action.problem));
        }
    }
    

}

export function* watchProblemReportingSaga() {
    yield takeLatest(ProblemReportingTypes.ADD_PROBLEM, addProblemSaga);
    yield takeLatest(ProblemReportingTypes.DELETE_PROBLEM, deleteProblemSaga);
    yield takeLatest(ProblemReportingTypes.UPDATE_PROBLEM, updateProblemSaga);
    yield takeLatest(ProblemReportingTypes.GET_ALL_PROBLEM, getAllProblemSaga);
}
