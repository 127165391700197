import { Button, Checkbox, IconButton } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Delete, FileCopy } from "@material-ui/icons";
import { FC, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateAppInfo } from "../../../redux/actions/app.action";
import { FirebaseConfig, IAppColor, IAppInfo, IAppOS, InAppProduct } from "../../../shared/models/appInfo";
import { convertDate } from "../../../shared/utils";
import { formatDate } from "../../../util";
import "../index.scss";

import { toast } from "react-toastify";
import downloadObjectAsJson from "../../../utils";

const AppItemWidget: FC<{
    appInfo: IAppInfo;
    onShowAppVersion: () => void;
    onShowAppTheme: () => void;
    onDeleteApp: () => void;
}> = ({ appInfo, onShowAppVersion, onShowAppTheme, onDeleteApp }) => {
    const [selected, setSelected] = useState<IAppInfo | null>(null);
    const dispatch = useDispatch();
    const onUpdateAppInfo = (newAppInfo: IAppInfo) => {
        dispatch(updateAppInfo(newAppInfo));
    };
    return (
        <div
            style={{
                border: "1px solid #ddd",
                borderRadius: 5,
                marginBottom: 12,
                padding: 20,
                backgroundColor: !!selected ? "#fff" : "",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div style={{ minWidth: 200 }}>{appInfo.longId}</div>
                <div style={{ minWidth: 200 }}>{appInfo.shortName}</div>
                <div style={{ minWidth: 200 }}>{appInfo.bucket}</div>
                <div style={{ minWidth: 100 }}>{appInfo.hasState ? "true" : "false"}</div>
                <div style={{ minWidth: 100 }}>{formatDate(convertDate(appInfo.lastUpdate))}</div>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: "auto", marginRight: 12 }}
                    onClick={onShowAppVersion}
                >
                    Version
                </Button>
                <Button variant="contained" color="primary" onClick={onShowAppTheme}>
                    Theme
                </Button>
                <IconButton
                    style={{ marginLeft: "auto" }}
                    onClick={() => (!selected ? setSelected(appInfo) : setSelected(null))}
                >
                    {selected ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </div>
            {selected?._id == appInfo._id && (
                <>
                    <TextEditor
                        title="ID"
                        text={appInfo.longId?.toString()}
                        onChange={(value: string) => {
                            appInfo.longId = parseInt(value);
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <TextEditor
                        title="Short Name"
                        text={appInfo.shortName}
                        onChange={(value: string) => {
                            appInfo.shortName = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <TextEditor
                        title="Bucket"
                        text={appInfo.bucket}
                        onChange={(value: string) => {
                            appInfo.bucket = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <TextEditor
                        title="Key"
                        text={appInfo.key}
                        onChange={(value: string) => {
                            appInfo.key = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <TextEditor
                        title="DB Version"
                        text={appInfo.dbVersion + ""}
                        onChange={(value: string) => {
                            let num = parseInt(value);
                            if (num && !isNaN(num)) {
                                appInfo.dbVersion = num;
                                onUpdateAppInfo(appInfo);
                            }
                        }}
                    />
                    <div style={{ paddingLeft: 20 }}>
                        <label style={{ width: 200 }}>Has State</label>
                        <Checkbox
                            checked={appInfo.hasState}
                            onChange={(e) => {
                                appInfo.hasState = e.target.checked;
                                onUpdateAppInfo(appInfo);
                            }}
                        />
                    </div>
                    <ColorManagement
                        colors={appInfo.colors}
                        onChange={(value: IAppColor) => {
                            appInfo.colors = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <div style={{ color: 'red' }}>
                        <label style={{ width: 240 }}><strong>Delete App</strong></label>
                        <IconButton onClick={onDeleteApp} color="inherit">
                            <Delete color="inherit" />
                        </IconButton>
                    </div>
                    <PlatformManagement
                        label="IOS Info"
                        info={appInfo.ios}
                        bucket={appInfo.bucket}
                        onChange={(value) => {
                            appInfo.ios = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                    <PlatformManagement
                        label="Android Info"
                        info={appInfo.android}
                        bucket={appInfo.bucket}
                        onChange={(value) => {
                            appInfo.android = value;
                            onUpdateAppInfo(appInfo);
                        }}
                    />
                </>
            )}
        </div>
    );
};

const BigqueryManagement:FC<({ 
    firebaseConfig: FirebaseConfig, 
    onChange: (value: FirebaseConfig) => void 
})> = ({ firebaseConfig, onChange }) => {
    const style = {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        paddingBottom: 8,
        paddingTop: 8,
    };
    const titleStyle = {
        width: 240,
    };
    const textInputStyle = {
        border: "1px solid #aaa",
        borderRadius: 4,
        padding: 4,
    };
    const [projectId, setProjectId] = useState(firebaseConfig.projectId);
    const [databaseId, setDataBaseId] = useState(firebaseConfig.datasetId);
    const [packageId, setPackageId] = useState(firebaseConfig.packageId);
    return (
        <div>
            <div>
                <strong>Firebase Config</strong>
            </div>
            <div style={style}>
                <div style={titleStyle}>ProjectId</div>
                <input
                    type="text"
                    style={textInputStyle}
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                    onBlur={(e) => {
                        if(e.target.value) {
                            firebaseConfig.projectId = e.target.value;
                            onChange(firebaseConfig);
                        }
                    }}
                />
            </div>
            <div style={style}>
                <div style={titleStyle}>DatasetId</div>
                <input
                    style={textInputStyle}
                    type="text"
                    value={databaseId}
                    onChange={(e) => setDataBaseId(e.target.value)}
                    onBlur={(e) => {
                        if(e.target.value) {
                            firebaseConfig.datasetId = e.target.value;
                            onChange(firebaseConfig);
                        }
                    }}
                />
            </div>
            <div style={style}>
                <div style={titleStyle}>PackageName</div>
                <input
                    style={textInputStyle}
                    type="text"
                    value={packageId}
                    onChange={(e) => setPackageId(e.target.value)}
                    onBlur={(e) => {
                        if(e.target.value) {
                            firebaseConfig.packageId = e.target.value;
                            onChange(firebaseConfig);
                        }
                    }}
                />
            </div>
        </div>
    );
}

const ColorManagement: FC<{
    colors: IAppColor;
    onChange: (value: IAppColor) => void;
}> = ({ colors, onChange }) => {
    const style = {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        paddingBottom: 8,
        paddingTop: 8,
    };
    const titleStyle = {
        width: 120,
    };
    const colorStyle = {
        width: 120,
    };
    const colorPickerStyle = {
        width: 120,
    };
    const textInputStyle = {
        border: "1px solid #aaa",
        borderRadius: 4,
        padding: 4,
    };
    const [primaryColor, setPrimaryColor] = useState(colors.primary);
    const [secondaryColor, setSecondaryColor] = useState(colors.secondary);
    const [tertiaryColor, setTertiaryColor] = useState(colors.tertiary);
    const _onChange = () => {
        onChange({
            primary: primaryColor?.trim() ?? "",
            secondary: secondaryColor?.trim() ?? "",
            tertiary: tertiaryColor?.trim() ?? "",
        });
    };
    return (
        <div>
            <div>
                <strong>Colors management</strong>
            </div>
            <div style={style}>
                <div style={titleStyle}>Primary color</div>
                <div style={colorPickerStyle}>
                    <input
                        type="color"
                        value={primaryColor}
                        onChange={(e) => setPrimaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
                <div style={colorStyle}>
                    <input
                        style={textInputStyle}
                        type="text"
                        value={primaryColor}
                        onChange={(e) => setPrimaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
            </div>
            <div style={style}>
                <div style={titleStyle}>Secondary color</div>
                <div style={colorPickerStyle}>
                    <input
                        type="color"
                        value={secondaryColor}
                        onChange={(e) => setSecondaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
                <div style={colorStyle}>
                    <input
                        style={textInputStyle}
                        type="text"
                        value={secondaryColor}
                        onChange={(e) => setSecondaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
            </div>
            <div style={style}>
                <div style={titleStyle}>Tertiary color</div>
                <div style={colorPickerStyle}>
                    <input
                        type="color"
                        value={tertiaryColor}
                        onChange={(e) => setTertiaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
                <div style={colorStyle}>
                    <input
                        style={textInputStyle}
                        type="text"
                        value={tertiaryColor}
                        onChange={(e) => setTertiaryColor(e.target.value)}
                        onBlur={_onChange}
                    />
                </div>
            </div>
        </div>
    );
};

const TextEditor: FC<{
    title: string;
    text: string;
    onChange: (value: string) => void;
}> = ({ title, text, onChange }) => {
    const [openEditor, setOpenEditor] = useState(false);
    const [value, setValue] = useState(text);
    const ref = useRef<HTMLInputElement | null>(null);
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                paddingLeft: 20,
            }}
        >
            <div style={{ width: 200 }}>{title}: </div>
            <div style={{ minWidth: 600 }}>
                <div
                    style={{
                        display: openEditor ? "none" : "block",
                        width: "100%",
                        minHeight: 30,
                    }}
                    onClick={() => {
                        setOpenEditor(true);
                        setTimeout(() => {
                            ref.current?.focus();
                        }, 100);
                    }}
                >
                    {value}
                </div>
                <input
                    autoFocus
                    ref={ref}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    style={{
                        display: !openEditor ? "none" : "block",
                        width: "100%",
                    }}
                    onBlur={(e) => {
                        setOpenEditor(false);
                        onChange(value);
                    }}
                />
            </div>
        </div>
    );
};

const PlatformManagement: FC<{
    bucket: string;
    label: string;
    info: IAppOS;
    onChange: (info: IAppOS) => void;
}> = ({ bucket, label, info, onChange }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div style={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setOpen(!open)}>
                <strong>{label}</strong>
                {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>
            {open && (
                <div className="app-details">
                    <TextEditor
                        title="Name"
                        text={info.name}
                        onChange={(value: string) => {
                            info.name = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="PackageName"
                        text={info.packageName}
                        onChange={(value: string) => {
                            info.packageName = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="AppleId"
                        text={info.appleId}
                        onChange={(value: string) => {
                            info.appleId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="TeamID"
                        text={info.teamId}
                        onChange={(value: string) => {
                            info.teamId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="Dynamic Link"
                        text={info.dynamicLink}
                        onChange={(value: string) => {
                            info.dynamicLink = value;
                            onChange(info);
                        }}
                    />
                    <div style={{ paddingLeft: 20 }}>
                        <label style={{ width: 200 }}>Has Login</label>
                        <Checkbox
                            checked={info.hasLogin}
                            onChange={(e) => {
                                info.hasLogin = e.target.checked;
                                onChange(info);
                            }}
                        />
                    </div>
                    <AdsWidget info={info} onChange={onChange} />
                    <InAppManagementParentWidget info={info} onChange={onChange} bucket={bucket} />
                    <BigqueryManagement
                        firebaseConfig={info.firebaseConfig}
                        onChange={(value) => {
                            info.firebaseConfig = value;
                            onChange(info);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const ImportInAppItemsPopup: FC<{ open: boolean; onHide: () => void; onUpdate: (json: string) => void }> = ({
    open,
    onHide,
    onUpdate,
}) => {
    const ref = useRef<HTMLTextAreaElement | null>(null);
    const [value, setValue] = useState("");
    const onImport = () => {
        if (!value) {
            toast("Value not empty!");
            return;
        }
        if (!isJsonString(value)) {
            toast("Value is not a JSON string!");
            return;
        }
        onUpdate(JSON.parse(value));
        onHide();
    };
    useEffect(() => {
        if (open) {
            setValue("");
        }
    }, [open]);

    return (
        <Modal show={open} centered onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Import In App Items</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <div>
                    <textarea
                        value={value}
                        style={{ width: "100%" }}
                        ref={ref}
                        rows={5}
                        placeholder="Enter json here"
                        onChange={(e) => setValue(e.target.value)}
                    ></textarea>
                </div>
                <div>OR</div>
                <div>
                    <input
                        type="file"
                        accept="*.json"
                        onChange={(e) => {
                            const file: File | null | undefined = e.target.files && e.target.files[0];
                            if (file) {
                                const fileReader = new FileReader();
                                fileReader.onload = function (evt) {
                                    if (evt.target?.result && typeof evt.target?.result === "string") {
                                        setValue(evt.target?.result);
                                    }
                                };
                                fileReader.readAsText(file);
                            }
                        }}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onImport} variant="contained" color="primary">
                    Import
                </Button>
                <div style={{ width: 8 }}></div>
                <Button aria-label="Close" onClick={onHide} color="secondary" variant="outlined">
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const getNewInAppId = (id: string, packageName: string, ios: boolean) => {
    let index = id.lastIndexOf(".");
    if (index > -1) {
        if (ios) {
            return `${packageName}.${id.substring(index + 1)}`;
        }
        return id.substring(index + 1);
    }
    return "";
};

const InAppManagementParentWidget: FC<{ bucket: string; info: IAppOS; onChange: (value: IAppOS) => void }> = ({
    bucket,
    info,
    onChange,
}) => {
    const [open, setOpen] = useState(false);
    const data: InAppProduct[] = info.inAppProductItems;
    const [openImportPopup, setOpenImportPopup] = useState(false);
    const onImport = () => {
        setOpenImportPopup(true);
    };
    const onExport = () => {
        const filename = `${bucket}_in_app_items.json`;
        downloadObjectAsJson(data, filename);
    };
    const onCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(data));
        toast("Copies in app data!");
    };
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setOpen(!open)}>
                    <strong>In-app Purchase</strong>
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </div>
                <div style={{ width: 12 }}></div>
                <Button color="secondary" variant="outlined" onClick={onCopy}>
                    <FileCopy />
                </Button>
                <div style={{ width: 12 }}></div>
                <Button color="secondary" variant="outlined" onClick={onExport}>
                    Export
                </Button>
                <div style={{ width: 12 }}></div>
                <Button color="secondary" variant="outlined" onClick={onImport}>
                    Import
                </Button>
            </div>
            <ImportInAppItemsPopup
                open={openImportPopup}
                onHide={() => setOpenImportPopup(false)}
                onUpdate={(value: any) => {
                    const list: InAppProduct[] = [];
                    for (let item of value) {
                        list.push(
                            new InAppProduct({
                                ...item,
                                id: getNewInAppId(item.id, info.packageName, !!info.appleId),
                            })
                        );
                    }
                    info.inAppProductItems = list;
                    onChange(info);
                }}
            />
            {open && (
                <div>
                    <div>
                        <label style={{ width: 200 }}>Use In-app Purchase</label>
                        <Checkbox
                            checked={info.inAppPurchase}
                            onChange={(e) => {
                                info.inAppPurchase = e.target.checked;
                                onChange(info);
                            }}
                        />
                    </div>
                    <InAppManagementWidget
                        info={info}
                        onChange={(value) => {
                            info.inAppProductItems = value;
                            onChange(info);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

const AdsWidget: FC<{ info: IAppOS; onChange: (value: IAppOS) => void }> = ({ info, onChange }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div style={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setOpen(!open)}>
                <strong>Ads</strong>
                {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>
            {open && (
                <div>
                    <div style={{ paddingLeft: 20 }}>
                        <label style={{ width: 200 }}>Show Ads</label>
                        <Checkbox
                            checked={info.showAds}
                            onChange={(e) => {
                                info.showAds = e.target.checked;
                                onChange(info);
                            }}
                        />
                    </div>
                    <TextEditor
                        title="AdModId"
                        text={info.adModId}
                        onChange={(value: string) => {
                            info.adModId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="NativeId"
                        text={info.nativeId}
                        onChange={(value: string) => {
                            info.nativeId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="BannerId"
                        text={info.bannerId}
                        onChange={(value: string) => {
                            info.bannerId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="InterstitialId"
                        text={info.interstitialId}
                        onChange={(value: string) => {
                            info.interstitialId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="OpenAdId"
                        text={info.openAdId}
                        onChange={(value: string) => {
                            info.openAdId = value;
                            onChange(info);
                        }}
                    />
                    <TextEditor
                        title="RewardAdId"
                        text={info.rewardAdId}
                        onChange={(value: string) => {
                            info.rewardAdId = value;
                            onChange(info);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

const InAppManagementWidget: FC<{
    info: IAppOS;
    onChange: (items: InAppProduct[]) => void;
}> = ({ info, onChange }) => {
    const rootList = info.inAppProductItems.map((item) => {
        return { ...item };
    });
    const [listInAppItem, setListInAppItem] = useState<InAppProduct[]>(rootList);
    const onDeleteItem = (item: InAppProduct) => {
        const newList = listInAppItem.filter((i) => i.id != item.id);
        setListInAppItem(newList);
    };
    const onAddItem = () => {
        setListInAppItem([
            ...listInAppItem,
            {
                description: "",
                duration: "",
                id: "",
                name: "",
                subscription: false,
                days: 0,
                price: 0,
            },
        ]);
    };
    const onUpdate = () => {
        onChange(listInAppItem);
    };

    const checkUpdateDisabled = () => {
        if (listInAppItem.length != rootList.length) {
            return false;
        }
        let count = 0;
        for (let item of listInAppItem) {
            let oldItem = rootList.find(
                (i) =>
                    i.id == item.id &&
                    i.description == item.description &&
                    i.duration == item.duration &&
                    i.name == item.name &&
                    i.subscription == item.subscription &&
                    i.days == item.days &&
                    i.price == item.price
            );
            if (oldItem) {
                count++;
            }
        }
        if (rootList.length != count) {
            return false;
        }
        return true;
    };
    const checkDisabled = checkUpdateDisabled();
    return (
        <div>
            {listInAppItem.map((item, index) => {
                return (
                    <div
                        key={"key-item-" + item.id + index}
                        style={{
                            border: "1px solid #ddd",
                            borderRadius: 5,
                            marginBottom: 8,
                            position: "relative",
                        }}
                    >
                        <Button
                            style={{
                                position: "absolute",
                                top: 16,
                                right: 16,
                                backgroundColor: "white",
                            }}
                            color="secondary"
                            variant="outlined"
                            onClick={() => onDeleteItem(item)}
                        >
                            <Delete />
                        </Button>
                        <TextEditor
                            title="Id"
                            text={item.id}
                            onChange={(value: string) => {
                                listInAppItem[index].id = value;
                                setListInAppItem([...listInAppItem]);
                            }}
                        />
                        <TextEditor
                            title="Name"
                            text={item.name}
                            onChange={(value: string) => {
                                listInAppItem[index].name = value;
                                setListInAppItem([...listInAppItem]);
                            }}
                        />
                        <TextEditor
                            title="Description"
                            text={item.description}
                            onChange={(value: string) => {
                                listInAppItem[index].description = value;
                                setListInAppItem([...listInAppItem]);
                            }}
                        />
                        <TextEditor
                            title="Duration"
                            text={item.duration}
                            onChange={(value: string) => {
                                listInAppItem[index].duration = value;
                                setListInAppItem([...listInAppItem]);
                            }}
                        />
                        <div style={{ paddingLeft: 20 }}>
                            <label style={{ width: 200 }}>Subscription</label>
                            <Checkbox
                                checked={item.subscription}
                                onChange={(e) => {
                                    listInAppItem[index].subscription = e.target.checked;
                                    setListInAppItem([...listInAppItem]);
                                }}
                            />
                        </div>
                        <TextEditor
                            title="Days"
                            text={item.days?.toString()}
                            onChange={(value: string) => {
                                let days = parseFloat(value);
                                if (days && !isNaN(days) && days > -1) {
                                    listInAppItem[index].days = days;
                                    setListInAppItem([...listInAppItem]);
                                }
                            }}
                        />
                        <TextEditor
                            title="Price"
                            text={item.price?.toString() ?? ""}
                            onChange={(value: string) => {
                                let price = parseFloat(value);
                                if (price && !isNaN(price) && price > -1) {
                                    listInAppItem[index].price = price;
                                    setListInAppItem([...listInAppItem]);
                                }
                            }}
                        />
                    </div>
                );
            })}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Button color="primary" variant="contained" disabled={checkDisabled} onClick={onUpdate}>
                    Update
                </Button>
                <Button color="primary" variant="contained" onClick={onAddItem}>
                    Add Item
                </Button>
            </div>
        </div>
    );
};

export const AppItemTitleWidget = () => {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: 20,
                fontWeight: "bold",
            }}
        >
            <div style={{ minWidth: 200 }}>ID</div>
            <div style={{ minWidth: 200 }}>Short Name</div>
            <div style={{ minWidth: 200 }}>Bucket</div>
            <div style={{ minWidth: 100 }}>Has State</div>
            <div style={{ minWidth: 100 }}>Last Update</div>
        </div>
    );
};

export default AppItemWidget;
