import Config from "../../shared/models/config";
import { ISharingAction, SharingTypes } from "../actions/sharing.action";

export interface ISharingState {
    loading: boolean;
    sharingDatas: any[];
    userDatas: any[];
}

const initState: ISharingState = {
    loading: false,
    sharingDatas: [],
    userDatas: [],
};

const sharingState = (state = initState, action: ISharingAction): ISharingState => {
    switch (action.type) {
        case SharingTypes.GET_SHARING:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case SharingTypes.GET_SHARING_SUCCESS:
            const sharingDataCopy = [...(action.sharingDatas ?? [])];

            sharingDataCopy.sort((a, b) => {
                if (a.completed !== b.completed) {
                    return a.completed ? 1 : -1;
                }
                const dateA: any = new Date(a.schedule);
                const dateB: any = new Date(b.schedule);
                return dateA - dateB;
            });
            return {
                ...state,
                ...{
                    loading: false,
                    sharingDatas: sharingDataCopy,
                },
            };

        case SharingTypes.CREATE_SHARING_SUCCESS:
            let cloneSharingData = [...state.sharingDatas];
            if (action.id) {
                cloneSharingData = cloneSharingData.map((item) => {
                    if (item._id === action.id) {
                        let test = cloneSharingData.find((test: any) => test._id === item._id);
                        if (test) {
                            return {
                                ...item,
                                title: action.sharingData.title,
                                schedule: action.sharingData.schedule,
                                summary: action.sharingData.summary,
                                completed: action.sharingData.completed,
                            };
                        }
                    }
                    return item;
                });
            } else {
                if (action.sharingData) {
                    cloneSharingData.push(action.sharingData);
                    cloneSharingData.sort((a, b) => {
                        if (a.completed !== b.completed) {
                            return a.completed ? 1 : -1;
                        }
                        const dateA: any = new Date(a.schedule);
                        const dateB: any = new Date(b.schedule);
                        return dateA - dateB;
                    });
                }
            }
            return {
                ...state,
                ...{
                    loading: false,
                    sharingDatas: cloneSharingData,
                },
            };
        case SharingTypes.DELETE_SHARING_SUCCESS:
            const cloneSharingDatas = [...state.sharingDatas];
            let index = cloneSharingDatas.findIndex((u) => u._id === action.id);
            if (index > -1) {
                cloneSharingDatas.splice(index, 1);
            }
            return {
                ...state,
                ...{
                    sharingDatas: cloneSharingDatas,
                },
            };

        case SharingTypes.GET_USER_DATA_SHARING:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case SharingTypes.GET_USER_DATA_SHARING_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    userDatas: action.userDatas ?? [],
                },
            };

        case SharingTypes.ADD_SCORE_SHARING_SUCCESS:
            let cloneUserData = [...state.userDatas];
            if (action.id) {
                cloneUserData = cloneUserData.map((item) => {
                    if (item._id === action.id) {
                        let test = cloneUserData.find((test: any) => test._id === item._id);
                        if (test) {
                            let newScore = item.sharingScore + action.userData;
                            return {
                                ...item,
                                sharingScore: newScore,
                            };
                        }
                    }
                    return item;
                });
            }
            return {
                ...state,
                ...{
                    loading: false,
                    userDatas: cloneUserData,
                },
            };

        default:
            return state;
    }
};
export default sharingState;
