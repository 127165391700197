import axios, { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { Campaign, ICampaign } from "../../shared/models/campaign";
import Config from "../../shared/models/config";
import Url from "../../util/url";
import {
    CampaignTypes,
    ICamPaignAction,
    deleteCampaignFailed,
    deleteCampaignSuccess,
    getAllCampaignFailed,
    getAllCampaignSuccess,
    updateCampaignFailed,
    updateCampaignSuccess,
} from "../actions/campaign.action";
import { IUserInfo } from "../../shared/models/userInfo";
import { converDataCampaign } from "../../util";
import { getTasksByIds } from "../actions/task.action";

async function getAllCampaignAPI(userId: string): Promise<ICampaign[] | null> {
    return await axios.get(Url("campaign/getAll/" + userId)).then((data: any) => {
        if (data?.data?.success && data?.data?.response) {
            // console.log("data.data.response", data.data.response)
            return data.data.response.map((item: any) => {
                return new Campaign(item);
            });
        }
        return null;
    });
}

function* getAllCampaignSaga() {
    try {
        const user: IUserInfo = yield select((state: any) => state.authState.user);
        const campaigns: ICampaign[] | null = yield call(getAllCampaignAPI, user._id);
        if (campaigns) {
            yield put(getAllCampaignSuccess(campaigns));
        } else {
            yield put(getAllCampaignFailed("getAllCampaignSaga status undefined!"));
        }
    } catch (err) {
        yield put(getAllCampaignFailed("getAllCampaignSaga ERROR: " + err));
    }
}

async function updateCampaignAPI(campaign: ICampaign): Promise<ICampaign | null> {
    const item = new Campaign(campaign);
    return await axios.put(Url("campaign/update-campaign"), item.toJson()).then((data: AxiosResponse<any>) => {
        if (data.status == 200 && data?.data?.success) {
            return item;
        }
        return null;
    });
}

function* updateCampaignSaga(action: ICamPaignAction) {
    if (action.campaigns) {
        let campaigns = action.campaigns;
        let updateTasks = action.updateTasks;
        let users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos ?? []);
        let mapUsers: { [userId: string]: IUserInfo } = {};
        for (let user of users) {
            mapUsers[user._id] = user;
        }
        try {
            let result: ICampaign[] = [];
            for (let campaign of campaigns) {
                if (campaign._id) {
                    let _res: null | ICampaign = yield call(updateCampaignAPI, campaign);
                    if (_res) {
                        result.push({
                            ...campaign,
                            reporter: mapUsers[campaign.reporterId],
                        });
                    }
                } else {
                    result.push(yield call(createCampaignAPI, campaign));
                }
            }
            if (updateTasks) {
                let { listTaskIds, campaignObj } = converDataCampaign(campaigns);
                yield put(getTasksByIds(listTaskIds, campaignObj));
            }
            yield put(updateCampaignSuccess(result));
        } catch (err) {
            window.alert("updateCampaignSaga ERROR: " + err);
            yield put(getAllCampaignFailed("updateCampaignSaga ERROR: " + err));
        }
    } else {
        window.alert("updateCampaignSaga campaign empty");
        yield put(updateCampaignFailed("updateCampaignSaga campaign empty"));
    }
}

async function createCampaignAPI(campaign: ICampaign): Promise<ICampaign | null> {
    return await axios
        .post(Url("campaign/create-campaign"), new Campaign(campaign).toJson())
        .then((data: AxiosResponse<any>) => {
            if (data.status == 200 && data?.data?.success && data?.data?.response && data?.data?.response[0]) {
                return new Campaign(data?.data?.response[0]);
            }
            return null;
        });
}

async function deleteCampaignAPI(campaign: ICampaign): Promise<string> {
    return await axios.delete(Url("campaign/deletes-campaign/" + campaign._id)).then((data: AxiosResponse<any>) => {
        if (data?.data?.success) {
            return Config.HTTP_RESULT_OK;
        }
        return data.status + "";
    });
}

function* deleteCampaignSaga(action: ICamPaignAction) {
    if (action.campaigns && action.campaigns.length) {
        let campaign = action.campaigns[0];
        try {
            const result: string = yield call(deleteCampaignAPI, campaign);
            if (result === Config.HTTP_RESULT_OK) {
                yield put(deleteCampaignSuccess([campaign]));
            } else {
                yield put(deleteCampaignFailed("deleteCampaignSaga status " + result));
            }
        } catch (err) {
            window.alert("deleteCampaignSaga ERROR: " + err);
            yield put(deleteCampaignFailed("deleteCampaignSaga ERROR: " + err));
        }
    } else {
        yield put(deleteCampaignFailed("deleteCampaignSaga campaign empty"));
    }
}

export function* watchCampaign() {
    yield takeLatest(CampaignTypes.GET_ALL_CAMPAIGN, getAllCampaignSaga);
    yield takeLatest(CampaignTypes.UPDATE_CAMPAIGN, updateCampaignSaga);
    yield takeLatest(CampaignTypes.DELETE_CAMPAIGN, deleteCampaignSaga);
}
