import React, { useState } from "react";
import EventModal from "../user/event-modal";
import moment from "moment";

// style
import "./style.scss";

function CareerEvent({ userId, events }: any) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") ?? "");
    const { role } = userInfo;
    const [show, setShow] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const sortedEvent = events?.sort((a: any, b: any) => {
        const numberDateA = new Date(a.date).getTime();
        const numberDateB = new Date(b.date).getTime();
        return numberDateB - numberDateA;
    });

    const handleShowModal = () => {
        setShow(!show);
    };
    const handleSelectEvent = (event: any) => {
        if (role === 1) {
            setCurrentData(event);
            handleShowModal();
        }
    };

    return (
        <div id="scroll-to-event" className="career-event-container">
            <EventModal
                showModal={show}
                handleShowModal={handleShowModal}
                externalData={currentData}
            />
            <div className="title">Lộ trình công danh</div>
            {sortedEvent.length !== 0 && userId ? (
                <>
                    {sortedEvent.map((event: any) => (
                        <div
                            onClick={() => handleSelectEvent(event)}
                            className="event-container"
                        >
                            <div className="event-date">
                                {moment(event.date).format("DD-MM-YYYY")}
                            </div>
                            <div className="event-detail">{event.event}</div>
                        </div>
                    ))}
                </>
            ) : (
                <div className="no-event">Không có sự kiện nào</div>
            )}
        </div>
    );
}

export default CareerEvent;
