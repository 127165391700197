import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";

const UserCoefficientDialog = ({
    open,
    onClose,
    userInfo,
    onUpdate,
}: {
    open: boolean;
    onClose: any;
    userInfo: IUserInfo;
    onUpdate: any;
}) => {
    const [standarExp, setStandarExp] = useState(userInfo.standarExpPerLevel);
    const [cWork, setcWork] = useState(userInfo.cWork);
    const [cDiscipline, setcDiscipline] = useState(userInfo.cDiscipline);
    const [cActive, setcActive] = useState(userInfo.cActive);
    const [cCreative, setcCreatve] = useState(userInfo.cCreative);
    const [cLeadership, setcLeadership] = useState(userInfo.cLeadership);
    const [cQuality, setcQuality] = useState(userInfo.cQuality);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title4"
            aria-describedby="alert-dialog-description4"
        >
            <DialogTitle id="alert-dialog-title4">Cài đặt hệ số cho {userInfo.firstName + " " + userInfo.lastName}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Định mức cho level"
                    variant="standard"
                    value={standarExp}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setStandarExp(v);
                    }}
                    sx={{ marginTop: "20px" }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">exp</InputAdornment>,
                    }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số công việc [a]"
                    variant="standard"
                    value={cWork}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcWork(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số kỷ luật [b]"
                    variant="standard"
                    value={cDiscipline}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcDiscipline(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số sáng tạo [c]"
                    variant="standard"
                    value={cCreative}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcCreatve(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số tích cực [d]"
                    variant="standard"
                    value={cActive}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcActive(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số lãnh đạo [e]"
                    variant="standard"
                    value={cLeadership}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcLeadership(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    // id="done-task-percentage"
                    label="Hệ số chất lượng [f]"
                    variant="standard"
                    value={cQuality}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (isNaN(v)) v = 0;
                        setcQuality(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onUpdate(
                            new UserInfo({
                                ...userInfo,
                                standarExpPerLevel: standarExp,
                                cWork,
                                cDiscipline,
                                cCreative,
                                cLeadership,
                                cActive,
                                cQuality,
                            })
                        );
                        setTimeout(() => {
                            onClose();
                        }, 500);
                    }}
                    autoFocus
                >
                    Lưu
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserCoefficientDialog;
