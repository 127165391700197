import { AppInfo, IAppInfo } from "./appinfo.model";

export interface ICertificates {
  id: number;
  appID: number;
  name: string;
  slug: string;
  parent: string;
  bigqueryTable: string;
  passPercent: number;
  duration: number;
  realTestNbQuestions: number;
  nbQuestions: number;
  appIDs: string;
  recentNPSScore: number;
  recentReportMistakeScore: number;
  recentReviewScore: number;
  currentScore: number;
  logo: string;

  appInfos?: IAppInfo[];

  // descriptionSEO: string;
  // keywordSEO: string;
  // title: string;
  // description: string;

  // usingFeaturePro: boolean;
  // usingMathJax: boolean;
  // parentId: number;
  // stateName?: string;
  // ip?: string;
}
export class Certificates implements ICertificates {
  id: number;
  appID: number;
  name: string;
  slug: string;
  parent: string;
  bigqueryTable: string;
  passPercent: number;
  duration: number;
  realTestNbQuestions: number;
  nbQuestions: number;
  appIDs: string;
  recentNPSScore: number;
  recentReportMistakeScore: number;
  recentReviewScore: number;
  currentScore: number;
  logo: string;

  appInfos?: IAppInfo[];

  constructor(object: any = {}) {
    this.id = object.id;
    this.appID = object.appID ?? parseInt(object.appID + "");
    this.name = object.name ?? "";
    this.slug = object.slug ?? "";
    this.parent = object.parent ?? "";
    this.bigqueryTable = object.appShortName ?? "";
    this.passPercent = object.packageNameIOS ?? 0;
    this.duration = object.duration ?? 0;
    this.realTestNbQuestions = object.realTestNbQuestions ?? 0;
    this.nbQuestions = object.nbQuestions ?? 0;
    this.appIDs = object.appIDs ?? "";
    this.recentNPSScore = object.recentNPSScore ?? 0;
    this.recentReportMistakeScore = object.recentReportMistakeScore ?? 0;
    this.recentReviewScore = object.recentReviewScore ?? 0;
    this.currentScore = object.currentScore ?? 0;
    this.logo = object.logo ?? "";
    if (object.appInfos) {
      this.appInfos = object.appInfos.map((app: any) => new AppInfo(app));
      if (this.appInfos?.length) {
        for (let i = 0; i < this.appInfos.length; i++) {
          if (this.appInfos[i].logo) {
            this.logo = this.appInfos[i].logo;
          }
        }
      }
    }
  }
}
