import { useState, useEffect } from "react";
import axios from "axios";
import Constants from "../../../../constant";
import "./index.scss";
const MessengerFeedbacks = ({ testID }) => {
    const [feedbacks, setFeedbacks] = useState([]);

    useEffect(() => {
        fetchFeedbacks();
    }, [testID]);

    const fetchFeedbacks = async () => {
        const res = await axios.get(
            Constants.API_DASHBOARD_URL +
                "messenger-feedbacks?test=" +
                testID +
                "&category=content"
        );
        setFeedbacks(res.data);
    };

    return (
        <div className="main-feedback">
            <div className="fb-messenger-feedbacks">
                {feedbacks.map((feedback, index) => (
                    <div className="feedback" key={index}>
                        <a href={feedback.url} target="_blank">
                            {feedback.summary}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MessengerFeedbacks;
