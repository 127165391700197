import { ISprint } from "../../shared/models/sprint";
import { ISprintReport } from "../../shared/models/sprintReport";
import { IRewardMinusExpRaw } from "../reducers/rewardMinusExp.reducer";

export enum SprintReportTypes {
    UPDATE_REPORT = "UPDATE_REPORT",
    UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS",
    UPDATE_REPORT_FAILED = "UPDATE_REPORT_FAILED",
    UPDATE_CHECKPOINT_TASK_IN_SPRINT = "UPDATE_CHECKPOINT_TASK_IN_SPRINT",

    GET_REWARD_MINUS_EXP_ACTION_SUCCESS = "GET_REWARD_MINUS_EXP_ACTION_SUCCESS",
    GET_REWARD_MINUS_EXP_ACTION = "GET_REWARD_MINUS_EXP_ACTION",
    ADD_REWARD_MINUS_EXP_ACTION_SUCCESS = "ADD_REWARD_MINUS_EXP_ACTION_SUCCESS",
    GET_SPRINT_REPORT_V2_DATA = "GET_SPRINT_REPORT_V2_DATA",
    GET_SPRINT_REPORT_V2_DATA_SUCCESS = "GET_SPRINT_REPORT_V2_DATA_SUCCESS",
    GET_SPRINT_REPORT_V2_DATA_FAILED = "GET_SPRINT_REPORT_V2_DATA_FAILED",
    SEND_MAIL_TO_MEMBER_TEAM_DEV = "SEND_MAIL_TO_MEMBER_TEAM_DEV",
}

export interface ISprintReportAction {
    type: SprintReportTypes;
    sprintReports?: ISprintReport[];
    error?: any;
    sprint?: ISprint;
    rewardMinusExp?: IRewardMinusExpRaw[];
    userIds?: string[];
    endSprint?: boolean;
    newSprintReports?: any;
    taskId?: string;
    userId?: string;
    currentSprint?: string;
    checkPoint?: boolean;
}

export const updateSprintReport = (sprint: ISprint, endSprint?: boolean): ISprintReportAction => {
    return {
        type: SprintReportTypes.UPDATE_REPORT,
        sprint,
        endSprint,
    };
};

export const sendMailToMemberTeamDEVAction = ({ sprint, userId }: { sprint: ISprint; userId: string }): ISprintReportAction => {
    return {
        type: SprintReportTypes.SEND_MAIL_TO_MEMBER_TEAM_DEV,
        sprint,
        userId,
    };
};

export const updateSprintReportSuccess = (sprintReports: ISprintReport[]): ISprintReportAction => {
    return {
        type: SprintReportTypes.UPDATE_REPORT_SUCCESS,
        sprintReports,
    };
};

export const updateCheckPointTaskInSprintAction = ({
    taskId,
    userId,
    currentSprint,
    checkPoint,
}: {
    taskId: string;
    userId: string;
    currentSprint: string;
    checkPoint: boolean;
}): ISprintReportAction => {
    return {
        type: SprintReportTypes.UPDATE_CHECKPOINT_TASK_IN_SPRINT,
        taskId,
        userId,
        currentSprint,
        checkPoint,
    };
};

export const updateSprintReportFailed = (error: any): ISprintReportAction => {
    return {
        type: SprintReportTypes.UPDATE_REPORT_FAILED,
        error,
    };
};

export const getRewardMinusExpSuccess = (rewardMinusExp: IRewardMinusExpRaw[], userIds: string[]): ISprintReportAction => {
    return {
        type: SprintReportTypes.GET_REWARD_MINUS_EXP_ACTION_SUCCESS,
        rewardMinusExp,
        userIds,
    };
};

export const getRewardMinusExp = (userIds: string[]): ISprintReportAction => {
    return {
        type: SprintReportTypes.GET_REWARD_MINUS_EXP_ACTION,
        userIds,
    };
};

export const addRewardMinusExpSuccess = (rewardMinusExp: IRewardMinusExpRaw[]): ISprintReportAction => {
    return {
        type: SprintReportTypes.ADD_REWARD_MINUS_EXP_ACTION_SUCCESS,
        rewardMinusExp,
    };
};
/**
 * @param sprint optional, if has, api return this sprint's data
 * @_ if auth user have roleForPoint, return only user's data, else return all user's data with roleForPoint
 * @returns
 */
export const getSprintReportV2Data = (sprint?: ISprint, userIds?: string[]): ISprintReportAction => {
    return {
        type: SprintReportTypes.GET_SPRINT_REPORT_V2_DATA,
        sprint,
        userIds,
    };
};

export const getSprintExpDataSuccess = (newSprintReports: { [userId: string]: any }): ISprintReportAction => {
    return {
        type: SprintReportTypes.GET_SPRINT_REPORT_V2_DATA_SUCCESS,
        newSprintReports,
    };
};

export const getSprintExpDataFailed = (err: any): ISprintReportAction => {
    return {
        type: SprintReportTypes.GET_SPRINT_REPORT_V2_DATA_FAILED,
        error: err,
    };
};
