import { IAppChannel } from "../../shared/models/appChannel";

export enum AppChannelType {
    GET_APP_CHANNEL = "GET_APP_CHANNEL",
    GET_APP_CHANNEL_SUCCESS = "GET_APP_CHANNEL_SUCCESS",
    GET_APP_CHANNEL_FAILED = "GET_APP_CHANNEL_FAILED",
    UPDATE_APP_CHANNEL = "UPDATE_APP_CHANNEL",
    UPDATE_APP_CHANNEL_SUCCESS = "UPDATE_APP_CHANNEL_SUCCESS",
    UPDATE_APP_CHANNEL_FAILED = "UPDATE_APP_CHANNEL_FAILED",
}

export interface AppChannelAction {
    type: AppChannelType;
    updateAppChannels?: IAppChannel[];
    updateAppChannel?: IAppChannel;
    error?: any;
    offset?: number;
    limit?: number;
    rateFilter?: number;
    platformFilter?: string;
    typeFilter?: string;
    dateStart?: string;
    dateEnd?: string;
    appId?: string;
}
export const getAppChannelAction = (
    offset?: number,
    limit?: number,
    rateFilter?: number,
    platformFilter?: string,
    typeFilter?: string,
    dateStart?: string,
    dateEnd?: string,
    appId?: string
): AppChannelAction => {
    return {
        type: AppChannelType.GET_APP_CHANNEL,
        offset,
        limit,
        rateFilter,
        platformFilter,
        typeFilter,
        dateStart,
        dateEnd,
        appId,
    };
};
export const getAppChannelSuccess = (
    updateAppChannels: IAppChannel[]
): AppChannelAction => {
    return {
        type: AppChannelType.GET_APP_CHANNEL_SUCCESS,
        updateAppChannels,
    };
};
export const getAppChannelFailed = (error: any): AppChannelAction => {
    return {
        type: AppChannelType.GET_APP_CHANNEL_FAILED,
        error,
    };
};
export const updateAppChannelAction = (
    updateAppChannel: IAppChannel
): AppChannelAction => {
    return {
        type: AppChannelType.UPDATE_APP_CHANNEL,
        updateAppChannel,
    };
};
export const updateAppChannelSuccess = (
    updateAppChannel: IAppChannel
): AppChannelAction => {
    return {
        type: AppChannelType.UPDATE_APP_CHANNEL_SUCCESS,
        updateAppChannel,
    };
};
export const updateAppChannelFailed = (error: any): AppChannelAction => {
    return {
        type: AppChannelType.UPDATE_APP_CHANNEL_FAILED,
        error,
    };
};
