import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { AppTheme, IAppTheme } from "../../shared/models/appTheme";
import Url from "../../util/url";
import { AppThemeTypes, IAppThemeAction, IAppThemeRootAction, getAppThemeFailed, getAppThemeRootFailed, getAppThemeRootSuccess, getAppThemeSuccess } from "../actions/appTheme.action";

async function getAppThemeAPI(appId: string, root: boolean): Promise<IAppTheme> {
    return await axios.get(Url("app/get-app-theme?id=" + appId + "&root=" + root)).then((data) => {
        if (data.status == 200 && data.data) {
            return new AppTheme(data.data);
        }
        return new AppTheme({ root: root });
    });
}

async function updateAppThemeAPI(appTheme: IAppTheme): Promise<IAppTheme | null> {
    return await axios.post(Url("app/update-app-theme"), { appTheme }).then((data) => {
        if (data.status == 200 && data.data) {
            return new AppTheme(data.data);
        }
        return null;
    });
}

function* getAppThemeSaga(action: IAppThemeAction) {
    if(action.appId) {
        try {
            const appTheme: IAppTheme = yield call(getAppThemeAPI, action.appId, false);
            yield put(getAppThemeSuccess(appTheme));
        } catch (err) {
            yield put(getAppThemeFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(getAppThemeFailed("appId not found"));
    }
}

function* updateAppThemeSaga(action: IAppThemeAction) {
    console.log(action.appTheme)
    if(action.appId && action.appTheme) {
        try {
            action.appTheme.appInfoId = action.appId;
            const appTheme: IAppTheme | null = yield call(updateAppThemeAPI, action.appTheme);
            if(appTheme) {
                if(appTheme.root) {
                    yield put(getAppThemeRootSuccess(appTheme));
                } else {
                    yield put(getAppThemeSuccess(appTheme));
                }
            }
        } catch (err) {
            yield put(getAppThemeFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(getAppThemeFailed("appId not found"));
    }
}

function* getAppThemeRootSaga(action: IAppThemeRootAction) {
    if(action.appId) {
        try {
            const appTheme: IAppTheme = yield call(getAppThemeAPI, action.appId, true);
            yield put(getAppThemeRootSuccess(appTheme));
        } catch (err) {
            yield put(getAppThemeRootFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(getAppThemeRootFailed("appId not found"));
    }
}

export function* watchAppTheme() {
    yield takeLatest(AppThemeTypes.GET_APP_THEME, getAppThemeSaga);
    yield takeLatest(AppThemeTypes.UPDATE_APP_THEME, updateAppThemeSaga);
    yield takeLatest(AppThemeTypes.GET_APP_THEME_ROOT, getAppThemeRootSaga);
}
