import Config from "../../shared/models/config";
import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import { IWorkingTime } from "../../shared/models/workingTime";
import { IWorkingTimeAction, WorkingTimeTypes } from "../actions/workingTime.action";

export interface IOffDaysWfhDaysState {
    offDaysWfhDays: IOffDaysWfhDays[];
    workingTime: IWorkingTime[];
    loading: boolean;
    error: any;
}
const initState: IOffDaysWfhDaysState = {
    offDaysWfhDays: [],
    workingTime: [],
    loading: false,
    error: null,
};
let convertSlot = {
    [Config.ALL_DAY]: Config.ALL_DAY_VNESE,
    [Config.MORNING]: Config.MORNING_VNESE,
    [Config.AFTERNOON]: Config.AFTERNOON_VNESE,
};
let convertSlotNb = {
    [Config.ALL_DAY]: 1,
    [Config.ALL_DAY_VNESE]: 1,
    [Config.MORNING]: 0.5,
    [Config.MORNING_VNESE]: 0.5,
    [Config.AFTERNOON]: 0.5,
    [Config.AFTERNOON_VNESE]: 0.5,
};

const workingTimes = (state = initState, action: IWorkingTimeAction) => {
    switch (action.type) {
        case WorkingTimeTypes.GET_WORKING_TIME:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case WorkingTimeTypes.GET_WORKING_TIME_SUCCESS:
            return {
                ...state,
                offDaysWfhDays: action.offDaysWfhDays,
                workingTime: action.workingTime, // du lieu da duoc chuan hoa sau khi goi api
                loading: false,
                error: false,
            };
        case WorkingTimeTypes.GET_WORKING_TIME_FAILED:
            return { ...state, loading: false, error: true };
        case WorkingTimeTypes.UPDATE_WORKING_TIME_SUCCESS:
            let oldWT = state.workingTime;
            let newWT = action.workingTime;
            newWT?.forEach((item) => {
                let flag = true;
                for (let i = 0; i < oldWT.length; i++) {
                    if (oldWT[i].date === item.date && oldWT[i].userId === item.userId) {
                        oldWT[i].checkin = item.checkin;
                        oldWT[i].checkout = item.checkout;
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    oldWT.push(item);
                }
            });
            state.workingTime = oldWT;
            return {
                ...state,
            };
        case WorkingTimeTypes.GET_ALL_TIME_ATTENDANCE_SUCCESS:
            return {
                ...state,
                workingTime: action.workingTime,
                loading: false,
            };
        case WorkingTimeTypes.ADD_DAYOFF_SUCCESS:
            let newOffDaysWfhDays = state.offDaysWfhDays;
            let dayoff = action.dayoffs;
            let typeAdd = action.typeData;
            if (typeAdd == Config.TYPE.offday) {
                newOffDaysWfhDays = newOffDaysWfhDays.map((item) => {
                    if (item.userId === dayoff?.userId) {
                        let dayOffs = item.offDays;
                        let exist = false;
                        let newNb = dayoff.slot;
                        dayOffs = dayOffs.map((day) => {
                            if (day._id === dayoff?._id) {
                                exist = true;
                                let oldNb = day.slot;
                                item.numberOffDay = item.numberOffDay - convertSlotNb[oldNb] + convertSlotNb[newNb];
                                return {
                                    ...dayoff,
                                    slot: dayoff?.slot ? convertSlot[dayoff?.slot] : Config.ALL_DAY_VNESE,
                                    reason: dayoff?.reason ?? day.reason,
                                };
                            }
                            return day;
                        });
                        if (!exist) {
                            dayoff = {
                                ...dayoff,
                                slot: dayoff?.slot ? convertSlot[dayoff?.slot] : Config.ALL_DAY_VNESE,
                            };
                            dayOffs.push(dayoff);
                            dayOffs.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                            item.numberOffDay = item.numberOffDay + convertSlotNb[newNb];
                        }
                        item.offDays = dayOffs;
                    }
                    return item;
                });
            } else if (typeAdd == Config.TYPE.wfhday) {
                newOffDaysWfhDays = newOffDaysWfhDays.map((item) => {
                    if (item.userId === dayoff?.userId) {
                        let wfhDays = item.wfhDays;
                        let exist = false;
                        let newNb = dayoff.slot;
                        wfhDays = wfhDays.map((day) => {
                            if (day._id === dayoff?._id) {
                                exist = true;
                                let oldNb = day.slot;
                                item.numberWfhDay = item.numberWfhDay - convertSlotNb[oldNb] + convertSlotNb[newNb];
                                return {
                                    ...dayoff,
                                    slot: dayoff?.slot ? convertSlot[dayoff?.slot] : Config.ALL_DAY_VNESE,
                                    reason: dayoff?.reason ?? day.reason,
                                };
                            }
                            return day;
                        });
                        if (!exist) {
                            dayoff = {
                                ...dayoff,
                                slot: dayoff?.slot ? convertSlot[dayoff?.slot] : Config.ALL_DAY_VNESE,
                            };
                            wfhDays.push(dayoff);
                            wfhDays.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                            item.numberWfhDay = item.numberWfhDay + convertSlotNb[newNb];
                        }
                        item.wfhDays = wfhDays;
                    }
                    return item;
                });
            }
            return {
                ...state,
                offDaysWfhDays: newOffDaysWfhDays,
            };
        case WorkingTimeTypes.DELETE_DAYOFF_SUCCESS:
            let deletedList = action.deletedList;
            let newData = state.offDaysWfhDays;
            let typeDelete = action.typeData;
            if (typeDelete == Config.TYPE.offday) {
                newData = newData.map((data) => {
                    let offDays = data.offDays;
                    let numberOffDay = data.numberOffDay;
                    offDays = offDays.filter((d) => {
                        if (!deletedList?.includes(d._id)) return true;
                        else {
                            numberOffDay -= convertSlotNb[d.slot];
                            return false;
                        }
                    });
                    data.numberOffDay = numberOffDay;
                    data.offDays = offDays;
                    return data;
                });
            } else if (typeDelete == Config.TYPE.wfhday) {
                newData = newData.map((data) => {
                    let wfhDays = data.wfhDays;
                    let numberWfhDay = data.numberWfhDay;
                    wfhDays = wfhDays.filter((d) => {
                        if (!deletedList?.includes(d._id)) return true;
                        else {
                            numberWfhDay -= convertSlotNb[d.slot];
                            return false;
                        }
                    });
                    data.numberWfhDay = numberWfhDay;
                    data.wfhDays = wfhDays;
                    return data;
                });
            }
            return {
                ...state,
                offDaysWfhDays: newData,
            };
        default:
            return state;
    }
};

export default workingTimes;
