import { FC, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import icon1 from "../../../assets/icon/icon1.png";
import icon2 from "../../../assets/icon/icon2.png";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import { getRatingAPI } from "../../../redux/sagas/userProgress.saga";
import { ISprintReport } from "../../../shared/models/sprintReport";
import { UserProgress } from "../../../shared/models/userProgress";
import { roleForPoint } from "../../../shared/utils";
import { getColoWithPercent } from "../../../utils";
import LoadingWidget from "../../LoadingWidget";
import DisciplineWidget, { DetailDiscipline } from "./DisciplineWidget";
import RatingItem from "./RatingItem";

const ShowUserDetails: FC<{
    item: ISprintReport;
    detailDiscipline: DetailDiscipline;
    onHide: () => void;
}> = ({ item, onHide, detailDiscipline }) => {
    const avatar = item.user?.avatar ?? defaultAvatar;
    const [userProgress, setUserProgress] = useState<UserProgress[] | null>(null);
    useEffect(() => {
        getRatingAPI(item.userId, item.sprint).then((data) => {
            setUserProgress(data);
        });
    }, [item]);
    return (
        <Modal show={true} size="xl" centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={avatar} width={35} height={35} style={{ borderRadius: 35 }} />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>{item.user?.firstName + " " + item.user?.lastName}</span>
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                {!userProgress ? (
                    <LoadingWidget />
                ) : (
                    <div className="user-progress-content">
                        <RatingItem
                            title="Chất lượng công việc (số bugs trên tổng số task)"
                            color={getColoWithPercent(1 - item.bugTasks.length / item.totalTasks.length)}
                            value={item.bugTasks.length}
                            total={item.totalTasks.length}
                            icon={icon1}
                        />
                        <RatingItem
                            title="Tỷ lệ hoàn thành (số task hoàn thành)"
                            color={getColoWithPercent(item.finishedTasks.length / item.totalTasks.length)}
                            value={item.finishedTasks.length}
                            total={item.totalTasks.length}
                            icon={icon2}
                        />
                        {roleForPoint(item.user) && <DisciplineWidget detailDiscipline={detailDiscipline} />}
                        {/* {userProgress && (
                            <CreativeWidget userProgress={userProgress} />
                        )}
                        {userProgress && (
                            <LeadershipWidget userProgress={userProgress} />
                        )}
                        {userProgress && (
                            <TeamworkWidget userProgress={userProgress} />
                        )} */}
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ShowUserDetails;
