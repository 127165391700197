import { ICurriculumVitae } from "../../shared/models/curriculumVitae";

export enum CurriculumVitaeTypes{
    CREATE_CURRICULUMVITAE = "CREATE_CURRICULUMVITAE",
}

export interface CurriculumVitaeAction {
    type : CurriculumVitaeTypes,
    CurriculumVitaeInfo : ICurriculumVitae,
    error? : any,
}

export const createcCurriculumVitae = (CurriculumVitaeInfo: ICurriculumVitae) : CurriculumVitaeAction => {
    return {
        type: CurriculumVitaeTypes.CREATE_CURRICULUMVITAE,
        CurriculumVitaeInfo
    }
}