export enum CyclesTypes {
    GET_CYCLES = "GET_CYCLES",
    GET_CYCLES_SUCCESS = "GET_CYCLES_SUCCESS",
    GET_CYCLES_FAILED = "GET_CYCLES_FAILED",
    ADD_CYCLE = "ADD_CYCLE",
    UPDATE_CYCLE = "UPDATE_CYCLE",
    UPDATE_CYCLES = "UPDATE_CYCLES",
    DELETE_CYCLE = "DELETE_CYCLE",
}

export interface ICyclesAction {
    type: CyclesTypes;
    error?: any;
    data?: any;
}

export const getCycles = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.GET_CYCLES,
        data,
    };
};

export const getCyclesSuccess = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.GET_CYCLES_SUCCESS,
        data,
    };
};

export const getCyclesFailed = (error: any): ICyclesAction => {
    return {
        type: CyclesTypes.GET_CYCLES_FAILED,
        error,
    };
};

export const addCycle = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.ADD_CYCLE,
        data,
    };
};

export const updateCycle = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.UPDATE_CYCLE,
        data,
    };
};

export const updateCycles = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.UPDATE_CYCLES,
        data,
    };
};

export const deleteCycle = (data: any): ICyclesAction => {
    return {
        type: CyclesTypes.DELETE_CYCLE,
        data,
    };
};
