import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore, //only createStore have crash on some devices
} from "redux";
import createSagaMiddleware from "redux-saga";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/scss/style.scss";
import App from "./App";

import reducers from "./redux/reducers/index";
import rootSaga from "./redux/sagas";
import reportWebVitals from "./reportWebVitals";

const composeEnhancers =
    (process.env.NODE_ENV === "development"
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null) || compose; ////////////////////////

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
