import { ITestSectionAction, TestSectionTypes } from "../actions/testSection.action";

export interface ITestSectionState {
    loading: boolean
	data?: any
	error?: any
}

const initState: ITestSectionState = {
    loading: false,
    error: false,
    data: [],
};

const testSectionReducer = (state = initState, action: ITestSectionAction) => {
    switch (action.type){
        case TestSectionTypes.GET_ALL_TEST_SECTION:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case TestSectionTypes.GET_ALL_TEST_SECTION_SUCCESS: 
            return {
                data: action.data,
                loading: false,
                error: false,
            }
        case TestSectionTypes.GET_ALL_TEST_SECTION_FAILED:
            return {
                loading: false,
                error: true,
            }
        case TestSectionTypes.ADD_TEST_SECTION: 
            return {
                ...state,
                data: [...state.data, action.data]
            }
        case TestSectionTypes.UPDATE_TEST_SECTIONS:
            let tempArr = [...state.data];
            tempArr = tempArr.map(item => {
                const testSection = action.data.find((md: any) => md._id === item._id);
                if(testSection) {
                    return testSection;
                }

                return item;
            })
            return {
                ...state,
                data: tempArr
            }

        case TestSectionTypes.DELETE_TEST_SECTION: 
            let tempArr1 = state.data.filter((item: any) => action.data._id !== item._id);

            return {
                ...state,
                data: tempArr1
            }

        default:
            return state;
    }
}

export default testSectionReducer;