import { AppTheme, IAppTheme } from "../../shared/models/appTheme";
import { AppThemeTypes, IAppThemeRootAction } from "../actions/appTheme.action";

export interface IAppThemeRootState {
    loading: boolean;
    appThemeRoot: IAppTheme;
    error?: any;
}

const initState: IAppThemeRootState = {
    loading: false,
    appThemeRoot: new AppTheme({ root: true })
};

const appThemeRootState = (state = initState, action: IAppThemeRootAction): IAppThemeRootState => {
    switch (action.type) {
        case AppThemeTypes.GET_APP_THEME_ROOT:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case AppThemeTypes.GET_APP_THEME_ROOT_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    appThemeRoot: action.appThemeRoot ?? new AppTheme({ root: true })
                },
            };
        case AppThemeTypes.GET_APP_THEME_ROOT_FAILED:
            return {
                ...state,
                ...{
                    error: action.error
                }
            };
        default:
            return state;
    }
};

export default appThemeRootState;
