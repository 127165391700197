import React, {
    CSSProperties,
    ChangeEvent,
    FC,
    useEffect,
    useState,
} from "react";
import "./index.scss";
import axios from "axios";
import Url from "../../util/url";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Form, FormControl, Modal, ModalFooter } from "react-bootstrap";
import moment from "moment";
import { Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Config from "../../shared/models/config";
import SearchAndSelectUsers from "../user-group/add-update-user-group-modal/SearchAndSelectUser";
import { useDispatch, useSelector } from "react-redux";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MButton from "react-bootstrap/Button";
import HistoryIcon from "@mui/icons-material/History";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    getAppChannelAction,
    updateAppChannelAction,
} from "../../redux/actions/appChannel.action";
import {
    AppChannel,
    AppChannelHistory,
    IAppChannel,
    IAppChannelHistory,
} from "../../shared/models/appChannel";
import { IUserInfo } from "../../shared/models/userInfo";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { convertUserOptions } from "../../utils";
const limit = 20;

const ShowInfoRating = () => {
    const [offset, setOffset] = useState<number>(0);
    const [dataType, setDataType] = useState<any>(null);
    const [dataTotal, setDataTotal] = useState<any>(null);
    const [rateFilter, setRateFilter] = useState<number>(0);
    const [platformFilter, setPlatformFilter] = useState<string>("");
    const [typeFilter, setTypeFilter] = useState<string>("");
    const [dateStart, setDateStart] = useState(new Date("2021-05-04"));
    const [dateEnd, setDateEnd] = useState(new Date());
    const [appId, setAppId] = useState<string>("");
    const dispatch = useDispatch();
    const [dataItem, setDataItem] = useState<AppChannel>();
    const [dataHistories, setDataHistories] = useState<AppChannel>();
    const dataAppChannel: IAppChannel[] = useSelector(
        (state: any) => state.appChannelState.updateAppChannels
    );
    useEffect(() => {
        loadTotalData();
        dispatch(getAllUserInfo());
    }, []);

    const loadTotalData = async () => {
        try {
            const res_count = await axios.get(
                Url("app-channel/get-count-items")
            );
            setDataType(res_count?.data);
            const res_total = await axios.get(
                Url("app-channel/get-total-items")
            );
            setDataTotal(res_total?.data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu:", error);
        }
    };
    const getDataAppChannel = () => {
        dispatch(
            getAppChannelAction(
                offset,
                limit,
                rateFilter,
                platformFilter,
                typeFilter,
                dateStart.toDateString(),
                dateEnd.toDateString(),
                appId
            )
        );
    };
    useEffect(() => {
        getDataAppChannel();
    }, [
        offset,
        limit,
        rateFilter,
        platformFilter,
        typeFilter,
        dateStart,
        dateEnd,
        appId,
        dataItem,
    ]);
    const handleRateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRateFilter(event.target.value as number);
    };
    const handlePlatformChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setPlatformFilter(event.target.value as string);
    };

    const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTypeFilter(event.target.value as string);
    };

    const onNextPage = () => {
        if (dataAppChannel?.length > 0) {
            setOffset(offset + limit);
        }
    };

    const onPrevPage = () => {
        if (offset - limit >= 0) {
            setOffset(offset - limit);
        }
    };
    const handleShowHistories = (data: AppChannel) => {
        setDataHistories(data);
    };
    const handleEditItem = (data: AppChannel) => {
        setDataItem(data);
    };
    const filteredData: any[] = dataType?.filter(
        (item: any) => item._id.length === 1
    );
    const users: IUserInfo[] = useSelector(
        (state: any) => state.userInfoState.userInfos
    );

    const onHideHistories = () => {
        setDataHistories(undefined);
    };
    const onHide = () => {
        setDataItem(undefined);
    };
    const handleAppNameChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setAppId(event.target.value);
    };
    return (
        <Box>
            {dataAppChannel ? (
                <div className="container-table-rating">
                    <div className="container-date">
                        <div className="container-input">
                            <h6>Từ</h6>
                            <FormControl
                                className="input"
                                type="date"
                                name="startDate"
                                value={moment(dateStart).format("YYYY-MM-DD")}
                                onChange={(e) =>
                                    setDateStart(new Date(e.target.value))
                                }
                            />
                        </div>
                        <div className="container-input">
                            <h6>Đến</h6>
                            <FormControl
                                className="input"
                                type="date"
                                name="endDate"
                                value={moment(dateEnd).format("YYYY-MM-DD")}
                                onChange={(e) =>
                                    setDateEnd(new Date(e.target.value))
                                }
                            />
                        </div>
                    </div>
                    <div className="container-count-content">
                        {filteredData?.map((item: any, index: number) => (
                            <div key={index} className="group-count">
                                <span>{item?._id} : </span>
                                <span>{item?.count}</span>
                            </div>
                        ))}
                        <div className="group-count">
                            <span>Tổng :</span>
                            <span>{dataTotal?.count}</span>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 1200 }}
                            size="small"
                            aria-label="a dense table"
                        ></Table>
                        <TableHead className="container-header">
                            <TableRow>
                                <TableCell className="title-stt">STT</TableCell>
                                <TableCell className="title-rate">
                                    <span>RATE</span>
                                    <select
                                        className="select"
                                        value={rateFilter}
                                        onChange={handleRateChange}
                                    >
                                        <option value={0}></option>
                                        <option value={1}>1 Star</option>
                                        <option value={2}>2 Star</option>
                                        <option value={3}>3 Star</option>
                                    </select>
                                </TableCell>
                                <TableCell className="title-type">
                                    <span>TYPES</span>
                                    <select
                                        className="select"
                                        value={typeFilter}
                                        onChange={handleTypeChange}
                                    >
                                        <option value={""}></option>
                                        <option value={"tech"}>Tech</option>
                                        <option value={"content"}>
                                            Content
                                        </option>
                                        <option value={"design"}>Design</option>
                                        <option value={"missRating"}>
                                            Miss Rating
                                        </option>
                                    </select>
                                </TableCell>
                                <TableCell className="title-content">
                                    CONTENT
                                </TableCell>
                                <TableCell className="title-rate">
                                    <span>APP NAME</span>
                                    <div>
                                        <select
                                            className="select"
                                            value={appId}
                                            onChange={handleAppNameChange}
                                        >
                                            <option value=""></option>
                                            {Config.APP_NAME.map(
                                                (item, index) => (
                                                    <option
                                                        key={item.value + index}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </TableCell>
                                <TableCell className="title-rate">
                                    <span>PLATFORM</span>
                                    <select
                                        className="select"
                                        onChange={handlePlatformChange}
                                        value={platformFilter}
                                    >
                                        <option value={""}></option>
                                        <option value={"android"}>
                                            android
                                        </option>
                                        <option value={"ios"}>ios</option>
                                        <option value={"web"}>web</option>
                                    </select>
                                </TableCell>
                                <TableCell className="title-table">
                                    STATUS
                                </TableCell>
                                <TableCell className="title-table">
                                    MANAGER
                                </TableCell>
                                <TableCell className="title-table">
                                    LAST UPDATE
                                </TableCell>
                                <TableCell className="title-table">
                                    CREAT DATE
                                </TableCell>
                                <TableCell className="title-table">
                                    CHAGE DATA
                                </TableCell>
                                <TableCell className="title-table">
                                    HISTORIES
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {dataAppChannel.length > 0 ? (
                            <TableBody>
                                {dataAppChannel.map(
                                    (item: AppChannel, index: number) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="title-stt">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell className="text-rate">
                                                    {item?.rating === 1 ? (
                                                        <StarIcon />
                                                    ) : item?.rating === 2 ? (
                                                        <div>
                                                            <StarIcon />
                                                            <StarIcon />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <StarIcon />
                                                            <StarIcon />
                                                            <StarIcon />
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell className="title-type">
                                                    {item?.types.length > 0
                                                        ? item?.types?.map(
                                                              (
                                                                  item_type: any,
                                                                  index: number
                                                              ) => (
                                                                  <span
                                                                      key={
                                                                          index
                                                                      }
                                                                      className="text-type"
                                                                  >
                                                                      {
                                                                          item_type
                                                                      }
                                                                  </span>
                                                              )
                                                          )
                                                        : "-"}
                                                </TableCell>
                                                <TableCell className="title-content">
                                                    {item?.content}
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    {item?.appName}
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    {item?.platform
                                                        ? item?.platform
                                                        : "-"}
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    {item.status ? (
                                                        <div>{item.status}</div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>
                                                <TableCell className="title-manager">
                                                    <div>
                                                        {users.map(
                                                            (user) =>
                                                                item?.manager.includes(
                                                                    user._id
                                                                ) && (
                                                                    <div
                                                                        key={
                                                                            user._id
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            textAlign:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {user.lastName +
                                                                                " "}
                                                                            {
                                                                                user.firstName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    {item?.lastUpdate
                                                        ? item?.lastUpdate
                                                        : "-"}
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    {moment(
                                                        item?.createDate
                                                    ).format("MM-DD-YYYY")}
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <AssignmentIcon
                                                            onClick={() => {
                                                                handleEditItem(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell className="title-table">
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <HistoryIcon
                                                            onClick={() =>
                                                                handleShowHistories(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        ) : (
                            <span className="notification-end-data">
                                Out of data notification !
                            </span>
                        )}
                    </TableContainer>
                    <div>
                        {dataItem ? (
                            <ModalEditInfoAppChannel
                                onHide={onHide}
                                data={dataItem}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                    <div>
                        {dataHistories ? (
                            <HistoryModal
                                data={dataHistories}
                                onHide={onHideHistories}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                    <div className="table-bottom">
                        <div className="containner-button">
                            <button className="btn-next" onClick={onPrevPage}>
                                prev
                            </button>
                            <button className="btn-next" onClick={onNextPage}>
                                next
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </Box>
    );
};

export default ShowInfoRating;

const ModalEditInfoAppChannel: FC<{ onHide: () => void; data: AppChannel }> = ({
    onHide,
    data,
}) => {
    type OptionType = {
        value: string;
        label: string;
    };
    const [status, setStatus] = useState<string>("");
    const [listUsers, setListUsers] = useState<string[]>([]);
    const dispatch = useDispatch();
    const userInfo: IUserInfo = useSelector(
        (state: any) => state.authState.user
    );
    useEffect(() => {
        setStatus(data.status);
        setListUsers(data.manager);
    }, [data]);
    const getChangedInfo = (appChannelUpdate: IAppChannel) => {
        let changes: IAppChannelHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if (status !== appChannelUpdate.status) {
            changes.push(
                new AppChannelHistory({
                    field: "status",
                    from: status,
                    to: appChannelUpdate.status,
                    userID,
                    time,
                })
            );
        }
        if (listUsers !== appChannelUpdate.manager) {
            changes.push(
                new AppChannelHistory({
                    field: "manager",
                    from: listUsers.join(","),
                    to: appChannelUpdate.manager.join(","),
                    userID,
                    time,
                })
            );
        }
        return changes;
    };
    const onUpdateAppChannel = () => {
        const appChannelUpdate = {
            ...data,
            status: status ?? data.status,
            manager: listUsers ?? data.manager,
        };
        const changes = getChangedInfo(data);
        const histories = (changes ?? []).concat(data.histories ?? []);
        const newDataAppChannel: IAppChannel = {
            ...appChannelUpdate,
            histories,
        };
        if (status !== data.status || listUsers !== data.manager) {
            dispatch(updateAppChannelAction(newDataAppChannel));
            onHide();
        }
    };
    const handleCloseEdit = () => {
        onHide();
    };
    const statusInfo: OptionType[] = [
        { value: "to do", label: "To Do" },
        { value: "in progress", label: "In Progress" },
        { value: "done", label: "Done" },
    ];
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setStatus(event.target.value);
    };
    const users: IUserInfo[] = useSelector(
        (state: any) => state.userInfoState.userInfos
    );

    const userOptions = convertUserOptions(users);

    return (
        <>
            <Modal show={true} centered size="lg" onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ItemPanel
                            title="Status"
                            children={
                                <Form.Select
                                    value={status}
                                    onChange={handleChange}
                                >
                                    <option value=""></option>
                                    {statusInfo.map(
                                        (item: OptionType, index: number) => (
                                            <option
                                                key={item.value + index}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </option>
                                        )
                                    )}
                                </Form.Select>
                            }
                        />
                        <ItemPanel
                            title="Manager"
                            children={
                                <SearchAndSelectUsers
                                    ids={listUsers.map((u) => u)}
                                    onChange={(userIds) => {
                                        setListUsers(userIds);
                                    }}
                                    userOptions={userOptions}
                                    label="Manager"
                                />
                            }
                        />
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <MButton onClick={() => onUpdateAppChannel()}>
                        Update
                    </MButton>
                    <MButton
                        className="close"
                        aria-label="Close"
                        onClick={() => handleCloseEdit()}
                        variant="secondary"
                    >
                        Close
                    </MButton>
                </ModalFooter>
            </Modal>
        </>
    );
};

const ItemPanel: FC<{ children: React.ReactNode; title: string }> = ({
    title,
    children,
}) => {
    const itemStyle: CSSProperties = {
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    };
    const itemLabelStyle: CSSProperties = {
        width: 100,
        fontWeight: "600",
    };
    const itemContentStyle: CSSProperties = {
        width: "calc(100% - 100px)",
    };
    return (
        <div style={itemStyle}>
            <label style={itemLabelStyle}>{title}</label>
            <div style={itemContentStyle}>{children}</div>
        </div>
    );
};

const HistoryModal: FC<{ data: AppChannel; onHide: () => void }> = ({
    data,
    onHide,
}) => {
    const users: IUserInfo[] = useSelector(
        (state: any) => state.userInfoState.userInfos
    );
    const filteredUsers = users.filter((user) =>
        data?.histories.find((dt) => dt.userID == user._id)
    );
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    return (
        <>
            <Modal show={true} centered size="xl" onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div>
                            {data.histories.length > 0 ? (
                                data.histories.map(
                                    (
                                        history: AppChannelHistory,
                                        index: number
                                    ) => {
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                }}
                                                key={index + history.field}
                                            >
                                                <div>{index + 1 + " ."}</div>
                                                <span>
                                                    {filteredUsers[0]
                                                        ?.lastName + " "}{" "}
                                                    {filteredUsers[0]
                                                        ?.firstName + " "}{" "}
                                                    update the {history.field}{" "}
                                                    on{" "}
                                                    {moment(
                                                        history?.time
                                                    ).format("MM-DD-YYYY")}{" "}
                                                </span>
                                                <HistoryLabelOld
                                                    action={history}
                                                    mapUser={mapUser}
                                                />
                                            </div>
                                        );
                                    }
                                )
                            ) : (
                                <span>Information has never been edited</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <MButton
                        className="close"
                        aria-label="Close"
                        onClick={() => onHide()}
                        variant="secondary"
                    >
                        Close
                    </MButton>
                </ModalFooter>
            </Modal>
        </>
    );
};

const HistoryLabelOld: FC<{
    action: IAppChannelHistory;
    mapUser: Map<string, IUserInfo>;
}> = ({ action, mapUser }) => {
    const getUserNameById = (id: string) => {
        const user = mapUser.get(id);
        if (user) {
            return user.lastName + " " + user.firstName;
        }
        return "";
    };
    const getHistoryActionLabel = (field: string, val: string) => {
        switch (field) {
            case "manager":
                let message = "";
                let valCopy = [val];
                if (typeof val === "string") {
                    valCopy = val.split(",");
                }
                valCopy.forEach((t, index) => {
                    if (index === valCopy.length - 1) {
                        message += getUserNameById(t);
                        return;
                    }
                    message += getUserNameById(t) + ", ";
                });
                return message;
            default:
                return val;
        }
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        textDecoration: "line-through",
                        paddingLeft: "5px",
                    }}
                >
                    {getHistoryActionLabel(action.field, action.to)}
                </div>
                <FontAwesomeIcon icon={faLongArrowAltRight as IconProp} />
                <div>{getHistoryActionLabel(action.field, action.from)}</div>
            </div>
        </>
    );
};
