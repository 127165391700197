import React from "react";
import { getPlatformName } from "../../../../../config_data_analytic";
import Constants from "../../../../../constant";
import { IAppInfo } from "../../../../../models/appinfo.model";
import { ICertificates } from "../../../../../models/certificates.model";
import "./index.scss";
const AvgRating = ({ certificates }: { certificates: ICertificates }) => {
    return (
        <div>
            <div
                className="list-chart"
                style={{
                    gap: "24px",
                }}
            >
                {certificates?.appInfos?.map((appInfo, index) => {
                    if (appInfo.platformId == Constants.PLATFORM_WEB) {
                        return null;
                    }
                    return (
                        <AvgRatingView
                            key={index}
                            certificates={certificates}
                            appInfo={appInfo}
                        />
                    );
                })}
            </div>
        </div>
    );
};
const AvgRatingView = ({
    certificates,
    appInfo,
}: {
    certificates: ICertificates;
    appInfo: IAppInfo;
}) => {
    const platformName = getPlatformName(appInfo.platformId);
    return (
        <>
            {/* {appInfo.averageUserRating && ( */}
            <div
                className="col"
                style={{
                    textAlign: "center",
                    border: `1px solid #e4e4e4`,
                    padding: "20px",
                    borderRadius: "12px",
                }}
            >
                <div>{certificates.name + " " + platformName}</div>
                <div>{appInfo.averageUserRating ?? 0}</div>
            </div>
            {/* )} */}
        </>
    );
};

export default AvgRating;
