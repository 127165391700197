import { memo, useEffect, useState } from "react";
import { IUserExpectation } from "../../../shared/models/userExpectation";
import { ISprint } from "../../../shared/models/sprint";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ReactTooltip from "react-tooltip";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { ITaskScores, TaskScore } from "../../../shared/models/taskScore";
import NewProgressBar from "./NewProgressBar";

const PersonalExpectationItem = memo(
    ({
        taskScores,
        expectation,
        currentSprint,
        disciplinePoints,
    }: {
        taskScores: ITaskScores;
        expectation: IUserExpectation;
        currentSprint: ISprint;
        disciplinePoints: any;
    }) => {
        if (!taskScores) taskScores = new TaskScore();
        let donePointPercentage = taskScores?.pointDonePercentage.value ?? 0;
        let doneTasksInDeadlinePercentage = taskScores?.taskDoneInDeadlinePercentage.value ?? 0;
        let doneTasksPercentage = taskScores?.taskDonePercentage.value ?? 0;
        return (
            <div className="expected-list">
                <TimeWatcher currentSprint={currentSprint} />
                <div className="expected-list-item white">
                    {taskScores?.pointDone?.label}
                    <div className="flex" style={{ alignItems: "baseline" }}>
                        <NewProgressBar
                            current={donePointPercentage}
                            expect={(expectation.pointDone / 80) * 100}
                            tooltipTag="donePointPercentage"
                        >
                            <>
                                <span>{`${taskScores?.pointDone.value ?? 0} point`}</span>
                                <ReactTooltip id="donePointPercentage" type="info">
                                    <SportsScoreIcon htmlColor="#fff" />
                                    {`${expectation?.pointDone ?? 0} point`}
                                </ReactTooltip>
                            </>
                        </NewProgressBar>
                    </div>
                </div>
                <div className="expected-list-item white">
                    {taskScores?.taskDonePercentage?.label}
                    <div className="flex" style={{ alignItems: "baseline" }}>
                        <NewProgressBar
                            current={doneTasksPercentage}
                            expect={expectation?.doneTasksPercentage ?? 80}
                            tooltipTag="doneTasksPercentage"
                        >
                            <>
                                <span>{`${doneTasksPercentage}%`}</span>
                                <ReactTooltip id="doneTasksPercentage" type="info">
                                    <SportsScoreIcon htmlColor="#fff" />
                                    {`${expectation?.doneTasksPercentage ?? 0}%`}
                                </ReactTooltip>
                            </>
                        </NewProgressBar>
                    </div>
                </div>
                <div className="expected-list-item white">
                    {taskScores?.taskDoneInDeadlinePercentage?.label}
                    <div className="flex" style={{ alignItems: "baseline" }}>
                        <NewProgressBar
                            current={doneTasksInDeadlinePercentage}
                            expect={expectation?.doneTasksInDeadlinePercentage ?? 60}
                            tooltipTag="doneTasksInDeadlinePercentage"
                        >
                            <>
                                <span>{`${doneTasksInDeadlinePercentage}%`}</span>
                                <ReactTooltip id="doneTasksInDeadlinePercentage" type="info">
                                    <SportsScoreIcon htmlColor="#fff" />
                                    {`${expectation?.doneTasksInDeadlinePercentage ?? 0}%`}
                                </ReactTooltip>
                            </>
                        </NewProgressBar>
                    </div>
                </div>
                <div className="expected-list-item white">
                    Kỷ luật
                    <div className="flex" style={{ alignItems: "baseline" }}>
                        <NewProgressBar
                            current={
                                100 - (disciplinePoints?.minusScore ?? 0) < 0 ? 0 : 100 - (disciplinePoints?.minusScore ?? 0)
                            }
                            expect={expectation?.disciplinePoints ?? 100}
                            tooltipTag="discipline-point"
                        >
                            <>
                                <span>{`${100 - (disciplinePoints?.minusScore ?? 0)}`}</span>
                                <ReactTooltip id="discipline-point" type="info">
                                    <SportsScoreIcon htmlColor="#fff" />
                                    {`${expectation?.disciplinePoints ?? 0}`}
                                </ReactTooltip>
                            </>
                        </NewProgressBar>
                    </div>
                </div>
            </div>
        );
    }
);

const TimeWatcher = memo(
    ({ currentSprint }: { currentSprint: ISprint }) => {
        const [time, setTime] = useState({ h: 0, m: 0, s: 0 });
        const getTime = () => {
            let time = new Date(currentSprint.endDate).getTime() - Date.now();
            if (time >= 0) {
                setTime({
                    h: Math.floor(time / (1000 * 60 * 60)),
                    m: Math.floor((time / 1000 / 60) % 60),
                    s: Math.floor((time / 1000) % 60),
                });
            }
        };
        const [sprintTime, setSprintTime] = useState(0);
        useEffect(() => {
            const interval = setInterval(() => getTime(), 1000);
            setSprintTime(
                (new Date(currentSprint.endDate).getTime() - new Date(currentSprint.startDate).getTime()) / 1000 / 60
            ); //minutes
            return () => clearInterval(interval);
        }, []);

        return (
            <div className="stop-watcher white">
                <div className="sprint-progress flex">
                    <div className="sprint-name">{currentSprint.name}</div>
                    <div className="align-item-center">
                        <TimerOutlinedIcon htmlColor="#fff" />
                        {` ${time.h} : ${time.m} : ${time.s}`}
                    </div>
                    <div className="sprint-name">
                        {currentSprint.name.replace(currentSprint.shortId, parseInt(currentSprint.shortId) + 1 + "")}
                    </div>
                </div>
                <NewProgressBar current={((sprintTime - time.h * 60 - time.m) / sprintTime) * 100} />
            </div>
        );
    },
    (prev, next) => {
        return true;
    }
);
export default PersonalExpectationItem;
