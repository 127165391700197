import axios, { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import Config from "../../shared/models/config";
import { ITask, ITaskComment, TaskComment } from "../../shared/models/task";
import Url from "../../util/url";
import {
    CommentTypes,
    ICommentAction,
    deleteCommentFailed,
    deleteCommentSuccess,
    getCommentFailed,
    getCommentSuccess,
    updateCommentFailed,
    updateCommentSuccess,
} from "../actions/comment.action";

function* getCommentSaga(action: ICommentAction) {
    if (action.taskId) {
        try {
            const tasks: ITask[] = yield select(
                (state: any) => state.taskState.tasks ?? []
            );
            const task = tasks.find((task) => task._id == action.taskId);
            if (task) {
                yield put(getCommentSuccess(task.comments ?? []));
            } else {
                yield put(getCommentFailed("getCommentSaga task undefined"));
            }
        } catch (err) {
            yield put(getCommentFailed("getCommentSaga ERROR: " + err));
        }
    } else {
        yield put(getCommentFailed("getCommentSaga ERROR: taskId empty!"));
    }
}

async function updateCommentAPI(
    comment: ITaskComment
): Promise<TaskComment | null> {
    return await axios
        .post(Url("task/comment/update"), comment)
        .then((data: AxiosResponse<any>) => {
            if (data.status == 200 && data?.data?.response) {
                return new TaskComment(data?.data?.response);
            }
            return null;
        });
}

function* updateCommentSaga(action: ICommentAction) {
    if (action.comments) {
        let comments = action.comments;
        try {
            let result: ITaskComment[] = [];
            for (let comment of comments) {
                result.push(yield call(updateCommentAPI, comment));
            }
            yield put(updateCommentSuccess(result));
        } catch (err) {
            window.alert("updateCommentSaga ERROR: " + err);
            yield put(updateCommentFailed("updateCommentSaga ERROR: " + err));
        }
    } else {
        window.alert("updateCommentSaga comment empty");
        yield put(updateCommentFailed("updateCommentSaga comment empty"));
    }
}

async function deleteCommentAPI(comment: ITaskComment): Promise<string> {
    return await axios
        .post(Url("task/delete-comment"), {
            commentId: comment._id,
            taskId: comment.taskId,
        })
        .then((data: AxiosResponse<any>) => {
            return data.status + "";
        });
}

function* deleteCommentSaga(action: ICommentAction) {
    if (action.comments && action.comments.length) {
        let comment = action.comments[0];
        try {
            const result: string = yield call(deleteCommentAPI, comment);
            if (result === "200") {
                yield put(deleteCommentSuccess([comment._id]));
            } else {
                yield put(
                    deleteCommentFailed("deleteCommentSaga status " + result)
                );
            }
        } catch (err) {
            window.alert("deleteCommentSaga ERROR: " + err);
            yield put(deleteCommentFailed("deleteCommentSaga ERROR: " + err));
        }
    } else {
        yield put(deleteCommentFailed("deleteCommentSaga comment empty"));
    }
}

export function* watchComment() {
    yield takeLatest(CommentTypes.GET_COMMENT, getCommentSaga);
    yield takeLatest(CommentTypes.UPDATE_COMMENT, updateCommentSaga);
    yield takeLatest(CommentTypes.DELETE_COMMENT, deleteCommentSaga);
}
