import * as React from "react";
import "./style.scss";
import Moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";
import FormSchedule from "./formSchedule";
import { useSelector } from "react-redux";
import Url from "../../util/url";
import Constants from "../../constant";
import { IUserInfo } from "../../shared/models/userInfo";

const WorkSchedule = () => {
    const currentUser = useSelector((state: any) => state.authState?.user);
    const [open, setOpen] = React.useState(false);
    const [scheduleList, setScheduleList] = React.useState([]);
    const [chooseSchedule, setChooseSchedule] = React.useState<any>({});
    const [reload, setReload] = React.useState("");
    const [userList, setUserList] = React.useState<IUserInfo[]>([]);

    React.useEffect(() => {
        getAllSchedule();
        getAllUsers();
    }, [reload]);

    const getAllSchedule = async () => {
        try {
            const { data: response } = await axios.get(Url(`schedule/getAll/${currentUser._id}`));
            setScheduleList(response);
        } catch (error) {}
    };

    const getAllUsers = async () => {
        try {
            const { data: response } = await axios.get(Url("user/not-disable"));
            const filterUsers = response.response.filter((user: IUserInfo) => user.status === 1 && user.discordID);
            setUserList(filterUsers);
        } catch (error) {}
    };

    const handleOpen = (chooseSchedule: any) => {
        setChooseSchedule(chooseSchedule);
        setOpen(true);
    };

    const handleGetNameReporters = (schedule: any, reporters: any) => {
        let nameString = "";
        if (schedule.statusReporters === Constants.PERSONS) {
            for (let reporter of reporters) {
                const user = userList.find((user: IUserInfo) => user._id === reporter);
                if (user) {
                    nameString += `${user.lastName} ${user.firstName}, `;
                }
            }
            nameString = nameString.replace(/,\s*$/, "");
            return nameString;
        } else if (schedule.statusReporters === Constants.TEAMS) {
            const newReporters = reporters.join(", ");
            return newReporters;
        } else if (schedule.statusReporters === Constants.ALL_TEAMS) {
            return "All Teams";
        } else if (schedule.statusReporters === Constants.CHANNELS) {
            const newReporters = reporters.join(", ");
            return newReporters;
        }
    };
    // lam 1 ham gioi han so ki tu
    return (
        <div className="Schedule">
            <TableContainer component={Paper}>
                <Table aria-label="Schedule">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#cce6ff" }}>
                            <TableCell sx={{ width: "30%" }} align="center">
                                Title
                            </TableCell>
                            <TableCell sx={{ width: "20%" }} align="center">
                                Schedule
                            </TableCell>
                            <TableCell sx={{ width: "20%" }} align="center">
                                Recurrent
                            </TableCell>
                            <TableCell sx={{ width: "30%" }} align="center">
                                Reporters
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleList.length > 0 &&
                            scheduleList.map((schedule: any, index: number) => (
                                <TableRow key={index} style={{ cursor: "pointer" }} onClick={() => handleOpen(schedule)}>
                                    <TableCell align="center">{schedule.title}</TableCell>
                                    <TableCell align="center">{Moment(schedule.schedule).format("DD/MM/YYYY HH")}h</TableCell>
                                    <TableCell align="center">{schedule.recurrent}</TableCell>
                                    <TableCell align="center">{handleGetNameReporters(schedule, schedule.reporters)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <IconButton className="add-icon" onClick={() => handleOpen("")}>
                <AddCircleOutlineIcon fontSize="large" color={"primary"} />
            </IconButton>
            {open && (
                <FormSchedule
                    currentUser={currentUser}
                    open={open}
                    setOpen={setOpen}
                    chooseSchedule={chooseSchedule}
                    setReload={setReload}
                    userList={userList}
                />
            )}
        </div>
    );
};

export default WorkSchedule;
