import { memo } from "react";
import "./new-progress-bar.scss";
/**
 *
 * @current current value of the progress bar (dimensional: %)
 * @children child progress bar (dimensional: %)
 * @expect expect value (for user expectation values)
 * @tooltipTag tooltip data-for
 */
const NewProgressBar = ({
    current,
    children,
    expect,
    tooltipTag = "_unknow_",
}: {
    current: number;
    children?: React.ReactNode;
    tooltipTag?: string;
    expect?: number;
}) => {
    return (
        <div className="expected-progress">
            <div className="current" style={{ width: `${current > 100 ? 100 : current}%` }}></div>
            {expect && (
                <div
                    data-tip
                    data-for={tooltipTag}
                    className="expect-spot"
                    style={{
                        marginLeft: `calc(${expect > 100 ? 100 : expect}% - 5px)`, // TODO: expectation.pointDone / expectPointDone KPI
                    }}
                >
                    <div />
                </div>
            )}
            {children}
        </div>
    );
};

export default memo(NewProgressBar, (prevProps, nextProps) => {
    if (prevProps.current === nextProps.current) return true;
    return false;
});
