import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import * as React from "react";

import ArrowBack from "@material-ui/icons/ArrowBack";
import FilterList from "@material-ui/icons/FilterList";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import {
    getPurchaseInfomationByEmail,
    getPurchaseInfomationByTransactionId,
    setDataProcessingAction,
    setMesage,
} from "../../redux/actions/toolAnalysis.action";
import { convertDate } from "../../shared/utils";
import { validateEmail } from "../../util";
import { urlDashboard } from "../../util/url";
import CustomizedSnackbar from "./CustomizeSnackbar";
import Orders from "./OrdersPanel";
const mdTheme = createTheme();
const options = ["Get data by Email", "Get data by TransactionId", "Cancel subscription"];
const DATA_BY_EMAIL = 0;
const DATA_BY_TRANSACTIONID = 1;
const CANCEL_SUBSCRIPTION = 2;
const FILTER_WIDTH = 256;

function DashboardContent() {
    const getDataProcessing: boolean = useSelector((state: any) => state.toolAnalysisState.getDataProcessing);
    const [email, setEmail] = React.useState("");
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [anchorElDateRange, setAnchorElDateRange] = React.useState<any>(null);
    const openPopover = Boolean(anchorEl);
    const openPopoverDateRange = Boolean(anchorElDateRange);
    const [transactionId, setTransactionId] = React.useState("");
    const [orderId, setOrderId] = React.useState("");
    const [currentOption, setOption] = React.useState(0);
    const [dateRange, setDateRange] = React.useState<{
        startDate: Date | undefined;
        endDate: Date | undefined;
        key: string;
    }>({
        startDate: undefined,
        endDate: undefined,
        key: "selection",
    });

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDateRange = () => {
        setAnchorElDateRange(null);
    };

    const handelGetDataByEmail = () => {
        dispatch(setDataProcessingAction(true));
        dispatch(getPurchaseInfomationByEmail(email));
    };

    const handleGetDataByTransaction = () => {
        dispatch(setDataProcessingAction(true));
        dispatch(
            getPurchaseInfomationByTransactionId({
                transactionId: transactionId,
                start_date: dateRange.startDate?.toISOString(),
                end_date: dateRange.endDate?.toISOString(),
            })
        );
    };

    const handleCancelSubscription = async () => {
        await axios.post(urlDashboard + "cancel-subscription", { orderId: orderId }).then(
            (response) => {
                dispatch(setMesage("Cancel subscription done", "success"));
            },
            (e) => {
                dispatch(setMesage("Cancel subscription error", "error"));
                console.log(e);
            }
        );
    };

    const handleSelect = (ranges: any) => {
        setDateRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: "selection",
        });
    };

    const disableButton = () => {
        return (
            getDataProcessing ||
            (currentOption == DATA_BY_EMAIL
                ? !validateEmail(email)
                : currentOption == DATA_BY_TRANSACTIONID
                ? !!!transactionId || !!!dateRange.startDate || !!!dateRange.endDate
                : currentOption == CANCEL_SUBSCRIPTION && !!!orderId)
        );
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex", flexDirection: "column" }} className="tool-container">
                <CssBaseline />
                <div className="div-container">
                    <div
                        onClick={(e) => {
                            window.history.back();
                        }}
                        className="back-btn"
                    >
                        <ArrowBack />
                    </div>
                </div>

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                            }}
                            style={{
                                marginBottom: 16,
                                display: "flex",
                                justifyContent: "space-between",
                                width: FILTER_WIDTH,
                                minWidth: FILTER_WIDTH,
                            }}
                        >
                            {options[currentOption]}
                            <FilterList />
                        </Button>
                        <Popover
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: 6,
                                    width: FILTER_WIDTH,
                                },
                            }}
                        >
                            <List>
                                {options.map((opt, index) => {
                                    return (
                                        <ListItem disablePadding key={index}>
                                            <ListItemButton
                                                onClick={(e) => {
                                                    if (currentOption != index) setOption(index);
                                                    handleClose();
                                                }}
                                            >
                                                {opt}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Popover>

                        <Paper
                            sx={{
                                paddingBottom: 2,
                                display: "flex",
                                background: "transparent",
                                boxShadow: "none",
                            }}
                        >
                            {currentOption == DATA_BY_EMAIL ? (
                                <TextField
                                    onKeyDown={(event) => {
                                        if (event.key == "Enter") {
                                            handelGetDataByEmail();
                                        }
                                    }}
                                    fullWidth
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    label="Enter email"
                                    variant="outlined"
                                    autoComplete="off"
                                />
                            ) : currentOption == DATA_BY_TRANSACTIONID ? (
                                <>
                                    <TextField
                                        value={transactionId}
                                        onChange={(event) => setTransactionId(event.target.value)}
                                        label="Enter transactionId"
                                        variant="outlined"
                                        autoComplete="off"
                                        sx={{ width: "100%" }}
                                    />
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            marginLeft: "16px",
                                            width: "256px",
                                            minWidth: "256px",
                                        }}
                                        onClick={(e) => {
                                            setAnchorElDateRange(e.currentTarget);
                                        }}
                                    >
                                        {(convertDate(dateRange.startDate)?.toLocaleDateString().toString() ?? "Start date") +
                                            " - " +
                                            (convertDate(dateRange.endDate)?.toLocaleDateString().toString() ?? "End date")}
                                    </Button>
                                    <Popover
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={openPopoverDateRange}
                                        anchorEl={anchorElDateRange}
                                        onClose={handleCloseDateRange}
                                        PaperProps={{
                                            style: {},
                                        }}
                                    >
                                        <DateRangePicker
                                            ranges={[dateRange]}
                                            rangeColors={["#1876d2"]}
                                            onChange={handleSelect}
                                            direction="horizontal"
                                            moveRangeOnFirstSelection={false}
                                            maxDate={new Date(new Date().getTime() + 3600 * 1000 * 24)}
                                            months={2}
                                        />
                                    </Popover>
                                </>
                            ) : currentOption == CANCEL_SUBSCRIPTION ? (
                                <>
                                    <TextField
                                        onKeyDown={(event) => {
                                            if (event.key == "Enter") {
                                                handelGetDataByEmail();
                                            }
                                        }}
                                        fullWidth
                                        value={orderId}
                                        onChange={(event) => {
                                            setOrderId(event.target.value);
                                        }}
                                        label="Enter orderId"
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            <Button
                                variant="contained"
                                sx={{
                                    marginLeft: "16px",
                                    width: "160px",
                                    minWidth: "160px",
                                    fontWeight: "bold",
                                    lineHeight: "16px",
                                }}
                                onClick={() => {
                                    if (currentOption == DATA_BY_EMAIL) {
                                        handelGetDataByEmail();
                                    } else if (currentOption == DATA_BY_TRANSACTIONID) {
                                        handleGetDataByTransaction();
                                    } else if (currentOption == CANCEL_SUBSCRIPTION) {
                                        if (
                                            window.confirm(
                                                "Are you sure you want to delete this transaction have orderId: " + orderId
                                            )
                                        )
                                            handleCancelSubscription();
                                    }
                                }}
                                disabled={disableButton()}
                            >
                                {getDataProcessing ? (
                                    <CircularProgress />
                                ) : currentOption == DATA_BY_EMAIL || currentOption == DATA_BY_TRANSACTIONID ? (
                                    "Get data"
                                ) : (
                                    currentOption == CANCEL_SUBSCRIPTION && "Cancel this subscription"
                                )}
                            </Button>
                        </Paper>

                        {(currentOption == DATA_BY_EMAIL || currentOption == DATA_BY_TRANSACTIONID) && (
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Orders type={currentOption} />
                                </Paper>
                            </Grid>
                        )}
                    </Container>
                </Box>
            </Box>
            <CustomizedSnackbar></CustomizedSnackbar>
        </ThemeProvider>
    );
}

export default function ToolDashboard() {
    return <DashboardContent />;
}
