import {
    getInfoAppsSuccessAction,
    getRankingAppSuccessAction,
} from "./../actions/dashboard.action";
import { Certificates, ICertificates } from "../../models/certificates.model";
import { IAppInfo } from "./../../models/appinfo.model";
import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    dashboardTypes,
    getInfoCertificateAllAppSuccessAction,
    IDashBoardAction,
} from "../actions/dashboard.action";
import { urlDashboard } from "../../util/url";
const Config = {
    SHOW_CERTIFICATE_INFORMATION: "app/show-certificate-information?appID=",
    TEST_CERTIFICATE: "test/test-certificate",
};
function getInfoCertificateAllAppApi() {
    return axios({
        method: "get",
        url: urlDashboard + "test/test-certificate",
    }).then((response: any) => {
        let result: ICertificates[] = response.data?.map(
            (item: any) => new Certificates(item)
        );
        return result;
    });
}

function getInfoAppsApi(appID?: number) {
    return axios({
        method: "get",
        url: urlDashboard + "app/show-certificate-information?appID=" + appID,
    }).then((response: any) => {
        let result: ICertificates[] = response.data?.map(
            (item: any) => new Certificates(item)
        );
        return result;
    });
}

function getRankingAppApi(appID?: number) {
    return axios({
        method: "get",
        url: urlDashboard + "rank/get-list-rank?appID=" + appID,
    }).then((response: any) => {
        let result = response.data;

        return result;
    });
}

function* getInfoCertificateAllApp(action: IDashBoardAction) {
    try {
        let appName = action.appName;
        // let testID = action.testID;
        let result: ICertificates[] = yield call(getInfoCertificateAllAppApi);
        if (result) {
            yield put(getInfoCertificateAllAppSuccessAction(result));
        }
    } catch (error) {
        console.log("error", error);
    }
}

function* getInfoApps(action: IDashBoardAction) {
    try {
        let appID = action.appID;
        let result: IAppInfo[] = yield call(getInfoAppsApi, appID);
        if (result) {
            yield put(getInfoAppsSuccessAction(result));
        }
    } catch (error) {
        console.log("error", error);
    }
}

function* getRankingSage(action: IDashBoardAction) {
    try {
        let appID = action.appID;
        let result: any[] = yield call(getRankingAppApi, appID);

        if (result) {
            yield put(getRankingAppSuccessAction(result));
        }
    } catch (error) {
        console.log("error", error);
    }
}

export function* watchDashBoard() {
    yield takeLatest(
        dashboardTypes.GET_INFO_CERTIFICATE_ALL_APP,
        getInfoCertificateAllApp
    );
    yield takeLatest(dashboardTypes.GET_INFO_APPS, getInfoApps);
    yield takeLatest(dashboardTypes.GET_RANKING, getRankingSage);
}
