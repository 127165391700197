import axios from "axios";
import moment from "moment";
import { FC, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllWorkingTimeInfo } from "../../../../redux/actions/workingTime.action";
import { IWorkingTime } from "../../../../shared/models/workingTime";
import Url from "../../../../util/url";
import { IUserInfo } from "../../../../shared/models/userInfo";
import * as XLSX from "xlsx";
import Config from "../../../../shared/models/config";
import { timezoneSetHours } from "../../../../util";
const A_CODE = 97;
const UpdateTimeAttendanceModal: FC<{
    setUpdateWorkingTimeModalVisible: any;
    startTime?: string;
    endTime?: string;
}> = ({ setUpdateWorkingTimeModalVisible, startTime, endTime }) => {
    const [data, setData] = useState<IWorkingTime[]>([]);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const allUsers: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const [disabled, setDisable] = useState(false);
    const uploadNewInfo = async () => {
        if (data.length) {
            setUploading(true);
            await axios
                .post(Url("working-time/update-working-time"), data)
                .then((response) => {
                    if (response.data == "1") {
                        if (startTime && endTime) {
                            let start = timezoneSetHours(startTime).getTime();
                            let end = timezoneSetHours(endTime, 23, 59, 59).getTime();
                            dispatch(getAllWorkingTimeInfo({ start, end }));
                        }
                    }
                })
                .catch((error) => {
                    console.log("upload error", error);
                });
            setUploading(false);
            toast.success("Data uploaded successfully", {
                position: "top-center",
                autoClose: 1000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            setUpdateWorkingTimeModalVisible(false);
            setData([]);
        } else {
            toast.error("No data", {
                position: "top-center",
                autoClose: 1000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    };
    const loadFileXLSX = async (e: any) => {
        const file = await e.target.files[0].arrayBuffer();
        const workbook = XLSX.read(file);
        const sheetName = workbook.SheetNames[0];
        const Sheet = workbook.Sheets[sheetName];
        let data: any = [];

        const getCellLocation = (key: string) => {
            //column only in alphabet !!!!!!!!!!! (1 character)
            if (key.includes("!")) return [-1, -1];
            let row = 0;
            let col = 0;
            key = key.toLowerCase();
            col = key.charCodeAt(0) - A_CODE;
            row = parseInt(key.substring(1, key.length));
            if (row >= 0 && col >= 0) return [row, col];
            else return [-1, -1];
        };

        const formatTime = (time: string) => {
            let [h, m] = time.split(":");
            if (h.length == 1) h = "0" + h;
            if (m.length == 1) m = "0" + m;
            return h + ":" + m;
        };
        for (let cell in Sheet) {
            let [row, col] = getCellLocation(cell);
            if (row >= 0 && col >= 0) {
                if (!!!data[row]) data[row] = [];
                data[row][col] = Sheet[cell].w.trim();
            }
        }
        let undefineEmployee: string[] = [];
        setData(
            data
                .filter((item: any) => item.length > 1)
                .map((item: any) => {
                    try {
                        let user = allUsers.find(
                            (u) =>
                                u.employeeCode.toLowerCase() === item[1].trim().toLowerCase() &&
                                u.status == Config.STATUS_PUBLIC
                        );
                        if (
                            !!!user &&
                            moment(item[3], "M/D/YY").utcOffset("+0700").toDate().toString() !== "Invalid Date" &&
                            !undefineEmployee.includes(item[1].trim() + " " + item[2].trim())
                        )
                            undefineEmployee.push(item[1].trim() + " " + item[2].trim());

                        let entity: IWorkingTime = {
                            userId: user?._id ?? "",
                            date: moment(item[3], "M/D/YY").utcOffset("+0700").toDate(),
                            checkin: item[6] ? formatTime(item[6]) : "",
                            checkout: item[7] ? formatTime(item[7]) : "",
                        };
                        return entity;
                    } catch (e) {
                        return {};
                    }
                })
                .filter((item: IWorkingTime) => {
                    return (
                        item?.date &&
                        item?.date?.toString() !== "Invalid Date" &&
                        item?.date?.getTime() <= timezoneSetHours(new Date(), 23, 59, 59).getTime() //chi lay du lieu den ngay hom nay
                    );
                })
        );
        if (undefineEmployee.length) {
            window.alert("Không tìm thấy mã nhân viên của: " + undefineEmployee.join(", ") + ", hãy cập nhật để tiếp tục!");
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    return (
        <Modal
            show={true}
            onHide={() => {
                setUpdateWorkingTimeModalVisible(false);
            }}
            className="update-working-time-modal"
        >
            <Modal.Header>
                <Modal.Title>Cập nhật máy chấm công</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <input
                    type="file"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                        loadFileXLSX(e);
                    }}
                />
                {uploading ? (
                    <Button variant="primary">Đang cập nhật</Button>
                ) : (
                    <Button variant="primary" onClick={uploadNewInfo} disabled={disabled}>
                        Cập nhật
                    </Button>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default UpdateTimeAttendanceModal;
