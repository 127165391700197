import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
import { IUserInfo, UserCoefficient } from "../../shared/models/userInfo";
import Config from "../../shared/models/config";
import LoadingWidget from "../LoadingWidget";
import { getSprintReportV2Data } from "../../redux/actions/sprintReport.action";
import { ISprintState } from "../../redux/reducers/sprint.reducer";
import DashboardUsersFilter from "../dashboard/users-filter/UserFilterWidget";
import { IUserExpectation } from "../../shared/models/userExpectation";
import { ISprint } from "../../shared/models/sprint";
import Paper from "@mui/material/Paper";
import "./chart-widget.scss";
import WorkingChartArea from "./widget/WorkingChartArea";
import DisciplineChartArea from "./widget/DisciplineChartArea";
import UserAvatar from "../user/UserAvatar";
import ExperienceProgress from "../dashboard/sidebar/ExperienceProgress";
import { roleForPoint } from "../../shared/utils";
import archive from "../../assets/icon/archivement2.png";
import { calculateExp, getUserDisciplineScore, getUserTaskScores } from "../../utils";
const ReportStatistic = () => {
    const dispatch = useDispatch();
    const userInfoState: IUserInfoState = useSelector((state: any) => state.userInfoState);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const sprintState: ISprintState = useSelector((state: any) => state.sprintState);
    const userExpectations: IUserExpectation[] = useSelector((state: any) => state.userExpectationState.data);
    const newSprintReports: any = useSelector((state: any) => state.sprintReportState.newSprintReports);
    const sprints: ISprint[] = sprintState.sprints ?? [];
    const mapSprint: { [key: string]: ISprint } = {};
    for (let sprint of sprints) {
        mapSprint[sprint._id] = sprint;
    }
    const users = userInfoState.userInfos ?? [userInfo];
    const mapUsers: { [key: string]: IUserInfo } = {};
    mapUsers[userInfo._id] = userInfo;
    for (let user of users) {
        mapUsers[user._id] = user;
    }
    const [currentUserIds, setCurrentUserIds] = useState<string[]>([]);
    const isAdmin = userInfo.role == Config.USER_ROLE_ADMIN;
    useEffect(() => {
        if (isAdmin) dispatch(getAllUserInfo()); // 'user/not-disable'
        else setCurrentUserIds([userInfo._id]);
        dispatch(getSprintReportV2Data());
    }, []);

    if (
        sprintState.loading ||
        !sprintState.sprints ||
        sprintState.sprints.length == 0 ||
        (isAdmin && (userInfoState.loading || !userInfoState.userInfos || userInfoState.userInfos.length == 0))
    ) {
        return <LoadingWidget />;
    }

    const userReportData: any[] = newSprintReports[currentUserIds[0]] ?? [];
    const expectation = userExpectations?.filter((item) => item.userId === currentUserIds[0]) ?? [];

    //sort data by sprint start date
    if (userReportData.length > 1) {
        userReportData.sort(
            (a, b) => new Date(mapSprint[a.sprintId].startDate).getTime() - new Date(mapSprint[b.sprintId].startDate).getTime()
        );
    }
    if (expectation.length > 1) {
        expectation.sort(
            (a, b) => new Date(mapSprint[a.sprintId].startDate).getTime() - new Date(mapSprint[b.sprintId].startDate).getTime()
        );
    }
    const currentSprint = sprints.find((s) => s.activeSprint) ?? sprints[0];
    let exp = { prev: 0, current: 0 };
    if (currentUserIds.length > 0) {
        if (mapUsers[currentUserIds[0]]) {
            let currentSprintData = userReportData.filter((s) => s.sprintId === currentSprint._id);
            if (currentSprintData.length > 0) {
                let userTaskScore = getUserTaskScores({
                    currentSprint: currentSprint,
                    users: [mapUsers[currentUserIds[0]]],
                    newSprintReports: {
                        [currentUserIds[0]]: currentSprintData,
                    },
                    onlyReports: true,
                });

                let userDiscipline = getUserDisciplineScore({
                    currentSprint: currentSprint,
                    users: [mapUsers[currentUserIds[0]]],
                    newSprintReports: {
                        [currentUserIds[0]]: currentSprintData,
                    },
                    onlyReports: true,
                });
                exp = calculateExp(
                    userDiscipline[currentUserIds[0]],
                    mapUsers[currentUserIds[0]],
                    userTaskScore[currentUserIds[0]],
                    currentSprintData[0]?.coefficients
                );
            }
        }
    }
    return (
        <Container maxWidth="lg" style={{ paddingTop: "20px" }} className="reports-statistic">
            {isAdmin && (
                <DashboardUsersFilter
                    currentUserIds={currentUserIds}
                    onSelectedUser={(id: string) => {
                        if (currentUserIds[0] === id) setCurrentUserIds([]);
                        else setCurrentUserIds([id]);
                    }}
                    showTooltip={false}
                    filterUsers={(user: IUserInfo) => user.status == Config.STATUS_PUBLIC}
                    isFilterTask={false}
                />
            )}

            <Paper className="paper-container">
                {currentUserIds?.length ? (
                    <>
                        <div className="user-item">
                            <div className="user">
                                <div className="user-item-avatar">
                                    <UserAvatar avatar={mapUsers[currentUserIds[0]]?.avatar} />
                                </div>

                                <div className="user-item-info">
                                    <div className="username">
                                        {mapUsers[currentUserIds[0]]?.firstName + " " + mapUsers[currentUserIds[0]]?.lastName}
                                    </div>
                                    <div className="position">{mapUsers[currentUserIds[0]]?.position}</div>
                                    <div className="level">{`Level: ${mapUsers[currentUserIds[0]]?.level}`}</div>
                                </div>
                            </div>
                            <div className="progress-container">
                                <ExperienceProgress
                                    user={mapUsers[currentUserIds[0]]}
                                    isUserAuthStaff={roleForPoint(userInfo)}
                                    sprint={currentSprint}
                                    exp={exp}
                                />
                                {/* <div className="achievement">
                                </div> */}
                            </div>
                        </div>
                        <WorkingChartArea
                            user={mapUsers[currentUserIds[0]]}
                            mapSprint={mapSprint}
                            userReportData={userReportData}
                            expectation={userExpectations.filter((item) => item.userId === currentUserIds[0])}
                        />

                        <DisciplineChartArea
                            user={mapUsers[currentUserIds[0]]}
                            mapSprint={mapSprint}
                            userReportData={userReportData}
                            expectation={userExpectations.filter((item) => item.userId === currentUserIds[0])}
                        />
                    </>
                ) : (
                    <>Chưa có user nào được chọn</>
                )}
            </Paper>
        </Container>
    );
};

export default ReportStatistic;
