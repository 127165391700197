export const getTotalAmountUsed = (arr) => {
    let total = 0;
    arr.forEach(item => {
        const amountUsed = parseInt(item.amountUsed);
        if(!isNaN(amountUsed)){
            total += parseInt(item.amountUsed);
        }
    })

    return total;
}