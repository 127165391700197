import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { AppInfo, AppType, IAppInfo } from "../../shared/models/appInfo";
import Url from "../../util/url";
import {
    AppTypes,
    IAppAction,
    deleteAppInfoFailed,
    deleteAppInfoSuccess,
    getAppInfosFailed,
    getAppInfosSuccess,
    updateAppInfoFailed,
    updateAppInfoSuccess,
} from "../actions/app.action";

async function getAppInfosAPI(type: AppType): Promise<IAppInfo[]> {
    return await axios.get(Url("app/getAppInfos?type=" + type)).then((data) => {
        if (data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new AppInfo(item);
            });
        }
        return [];
    });
}

function* appSaga(action: IAppAction) {
    if(action.appType) {
        try {
            const appInfos: IAppInfo[] = yield call(getAppInfosAPI, action.appType);
            if (appInfos) {
                yield put(getAppInfosSuccess(appInfos));
            } else {
                yield put(getAppInfosFailed("AppInfos not existed!"));
            }
        } catch (err) {
            yield put(getAppInfosFailed("appSaga ERROR: " + err));
        }
    } else {
        yield put(getAppInfosFailed("appType not found"));
    }
}

async function updateAppInfoAPI(appInfo: IAppInfo): Promise<IAppInfo | null> {
    return await axios
        .post(Url("app/update"), { data: appInfo })
        .then((data) => {
            if (data.status == 200 && data.data && data.data[0]) {
                return new AppInfo(data.data[0]);
            }
            return null;
        });
}

function* updateAppSaga(action: IAppAction) {
    const appInfo = action.appInfo;
    if (appInfo) {
        try {
            const newAppInfo: IAppInfo | null = yield call(
                updateAppInfoAPI,
                appInfo
            );
            if (newAppInfo) {
                yield put(updateAppInfoSuccess(newAppInfo));
            } else {
                yield put(updateAppInfoFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(updateAppInfoFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            updateAppInfoFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

async function deleteAppInfoAPI(appId: string): Promise<string | null> {
    return await axios.post(Url("app/delete"), { appId }).then((data) => {
        if (data.status == 200) {
            return appId;
        }
        return null;
    });
}

function* deleteAppSaga(action: IAppAction) {
    const appInfo = action.appInfo;
    if (appInfo) {
        try {
            const id: string | null = yield call(deleteAppInfoAPI, appInfo._id);
            if (id) {
                yield put(deleteAppInfoSuccess(appInfo));
            } else {
                yield put(deleteAppInfoFailed("appInfo not existed!"));
            }
        } catch (err) {
            yield put(deleteAppInfoFailed("updateAppSaga ERROR: " + err));
        }
    } else {
        yield put(
            deleteAppInfoFailed("updateAppSaga ERROR: appInfo not found!")
        );
    }
}

export function* watchApp() {
    yield takeLatest(AppTypes.GET_APP_INFOS, appSaga);
    yield takeLatest(AppTypes.UPDATE_APP_INFO, updateAppSaga);
    yield takeLatest(AppTypes.DELETE_APP_INFO, deleteAppSaga);
}
