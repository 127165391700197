import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IUserGroup, UserGroup } from "../../shared/models/userGroup";
import Url from "../../util/url";
import {
    deleteUserGroupFailed,
    deleteUserGroupSuccess,
    getAllUserGroupFailed,
    getAllUserGroupSuccess,
    IUserGroupAction,
    updateUserGroupFailed,
    updateUserGroupSuccess,
    UserGroupTypes,
} from "../actions/userGroup.action";

async function getAllUserGroupAPI(): Promise<IUserGroup[]> {
    return await axios
        .get(Url("user-group/get-all-user-group"))
        .then((data) => {
            return data?.data?.response?.map(
                (item: any) => new UserGroup(item)
            );
        });
}

export function* getAllUserGroupSaga() {
    try {
        let data: IUserGroup[] = yield call(getAllUserGroupAPI);
        if (data.length) {
            yield put(getAllUserGroupSuccess(data));
        } else {
            yield put(getAllUserGroupFailed("getAllUserGroupFailed: no data"));
        }
    } catch (e) {
        yield put(getAllUserGroupFailed("getAllUserGroupFailed: " + e));
    }
}

async function updateUserGroupAPI(data: IUserGroup): Promise<IUserGroup[]> {
    let userGroupUpdated: IUserGroup[] = [];
    let userGroup: IUserGroup | null = await axios
        .post(Url("user-group/update-user-group"), {
            data,
        })
        .then((data) => {
            return data?.data?.response
                ? new UserGroup(data?.data?.response)
                : null;
        });
    userGroup && userGroupUpdated.push(userGroup);
    return userGroupUpdated;
}

export function* updateUserGroupSaga(action: IUserGroupAction) {
    let userGroup = action.userGroup;
    if (userGroup) {
        try {
            let data: IUserGroup[] = yield call(updateUserGroupAPI, userGroup);
            if (data.length) {
                yield put(updateUserGroupSuccess(data));
            } else {
                yield put(
                    updateUserGroupFailed(
                        "updateUserGroupFailed: update failed"
                    )
                );
            }
        } catch (e) {
            yield put(updateUserGroupFailed("updateUserGroupFailed: " + e));
        }
    } else {
        yield put(
            updateUserGroupFailed("updateUserGroupFailed: user group empty")
        );
    }
}

async function deleteUserGroupAPI(data: IUserGroup): Promise<IUserGroup[]> {
    let userGroupDeleted: IUserGroup[] = [];
    let userGroup: IUserGroup | null = await axios
        .post(Url("user-group/delete-user-group"), {
            data,
        })
        .then((data) => {
            return data?.data?.response
                ? new UserGroup(data?.data?.response)
                : null;
        });
    userGroup && userGroupDeleted.push(userGroup);
    return userGroupDeleted;
}

export function* deleteUserGroupSaga(action: IUserGroupAction) {
    let userGroup = action.userGroup;
    if (userGroup) {
        try {
            let data: IUserGroup[] = yield call(deleteUserGroupAPI, userGroup);
            if (data.length) {
                yield put(deleteUserGroupSuccess(data));
            } else {
                yield put(
                    deleteUserGroupFailed(
                        "deleteUserGroupFailed: delete failed"
                    )
                );
            }
        } catch (e) {
            yield put(deleteUserGroupFailed("deleteUserGroupFailed: " + e));
        }
    } else {
        yield put(
            deleteUserGroupFailed("deleteUserGroupFailed: user group empty")
        );
    }
}

export function* watchUserGroup() {
    yield takeLatest(UserGroupTypes.GET_ALL_USER_GROUP, getAllUserGroupSaga);
    yield takeLatest(UserGroupTypes.UPDATE_USER_GROUP, updateUserGroupSaga);
    yield takeLatest(UserGroupTypes.DELETE_USER_GROUP, deleteUserGroupSaga);
}
