import Config from "../../shared/models/config";
import OutOfficeRegistration from "../outoffice-registration/OutOfficeRegistration";
const DayOffRequest = () => {
    return (
        <div className="wiki container">
            <div className="documentations">
                <OutOfficeRegistration type={Config.TYPE.offday} />
            </div>
        </div>
    );
};

export default DayOffRequest;
