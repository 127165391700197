import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    mergeData,
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../../config_data_analytic";
import Constants from "../../../../../constant";
import { ICertificates } from "../../../../../models/certificates.model";
import { getInfoWebAppByEventNameAPI } from "../../../../../services/traffic.service";
import {
    formatCurrency,
    formatDate,
    hasAppReactNative,
    hasAppWeb,
} from "../../../../../util";
import CircularIndeterminate from "../../../../common/icon/Loading";
import CustomLineChart from "../../../charts";
import PlatformTemplate from "../../platform-template";
import ColumnView from "../column";

const NumberLoggedUser = ({
    certificates,
}: {
    certificates: ICertificates;
}) => {
    return (
        <div className="list-chart">
            {hasAppWeb(certificates) && (
                <ColumnView
                    platformIds={[Constants.PLATFORM_WEB]}
                    certificates={certificates}
                    eventName="login_by_email_success"
                />
            )}
            <ColumnView
                platformIds={[
                    Constants.PLATFORM_FLUTTER_ANDROID,
                    Constants.PLATFORM_FLUTTER_IOS,
                ]}
                certificates={certificates}
                eventName="login_by_email_success"
            />
            {hasAppReactNative(certificates) && (
                <ColumnView
                    platformIds={[
                        Constants.PLATFORM_REACT_NATIVE_ANDROID,
                        Constants.PLATFORM_REACT_NATIVE_IOS,
                    ]}
                    certificates={certificates}
                    eventName="login_by_email_success"
                />
            )}

            {/* <UserLoggedView
                platformIds={[
                    Constants.PLATFORM_FLUTTER_ANDROID,
                    Constants.PLATFORM_FLUTTER_IOS,
                ]}
                certificates={certificates}
            />
            <UserLoggedView
                platformIds={[
                    Constants.PLATFORM_REACT_NATIVE_ANDROID,
                    Constants.PLATFORM_REACT_NATIVE_IOS,
                ]}
                certificates={certificates}
            /> */}
        </div>
    );
};
const UserLoggedView = ({
    platformIds,
    certificates,
}: {
    platformIds: number[];
    certificates: ICertificates;
}) => {
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [appValues, setAppValues] = useState(null);
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    useEffect(() => {
        async function getDataFC() {
            let apps = certificates.appInfos
                ?.filter((app) => platformIds.includes(app.platformId))
                ?.map((app) => {
                    return { type: app.platformId, streamId: 1541841977 + "" };

                    // return { type: app.platformId, streamId: app.gaID + "" };
                });
            if (apps?.length) {
                let resData = await getInfoWebAppByEventNameAPI(
                    apps,
                    "login_by_email_success",
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );

                setAppValues(resData);
            } else {
                let obj: any = {};
                setAppValues(obj);
            }
        }
        getDataFC();
    }, [startTime, endTime, certificates.id]);
    let newPlatform: number[] = [...platformIds];
    if (optionValue != "all") {
        const platforms: any = PLATFORM_CONFIG.filter((platform) =>
            platformIds.includes(platform.type)
        );
        platforms.forEach((platform: any) => {
            if (
                (platform.category == Constants.CATEGORY_ANDROID &&
                    optionValue == "android") ||
                (platform.category == Constants.CATEGORY_IOS &&
                    optionValue == "ios")
            ) {
                newPlatform = [platform.type];
            }
        });
    }

    let newData = mergeData(appValues, newPlatform);

    const total = newData
        ? newData.reduce((acc, data) => {
              return acc + data.value;
          }, 0) / newData.length
        : 0;

    return (
        <div className="col">
            {appValues ? (
                Object.keys(appValues).length > 0 ? (
                    <>
                        <PlatformTemplate
                            platformIds={platformIds}
                            optionValue={optionValue}
                            setOptionValue={(value: any) =>
                                setOptionValue(value)
                            }
                        />
                        <div className="total">
                            Total <span>{formatCurrency(total)}</span>
                        </div>
                        <CustomLineChart data={newData} dataKeyX="date" />
                    </>
                ) : (
                    <></>
                )
            ) : (
                <CircularIndeterminate />
            )}
        </div>
    );
};

export default NumberLoggedUser;
