import { Button } from "@mui/material";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import TableSection from "./TableSection";
import AddEditAllTestCases, {
    getLastTestIndex,
} from "./add-edit-all-test-cases";
import TestSectionModal from "../components/add-edit-test-section";
import "./style.scss";
import { Add } from "@material-ui/icons";
import { ITestCase } from "../../../../../shared/models/testCase";
import { ITestCycle } from "../../../../../shared/models/testCycle";
import {
    ITestSection,
    TestSection,
} from "../../../../../shared/models/testSection";

const AllTestCases: FC<{
    taskId: string;
    permission: boolean;
    tests: ITestCase[];
    cycles: ITestCycle[];
}> = ({ taskId, permission, tests, cycles }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [test, setTest] = useState<ITestCase | null>(null);
    const [openSectionModal, setOpenSectionModal] = useState(false);
    const [sectionEdit, setSectionEdit] = useState<ITestSection | null>(null);
    const [section, setSection] = useState<ITestSection|null>(null);
    const sections: ITestSection[] = useSelector(
        (state: any) => state.testSections.data
    );
    const sectionsObj: any = {};
    if (tests) {
        tests.reduce((obj: any, item: ITestCase) => {
            const key = item["sectionID"];
            if (!obj[key]) {
                obj[key] = [];
            }
            // Add object to list for given key's value
            obj[key].push(item);
            return obj;
        }, sectionsObj);
    }

    const handleToggleEdit = () => {
        setTest(null);
        setIsEdit(!isEdit);
    };

    const handleClickNewCase = (section: ITestSection) => {
        handleToggleEdit();
        setSection(section);
    };

    const handleClickItem = (testCase: ITestCase) => {
        setTest(testCase);
        setIsEdit(true);
    };

    const handleEditSection = (section: ITestSection) => {
        setSectionEdit(section);
        setOpenSectionModal(true);
    };

    const handleCloseSection = () => {
        setSectionEdit(null);
        setOpenSectionModal(false);
    };

    return isEdit ? (
        <AddEditAllTestCases
            taskId={taskId}
            test={test}
            tests={tests}
            handleToggleEdit={handleToggleEdit}
            section={section}
            sections={sections}
        />
    ) : (
        <>
            <div className="all-test-case">
                <div className="test-case-btn">
                    <Button
                        variant="outlined"
                        onClick={() => setOpenSectionModal(true)}
                        startIcon={<Add />}
                    >
                        New Section
                    </Button>
                </div>
                {sectionsObj &&
                    sections?.map((item: ITestSection) => (
                        <TableSection
                            key={item._id}
                            editSection={handleEditSection}
                            section={item}
                            tests={sectionsObj[item._id]}
                            lastIndex={getLastTestIndex(tests) + 1}
                            handleClick={handleClickItem}
                            handleClickNewCase={handleClickNewCase}
                            permission={permission}
                            cycles={cycles}
                            taskId={taskId}
                        />
                    ))}
                <TableSection
                    editSection={handleEditSection}
                    section={
                        new TestSection({
                            name: "Default Section",
                            description: "",
                        })
                    }
                    tests={sectionsObj["other"]}
                    lastIndex={getLastTestIndex(tests) + 1}
                    handleClick={handleClickItem}
                    handleClickNewCase={handleClickNewCase}
                    permission={permission}
                    cycles={cycles}
                    taskId={taskId}
                />
            </div>
            <TestSectionModal
                open={openSectionModal}
                onClose={handleCloseSection}
                editItem={sectionEdit}
                permission={permission}
                taskId={taskId}
            />
        </>
    );
};

export default AllTestCases;
