import { IUserInfo } from "../../shared/models/userInfo";
import { ISprintReportAction, SprintReportTypes } from "../actions/sprintReport.action";
import { IUserInfoAction, UserInfoTypes } from "../actions/userInfo.action";

export interface IUserInfoState {
    loading: boolean;
    error: any;
    userInfos: IUserInfo[];
}

const initState: IUserInfoState = {
    loading: false,
    error: null,
    userInfos: [],
};

const userInfoState = (state = initState, action: IUserInfoAction | ISprintReportAction): IUserInfoState => {
    switch (action.type) {
        case UserInfoTypes.GET_ALL_USER_INFO:
            return {
                ...initState,
                ...{
                    loading: true,
                },
            };
        case UserInfoTypes.GET_ALL_USER_INFO_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    userInfos: action.userInfos ?? [],
                },
            };
        case UserInfoTypes.GET_ALL_USER_INFO_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case UserInfoTypes.UPDATE_USERS_SUCCESS:
            if (action.userInfos) {
                for (let userInfo of action.userInfos) {
                    let index = state.userInfos.findIndex((u) => u._id == userInfo._id);
                    if (index > -1) {
                        state.userInfos[index] = {
                            ...state.userInfos[index],
                            ...userInfo,
                        };
                    } else {
                        state.userInfos.push(userInfo);
                    }
                }
            }
            return {
                ...state,
            };
        case SprintReportTypes.ADD_REWARD_MINUS_EXP_ACTION_SUCCESS:
            let _userInfos = state.userInfos;
            _userInfos = _userInfos.map((u) => {
                let data = action?.rewardMinusExp ?? [];
                let _data = data[0];
                if (u._id === _data?.userId) {
                    u.point += _data.exp;
                }
                return u;
            });
            return { ...state, userInfos: _userInfos };
        default:
            return state;
    }
};

export default userInfoState;
