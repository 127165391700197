// import { Button } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "./index.scss";
import { toast } from "react-toastify";
import { Button as ButtonMaterial } from "@mui/material";
import Constants from "../../constant";
import {
    createBlogApi,
    getBlogByIdApi,
    getCategoriesApi,
    updateStatusByIdApi,
} from "../../services/createBlog";
import { callApi } from "../../services";
import { delay } from "../../util";

const lIST_WEB = [
    {
        url: "https://cms.worksheetzone.org/wp-json/v1/save-posts",
        name: "Worksheet",
    },
    {
        url: "https://aws-prep.com/wp-json/passemall/v1/save-posts",
        name: "AWS",
    },
    {
        url: "https://asvab-prep.com/wp-json/passemall/v1/save-posts",
        name: "Asvab",
    },
    {
        url: "https://cdl-prep.com/wp-json/passemall/v1/save-posts",
        name: "CDL",
    },
    {
        url: "https://cna-prep.com/wp-json/passemall/v1/save-posts",
        name: "CNA",
    },
    {
        url: "https://dmv-practicetests.com/wp-json/passemall/v1/save-posts",
        name: "DMV",
    },
    {
        url: "https://drivingtheory-tests.com/wp-json/passemall/v1/save-posts",
        name: "Drivingtheory",
    },
    {
        url: "https://ged-testprep.com/wp-json/passemall/v1/save-posts",
        name: "GED",
    },
    {
        url: "https://pmp-testprep.com/wp-json/passemall/v1/save-posts",
        name: "PMP",
    },
    {
        url: "https://ptceprep.com/wp-json/passemall/v1/save-posts",
        name: "PTCE",
    },
    {
        url: "https://realestate-prep.com/wp-json/passemall/v1/save-posts",
        name: "Realestate",
    },
    {
        url: "https://servsafe-prep.com/wp-json/passemall/v1/save-posts",
        name: "Servsafe",
    },
    {
        url: "https://teas-prep.com/wp-json/passemall/v1/save-posts",
        name: "Teas",
    },
    {
        url: "https://passemall.com/wp-json/passemall/v1/save-posts",
        name: "Passemall",
    },
    {
        url: "https://medtutor.org/wp-json/passemall/v1/save-posts",
        name: "Medtutor",
    },
    {
        url: "https://pbn.passemall.com/wp-json/v1/save-posts",
        name: "PBN Site",
    },
];

interface FormValue {
    category: any;
    mode: string;
    range: string;
    website: string;
    keyword: string;
    url: string;
    sheetName: string;
    timestamp: boolean;
}

const CreateBlogPopup = () => {
    const [createBlogFrom, setCreateBlogFrom] = useState("");
    const [listCategory, setListCategory] = useState([]);
    const [blogInfo, setBlogInfo] = useState<any>(null);
    const [formValue, setFormValue] = useState<FormValue>({
        category: -1,
        mode: "",
        range: "",
        website: "",
        keyword: "",
        url: "",
        sheetName: "",
        timestamp: false,
    });
    const [listUrl, setListUrl] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOutput, setListOutput] = useState<any>([]);
    const checkBlogCrawled = async () => {
        while (loading == true && blogInfo?._id) {
            try {
                if (createBlogFrom == "youtube") {
                    let result = await getBlogByIdApi({ blogId: blogInfo._id });
                    if (result.status == Constants.BLOG_CRAWL_CHECKED) {
                        setListUrl(result.listCrawled);
                        setIsSuccess(true);
                        setLoading(false);
                        break;
                    }
                    await delay(10000);
                }
            } catch (error) {
                console.log(error);
                await delay(10000);
            }
        }
    };
    const handleSubmit = async () => {
        try {
            let userId = JSON.parse(localStorage.getItem("userInfo") ?? "")._id;
            if (createBlogFrom == "youtube") {
                setLoading(true);
            }
            let blogInfoResult = await createBlogApi({
                ...formValue,
                user: userId,
                type:
                    createBlogFrom == "paa"
                        ? Constants.PAA_BLOG_TYPE
                        : Constants.YOUTUBE_BLOG_TYPE,
            });
            if (
                formValue.keyword.includes("http") &&
                createBlogFrom == "youtube"
            ) {
                setLoading(false);
                toast("Dữ liệu đã được crawl xong", {
                    position: "top-center",
                });
            } else {
                if (createBlogFrom == "youtube") {
                    setBlogInfo(blogInfoResult);
                } else {
                    toast("Dữ liệu đang được crawl", {
                        position: "top-center",
                    });
                    setIsSuccess(true);
                }
            }
            setFormValue({
                category: -1,
                mode: "",
                website: "",
                range: "",
                keyword: "",
                url: "",
                sheetName: "",
                timestamp: false,
            });
        } catch (error: any) {
            setLoading(false);
            if (error?.response.status == 400) {
                toast("Đang crawl youtube khác", {
                    position: "top-center",
                });
            } else {
                if (createBlogFrom == "paa") {
                    toast("URL hoặc SheetName sai", {
                        position: "top-center",
                    });
                } else {
                    toast("Server bị lỗi", {
                        position: "top-center",
                    });
                }
            }

            console.log("error", error);
        }
    };
    const clickButtonCancelAndReset = async (status: any) => {
        if (
            status == Constants.BLOG_CRAWL_NEED_UPLOAD &&
            listOutput.length == 0
        ) {
            toast("Phải chọn ít nhất 1 video", {
                position: "top-center",
            });
        } else {
            setCreateBlogFrom("");
            setFormValue({
                category: -1,
                mode: "",
                website: "",
                range: "",
                keyword: "",
                url: "",
                sheetName: "",
                timestamp: false,
            });
            setIsSuccess(false);
            setListUrl([]);
            if (status && blogInfo._id) {
                console.log("xxx");
                try {
                    toast("Web đang được build", {
                        position: "top-center",
                    });
                    let updateBlogInfo = await updateStatusByIdApi({
                        id: blogInfo._id,
                        status: status,
                        listOutput,
                    });
                    if (
                        updateBlogInfo.status ==
                            Constants.BLOG_CRAWL_NEED_UPLOAD ||
                        updateBlogInfo.status == Constants.BLOG_CRAWL_SUCCESS
                    ) {
                        toast("Web đã build xong", {
                            position: "top-center",
                        });
                    }
                    setBlogInfo(null);
                } catch (error) {
                    toast("Server bị lỗi", {
                        position: "top-center",
                    });
                    console.log("error", error);
                }
            }
            setListOutput([]);
        }
    };
    const handleChangeCheckBox = (ele: any) => {
        let listYoutubeUrl = listOutput.map((el: any) => {
            return el.youtubeUrl;
        });
        if (listYoutubeUrl.includes(ele.youtubeUrl)) {
            setListOutput([
                ...listOutput.filter(
                    (el: any) => el.youtubeUrl != ele.youtubeUrl
                ),
            ]);
        } else {
            setListOutput([...listOutput, ele]);
        }
    };
    useEffect(() => {
        setFormValue({
            ...formValue,
            category: "",
        });
        if (formValue.website) {
            let urlValue =
                "https://" +
                formValue.website.split("//")[1].split("/")[0] +
                "/wp-json/wp/v2/categories?per_page=100";
            const getCategories = async () => {
                let categories = await getCategoriesApi(urlValue);
                categories = categories.map((el: any) => {
                    return {
                        name: el.name,
                        id: el.id,
                    };
                });
                setListCategory(categories);
            };
            getCategories();
        }
    }, [formValue.website]);
    useEffect(() => {
        if (loading) {
            checkBlogCrawled();
        }
    }, [loading, blogInfo]);
    const renderAction = () => {
        if (createBlogFrom.length > 0 && !loading) {
            return (
                <div className="list-action">
                    {isSuccess && createBlogFrom == "youtube" && (
                        <ButtonMaterial
                            onClick={() =>
                                clickButtonCancelAndReset(
                                    Constants.BLOG_CRAWL_ERROR
                                )
                            }
                        >
                            Cancel
                        </ButtonMaterial>
                    )}
                    {((isSuccess && listUrl.length > 0) ||
                        !isSuccess ||
                        createBlogFrom == "paa") && (
                        <ButtonMaterial
                            onClick={() =>
                                clickButtonCancelAndReset(
                                    isSuccess &&
                                        createBlogFrom == "youtube" &&
                                        Constants.BLOG_CRAWL_NEED_UPLOAD
                                )
                            }
                        >
                            {isSuccess && createBlogFrom == "youtube"
                                ? "Build"
                                : "Reset"}
                        </ButtonMaterial>
                    )}
                    {(isSuccess == false || createBlogFrom == "paa") && (
                        <ButtonMaterial onClick={() => handleSubmit()}>
                            Submit
                        </ButtonMaterial>
                    )}
                </div>
            );
        }
        return null;
    };
    const renderContent = () => {
        if (loading) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            );
        }
        return (
            <>
                {!isSuccess || createBlogFrom == "paa" ? (
                    <div className="content-container">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Tạo blog
                            </InputLabel>
                            <Select
                                id="demo-simple-select"
                                labelId="demo-simple-select-label"
                                value={createBlogFrom}
                                label="Tạo blog"
                                onChange={(event) =>
                                    setCreateBlogFrom(event.target.value)
                                }
                            >
                                <MenuItem value="youtube">
                                    Tạo blog từ Youtube keywords{" "}
                                </MenuItem>
                                <MenuItem value="paa">Tạo blog từ PAA</MenuItem>
                            </Select>
                            {createBlogFrom === "youtube" ? (
                                <YoutubeForm
                                    formValue={formValue}
                                    listCategory={listCategory}
                                    setFormValue={(value: any) =>
                                        setFormValue(value)
                                    }
                                />
                            ) : (
                                createBlogFrom === "paa" && (
                                    <PAAForm
                                        listCategory={listCategory}
                                        formValue={formValue}
                                        setFormValue={(value: any) =>
                                            setFormValue(value)
                                        }
                                    />
                                )
                            )}
                        </FormControl>
                    </div>
                ) : (
                    <div className="results">
                        {listUrl.length == 0 ? (
                            <div>Không tìm thấy video phù hợp</div>
                        ) : (
                            <>
                                <div>Link Videos</div>
                                {listUrl.map((el: any, index: number) => {
                                    return (
                                        <div className="list-video">
                                            <Checkbox
                                                checked={listOutput.find(
                                                    (ele: any) =>
                                                        ele.youtubeUrl ==
                                                        el.youtubeUrl
                                                )}
                                                onChange={() =>
                                                    handleChangeCheckBox(el)
                                                }
                                            />
                                            <iframe
                                                allowFullScreen
                                                frameBorder="0"
                                                src={el.youtubeUrl.replace(
                                                    "watch?v=",
                                                    "embed/"
                                                )}
                                                width="80%"
                                                height="200px"
                                                key={index}
                                                className="youtube-iframe"
                                            ></iframe>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };
    return (
        <React.Fragment>
            <div className="dialog-form">
                <DialogTitle className={"title-dialog"}>
                    {isSuccess
                        ? "Kết quả"
                        : loading
                        ? "Đang crawl dữ liệu"
                        : "Tạo Blog"}
                </DialogTitle>
                <DialogContent>
                    {renderContent && renderContent()}
                </DialogContent>

                {renderAction && (
                    <DialogActions>{renderAction()}</DialogActions>
                )}
            </div>
        </React.Fragment>
    );
};
const YoutubeForm: FC<{
    formValue: FormValue;
    setFormValue: any;
    listCategory: any;
}> = ({ formValue, setFormValue, listCategory }) => {
    return (
        <div className="youtube-form-container">
            <TextField
                fullWidth
                id="outlined-basic"
                value={formValue.keyword}
                onChange={(event) =>
                    setFormValue({
                        ...formValue,
                        keyword: event.target.value,
                    })
                }
                label="Keyword hoặc url"
                variant="outlined"
            />
            <FormControl fullWidth className="website">
                <InputLabel id="demo-simple-select-label">Timestamp</InputLabel>
                <Select
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    value={formValue.timestamp}
                    label="Timestamp"
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            timestamp: event.target.value,
                        })
                    }
                >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth className="website">
                <InputLabel id="demo-simple-select-label">Website</InputLabel>
                <Select
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    value={formValue.website}
                    label="Website"
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            website: event.target.value,
                        })
                    }
                >
                    {lIST_WEB.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.url}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth className="website">
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    value={formValue.category}
                    label="Website"
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            category: event.target.value,
                        })
                    }
                >
                    {listCategory.map((item: any, index: number) => {
                        return (
                            <MenuItem key={index} value={item.id}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};
const PAAForm: FC<{
    formValue: FormValue;
    setFormValue: any;
    listCategory: any;
}> = ({ formValue, setFormValue, listCategory }) => {
    return (
        <div className="paa-form-container">
            <div className="button-container">
                <TextField
                    fullWidth
                    label="Link google sheet"
                    variant="outlined"
                    value={formValue.url}
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            url: event.target.value,
                        })
                    }
                />
            </div>
            <div className="button-container">
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Sheet name"
                    variant="outlined"
                    value={formValue.sheetName}
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            sheetName: event.target.value,
                        })
                    }
                />
            </div>
            <div className="button-container">
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Cell Range"
                    variant="outlined"
                    value={formValue.range}
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            range: event.target.value,
                        })
                    }
                />
            </div>
            <FormControl fullWidth className="website">
                <InputLabel id="demo-simple-select-label">Website</InputLabel>
                <Select
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    value={formValue.website}
                    label="Website"
                    onChange={(event) =>
                        setFormValue({
                            ...formValue,
                            website: event.target.value,
                        })
                    }
                >
                    {lIST_WEB.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.url}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <div className="second-row">
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Category
                    </InputLabel>
                    <Select
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        value={formValue.category}
                        label="Website"
                        onChange={(event) =>
                            setFormValue({
                                ...formValue,
                                category: event.target.value,
                            })
                        }
                    >
                        {listCategory.map((item: any, index: number) => {
                            return (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};
export default CreateBlogPopup;
