import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import { FC } from "react";

const RatingItem: FC<{
    icon?: any;
    value: number;
    total: number;
    color: string;
    title: string;
    open?: boolean;
    onCollapse?: () => void;
}> = ({ icon, value, total, color, title, open, onCollapse }) => {
    return (
        <div className="item">
            {icon && (
                <div>
                    <img src={icon} />
                </div>
            )}
            <div className="right">
                <div className="title">{title}</div>
                <div className="details">
                    <span className="score">
                        {Math.ceil(value * 100) / 100} / {total}
                    </span>
                    <span
                        className="u-progress"
                        style={{
                            width: (value / total > 1 ? 1 : value / total) * 100 + "%",
                            backgroundColor: color,
                        }}
                    ></span>
                </div>
            </div>
            <div style={{ width: 100 }}>
                {onCollapse && (
                    <Button onClick={onCollapse}>
                        <FontAwesomeIcon icon={open ? (faAngleUp as IconProp) : (faAngleDown as IconProp)} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default RatingItem;
