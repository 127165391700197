import { ProblemReporting } from "../../shared/models/problemReporting";
import {
    IProblemReportingAction,
    ProblemReportingTypes,
} from "../actions/problemReporting.action";

export interface IProblemReportingState {
    loading: boolean;
    problemReportings: ProblemReporting[];
}

const initState: IProblemReportingState = {
    loading: false,
    problemReportings: [],
};

const problemReportingState = (
    state = initState,
    action: IProblemReportingAction
) => {
    switch (action.type) {
        case ProblemReportingTypes.GET_ALL_PROBLEM:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case ProblemReportingTypes.GET_ALL_PROBLEM_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    problemReportings: action.problems ?? [],
                },
            };
        case ProblemReportingTypes.ADD_PROBLEM_SUCCESS:
            if (action.problem) {
                state.problemReportings.push(action.problem);
            }
            return {
                ...state,
            };
        case ProblemReportingTypes.UPDATE_PROBLEM_SUCCESS:
            if (action.problem) {
                const key = action.problem._id;
                state.problemReportings = state.problemReportings.map((e) => e._id === key ? action.problem as ProblemReporting : e)
            }
            return {
                ...state,
            };

        case ProblemReportingTypes.DELETE_PROBLEM_SUCCESS:
            if (action.problem) {
                const key = action.problem._id;
                const newData = state.problemReportings.filter(item => item._id !== key);
                state.problemReportings = newData;
            }
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default problemReportingState;
