import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { IProject } from "../../../shared/models/project";
import { ITask } from "../../../shared/models/task";
import "./style.scss";
import operation_icon from "../../../assets/icon/operation.svg";
import worksheet_icon from "../../../assets/icon/worksheet.svg";

const DashboardProjectsFilter: FC<{
    currentProjectIds: string[];
    onSelectedProject: (id: string) => void;
    isNewVersion?: boolean;
}> = ({ currentProjectIds = [], onSelectedProject, isNewVersion = false }) => {
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const projects: IProject[] = useSelector(
        (state: any) => state.projectState.projects ?? []
    );
    let sortProjects: (IProject & { tasksLength: number })[] = [];
    if (projects) {
        sortProjects = projects
            .map((project) => ({
                ...project,
                tasksLength: tasks?.filter(
                    (task) =>
                        task.projectId === project._id &&
                        task.type !== "Sub Task"
                ).length,
            }))
            .sort((a, b) => (a.tasksLength > b.tasksLength ? -1 : 1))
            .filter((project) => project.tasksLength !== 0);
        for (let i = 0; i < sortProjects.length; i++) {
            if (sortProjects[i].title === "Worksheet" && i != 0) {
                let tmp = sortProjects[i];
                sortProjects[i] = sortProjects[0];
                sortProjects[0] = tmp;
            } else if (sortProjects[i].title === "Operation" && i != 1) {
                let tmp = sortProjects[i];
                sortProjects[i] = sortProjects[1];
                sortProjects[1] = tmp;
            }
        }
    }
    const ref = useRef<HTMLDivElement | null>(null);
    if (isNewVersion)
        return (
            <div className="projects-filter-v2">
                <span className="white">Projects</span>
                {sortProjects
                    .filter((p) => p)
                    .map((project) => (
                        <div
                            className={
                                "project-v2 background-white-008 radius-8 white align-item-center justify-content-center " +
                                (currentProjectIds.indexOf(project?._id) > -1
                                    ? "wrap-active"
                                    : "wrap")
                            }
                            onClick={() => {
                                onSelectedProject(project?._id);
                            }}
                            key={project?._id}
                        >
                            {project?.title?.toLocaleLowerCase() ==
                            "worksheet" ? (
                                <img src={worksheet_icon} />
                            ) : project?.title?.toLocaleLowerCase() ==
                              "operation" ? (
                                <img src={operation_icon} />
                            ) : (
                                <></>
                            )}
                            {project?.title}
                        </div>
                    ))}
            </div>
        );
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
                onClick={() =>
                    ref.current?.scroll({ behavior: "smooth", left: 0 })
                }
            >
                <ArrowBackIosIcon />
            </IconButton>
            <div className="projects-filter" ref={ref}>
                {sortProjects.map((project) => (
                    <div
                        className="project"
                        onClick={() => {
                            onSelectedProject(project._id);
                        }}
                        key={project._id}
                    >
                        <div
                            className={
                                currentProjectIds.indexOf(project._id) > -1
                                    ? "wrap-active"
                                    : "wrap"
                            }
                        >
                            <p>
                                {project.title} ({project.tasksLength})
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <IconButton
                onClick={() =>
                    ref.current?.scroll({
                        behavior: "smooth",
                        left: ref.current?.clientWidth ?? 1000,
                    })
                }
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
    );
};

export default DashboardProjectsFilter;
