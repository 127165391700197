import './style.scss';

const Posts = ({ posts }: any) => {

    const clickPost = (post_name: any) => {
        window.open(`/wiki/showpost/${post_name}`);
    }

    return (
        <div className="posts">
            {posts.map((post: any, index: number) => {
                return (
                    <div className="post" key={index} onClick={() => { clickPost(post.post_name) }}>
                        &bull; {post.post_title}
                    </div>
                )
            })}
        </div>
    )
}

export default Posts;