import { FC, Ref, forwardRef } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FailedReason, IFailedReason } from "../../../../../shared/models/setting";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import { isLeaderEditPoint } from "../../../../../shared/permissions";
import { isAdmin } from "../../../../../util";
import MyTextBox from "../../../widgets/MyTextBox";

export interface PointProps {
    point?: number;
    consumedPoint?: number;
    confirmedPoint?: number;
    failedReason?: string;
    expectPoints: number
}
const ConfirmedPoints: FC<{
    points: PointProps;
    disable: boolean;
    user: IUserInfo
    onChange: (value: PointProps) => void;
}> = ({ points, onChange, disable, user }) => {
    const check =
        typeof points.confirmedPoint !== "undefined" ||
        typeof points.consumedPoint !== "undefined" ||
        (typeof points.failedReason !== "undefined" && !!points.failedReason);
    // const [showPointMoreInfo, setShowPointMoreInfo] = useState(check);
    console.log("user", user);
    
    return (
        <>
            <div className="item item-inline points">
                <h5>Estimated Points</h5>
                <div className="point">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MyTextBox
                            text={points.point + ""}
                            title="estimatedPoint"
                            type="number"
                            min={0}
                            disabled={points.point === undefined || points.point === null ? false : disable}
                            onChange={(value) => {
                                let _val: any;
                                try {
                                    _val = parseInt(value);
                                } catch (e) {}
                                if (!Number.isNaN(_val)) {
                                    if (!validatePoint(_val)) {
                                        return;
                                    }
                                    onChange({
                                        ...points,
                                        point: _val,
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            { (isLeaderEditPoint(user) || isAdmin(user)) && (
                <div className="item item-inline points">
                    <h5>Expect Points</h5>
                    <MyTextBox
                        text={points.expectPoints + ""}
                        title="expectPoints"
                        type="number"
                        min={0}
                        onChange={(value) => {
                            let _val: any;
                            try {
                                _val = parseInt(value);
                            } catch (e) {}
                            if (!Number.isNaN(_val)) {
                                // if (!validatePoint(_val)) {
                                //     return;
                                // }
                                onChange({
                                    ...points,
                                    expectPoints: _val,
                                });
                            }
                        }}
                    />
                </div>
            )}
            {/* {showPointMoreInfo && (
                <>
                    <div className="item item-inline points">
                        <h5>Consumed points</h5>
                        <MyTextBox
                            text={points.consumedPoint + ""}
                            title="consumedPoint"
                            type="number"
                            min={0}
                            disabled={points.consumedPoint === undefined ? false : disable}
                            onChange={(value) => {
                                let _val: any;
                                try {
                                    _val = parseInt(value);
                                } catch (e) {}
                                if (!Number.isNaN(_val)) {
                                    if (!validatePoint(_val)) {
                                        return;
                                    }
                                    onChange({
                                        ...points,
                                        consumedPoint: _val,
                                    });
                                }
                            }}
                        />
                    </div>
                    <div className="item item-inline points">
                        <h5>Confirmed points</h5>
                        <MyTextBox
                            text={points.confirmedPoint + ""}
                            title="confirmedPoint"
                            type="number"
                            min={0}
                            disabled={points.confirmedPoint === undefined ? false : disable}
                            onChange={(value) => {
                                let _val: any;
                                try {
                                    _val = parseInt(value);
                                } catch (e) {}
                                if (!Number.isNaN(_val)) {
                                    if (!validatePoint(_val)) {
                                        return;
                                    }
                                    onChange({
                                        ...points,
                                        confirmedPoint: _val,
                                    });
                                }
                            }}
                        />
                    </div>
                    {points.consumedPoint && points.point && points.consumedPoint > points.point && (
                        <div className="item item-inline failed-reason">
                            <h5>Failed reason</h5>
                            <SelectFailedReason
                                id={points.failedReason}
                                onChange={(value) => {
                                    onChange({
                                        ...points,
                                        failedReason: value,
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            )} */}
        </>
    );
};

const SelectFailedReason = forwardRef<
    HTMLSelectElement,
    {
        id: string | undefined;
        onChange: (value: string) => void;
    }
>(({ id, onChange }, ref: Ref<HTMLSelectElement>) => {
    const failedReasons: IFailedReason[] = useSelector((state: any) => {
        if (state.settingState?.data?.failedReasons) {
            return (
                state.settingState?.data?.failedReasons?.map((item: any) => {
                    return new FailedReason(item);
                }) ?? []
            );
        }
        return [];
    });
    return (
        <Form.Select
            ref={ref}
            className="select-failed-reason"
            defaultValue={id}
            onChange={(e) => {
                const id = e.target.value;
                onChange(failedReasons.find((a) => a._id === id)?.name ?? "");
            }}
        >
            <option value=""></option>
            {failedReasons.map((failedReason) => (
                <option value={failedReason._id} key={failedReason._id} title={failedReason.description}>
                    {failedReason.name}
                </option>
            ))}
        </Form.Select>
    );
});

export const validatePoint = (_val: number): boolean => {
    if (_val > 8) {
        toast(() => <div>Point phải &le; 8 hoặc chia nhỏ task!</div>, {
            position: "top-center",
            type: "error",
            autoClose: 1000,
        });
        return false;
    }
    if (_val < 0) {
        toast(() => <div>Point phải &gt; 0</div>, {
            position: "top-center",
            type: "error",
            autoClose: 1000,
        });
        return false;
    }
    return true;
};
export default ConfirmedPoints;
