import { convertDate } from "../utils"
import { IBasic } from "./basic"
import Config from "./config"

export interface ISprint extends IBasic {
    name: string
    startDate: Date
    endDate: Date
    activeSprint: boolean
    backLog: boolean
    status: number
    shortId: string
}

export class Sprint implements ISprint {
    _id: any
    name: string
    startDate: Date
    endDate: Date
    activeSprint: boolean
    backLog: boolean
    status: number
    shortId: string

    constructor(props: any = {}) {
        this._id = props._id;
        this.name = props.name ?? "";
        this.activeSprint = !!props.activeSprint;
        this.backLog = !!props.backLog;
        this.startDate = convertDate(props.startDate) ?? new Date();
        this.endDate = convertDate(props.endDate) ?? new Date();
        this.status = props.status ?? Config.STATUS.PUBLIC.id;
        this.shortId = props.shortId ?? "";
    }
}