import { IBasic } from "./basic";
import { ITask } from "./task";
import { IExpectations } from "./userExpectation";
import { IUserInfo, UserInfo } from "./userInfo";

export interface ISprintReportItem extends IBasic {
    _id: string;
    taskID: string;
    taskName: string;
    point: number | null | undefined;
    task?: ITask;
    status?: string;
    assignee?: IUserInfo;
    checkPoint?: boolean;
}

export interface ISprintTask {
    //report-v2
    taskID: string;
    taskName: string;
    point: number;
    coef: number;
    state: -1 | 0 | 1 | 2;
    status: string;
    taskType?: string; // merge code xong thi bo ? di
    checkPoint: boolean;
}
export class SprintTask {
    //report-v2
    taskID: string;
    taskName: string;
    point: number;
    coef: number;
    state: -1 | 0 | 1 | 2;
    status: string;
    taskType?: string;
    checkPoint: boolean;
    //-1: prev sprint | 0: not done in sprint | 1: done out of deadline in sprint | 2: done in deadline in sprint
    constructor(props: ISprintTask) {
        this.taskID = props.taskID;
        this.taskName = props.taskName;
        this.point = props.point;
        this.coef = props.coef;
        this.state = props.state;
        this.status = props.status;
        this.taskType = props.taskType;
        this.checkPoint = props.checkPoint;
    }
}
export interface IDiscipline {
    minusScore: number;
    offDaysUnauthorized: string[];
    workingTime: string[];
    offDay: number;
}

export interface ISprintReportV2 {
    sprintId: string;
    sprintTasks: ISprintTask[];
    discipline: IDiscipline[];
    expectations: IExpectations;
}

export class SprintReportItem implements ISprintReportItem {
    _id: string;
    taskID: string;
    taskName: string;
    status: string;
    point: number | null | undefined;
    checkPoint?: boolean | undefined;

    constructor(props: any = {}) {
        this._id = props._id;
        this.taskID = props.taskID;
        this.taskName = props.taskName;
        this.status = props.status ?? "";
        this.point = props.point;
        this.checkPoint = props.checkPoint ?? false;
    }
}

export interface ISprintReport extends IBasic {
    _id: string;
    totalTasks: ISprintReportItem[];
    finishedTasks: ISprintReportItem[];
    bugTasks: ISprintReportItem[];
    sprint: string;
    userId: string;
    user?: IUserInfo;
    lastUpdate: Date | null;
}

export class SprintReport implements ISprintReport {
    _id: any;
    totalTasks: ISprintReportItem[];
    finishedTasks: ISprintReportItem[];
    bugTasks: ISprintReportItem[];
    sprint: string;
    userId: string;
    user?: IUserInfo | undefined;
    lastUpdate: Date | null;
    constructor(props: any = {}) {
        this._id = props._id;
        this.sprint = props.sprint;
        this.userId = props.userId;
        if (props.user && props.user[0] && typeof props.user[0] === "object") {
            this.user = new UserInfo(props.user[0]);
            this.userId = this.user._id;
        } else if (typeof props.user === "object") {
            this.user = new UserInfo(props.user);
            this.userId = this.user._id;
        } else if (props.user && typeof props.user === "string") {
            this.userId = props.user;
        }
        if (props.userId && typeof props.userId === "string") {
            this.userId = props.userId;
        }
        if (props.userID && typeof props.userID === "string") {
            this.userId = props.userID;
        }
        this.lastUpdate = props.lastUpdate ? new Date(props.lastUpdate) : null;
        const totalTasks: ISprintReportItem[] = [];
        if (props.totalTasks) {
            for (let item of props.totalTasks) {
                totalTasks.push(new SprintReportItem(item));
            }
        }
        this.totalTasks = totalTasks;
        const finishedTasks: ISprintReportItem[] = [];
        if (props.finishedTasks) {
            for (let item of props.finishedTasks) {
                finishedTasks.push(new SprintReportItem(item));
            }
        }
        this.finishedTasks = finishedTasks;
        const bugTasks: ISprintReportItem[] = [];
        if (props.bugTasks) {
            for (let item of props.bugTasks) {
                bugTasks.push(new SprintReportItem(item));
            }
        }
        this.bugTasks = bugTasks;
    }

    toJson() {
        return {
            sprint: this.sprint,
            user: this.user ?? this.userId,
            finishedTasks: this.finishedTasks,
            totalTasks: this.totalTasks,
            lastUpdate: this.lastUpdate,
        };
    }
}
