/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import axios from "axios";
import Url from "../../util/url";
import "./index.css"
const AnyReactComponent = ({ text,icon }) => <div>{icon}{text}</div>;

const KEY = "AIzaSyDUvCNYMfmeNVLHeCSVhkMNCAxp8CHRRVg";

export default function simpleMap(){
    const defaultProps = {
        center: {
        lat: 	20.990895324137906, 
        lng: 105.78380215367454,
        },
        zoom: 15
    };

    const [userPositions, setUserPositions] = useState();

    useEffect(() =>{
        const getUserInfos = async () => {
            const {data : response} = await axios.get(Url("user/not-disable"));
            return response.response;
        }

        const getUserPositions = async (users) => {
            let userData = []
            for (let user of users) {
                if (user.address) {
                    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${user.address}&key=${KEY}`;
                    const {data : resTotal} = await axios.get(url);
                    if(resTotal?.results[0]){
                        const data = {
                            name: user.firstName,
                            response: resTotal.results[0],
                            avatar : user.avatar
                        }
                        userData.push(data)
                    }
                }
            }
            return userData;
        }

        getUserInfos().then((users) => {
            getUserPositions(users).then((data) => {
                setUserPositions(data);
            });
        });
    }, []);

    return (
    <div className="map">
        <GoogleMapReact
            bootstrapURLKeys={{ key: KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
        {userPositions?.map((user, index) => {
            return <AnyReactComponent
                key={"item-" + index}
                lat={user?.response?.geometry?.location?.lat}
                lng={user?.response?.geometry?.location?.lng}
                icon={<div className="img_ripple"><img src={user?.avatar} alt="avatar"/></div>}
                text={<p className="name" >{user?.name}</p>}
                />
            })} 
            </GoogleMapReact>
        </div>
    );
}