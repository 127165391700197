import { convertDate } from "../utils";
import { IBasic } from "./basic";
export interface IWfhDayOffDay extends IBasic {
    date: Date;
    slot: string;
    reason: string;
    userId: string;
    type: string;
    offType: string;
}
export class WfhDayOffDay implements IWfhDayOffDay {
    _id: any;
    date: Date;
    slot: string;
    reason: string;
    userId: string;
    type: "dayoff" | "wfh";
    offType: string;
    constructor(props: any = {}) {
        this._id = props.id;
        this.date = convertDate(props.date) ?? new Date();
        this.slot = props.slot ?? "";
        this.reason = props.reason ?? "";
        this.userId = props.userId ?? "";
        this.type = props.type;
        this.offType = props.offType;
    }
}
