import { convertDate } from "../utils";
import Config from "./config";

export interface IWorkingTime {
    _id?: any;
    slot?: string;
    date: Date;
    checkin: string;
    checkout: string;
    userId: string;
}

export class WorkingTime implements IWorkingTime {
    _id: any;
    date: Date;
    checkin: string;
    checkout: string;
    userId: string;
    slot: string;

    constructor(props: any = {}) {
        this._id = props.id;
        this.slot = props.slot ?? Config.ALL_DAY_VNESE;
        this.date = convertDate(props.date) ?? new Date();
        this.checkin = props.checkin ?? "";
        this.checkout = props.checkout ?? "";
        this.userId = props.userId ?? "";
    }
}
