import { Button } from 'react-bootstrap';
import './style.scss';
import { Redirect, useHistory } from "react-router";

const ManageDevice = () => {
    const history = useHistory();
    return (
        <>
            <Button  variant='primary' 
                        onClick={() => {
                                history.push({
                                    pathname: "/manage-device",
                                });
                                <Redirect to="/manage-device" />;
                            }}
            >
                Quản lý thiết bị
            </Button>
        </>
    )
}

export default ManageDevice