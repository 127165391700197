import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import { MANAGE_DEVICE_TYPE } from '../../../../redux/reducers/manageDevice.reducer';
import Url from '../../../../util/url';

export async function getManageDevicesApi() {
  const { data } = await axios.get(
    Url('manage-device/get-manage-devices')
  );
  return data;
}

function* getManageDevices(action) {
  try {
    const data = yield call(getManageDevicesApi);
    yield put({ type: MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE_SUCCESS, data });
  } catch (error) {
    console.log(error);
    yield put({ type: MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE_FAILED });
  }
}

export function* watchGetManageDevice() {
  yield takeLatest(MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE, getManageDevices);
}
