import { ArrowDropDown } from "@material-ui/icons";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { updateDateTimeAction } from "../../../redux/actions/dashboard.action";
import { getDateTime, getDayofWeek } from "../../../util";
import ChartIconSvg from "../icon/ChartIcon";
import "./index.scss";

export default function DateRangView() {
    const dispatch = useDispatch();
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const typeInfo = useSelector((state: any) => state.dashBoardState.typeInfo);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [state, setState] = useState([
        {
            startDate: new Date(startTime),
            endDate: new Date(endTime),
            key: "selection",
        },
    ]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const getDate = () => {
        return (
            getDayofWeek(state[0].startDate, true) +
            " - " +
            getDayofWeek(state[0].endDate, true, true)
        );
    };
    const startDay = getDayofWeek(startTime);
    const endDay = getDayofWeek(endTime);
    // useEffect(() => {
    //     if (!!!typeInfo) {
    //         dispatch(
    //             updateDateTimeAction(
    //                 getDateTime() - 30 * 24 * 60 * 60 * 1000,
    //                 getDateTime() - 2 * 24 * 60 * 60 * 1000
    //             )
    //         );
    //     } else {
    //         dispatch(
    //             updateDateTimeAction(
    //                 getDateTime() - 30 * 24 * 60 * 60 * 1000,
    //                 getDateTime()
    //             )
    //         );
    //     }
    // }, [typeInfo]);
    return (
        <>
            <div className="days">{startDay + " - " + endDay}</div>
            <Button
                aria-describedby={id}
                variant="contained"
                className="btn-date-custom"
                onClick={handleClick}
            >
                {getDate()}
                <ArrowDropDown />
            </Button>
            <ChartIconSvg />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <DateRangePicker
                        onChange={(item: any) => {
                            const startDate =
                                item.selection.startDate.getTime();
                            const endDate = item.selection.endDate.getTime();
                            setState([item.selection]);

                            dispatch(updateDateTimeAction(startDate, endDate));
                            if (startDate < endDate) {
                                handleClose();
                            }
                        }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        showDateDisplay={false}
                        ranges={state}
                        direction="horizontal"
                        disabledDates={
                            !!!typeInfo
                                ? [
                                      new Date(),
                                      new Date(
                                          new Date().setTime(
                                              new Date().getTime() -
                                                  24 * 60 * 60 * 1000 * 1
                                          )
                                      ),
                                  ]
                                : []
                        }
                    />
                </Typography>
            </Popover>
        </>
    );
}
