import React, { useEffect, useState } from "react";
import FormControl from "react-bootstrap/FormControl";

type Props = {
    title: string;
    disabled?: boolean;
    type?: string;
    text: string;
    min?: number;
    onChange?: (value: string) => void;
    onChanging?: (value: string) => void;
};
const MyTextBox = React.forwardRef<HTMLInputElement, Props>(
    ({ title, disabled, type, text, min, onChange = () => {}, onChanging = () => {} }, ref: React.Ref<HTMLInputElement>) => {
        text = (text ?? "") + "";
        const [value, setValue] = useState(text);
        useEffect(() => {
            setValue(text);
        }, [text]);
        return (
            <FormControl
                ref={ref ?? null}
                disabled={disabled}
                type={type ?? "text"}
                name={title ?? ""}
                value={value}
                min={min}
                onKeyDown={(e) => {
                    if (e.code == "Enter") {
                        onChange(value);
                    }
                }}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChanging(e.target.value);
                }}
                onBlur={(e) => onChange(value)}
            />
        );
    }
);

export default MyTextBox;
