import { IAppVersion } from "../../shared/models/appVersion";
import {
    AppVersionTypes,
    IAppVersionAction,
} from "../actions/appVersion.action";

export interface IAppVersionState {
    loading: boolean;
    appVersions?: IAppVersion[];
    error?: any;
    newAddInfoVersion?: IAppVersion[] | any;
}

const initState: IAppVersionState = {
    loading: false,
    newAddInfoVersion: [],
};

const appVersionState = (
    state = initState,
    action: IAppVersionAction
): IAppVersionState => {
    switch (action.type) {
        case AppVersionTypes.GET_APP_VERSION:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case AppVersionTypes.GET_INFO_VERSION:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case AppVersionTypes.ADD_INFO_VERSION:
            return {
                ...state,
            };
        case AppVersionTypes.DELETE_INFO_VERSION:
            return {
                ...state,
            };
        case AppVersionTypes.UPDATE_INFO_VERSION:
            return {
                ...state,
            };
        case AppVersionTypes.GET_APP_VERSION_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    appVersions: action.appVersions ?? [],
                },
            };
        case AppVersionTypes.GET_INFO_VERSION_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    newAddInfoVersion: action.newAddInfoVersion ?? [],
                },
            };
        case AppVersionTypes.ADD_INFO_VERSION_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    newAddInfoVersion:
                        [
                            ...state.newAddInfoVersion,
                            action.newAddInfoVersion,
                        ] ?? [],
                },
            };
        case AppVersionTypes.GET_APP_VERSION_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case AppVersionTypes.GET_INFO_VERSION_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case AppVersionTypes.ADD_INFO_VERSION_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case AppVersionTypes.UPDATE_APP_VERSION_SUCCESS:
            if (action.appVersion && state.appVersions) {
                let index = state.appVersions.findIndex(
                    (item) => item._id == action.appVersion?._id
                );
                if (index > -1) {
                    state.appVersions[index] = action.appVersion;
                } else {
                    state.appVersions.push(action.appVersion);
                }
            }
            return {
                ...state,
            };
        case AppVersionTypes.UPDATE_INFO_VERSION_SUCCESS:
            if (action.newAddInfoVersion && state.newAddInfoVersion) {
                let index = state.newAddInfoVersion.findIndex(
                    (item: any) => item._id == action.newAddInfoVersion?._id
                );
                if (index > -1) {
                    state.newAddInfoVersion[index] = action.newAddInfoVersion;
                } else {
                    state.newAddInfoVersion.push(action.newAddInfoVersion);
                }
            }
            return {
                ...state,
            };
        case AppVersionTypes.DELETE_APP_VERSION_SUCCESS:
            if (action.appVersion && state.appVersions) {
                state.appVersions = state.appVersions.filter(
                    (item) => item._id != action.appVersion?._id
                );
            }
            return {
                ...state,
            };
        case AppVersionTypes.DELETE_INFO_VERSION_SUCCESS:
            if (action.newAddInfoVersion && state.newAddInfoVersion) {
                state.newAddInfoVersion = state.newAddInfoVersion.filter(
                    (item: any) => item._id != action.newAddInfoVersion?._id
                );
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default appVersionState;
