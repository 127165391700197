import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteRenderOptionState,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import {
    deleteUserGroup,
    updateUserGroup,
} from "../../../redux/actions/userGroup.action";
import {
    IUserGroup,
    IUserInfoInGroup,
    RoleGroup,
    UserGroup,
} from "../../../shared/models/userGroup";
import { IUserInfo } from "../../../shared/models/userInfo";
import { isAdmin, isLeadDev } from "../../../shared/permissions";
import { capitalizeFirstLetter } from "../../../shared/utils";
import { SelectOption, convertUserOptions } from "../../../utils";
import SearchAndSelectUsers from "./SearchAndSelectUser";
import Config from "../../../shared/models/config";
import { Close, Remove } from "@material-ui/icons";

const rolesGroup: RoleGroup[] = ["intern", "member", "lead"];

type OptionType = {
    value: string;
    label: string;
};

const EditAddUserGroupModal: FC<{
    onClose: () => void;
    userGroup: IUserGroup | null;
}> = ({ onClose, userGroup }) => {
    if (!userGroup) {
        userGroup = new UserGroup({});
    }
    const users: IUserInfo[] = useSelector(
        (state: any) => state.userInfoState.userInfos ?? []
    );
    const dispatch = useDispatch();

    const [name, setName] = useState(userGroup?.name ?? "");
    const [description, setDescription] = useState(userGroup?.description ?? "");
    const [listUsers, setListUsers] = useState<IUserInfoInGroup[]>(
        userGroup?.users?.map((user) => {
            user.user = users.find((u) => u._id == user.userId);
            return user;
        }) ?? []
    );

    let showOptions: OptionType[] = [];
    userGroup?.users?.map((user) => {
        if (user.permission !== undefined) {
            showOptions = user.permission.map((dt) => {
                return {
                    value: dt,
                    label: dt,
                };
            });
        }
    });

    const [listPermission, setListPermission] = useState<OptionType[]>(showOptions ?? []);
    //state for validate data
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");

    const validateDate = (data: IUserGroup) => {
        if (data.name) {
            return true;
        }
        if (!data.name) {
            setNameError(true);
            setNameHelperText("Vui lòng nhập tên nhóm");
        }
        return false;
    };
    const handleResetState = () => {
        setName("");
        setDescription("");
        setNameError(false);
        setNameHelperText("");
        setListUsers([]);
    };

    const handleClose = () => {
        handleResetState();
        onClose();
    };
    const handleDelete = () => {
        if (userGroup) {
            if (window.confirm("Xác nhận xóa group")) {
                dispatch(deleteUserGroup(userGroup));
                handleResetState();
                onClose();
            }
        }
    };
    let dataPermission = listPermission?.map((d) => d.value);
    const handleSubmit = () => {
        let listPermissionUpdate: IUserInfoInGroup[] = [];
        listUsers.forEach((u) => {
            listPermissionUpdate.push({
                ...u,
                permission: dataPermission ?? [],
            });
        });

        const data = new UserGroup({
            _id: userGroup?._id,
            name: name,
            description: description,
            users: listPermissionUpdate,
        });

        if (validateDate(data)) {
            dispatch(updateUserGroup(data));
            handleResetState();
            onClose();
        }
    };
    const getDefaultRoleGroup = (user?: IUserInfo): RoleGroup => {
        if (!user) {
            return "member";
        }
        if (isAdmin(user) || isLeadDev(user)) {
            return "lead";
        }
        return "member";
    };
    const userOptions = convertUserOptions(users);

    return (
        <>
            <Dialog open={true} onClose={handleClose} maxWidth="md">
                <DialogTitle>{userGroup ? "Edit user group" : "New user group"}</DialogTitle>
                <DialogContent dividers>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            variant="standard"
                            label="Group name"
                            error={nameError}
                            value={name}
                            helperText={nameHelperText}
                            onChange={(e: any) => {
                                setNameError(false);
                                setNameHelperText("");
                                setName(e.target.value);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            value={description}
                            variant="standard"
                            label="Description"
                            onChange={(e: any) => setDescription(e.target.value)}
                        />
                    </FormControl>
                    <SearchAndSelectUsers
                        ids={listPermission.map((d) => d.value)}
                        onChange={(value) => {
                            const datas = value?.map((d) => {
                                const data = Config.PERMISSIONS_GROUP.find((dt) => dt.value == d);
                                return {
                                    value: data?.value,
                                    label: d,
                                };
                            });
                            setListPermission(datas as []);
                        }}
                        label="Permission"
                        userOptions={Config.PERMISSIONS_GROUP}
                    />
                    <SearchAndSelectUsers
                        ids={listUsers?.map((u) => u.userId)}
                        onChange={(userIds) => {
                            const value: IUserInfoInGroup[] = userIds.map((userId) => {
                                const user = users.find((u) => u._id == userId);
                                return {
                                    userId,
                                    user: user,
                                    role: getDefaultRoleGroup(user),
                                    permission: [],
                                };
                            });
                            setListUsers(value);
                        }}
                        label="Users"
                        userOptions={userOptions}
                    />

                    {listUsers?.map((user) => {
                        const avatar = !!user.user?.avatar ? user.user?.avatar : defaultAvatar;
                        const name = user.user ? user.user.firstName + " " + user.user.lastName : "";
                        return (
                            <ListItem
                                key={user.userId}
                                style={{ borderBottom: "1px solid #ddd" }}
                            >
                                <Avatar
                                    sx={{ marginRight: "10px" }}
                                    alt={avatar}
                                    src={avatar}
                                />
                                <ListItemText primary={name} />
                                <FormControl
                                    variant="standard"
                                    sx={{ width: "150px" }}
                                >
                                    <Select
                                        onChange={(e) => {
                                            const valueIndex = rolesGroup
                                                .map((u) => u.toString())
                                                .indexOf(e.target.value);
                                            const list = [...listUsers];
                                            const index = list.findIndex(
                                                (u) => u.userId == user.userId
                                            );
                                            if (index > -1) {
                                                list[index].role =
                                                    rolesGroup[valueIndex];
                                                setListUsers(list);
                                            }
                                        }}
                                        value={user.role?.toLowerCase()}
                                    >
                                        {rolesGroup.map((role) => {
                                            return (
                                                <MenuItem
                                                    key={"role-item-" + role}
                                                    value={role}
                                                >
                                                    {capitalizeFirstLetter(
                                                        role
                                                    )}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => {
                                    setListUsers(listUsers.filter((a) => a.userId !== user.userId));
                                }}>
                                    <Close />
                                </IconButton>
                            </ListItem>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Submit</Button>
                    {userGroup && <Button onClick={handleDelete}>Delete</Button>}
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

// const renderOption:FC<({ props: any, option: SelectOption, state: AutocompleteRenderOptionState })> = ({props, option, state}) => {
//     const avatar = option.avatar ? option.avatar : defaultAvatar;
//     return (
//         <Box
//             component="li"
//             sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
//             {...props}
//         >
//             <Avatar
//                 alt={avatar}
//                 src={avatar}
//                 sx={{ marginRight: "10px" }}
//             />
//             {option.label}
//         </Box>
//     );
// }

// const onGetOptionsValue = (list: string[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<SelectOption>) => {
//     const id = details?.option?.value;
//     if(reason === 'selectOption' && id){
//         return [...list, id];
//     } else if(reason === 'removeOption'){
//         return list.filter(e => e !== id);
//     } else if(reason === 'clear'){
//         return [];
//     }
//     return list;
// }

// const SearchAndSelectUsers:FC<({ ids: string[], onChange: (ids: string[]) => void })> = ({ ids, onChange }) => {
//     const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
//     const userOptions = convertUserOptions(users);
//     return (
//         <FormControl fullWidth margin="normal">
//             <Autocomplete
//                 multiple
//                 options={userOptions}
//                 filterSelectedOptions
//                 value={userOptions.filter(item => ids.includes(item.value))}
//                 getOptionLabel={(option: SelectOption) => option.label}
//                 renderOption={(props, option, state) => renderOption({ props, option, state })}
//                 onChange={(
//                     event,
//                     value,
//                     reason,
//                     details
//                 ) => {
//                     console.log(1111)
//                     onChange(onGetOptionsValue(ids, reason, details));
//                 }}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         variant="standard"
//                         label="Users"
//                     />
//                 )}
//             />
//         </FormControl>
//     );
// }

export default EditAddUserGroupModal;
