import { FC, useState } from "react";
import {  Modal } from "react-bootstrap";
import MButton from "react-bootstrap/Button";
import Select from "react-select";
import { convertMergeTaskOptions} from "../../../utils";
import { ITask, ITaskHistory, TaskHistory } from "../../../shared/models/task";
import { useDispatch, useSelector } from "react-redux";
import { updateParentTask } from "../../../redux/actions/task.action";
import { IUserInfo } from "../../../shared/models/userInfo";

export interface SelectOption {
    value: string;
    label: string;
    avatar: string;
}

const MergeTaskWidget:FC<{onHide : () => void , listId : string[],setShowCheckBox : (check : boolean) => void}> = ({onHide,listId,setShowCheckBox = () => {}}) => {
    const [selected, setSelected] = useState<SelectOption | undefined>();
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks);
    const dispatch = useDispatch();
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const handleSelectChange = (option: SelectOption | null) => {
        if (option === null) {
            setSelected(undefined);
        } else {
            setSelected(option);
        }
    };
    const listTasFilter: ITask[] = tasks.filter((task) => {
        return listId.includes(task._id);
    });
    const taskFilter = tasks.find((task) => task._id == selected?.value);
    let listTasFilterName = listTasFilter.map((t) => t.title).join(", ");
    const getChangedInfo = ( taskTo: ITask) => {
        let changes: ITaskHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if ( taskTo) {
            changes.push(
                new TaskHistory({
                    field: "Merge Task",
                    from: listTasFilterName,
                    to: taskFilter?.title,
                    userID,
                    time,
                })
            );
        }
        return changes;
    };

    const handelOnSave = () => {
        if(taskFilter){
            const changes = getChangedInfo( taskFilter);
            const historiesTaskTo = { history: (changes ?? []).concat(taskFilter.histories ?? []), id: taskFilter._id };
            const historiesUpdate = [ historiesTaskTo];
            let valueSeclet  = selected?.value;
            if(valueSeclet){
                dispatch(updateParentTask(listId,valueSeclet,historiesUpdate,"merge"))
            }
            onHide();
            setShowCheckBox(false)
        }
    }
    return (
        <>
             <Modal show={true} size="lg" onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>
                        Chọn Task Merge Đến
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    <Select
                        value={selected}
                        options={convertMergeTaskOptions(tasks,listId)}
                        onChange={(newValue) => handleSelectChange(newValue)}
                    />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <MButton onClick={handelOnSave}>Save</MButton>
                    <MButton className="close" aria-label="Close" onClick={onHide} variant="secondary">Close</MButton>
                </Modal.Footer>
             </Modal>
        </>
    )
}
export default MergeTaskWidget ;