import React from "react";

const CloseIconSvg = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.3335 8C1.3335 4.31333 4.3135 1.33333 8.00016 1.33333C11.6868 1.33333 14.6668 4.31333 14.6668 8C14.6668 11.6867 11.6868 14.6667 8.00016 14.6667C4.3135 14.6667 1.3335 11.6867 1.3335 8ZM9.92683 10.8667C10.1868 11.1267 10.6068 11.1267 10.8668 10.8667C11.1202 10.6067 11.1202 10.18 10.8668 9.92667L8.94016 8L10.8668 6.07333C11.1268 5.81333 11.1268 5.39333 10.8668 5.13333C10.6068 4.87333 10.1868 4.87333 9.92683 5.13333L8.00016 7.06L6.0735 5.13333C5.8135 4.87333 5.3935 4.87333 5.1335 5.13333C5.00866 5.25789 4.93851 5.42699 4.93851 5.60333C4.93851 5.77968 5.00866 5.94878 5.1335 6.07333L7.06016 8L5.1335 9.92667C5.00866 10.0512 4.93851 10.2203 4.93851 10.3967C4.93851 10.573 5.00866 10.7421 5.1335 10.8667C5.3935 11.1267 5.8135 11.1267 6.0735 10.8667L8.00016 8.94L9.92683 10.8667Z"
                fill="#495467"
                fillOpacity="0.52"
            />
        </svg>
    );
};

export default CloseIconSvg;
