import { Close } from "@material-ui/icons";
import { Dialog, DialogTitle, IconButton, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllTestSection } from "../../../../redux/actions/testSection.action";
import { IProject } from "../../../../shared/models/project";
import { ITask } from "../../../../shared/models/task";
import { ITestCase } from "../../../../shared/models/testCase";
import { ITestCycle } from "../../../../shared/models/testCycle";
import { IUserInfo } from "../../../../shared/models/userInfo";
import { isAdmin } from "../../../../util";
import AllCycles from "./all-cycles";
import AllTestCases from "./all-test-cases";
import "./style.scss";

const TestCycleModalWidget: FC<{
    open: boolean;
    handleClose: any;
    task: ITask;
}> = ({ open, handleClose, task }) => {
    const [value, setValue] = useState<number>(1);
    const [firstTime, setFirstTime] = useState<boolean>(false);
    const tests: ITestCase[] = useSelector((state: any) => state.tests.data);
    const cycles: ITestCycle[] = useSelector((state: any) => state.cycles.data);
    const dispatch = useDispatch();
    const history = useHistory();

    // Check permission
    const user: IUserInfo = useSelector((state: any) => state.authState.user);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects);
    const permission = checkPermission({ user, task, projects });
    const permissionForDev = checkPermissionForDev(user);
    let selectedTests: ITestCase[] = [];
    let selectedCycles: ITestCycle[] = [];

    if (tests && cycles) {
        selectedTests = tests.filter((test: ITestCase) => test.taskID === task._id);
        selectedCycles = cycles.filter((cycle: ITestCycle) => cycle.taskID === task._id);
    }

    useEffect(() => {
        // fetch all test sections for this case
        dispatch(getAllTestSection(task._id));
        // To open last cycle in the first time open modal
        setFirstTime(true);
    }, []);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);

        // Clear open last cycle
        if (newValue !== 1) {
            setFirstTime(false);
        }
    };

    const handleClick = () => {
        handleClose();
        history.push({ pathname: "/task/" + task._id });
    };

    return (
        <Dialog scroll="body" maxWidth="xl" open={open}>
            <DialogTitle className="test-cycle-header">
                <span>
                    Test cycle for task:{" "}
                    <b className="test-cycle-header__link" onClick={handleClick}>
                        {task.title}
                    </b>
                </span>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <div className="test-cycle">
                <Tabs orientation="vertical" value={value} onChange={handleChange} className="test-cycle__tabs">
                    <Tab label="All Test Cases" />
                    <Tab label="All Cycles" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <AllTestCases tests={selectedTests} cycles={selectedCycles} permission={permission} taskId={task._id} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AllCycles
                        tests={selectedTests}
                        cycles={selectedCycles}
                        firstTime={firstTime}
                        permissionForDev={permissionForDev}
                        permission={permission}
                        taskId={task._id}
                    />
                </TabPanel>
            </div>
        </Dialog>
    );
};

function TabPanel({ children, value, index }: { children: any; value: number; index: number }) {
    return <div>{value === index && <div className="test-cycle__content">{children}</div>}</div>;
}

// Check permission for PO, tester , admin
const checkPermission = ({ user, task, projects }: { user: IUserInfo; task: ITask; projects: IProject[] }) => {
    const sprintProject = projects?.find((project: IProject) => project._id === task.projectId);
    const isTester = user?.position.toLowerCase().indexOf("tester") > -1;
    const isPO = sprintProject?.ownerID === user._id;

    if (isPO || isTester || isAdmin(user)) {
        return true;
    }

    return false;
};

// Check permissions for DEV
const checkPermissionForDev = (user: any) => {
    return user?.position.toLowerCase().indexOf("Developer") > -1;
};

export default TestCycleModalWidget;
