import { convertDate, getUserFromJson } from "../utils";
import { IUserInfo } from "./userInfo";

export interface ICampaign {
  _id: any;
  projectIds: string[];
  sprintId: string;
  title: string;
  description: string;
  target: string;
  listTaskIDs: string[];
  priority: string;
  reporterId: string;
  reporter?: IUserInfo;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  deadline: Date | null;
  createDate: Date;
  lastUpdate: Date;
  donePercent?: number;
  inProgressPercent?: number;
  countTaskDone?: number;
  archive?: boolean;
  userId?: string;
}

export class Campaign implements ICampaign {
  _id: any;
  projectIds: string[];
  sprintId: string;
  title: string;
  description: string;
  target: string;
  listTaskIDs: string[];
  priority: string;
  reporterId: string;
  reporter?: IUserInfo;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  deadline: Date | null;
  createDate: Date;
  lastUpdate: Date;
  donePercent?: number;
  inProgressPercent?: number;
  countTaskDone?: number;
  archive?: boolean;
  userId?: string;
  constructor(props: any) {
    this._id = props._id;
    if (typeof props.projectIds === "string") {
      this.projectIds = JSON.parse(props.projectIds) ?? [];
    } else {
      this.projectIds = props.projectIds ?? [];
    }
    this.sprintId = props.sprintId ?? "";
    this.title = props.title ?? "";
    this.description = props.description ?? "";
    this.target = props.target ?? "";
    if (typeof props.listTaskIDs === "string") {
      this.listTaskIDs = JSON.parse(props.listTaskIDs) ?? [];
    } else {
      this.listTaskIDs = props.listTaskIDs ?? [];
    }
    this.priority = props.priority ?? "";
    let _reporterId = "";
    if (props.reporter && typeof props.reporter === "string") {
      _reporterId = props.reporter;
    } else {
      this.reporter = getUserFromJson(props.reporter);
      _reporterId = this.reporter?._id ?? "";
    }
    if (props.reporterId && typeof props.reporterId === "string") {
      _reporterId = props.reporterId;
    } else if (props.reporterID && typeof props.reporterID === "string") {
      _reporterId = props.reporterID;
    }
    this.reporterId = _reporterId;
    this.status = props.status ?? "";
    this.startDate = convertDate(props.startDate);
    this.endDate = convertDate(props.endDate);
    this.deadline = convertDate(props.deadline);
    this.createDate = convertDate(props.createDate) ?? new Date();
    this.lastUpdate = convertDate(props.lastUpdate) ?? new Date();
    this.archive = !!props.archive;
    this.donePercent = props.donePercent ?? 0;
    this.inProgressPercent = props.inProgressPercent ?? 0;
    this.countTaskDone = props.countTaskDone ?? 0;
    this.userId = props.userId ?? "";
  }

  toJson(): any {
    // convert về dạng dữ liệu cũ
    return {
      _id: this._id,
      projectIds: this.projectIds,
      sprintId: this.sprintId,
      title: this.title,
      description: this.description,
      target: this.target,
      listTaskIDs: this.listTaskIDs,
      priority: this.priority,
      reporter: this.reporterId,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      deadline: this.deadline,
      createDate: this.createDate,
      lastUpdate: this.lastUpdate,
      donePercent: this.donePercent,
      inProgressPercent: this.inProgressPercent,
      countTaskDone: this.countTaskDone,
      userId: this.userId,
    };
  }

  static isObject(item: any): boolean {
    if (item) {
      if (item.constructor.name == this.name) {
        return true;
      }
      const list = [
        "_id",
        "projectIds",
        "sprintId",
        "title",
        "target",
        "listTaskIDs",
        "status",
      ];
      let count = 0;
      const itemList = Object.getOwnPropertyNames(item);
      for (let attr of itemList) {
        if (list.includes(attr)) {
          count++;
        }
      }
      return count == list.length && itemList.length == count;
    }
    return false;
  }
}
