import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React, { useEffect, useState } from "react";
import { getTestProgressAPI, getTopicProgressAPI, updateOnBoardingPass } from "../saga";
import "./progress.scss";
const Progress = ({ email, role }) => {
    const [topicProgress, setTopicProgress] = useState([]);
    const [isUserPass, setIsUserPass] = useState(false);
    const [testProgress, setTestProgress] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getProgress(email);
    }, [email]);
    useEffect(() => {
        const timeout = setTimeout(() => setOpen(false), 1000);
        return () => clearTimeout(timeout);
    }, [open]);

    const getProgress = async (email) => {
        let userId = email;
        let resultTopic = await getTopicProgressAPI(userId);
        let resultTest = await getTestProgressAPI(userId);
        setIsUserPass(resultTopic.pass);
        if (resultTopic.progress) {
            setTopicProgress(resultTopic.progress);
        }
        if (resultTest.progress) {
            setTestProgress(resultTest.progress);
        }
    };
    const acceptPass = (email, status) => {
        setIsUserPass(status);
        updateOnBoardingPass(email, status);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Container>
                {(topicProgress?.length > 0 || testProgress?.length > 0) && (
                    <>
                        {role === 1 && (
                            <div className="user-progress-action">
                                <Button
                                    onClick={() => acceptPass(email, isUserPass ? false : true)}
                                    color={!isUserPass ? "success" : "error"}
                                    className={!isUserPass ? "success" : "error"}
                                >
                                    {!isUserPass ? "Pass Onboarding" : "Undo Pass Onboarding"}
                                </Button>
                            </div>
                        )}
                        <div className={"user-progress "}>
                            <div className="progress-topic">
                                <p className="title">Tiến trình đào tạo</p>
                                {topicProgress?.map((topic, index) => {
                                    return <TopicProgress topic={topic} key={index} />;
                                })}
                            </div>
                            <div className="progress-test">
                                <p className="title">Tiến trình kiểm tra</p>

                                {testProgress?.map((test, index) => {
                                    return <TestProgress test={test} key={index} />;
                                })}
                            </div>
                        </div>
                    </>
                )}
            </Container>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{ padding: "20px 36px" }}
                    >
                        Thành công
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
const TopicProgress = ({ topic }) => {
    let topicName = topic.topicName;
    let progress = topic?.progress * 100 + "%";
    return (
        <div className="topic">
            <p>
                {topicName}: {progress}
            </p>
        </div>
    );
};

const TestProgress = ({ test }) => {
    let testName = test.testName;
    let progress = test?.progress * 100 + "%";
    return (
        <div className="test">
            <p>
                {testName}: {progress}
            </p>
        </div>
    );
};
export default Progress;
