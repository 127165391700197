import { SprintReportTypes } from "../actions/sprintReport.action";

export interface ExpData {
    _id: string;
    reason: string;
    exp: number;
    time: Date;
    by: string;
}
export interface IRewardMinusExp {
    userId: string;
    histories: ExpData[];
}

export interface IRewardMinusExpRaw extends ExpData {
    userId: string;
}
export interface IRewardMinusExpState {
    rewardMinusExp: IRewardMinusExp[];
}
const initState: IRewardMinusExpState = {
    rewardMinusExp: [],
};

const rewardMinusExpState = (state = initState, action: any) => {
    switch (action.type) {
        case SprintReportTypes.GET_REWARD_MINUS_EXP_ACTION_SUCCESS:
            let userIds = action.userIds;
            let data: IRewardMinusExp[] = state.rewardMinusExp;
            userIds.forEach((id: string) => {
                let item: IRewardMinusExp = { userId: id, histories: [] };
                let dataForUser = action.rewardMinusExp.filter(
                    (i: any) => i.userId === id
                );
                if (dataForUser) {
                    let histories: ExpData[] = dataForUser.map((i: any) => {
                        return {
                            _id: i._id,
                            reason: i.reason,
                            exp: i.exp,
                            time: new Date(i.time),
                            by: i.by,
                        };
                    });
                    item.histories = histories;
                }
                let checkExist = false;
                data.map((i) => {
                    if (i.userId === id) {
                        checkExist = true;
                        return item;
                    }
                    return i;
                });
                if (!checkExist) data.push(item);
            });
            return {
                ...state,
                rewardMinusExp: data,
            };
        case SprintReportTypes.ADD_REWARD_MINUS_EXP_ACTION_SUCCESS:
            let item = action.rewardMinusExp[0];
            for (let i of state.rewardMinusExp) {
                if (i.userId === item.userId) {
                    i.histories.push({
                        _id: item._id,
                        exp: item.exp,
                        time: new Date(item.time),
                        reason: item.reason,
                        by: item.by,
                    });
                }
            }
            return { ...state };
        default:
            return state;
    }
};

export default rewardMinusExpState;
