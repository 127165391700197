import { IBasic } from "./basic";
import { IUserInfo, UserInfo } from "./userInfo";

export type RoleGroup = "lead" | "member" | "intern";

export interface IUserInfoInGroup {
    userId: string;
    user?: IUserInfo;
    role: RoleGroup;
    permission?: string[];
}

export class UserInfoInGroup implements IUserInfoInGroup {
    userId: string;
    user?: IUserInfo;
    role: RoleGroup;
    permission: string[];
    constructor(props: any = {}) {
        let _userId;
        if (typeof props.user === "string") {
            _userId = props.user;
        } else if (typeof props.user === "object") {
            this.user = new UserInfo(props.user);
            _userId = this.user._id;
        } else if (typeof props.userId === "string") {
            _userId = props.userId;
        }
        this.userId = _userId;
        this.role = props.role?.toLowerCase();
        this.permission = props.permission ?? [];
    }
}
export interface IUserGroup extends IBasic {
    name: string;
    description: string;
    users: IUserInfoInGroup[];
    projectId: string;
}

export class UserGroup implements IUserGroup {
    _id: string;
    name: string;
    description: string;
    users: IUserInfoInGroup[];
    projectId: string;

    constructor(props: any = {}) {
        this._id = props._id;
        this.name = props.name;
        this.description = props.description ?? "";
        this.projectId = props.projectId ?? "";
        if (props.users) {
            this.users = props.users.map((user: any) => {
                return new UserInfoInGroup(user);
            });
        } else {
            this.users = props.users ?? [];
        }
    }
}
