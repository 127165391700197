import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IUserInfo } from "../../shared/models/userInfo";
import { List, ListItem, ListItemText } from "@mui/material";
import IconHero from "../../assets/images/logo_hero_1.0_notifications.png";
import "./style.scss";
import { ISprint } from "../../shared/models/sprint";
import moment from "moment";
import { getDataNotificationAction } from "../../redux/actions/notification_hero.action";
import { INotification } from "../../shared/models/notification";
const Notifications = () => {
    const dispatch = useDispatch();
    const userInfo: IUserInfo = useSelector(
        (state: any) => state.authState.user
    );
    const sprints: ISprint[] = useSelector(
        (state: any) => state.sprintState.sprints ?? []
    );
    const notifications: INotification[] = useSelector(
        (state: any) => state.notificationState.notifications ?? []
    );
    const sortNotification = notifications.slice().sort((a, b) => {
        const date1 = new Date(a.timestamp.toString());
        const date2 = new Date(b.timestamp.toString());
        return date2.getTime() - date1.getTime();
    });
    const activeSprint = sprints.find((sprint) => sprint.activeSprint === true);
    useEffect(() => {
        dispatch(getDataNotificationAction(userInfo._id));
    }, []);

    const handleOpenTask = (_id: string) => {
        window.open(`/task/${_id}`);
    };
    return (
        <>
            {sortNotification.length > 0 ? (
                <List
                    sx={{ overflow: "auto", maxHeight: 140 }}
                    className="scrollbarStyles"
                >
                    {sortNotification
                        .filter((dataft) => {
                            return (
                                dataft?.sprintID === activeSprint?._id &&
                                dataft.message
                            );
                        })
                        .map((data, index) => {
                            return (
                                <ListItem
                                    onClick={() => handleOpenTask(data.longID)}
                                    key={index + ""}
                                >
                                    {data &&
                                        data?.sprintID === activeSprint?._id &&
                                        data.message && (
                                            <ListItemText>
                                                <div className="container-item">
                                                    <img
                                                        src={IconHero}
                                                        className="icon-hero"
                                                    />
                                                    <div className="container-content">
                                                        {data.shortID ? (
                                                            <span className="title">
                                                                [ {data.shortID}{" "}
                                                                ]{data.message}
                                                            </span>
                                                        ) : (
                                                            <span className="title">
                                                                {data.message}
                                                            </span>
                                                        )}
                                                        <span className="text-time">
                                                            {moment(
                                                                data.timestamp
                                                            ).format(
                                                                "DD-MM-YYYY HH:mm"
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </ListItemText>
                                        )}
                                </ListItem>
                            );
                        })}
                </List>
            ) : (
                <div className="text-none-data">
                    <span>Không có thông báo nào!</span>
                </div>
            )}
        </>
    );
};

export default Notifications;
