import { convertDate } from "../utils";
import { IBasic } from "./basic";
import Config from "./config";

export interface ICoefficients {
    cWork: number;
    cDiscipline: number;
    cCreative: number;
    cLeadership: number;
    cActive: number;
    cQuality: number;
}

export class UserCoefficient implements ICoefficients {
    cWork: number;
    cDiscipline: number;
    cCreative: number;
    cLeadership: number;
    cActive: number;
    cQuality: number;
    constructor(props: any = {}) {
        this.cWork = props.cWork ?? Config.COEFFICIENT.WORK;
        this.cDiscipline = props.cDiscipline ?? Config.COEFFICIENT.DISCIPLINE;
        this.cCreative = props.cCreative ?? Config.COEFFICIENT.CREATIVE;
        this.cLeadership = props.cLeadership ?? Config.COEFFICIENT.LEADERSHIPS;
        this.cActive = props.cActive ?? Config.COEFFICIENT.ACTIVE;
        this.cQuality = props.cQuality ?? Config.COEFFICIENT.QUALITY;
    }
}
export interface IUserInfo extends IBasic, ICoefficients {
    address: string;
    avatar: string;
    badge: number;
    birth: number;
    credit: number;
    currentLevelStartExp: number;
    directManagerID: string;
    disable: boolean;
    disciplinePointExpect: number;
    discordID: string;
    doneTasksInDeadlinePercentageExpect: number;
    doneTasksPercentageExpect: number;
    email: string;
    emailActive: boolean;
    employeeCode: string;
    firstName: string;
    gender: string;
    hybridDisable: boolean;
    isChangePassword: boolean;
    lastName: string;
    level: number;
    onBoardingPass: boolean;
    password: string;
    point: number;
    pointDoneExpect: number;
    position: string;
    positionId: number;
    role: number;
    standarExpPerLevel: number;
    startDate: Date;
    status: number;
    teamId: number;
    teams: string;
    totalScore: number;
}

export class UserInfo implements IUserInfo {
    _id: any;
    address: string;
    avatar: string;
    badge: number;
    birth: number;
    credit: number;
    currentLevelStartExp: number;
    directManagerID: string;
    disable: boolean;
    disciplinePointExpect: number;
    discordID: string;
    doneTasksInDeadlinePercentageExpect: number;
    doneTasksPercentageExpect: number;
    email: string;
    emailActive: boolean;
    employeeCode: string;
    firstName: string;
    gender: string;
    hybridDisable: boolean;
    isChangePassword: boolean;
    lastName: string;
    level: number;
    onBoardingPass: boolean;
    password: string;
    point: number;
    pointDoneExpect: number;
    position: string;
    positionId: number;
    role: number;
    standarExpPerLevel: number;
    startDate: Date;
    status: number;
    teamId: number;
    teams: string;
    totalScore: number;
    cWork: number;
    cDiscipline: number;
    cCreative: number;
    cLeadership: number;
    cActive: number;
    cQuality: number;
    sharingScore: number;

    constructor(props: any = {}) {
        this._id = props._id;
        this.address = props.address ?? "";
        this.avatar = props.avatar ?? "";
        this.badge = props.badge ?? 0;
        this.birth = props.birth ?? -1;
        this.credit = props.credit ?? 0;
        this.currentLevelStartExp = props.currentLevelStartExp ?? 0;
        this.directManagerID = props.directManagerID ?? "";
        this.disable = !!props.disable;
        this.disciplinePointExpect = props.disciplinePointExpect ?? 100;
        this.discordID = props.discordID ?? "";
        this.doneTasksInDeadlinePercentageExpect =
            props.doneTasksInDeadlinePercentageExpect ?? 60;
        this.doneTasksPercentageExpect = props.doneTasksPercentageExpect ?? 80;
        this.email = props.email ?? "";
        this.emailActive = !!props.emailActive;
        this.employeeCode = props.employeeCode ?? props._id;
        this.firstName = props.firstName ?? "";
        this.gender = props.gender ?? "";
        this.hybridDisable = !!props.hybridDisable;
        this.isChangePassword = !!props.isChangePassword;
        this.lastName = props.lastName ?? "";
        this.level = props.level ?? 1;
        this.onBoardingPass = !!props.onBoardingPass;
        this.password = props.password;
        this.point = props.point ?? 0;
        this.pointDoneExpect = props.pointDoneExpect ?? Config.DEFAULT_POINT;
        this.position = props.position ?? "";
        this.role = props.role ?? Config.USER_ROLE_STAFF;
        this.standarExpPerLevel =
            props.standarExpPerLevel ?? Config.STANDAR_EXP;
        this.startDate = convertDate(props.startDate) ?? new Date();
        this.status = props.status ?? Config.STATUS.PUBLIC.id;
        this.totalScore = props.totalScore ?? 0;
        this.cWork = props.cWork ?? Config.COEFFICIENT.WORK;
        this.cActive = props.cActive ?? Config.COEFFICIENT.ACTIVE;
        this.cCreative = props.cCreative ?? Config.COEFFICIENT.CREATIVE;
        this.cDiscipline = props.cDiscipline ?? Config.COEFFICIENT.DISCIPLINE;
        this.cLeadership = props.cLeadership ?? Config.COEFFICIENT.LEADERSHIPS;
        this.cQuality = props.cQuality ?? Config.COEFFICIENT.QUALITY;
        this.sharingScore = props.sharingScore ?? 0;

        switch (props.teams?.toUpperCase()) {
            case "MANAGER":
                this.teamId = Config.TEAMS.MANAGER.id;
                this.teams = Config.TEAMS.MANAGER.name;
                break;
            case "MARKETING":
                this.teamId = Config.TEAMS.MARKETING.id;
                this.teams = Config.TEAMS.MARKETING.name;
                break;
            case "TECHNICAL":
                this.teamId = Config.TEAMS.TECHNICAL.id;
                this.teams = Config.TEAMS.TECHNICAL.name;
                break;
            case "UI":
                this.teamId = Config.TEAMS.DESIGN.id;
                this.teams = Config.TEAMS.DESIGN.name;
                break;
            case "HR":
                this.teamId = Config.TEAMS.HR.id;
                this.teams = Config.TEAMS.HR.name;
                break;
            case "CONTENT":
                this.teamId = Config.TEAMS.CONTENT.id;
                this.teams = Config.TEAMS.CONTENT.name;
                break;
            default:
                this.teamId = Config.TEAMS.OTHER.id;
                this.teams = Config.TEAMS.OTHER.name;
                break;
        }
        switch (props.position?.toUpperCase()) {
            case "COFOUNDER & HEAD OF OPERATION & PM":
                this.positionId = Config.POSITIONS.CF_HO_PM.id;
                this.position = Config.POSITIONS.CF_HO_PM.name;
                break;
            case "FOUNDER & CEO":
                this.positionId = Config.POSITIONS.F_CEO.id;
                this.position = Config.POSITIONS.F_CEO.name;
                break;
            case "COFOUNDER & PM & CMO":
                this.positionId = Config.POSITIONS.CF_PM_CMO.id;
                this.position = Config.POSITIONS.CF_PM_CMO.name;
                break;
            case "UI DESIGNER":
                this.positionId = Config.POSITIONS.UI_DESIGNER.id;
                this.position = Config.POSITIONS.UI_DESIGNER.name;
                break;
            case "LEAD DEV":
                this.positionId = Config.POSITIONS.LEAD_DEV.id;
                this.position = Config.POSITIONS.LEAD_DEV.name;
                break;
            case "DEVELOPER":
                this.positionId = Config.POSITIONS.DEVELOPER.id;
                this.position = Config.POSITIONS.DEVELOPER.name;
                break;
            case "TESTER":
                this.positionId = Config.POSITIONS.TESTER.id;
                this.position = Config.POSITIONS.TESTER.name;
                break;
            case "FRESHER DEVELOPER":
                this.positionId = Config.POSITIONS.FRESHER_DEVELOPER.id;
                this.position = Config.POSITIONS.FRESHER_DEVELOPER.name;
                break;
            case "CONTENT EDITOR":
                this.positionId = Config.POSITIONS.CONTENT_EDITOR.id;
                this.position = Config.POSITIONS.CONTENT_EDITOR.name;
                break;
            case "DEVELOPER INTERN":
                this.positionId = Config.POSITIONS.DEVELOPER_INTERN.id;
                this.position = Config.POSITIONS.DEVELOPER_INTERN.name;
                break;
            case "HR":
                this.positionId = Config.POSITIONS.HR.id;
                this.position = Config.POSITIONS.HR.name;
                break;
            case "MARKETER":
                this.positionId = Config.POSITIONS.MARKETER.id;
                this.position = Config.POSITIONS.MARKETER.name;
                break;
            case "MARKETER & PM":
                this.positionId = Config.POSITIONS.MARKETER_PM.id;
                this.position = Config.POSITIONS.MARKETER_PM.name;
                break;
            case "TESTER INTERN":
                this.positionId = Config.POSITIONS.TESTER_INTERN.id;
                this.position = Config.POSITIONS.TESTER_INTERN.name;
                break;
            default:
                this.positionId = Config.POSITIONS.OTHER.id;
                this.position = Config.POSITIONS.OTHER.name;
                break;
        }
    }

    getFullName() {
        return this.firstName + " " + this.lastName;
    }

    static isObject(item: any): boolean {
        if (item) {
            if (item.constructor.name == this.name) {
                return true;
            }
            const list = [
                "_id",
                "firstName",
                "lastName",
                "gender",
                "avatar",
                "email",
                "password",
            ];
            let count = 0;
            for (let attr of Object.getOwnPropertyNames(item)) {
                if (list.includes(attr)) {
                    count++;
                }
            }
            return count == list.length;
        }
        return false;
    }
}
