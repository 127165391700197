import { ISetting } from "../../shared/models/setting";

export enum SettingTypes {
    GET_SETTING = "GET_SETTING",
    GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS",
    GET_SETTING_FAILED = "GET_SETTING_FAILED",
}

export interface ISettingAction {
    type: SettingTypes
    data?: ISetting
    error?: any
}

export const getSetting = (): ISettingAction => {
    return {
        type: SettingTypes.GET_SETTING
    };
}

export const getSettingSuccess = (data: ISetting): ISettingAction => {
    return {
        type: SettingTypes.GET_SETTING_SUCCESS,
        data
    };
}

export const getSettingFailed = (error: any): ISettingAction => {
    return {
        type: SettingTypes.GET_SETTING_FAILED,
        error
    };
}