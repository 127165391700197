import { call, put, select, takeLatest } from "redux-saga/effects";
import Config from "../../shared/models/config";
import { ITask } from "../../shared/models/task";
import { IReportAction, ReportTypes, getReportSprintFailed, getReportSprintSuccess } from "../actions/report.action";
import { UserReport } from "../reducers/report.reducer";
import { getAllTaskAPI } from "./task.saga";
import { IUserInfo } from "../../shared/models/userInfo";
import { ISprint } from "../../shared/models/sprint";
import { IStatus } from "../../shared/models/status";
import { IProject } from "../../shared/models/project";
import { preHandleTasks } from "../../utils";

/**
 * Khong con chinh xac nua vi sau khi ket thuc sprint thi khong the query task theo sprintId duoc nua
 */
function* getReportSprintSaga(action: IReportAction) {
    const sprintId = action.sprintId;
    const users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos);
    const sprints: ISprint[] = yield select((state: any) => state.sprintState.sprints);
    const allStatus: IStatus[] = yield select((state: any) => state.statusState.status);
    const projects: IProject[] = yield select((state: any) => state.projectState.projects);
    if (sprintId) {
        try {
            let tasks: ITask[] = yield call(getAllTaskAPI, sprintId);
            tasks = preHandleTasks(tasks, users, sprints, allStatus, projects);
            // console.log("tasks", tasks?.length)
            const mapUserReport = new Map<string, UserReport>();
            if (tasks) {
                for (const task of tasks) {
                    const assigneeId = task.reporterId;
                    const oldReport: UserReport = mapUserReport.get(assigneeId) ?? {
                        userId: assigneeId,
                        numberOfBugs: 0,
                        numberOfPointsDone: 0,
                        numberOfTasksDone: 0,
                        totalPoints: 0,
                        totalTasks: 0,
                        user: task.assignee,
                    };
                    const isBug = task.type == "Bug";
                    // const isSubtask = task.type == "Sub Task";
                    const newReport: UserReport = {
                        userId: assigneeId,
                        numberOfBugs: 0,
                        numberOfPointsDone: 0,
                        numberOfTasksDone: 0,
                        totalPoints: 0,
                        totalTasks: 0,
                        user: task.assignee,
                    };
                    if (isBug) {
                        newReport.numberOfBugs = oldReport.numberOfBugs + 1;
                    } else {
                        const isTaskDone = task.status?.shortId == Config.COLUMN_STATUS.DONE.id;
                        newReport.totalPoints = oldReport.totalTasks + (task.point ?? 0);
                        newReport.totalTasks = oldReport.totalTasks + 1;
                        if (isTaskDone) {
                            newReport.numberOfPointsDone = oldReport.numberOfPointsDone + 1;
                            newReport.numberOfTasksDone = oldReport.numberOfTasksDone + 1;
                        }
                        newReport.totalPoints = oldReport.totalPoints + 1;
                        newReport.totalTasks = oldReport.totalTasks + 1;
                    }
                    mapUserReport.set(assigneeId, newReport);
                }
            }
            yield put(getReportSprintSuccess(Array.from(mapUserReport.values())));
        } catch (err) {
            console.log(err);
            yield put(getReportSprintFailed("getReportSprintSaga ERROR: " + err));
        }
    } else {
        yield put(getReportSprintFailed("getReportSprintSaga sprintId null"));
    }
}

export function* watchReport() {
    yield takeLatest(ReportTypes.GET_REPORT_SPRINT, getReportSprintSaga);
}
