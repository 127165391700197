import { UserReport } from "../reducers/report.reducer";

export enum ReportTypes {
    GET_REPORT_SPRINT = "GET_REPORT_SPRINT",
    GET_REPORT_SPRINT_SUCCESS = "GET_REPORT_SPRINT_SUCCESS",
    GET_REPORT_SPRINT_FAILED = "GET_REPORT_SPRINT_FAILED",
    GET_REPORT_MONTH = "GET_REPORT_MONTH",
    GET_REPORT_MONTH_SUCCESS = "GET_REPORT_MONTH_SUCCESS",
    GET_REPORT_MONTH_FAILED = "GET_REPORT_MONTH_FAILED",
    SET_SPRINT_ACTIVE_USER = "SET_SPRINT_ACTIVE_USER",
}

export interface ReportDate {
    startDate: Date;
    endDate: Date;
}

export interface IReportAction {
    type: ReportTypes;
    sprintId?: string;
    date?: ReportDate;
    reports?: UserReport[];
    error?: any;
    sprintActiveUsers?: any;
}

export const getReportSprint = (sprintId?: string): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_SPRINT,
        sprintId,
    };
};

export const getReportSprintSuccess = (reports: UserReport[]): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_SPRINT_SUCCESS,
        reports,
    };
};

export const getReportSprintFailed = (error: any): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_SPRINT_FAILED,
        error,
    };
};

export const getReportMonth = (date: ReportDate): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_MONTH,
        date,
    };
};

export const getReportMonthSuccess = (reports: UserReport[]): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_SPRINT_SUCCESS,
        reports,
    };
};

export const getReportMonthFailed = (error: any): IReportAction => {
    return {
        type: ReportTypes.GET_REPORT_SPRINT_FAILED,
        error,
    };
};

export const setSprintActiveUser = (sprintActiveUsers: any): IReportAction => {
    return {
        type: ReportTypes.SET_SPRINT_ACTIVE_USER,
        sprintActiveUsers,
    };
};
