import "./style.scss";
import React from "react";
import split from "../../assets/images/SplitFeedback.svg";
import { Carousel } from "react-bootstrap";
import EvaluationModal from "../user/evaluation-modal/EvaluationModal";

const Feedback = ({ userId, feedback }) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const { role } = userInfo;
    const [show, setShow] = React.useState(false);
    const [currentComment, setCurrentComment] = React.useState({});
    const groups =
        feedback.length > 0 &&
        feedback?.reduce((groups, data) => {
            const userId = data.userId;
            if (!groups[userId]) {
                groups[userId] = [];
            }
            const dataGroups = {
                _id: data?._id,
                userId: data?.userId,
                strength: data?.strength ?? "",
                weakness: data?.weakness ?? "",
                target: data?.target ?? "",
                review: data?.review ?? "",
                opinion: data?.opinion ?? "",
                month: data?.month ?? "",
                year: data?.year ?? "",
            };
            if (dataGroups._id !== 0) {
                groups[userId].unshift(dataGroups);
            }
            return groups;
        }, {});

    const group2021 = [];
    const group2022 = [];

    for (let x in groups[userId]) {
        if (groups[userId][x].year === 2021) {
            group2021.push(groups[userId][x]);
        }
    }

    for (let x in groups[userId]) {
        if (groups[userId][x].year === 2022) {
            group2022.push(groups[userId][x]);
        }
    }

    const group2021ByMonth =
        group2021 && group2021.sort((a, b) => b?.month - a?.month);
    const group2022ByMonth =
        group2022 && group2022.sort((a, b) => b?.month - a?.month);

    const groupSorted = group2022ByMonth.concat(group2021ByMonth);

    // group feedbacks into collections of 3 to show 3 feedbacks at a time each carousel item
    const groupCollection = [];

    let temp = [];
    for (let i = 1; i <= groupSorted.length; i++) {
        temp.push(groupSorted[i - 1]);
        if (i % 3 === 0 || i === groupSorted.length) {
            groupCollection.push(temp);
            temp = [];
        }
    }

    const group =
        groups &&
        Object.keys(groups)?.map((userId) => {
            return {
                userId,
                groupByUserId: groupCollection,
            };
        });

    const handleShowModal = () => {
        setShow(!show);
    };

    const handleClickComment = (comment) => {
        if (role === 1) {
            setCurrentComment(comment);
            handleShowModal();
        }
    };

    return (
        <>
            <EvaluationModal
                showModal={show}
                handleShowModal={handleShowModal}
                externalData={currentComment}
            />
            {userId === null || userId === undefined
                ? null
                : group &&
                  group.map((item, index) => {
                      return (
                          <div className="boxes" key={"box-" + index}>
                              <Carousel>
                                  {item.groupByUserId &&
                                      item.groupByUserId?.map(
                                          (collection, index) => {
                                              return (
                                                  <Carousel.Item
                                                      interval={1000000000}
                                                      key={index}
                                                  >
                                                      {collection?.map(
                                                          (item) => {
                                                              const strength =
                                                                  item?.strength;
                                                              const weakness =
                                                                  item?.weakness;
                                                              const target =
                                                                  item?.target;
                                                              const review =
                                                                  item?.review;
                                                              const opinion =
                                                                  item?.opinion;
                                                              const month =
                                                                  item?.month;
                                                              const year =
                                                                  item?.year;
                                                              return (
                                                                  <div
                                                                      onClick={() =>
                                                                          handleClickComment(
                                                                              item
                                                                          )
                                                                      }
                                                                      className="box-feedback"
                                                                  >
                                                                      <div className="month">
                                                                          <p>
                                                                              {`Tháng ${month} năm ${year}`}
                                                                          </p>
                                                                      </div>
                                                                      <div className="feedback">
                                                                          {strength && (
                                                                              <div className="strength">
                                                                                  <p>
                                                                                      Điểm
                                                                                      mạnh
                                                                                  </p>
                                                                                  <div className="content">
                                                                                      {strength
                                                                                          .split(
                                                                                              "\n"
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  p,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <p
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          p
                                                                                                      }
                                                                                                  </p>
                                                                                              )
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {/* <img
                                                                              className="split"
                                                                              src={
                                                                                  split
                                                                              }
                                                                              alt="weakness"
                                                                          /> */}
                                                                          {weakness && (
                                                                              <div className="weakness">
                                                                                  <p>
                                                                                      Điểm
                                                                                      yếu
                                                                                  </p>
                                                                                  <div className="content">
                                                                                      {weakness
                                                                                          .split(
                                                                                              "\n"
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  p,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <p
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          p
                                                                                                      }
                                                                                                  </p>
                                                                                              )
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {/* <img
                                                                              className="split"
                                                                              src={
                                                                                  split
                                                                              }
                                                                              alt="target"
                                                                          /> */}
                                                                          {target && (
                                                                              <div className="target">
                                                                                  <p>
                                                                                      Mục
                                                                                      tiêu
                                                                                      của
                                                                                      tháng
                                                                                  </p>
                                                                                  <div className="content">
                                                                                      {target
                                                                                          .split(
                                                                                              "\n"
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  p,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <p
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          p
                                                                                                      }
                                                                                                  </p>
                                                                                              )
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {review && (
                                                                              <div className="review">
                                                                                  <p>
                                                                                      Review
                                                                                      công
                                                                                      việc
                                                                                  </p>
                                                                                  <div className="content">
                                                                                      {target
                                                                                          .split(
                                                                                              "\n"
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  p,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <p
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          p
                                                                                                      }
                                                                                                  </p>
                                                                                              )
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {opinion && (
                                                                              <div className="opinion">
                                                                                  <p>
                                                                                      Ý
                                                                                      kiến
                                                                                      cá
                                                                                      nhân
                                                                                  </p>
                                                                                  <div className="content">
                                                                                      {target
                                                                                          .split(
                                                                                              "\n"
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  p,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <p
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          p
                                                                                                      }
                                                                                                  </p>
                                                                                              )
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              );
                                                          }
                                                      )}
                                                  </Carousel.Item>
                                              );
                                          }
                                      )}
                              </Carousel>
                          </div>
                      );
                  })}
        </>
    );
};

export default Feedback;
