import { IAppTheme } from "../../shared/models/appTheme";

export enum AppThemeTypes {
    GET_APP_THEME = "GET_APP_THEME",
    GET_APP_THEME_SUCCESS = "GET_APP_THEME_SUCCESS",
    GET_APP_THEME_FAILED = "GET_APP_THEME_FAILED",
    UPDATE_APP_THEME = "UPDATE_APP_THEMES",
    
    GET_APP_THEME_ROOT = "GET_APP_THEME_ROOT",
    GET_APP_THEME_ROOT_SUCCESS = "GET_APP_THEME_ROOT_SUCCESS",
    GET_APP_THEME_ROOT_FAILED = "GET_APP_THEME_ROOT_FAILED",
}

export interface IAppThemeRootAction {
    type: AppThemeTypes
    appThemeRoot?: IAppTheme
    error?: any
    appId?: string
    mapIndex?: Map<string, number>
}


export interface IAppThemeAction {
    type: AppThemeTypes
    appTheme?: IAppTheme
    error?: any
    appId?: string
}

export const getAppTheme = (appId: string): IAppThemeAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME,
        appId
    };
};

export const getAppThemeSuccess = (
    appTheme: IAppTheme
): IAppThemeAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_SUCCESS,
        appTheme,
    };
};

export const getAppThemeFailed = (error: any): IAppThemeAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_FAILED,
        error,
    };
};

export const updateAppTheme = (
    appId: string,
    appTheme: IAppTheme
): IAppThemeAction => {
    return {
        type: AppThemeTypes.UPDATE_APP_THEME,
        appTheme,
        appId
    };
};

export const getAppThemeRoot = (appId: string): IAppThemeRootAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_ROOT,
        appId
    };
};

export const getAppThemeRootSuccess = (appThemeRoot: IAppTheme): IAppThemeRootAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_ROOT_SUCCESS,
        appThemeRoot,
    };
};

export const getAppThemeRootFailed = (error: any): IAppThemeRootAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_ROOT_FAILED,
        error,
    };
};

export const updateThemeRootSuccess = (appThemeRoot: IAppTheme): IAppThemeRootAction => {
    return {
        type: AppThemeTypes.GET_APP_THEME_ROOT_SUCCESS,
        appThemeRoot,
    };
};