import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import Url from "../../../util/url";

async function createUserFeedbackAPI(comment) {
    const res = await axios.post(Url(`comment/create-comment`), comment);
    return res?.data?.response?.response[0] ?? null;
}

export function* createUserFeedback(action) {
    const comment = action.data;
    if (comment) {
        try {
            const newComment = yield call(createUserFeedbackAPI, comment);
            if (newComment) {
                yield put({
                    type: "CREATE_USER_FEEDBACK_SUCCESS",
                    data: newComment,
                });
            } else {
                yield put({
                    type: "CREATE_USER_FEEDBACK_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

async function updateUserFeedbackAPI(comment) {
    await axios.patch(Url(`comment/update-comment/${comment._id}`), comment);
    return comment ?? null;
}

export function* updateUserFeedback(action) {
    const comment = action.data;
    if (comment) {
        try {
            const newComment = yield call(updateUserFeedbackAPI, comment);
            if (newComment) {
                yield put({
                    type: "UPDATE_USER_FEEDBACK_SUCCESS",
                    data: newComment,
                });
            } else {
                yield put({
                    type: "UPDATE_USER_FEEDBACK_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

async function deleteUserFeedbackAPI(id) {
    await axios.delete(Url(`comment/delete-comment/${id}`));
    return id ?? null;
}

export function* deleteUserFeedback(action) {
    const comment = action.data;
    if (comment) {
        try {
            const res = yield call(deleteUserFeedbackAPI, comment._id);
            if (res) {
                yield put({
                    type: "DELETE_USER_FEEDBACK_SUCCESS",
                    data: res,
                });
            } else {
                yield put({
                    type: "DELETE_USER_FEEDBACK_FAILED",
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

export function* watchCreateUserFeedback() {
    yield takeEvery("CREATE_USER_FEEDBACK", createUserFeedback);
}

export function* watchUpdateUserFeedback() {
    yield takeEvery("UPDATE_USER_FEEDBACK", updateUserFeedback);
}

export function* watchDeleteUserFeedback() {
    yield takeEvery("DELETE_USER_FEEDBACK", deleteUserFeedback);
}
