import { IUserProgress } from "../../shared/models/userProgress"

export enum UserProgressTypes {
    UPDATE_USER_PROGRESS = "UPDATE_USER_PROGRESS",
    UPDATE_USER_PROGRESS_SUCCESS = "UPDATE_USER_PROGRESS_SUCCESS",
    UPDATE_USER_PROGRESS_FAILED = "UPDATE_USER_PROGRESS_FAILED",
}

export interface IUserProgressAction {
    type: UserProgressTypes
    data?: any
    userProgress?: IUserProgress
    error?: any
}

export const updateUserProgress = (data: any): IUserProgressAction => {
    return {
        type: UserProgressTypes.UPDATE_USER_PROGRESS,
        data
    };
}

export const updateUserProgressSuccess = (userProgress: IUserProgress): IUserProgressAction => {
    return {
        type: UserProgressTypes.UPDATE_USER_PROGRESS_SUCCESS,
        userProgress
    };
}

export const updateUserProgressFailed = (error: any): IUserProgressAction => {
    return {
        type: UserProgressTypes.UPDATE_USER_PROGRESS_FAILED,
        error
    };
}

