import { IBasic } from "./basic";

export interface IStatus extends IBasic {
    title: string
    color: string
    prev: string
    next: string
    shortId: number
}

export class Status implements IStatus {
    _id: any
    title: string
    color: string
    prev: string
    next: string
    shortId: number

    constructor(props: any = {}) {
        this._id = props._id;
        this.title = props.title;
        this.color = props.color;
        this.prev = props.prev;
        this.next = props.next;
        this.shortId = props.shortId;
    }
}