import { ICertificates } from "./models/certificates.model";

export const GA4_PROPERTY_PASSEMALL = 276498001;

export const isWebWorksheet = (slug: string) => {
    return slug == "worksheetzone";
};

export const isWebPassemall = (slug: string) => {
    return slug == "passemall";
};

export const hasApp = (
    certificates: ICertificates,
    platform: number
): boolean => {
    return certificates.appInfos?.find(
        (appInfo) => appInfo.platformId === platform
    )
        ? true
        : false;
};

export const priceProApp = (): any => {
    return {
        "4574196346650624": 4.99, //cissp
        "5757836690718720": 4.99, //cissp
        "6028216894488576": 5.99, //dmv
        "5243570678136832": 4.99, //emtp
        "6074233270566912": 3.99, //epa
        "5907072057081856": 4.99, //cfa
        "5753091221618688": 7.99, //ptce
        "6425502466179072": 9.99, //accuplacer
        "5678215016218624": 4.99, //mblex
        "5656255221727232": 4.99, //hvac
        "6740049070653440": 7.99, //real_state
        "5685506780168192": 4.99, //motor
        "5017963709071360": 4.99, //rn
        "5738309204574208": 9.99, //pmp
        "5962925959282688": 4.99, //aws
        "4583382262480896": 9.99, //net+
        "5651471211167744": 4.99, //pn
        "4902462618075136": 9.99, //cdl
        "4739242989191168": 4.99, //g1
        "5552846747467776": 5.99, //dmv
    };
};
