import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IProject, Project } from "../../shared/models/project";
import Url from "../../util/url";
import { ProjectTypes, getAllProjectFailed, getAllProjectSuccess } from "../actions/project.action";

async function getAllProjectAPI(): Promise<IProject[] | null> {
    return await axios.get(Url("projects")).then((data: any) => {
        if(data?.data?.success && data?.data?.response) {
            return data.data.response.map((item: any) => {
                return new Project(item);
            })
        }
        return null;
    });
}

function* getAllProjectSaga() {
    try {
        const projects: IProject[] | null = yield call(getAllProjectAPI);
        if(projects) {
            yield put(getAllProjectSuccess(projects));
        } else {
            yield put(getAllProjectFailed("getAllProjectSaga: projects undefined!"));
        }
    } catch (err) {
        yield put(getAllProjectFailed("getAllProjectFailed ERROR: " + err));
    }

}

export function* watchProject() {
    yield takeLatest(ProjectTypes.GET_ALL_PROJECT, getAllProjectSaga);
}
