import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CheckTaskCreated, EndSprintPanel } from ".";
import menuicon from "../../../assets/icon/moreicon.png";
import sporticon from "../../../assets/icon/sport.png";
import Constants from "../../../constant";
import { Campaign } from "../../../shared/models/campaign";
import { ISprint } from "../../../shared/models/sprint";
import { IUserGroup } from "../../../shared/models/userGroup";
import { ICoefficients, IUserInfo } from "../../../shared/models/userInfo";
import { formatDate, isAdmin, workingDaysBetweenDates } from "../../../util";
import { BasicMenu } from "../../Header";
import CreateCampaignModal from "../campaign/create-campaign-modal";
import CreateSprintModal from "../sprint/create-sprint-modal";
import CreateTaskModal from "../task-preview/create-task-modal";
import UserProgressUpdateModal from "../update-user-progress";
import FunctionButton from "./FunctionButton";
import UserIndicator from "./UserIndicator";
import "./style.scss";
import { roleForPoint } from "../../../shared/utils";
import Config from "../../../shared/models/config";

const DashboardSidebarV2: FC<{
    currentSprint: ISprint;
    backButton: boolean;
    currentUsersInfo: any[];
    userTaskScore: any; // {userId: ITaskScores}
    userDisciplineScore: any;
    coefs: { [userId: string]: ICoefficients };
}> = ({ currentSprint, backButton, currentUsersInfo, userTaskScore, userDisciplineScore, coefs }) => {
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const projects = useSelector((state: any) => state.projectState.projects ?? []);
    const pageName = useSelector((state: any) => state.page);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups ?? []);
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
    const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false);
    const [expandMenu, setExpandMenu] = useState(false);
    const history = useHistory();
    let sprintID: string = currentSprint._id;
    const showAllSprint = (history.location.search?.indexOf("showAllSprint=true") ?? -1) > -1;
    const listSprints = showAllSprint ? sprints : sprints.filter((a) => a.backLog == true); // lọc những sprint là backlog và active
    listSprints.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
    const [showCreateSprintModal, setShowCreateSprintModal] = useState(false);
    useEffect(() => {
        checkRoleToAddCampaign();
    }, [projects]);

    const isUserGroups: IUserGroup[] = userGroups.filter((group) => group.users.some((user) => user.userId === userInfo._id)); //check xem user co trong group khong
    let checkPermission: boolean | undefined;
    isUserGroups.map((data, index) => {
        data?.users?.forEach((d) => {
            d?.permission?.find((dt) => {
                if(d.permission !== undefined){
                    if (d?.permission.length > 0 && dt === Config.PERMISSION_EDITOR_CAMPAIGN) {
                        checkPermission = true;
                    }
                }
            });
        });
    }); // check quyen cua user
    const checkRoleToAddCampaign = () => {
        if (checkPermission) {
            return true;
        }
        
        // for (let index = 0; index < projects.length; index++) {
        //     if (projects[index].ownerID == userInfo._id) {
        //         return true;
        //     }
        // }
        
        return false;
    };

    let isLeader = false;
    userGroups.forEach((group) => {
        let users = group.users;
        users.forEach((user) => {
            if (user.userId === userInfo._id && user.role === "lead") isLeader = true;
        });
    });
    return (
        <div className="sidebar-v2">
            <div
                style={{
                    padding: "16px",
                    justifyContent: "space-between",
                }}
                className="background-white-008 radius-12 align-item-center"
            >
                <div className="column">
                    <div className="align-item-center">
                        <img
                            src={sporticon}
                            style={{
                                width: "24px",
                                height: "24px",
                                margin: "1px 4px",
                            }}
                        />
                        <span
                            className="white"
                            style={{
                                fontSize: "22px",
                                fontWeight: 500,
                                marginLeft: "10px",
                            }}
                        >
                            {currentSprint?.name}
                        </span>
                    </div>

                    <span style={{ color: "#6B7280", fontSize: "12px" }}>
                        {formatDate(new Date(currentSprint?.startDate) ?? new Date(), "dd/mm/yyyy") +
                            " - " +
                            formatDate(new Date(currentSprint?.endDate) ?? new Date(), "dd/mm/yyyy")}
                    </span>
                </div>
                <img
                    src={menuicon}
                    style={{
                        cursor: "pointer",
                        width: "40px",
                        height: "40px",
                    }}
                    onClick={() => {
                        setExpandMenu(!expandMenu);
                    }}
                />
            </div>
            <Collapse in={expandMenu}>
                <div className="menu column align-item-center">
                    <CheckTaskCreated onCreatedTask={() => setShowCreateTaskModal(false)} />
                    {!backButton && (
                        <>
                            <FunctionButton pageData={Constants.REPORTS} active={pageName === Constants.REPORTS} />
                            <FunctionButton pageData={Constants.RELEASES} active={pageName === Constants.RELEASES} />
                            <FunctionButton pageData={Constants.TOOL} active={pageName === Constants.TOOL} />
                            <FunctionButton pageData={Constants.RECRUITMENT} active={pageName === Constants.RECRUITMENT} />
                            <FunctionButton
                                pageData={Constants.INFORECRUITMENT}
                                active={pageName === Constants.INFORECRUITMENT}
                            />
                            <FunctionButton pageData={Constants.INFORATING} active={pageName === Constants.INFORATING} />
                            <FunctionButton pageData={Constants.APPSTATISTICS} active={pageName === Constants.APPSTATISTICS} />
                        </>
                    )}
                    {listSprints.map((sprint: ISprint) => {
                        const active = sprint._id == sprintID;
                        return (
                            <a
                                href={"/sprint/" + (sprint.shortId ? sprint.shortId : sprint._id)}
                                key={sprint._id}
                                className={active ? "active" : ""}
                            >
                                <span>{sprint.name}</span>
                                {sprint.activeSprint && <span> (active)</span>}
                                {!sprint.backLog && (
                                    <p style={{ fontSize: 12 }}>
                                        {workingDaysBetweenDates(sprint.startDate, sprint.endDate)} ngày (
                                        {moment(sprint.startDate).format("MMM D")} - {moment(sprint.endDate).format("MMM D")})
                                    </p>
                                )}
                            </a>
                        );
                    })}
                </div>
            </Collapse>
            <div style={{ padding: "12px 12px 0" }}>
                <Button
                    variant="contained"
                    onClick={() => setShowCreateTaskModal(true)}
                    className="btn-open-create-task-modal radius-12"
                >
                    Thêm Task
                </Button>
                {showCreateTaskModal && (
                    <CreateTaskModal onHide={() => setShowCreateTaskModal(false)} currentSprintID={sprintID} user={userInfo} />
                )}
                <div className="add-new-task-btn-container">
                    {!sprintID && (
                        <Button
                            variant="contained"
                            style={{ width: "100%", marginTop: "10px" }}
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            Back
                        </Button>
                    )}
                    {/* <UserProgressUpdateModal /> */}
                    {!backButton && isAdmin(userInfo) && (
                        <Button
                            variant="contained"
                            onClick={() => setShowCreateSprintModal(true)}
                            className="btn-open-create-task-modal radius-12"
                        >
                            Thêm Sprint
                        </Button>
                    )}
                    {showCreateSprintModal && <CreateSprintModal onHide={() => setShowCreateSprintModal(false)} />}

                    {!backButton && checkRoleToAddCampaign() && (
                        <Button
                            variant="contained"
                            onClick={() => setShowCreateCampaignModal(true)}
                            className="btn-open-create-task-modal radius-12"
                        >
                            Thêm Campaign
                        </Button>
                    )}
                    {showCreateCampaignModal && (
                        <CreateCampaignModal
                            onHide={() => setShowCreateCampaignModal(false)}
                            currentSprintId={sprintID}
                            campaign={new Campaign({})}
                        />
                    )}
                    {isAdmin(userInfo) && <div>{currentSprint?.activeSprint && <EndSprintPanel />}</div>}
                </div>
            </div>

            <div className="user-info">
                <div className="flex padding-12" style={{ justifyContent: "space-between" }}>
                    <div className="avatar flex align-item-center ">
                        <div className="avatar-image align-item-center justify-content-center">
                            <BasicMenu userInfo={userInfo} />
                        </div>

                        <div
                            className="column"
                            style={{
                                marginLeft: "10px",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                className="white"
                                style={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                }}
                            >
                                {userInfo.firstName}
                            </div>
                            <div
                                style={{
                                    fontSize: "12px",
                                    color: "#CED8E1",
                                    lineHeight: "14px",
                                }}
                            >
                                {userInfo.position}
                            </div>

                            {roleForPoint(userInfo) ? (
                                <div
                                    style={{
                                        fontSize: "12px",
                                        color: "#CED8E1",
                                        lineHeight: "14px",
                                    }}
                                >
                                    {"Level: " + userInfo.level}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                {roleForPoint(userInfo) && (
                    <UserIndicator
                        user={userInfo}
                        sprint={currentSprint}
                        disciplinePoints={userDisciplineScore[userInfo._id]}
                        taskScores={userTaskScore[userInfo._id]}
                        isUserAuthStaff={true}
                        coefs={coefs[userInfo._id]}
                    />
                )}

                {currentUsersInfo.map((user) => {
                    return (
                        <UserIndicator
                            user={user}
                            sprint={currentSprint}
                            disciplinePoints={userDisciplineScore[user._id]}
                            taskScores={userTaskScore[user._id]}
                            key={user._id}
                            coefs={coefs[user._id]}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default DashboardSidebarV2;
