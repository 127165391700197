import { ISprint } from "../../shared/models/sprint";
import { ISprintAction, SprintTypes } from "../actions/sprint.action";

export interface ISprintState {
    loading: boolean;
    sprints?: ISprint[];
    error?: any;
}

const initState: ISprintState = {
    loading: false,
};

const sprintState = (state = initState, action: ISprintAction): ISprintState => {
    switch (action.type) {
        case SprintTypes.GET_ALL_SPRINT:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case SprintTypes.GET_ALL_SPRINT_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    sprints: action.sprints,
                },
            };
        case SprintTypes.GET_ALL_SPRINT_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case SprintTypes.GET_ALL_SPRINT_PENDING:
            return {
                ...state,
                ...{
                    loading: !!action.loading,
                },
            };
        default:
            return state;
    }
};

export default sprintState;
