import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Url from "../../../util/url";
import { convertLink } from "../../../util";
import "./index.scss";
import ZoomImage from "../../dashboard/task-preview/zoom-image";

const EditorWidget: FC<{
    defaultValue: string;
    onChange: (value: string) => void;
    buttonSave?: boolean;
    disabled?: boolean;
    isOnChange?: boolean;
}> = ({
    defaultValue,
    onChange,
    buttonSave = true,
    disabled = false,
    isOnChange = false,
}) => {
    const [value, setValue] = useState(defaultValue);
    const [showEditDescription, setShowEditDescription] = useState(
        !defaultValue || isOnChange
    );
    const [zoomImageUrl, setZoomImageUrl] = useState("");
    useEffect(() => {
        setValue(defaultValue);
        setShowEditDescription(!defaultValue || isOnChange);
    }, [defaultValue]);
    return (
        <>
            {zoomImageUrl && (
                <ZoomImage
                    url={zoomImageUrl}
                    closeZoomImage={() => setZoomImageUrl("")}
                />
            )}
            <div className="editor-widget">
                <div
                    className="edit-description"
                    style={{ display: !showEditDescription ? "none" : "block" }}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        config={{
                            ckfinder: { uploadUrl: Url("upload/editor") },
                        }}
                        onBlur={(e, editor) => {
                            setValue(editor.getData());
                            if (!buttonSave) {
                                onChange(editor.getData());
                            }
                        }}
                        disabled={disabled}
                    />
                    {buttonSave && (
                        <div className="handle-edit-description-button">
                            <Button
                                style={{
                                    marginRight: "10px",
                                    background: "#4bb4ed",
                                    borderColor: "#4bb4ed",
                                }}
                                disabled={disabled}
                                onClick={() => {
                                    setShowEditDescription(false);
                                    onChange(value);
                                }}
                            >
                                Lưu
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setShowEditDescription(false);
                                }}
                                disabled={disabled}
                            >
                                Huỷ
                            </Button>
                        </div>
                    )}
                </div>
                <div
                    onClick={(event) => {
                        let e = event.target as HTMLElement;
                        const localName = e.localName;
                        event.preventDefault();
                        event.stopPropagation();
                        if (e.classList.contains("link")) {
                            window.open(e.innerText);
                            return;
                        }
                        if (localName === "a") {
                            const href = e.getAttribute("href");
                            href && window.open(href, "_blank");
                            return;
                        }
                        if (localName === "img") {
                            const imageUrl = e.getAttribute("src");
                            imageUrl && setZoomImageUrl(imageUrl);
                            return;
                        }
                        setShowEditDescription(true);
                    }}
                    className="show-description"
                    dangerouslySetInnerHTML={{ __html: convertLink(value) }}
                    style={{ display: !showEditDescription ? "block" : "none" }}
                ></div>
            </div>
        </>
    );
};

export default EditorWidget;
