import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";

const UserExpectationDialog = ({
    open,
    onClose,
    userInfo,
    onUpdate,
}: {
    open: boolean;
    onClose: any;
    userInfo: IUserInfo;
    onUpdate: any;
}) => {
    const [doneTasksPercentage, setDoneTasksPercentage] = useState(userInfo?.doneTasksPercentageExpect);
    const [pointDone, setPointDone] = useState(userInfo.pointDoneExpect);
    const [doneTasksInDeadlinePercentage, setDoneTasksInDealdinePercentage] = useState(
        userInfo.doneTasksInDeadlinePercentageExpect
    );
    const [discipline, setDiscipline] = useState(userInfo.disciplinePointExpect);
    const _onUpdate = async () => {
        onUpdate(
            new UserInfo({
                ...userInfo,
                pointDoneExpect: pointDone,
                doneTasksPercentageExpect: doneTasksPercentage,
                doneTasksInDeadlinePercentageExpect: doneTasksInDeadlinePercentage,
                disciplinePointExpect: discipline,
            })
        );
        setTimeout(() => {
            onClose();
        }, 500);
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title3"
            aria-describedby="alert-dialog-description3"
        >
            <DialogTitle id="alert-dialog-title3">Đặt mục tiêu cho {userInfo.firstName + " " + userInfo.lastName}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth={true}
                    type="number"
                    id="done-task-percentage"
                    label="Tỷ lệ hoàn thành task"
                    variant="standard"
                    value={doneTasksPercentage}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (v > 100) v = 100;
                        // if (isNaN(v)) v = 0;
                        setDoneTasksPercentage(v);
                    }}
                    sx={{ marginTop: "20px" }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    id="done-point-percentage"
                    label="Số point hoàn thành"
                    variant="standard"
                    value={pointDone}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (v > 100) v = 100;
                        // if (isNaN(v)) v = 0;
                        setPointDone(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    id="done-task-in-deadline-percentage"
                    label="Tỷ lệ hoàn thành task đúng deadline"
                    variant="standard"
                    value={doneTasksInDeadlinePercentage}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (v > 100) v = 100;
                        // if (isNaN(v)) v = 0;
                        setDoneTasksInDealdinePercentage(v);
                    }}
                    sx={{ marginTop: "20px" }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
                <TextField
                    fullWidth={true}
                    type="number"
                    id="discipline"
                    label="Điểm kỷ luật"
                    variant="standard"
                    value={discipline}
                    onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (v > 100) v = 100;
                        // if (isNaN(v)) v = 0;
                        setDiscipline(v);
                    }}
                    sx={{ marginTop: "20px" }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={_onUpdate} autoFocus>
                    Lưu
                </Button>
                <Button onClick={onClose}>Huỷ</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserExpectationDialog;
