import { ArrowBackIos } from '@material-ui/icons';
import {
  Button,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Constants from '../../../../../constant';
import { addCycle, updateCycle } from '../../../../../redux/actions/testCycle.action';
import { updateTests } from '../../../../../redux/actions/testCase.action';
import { ITestCase } from '../../../../../shared/models/testCase';
import { ITestCycle, ITestCycleCases, TestCycle } from '../../../../../shared/models/testCycle';
import { ITestSection, TestSection } from '../../../../../shared/models/testSection';
import Url from '../../../../../util/url';
import TestSectionModal from '../components/add-edit-test-section';
import TableSection from './TableSection';

const AddEditAllCycles: FC<{
  tests: ITestCase[],
  cycle: ITestCycle, 
  taskId: string, 
  handleToggleEdit: ()=>void, 
  permission: boolean, 
  permissionForDev: boolean, 
  isEdit: boolean 
}> = ({
    tests,
    cycle, 
    taskId, 
    handleToggleEdit, 
    permission, 
    permissionForDev, 
    isEdit 
  }) => {
  const [testCases, setTestCases] = useState<ITestCase[]>([]);
  const [changeAction, setChangeAction] = useState('');
  const [focusValue, setFocusValue] = useState('');
  const [openSectionModal, setOpenSectionModal] = useState<boolean>(false);
  const [sectionEdit, setSectionEdit] = useState<any>(null);
  const sections: ITestSection[] = useSelector((state: any) => state.testSections.data)
  const dispatch = useDispatch()
  const sectionsObj: any = {};

  useEffect(() => {
    let tempArr: any[] = [];
    if(isEdit){
      tempArr = [...getTestsDataForEdit(cycle.cases)];
    } else {
      tempArr = [...getTestsDataForAdd(cycle.cases)];
    }
    setTestCases(tempArr);
  }, []);

  // Listen Select change to update
  useEffect(() => {
    if(changeAction && isEdit){
      handleSubmit();
    }

  }, [changeAction])

  if(testCases.length > 0){
    testCases.reduce((obj: any, item: ITestCase) => {
      const key = item['sectionID'];
      if (!obj[key]) {
         obj[key] = [];
      }
      // Add object to list for given key's value
      obj[key].push(item);
      return obj;
    }, sectionsObj);
  }

  // Get cycle cases data from last cycle
  const getTestsDataForAdd = (cases: ITestCycleCases[]) => {
    const tempArr = tests.map(tc => {
      let testFound = null
      if(cases && cases?.length > 0){
        testFound = cases.find(test => test._id === tc._id)
      }

      if(testFound) {
        return {
          ...tc,
          ...testFound,
          status: testFound.status !== Constants.PASS ? Constants.UNTESTED : Constants.PASS,
          devCheck: testFound.status !== Constants.PASS ? Constants.FAIL : Constants.PASS,
          actualResult: '',
          note: '',
        }
      } else {
        return {
          ...tc,
          status: Constants.UNTESTED,
          devCheck: Constants.FAIL,
          actualResult: '',
          note: '',
        };
      }
    });

    // Push pass task to bottom
    const doneArr: any[] = [];
    const indexDoneArr: number[] = [];

    tempArr.forEach((item, index) => {
      if(item.status === Constants.PASS) {
        doneArr.push(item);
        indexDoneArr.push(index);
      }
    })

    indexDoneArr.reverse().forEach((item) => {
      tempArr.splice(item, 1);
    })

    return tempArr.concat(doneArr);
  }

  // Get cycle cases data from last cycle
  const getTestsDataForEdit = (cases: ITestCycleCases[]) => {
    const tempCases = cases.slice();

    return tempCases.map(tc => {
      const testFound = tests.find(test => test._id === tc._id)
      if(testFound) {
        return {
          ...testFound,
          devCheck: tc.devCheck,
          status: tc.status,
          actualResult: tc.actualResult,
          note: tc.note,
        }
      }

      return tc;
    })

  }

  const handleEditSection = (section: any) => {
    setSectionEdit(section);
    setOpenSectionModal(true);
  }

  const handleCloseSection = () => {
    setSectionEdit(null);
    setOpenSectionModal(false);
  }

  const handleSubmit = async () => {
    const {_id} = JSON.parse(localStorage.getItem('userInfo') ?? "");
    // Check permission before update
    if(!permission && permissionForDev){
      if(isEdit){
        toast.error("Bạn không có quyền thay đổi cycle", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        return;
      }
      toast.error("Bạn không có quyền thêm cycle", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    
    let newCycle = new TestCycle({
      userID: _id,
      taskID: taskId,
      lastUpdate: new Date().getTime(),
      note: '',
      cases: testCases,
    });

    if(cycle && isEdit){
      newCycle = {
        ...newCycle,
        lastUpdate: cycle.lastUpdate,
        _id: cycle._id
      }
    }

    try {
      await axios.post(Url('test-case/updates-test-cases'), testCases)
      dispatch(updateTests(testCases))
      if (isEdit) {
        await axios.post(Url('test-case/updates-test-cycles'), [newCycle]);
        dispatch(updateCycle(newCycle));
        toast.success("Sửa cycle thành công", {
          position: "top-right",
          autoClose: 1000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        const { data } = await axios.post(Url('test-case/inserts-test-cycles'), [newCycle]);
        dispatch(addCycle(data[0]));
        handleToggleEdit();
        toast.success("Thêm cycle thành công", {
          position: "top-right",
          autoClose: 1000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleOnBlur = (event: any, editor: any) => {
    if(isEdit && (editor.getData() !== focusValue)){
      handleSubmit();
    }
  }

  // Change content of state
  const handleChange = (event: any, id: any) => {
    const data = event.target ? event.target : event;
    const { value, name } = data;
      
    if(!permission && !permissionForDev && name !== 'devCheck'){
      return;
    }
    const tempTestCases = testCases.slice();
    const index = tempTestCases.findIndex(item => item._id === id);
    const changeTestCase = {
      ...tempTestCases[index],
      [name]: value,
    };
    tempTestCases.splice(index, 1, changeTestCase);
    setTestCases(tempTestCases);
  };

  const handleSelectChange = (event: any, id: any) => {
    handleChange(event, id)
    setChangeAction(`${id}${event.value}`);
  }

  const handleCheckBoxChange = (event: any, id: any) => {
    handleChange(event, id)
    setChangeAction(`${id}${event.value}`);
  }

  return (
    <div className='edit-cycles'>
      <div className='edit-cycles__title'>
        <IconButton color='primary' onClick={handleToggleEdit}>
          <ArrowBackIos />
        </IconButton>
        <span>{isEdit ? 'Edit cycle' : 'New cycle'}</span>
      </div>
      <div> 
        {
          sectionsObj && sections && sections.map((item: ITestSection) => (
            <TableSection
              key={item._id}
              editSection={handleEditSection}
              section={item}
              tests={sectionsObj[item._id]} 
              permission={permission}
              setFocusValue={setFocusValue}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleCheckBoxChange={handleCheckBoxChange}
            />
          ))
        }
        <TableSection 
          editSection={handleEditSection}
          section={new TestSection({
            name: 'Default Section',
            description: ''
          })}
          tests={sectionsObj['other']} 
          permission={permission}
          setFocusValue={setFocusValue}
          handleOnBlur={handleOnBlur}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          handleCheckBoxChange={handleCheckBoxChange}
        />
      
      </div>
      {!isEdit?
        <div className='edit-cycles__btn'>
          <Button onClick={handleSubmit} variant='contained'>
            Create
          </Button>         
        </div> : <></> 
      }
      <TestSectionModal 
        open={openSectionModal} 
        onClose={handleCloseSection}
        editItem={sectionEdit}
        permission={permission}
        taskId={taskId} 
      />
    </div>
  );
};

export default AddEditAllCycles;
