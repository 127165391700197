import "./style.scss";
import { Modal, ModalBody, ModalFooter, Button, FormControl } from "react-bootstrap";
import { useState } from "react";
import IsLoading from "../../../IsLoading";
import axios from "axios";
import Url from "../../../../util/url";
import { useDispatch, useSelector } from "react-redux";
import Constant from "../../../../constant";
import { timezoneSetHours } from "../../../../util";
import { toast } from "react-toastify";
import { ISprint } from "../../../../shared/models/sprint";

const CreateSprintModal = ({ onHide }: { onHide: any }) => {
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const sprints: ISprint[] = useSelector((state: any) => state.sprints);
    const dispatch = useDispatch();

    const createSprint = async () => {
        if (!name) {
            toast("Chưa có tên sprint!", { position: "top-center" });
            return false;
        }
        if (!startDate) {
            toast("Chưa có ngày bắt đầu!", { position: "top-center" });
            return false;
        }
        if (!endDate) {
            toast("Chưa có ngày kết thúc!", { position: "top-center" });
            return false;
        }
        let check = true;
        let lastShortId = 0;
        let shortIdByName = parseInt(name.split(" ")[1]);
        sprints.forEach((sprint) => {
            if (sprint.shortId) {
                const shortId = parseInt(sprint.shortId);
                if (shortId > lastShortId) lastShortId = shortId;

                if (shortId === shortIdByName) check = false;
            }
        });
        lastShortId += 1;
        if (!isNaN(shortIdByName) && check) {
            lastShortId = shortIdByName;
        }

        if (!!name && !!startDate && !!endDate) {
            // e.preventDefault();
            setIsLoading(true);
            const sprintData = {
                name: name,
                startDate: timezoneSetHours(startDate),
                endDate: timezoneSetHours(endDate, 23, 59, 59),
                activeSprint: false,
                backLog: false,
                status: Constant.STATUS_PUBLIC,
                shortId: lastShortId,
            };
            const newSprintRes = await axios.post(Url("sprint/create"), sprintData);
            dispatch({
                type: "ADD_SPRINT",
                data: {
                    _id: newSprintRes.data.response[0]._id,
                    name: name,
                    startDate: timezoneSetHours(startDate),
                    endDate: timezoneSetHours(endDate, 23, 59, 59),
                    activeSprint: false,
                    backLog: false,
                    status: Constant.STATUS_PUBLIC,
                    shortId: lastShortId,
                },
            });
            setIsLoading(false);
            onHide();
        } else {
            alert("Information can not be null");
        }
    };

    return (
        <Modal show={true} centered onHide={onHide}>
            {isLoading && <IsLoading />}

            <Modal.Header>
                <Modal.Title>Tạo Sprint</Modal.Title>
            </Modal.Header>

            <ModalBody>
                <div className="field name">
                    <h6>
                        <strong>Tên Sprint*</strong>
                    </h6>
                    <FormControl type="text" name="name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="field startDate">
                    <h6>Ngày bắt đầu</h6>
                    <FormControl type="datetime-local" name="startDate" onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="field endDate">
                    <h6>Ngày kết thúc</h6>
                    <FormControl type="datetime-local" name="endDate" onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        createSprint();
                    }}
                    style={{ background: "#4bb4ed", borderColor: "#4bb4ed" }}
                >
                    Tạo
                </Button>
                <Button className="close" aria-label="Close" onClick={onHide} variant="secondary">
                    Huỷ
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateSprintModal;
