import react, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import { getKeywordPerformance } from "../../../services/dashboard";
import { format } from "date-fns";
const DATE_FORMAT = "LLL dd";

const KeywordPerformanceChart = () => {
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);

    const [dataChart, setDataChart] = useState([]);

    useEffect(() => {
        if (startTime && endTime) {
            let startDate = new Date(
                new Date(startTime).setHours(23, 59, 59, 59)
            )
                .toISOString()
                .slice(0, 10);
            let endDate = new Date(new Date(endTime).setHours(23, 59, 59, 59))
                .toISOString()
                .slice(0, 10);
            getKeywordPerformance({ startDate, endDate })
                .then((data) => {
                    let formatData = data.map((el: any) => {
                        return {
                            ...el,
                            date: format(
                                new Date(el.date).setHours(23, 59, 59, 59),
                                DATE_FORMAT
                            ),
                        };
                    });
                    setDataChart(formatData);
                })
                .catch((err) => {
                    console.log("error", err);
                });
        }
    }, [startTime, endTime]);
    // console.log("dataChart", dataChart);
    return (
        <div>
            <ResponsiveContainer height={550} width="100%" className="chart">
                <LineChart data={dataChart}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                        type="monotone"
                        dataKey="nbKeywords"
                        stroke="#3878ed"
                        yAxisId="left"
                    />
                    <Line
                        type="monotone"
                        dataKey="indexedOtherCollections"
                        stroke="#990099"
                        yAxisId="left"
                    />
                    <XAxis dataKey="date" padding={{ right: 30 }} />
                    <YAxis yAxisId="left" />
                    <Tooltip
                        content={<CustomBarTooltip />}
                        cursor={{ fill: "rgba(0, 0, 0, 0)" }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
const CustomBarTooltip = ({
    payload,
    label,
}: {
    payload?: any;
    label?: string;
}) => {
    return payload && payload.length ? (
        <div
            className="custom-tooltip"
            style={{
                padding: "10px",
                background: "#fff",
                margin: 0,
            }}
        >
            <div className="custom-tooltip-label">{label}</div>
            <div
                className="custom-tooltip-value"
                style={{
                    color: "#3878ed",
                }}
            >
                Total Keyword: {payload[0].payload.nbKeywords}
            </div>
        </div>
    ) : null;
};
export default KeywordPerformanceChart;
