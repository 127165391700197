import { CSSProperties, FC, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { IAppInfo } from "../../../shared/models/appInfo";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import addIcon from "../../../assets/images/add.svg";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import {
    AppPlatform,
    AppVersion,
    IAppVersion,
} from "../../../shared/models/appVersion";
import { useDispatch, useSelector } from "react-redux";
import {
    addInfoVersionAction,
    deleteInforVersionAction,
    getInfoVersionAcation,
    updateInfoVersion,
} from "../../../redux/actions/appVersion.action";
import { IAppVersionState } from "../../../redux/reducers/appVersion.reducer";
import LoadingWidget from "../../LoadingWidget";
import moment from "moment";
import EditorWidget from "../../ckeditor/editor-widget";
import { convertLink } from "../../../util";
const AppVersionManagementWidget: FC<{
    appInfo: IAppInfo;
    onHide: () => void;
}> = ({ onHide, appInfo }) => {
    const dispatch = useDispatch();
    const appVersionState: IAppVersionState = useSelector(
        (state: any) => state.appVersionState
    );
    const appVersions = appVersionState.newAddInfoVersion ?? [];

    useEffect(() => {
        dispatch(getInfoVersionAcation(appInfo.longId));
    }, []);
    if (appVersionState.loading) {
        return <LoadingWidget />;
    }
    return (
        <Modal show={true} centered onHide={onHide} size="xl">
            <Modal.Header>
                <Modal.Title>{appInfo.shortName}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Content appInfo={appInfo} appVersions={appVersions} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" variant="contained" onClick={onHide}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Content: FC<{ appInfo: IAppInfo; appVersions: IAppVersion[] }> = ({
    appInfo,
    appVersions,
}) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Android" {...a11yProps(0)} />
                    <Tab label="IOS" {...a11yProps(1)} />
                    <Tab label="Web" {...a11yProps(2)} />
                    <Tab label="Content" {...a11yProps(3)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <TeamAppAndroidVersion
                    appInfo={appInfo}
                    appVersions={appVersions.filter(
                        (a) => a.platform == "android"
                    )}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TeamAppIOSVersion
                    appInfo={appInfo}
                    appVersions={appVersions.filter((a) => a.platform == "ios")}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TeamAppWebVersion
                    appInfo={appInfo}
                    appVersions={appVersions.filter((a) => a.platform == "web")}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <TeamContentVersion
                    appInfo={appInfo}
                    appVersions={appVersions.filter(
                        (a) => a.platform == "content"
                    )}
                />
            </TabPanel>
        </Box>
    );
};

const TableVersionItem: FC<{
    appInfo: IAppInfo;
    platform: AppPlatform;
    appVersions: IAppVersion[];
    bucket: string;
}> = ({ appInfo, platform, appVersions, bucket }) => {
    const dispatch = useDispatch();
    const [dataItem, setDataItem] = useState<IAppVersion | undefined>();
    const onHide = () => {
        setDataItem(undefined);
    };
    const handleEditItem = (data: IAppVersion) => {
        setDataItem(data);
    };
    const handleDeleteItem = (data: any) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (data) {
                dispatch(deleteInforVersionAction(data));
            }
        }
    };
    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <img
                className="add-icon"
                src={addIcon}
                title="Add"
                onClick={() => setDataItem(new AppVersion())}
            />
            <TableContainer>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ textAlign: "center", fontWeight: "600" }}
                        >
                            STT
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: "center",
                                width: "250px",
                                fontWeight: "600",
                            }}
                        >
                            VERSION
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: "center",
                                width: "250px",
                                fontWeight: "600",
                            }}
                        >
                            LAST UPDATE
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: "center",
                                width: "250px",
                                fontWeight: "600",
                            }}
                        >
                            INFO UPDATE
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: "center",
                                width: "250px",
                                fontWeight: "600",
                            }}
                        >
                            NOTE
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: "center",
                                width: "100px",
                                fontWeight: "600",
                            }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {appVersions.map((app: AppVersion, index: number) => (
                        <TableRow key={index + app.appId}>
                            <TableCell style={{ textAlign: "center" }}>
                                <span>{index + 1}</span>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                {app.version}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                {moment(app.lastUpdate).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: convertLink(app.updateInfo),
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: convertLink(app.note),
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <div
                                    style={{
                                        width: "60px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <ModeIcon
                                        onClick={() => handleEditItem(app)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />

                                    <DeleteForeverIcon
                                        onClick={() => handleDeleteItem(app)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TableContainer>
            {dataItem && (
                <ModalAppVersion
                    appVersions={dataItem}
                    onHide={onHide}
                    appInfo={appInfo}
                    bucket={bucket}
                    platform={platform}
                />
            )}
        </div>
    );
};

const TeamContentVersion: FC<{
    appInfo: IAppInfo;
    appVersions: IAppVersion[];
}> = ({ appInfo, appVersions }) => {
    return (
        <div className="container-title">
            <TableVersionItem
                appInfo={appInfo}
                platform="content"
                appVersions={appVersions}
                bucket={appInfo?.bucket}
            />
        </div>
    );
};
const TeamAppAndroidVersion: FC<{
    appInfo: IAppInfo;
    appVersions: IAppVersion[];
}> = ({ appInfo, appVersions }) => {
    return (
        <div className="container-title">
            <TableVersionItem
                bucket={appInfo?.bucket}
                appInfo={appInfo}
                platform="android"
                appVersions={appVersions}
            />
        </div>
    );
};
const TeamAppIOSVersion: FC<{
    appInfo: IAppInfo;
    appVersions: IAppVersion[];
}> = ({ appInfo, appVersions }) => {
    return (
        <div className="container-title">
            <TableVersionItem
                bucket={appInfo?.bucket}
                appInfo={appInfo}
                platform="ios"
                appVersions={appVersions}
            />
        </div>
    );
};
const TeamAppWebVersion: FC<{
    appInfo: IAppInfo;
    appVersions: IAppVersion[];
}> = ({ appInfo, appVersions }) => {
    return (
        <div className="container-title">
            <TableVersionItem
                bucket={appInfo?.bucket}
                appInfo={appInfo}
                platform="web"
                appVersions={appVersions}
            />
        </div>
    );
};

export default AppVersionManagementWidget;

const ModalAppVersion: FC<{
    appVersions: IAppVersion;
    onHide: () => void;
    appInfo: IAppInfo;
    bucket: string;
    platform: AppPlatform;
}> = ({ appVersions, onHide, appInfo, bucket, platform }) => {
    let newDate = new Date();
    const dispatch = useDispatch();
    const [updateInfo, setUpdateInfo] = useState<string>(
        appVersions.updateInfo ?? ""
    );
    const lastUpdate: Date = newDate;
    const [noteVersion, setNoteVersion] = useState<string>(
        appVersions.note ?? ""
    );
    const infoPlatform: AppPlatform = platform;
    const [version, setVersion] = useState<string>(appVersions.version ?? "");
    useEffect(() => {
        setUpdateInfo(appVersions?.updateInfo ?? "");
        setNoteVersion(appVersions?.note ?? "");
        setVersion(appVersions?.version ?? "");
    }, [appVersions]);
    const onUpdate = async () => {
        const newVersion: IAppVersion = {
            ...appVersions,
            ...{
                updateInfo: updateInfo,
                lastUpdate: lastUpdate,
                note: noteVersion,
                version: version,
                platform: infoPlatform,
                appId: appInfo.longId,
                appInfoId: appInfo._id,
            },
        };
        dispatch(updateInfoVersion(newVersion));
        onHide();
    };
    return (
        <>
            <Modal show={true} centered onHide={onHide} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        {appVersions._id
                            ? "Update Version " + bucket
                            : "Create Version " + bucket}
                    </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <div>
                        <ItemPanel
                            title="Last Update"
                            children={
                                <span>
                                    {" "}
                                    {moment(lastUpdate).format("MM-DD-YYYY")}
                                </span>
                            }
                        />
                        <ItemPanel
                            title="Version"
                            children={
                                <TextField
                                    defaultValue={version}
                                    style={{ width: " 100%" }}
                                    onChange={(v) => setVersion(v.target.value)}
                                />
                            }
                        />
                        <ItemPanel
                            title="Info Update"
                            children={
                                <EditorWidget
                                    buttonSave={false}
                                    defaultValue={updateInfo}
                                    onChange={(v) => {
                                        setUpdateInfo(v);
                                    }}
                                />
                            }
                        />
                        <ItemPanel
                            title="Note Version"
                            children={
                                <EditorWidget
                                    buttonSave={false}
                                    defaultValue={noteVersion}
                                    onChange={(v) => {
                                        setNoteVersion(v);
                                    }}
                                />
                            }
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    {appVersions.version === undefined ||
                    appVersions.version === null ? (
                        <Button
                            style={{
                                marginRight: "10px",
                            }}
                            color="primary"
                            variant="contained"
                            onClick={onUpdate}
                        >
                            Create
                        </Button>
                    ) : (
                        <Button
                            style={{
                                marginRight: "10px",
                            }}
                            color="primary"
                            variant="contained"
                            onClick={onUpdate}
                        >
                            Update
                        </Button>
                    )}
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={onHide}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const ItemPanel: FC<{ children: React.ReactNode; title: string }> = ({
    title,
    children,
}) => {
    const itemStyle: CSSProperties = {
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    };
    const itemLabelStyle: CSSProperties = {
        width: 100,
        fontWeight: "600",
    };
    const itemContentStyle: CSSProperties = {
        width: "calc(100% - 10px)",
    };
    return (
        <div style={itemStyle}>
            <label style={itemLabelStyle}>{title}</label>
            <div style={itemContentStyle}>{children}</div>
        </div>
    );
};
