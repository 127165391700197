import { IAppInfo } from "../../shared/models/appInfo";
import { AppTypes, IAppAction } from "../actions/app.action";

export interface IAppState {
	loading: boolean
	appInfos?: IAppInfo[]
	error?: any
}

const initState: IAppState = {
	loading: false
};

const appState = (state = initState, action: IAppAction): IAppState => {
	switch (action.type) {
		case AppTypes.GET_APP_INFOS:
			return {
				...state,
				...{
					loading: true
				}
			}
		case AppTypes.GET_APP_INFOS_SUCCESS:
			return {
				...state,
				...{
					loading: false,
					appInfos: action.appInfos ?? []
				}
			}
		case AppTypes.GET_APP_INFOS_FAILED:
			return {
				...state,
				...{
					loading: false,
					error: action.error
				}
			}
		case AppTypes.UPDATE_APP_INFO_SUCCESS:
			if(action.appInfo && state.appInfos) {
				let index = state.appInfos.findIndex(item => item._id == action.appInfo?._id);
				if(index > -1) {
					state.appInfos[index] = action.appInfo;
				} else {
					state.appInfos.push(action.appInfo);
				}
			}
			return {
				...state,
			}
		case AppTypes.DELETE_APP_INFO_SUCCESS: 
			if(action.appInfo && state.appInfos) {
				state.appInfos = state.appInfos.filter(item => item._id != action.appInfo?._id);
			}
			return {
				...state
			}
		default:
			return state;
	}
};

export default appState;
