import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { ITestCycle } from "../../shared/models/testCycle";
import Url from "../../util/url";
import {
    CyclesTypes,
    ICyclesAction,
    getCyclesFailed,
    getCyclesSuccess,
} from "../actions/testCycle.action";

export async function getCyclesApi(id: any) {
    const { data } = await axios.get(
        Url(`test-case/get-test-cycle-sprint?sprintID=${id}`)
    );
    return data;
}

function* getCycles(action: ICyclesAction) {
    if(action.data) {
        try {
            const data: ITestCycle[] = yield call(getCyclesApi, action.data);
            yield put(getCyclesSuccess(data));
        } catch (error) {
            console.log(error);
            yield put(getCyclesFailed(error));
        }
    } else {
        yield put(getCyclesFailed("sprintId not found"));
    }
}

export function* watchGetCycles() {
    yield takeLatest(CyclesTypes.GET_CYCLES, getCycles);
}
