import { IUserInfo } from "../../shared/models/userInfo";
import { IReportAction, ReportTypes } from "../actions/report.action";

export interface IReportState {
    loading: boolean;
    reports?: UserReport[];
    error?: any;
    sprintActiveUsers: any;
}

const initState: IReportState = {
    loading: false,
    sprintActiveUsers: {},
};

export interface UserReport {
    userId: string;
    numberOfTasksDone: number;
    totalTasks: number;
    numberOfBugs: number;
    numberOfPointsDone: number;
    totalPoints: number;
    user?: IUserInfo;
}

const reportState = (
    state = initState,
    action: IReportAction
): IReportState => {
    switch (action.type) {
        case ReportTypes.GET_REPORT_SPRINT:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case ReportTypes.GET_REPORT_SPRINT_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    reports: action.reports,
                },
            };
        case ReportTypes.GET_REPORT_SPRINT_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case ReportTypes.SET_SPRINT_ACTIVE_USER:
            state.sprintActiveUsers = action.sprintActiveUsers;
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default reportState;
