import { IUserProgress } from "../../shared/models/userProgress"

export enum RatingTypes {
    GET_REQUEST_RATING = "GET_REQUEST_RATING",
    GET_REQUEST_RATING_SUCCESS = "GET_REQUEST_RATING_SUCCESS",
    UPDATE_REQUEST_RATING = "UPDATE_REQUEST_RATING",
}

export interface IRatingAction {
    type: RatingTypes
    requestRatingUserIds?: string[],
    sprintId?: string 
    userId?: string
    userProgress?: IUserProgress
}

export const getRequestRating = (sprintId: string, userId: string): IRatingAction => {
    return {
        type: RatingTypes.GET_REQUEST_RATING,
        userId,
        sprintId
    };
}

export const getRequestRatingSuccess = (ids: string[]): IRatingAction => {
    return {
        type: RatingTypes.GET_REQUEST_RATING_SUCCESS,
        requestRatingUserIds: ids
    };
}

export const updateRequestRating = (userProgress: IUserProgress): IRatingAction => {
    return {
        type: RatingTypes.UPDATE_REQUEST_RATING,
        userProgress
    };
}