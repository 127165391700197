import {
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { OPTIONS_PLATFORM } from "../../../config_data_analytic";
import Constants from "../../../constant";
import { capitalizeFirstLetter, formatPrice } from "../../../util";
import "./style.scss";

const CustomTooltip = ({
    active,
    payload,
}: {
    active?: any;
    payload?: any;
}) => {
    if (active && payload && payload.length) {
        let items = payload[0].payload;

        let total = 0;
        if (items["ads"]) {
            total += Number(items["ads"]);
        }
        if (items["pro"]) {
            total += Number(items["pro"]);
        }

        return (
            <>
                <div className="custom-tooltip">
                    <>
                        {Object.keys(items).map((key: any, index: any) => {
                            let item = items[key];
                            if (key == "date") {
                                return (
                                    <p style={{ color: "red" }} key={index}>
                                        Total: ${formatPrice(total)}
                                    </p>
                                );
                            }
                            if (key == "appChild" || key == "appChildSubs") {
                                return Object.keys(item).map((i) => {
                                    let arrayResult = item[i]?.split("|");
                                    return (
                                        <p
                                            className={key + " app-child"}
                                            key={index}
                                        >
                                            {capitalizeFirstLetter(i)}: $
                                            {formatPrice(arrayResult[0])} -
                                            {arrayResult[1]} Packs
                                        </p>
                                    );
                                });
                            }

                            if (key == "appName") {
                                return (
                                    <p className={key} key={index}>
                                        {item}
                                    </p>
                                );
                            }
                            item = formatPrice(item);
                            if (key == "proOneShot") {
                                return (
                                    <p className={key + " list"} key={index}>
                                        {` One Time Payment: $${item} - 
                                        ${Number(items["sellOneShotNumber"])}
                                        Packs`}
                                    </p>
                                );
                            }

                            if (key == "proCardOneShot") {
                                return (
                                    <p
                                        className={key + " app-child"}
                                        key={index}
                                    >
                                        {`Card: $${item} - 
                                        ${Number(items["proSellCardOneShot"])}
                                        Packs`}
                                    </p>
                                );
                            }
                            if (key.toLowerCase().includes("subscription")) {
                                return (
                                    <p
                                        className={key + " transaction-subject"}
                                        key={index}
                                    >
                                        {capitalizeFirstLetter(
                                            key.replace(/_/g, " ")
                                        )}
                                        : ${item}
                                    </p>
                                );
                            }
                            if (key == "proPaypalOneShot") {
                                return (
                                    <p
                                        className={key + " app-child"}
                                        key={index}
                                    >
                                        {`Paypal: $${item} - 
                                        ${Number(items["proSellPaypalOneShot"])}
                                        Packs`}
                                    </p>
                                );
                            }

                            if (key == "proSubs") {
                                return (
                                    <p className={key + " list"} key={index}>
                                        {`Subscriptions: $${item} - ${Number(
                                            items["sellSubsNumber"]
                                        )}
                                        Packs`}
                                    </p>
                                );
                            }

                            if (key == "proCardSubs") {
                                return (
                                    <p
                                        className={key + " app-child"}
                                        key={index}
                                    >
                                        {`Card: $${item} - 
                                        ${Number(items["proSellCardSubs"])}
                                        Packs`}
                                    </p>
                                );
                            }

                            if (key == "proPaypalSubs") {
                                return (
                                    <p
                                        className={key + " app-child"}
                                        key={index}
                                    >
                                        {`Paypal: $${item} - 
                                        ${Number(items["proSellPaypalSubs"])}
                                        Packs`}
                                    </p>
                                );
                            }

                            if (
                                key == "sellOneShotNumber" ||
                                key == "sellSubsNumber" ||
                                key == "proSellPaypalOneShot" ||
                                key == "proSellPaypalSubs" ||
                                key == "proSellCardOneShot" ||
                                key == "proSellCardSubs"
                            ) {
                                return null;
                            }

                            return (
                                <p className={key} key={index}>
                                    {capitalizeFirstLetter(key)}: ${item}
                                </p>
                            );
                        })}
                    </>
                </div>
            </>
        );
    }

    return null;
};
const CustomBarChart = ({
    data,
    optionValue,
    dataKeyXAxis,
    dataKeyYAxis,
    month,
    barValues,
}: {
    data: any;
    optionValue?: any;
    dataKeyXAxis: string;
    dataKeyYAxis?: any;
    month?: boolean;
    barValues?: any;
}) => {
    return (
        <div className="bar-chart">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis
                        dataKey={dataKeyXAxis}
                        scale="point"
                        padding={{ left: 30, right: 30 }}
                    />
                    <YAxis />
                    {/* <Tooltip /> */}
                    <Tooltip content={<CustomTooltip />} />
                    {month ? (
                        <>
                            {dataKeyYAxis.map((key: any, index: any) => {
                                let colorBar =
                                    index == 0
                                        ? "#8884d8"
                                        : index == 1
                                        ? "#82ca9d"
                                        : "#ffc658";
                                return (
                                    <Bar
                                        key={key}
                                        dataKey={key}
                                        fill={colorBar}
                                        background={{ fill: "transparent" }}
                                        name={
                                            key[0].toUpperCase() + key.slice(1)
                                        }
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {(optionValue == OPTIONS_PLATFORM[0] ||
                                barValues?.includes(
                                    Constants.PLATFORM_WEB
                                )) && (
                                <Bar
                                    dataKey="web"
                                    fill="#73A4FF"
                                    background={{ fill: "transparent" }}
                                    name="Web"
                                />
                            )}

                            {(barValues?.includes(
                                Constants.PLATFORM_FLUTTER_ANDROID
                            ) ||
                                barValues?.includes(
                                    Constants.PLATFORM_FLUTTER_IOS
                                )) && (
                                <Bar
                                    dataKey="flutter"
                                    name="Flutter app"
                                    fill="blue"
                                    background={{ fill: "transparent" }}
                                />
                            )}
                            {(barValues?.includes(
                                Constants.PLATFORM_REACT_NATIVE_ANDROID
                            ) ||
                                barValues?.includes(
                                    Constants.PLATFORM_REACT_NATIVE_IOS
                                )) && (
                                <Bar
                                    dataKey="native"
                                    name="Native app"
                                    fill="red"
                                    background={{ fill: "transparent" }}
                                />
                            )}
                        </>
                    )}
                </BarChart>
            </ResponsiveContainer>
        </div>
        // <BarChart
        //     width={650}
        //     height={300}
        //     data={data}
        //     margin={{
        //         top: 5,
        //         right: 30,
        //         left: 20,
        //         bottom: 5,
        //     }}
        //     barSize={20}
        // >
        //     <XAxis
        //         dataKey="name"
        //         scale="point"
        //         padding={{ left: 10, right: 10 }}
        //     />
        //     <YAxis />
        //     <Tooltip />
        //     <Legend />
        //     <CartesianGrid strokeDasharray="3 3" />
        //     <Bar dataKey="value" fill="#8884d8" background={{ fill: "#eee" }} />
        // </BarChart>
    );
};

export default CustomBarChart;
