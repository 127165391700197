import React from "react";

const MenuTab = ({
    menuList,
    tab,
    setTab,
}: {
    menuList: any;
    tab: string;
    setTab: Function;
}) => {
    return (
        <div className="menu">
            {menuList.map((menu: any) => {
                return (
                    <div
                        key={menu.tab}
                        className={tab == menu.tab ? "active" : ""}
                        onClick={() => setTab(menu.tab)}
                    >
                        {menu.title}
                    </div>
                );
            })}
        </div>
    );
};

export default MenuTab;
