import { AppType, IAppInfo } from "../../shared/models/appInfo";

export enum AppTypes {
    GET_APP_INFOS = "GET_APP_INFOS",
    GET_APP_INFOS_SUCCESS = "GET_APP_INFOS_SUCCESS",
    GET_APP_INFOS_FAILED = "GET_APP_INFOS_FAILED",
    UPDATE_APP_INFO = "UPDATE_APP_INFO",
    UPDATE_APP_INFO_SUCCESS = "UPDATE_APP_INFO_SUCCESS",
    UPDATE_APP_INFO_FAILED = "UPDATE_APP_INFO_FAILED",
    DELETE_APP_INFO = "DELETE_APP_INFO",
    DELETE_APP_INFO_SUCCESS = "DELETE_APP_INFO_SUCCESS",
    DELETE_APP_INFO_FAILED = "DELETE_APP_INFO_FAILED",
}

export interface IAppAction {
    type: AppTypes
    appInfos?: IAppInfo[]
    appInfo?: IAppInfo
    error?: any
    appType?: AppType
}

export const getAppInfos = (appType: AppType): IAppAction => {
    return {
        type: AppTypes.GET_APP_INFOS,
        appType
    };
}

export const getAppInfosSuccess = (appInfos: IAppInfo[]): IAppAction => {
    return {
        type: AppTypes.GET_APP_INFOS_SUCCESS,
        appInfos
    };
}

export const getAppInfosFailed = (error: any): IAppAction => {
    return {
        type: AppTypes.GET_APP_INFOS_FAILED,
        error
    };
}

export const updateAppInfo = (appInfo: IAppInfo): IAppAction => {
    return {
        type: AppTypes.UPDATE_APP_INFO,
        appInfo
    };
}

export const updateAppInfoSuccess = (appInfo: IAppInfo): IAppAction => {
    return {
        type: AppTypes.UPDATE_APP_INFO_SUCCESS,
        appInfo
    };
}

export const updateAppInfoFailed = (error: any): IAppAction => {
    return {
        type: AppTypes.UPDATE_APP_INFO_FAILED,
        error
    };
}

export const deleteAppInfo = (appInfo: IAppInfo): IAppAction => {
    return {
        type: AppTypes.DELETE_APP_INFO,
        appInfo
    };
}

export const deleteAppInfoSuccess = (appInfo: IAppInfo): IAppAction => {
    return {
        type: AppTypes.DELETE_APP_INFO_SUCCESS,
        appInfo
    };
}

export const deleteAppInfoFailed = (error: any): IAppAction => {
    return {
        type: AppTypes.DELETE_APP_INFO_FAILED,
        error
    };
}