import { FC } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { IProject } from "../../../../../shared/models/project";
import { SelectOption, convertProjectOptions, onGetOptionsValue } from "../../../../../utils";

interface SelectProjectProps {
    ids: string[];
    onSelected: (ids: string[]) => void;
    isMulti?: boolean;
    disabled?: boolean;
}

interface SelectProjectContentProps extends SelectProjectProps {
    options: SelectOption[];
}

const SelectProjectWidget: FC<SelectProjectProps> = (props) => {
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const options = convertProjectOptions(projects);
    if (props.isMulti) {
        return <SelectMultiProjectWidget {...props} options={options} />;
    }
    return <SelectProject {...props} options={options} />;
};

const SelectProject: FC<SelectProjectContentProps> = ({ options, ids, onSelected, disabled = false }) => {
    return (
        <Select
            className="select-project"
            options={options}
            onChange={(value) => {
                const id = value?.value;
                if (id) {
                    onSelected([id]);
                }
            }}
            isDisabled={disabled}
            value={options.find((p) => ids.includes(p.value))}
        />
    );
};

const SelectMultiProjectWidget: FC<SelectProjectContentProps> = ({ ids, onSelected, options, disabled = false }) => {
    const selected = options.filter((o) => ids.includes(o.value));
    return (
        <Select
            value={selected}
            isMulti
            options={options}
            isDisabled={disabled}
            className="basic-multi-select"
            onChange={(newValue, actionMeta) => {
                let list = onGetOptionsValue(selected, actionMeta);
                onSelected(list.map((t) => t.value));
            }}
        />
    );
};

export default SelectProjectWidget;
