import * as React from 'react'
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { ButtonGroup, DialogContent, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SelectReporters from './selectReporters';
import Url from '../../../util/url';
import Constants from '../../../constant';
import { IUserInfo } from '../../../shared/models/userInfo';


const FormSchedule: React.FC<{
    userList: IUserInfo[];
    currentUser: any;
    open: any;
    setOpen: any;
    chooseSchedule: any;
    setReload: any;
}>  = ({ userList, currentUser, open, setOpen, chooseSchedule, setReload }) => {
    const [schedule, setSchedule] = React.useState<any>({
        title: "",
        schedule: dayjs(new Date()),
        recurrent: Constants.NONE,
        reporters: [],
        statusReporters: Constants.PERSONS
    });
    const [statusReporters, setStatusReporters] = React.useState(Constants.PERSONS);
    const [personsReporters, setPersonsReporters] = React.useState([currentUser._id])
    const [teamsReporters, setTeamsReporters] = React.useState(["technical"])
    const [channelsReporters, setChannelsReporters] = React.useState([Constants.DISCORD_CHANNELS_LIST[0]])

    React.useEffect(() => {
        if (statusReporters === Constants.ALL_TEAMS) {
            setSchedule({...schedule, reporters: [currentUser._id]})
        } else if (statusReporters === Constants.TEAMS) {
            setSchedule({...schedule, reporters: teamsReporters})
        } else if (statusReporters === Constants.PERSONS) {
            setSchedule({...schedule, reporters: personsReporters})
        } else if (statusReporters === Constants.CHANNELS) {
            setSchedule({...schedule, reporters: channelsReporters})
        }
    }, [statusReporters])

    React.useEffect(() => {
        handleChooseSchedule(chooseSchedule)
    }, [open]);

    const handleChooseSchedule = (chooseSchedule: any) => {
        if (chooseSchedule) {
            setSchedule({
                title: chooseSchedule.title,
                schedule: dayjs(new Date(chooseSchedule.schedule)),
                recurrent: chooseSchedule.recurrent,
                statusReporters: chooseSchedule.statusReporters
            });
            setStatusReporters(chooseSchedule.statusReporters)
            if (chooseSchedule.statusReporters === Constants.PERSONS) {
                setPersonsReporters(chooseSchedule.reporters)
            } else if (chooseSchedule.statusReporters === Constants.TEAMS) {
                setTeamsReporters(chooseSchedule.reporters)
            } else if (chooseSchedule.statusReporters === Constants.CHANNELS) {
                setChannelsReporters(chooseSchedule.reporters)
            }
        } else {
            setSchedule({
                title: "",
                schedule: dayjs(new Date()),
                recurrent: Constants.NONE,
                reporters: [currentUser._id],
                statusReporters: Constants.PERSONS
            })
        }
    };

    const handleSubmit = async () => {
        schedule.statusReporters = statusReporters;
        if (statusReporters === Constants.PERSONS) {
            schedule.reporters = personsReporters
        } else if (statusReporters === Constants.TEAMS) {
            schedule.reporters = teamsReporters
        } else if (statusReporters === Constants.CHANNELS) {
            schedule.reporters = channelsReporters
        }
        // console.log("ben trong",schedule)
        try {
            if (!chooseSchedule) {
                const {data: response} = await axios.post(
                    Url(`schedule/create-schedule/${currentUser._id}`),
                    schedule
                );
                setReload(response)
            } else {
                const {data: response} = await axios.patch(
                    Url(`schedule/update-schedule/${chooseSchedule._id}`),
                    schedule
                )
                setReload(response)
            }
            handleClose()
        } catch (error) { }
    };
    // console.log("schedule",channelsReporters)

    const handleDelete = async () => {
        try {
            const {data: response} = await axios.delete(
                Url(`schedule/delete-schedule/${chooseSchedule._id}`)
            )
            setReload(response)
            handleClose()
        } catch (error) { }
    };

    const handleConfirmDelete = () => {
        const statusConfirm = window.confirm("Are you sure ?")
        if (statusConfirm) {
            handleDelete()
        }
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth='xl' sx={{ ".MuiPaper-root": {overflow: "visible"} }}>
            <DialogTitle id="dialog-schedule" color={'blue'}>
                {chooseSchedule ? "Edit schedule" : "New schedule"}
            </DialogTitle>
            <DialogContent>
                <Box 
                sx={{ 
                    '& .MuiTextField-root': { m: 2, width: 500 }
                    }}
                    >
                    <TextField
                    id="title"
                    type={"text"}
                    multiline
                    maxRows={3}
                    label="Title"
                    value={schedule.title}
                    onChange={(e) => {
                        setSchedule({...schedule, title: e.target.value})   
                    }}
                    />
                </Box>
                <Box sx={{ m : 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DateTimePicker
                            label="DateTime picker"
                            value={schedule.schedule}
                            ampm={false} //chuyen dong ho ve dang 24h
                            inputFormat={"DD/MM/YYYY HH"} // fomat datetime
                            onChange={(newValue) => setSchedule({...schedule, schedule: newValue})}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Box>
                <Box>
                    <FormControl
                    sx={{ m: 2, width: 500 }}
                    >
                        <InputLabel id="recurrent">Recurrent</InputLabel>
                        <Select
                        labelId="recurrent"
                        id="recurrent"
                        value={schedule.recurrent}
                        label="recurrent"
                        onChange={(e) => {
                            setSchedule({...schedule, recurrent: e.target.value})
                        }}
                        >
                            <MenuItem value={Constants.NONE}>None</MenuItem>
                            <MenuItem value={Constants.ONE_DAY}>1 day</MenuItem>
                            <MenuItem value={Constants.ONE_WEEK}>1 week</MenuItem>
                            <MenuItem value={Constants.TWO_WEEKS}>2 weeks</MenuItem>
                            <MenuItem value={Constants.ONE_MONTH}>1 month</MenuItem>
                            <MenuItem value={Constants.THREE_MONTHS}>3 months</MenuItem>
                            <MenuItem value={Constants.SIX_MONTHS}>6 months</MenuItem>
                            <MenuItem value={Constants.ONE_YEAR}>1 year</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                sx={{ m: 2 }}
                >
                    <h5 style={{ color: "blue" }}>
                        Reporters
                    </h5>
                    <ButtonGroup 
                    size="small" 
                    defaultValue={statusReporters} 
                    onClick={(e: any) => setStatusReporters(e.target.value)}
                    >
                        <Button 
                        variant={statusReporters === Constants.PERSONS ? "contained" : "outlined"} 
                        value={Constants.PERSONS} 
                        >
                            Persons
                        </Button>
                        <Button 
                        variant={statusReporters === Constants.TEAMS ? "contained" : "outlined"}
                        value={Constants.TEAMS} 
                        >
                            Teams
                        </Button>
                        <Button 
                        variant={statusReporters === Constants.ALL_TEAMS ? "contained" : "outlined"}
                        value={Constants.ALL_TEAMS} 
                        >
                            All Teams
                        </Button>
                        <Button 
                        variant={statusReporters === Constants.CHANNELS ? "contained" : "outlined"}
                        value={Constants.CHANNELS} 
                        >
                            Channels
                        </Button>
                    </ButtonGroup>
                    {
                    (
                        statusReporters === Constants.PERSONS || 
                        statusReporters === Constants.TEAMS ||
                        statusReporters === Constants.CHANNELS
                    ) &&
                    <SelectReporters 
                    statusReporters={statusReporters}
                    userList={userList}
                    personsReporters={personsReporters}
                    setPersonsReporters={setPersonsReporters}
                    teamsReporters={teamsReporters}
                    setTeamsReporters={setTeamsReporters}
                    channelsReporters={channelsReporters}
                    setChannelsReporters={setChannelsReporters}
                    />
                    }
                </Box>
            </DialogContent>

            <DialogActions sx={{ m: 0.75 }}>
                {chooseSchedule &&
                <Button 
                style={{ margin: 10 }}
                onClick={handleConfirmDelete}
                variant="outlined"
                color="error"
                >
                    Delete
                </Button>
                }
                <Button 
                style={{ margin: 10 }}
                onClick={handleClose}
                variant="outlined"
                >
                    Cancel
                </Button>
                <Button 
                style={{ margin: 10 }}
                onClick={handleSubmit}
                variant="contained"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default FormSchedule;
