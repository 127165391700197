import { convertDate } from "../utils";
import { IBasic } from "./basic";
export interface IRatingAppHistory extends IBasic {
    appID : string;
    userID: string;
    managerID: string[];
    note: string;
    time: Date;
}
export class RatingAppHistory implements IRatingAppHistory {
    _id: any;
    appID : string;
    userID: string;
    managerID: string[];
    note: string;
    time: Date;
    constructor(props: any = {}) {

        this._id = props._id;
        this.appID = props.appID ?? "";
        this.managerID = props.managerID ?? [];
        this.userID = props.userID ?? "";
        this.note = props.note ?? "";
        this.time = convertDate(props.time) ?? new Date();
    }
}

export type RatingAppType = "fixing" | "todo" | "fixed" | "waiting";

export interface IRatingApp extends IBasic {
    _id: any;
    date: string;
    appId: string;
    appKey: string;

    totalUser: number; // Rating trung bình
    ratingAverage: number; // Rating trung bình
    badRatingNumber: number; // "Số lượng rating thấp (từ 3 sao trở xuống)"
    mistakeRatingNumber: number; // Số rating thấp nhưng do khách nhầm
    reportMistakeNumber: number;
    npsQuestionNumber: number;

    note: string;
    status: RatingAppType;
    assigneeId: string[]; // người phụ trách

    reviewId: string;

    createDate: number;
    lastUpdate: number;
    histories: RatingAppHistory[];
}

export class RatingApp implements IRatingApp {
    _id: any;
    date: string;
    appId: string;
    appKey: string;

    totalUser: number; // Rating trung bình
    ratingAverage: number; // Rating trung bình
    badRatingNumber: number; // "Số lượng rating thấp (từ 3 sao trở xuống)"
    mistakeRatingNumber: number; // Số rating thấp nhưng do khách nhầm
    reportMistakeNumber: number;
    npsQuestionNumber: number;

    totalQuestionNumber1: number
    totalReportMistake1: number
    totalBadNPS1: number
    totalUser1: number
    totalBadRating1: number

    note: string;
    status: RatingAppType;
    assigneeId: string[]; // người phụ trách

    reviewId: string;

    createDate: number;
    lastUpdate: number;
    histories: RatingAppHistory[];
    constructor(props: any = {}) {
        this._id = props._id;
        this.date = props.date ?? -1;
        this.appId = props.appId ?? "";
        this.appKey = props.appKey ?? "";
        this.totalUser = props.totalUser ?? 0;
        this.ratingAverage = props.ratingNumber ?? 0;
        this.badRatingNumber = props.badRatingNumber ?? 0;
        this.mistakeRatingNumber = props.mistakeRatingNumber ?? 0;
        this.reportMistakeNumber = props.reportMistakeNumber ?? 0;
        this.npsQuestionNumber = props.npsQuestionNumber ?? 0;
        this.note = props.note ?? "";
        this.status = props.status ? (props.status as RatingAppType) : "todo";
        this.assigneeId = [];
        if(typeof props.assigneeId === 'string') {
            this.assigneeId = [props.assigneeId];
        } else if(typeof props.assigneeId === 'object') {
            try {
                this.assigneeId = props.assigneeId?.filter((a: any) => !!a) ?? [];
            } catch(e){}
        }
        this.lastUpdate = props.lastUpdate ?? 0;
        this.createDate = props.createDate ?? 0;
        this.reviewId = props.reviewId ?? "";
        this.histories = [];
        if (typeof props.histories === "object") {
            for (let history of props.histories) {
                this.histories.push(new RatingAppHistory(history));
            }
        }

        this.totalQuestionNumber1 = props.totalQuestionNumber1 ?? 0;
        this.totalReportMistake1 = props.totalReportMistake1 ?? 0;
        this.totalBadNPS1 = props.totalBadNPS1 ?? 0;
        this.totalUser1 = props.totalUser1 ?? 0;
        this.totalBadRating1 = props.totalBadRating1 ?? 0;
    }
}
