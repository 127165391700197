import { useHistory } from "react-router";

const FunctionButton = ({ pageData, active }: { pageData: any; active: boolean }) => {
    const history = useHistory();
    return (
        <a
            onClick={() => {
                history.push({ pathname: `/${pageData.pageName ?? "error_"}` });
            }}
            className={active ? "active" : ""}
        >
            {pageData?.label ?? "error"}
        </a>
    );
};

export default FunctionButton;
