import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import './index.css'
import moment from 'moment';
import { Add, Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import Constants from "../../../constant";
import {
    getFlatForm,
} from "../../../config_data_analytic";
import IsLoading from "../../IsLoading";


const CompareAbTestVersion = ({ abTestData, currentApp, handleFollowReGet }) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [idTest, setIdTest] = React.useState(0)
    const [test, setTest] = React.useState("");
    const [app, setApp] = React.useState("");
    const [abTest, setAbTest] = React.useState(
        {
            active: true,
            title: "",
            startDate: new Date(),
            endDate: new Date()
        });
    const [versions, setVersions] = React.useState([
        {
            idVersion: 0,
            priceBasic: '',
            pricePro: '',
            percentTest: ''
        }
    ]);
    const currentTest = useSelector((state) => state.dashBoardState?.currentCertificates).appID
    const platformIds = [
        Constants.PLATFORM_WEB,
        Constants.PLATFORM_FLUTTER_ANDROID,
        Constants.PLATFORM_FLUTTER_IOS,
        Constants.PLATFORM_REACT_NATIVE_ANDROID,
        Constants.PLATFORM_REACT_NATIVE_IOS,
    ];

    const handleChangeApp = (event) => {
        setApp(event.target.value);
    };
    const handleChangeAbTest = (newValue) => {
        setAbTest(newValue);
    };

    const handleChangeVersions = (newValue, index) => {
        const newVersions = [...versions]
        newVersions.splice(index, 1, newValue)

        if (Number(newValue.percentTest) < 0) {
            newValue.percentTest = ''
        } else if (Number(newValue.priceBasic) < 0) {
            newValue.priceBasic = ''
        } else if (Number(newValue.pricePro) < 0) {
            newValue.pricePro = ''
        }
        setVersions(newVersions)
    };
    
    const handleAddVersions = () => {
        setVersions([
            ...versions,
            {
                idVersion: 0,
                priceBasic: '',
                pricePro: '',
                percentTest: ''
            }
        ])
    };
    const handleDeleteVersions = (index) => {
        const newVersions = [...versions]
        newVersions.splice(index, 1)
        setVersions(newVersions)
    };

    let statusAbTest = true
    const handlePassAbTest = () => {
        statusAbTest = false
        handleSubmit();
    };

    const handleValidation = (objectTest) => {
        let warnings = ''
        objectTest.versions.map((version, index ) => (
            (version.percentTest === 0 ? warnings = `Percent Test ${index + 1} > 0 !!`: 1) &&
            (version.pricePro === 0 ? warnings = `Price Pro ${index + 1} > 0 !!`: 1) &&
            (version.priceBasic === 0 ? warnings = `Price Basic ${index + 1} > 0 !!`: 1) 
            ))
        if (objectTest.abTest.endDate < objectTest.abTest.startDate) {
            warnings = 'End Date must be later than Start Date !!'
        }
        if (warnings === '') {
            return false
        }
        return warnings
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        const objectTest = {
            statusAbTest: statusAbTest,
            idTest: idTest,
            test: test,
            app: app,
            abTest: {
                        ...abTest, 
                        startDate: moment(abTest.startDate).format('YYYY-MM-DD').valueOf(), 
                        endDate: moment(abTest.endDate).format('YYYY-MM-DD').valueOf()
                    },
            versions: versions.map(version => ({
                        ...version,
                        percentTest: Number(version.percentTest),
                        priceBasic: Number(version.priceBasic),
                        pricePro: Number(version.pricePro)
            }))
        }
        const checkWarnings = handleValidation(objectTest);
        if (checkWarnings) {
            setIsLoading(false)
            return (
                toast.warning(`${checkWarnings}`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
            }))
        };

        try {
            if (abTestData) {
                const {data: response} = await axios.post(
                    Constants.API_DASHBOARD_URL+"editComparePrice", 
                    objectTest
                    );
                    toast.success(`Success`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                    setIsLoading(false)
                    handleFollowReGet(response)
                }
            
            else {
                const {data: response} = await axios.post(
                    Constants.API_DASHBOARD_URL+"addComparePrice", 
                    objectTest
                    );
                    toast.success(`Success`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                    setIsLoading(false)
                    handleFollowReGet(response)
                }
            handleClose();
            }
            catch (e) {
                toast.error(`Failed`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setIsLoading(false)
        }
    }

    const handleCheckExistAbTestData = (abTestData) => {
        setTest(currentTest);
        setApp(Number(currentApp))
        if (!abTestData) {
            setAbTest(
                { 
                    title: "",
                    startDate: new Date(),
                    endDate: new Date()     
                }
            );
            setVersions([
                {
                    priceBasic: '',
                    pricePro: '',
                    percentTest: ''
                }
            ]);
        } else if (abTestData) {
            setIdTest(abTestData.id)
            setTest(abTestData.testData.appID);
            setApp(abTestData.appData.platform);
            setAbTest({ 
                title: abTestData.title,
                startDate: new Date(abTestData.startDate),
                endDate: new Date(abTestData.endDate)     
            });
            setVersions(
                abTestData.versions.map(version => ({
                    idVersion: version.id,
                    priceBasic: version.priceBasic,
                    pricePro: version.pricePro,
                    percentTest: version.percentTest
                }))
            );    
        }
    }

    const handleClickOpen = () => {
        handleCheckExistAbTestData(abTestData)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button 
            style={ abTestData && { position: "absolute" , bottom: 20, left: "42%"}}
            variant={abTestData ? "outlined" : "contained"} 
            onClick={handleClickOpen}
            >
                {abTestData ? 'Edit Version' : "New Version"}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth='xl'>
                <Stack>
                    <DialogTitle id="alert-dialog-title" color={'blue'}>
                        {abTestData ? "Edit Version" : "New Version"}
                    </DialogTitle>
                        <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '47%', minHeight: 100 },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <div style={{ marginLeft: 6, marginTop: 30, marginBottom: -20 }}>
                            <FormControl sx = {{ m: 1,  width: '47%' }}>
                                <InputLabel id="app" >
                                    App
                                </InputLabel>
                                <Select
                                    labelId="label app"
                                    id="idApp"
                                    value={app}
                                    label="name"
                                    onChange={handleChangeApp}
                                    >
                                    {platformIds?.map((app, index) => (
                                        <MenuItem key={index} value={app}>{getFlatForm(app).toUpperCase()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                            id="Title"
                            label="Title"
                            value={abTest.title}
                            onChange={(e) => {
                                handleChangeAbTest({...abTest, title: e.target.value})
                            }}
                            />
                            </div>
                        </Box>
                        <Box 
                        className="container" 
                        style={{ 
                            display : 'flex', 
                            marginLeft: 7,
                            }}
                            >
                            <div style={{ marginRight: '10%' }}>
                                <label>Start Date</label>
                                <DatePicker 
                                className="date-picker-abTest"
                                selected={abTest.startDate} 
                                onChange={(date) => {
                                    handleChangeAbTest({...abTest, startDate: date})
                                }} 
                                dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div>
                                <label>End Date</label>
                                <DatePicker 
                                className="date-picker-abTest"
                                selected={abTest.endDate} 
                                onChange={(date) => {
                                    handleChangeAbTest({...abTest, endDate: date})
                                }}  
                                dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </Box>
                        <Box 
                        className="container" 
                        style={{marginTop: 30}}
                        sx={{ 
                            '& .MuiTextField-root': { m: 1, width: 267 }
                            }}
                            >
                            {versions.map((version, index) => (
                                <div>
                                <label style={{marginBottom: 12}}>Version {index + 1}:</label>
                                <div key={index} style={{ display : 'flex' }}>
                                    <TextField
                                    required
                                    id="priceBasic"
                                    label="Price Basic"
                                    type={'number'}
                                    value={version.priceBasic}
                                    onChange={(e) => {
                                        handleChangeVersions({...version, priceBasic: e.target.value}, index)
                                    }}
                                    />
                                    <TextField
                                    required
                                    id="pricePro"
                                    label="Price Pro"
                                    type={'number'}
                                    value={version.pricePro}
                                    onChange={(e) => {
                                        handleChangeVersions({...version, pricePro: e.target.value}, index)
                                    }}
                                    />
                                    <TextField
                                    required
                                    id="percentTest"
                                    label="Percent Test"
                                    type={'number'}
                                    value={version.percentTest}
                                    onChange={(e) => {
                                        handleChangeVersions({...version, percentTest: e.target.value}, index)
                                    }}
                                    />
                                    <IconButton 
                                    disabled={versions.length === 1} 
                                    sx={{ width: '4%'}} 
                                    onClick={() => handleDeleteVersions(index)}
                                    >
                                        <Close color={versions.length === 1 ? 'disabled' : 'error'}/>
                                    </IconButton>
                                </div>
                                </div>
                            ))}
                            <Button 
                            endIcon={<Add/>} 
                            style={{ marginTop: 12 }}
                            size="small" 
                            variant="outlined"
                            onClick={handleAddVersions}
                            >
                                New version
                            </Button>
                        </Box>
                    <DialogActions style={{ marginTop: 12, paddingBottom: 15 }}>
                        {abTestData && abTestData.active === true &&
                        <Button
                        onClick={handlePassAbTest}
                        variant="contained"
                        color="success"
                        >
                            Done
                        </Button>}
                        <Button 
                        onClick={handleClose}
                        variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button 
                        disabled={versions.length < 2}
                        onClick={handleSubmit}
                        variant="contained" 
                        >
                            Submit
                        </Button>
                    </DialogActions>
                    {isLoading && <IsLoading/>}
                </Stack>
            </Dialog>        
        </div>
    )
}

export default CompareAbTestVersion