import React, { CSSProperties, ChangeEvent, FC, useEffect, useState } from "react";
import MButton from "react-bootstrap/Button";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.scss";
import Select, { ActionMeta, MultiValue } from "react-select";
import { IRatingApp, IRatingAppHistory, RatingApp, RatingAppHistory } from "../../../shared/models/ratingApp";
import { FormControl, Modal, ModalFooter } from "react-bootstrap";
import SearchAndSelectUsers from "../../user-group/add-update-user-group-modal/SearchAndSelectUser";
import { convertUserOptions } from "../../../utils";
import { IUserInfo } from "../../../shared/models/userInfo";
import { useDispatch, useSelector } from "react-redux";
import EditorWidget from "../../ckeditor/editor-widget";
import { getAllUserInfo } from "../../../redux/actions/userInfo.action";
import {
    deleteDataratingAppHistoryAction,
    getHistoriesRatingAction,
    getRatingAppAction,
    updateRatingAppticsAction,
    updateRatingHistoriesAppticsAction,
} from "../../../redux/actions/ratingApp.action";
import { convertLink } from "../../../util";
import axios from "axios";
import Url from "../../../util/url";
import SelectTimePeriodWidget from "./SelectTimePeriod";
import HistoryIcon from "@mui/icons-material/History";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
type OptionType = {
    value: string;
    label: string;
};
const AppStatisticsPage = () => {
    // const [startTime, setDateFrom] = useState(new Date());
    // const [finishTime, setDateTo] = useState(new Date());
    const [listAppName, setListAppName] = useState<OptionType[]>([]);
    const [userNumber, setUserNumber] = useState<number>(0);
    const [ratingFilter, setRatingFilter] = useState<number>(0);
    const [badRatingFilter, setBadRatingFilter] = useState<number>(0);
    const [reportNumberFilter, setReportNumberFilter] = useState<number>(0);
    const [badNPSFilter, setBadNPSFilter] = useState<number>(0);
    const [dataItem, setDataItem] = useState<RatingAppHistory>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUserInfo());
        dispatch(getHistoriesRatingAction());
        getDataApiRatingApp();
        // addData();
    }, []);
    const options: OptionType[] = [];
    const handleSelectUserNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBadRatingFilter(0);
        setReportNumberFilter(0);
        setBadNPSFilter(0);
        setRatingFilter(0);
        setUserNumber(event.target.value as number);
    };

    const optionRating = [
        { value: 0, label: null },
        { value: 1, label: "Từ nhiều đến ít" },
        { value: 2, label: "Từ ít đến nhiều" },
    ];
    const handleRatingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBadRatingFilter(0);
        setReportNumberFilter(0);
        setUserNumber(0);
        setBadNPSFilter(0);
        setRatingFilter(event.target.value as number);
    };
    const handleBadRatingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRatingFilter(0);
        setReportNumberFilter(0);
        setUserNumber(0);
        setBadNPSFilter(0);
        setBadRatingFilter(event.target.value as number);
    };
    const handleReportNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRatingFilter(0);
        setBadRatingFilter(0);
        setUserNumber(0);
        setBadNPSFilter(0);
        setReportNumberFilter(event.target.value as number);
    };
    const handleBadNPSChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRatingFilter(0);
        setBadRatingFilter(0);
        setReportNumberFilter(0);
        setUserNumber(0);
        setBadNPSFilter(event.target.value as number);
    };
    // const addData = async () => {
    //     const { data } = await axios.post(Url("rating/calc-rating-statistics?time=1698364800000"));
    // };

    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);

    const handleEditItem = (data: any) => {
        setDataItem(data);
    };
    const onHide = () => {
        setDataItem(undefined);
    };
    const ratingAppHistory: RatingAppHistory[] = useSelector((state: any) => state.appStatisticsState.historiesRating);
    const ratingApp: RatingApp[] = useSelector((state: any) => state.appStatisticsState.ratingApp);

    ratingApp.sort((a, b) => {
        if (a.ratingAverage > b.ratingAverage) {
            return -1;
        }
        if (a.ratingAverage < b.ratingAverage) {
            return 1;
        }
        if (a.reportMistakeNumber > b.reportMistakeNumber) {
            return -1;
        }
        if (a.reportMistakeNumber < b.reportMistakeNumber) {
            return 1;
        }
        if (a.npsQuestionNumber > b.npsQuestionNumber) {
            return -1;
        }
        if (a.npsQuestionNumber < b.npsQuestionNumber) {
            return 1;
        }
        return 0;
    });
    ratingApp.forEach((data) => {
        options.push({ value: data.appKey, label: data.appKey });
    });
    const filteredResults: RatingApp[] = ratingApp.filter((item) => {
        const selectedAppValues = listAppName.map((option) => option.value);
        const appNameCondition = selectedAppValues.length > 0 ? selectedAppValues.includes(item.appKey) : true;
        return appNameCondition;
    });
    const shortDataRating = filteredResults.slice().sort((a, b) => {
        if (userNumber == 2) {
            return a.totalUser - b.totalUser; //sort tu cao xuong thap
        } else if (userNumber == 1) {
            return b.totalUser - a.totalUser;
        } else if (ratingFilter == 2) {
            return a.ratingAverage - b.ratingAverage;
        } else if (ratingFilter == 1) {
            return b.ratingAverage - a.ratingAverage;
        } else if (badRatingFilter == 2) {
            return a.badRatingNumber - b.badRatingNumber;
        } else if (badRatingFilter == 1) {
            return b.badRatingNumber - a.badRatingNumber;
        } else if (reportNumberFilter == 2) {
            return a.reportMistakeNumber - b.reportMistakeNumber;
        } else if (reportNumberFilter == 1) {
            return b.reportMistakeNumber - a.reportMistakeNumber;
        } else if (badNPSFilter == 2) {
            return a.npsQuestionNumber - b.npsQuestionNumber;
        } else if (badNPSFilter == 1) {
            return b.npsQuestionNumber - a.npsQuestionNumber;
        }
        return 0;
    });
    const getDataApiRatingApp = () => {
        const today = new Date();
        const last30Day = new Date(today);
        last30Day.setDate(today.getDate() - 30);
        const epochStartTimestamp = Math.floor(last30Day.getTime());
        const epochFinishTimestamp = Math.floor(today.getTime());
        dispatch(getRatingAppAction(epochStartTimestamp, epochFinishTimestamp));
    };
    const handleSelectChange = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        if (selected) {
            setListAppName(selected as OptionType[]);
        }
    };
    return (
        <>
            <Container className="container-app-statistics" maxWidth="xl">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
                        <span>{"App" + ": "}</span>
                        <div className="contaiener-filter-options">
                            <Select
                                isMulti
                                options={options.sort((a, b) => a.label.localeCompare(b.label))}
                                isClearable={true}
                                isSearchable={true}
                                value={listAppName}
                                onChange={handleSelectChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
                        <span>{"Date" + " : "}</span>
                        <div className="filter-date">
                            <SelectTimePeriodWidget />
                        </div>
                    </Grid>
                </Grid>
                <TableContainer style={{ paddingTop: "20px" }}>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell className="content-header">STT</TableCell>
                            <TableCell className="content-header">Tên App</TableCell>
                            <TableCell className="content-header">
                                <div className="container-item">
                                    <span>Số người dùng</span>
                                    <select className="select" value={userNumber} onChange={handleSelectUserNumberChange}>
                                        {optionRating.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </TableCell>
                            <TableCell className="content-header">
                                <div className="container-item">
                                    <span>Rating hiện tại</span>
                                    <select className="select" value={ratingFilter} onChange={handleRatingChange}>
                                        <option value={0}> </option>
                                        <option value={1}>Từ cao đến thấp</option>
                                        <option value={2}>Từ thấp đến cao</option>
                                    </select>
                                </div>
                            </TableCell>
                            <TableCell className="content-header">
                                <div className="container-item">
                                    <span>Rating {"< 3* "}</span>
                                    <select className="select" value={badRatingFilter} onChange={handleBadRatingChange}>
                                        <option value={0}> </option>
                                        <option value={1}>Từ cao đến thấp</option>
                                        <option value={2}>Từ thấp đến cao</option>
                                    </select>
                                </div>
                            </TableCell>
                            <TableCell className="content-header">
                                <div className="container-item">
                                    <span>Report Mistake</span>
                                    <select className="select" value={reportNumberFilter} onChange={handleReportNumberChange}>
                                        {optionRating.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </TableCell>
                            <TableCell className="content-header">
                                <div className="container-item">
                                    <span>NPS {"< 6"}</span>
                                    <select className="select" value={badNPSFilter} onChange={handleBadNPSChange}>
                                        {optionRating.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </TableCell>
                            <TableCell className="content-header">Người Phụ Trách </TableCell>
                            <TableCell className="content-header">Ghi Chú</TableCell>
                            {/* <TableCell className="content-header">Sửa Dữ Liệu</TableCell> */}
                            <TableCell className="content-header">Lịch Sử</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                        {shortDataRating?.map((data: RatingApp, index: number) => {
                            let dataNote = ratingAppHistory.filter((d) => {
                                return d.appID == data.appId;
                            });
                            const newNote = [...dataNote].reverse();
                            return (
                                <TableRow key={index + "-" + data?.appId}>
                                    <TableCell className="content-body-stt">{index + 1}</TableCell>
                                    <TableCell className="content-body">{data?.appKey}</TableCell>
                                    <TableCell className="content-body">{data?.totalUser}</TableCell>
                                    <TableCell className="content-body">{data?.ratingAverage}</TableCell>
                                    <TableCell className="content-body">{data?.badRatingNumber}</TableCell>
                                    <TableCell className="content-body">{data?.reportMistakeNumber}</TableCell>
                                    <TableCell className="content-body">{data?.npsQuestionNumber}</TableCell>
                                    <TableCell className="content-body">
                                        <div>
                                            {users.map(
                                                (user) =>
                                                newNote[0]?.managerID?.includes(user._id) && (
                                                        <div
                                                            key={user._id}
                                                            style={{
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <span>
                                                                {user.lastName + " "}
                                                                {user.firstName}
                                                            </span>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className="content-body">
                                        {dataNote ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: convertLink(newNote[0]?.note),
                                                }}
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </TableCell>
                                    {/* <TableCell className="content-body">
                                        <AssignmentIcon
                                            className="content-body-icon-change"
                                            onClick={() => {
                                                handleEditItem(data);
                                            }}
                                        />
                                    </TableCell> */}
                                    <TableCell className="content-body">
                                        <HistoryIcon
                                            className="content-body-icon-change"
                                            onClick={() => {
                                                handleEditItem(data);
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </TableContainer>
                <div>{dataItem ? <HistoryModal data={dataItem} onHide={onHide} /> : <div />}</div>
            </Container>
        </>
    );
};
const ModalEditInfoRatingApp: FC<{ onHide: () => void; data: RatingAppHistory; appId: string }> = ({ onHide, data, appId }) => {
    // type OptionType = {
    //     value: string;
    //     label: string;
    // };

    // const [status, setStatus] = useState<RatingAppType | undefined>(undefined);
    const [listUsers, setListUsers] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [note, setNote] = useState<string>(data.note);
    const [time, setTime] = useState<Date>(data.time ?? new Date());
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    // const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    //     const selectedValue: RatingAppType = event.target.value as RatingAppType;
    //     setStatus(selectedValue);
    // };
    useEffect(() => {
        // setStatus(data.status);
        setListUsers(data.managerID);
        setNote(data.note);
        setTime(data.time);
    }, [data]);

    // const statusInfo: OptionType[] = [
    //     { value: "fixing", label: "Fixing" },
    //     { value: "todo", label: "Todo" },
    //     { value: "fixed", label: "Fixed" },
    //     { value: "waiting", label: "Waiting" },
    // ];
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const userOptions = convertUserOptions(users);

    const onUpdate = async () => {
        dispatch(
            updateRatingHistoriesAppticsAction({
                ...data,
                appID: appId,
                managerID: listUsers,
                note: note,
                time: time,
                userID: userInfo._id,
            })
        );
        onHide();
    };
    return (
        <>
            <Modal show={true} centered size="lg" onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>Sửa Thông Tin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ItemPanel
                            title="Lần Cập Nhật Gần Nhất"
                            children={
                                <FormControl
                                    type="date"
                                    value={moment(time, "MM-DD-YYYY").format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        setTime(new Date(e.target.value));
                                    }}
                                />
                            }
                        />
                        {/* <ItemPanel
                            title="Trạng Thái"
                            children={
                                <Form.Select value={status} onChange={handleChange}>
                                    <option value=""></option>
                                    {statusInfo.map((item: OptionType, index: number) => (
                                        <option key={item.value + index} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            }
                        /> */}
                        <ItemPanel
                            title="Người Phụ Trách"
                            children={
                                <SearchAndSelectUsers
                                    ids={listUsers.map((u) => u)}
                                    onChange={(userIds) => {
                                        setListUsers(userIds);
                                    }}
                                    userOptions={userOptions}
                                    label="Manager"
                                />
                            }
                        />
                        <ItemPanel
                            title="Ghi chú"
                            children={
                                <EditorWidget
                                    buttonSave={false}
                                    defaultValue={note}
                                    onChange={(v) => {
                                        setNote(v);
                                    }}
                                />
                            }
                        />
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <MButton onClick={onUpdate}>Update</MButton>
                    <MButton className="close" aria-label="Close" variant="secondary" onClick={onHide}>
                        Close
                    </MButton>
                </ModalFooter>
            </Modal>
        </>
    );
};
const ItemPanel: FC<{ children: React.ReactNode; title: string }> = ({ title, children }) => {
    const itemStyle: CSSProperties = {
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    };
    const itemLabelStyle: CSSProperties = {
        width: 100,
        fontWeight: "600",
    };
    const itemContentStyle: CSSProperties = {
        width: "calc(100% - 100px)",
    };
    return (
        <div style={itemStyle}>
            <label style={itemLabelStyle}>{title}</label>
            <div style={itemContentStyle}>{children}</div>
        </div>
    );
};

const HistoryModal: FC<{ data: any; onHide: () => void }> = ({ data, onHide }) => {
    const [dataItem, setDataItem] = useState<RatingAppHistory>();
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const ratingAppHistory: RatingAppHistory[] = useSelector((state: any) => state.appStatisticsState.historiesRating);
    const dataFilterHistory = ratingAppHistory.filter((item) => item.appID == data?.appId);
    const dispatch = useDispatch();
    // const filteredUsers = users.filter((user) => data?.histories?.find((dt) => dt.userID == user._id));
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    const handleEditItem = (data: RatingAppHistory) => {
        setDataItem(data);
    };
    const onHideHistories = () => {
        setDataItem(undefined);
    };
    const handleDeleteItem = (data: any) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (data) {
                dispatch(deleteDataratingAppHistoryAction(data));
            }
        }
    };
    return (
        <>
            <Modal show={true} centered size="xl" onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>Lịch Sử</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="contained" color="primary" onClick={() => handleEditItem(new RatingAppHistory())}>
                        Thêm
                    </Button>
                    {dataItem && <ModalEditInfoRatingApp onHide={onHideHistories} data={dataItem} appId={data?.appId} />}
                    {dataFilterHistory.map((item, index) => {
                        let filteredUsers = users.filter((user) => {
                            return item.managerID.some((id) => id === user._id);
                        });
                        let name = filteredUsers.map((data) => data?.firstName);
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    margin: "20px",
                                }}
                            >
                                <div
                                    key={index + item._id}
                                    style={{
                                        display: "flex",
                                        fontSize: "17px",
                                    }}
                                >
                                    {index + 1 + " ."}
                                    <div>{name}</div>

                                    <div
                                        style={{
                                            paddingLeft: "5px",
                                            paddingRight: "5px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: convertLink(item.note),
                                        }}
                                    />
                                    {moment(item.time).format("MM-DD-YYYY")}
                                </div>
                                <ModeIcon
                                    onClick={() => handleEditItem(item)}
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                />
                                <DeleteForeverIcon
                                    onClick={() => handleDeleteItem(item)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                        );
                    })}
                </Modal.Body>
                <ModalFooter>
                    <MButton className="close" aria-label="Close" onClick={() => onHide()} variant="secondary">
                        Close
                    </MButton>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AppStatisticsPage;
