import axios from "axios";
import Config from "../../shared/models/config";
import { ISprint } from "../../shared/models/sprint";
import { SprintReport, SprintReportItem } from "../../shared/models/sprintReport";
import { ITask } from "../../shared/models/task";
import Url from "../../util/url";
import downloadObjectAsJson from "../../utils";
import { getAllTaskAPI } from "../sagas/task.saga";

import { IProject } from "../../shared/models/project";
import { IStatus } from "../../shared/models/status";
import { IUserInfo } from "../../shared/models/userInfo";
import { preHandleTasks } from "../../utils";

export async function updateUserScoreAPI(
    sprint: ISprint,
    users: IUserInfo[],
    sprints: ISprint[],
    allStatus: IStatus[],
    projects: IProject[],
    endSprint?: boolean,
    tasksData?: ITask[]
) {
    let tasks: ITask[] | undefined = tasksData;
    if (!tasks) {
        //mergeCode with DatPhit
        tasks = await getAllTaskAPI(sprint._id);
        tasks = preHandleTasks(tasks!, users, sprints, allStatus, projects);
    }
    if (tasks && tasks.length) {
        if (endSprint) {
            try {
                downloadObjectAsJson(tasks, "sprint_" + `task_${sprint.shortId ?? sprint.name ?? sprint._id}` + "_tasks.json");
            } catch (e) {}
        }
        let mapScore: any = {};
        for (let task of tasks) {
            if (task.assigneeId && task.statusView == 1) {
                const userId = task.assigneeId;
                let sprintReport = mapScore[userId];
                if (!sprintReport) {
                    mapScore[userId] = sprintReport = new SprintReport({
                        totalTasks: [],
                        finishedTasks: [],
                        sprint: sprint._id,
                        user: task.assignee,
                        lastUpdate: new Date(),
                    });
                }
                sprintReport.totalTasks.push(
                    new SprintReportItem({
                        taskID: task._id,
                        taskName: task.title,
                        point: task.point,
                        status: task.statusId ?? "",
                        task: task,
                        checkPoint: task.checkPoint,
                    })
                );
                if (task.type === "Bug") {
                    sprintReport.bugTasks.push(
                        new SprintReportItem({
                            taskID: task._id,
                            taskName: task.title,
                            point: task.point,
                            status: task.statusId ?? "",
                            task: task,
                            checkPoint: task.checkPoint,
                        })
                    );
                }
                if (task.statusId == Config.COLUMN_STATUS.DONE.mongoId) {
                    sprintReport.finishedTasks.push(
                        new SprintReportItem({
                            taskID: task._id,
                            taskName: task.title,
                            point: task.point,
                            status: task.statusId ?? "",
                            task: task,
                            checkPoint: task.checkPoint,
                        })
                    );
                }
            }
        }
        const updateData: any = {};
        for (let key of Object.keys(mapScore)) {
            let score = mapScore[key];
            updateData[key] = new SprintReport({
                ...score,
                user: score?.userId,
                finishedTasks:
                    score?.finishedTasks?.map((item: any) => {
                        item.task = null;
                        return item;
                    }) ?? [],
                totalTasks:
                    score?.totalTasks?.map((item: any) => {
                        item.task = null;
                        return item;
                    }) ?? [],
                bugTasks:
                    score?.bugTasks?.map((item: any) => {
                        item.task = null;
                        return item;
                    }) ?? [],
            });
            updateData[key].user = score?.userId;
        }
        if (endSprint) {
            await axios
                .post(Url("sprint/update-reports-version2"), {
                    sprintID: sprint._id,
                    data: JSON.stringify(updateData),
                })
                .then((data) => {
                    // if (endSprint) {
                    try {
                        downloadObjectAsJson(data.data, `sprint_report_${sprint.shortId ?? sprint.name ?? sprint._id}`);
                    } catch (e) {}
                    // }
                    return data.data;
                })
                .catch((e) => {
                    console.log("wwwwwwwwwwwww", e);
                });
        }
        return Object.values(mapScore);
    }
}
