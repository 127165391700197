import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    formatDataCompare,
    OPTIONS_PLATFORM,
} from "../../../../../config_data_analytic";
import Constants from "../../../../../constant";
import { AppInfo, IAppInfo } from "../../../../../models/appinfo.model";
import { ICertificates } from "../../../../../models/certificates.model";
import { getTotalProWebRevenueAPI } from "../../../../../services/traffic.service";
import { formatDate } from "../../../../../util";
import CircularIndeterminate from "../../../../common/icon/Loading";
import CustomBarChart from "../../../charts/bar-chart";

const CompareProRevenue = () => {
    const certificatesSelect: ICertificates[] = useSelector(
        (state: any) => state.dashBoardState.certificatesSelect
    );
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [dataValue, setDataValue] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    let platids: any = [];
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);

    if (optionValue == OPTIONS_PLATFORM[0]) {
        platids = [
            [
                Constants.PLATFORM_FLUTTER_ANDROID,
                Constants.PLATFORM_FLUTTER_IOS,
            ],
            [
                Constants.PLATFORM_REACT_NATIVE_ANDROID,
                Constants.PLATFORM_REACT_NATIVE_IOS,
            ],
            [Constants.PLATFORM_WEB],
        ];
    } else if (optionValue == OPTIONS_PLATFORM[1]) {
        platids = [
            [Constants.PLATFORM_FLUTTER_ANDROID],
            [Constants.PLATFORM_REACT_NATIVE_ANDROID],
        ];
    } else {
        platids = [
            [Constants.PLATFORM_FLUTTER_IOS],
            [Constants.PLATFORM_REACT_NATIVE_IOS],
        ];
    }
    useEffect(() => {
        let slugs: any = [];
        certificatesSelect.forEach((certificate: ICertificates) => {
            slugs.push(String(certificate.slug) ?? "");
        });

        if (slugs.length > 0) {
            setIsLoading(true);
            const getDataFc = async () => {
                let result = await getTotalProWebRevenueAPI(
                    slugs,
                    formatDate(new Date(startTime), "YYYY-MM-DD"),
                    formatDate(new Date(endTime), "YYYY-MM-DD")
                );

                let value: any = {};

                result.forEach((obj: any, index: number) => {
                    let appSelected: IAppInfo = new AppInfo();
                    certificatesSelect.forEach((certificate: ICertificates) => {
                        const appInfos = certificate.appInfos;
                        const app = appInfos?.find(
                            (app: IAppInfo) => app.platformId == 1
                        );
                        if (certificate.slug == obj.slug && app) {
                            appSelected = app;
                        }
                    });

                    value[String(appSelected?.gaID)] = obj.amount ?? 0;
                });

                let dataFormat = formatDataCompare(
                    certificatesSelect,
                    value,
                    platids
                );

                setIsLoading(false);
                setData(result);
                setDataValue(dataFormat);
            };
            getDataFc();
        }
    }, [certificatesSelect, startTime, endTime]);
    useEffect(() => {
        let dataFormat = formatDataCompare(certificatesSelect, data, platids);
        setDataValue(dataFormat);
    }, [optionValue]);

    return (
        <div>
            {isLoading ? (
                <CircularIndeterminate />
            ) : dataValue ? (
                <CustomBarChart
                    data={dataValue}
                    optionValue={optionValue}
                    barValues={[Constants.PLATFORM_WEB]}
                    dataKeyXAxis={"appName"}
                />
            ) : null}
        </div>
    );
};

export default CompareProRevenue;
