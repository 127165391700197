import { RatingApp } from "../../shared/models/ratingApp";
import { RatingAppHistory } from "../../shared/models/ratingApp";
export enum RatingAppTypes {
    GET_RATING_APP = "GET_RATING_APP",
    GET_RATING_APP_SUCCESS = "GET_RATING_APP_SUCCESS",
    GET_RATING_APP_FAILED = "GET_RATING_APP_FAILED",
    UPDATE_RATING_APP = "UPDATE_RATING_APP",
    UPDATE_RATING_APP_SUCCESS = "UPDATE_RATING_APP_SUCCESS",
    UPDATE_RATING_APP_FAILED = "UPDATE_RATING_APP_FAILED",
    GET_HISTORIES_RATING_APP = "GET_HISTORIES_RATING_APP",
    GET_HISTORIES_RATING_APP_SUCCESS = "GET_HISTORIES_RATING_APP_SUCCESS",
    GET_HISTORIES_RATING_APP_FAILED = "GET_HISTORIES_RATING_APP_FAILED",
    UPDATE_HISTORIES_RATING_APP = "UPDATE_HISTORIES_RATING_APP",
    UPDATE_HISTORIES_RATING_APP_SUCCESS = "UPDATE_HISTORIES_RATING_APP_SUCCESS",
    UPDATE_HISTORIES_RATING_APP_FAILED = "UPDATE_HISTORIES_RATING_APP_FAILED",
    DELETE_HISTORIES_RATING_APP = "DELETE_HISTORIES_RATING_APP",
    DELETE_HISTORIES_RATING_APP_SUCCESS = "DELETE_HISTORIES_RATING_APP_SUCCESS",
    DELETE_HISTORIES_RATING_APP_FAILED = "DELETE_HISTORIES_RATING_APP_FAILED",
}

export interface IRatingAppAction {
    type: RatingAppTypes;
    ratingApp?: RatingApp;
    ratingApps?: RatingApp[];
    error?: any;
    startTime?: number;
    finishTime?: number;
    ratingAppHistory?: RatingAppHistory;
    ratingAppHistorys?: RatingAppHistory[];
}

export const updateRatingAppticsAction = (ratingApp: RatingApp): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_RATING_APP,
        ratingApp,
    };
};
export const updateRatingHistoriesAppticsAction = (ratingAppHistory: RatingAppHistory): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_HISTORIES_RATING_APP,
        ratingAppHistory,
    };
};
export const updateRatingHistoriesAppSuccess = (ratingAppHistory: RatingAppHistory): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_HISTORIES_RATING_APP_SUCCESS,
        ratingAppHistory,
    };
};
export const updateRatingHistoriesAppFailed = (error: any): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_HISTORIES_RATING_APP_FAILED,
        error,
    };
};
export const updateRatingAppSuccess = (ratingApp: RatingApp): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_RATING_APP_SUCCESS,
        ratingApp,
    };
};

export const updateRatingAppFailed = (error: any): IRatingAppAction => {
    return {
        type: RatingAppTypes.UPDATE_RATING_APP_FAILED,
        error,
    };
};

export const getRatingAppAction = (startTime: number, finishTime: number): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_RATING_APP,
        startTime,
        finishTime,
    };
};
export const getRatingAppSuccess = (ratingApps: RatingApp[]): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_RATING_APP_SUCCESS,
        ratingApps,
    };
};
export const getSprintExpDataFailed = (err: any): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_RATING_APP_FAILED,
        error: err,
    };
};
export const getHistoriesRatingAction = (): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_HISTORIES_RATING_APP,
    };
};
export const getHistoriesRatingSuccess = (ratingAppHistorys: RatingAppHistory[]): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_HISTORIES_RATING_APP_SUCCESS,
        ratingAppHistorys,
    };
};
export const getHistoriesRatingFailed = (err: any): IRatingAppAction => {
    return {
        type: RatingAppTypes.GET_HISTORIES_RATING_APP_FAILED,
        error: err,
    };
};
export const deleteDataratingAppHistoryAction = (
    ratingAppHistory: RatingAppHistory
): IRatingAppAction => {
    return {
        type: RatingAppTypes.DELETE_HISTORIES_RATING_APP,
        ratingAppHistory,
    };
};
export const deleteDataratingAppHistorySuccess = (
    ratingAppHistory: RatingAppHistory
): IRatingAppAction => {
    return {
        type: RatingAppTypes.DELETE_HISTORIES_RATING_APP_SUCCESS,
        ratingAppHistory,
    };
};
export const deleteDataratingAppHistoryFailed = (
    error: any
): IRatingAppAction => {
    return {
        type: RatingAppTypes.DELETE_HISTORIES_RATING_APP_FAILED,
        error,
    };
};
