import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.css";
import { Input, Modal } from "antd";
import {
    deleteProblem,
} from "../../redux/actions/problemReporting.action";
import {
    IProblemReporting,
    ProblemReporting,
} from "../../shared/models/problemReporting";
import moment from "moment";

export const DeleteProblemReportingModal: FC<{
    problem: ProblemReporting;
    onHide: () => void;
}> = ({ problem, onHide }) => {


    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(deleteProblem(problem));
        onHide();
    };
    return (
        <Modal
            okText="Xoá"
            cancelText="Huỷ"
            open={true}
            onOk={handleOk}
            onCancel={onHide}
            centered
        >
            <p>Bạn chắc chắn muốn xoá vấn đề này?</p>
        </Modal>
    );
};
