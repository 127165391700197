import React, { useEffect, useState } from "react";
import { ICertificates } from "../../../../models/certificates.model";
import MenuTab from "../menu";
import AvgRating from "./avg-rating";
import "./index.scss";
import NumberDownloads from "./number-dowloads";
import NumberLoggedUser from "./number-logged-in-user";
import UninstallDownloads from "./uninstall-app";
import UserGrowthRate from "./user-growth";

const MENU_LIST = [
    //   {
    //     title: "Info",
    //     tab: "info",
    //   },
    {
        title: "User growth rate",
        tab: "user_growth_rate",
    },
    {
        title: "Number of logged in users",
        tab: "number_of_logged_in_users",
    },
    {
        title: "Number of downloads",
        tab: "number_of_downloads",
    },
    {
        title: "Uninstall app",
        tab: "uninstall_app",
    },
    {
        title: "Average rating",
        tab: "average_rating",
    },
];
const WebAppIndexView = ({ certificates }: { certificates: ICertificates }) => {
    const [tab, setTab] = useState(MENU_LIST[0].tab);
    useEffect(() => {
        setTab(MENU_LIST[0].tab);
    }, [certificates.id]);
    return (
        <div className="question-quality-v2 web-app-index">
            <MenuTab tab={tab} setTab={setTab} menuList={MENU_LIST} />
            {tab === "user_growth_rate" ? (
                <div className="user-growth-rate">
                    <UserGrowthRate certificates={certificates} />
                </div>
            ) : tab === "number_of_logged_in_users" ? (
                <div className="number-of-logged-in-users">
                    <NumberLoggedUser certificates={certificates} />
                </div>
            ) : tab === "number_of_downloads" ? (
                <div className="number-of-downloads">
                    <NumberDownloads certificates={certificates} />
                </div>
            ) : tab === "uninstall_app" ? (
                <div className="uninstall-app">
                    <UninstallDownloads certificates={certificates} />
                </div>
            ) : tab === "average_rating" ? (
                <div className="average-rating">
                    <AvgRating certificates={certificates} />
                </div>
            ) : null}
        </div>
    );
};
export default WebAppIndexView;
