import React, { useRef } from "react";
import "./style.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const AssigneeList = ({ arrAssignee, addAssigneeArray }) => {
    const ref = useRef(null);
    const getUser = useSelector((state) => state.sharingState?.userDatas);
    const userFilters = getUser.filter((user) => user.status !== 0 && user.status !== -1);
    return (
        <div className="assignee-list">
            <IconButton onClick={() => ref.current?.scroll({ behavior: "smooth", left: 0 })}>
                <ArrowBackIosIcon />
            </IconButton>
            <div className="knowledge-sharing-filter" ref={ref}>
                {userFilters.map((user, index) => (
                    <Tooltip
                        title={"Sharing Score: " + (user.sharingScore ?? 0)}
                        key={index}
                        TransitionComponent={Zoom}
                        followCursor
                        placement="top"
                    >
                        <div
                            style={{ height: "fit-content" }}
                            onClick={() => {
                                addAssigneeArray(user._id);
                            }}
                        >
                            <img
                                style={arrAssignee.includes(user._id) ? { border: "3px solid #08adff" } : {}}
                                src={user.avatar}
                            />
                            <p>{user.firstName}</p>
                        </div>
                    </Tooltip>
                ))}
            </div>
            <IconButton
                onClick={() =>
                    ref.current?.scroll({
                        behavior: "smooth",
                        left: 3000,
                    })
                }
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
    );
};

export default AssigneeList;
