import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon1 from "../../../src/assets/icon/icon1.png";
import { createcCurriculumVitae } from "../../redux/actions/curriculumVitae.action";
import { CurriculumVitae, ICurriculumVitae } from "../../shared/models/curriculumVitae";
import { EnterCurriculumVitae, IEnterCurriculumVitae } from "../../shared/models/enterCurriculumVitae";
import Url from "../../util/url";
import "./index.scss";

const RecruitmentPage = () => {
    const fullNameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const phoneNumberRef = useRef<HTMLInputElement | null>(null);
    const positionRef = useRef<HTMLSelectElement | null>(null);
    const [cvFile, setCvFile] = useState(null);
    const messageRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState<string | null>(null);
    const [dataForm, setDataForm] = useState<IEnterCurriculumVitae[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [dataTitle, setDataTitle] = useState("");
    const [dataIncome, setDataIncome] = useState("");
    const [dataFrom, setDataFrom] = useState("");
    const [datalLocation, setDataLocation] = useState("");
    const [dataRank, setDataRank] = useState("");
    const [dataLevel, setDataLevel] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const [dataResponsibility, setDataResponsibility] = useState("");
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        handleGetAllCv();
        if (isVisible && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isVisible]);
    useEffect(() => {
        if (positionRef.current) {
            positionRef.current.value = "";
        }
    }, []);

    const handleGetAllCv = async () => {
        const res = await axios.get(Url("enterCurrirecruitment/get-data"));
        if (res.status == 200 && res.data) {
            setDataForm(res.data.map((item: any) => new EnterCurriculumVitae(item)));
        }
    };

    const sendCv = async () => {
        const newSendCV: ICurriculumVitae = new CurriculumVitae({
            fullName: fullNameRef.current?.value ?? "",
            email: emailRef.current?.value ?? "",
            phoneNumber: phoneNumberRef.current?.value ?? "",
            position: positionRef.current?.value ?? "",
            cvUrl: cvFile,
            message: messageRef.current?.value ?? "",
        });
        if (!newSendCV.fullName) {
            toast("You need to add full name!");
            return;
        }
        if (!newSendCV.email) {
            toast("You need to add email!");
            return;
        }
        if (!newSendCV.phoneNumber) {
            toast("You need to add phone number!");
            return;
        }
        if (!newSendCV.position || positionRef?.current?.value === "") {
            toast("You need to add position!");
            return;
        }
        if (!cvFile) {
            toast("You need to add phone cvFile!");
            return;
        }
        checkEmail();
        dispatch(createcCurriculumVitae(newSendCV));
        const req = await axios.post(Url("currirecruitment/set-data"), newSendCV);
        toast("already sent!");
        if (!!fullNameRef?.current) {
            fullNameRef.current.value = "";
        }
        if (!!emailRef?.current) {
            emailRef.current.value = "";
        }
        if (!!phoneNumberRef?.current) {
            phoneNumberRef.current.value = "";
        }
        if (!!positionRef?.current) {
            positionRef.current.value = "";
        }
        if (!!messageRef?.current) {
            messageRef.current.value = "";
        }
        if (cvFile) {
            setCvFile(null);
            setFileName("");
        }
    };

    function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            const fileExtension = file.name.split(".").pop()?.toLowerCase();
            if (fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx") {
                const fileData = new FormData();
                fileData.append("file", file);
                setFileName(file.name);
                axios
                    .post(Url("currirecruitment/upload"), fileData)
                    .then((response) => {
                        // const { fileName, filePath } = response.data;
                        // Xử lý dữ liệu file đã được tải lên thành công từ server
                        setCvFile(response.data);
                        // console.log("response", response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert("Failed to upload file.");
                    });
            } else {
                alert("Only PDF, DOC and DOCX file formats are allowed.");
            }
        }
    }
    let sortData: any[] = [];

    sortData = dataForm?.sort((a: any, b: any) => new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime());
    const newArr: any[][] = [];
    for (let i = 0; i < sortData.length; i += 2) {
        newArr.push([sortData[i], sortData[i + 1]]);
    }

    const date = new Date();
    const today = date.getDate();
    const thisMonth = date.getMonth() + 1;

    const checkEmail = () => {
        if (emailRef.current?.value === "") {
            setShowWarning(true);
        } else if (!emailRef.current?.validity.valid) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    };

    return (
        <div className="container-all">
            <div className="curriculumvitae-page">
                <div className="container-header">
                    <h1 className="title-page">Cơ hội dành cho bạn</h1>
                    <div className="content-header">
                        <div className="container-item">
                            <img className="icon-select" src={Icon1} />
                            <h6>Vị trí lãnh đạo</h6>
                        </div>
                        <div className="container-item">
                            <img className="icon-select" src={Icon1} />
                            <h6>Có kinh nghiệm</h6>
                        </div>
                        <div className="container-item">
                            <img className="icon-select" src={Icon1} />
                            <h6>Mới ra trường</h6>
                        </div>
                        <div className="container-item">
                            <img className="icon-select" src={Icon1} />
                            <h6>Thực tập sinh</h6>
                        </div>
                    </div>
                    <div className="container-silde">
                        <button
                            className="next-silde"
                            style={{
                                visibility: showAll ? "hidden" : "visible",
                            }}
                            ref={navigationPrevRef}
                        >
                            prev
                        </button>
                        {!showAll ? (
                            <div className="box-slide">
                                <Swiper
                                    spaceBetween={1}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    slidesPerView={3}
                                    pagination={{ clickable: true }}
                                    // scrollbar={{ draggable: true }}
                                    onSwiper={(swiper) => console.log("swiper")}
                                    onSlideChange={() => console.log("slide change")}
                                    className="grid-container"
                                    navigation={{
                                        prevEl: navigationPrevRef.current,
                                        nextEl: navigationNextRef.current,
                                    }}
                                >
                                    {newArr?.map((dataRow, index) => (
                                        <SwiperSlide key={index}>
                                            {dataRow?.map((dataCol, index) => (
                                                <div key={index}>
                                                    {dataCol?._id && (
                                                        <div
                                                            id="xxx"
                                                            className="grid-item"
                                                            onClick={() => {
                                                                if (dataCol.title == dataTitle) {
                                                                    setDataTitle("");
                                                                    setIsVisible(false);
                                                                } else {
                                                                    setDataLevel(dataCol?.level);
                                                                    setDataResponsibility(dataCol?.responsibility);
                                                                    setDataTitle(dataCol?.title);
                                                                    setDataFrom(dataCol?.form);
                                                                    setDataIncome(dataCol?.income);
                                                                    setDataRank(dataCol?.rank);
                                                                    setDataLocation(dataCol?.location);
                                                                    setIsVisible(true);
                                                                }
                                                            }}
                                                        >
                                                            <div className="item">
                                                                <h5 className="title">{dataCol?.title}</h5>
                                                                <div className="container-content-item">
                                                                    <div className="box-content">
                                                                        <h6>Tới {dataCol?.income} triệu</h6>
                                                                        <h6>{dataCol?.form}</h6>
                                                                    </div>
                                                                    <div className="box-content">
                                                                        <h6>{dataCol?.rank}</h6>
                                                                        <h6>{dataCol?.location}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="box-language">
                                                                    {dataCol?.language.map((data: string) => (
                                                                        <div className="container-text-language">
                                                                            <h6 className="text-language">{data}</h6>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="title-show">Xem chi tiết</div>
                                                                {thisMonth > dataCol?.dateEnd.getMonth() + 1 ||
                                                                (thisMonth === dataCol?.dateEnd.getMonth() + 1 &&
                                                                    today > dataCol?.dateEnd.getDate()) ? (
                                                                    <div>Hết hạn</div>
                                                                ) : (
                                                                    <div>Còn hạn</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        ) : (
                            <div className="container-show-all">
                                {sortData?.map((data, index) => (
                                    <div
                                        className="grid"
                                        onClick={() => {
                                            if (data?.title === dataTitle) {
                                                setDataTitle("");
                                                setIsVisible(false);
                                            } else {
                                                setDataLevel(data?.level);
                                                setDataResponsibility(data?.responsibility);
                                                setDataTitle(data?.title);
                                                setDataFrom(data?.form);
                                                setDataIncome(data?.income);
                                                setDataRank(data?.rank);
                                                setDataLocation(data?.location);
                                                setIsVisible(true);
                                            }
                                        }}
                                    >
                                        <div className="item">
                                            <h5 className="title">{data?.title}</h5>
                                            <div className="container-content-item">
                                                <div className="box-content">
                                                    <h6>Tới {data?.income} triệu</h6>
                                                    <h6>{data?.form}</h6>
                                                </div>
                                                <div className="box-content">
                                                    <h6>{data?.rank}</h6>
                                                    <h6>{data?.location}</h6>
                                                </div>
                                            </div>
                                            <div className="box-language">
                                                {data?.language.map((data: string) => (
                                                    <div className="container-text-language">
                                                        <h6 className="text-language">{data}</h6>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="title-show">Xem chi tiết</div>
                                            {thisMonth > data?.dateEnd.getMonth() + 1 ||
                                            (thisMonth === data?.dateEnd.getMonth() + 1 && today > data?.dateEnd.getDate()) ? (
                                                <div>Hết hạn</div>
                                            ) : (
                                                <div>Còn hạn</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <button
                            className="next-silde"
                            style={{
                                visibility: showAll ? "hidden" : "visible",
                            }}
                            ref={navigationNextRef}
                        >
                            next
                        </button>
                    </div>
                    <Button className="btn-send" onClick={() => setShowAll(!showAll)}>
                        {showAll ? "Thu gọn" : "Xem tất cả"}
                    </Button>
                </div>
                <div ref={itemRef}>
                    {isVisible && (
                        <div className="container-body">
                            <h1 className="title">{dataTitle}</h1>
                            <div className="container-info">
                                <div className="box-title">
                                    <img className="icon" src={Icon1} />
                                    <div className="box">
                                        <h6 className="tilte-content">Thu nhập</h6>
                                        <h5 className="text-content">Tới {dataIncome} triệu</h5>
                                    </div>
                                </div>
                                <div className="box-title">
                                    <img className="icon" src={Icon1} />
                                    <div className="box">
                                        <h6 className="tilte-content">Cấp bậc</h6>
                                        <h5 className="text-content">{dataRank}</h5>
                                    </div>
                                </div>
                                <div className="box-title">
                                    <img className="icon" src={Icon1} />
                                    <div className="box">
                                        <h6 className="tilte-content">Hình thức</h6>
                                        <h5 className="text-content">{dataFrom}</h5>
                                    </div>
                                </div>
                                <div className="box-title">
                                    <img className="icon" src={Icon1} />
                                    <div className="box">
                                        <h6 className="tilte-content">Địa điểm</h6>
                                        <h5 className="text-content">{datalLocation}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="container-list">
                                <h4 className="title-ul">Trình độ</h4>
                                <h5 className="text-li">{dataLevel}</h5>
                                <h4 className="title-ul">Trách nhiệm</h4>
                                <h5 className="text-li">{dataResponsibility}</h5>
                            </div>
                        </div>
                    )}
                </div>
                <div className="container-bottom">
                    <h1 className="title-page">Nộp CV ứng tuyển</h1>
                    <div className="input-page">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <FormControl ref={fullNameRef} placeholder="Họ và tên" type="text"></FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    ref={emailRef}
                                    placeholder="Email"
                                    type="email"
                                    isInvalid={showWarning}
                                ></FormControl>
                                <FormControl.Feedback type="invalid">
                                    Vui lòng nhập một địa chỉ email hợp lệ.
                                </FormControl.Feedback>
                            </Grid>
                            <Grid item xs={6}>
                                <IMaskInput
                                    className="input-type-number"
                                    mask="000 000 000 000"
                                    definitions={{
                                        "#": /[1-9]/,
                                    }}
                                    inputRef={phoneNumberRef}
                                    overwrite
                                    placeholder="Phone Number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Form.Select ref={positionRef} defaultValue={""}>
                                    <option value="" />
                                    {dataForm?.map((data, index) => (
                                        <option key={index} value={data?.title}>
                                            {data?.title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Grid>
                        </Grid>
                    </div>
                    <h5 className="text-upload">Tải lên CV của bạn</h5>
                    <h6>(Hãy tải lên CV của bạn ở định dạng .doc .docx .pdf không quá 5MB)</h6>

                    <label htmlFor="input-field" className="box-upload">
                        <div className="box-icon">
                            <img className="icon" src={Icon1} />
                            <h5 className="text-file">Chọn file hoặc kéo thả vào đây</h5>
                        </div>
                        <input
                            type="file"
                            className="input-field"
                            multiple
                            id="input-field"
                            hidden
                            accept=".doc,.docx,.pdf"
                            onChange={handleFileInputChange}
                        />
                        <a id="file-preview">{fileName}</a>
                    </label>
                    <div className="container-text-message">
                        <TextField
                            placeholder="Lời nhắn đến ABC"
                            className="text-message"
                            inputRef={messageRef}
                            multiline
                            rows={4}
                        ></TextField>
                    </div>
                    <div className="container-btn">
                        <Button className="btn-send" onClick={sendCv}>
                            Gửi
                        </Button>
                        <h5 className="text-or">Hoặc</h5>
                    </div>
                    <div className="bottom-page">
                        <div className="link-email">
                            <h5>Gửi CV qua mail :</h5>
                            <div className="link-icon">
                                <img className="icon" src={Icon1} />
                                <a className="link-email">hr@abc-elearning.org</a>
                            </div>
                        </div>
                        <div className="link-phone-number">
                            <h5>Liên hệ số điện thoại :</h5>
                            <div className="link-icon">
                                <img className="icon" src={Icon1} />
                                <a className="link-phone">0978 346 306</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruitmentPage;
