import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    formatDataCompare,
    OPTIONS_PLATFORM,
} from "../../../../../config_data_analytic";
import Constants from "../../../../../constant";
import { IAppInfo } from "../../../../../models/appinfo.model";
import { ICertificates } from "../../../../../models/certificates.model";
import { getTotalAdsRevenueAppAPI } from "../../../../../services/traffic.service";
import { formatDate } from "../../../../../util";
import CircularIndeterminate from "../../../../common/icon/Loading";
import CustomBarChart from "../../../charts/bar-chart";

const CompareAdsRevenue = () => {
    const certificatesSelect: ICertificates[] = useSelector(
        (state: any) => state.dashBoardState.certificatesSelect
    );
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [dataValue, setDataValue] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    let platids: any = [];
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);

    if (optionValue == OPTIONS_PLATFORM[0]) {
        platids = [
            [
                Constants.PLATFORM_FLUTTER_ANDROID,
                Constants.PLATFORM_FLUTTER_IOS,
            ],
            [
                Constants.PLATFORM_REACT_NATIVE_ANDROID,
                Constants.PLATFORM_REACT_NATIVE_IOS,
            ],
            [Constants.PLATFORM_WEB],
        ];
    } else if (optionValue == OPTIONS_PLATFORM[1]) {
        platids = [
            [Constants.PLATFORM_FLUTTER_ANDROID],
            [Constants.PLATFORM_REACT_NATIVE_ANDROID],
        ];
    } else {
        platids = [
            [Constants.PLATFORM_FLUTTER_IOS],
            [Constants.PLATFORM_REACT_NATIVE_IOS],
        ];
    }
    useEffect(() => {
        // let params: any = [];
        // certificatesSelect.forEach((certificate: ICertificates) => {
        //     const appInfos = certificate.appInfos;

        //     if (appInfos) {
        //         appInfos.forEach((appInfo: IAppInfo) => {
        //             if (appInfo.gaID && appInfo.gaID != 0) {
        //                 if (appInfo.platformId == 1) {
        //                     params.push({
        //                         streamId: String(appInfo.gaID) ?? "",
        //                         type: appInfo.platformId,
        //                     });
        //                 }
        //             }
        //         });
        //     }
        // });

        setIsLoading(true);
        const getDataFc = async () => {
            const result = await getTotalAdsRevenueAppAPI(
                formatDate(new Date(startTime), "YYYY-MM-DD"),
                formatDate(new Date(endTime), "YYYY-MM-DD")
            );

            let dataFormat = formatDataCompare(
                certificatesSelect,
                result,
                platids
            );

            setIsLoading(false);
            setData(result);
            setDataValue(dataFormat);
        };
        getDataFc();
    }, [certificatesSelect, startTime, endTime]);
    useEffect(() => {
        let dataFormat = formatDataCompare(certificatesSelect, data, platids);
        setDataValue(dataFormat);
    }, [optionValue]);

    return (
        <div>
            <div
                style={{
                    width: "fit-content",
                    marginLeft: "auto",
                }}
            >
                <Form.Select onChange={(e) => setOptionValue(e.target.value)}>
                    {OPTIONS_PLATFORM.map((op) => (
                        <option
                            selected={optionValue == op}
                            value={op}
                            key={op}
                        >
                            {op.toUpperCase()}
                        </option>
                    ))}
                </Form.Select>
            </div>
            {isLoading ? (
                <CircularIndeterminate />
            ) : dataValue ? (
                <CustomBarChart
                    data={dataValue}
                    barValues={[
                        Constants.PLATFORM_FLUTTER_ANDROID,
                        Constants.PLATFORM_FLUTTER_IOS,
                        Constants.PLATFORM_REACT_NATIVE_ANDROID,
                        Constants.PLATFORM_REACT_NATIVE_IOS,
                    ]}
                    dataKeyXAxis={"appName"}
                />
            ) : null}
        </div>
    );
};

export default CompareAdsRevenue;
