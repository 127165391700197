// import EditIcon from '@material-ui/icons/Edit';
import InventoryIcon from "@mui/icons-material/Inventory";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditIcon from "../../../../assets/images/Edit.svg";
import defaultAvatar from "../../../../assets/images/default-avatar.png";
import Constants from "../../../../constant";
import { archiveCampaignSuccess } from "../../../../redux/actions/campaign.action";
import { ICampaign } from "../../../../shared/models/campaign";
import Config from "../../../../shared/models/config";
import { IProject } from "../../../../shared/models/project";
import { IUserGroup } from "../../../../shared/models/userGroup";
import { IUserInfo } from "../../../../shared/models/userInfo";
import Url from "../../../../util/url";
import MyAvatar from "../../../avatar";
import { ISprintCampaignProgress } from "../campaign-progress";
import "./styles.scss";
export const archiveCampaign = async (
    userId: string,
    campaignId: string,
    isArchive: boolean,
    callback: (isOk: boolean, isArchive: boolean) => void
) => {
    await axios
        .post(Url("campaign/archive"), { userId, campaignId })
        .then((response) => {
            callback(response?.data?.success, isArchive);
        })
        .catch((err) => {
            toast("Lưu không thành công", {
                type: "error",
                autoClose: 500,
                position: "top-center",
            });
            console.log(err);
            return "error";
        });
};
const CampaignProgressReview: FC<{
    campaign: ICampaign;
    openDialog: () => void;
    selectedCampaignIds: string[];
    onSelected: () => void;
    sprintProgress: ISprintCampaignProgress;
    userId: string | undefined;
}> = ({ campaign, openDialog, selectedCampaignIds, onSelected, sprintProgress, userId }) => {
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects);
    const [showCampaignPreview, serShowCampaignPreview] = useState(false);
    const reporter = users.find((user) => user._id === campaign.reporterId);
    const [openMore, setOpenMore] = useState(false);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups ?? []);

    const extractDate = (date: Date) => {
        if (date) {
            let dateStr = date.toDateString();
            let extractDate = dateStr.split(" ");
            return {
                day: extractDate[2],
                monthYear: extractDate[1] + " " + extractDate[3].substr(2, 2),
            };
        } else {
            return {
                day: "",
                monthYear: "",
            };
        }
    };
    const [archive, setArchive] = useState(!!campaign.archive);
    useEffect(() => {
        setArchive(!!campaign?.archive);
    }, [campaign]);
    const dispatch = useDispatch();
    const getCampaignDuration = (startDate: Date, deadline: Date) => {
        if (deadline && startDate) {
            let now = new Date();
            let start = startDate;
            let end = deadline;
            let diffTimeDaysleft = end.getTime() - now.getTime();
            if (diffTimeDaysleft < 0) {
                return {
                    percent: 100,
                    daysLeft: 0,
                };
            }
            let diffTimeDurationCampaign = Math.abs(end.getTime() - start.getTime());
            let campaignduration = Math.ceil(diffTimeDurationCampaign / (1000 * 60 * 60 * 24));
            let daysLeft = Math.ceil(diffTimeDaysleft / (1000 * 60 * 60 * 24));
            let daysPass = campaignduration - daysLeft;
            let percent = Math.ceil((daysPass / campaignduration) * 100);
            return {
                percent,
                daysLeft,
            };
        } else {
            return null;
        }
    };
    const callbackArchive = (isOk: boolean, isArchive: boolean) => {
        if (isOk) {
            if (isArchive)
                toast(() => <>Lưu campaign thành công</>, {
                    type: "success",
                    autoClose: 500,
                    position: "top-center",
                });
            dispatch(archiveCampaignSuccess(campaign._id));
        } else {
            setArchive(archive);
            toast("Lưu không thành công", {
                type: "error",
                position: "top-center",
                autoClose: 500,
            });
        }
    };
    const onArchiveCampaign = () => {
        setArchive(!archive);
        setTimeout(() => {
            archiveCampaign(userInfo._id, campaign._id, !campaign?.archive, callbackArchive);
        }, 300);
    };
    const getHeaderCampaignClass = (status: string) => {
        switch (status) {
            case Constants.STATUS_CAMPAIGN_COMPLETE[0]: {
                return "campaign-success";
            }
            case Constants.STATUS_CAMPAIGN_COMPLETE[1]: {
                return "campaign-fail";
            }
            default: {
                return "";
            }
        }
    };
    const isUserGroups: IUserGroup[] = userGroups.filter((group) => group.users.some((user) => user.userId === userInfo._id)); //check xem user co trong group khong
    let checkPermission: boolean | undefined;
    isUserGroups.map((data, index) => {
        data?.users?.forEach((d) => {
            d?.permission?.find((dt) => {
                if(d.permission !== undefined){
                    if (d?.permission.length > 0 && dt === Config.PERMISSION_EDITOR_CAMPAIGN) {
                        checkPermission = true;
                    }
                }
                
            });
             
        });
    }); // check quyen cua user
    let isLeader = false;
    userGroups.forEach((group) => {
        let users = group.users;
        users.forEach((user) => {
            if (user.userId === userInfo._id && user.role === "lead") isLeader = true;
        });
    });
    let checkUserCreateCampaign = userId === userInfo._id;
    const checkRoleToEditCampaign = () => {
        if ((checkUserCreateCampaign && checkPermission) || isLeader) {
            return true;
        }
        return false;
    };

    return (
        <div
            className={`campaign-box ${selectedCampaignIds.indexOf(campaign._id) > -1 ? "active-campaign" : ""} radius-12`}
            style={{ position: "relative" }}
            onClick={(event) => {
                let e = event.target as HTMLDivElement;
                if (
                    e.classList.contains("edit-button") ||
                    e.classList.contains("edit-button-icon") ||
                    e.classList.contains("button-show-desc")
                ) {
                    openDialog();
                } else {
                    onSelected();
                }
            }}
        >
            <Collapse in={openMore}>
                {/*  */}
                <div
                    style={{
                        borderBottom: "2px solid rgba(255,255,255,0.08)",
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                    }}
                >
                    <div className="flex" style={{ padding: "4px 15px", cursor: "default" }}>
                        <div
                            style={{ flex: 1 }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onArchiveCampaign();
                                }}
                                className="white align-item-center"
                                style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                }}
                            >
                                {archive ? (
                                    <>
                                        <InventoryIcon htmlColor="#fff" />
                                        <span style={{ marginLeft: "6px" }}>Unarchive</span>
                                    </>
                                ) : (
                                    <>
                                        <Inventory2OutlinedIcon htmlColor="#fff" />
                                        <span style={{ marginLeft: "6px" }}>Archive</span>
                                    </>
                                )}
                            </div>
                        </div>

                        {checkRoleToEditCampaign() && (
                            <div className="align-item-center" style={{ flex: 1, marginLeft: "4px" }}>
                                <div
                                    className="edit-button align-item-center"
                                    style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                    }}
                                >
                                    <img className="edit-button-icon" width={22} height={22} src={EditIcon} />
                                    <span className="edit-button-icon white" style={{ marginLeft: "6px" }}>
                                        Edit
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>
            <div className={`header ${getHeaderCampaignClass(campaign.status)}`}>
                <div className="flex">
                    <div className="left">
                        {!showCampaignPreview && (
                            <div className="assignee">
                                <MyAvatar url={reporter?.avatar} />
                            </div>
                        )}
                        {campaign.priority && (
                            <div className="type">
                                <img className="campaign-icon" src={"/" + campaign.priority + "_priority.svg"} />
                            </div>
                        )}
                    </div>
                    <div className="title" style={{ paddingRight: "16px" }}>
                        {campaign.title}
                    </div>
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setOpenMore(!openMore);
                    }}
                    style={{ position: "absolute", right: "5px", top: "5px" }}
                >
                    <PendingOutlinedIcon htmlColor="#fff" />
                </div>
                {/* <div className="flex">
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onArchiveCampaign();
                        }}
                    >
                        {archive ? (
                            <BookmarkIcon htmlColor="#fff" />
                        ) : (
                            <BookmarkBorderIcon htmlColor="#fff" />
                        )}
                    </div>
                    <div className="right" style={{ marginLeft: "4px" }}>
                        <div className="edit-button">
                            <img
                                className="edit-button-icon"
                                width={24}
                                height={24}
                                src={EditIcon}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
            {showCampaignPreview && (
                <div className="campaign-content">
                    <div className="line">
                        <div className="line-2">
                            <div className="line-2-left">
                                <div className="assignee">
                                    <MyAvatar url={reporter?.avatar} />
                                </div>
                                {projects
                                    .filter((project) => campaign.projectIds.indexOf(project._id) !== -1)
                                    .map((e) => (
                                        <div className="wrap-project" key={"campaign_" + e._id}>
                                            <div className="project">{e?.title}</div>
                                        </div>
                                    ))}
                            </div>
                            {campaign.description !== undefined && campaign.description.length > 0 && (
                                <Button className="button-show-desc" size="sm" variant="secondary">
                                    Show Description
                                </Button>
                            )}
                        </div>
                        <div className="line-3">
                            <ProgressBar className="custom-progress">
                                <ProgressBar
                                    variant="success"
                                    now={sprintProgress.doneCurrentSprintPercent}
                                    label={`Done: ${sprintProgress.doneCurrentSprintPercent}%`}
                                    key={1}
                                />
                                <ProgressBar
                                    variant="info"
                                    now={sprintProgress.inProgressCurrentSprintPercent}
                                    label={`In Progress: ${sprintProgress.inProgressCurrentSprintPercent}%`}
                                    key={2}
                                />
                            </ProgressBar>
                        </div>
                        <div className="break-line"></div>
                        <div className="line-4">
                            <div className="type">
                                <img className="target-icon" src={"/target.svg"} />
                            </div>
                            <div className="title">{campaign.target}</div>
                        </div>
                        <div className="line-5">
                            <div className="campaign-done-percent">{campaign.donePercent}%</div>
                            <ProgressBar className="custom-progress">
                                <ProgressBar
                                    variant="success"
                                    now={campaign.donePercent}
                                    style={{
                                        background: "linear-gradient(270deg,#4dffdf 0%, #4da1ff 96.35%)",
                                    }}
                                />
                            </ProgressBar>
                        </div>
                        {campaign.deadline && (
                            <div className="line-6">
                                <div className="calander">
                                    <div className="calander-header start-date"></div>
                                    <div className="calander-day">
                                        {campaign.startDate ? extractDate(campaign.startDate).day : ""}
                                    </div>
                                    <div className="calander-month-year">
                                        {campaign.startDate ? extractDate(campaign.startDate).monthYear : ""}
                                    </div>
                                </div>
                                <div className="days-left">
                                    <img className="timer-icon" src={"/timer.svg"} />
                                    {campaign.startDate
                                        ? getCampaignDuration(campaign.startDate, campaign.deadline)?.daysLeft + " days left"
                                        : ""}
                                </div>
                                <div className="left-duration">
                                    <ProgressBar className="left-duration-height">
                                        <ProgressBar
                                            className="percent-progress"
                                            now={
                                                campaign.startDate
                                                    ? getCampaignDuration(campaign.startDate, campaign.deadline)?.percent
                                                    : 0
                                            }
                                        />
                                    </ProgressBar>
                                </div>
                                <div className="calander">
                                    <div className="calander-header deadline"></div>
                                    <div className="calander-day">{extractDate(campaign.deadline).day}</div>
                                    <div className="calander-month-year">{extractDate(campaign.deadline).monthYear}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="footer">
                {!showCampaignPreview && (
                    <div className="campaign-done-percent-wrapper">
                        <ProgressBar className="custom-progress">
                            <ProgressBar
                                variant="success"
                                now={sprintProgress.doneCurrentSprintPercent}
                                label={`Done: ${sprintProgress.doneCurrentSprintPercent}%`}
                                key={1}
                            />
                            <ProgressBar
                                variant="info"
                                now={sprintProgress.inProgressCurrentSprintPercent}
                                label={`In Progress: ${sprintProgress.inProgressCurrentSprintPercent}%`}
                                key={2}
                            />
                        </ProgressBar>
                    </div>
                )}
                <div
                    className="wrap-icon-footer"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        serShowCampaignPreview(!showCampaignPreview);
                    }}
                >
                    <img src={showCampaignPreview ? "/hide-campaign.svg" : "/show-campaign.svg"} />
                </div>
            </div>
        </div>
    );
};

export default CampaignProgressReview;
