import './style.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import IsLoading from '../../IsLoading';
import EditIcon from '../../../assets/images/Edit.svg';

const ShowPost = () => {
    const post_name: any = useParams();
    const [post, setPost] = useState('');
    const [title, setTitle] = useState('');
    const [postID, setPostID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchWikiPosts = async () => {
        setIsLoading(true);
        const resPost = await axios.post('https://wiki.abc-elearning.org/wp-json/passemall/v1/get-post-by-id', { 'post_name': post_name.id });
        setTitle(resPost.data.post_title);
        setPost(resPost.data.post_content);
        setPostID(resPost.data.ID);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchWikiPosts();
    }, []);

    const handleClickEditPost = () => {
        window.open(`https://wiki.abc-elearning.org/wp-admin/post.php?post=${postID}&action=edit`);
    }

    const newPost = post.replace(/-\d{0,10000}x\d{0,10000}/g, '')
        .replace(/passemall.com/g, 'abc-elearning.org')
        .replace(/\[/g, '<').replace(/\]/g, '>')

    return (
        <div className="test-post container">
            {isLoading && <IsLoading />}
            <div className="edit-post" onClick={handleClickEditPost}>
                <img src={EditIcon} />
                <h4>Edit Post</h4>
            </div>
            <h2 className="post-title">{title}</h2>
            <div className="post-content container" dangerouslySetInnerHTML={{ __html: newPost }}></div>
        </div>
    )
}

export default ShowPost;