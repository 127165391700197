import { IStatus } from "../../shared/models/status";

export enum StatusTypes {
    GET_ALL_STATUS = "GET_ALL_STATUS",
    GET_ALL_STATUS_SUCCESS = "GET_ALL_STATUS_SUCCESS",
    GET_ALL_STATUS_FAILED = "GET_ALL_STATUS_FAILED",
}

export interface IStatusAction {
    type: StatusTypes
    status?: IStatus[]
    error?: any
}

export const getAllStatus = (): IStatusAction => {
    return {
        type: StatusTypes.GET_ALL_STATUS,
    }
}

export const getAllStatusSuccess = (status: IStatus[]): IStatusAction => {
    return {
        type: StatusTypes.GET_ALL_STATUS_SUCCESS,
        status,
    }
}

export const getAllStatusFailed = (error: any): IStatusAction => {
    return {
        type: StatusTypes.GET_ALL_STATUS_FAILED,
        error,
    }
}