import { IAppVersion } from "../../shared/models/appVersion";

export enum AppVersionTypes {
    GET_APP_VERSION = "GET_APP_VERSION",
    GET_APP_VERSION_SUCCESS = "GET_APP_VERSION_SUCCESS",
    GET_APP_VERSION_FAILED = "GET_APP_VERSION_FAILED",
    UPDATE_APP_VERSION = "UPDATE_APP_VERSION",
    UPDATE_APP_VERSION_SUCCESS = "UPDATE_APP_VERSION_SUCCESS",
    UPDATE_APP_VERSION_FAILED = "UPDATE_APP_VERSION_FAILED",
    DELETE_APP_VERSION = "DELETE_APP_VERSION",
    DELETE_APP_VERSION_SUCCESS = "DELETE_APP_VERSION_SUCCESS",
    DELETE_APP_VERSION_FAILED = "DELETE_APP_VERSION_FAILED",
    ADD_INFO_VERSION = "ADD_INFO_VERSION",
    ADD_INFO_VERSION_SUCCESS = "ADD_INFO_VERSION_SUCCESS",
    ADD_INFO_VERSION_FAILED = "ADD_INFO_VERSION_FAILED",
    DELETE_INFO_VERSION = "DELETE_INFO_VERSION",
    DELETE_INFO_VERSION_SUCCESS = "DELETE_INFO_VERSION_SUCCESS",
    DELETE_INFO_VERSION_FAILED = "DELETE_INFO_VERSION_FAILED",
    GET_INFO_VERSION = "GET_INFO_VERSION",
    GET_INFO_VERSION_SUCCESS = "GET_INFO_VERSION_SUCCESS",
    GET_INFO_VERSION_FAILED = "GET_INFO_VERSION_FAILED",
    UPDATE_INFO_VERSION = "UPDATE_INFO_VERSION",
    UPDATE_INFO_VERSION_SUCCESS = "UPDATE_INFO_VERSION_SUCCESS",
    UPDATE_INFO_VERSION_FAILED = "UPDATE_INFO_VERSION_FAILED",
}

export interface IAppVersionAction {
    type: AppVersionTypes;
    appVersions?: IAppVersion[];
    appVersion?: IAppVersion;
    error?: any;
    newAddInfoVersion?: IAppVersion;
    versionId?: number;
}

export const getAppVersions = (): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_APP_VERSION,
    };
};

export const getAppVersionsSuccess = (
    appVersions: IAppVersion[]
): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_APP_VERSION_SUCCESS,
        appVersions,
    };
};

export const getAppVersionsFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_APP_VERSION_FAILED,
        error,
    };
};

export const updateAppVersion = (
    appVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_APP_VERSION,
        appVersion,
    };
};

export const updateAppVersionsSuccess = (
    appVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_APP_VERSION_SUCCESS,
        appVersion,
    };
};

export const updateAppVersionsFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_APP_VERSION_FAILED,
        error,
    };
};

export const deleteAppVersion = (
    appVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_APP_VERSION,
        appVersion,
    };
};

export const deleteAppVersionSuccess = (
    appVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_APP_VERSION_SUCCESS,
        appVersion,
    };
};

export const deleteAppVersionFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_APP_VERSION_FAILED,
        error,
    };
};

export const addInfoVersionAction = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.ADD_INFO_VERSION,
        newAddInfoVersion,
    };
};
export const addInfoVersionActionSuccess = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.ADD_INFO_VERSION_SUCCESS,
        newAddInfoVersion,
    };
};
export const addInfoVersionActionFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.ADD_INFO_VERSION_FAILED,
        error,
    };
};
export const deleteInforVersionAction = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_INFO_VERSION,
        newAddInfoVersion,
    };
};

export const deleteInfoVersionSuccess = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_INFO_VERSION_SUCCESS,
        newAddInfoVersion,
    };
};

export const deleteInfoVersionFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.DELETE_INFO_VERSION_FAILED,
        error,
    };
};

export const getInfoVersionAcation = (versionId: number): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_INFO_VERSION,
        versionId,
    };
};

export const getInfoVersionsSuccess = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_INFO_VERSION_SUCCESS,
        newAddInfoVersion,
    };
};

export const getinfoVersionsFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.GET_INFO_VERSION_FAILED,
        error,
    };
};

export const updateInfoVersion = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_INFO_VERSION,
        newAddInfoVersion,
    };
};

export const updateInfoVersionsSuccess = (
    newAddInfoVersion: IAppVersion
): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_INFO_VERSION_SUCCESS,
        newAddInfoVersion,
    };
};

export const updateInfoVersionsFailed = (error: any): IAppVersionAction => {
    return {
        type: AppVersionTypes.UPDATE_INFO_VERSION_FAILED,
        error,
    };
};
