import { IUserGroup } from "../../shared/models/userGroup";
import { IUserGroupAction, UserGroupTypes } from "../actions/userGroup.action";

export interface IUserGroupState {
    loading: boolean;
    error: any;
    userGroups: IUserGroup[];
}

const initState: IUserGroupState = {
    loading: false,
    error: null,
    userGroups: [],
};

const userGroupState = (
    state = initState,
    action: IUserGroupAction
): IUserGroupState => {
    switch (action.type) {
        case UserGroupTypes.GET_ALL_USER_GROUP:
            return {
                ...initState,
                ...{
                    loading: true,
                },
            };
        case UserGroupTypes.GET_ALL_USER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    userGroups: action.userGroups ?? [],
                },
            };
        case UserGroupTypes.GET_ALL_USER_GROUP_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case UserGroupTypes.UPDATE_USER_GROUP_SUCCESS:
            if (action.userGroups) {
                for (let userGroup of action.userGroups) {
                    let index = state.userGroups.findIndex(
                        (u) => u._id == userGroup._id
                    );
                    if (index > -1) {
                        state.userGroups[index] = {
                            ...state.userGroups[index],
                            ...userGroup,
                        };
                    } else {
                        state.userGroups.push(userGroup);
                    }
                }
            }
            return {
                ...state,
            };
        case UserGroupTypes.DELETE_USER_GROUP_SUCCESS:
            if (action.userGroups) {
                for (let userGroup of action.userGroups) {
                    let index = state.userGroups.findIndex(
                        (u) => u._id == userGroup._id
                    );
                    if (index > -1) {
                        state.userGroups.splice(index, 1);
                    }
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default userGroupState;
