import { Form } from "react-bootstrap";
import {
    OPTIONS_PLATFORM,
    PLATFORM_CONFIG,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import "./index.scss";

const PlatformTemplate = ({
    platformIds,
    setOptionValue,
    optionValue,
    total,
}: {
    platformIds: any;
    optionValue: any;
    setOptionValue: Function;
    total?: number;
}) => {
    const platform: any = PLATFORM_CONFIG.filter((platform) =>
        platformIds.includes(platform.type)
    )[0];
    return (
        <div className="main-header">
            <div className="left">
                <div className="name">{platform?.frameworkName}</div>
            </div>
            {platform?.category != Constants.CATEGORY_WEB && (
                <div className="right">
                    <Form.Select
                        onChange={(e) => setOptionValue(e.target.value)}
                    >
                        {OPTIONS_PLATFORM.map((op) => (
                            <option
                                selected={optionValue == op}
                                value={op}
                                key={op}
                            >
                                {op.toUpperCase()}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            )}
        </div>
    );
};

export default PlatformTemplate;
