import { convertDate } from "../utils";
import { IBasic } from "./basic";

export interface IEnterCurriculumVitae extends IBasic {
    title: string
    income: string
    rank: string
    form: string
    language: string[]
    location: string
    level: string
    responsibility: string
    yearsExperience: string
    dateStart : Date
    dateEnd : Date
}

export class EnterCurriculumVitae implements IEnterCurriculumVitae {
    _id: any
    title: string
    income: string
    rank: string
    form: string
    language: string[]
    location: string
    level: string
    responsibility: string
    yearsExperience: string
    dateStart : Date 
    dateEnd : Date 
    constructor(props: any = {}) {
        this._id = props._id;
        this.title = props.title ?? "";
        this.income = props.income ?? "";
        this.rank = props.rank ?? "";
        this.form = props.form ?? "";
        this.language = props.language ?? "";
        this.location = props.location ?? "";
        this.level = props.level ?? "";
        this.responsibility = props.responsibility ?? "";
        this.yearsExperience = props.yearsExperience ?? "";
        this.dateStart = convertDate(props.dateStart) ?? new Date();
        this.dateEnd = convertDate(props.dateEnd) ?? new Date();
    }
}