import { FC } from "react";
import { SelectOption, convertUserOptions } from "../../../utils";
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteRenderOptionState,
    Avatar,
    Box,
    FormControl,
    TextField,
} from "@mui/material";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import { IUserInfo, UserInfo } from "../../../shared/models/userInfo";
import { useSelector } from "react-redux";

const renderOption: FC<{
    props: any;
    option: SelectOption;
    state: AutocompleteRenderOptionState;
}> = ({ props, option, state }) => {
    const avatar = option.avatar ? option.avatar : defaultAvatar;
    return (
        <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
        >
            <Avatar alt={avatar} src={avatar} sx={{ marginRight: "10px" }} />
            {option.label}
        </Box>
    );
};

const onGetOptionsValue = (
    list: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SelectOption>
) => {
    const id = details?.option?.value;
    if (reason === "selectOption" && id) {
        return [...list, id];
    } else if (reason === "removeOption") {
        return list.filter((e) => e !== id);
    } else if (reason === "clear") {
        return [];
    }
    return list;
};

const SearchAndSelectUsers: FC<{
    ids: string[];
    onChange: (ids: string[]) => void;
    label? : string;
    userOptions?:any ;
}> = ({ ids, onChange ,label,userOptions}) => {
    return (
        <FormControl fullWidth margin="normal">
            <Autocomplete
                multiple
                options={userOptions}
                filterSelectedOptions
                value={userOptions.filter((item : any) => ids.includes(item.value))}
                getOptionLabel={(option: SelectOption) => option.label}
                renderOption={(props, option, state) =>
                    renderOption({ props, option, state })
                }
                onChange={(event, value, reason, details) => {
                    console.log(1111);
                    onChange(onGetOptionsValue(ids, reason, details));
                }}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" label={label} />
                )}
            />
        </FormControl>
    );
};
export default SearchAndSelectUsers;
