import { IRatingAction, RatingTypes } from "../actions/rating.action";

export interface IRatingState {
	loading: boolean
	requestRatingUserIds: string[]
}

const initState: IRatingState = {
	loading: false,
	requestRatingUserIds: []
};

const ratingState = (state = initState, action: IRatingAction): IRatingState => {
	switch (action.type) {
		case RatingTypes.GET_REQUEST_RATING:
			return {
				...state,
				... {
					loading: true,
				}
			}
		case RatingTypes.GET_REQUEST_RATING_SUCCESS:
			return {
				...state,
				...{
					loading: false,
					requestRatingUserIds: action.requestRatingUserIds ?? [],
				}
			}
		default:
			return state;
	}
};

export default ratingState;
