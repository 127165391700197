export default class Constants {
    static CATEGORY_WEB = 0;
    static CATEGORY_ANDROID = 1;
    static CATEGORY_IOS = 2;

    static PLATFORM_WEB = 1;
    static PLATFORM_FLUTTER_ANDROID = 2;
    static PLATFORM_FLUTTER_IOS = 3;
    static PLATFORM_REACT_NATIVE_ANDROID = 4;
    static PLATFORM_REACT_NATIVE_IOS = 5;

    static FIRST_SATURDAY_WORKDAY = "2021-10-02 00:00:00";

    static USER_ROLE_STAFF = 0;
    static USER_ROLE_ADMIN = 1;
    static USER_ROLE_PARTNER = 2;
    static USER_ROLE_CREATE_BLOG = 3;
    static USER_ROLE_LEADER = 4;
    static DAY_NUMBER_OF_SPRINT = 2 * 7; // 14 day
    static HTTP_REQUEST_TIMEOUT = 1200000;
    static HTTP_REQUEST_SUCCESS = 200;
    static STATUS_TASK_TO_DO = "TO DO";
    static STATUS_TASK_IN_PROGRESS = "IN PROGRESS";
    static STATUS_TASK_QA_TESTING = "QA TESTING";
    static STATUS_TASK_REVIEW_CODE = "REVIEW CODE";
    static STATUS_TASK_TO_DEPLOY = "TO DEPLOY";
    static STATUS_TASK_PROD_VALIDATION = "PROD VALIDATION";
    static STATUS_TASK_DONE = "DONE";
    static PAA_BLOG_TYPE = 1;
    static YOUTUBE_BLOG_TYPE = 2;
    static TIMESTAMP_DATE = 1000 * 60 * 60 * 24;
    static MAX_TIME = new Date(new Date().getTime() - Constants.TIMESTAMP_DATE);

    static DAY_NUMBER_OF_SPRINT = 14; // 14 day
    static HTTP_REQUEST_TIMEOUT = 30000;
    static HTTP_REQUEST_SUCCESS = 200;
    static PAA_BLOG_TYPE = 1;
    static YOUTUBE_BLOG_TYPE = 2;
    static TIMESTAMP_DATE = 1000 * 60 * 60 * 24;
    static MAX_TIME = new Date(new Date().getTime() - Constants.TIMESTAMP_DATE);

    static SCORE_FOR_A_SPRINT = 200;

    static COLUMN_TASK_TO_DO = "616eab3eccb9dac54a200202";
    static COLUMN_TASK_DONE = "616eac0fccb9dac54a200248";
    static COLUMN_STATUS_IN_PROGRESS = "616eab5eccb9dac54a200214";

    static PRIORITY_TASK_HIGH = "High";
    static PRIORITY_TASK_HIGHEST = "Highest";
    static TIMESTAMP_DATE = 1000 * 60 * 60 * 24;
    static MAX_TIME = new Date(new Date().getTime() - Constants.TIMESTAMP_DATE);
    static STATUS_CAMPAIGN_COMPLETE = ["Success", "Fail"];

    // Priority
    static BLOG_NOT_CRAWL = 1;
    static BLOG_CRAWL_CHECKED = 2;
    static BLOG_CRAWL_NEED_UPLOAD = 3;
    static BLOG_CRAWL_SUCCESS = 4;
    static BLOG_CRAWL_ERROR = 5;

    static priorityPool = [null, "Highest", "High", "Medium", "Low", "Lowest"];
    static prioritySort = ["Highest", "High", "Medium", "Low", "Lowest", null];
    static statusCampaigns = ["In progress", "Success", "Fail"];

    static TIMESTAMP_DATE = 1000 * 60 * 60 * 24;
    static MAX_TIME = new Date(new Date().getTime() - Constants.TIMESTAMP_DATE);

    static API_URL =
        process.env.REACT_APP_API_URL ??
        "https://hero-dev-api.abc-elearning.org";
    static API_DASHBOARD_URL =
        process.env.REACT_APP_DASHBOARD ??
        "https://dashboard-api2.abc-elearning.org/";
    static API_SEND_EMAIL =
        "https://test-dot-micro-enigma-235001.appspot.com/api/auth?type=send-email-common";

    static LOWEST = 0;
    static LOW = 1;
    static MEDIUM = 2;
    static HIGH = 3;
    static HIGHEST = 4;

    static SCORE_FOR_A_SPRINT = 200;
    // Pass status
    static UNTESTED = -1;
    static FAIL = 0;
    static PASS = 1;

    static TYPE_CATEGORY_WEB = 1;
    static TYPE_CATEGORY_APP_FLUTTER = 2;
    static TYPE_CATEGORY_APP_RN = 3;
    static LIST_FILTER_NAME_LOCALSTORAGE = "list-filter-name-fix-1";

    static CATEGORY = [
        {
            name: "Passemall",
            type: [Constants.TYPE_CATEGORY_WEB],
            webId: "219502380",
            color: "#15AB68",
            hasAds: true,
            slug: "passemall",
        },
        {
            name: "ASVAB",
            appId: 4878338973761536,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "233499836",
            flAppAndroidId: "1541823121",
            flAppIosId: "1541841966",
            color: "#8A8862",
            hasAds: true,
            slug: "asvab",
        },
        {
            name: "CDL",
            appId: 5513556919320576,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            webId: "233539277",
            flAppAndroidId: "1541835138",
            flAppIosId: "1541841977",
            rnAppAndroidId: "2726673757",
            rnAppIosId: "2726674910",
            color: "#737E8E",
            appStoreID: "1469003902",
            hasAds: true,
            slug: "cdl",
        },
        {
            name: "GED Test",
            appId: 5296397775536128,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "233542505",
            flAppAndroidId: "1541826034",
            flAppIosId: "1836507743",
            color: "#174D7C",
            slug: "ged",
        },
        {
            name: "TEAS",
            appId: 5186025303310336,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "233524673",
            flAppAndroidId: "1541820250",
            flAppIosId: "1738198535",
            color: "#737E8E",
            slug: "teas",
        },
        {
            name: "CNA",
            appId: 5747415311187968,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243224419",
            flAppAndroidId: "1780000506",
            flAppIosId: "1841653020",
            color: "#4E63BD",
            slug: "cna",
        },
        {
            name: "AWS",
            appId: 5840808850751488,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243229769",
            flAppAndroidId: "2036032112",
            flAppIosId: "2036991417",
            color: "#00AF81",
            slug: "aws",
        },
        {
            name: "PMP",
            appId: 5738309204574208,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243678259",
            flAppAndroidId: "1601211332",
            flAppIosId: "1841674262",
            color: "#E3844C",
            slug: "pmp",
        },
        {
            name: "PTCE",
            appId: 5713977044631552,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243624768",
            flAppAndroidId: "1601178612",
            flAppIosId: "1839561924",
            color: "#06253A",
            slug: "ptce",
        },
        {
            name: "Real Estate",
            appId: 6141832157200384,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243631953",
            flAppAndroidId: "1601234335",
            flAppIosId: "1841676825",
            color: "#DABE8F",
            slug: "real_estate",
        },
        {
            name: "Servsafe",
            appId: 5748957795844096,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243656305",
            flAppAndroidId: "2019474755",
            color: "#E3844C",
            slug: "servsafe",
        },
        {
            name: "Drivingtheory",
            appId: 5708468430307328,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243677343",
            flAppAndroidId: "1541830307",
            flAppIosId: "1841644712",
            color: "#3A5A82",
            slug: "driving_theory",
        },
        {
            name: "DMV",
            appId: 5082322646859776,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            webId: "243632964",
            flAppAndroidId: "1541842345",
            flAppIosId: "1541827439",
            color: "#1C7BBE",
            slug: "dmv",
        },
        {
            name: "ACCUPLACER",
            appId: 6425502466179072,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1541821459",
            flAppIosId: "1841644692",
            color: "#0eb8f2",
            appStoreID: "1502050400",
            slug: "accuplacer",
        },
        {
            name: "CEH",
            appId: 5704077698662400,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1926011144",
            flAppIosId: "2014896621",
            color: "#031b6e",
            slug: "ceh",
        },
        {
            name: "CFA",
            appId: 5759481505906688,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "2019456170",
            flAppIosId: "2122238021",
            color: "#45c1b8",
            slug: "cfa",
        },
        {
            name: "CompTIA A+",
            appId: 5655480462475264,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1601212611",
            flAppIosId: "1836468830",
            color: "#f23126",
            slug: "comptiaa",
        },
        {
            name: "CompTIA Network+",
            appId: 5653852602761216,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779985283",
            flAppIosId: "1839512888",
            color: "#f23126",
            slug: "comptianetwork",
        },
        {
            name: "CompTIA Security+",
            appId: 5712018204000256,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1780000670",
            flAppIosId: "1839535957",
            color: "#f23126",
            slug: "comptiasecurity",
        },
        {
            name: "EMT-B",
            appId: 5711380900478976,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779993599",
            flAppIosId: "1841670566",
            color: "#4745ff",
            slug: "emt",
        },
        {
            name: "EPA 608",
            appId: 5645825162084352,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "2019473893",
            color: "#3a7dcc",
            slug: "epa",
        },
        {
            name: "HESI A2",
            appId: 5657969412800512,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779996844",
            flAppIosId: "1841666145",
            color: "#4493e3",
            slug: "hesi",
        },
        {
            name: "HVAC",
            appId: 5656255221727232,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "2019481641",
            flAppIosId: "2122230434",
            color: "#3a7dcc",
            slug: "hvac",
        },
        {
            name: "MBLEX",
            appId: 5678215016218624,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1841670153",
            flAppIosId: "1841634731",
            color: "#0fa8d2",
            slug: "mblex",
        },
        {
            name: "Motorcycle",
            appId: 5685506780168192,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1677943077",
            flAppIosId: "1841661028",
            color: "#1C7BBE",
            slug: "",
        },
        {
            name: "NATE",
            appId: 6667648976814080,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "2036058006",
            flAppIosId: "2036995869",
            color: "#50ddc4",
            slug: "nate",
        },
        {
            name: "NCLEX-PN",
            appId: 5651471211167744,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779975419",
            flAppIosId: "1841670416",
            color: "#027dc8",
            slug: "nclexpn",
        },
        {
            name: "NCLEX-RN",
            appId: 5046838600138752,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779999033",
            flAppIosId: "1841671837",
            color: "#0fa8d2",
            slug: "nclexrn",
        },
        {
            name: "DKT",
            appId: 5746311907246080,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1541828687",
            flAppIosId: "1841675849",
            color: "#ffe600",
            slug: "dkt",
        },
        {
            name: "G1",
            appId: 5681717746597888,
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            flAppAndroidId: "1541801949",
            flAppIosId: "1841634494",
            rnAppAndroidId: "2704005184",
            rnAppIosId: "2704002024",
            color: "#fe292a",
            slug: "ontariog1",
        },
        {
            name: "PHR",
            appId: 5736441166430208,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "1779994118",
            flAppIosId: "1841671010",
            color: "#ec1e45",
            slug: "phr",
        },
        {
            name: "CCNA",
            appId: 4516085158117376,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: "2771132924",
            flAppIosId: "1841671010",
            color: "#015468",
            appStoreID: "1583152765",
            slug: "ccna",
        },
    ];

    static LIST_WEB_METRICS = [
        {
            label: "Active Users",
            value: "ga:users",
            aggregateLabel: "Total Result",
        },
        {
            label: "Sessions",
            value: "ga:sessions",
            aggregateLabel: "Total Result",
        },
        {
            label: "Avg Session Duration",
            value: "ga:avgSessionDuration",
            aggregateLabel: "Average Result",
        },
        {
            label: "Bounce Rate",
            value: "ga:bounceRate",
            aggregateLabel: "Average Result",
        },
        {
            label: "Pages per session",
            value: "ga:pageviewsPerSession",
            aggregateLabel: "Average Result",
        },
        {
            label: "Returning users",
            value: "returningUsers",
            aggregateLabel: "Average",
            route: "/web-traffic/get-returning-users",
        },
    ];

    static LIST_APP_METRICS = [
        {
            label: "Active Users",
            value: "activeUsers",
            aggregateLabel: "Total Result",
        },
        {
            label: "Avg Session Duration",
            value: "avgSessionDuration",
            aggregateLabel: "Average Result",
        },
    ];

    static DASHBOARD_SEGMENT_LIST = [
        {
            label: "Traffic & Revenue",
            value: "traffic_revenue",
        },
    ];

    static STATUS_DELETED = -1;
    static STATUS_PRIVATE = 0;
    static STATUS_PUBLIC = 1;

    static NONE = "none";
    static ONE_DAY = "1 day";
    static ONE_WEEK = "1 week";
    static TWO_WEEKS = "2 weeks";
    static ONE_MONTH = "1 month";
    static THREE_MONTHS = "3 months";
    static SIX_MONTHS = "6 months";
    static ONE_YEAR = "1 year";

    static PERSONS = "persons";
    static TEAMS = "teams";
    static ALL_TEAMS = "all teams";
    static CHANNELS = "channels";
    static DISCORD_CHANNELS_LIST = ["dev", "sinh-hoat-chung"];

    static BACK_LOG = "Back Log";
    static ALL_SPRINT = "All Sprint";
    static NO_SUCCESS = "no success";
    static GET_FIELD_AVATAR = "avatar";
    static GET_FIELD_FIRSTNAME = "firstName";
    static ALL_ASSIGNEE = "All assignee";
    static ALL_CAMPAIGN = "All campaign";

    static REPORTS = { pageName: "reports", label: "Reports" };
    static RELEASES = { pageName: "releases", label: "Releases" };
    static RECRUITMENT = { pageName: "recruitment-page", label: "Recruitment" };
    static TOOL = { pageName: "tool", label: "Tools" };
    static INFORECRUITMENT = {
        pageName: "info-recuiment-page",
        label: "InfoRecruitment",
    };
    static INFORATING = {
        pageName: "info-rating-review-app",
        label: "InfoRating",
    };
    static APPSTATISTICS = {
        pageName: "app-statistics",
        label: "App Statistics",
    };
    static SHOW_CAMPAIGN_TASKS = "show_campaign_tasks";
    static POSITION = [
        "Other",
        "Tester",
        "Marketer",
        "HR",
        "Content Editor",
        "Developer Intern",
        "Fresher Developer",
        "Tester Intern",
        "Developer",
        "UI Designer",
        "Senior Developer",
        "Junior Marketer & Lead Content",
        "Cofounder & PM & CMO",
        "Founder",
        "Cofounder & Head of Operation",
    ];
    static BAR_COLOR = [
        "#36a2eb",
        "#ff6283",
        "#4bc0c0",
        "#ff9e40",
        "#9966fe",
        "#ffcd56",
        "#c8cbcf",
        "#B30000",
    ];
}
