import Axios from "axios";
import Constants from "../constant";
const callApi = ({
  method,
  url,
  params = null,
  baseURl = "",
  headers = undefined,
}) => {
  return new Promise((resolve, reject) => {
    return Axios({
      baseURL: baseURl?.length
        ? baseURl
        : process.env.REACT_APP_API_URL ??
          "https://hero-dev-api.abc-elearning.org",
      timeout: Constants.HTTP_REQUEST_TIMEOUT,
      url: url,
      method: method ? method : "POST",
      data: params ? params : null,
      headers,
    })
      .then((response) => {
        if (response.status === Constants.HTTP_REQUEST_SUCCESS) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { callApi };
