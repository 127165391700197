import { RatingApp } from "../../shared/models/ratingApp";
import { RatingAppHistory } from "../../shared/models/ratingApp";
import { RatingAppTypes, IRatingAppAction } from "../actions/ratingApp.action";

export interface IRatingAppState {
    loading: boolean;
    ratingApp?: RatingApp[];
    historiesRating?: RatingAppHistory[];
    error?: any;
}

const initState: IRatingAppState = {
    loading: false,
    ratingApp: [],
    historiesRating: [],
};

const appStatisticsState = (state = initState, action: IRatingAppAction): IRatingAppState => {
    switch (action.type) {
        case RatingAppTypes.UPDATE_RATING_APP:
            return {
                ...state,
            };
        case RatingAppTypes.UPDATE_HISTORIES_RATING_APP:
            return {
                ...state,
            };
        case RatingAppTypes.GET_RATING_APP:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case RatingAppTypes.DELETE_HISTORIES_RATING_APP:
            return {
                ...state,
            };
        case RatingAppTypes.GET_HISTORIES_RATING_APP:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case RatingAppTypes.GET_RATING_APP_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    ratingApp: action.ratingApps ?? [],
                },
            };
        case RatingAppTypes.GET_HISTORIES_RATING_APP_SUCCESS:
            return {
                ...state,
                ...{
                    loading: false,
                    historiesRating: action.ratingAppHistorys ?? [],
                },
            };
        case RatingAppTypes.GET_RATING_APP_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case RatingAppTypes.GET_HISTORIES_RATING_APP_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case RatingAppTypes.UPDATE_RATING_APP_SUCCESS:
            const ratingApp = action.ratingApp;
            let listRatingApp = JSON.parse(JSON.stringify(state.ratingApp));
            if (ratingApp) {
                if (!listRatingApp) {
                    listRatingApp = [];
                }
                let index = listRatingApp.findIndex((u: any) => u._id == ratingApp._id);
                if (index > -1) {
                    listRatingApp[index] = ratingApp;
                } else {
                    listRatingApp.push(ratingApp);
                }
            }
            listRatingApp = listRatingApp.map((ratingApp: any) => new RatingApp(ratingApp));
            return {
                ...state,
                ratingApp: listRatingApp,
            };
        case RatingAppTypes.UPDATE_HISTORIES_RATING_APP_SUCCESS:
            const historiesRating = action.ratingAppHistory;
            let listHistoriesRating = JSON.parse(JSON.stringify(state.historiesRating));
            if (historiesRating) {
                if (!listHistoriesRating) {
                    listHistoriesRating = [];
                }
                let index = listHistoriesRating.findIndex((u: any) => u._id == historiesRating._id);
                if (index > -1) {
                    listHistoriesRating[index] = historiesRating;
                } else {
                    listHistoriesRating.push(historiesRating);
                }
            }
            listHistoriesRating = listHistoriesRating.map((historiesRating: any) => new RatingAppHistory(historiesRating));
            return {
                ...state,
                historiesRating: listHistoriesRating,
            };

        case RatingAppTypes.UPDATE_RATING_APP_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case RatingAppTypes.UPDATE_HISTORIES_RATING_APP_FAILED:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case RatingAppTypes.DELETE_HISTORIES_RATING_APP_SUCCESS:
            if (action.ratingAppHistory && state.historiesRating) {
                state.historiesRating = state.historiesRating.filter(
                    (item: any) => item._id != action.ratingAppHistory?._id
                );
            }
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default appStatisticsState;
