import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getRatingAppAction } from "../../../redux/actions/ratingApp.action";
import { Modal, ModalBody, ModalFooter, Button, FormControl } from "react-bootstrap";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
type OptionType = {
    value: { startDate: Date; endDate: Date };
    label: string;
};

const SelectTimePeriodWidget = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dataStartDateCustom, setDataStartDateCustom] = useState(new Date());
    const [dataEndDateCustom, setDataEndDateCustom] = useState(new Date());
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const options: OptionType[] = [
        { value: { startDate: startDate, endDate: endDate }, label: "Today" },
        { value: { startDate: startDate, endDate: endDate }, label: "Yesterday" },
        { value: { startDate: startDate, endDate: endDate }, label: "Last 7 days" },
        { value: { startDate: startDate, endDate: endDate }, label: "Last 28 days" },
        { value: { startDate: startDate, endDate: endDate }, label: "Last 30 days" },
        { value: { startDate: startDate, endDate: endDate }, label: "Last 90 days" },
        { value: { startDate: startDate, endDate: endDate }, label: "Last 12 months" },
        { value: { startDate: startDate, endDate: endDate }, label: "Custom time" },
    ];
    const getDataApiRatingApp = () => {
        const epochStartTimestamp = Math.floor(startDate.getTime());
        const epochFinishTimestamp = Math.floor(endDate.getTime());
        dispatch(getRatingAppAction(epochStartTimestamp, epochFinishTimestamp));
        onHide();
    };

    useEffect(() => {
        setDataDefaultStartDate();
    }, []);
    useEffect(() => {
        getDataApiRatingApp();
    }, [startDate,endDate]);
    const today = new Date();
    const setDataDefaultStartDate = () => {
        const last30Day = new Date(today);
        last30Day.setDate(today.getDate() - 30);
        setStartDate(last30Day);
    };
    const handleSelectChange = (value: any) => {
        switch (value.label) {
            case "Today":
                setStartDate(today);
                setEndDate(today);
                setStartDate(today);
                break;
            case "Yesterday":
                setStartDate(today);
                setEndDate(today);
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                setStartDate(yesterday);
                break;
            case "Last 7 days":
                setStartDate(today);
                setEndDate(today);
                const last7Day = new Date(today);
                last7Day.setDate(today.getDate() - 7);
                setStartDate(last7Day);
                break;
            case "Last 28 days":
                setStartDate(today);
                setEndDate(today);
                const last28Day = new Date(today);
                last28Day.setDate(today.getDate() - 28);
                setStartDate(last28Day);
                break;
            case "Last 30 days":
                setStartDate(today);
                setEndDate(today);
                const last30Day = new Date(today);
                last30Day.setDate(today.getDate() - 30);
                setStartDate(last30Day);
                break;
            case "Last 90 days":
                setStartDate(today);
                setEndDate(today);
                const last90Day = new Date(today);
                last90Day.setDate(today.getDate() - 90);
                setStartDate(last90Day);
                break;
            case "Last 12 months":
                setStartDate(today);
                setEndDate(today);
                const twelveMonthsAgo = new Date(today);
                twelveMonthsAgo.setMonth(today.getMonth() - 12);
                setStartDate(twelveMonthsAgo);
                break;
            case "Custom time":
                setShowModal(true);
                break;

            default:
                break;
        }
    };
    const onHide = () => {
        setShowModal(false);
    };

    const handleChangeDataTimeCustom = () => {
            setStartDate(dataStartDateCustom);
            setEndDate(dataEndDateCustom);
    }
    return (
        <>
            <div
                style={{
                    width: "500px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Select options={options} onChange={(e) => handleSelectChange(e)} maxMenuHeight={500} />
                <div
                    style={{
                        display: "flex",
                        width: "300px",
                        justifyContent: "space-between",
                        fontSize: "16px",
                    }}
                >
                    <span>Từ : {moment(startDate).format("YYYY-MM-DD")}</span>
                    <div>-</div>
                    <span> Đến : {moment(endDate).format("YYYY-MM-DD")}</span>
                </div>
            </div>
            {showModal && (
                <Modal show={true} onHide={onHide} centered>
                    <Modal.Header>
                        <Modal.Title>Khoảng Thời Gian</Modal.Title>
                    </Modal.Header>

                    <ModalBody>
                        <div className="field startDate">
                            <h6>Ngày bắt đầu</h6>
                            <FormControl
                                type="date"
                                name="startDate"
                                value={moment(dataStartDateCustom).format("YYYY-MM-DD")}
                                onChange={(e) => setDataStartDateCustom(new Date(e.target.value))}
                            />
                        </div>
                        <div className="field endDate">
                            <h6>Ngày kết thúc</h6>
                            <FormControl
                                type="date"
                                name="endDate"
                                value={moment(dataEndDateCustom).format("YYYY-MM-DD")}
                                onChange={(e) => setDataEndDateCustom(new Date(e.target.value))}
                            />
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={handleChangeDataTimeCustom} style={{ background: "#4bb4ed", borderColor: "#4bb4ed" }}>
                            Chọn
                        </Button>
                        <Button className="close" aria-label="Close" onClick={onHide} variant="secondary">
                            Huỷ
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};
export default SelectTimePeriodWidget;
