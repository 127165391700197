import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { IMaskInput } from "react-imask";
import { useDispatch } from "react-redux";
import Select, { ActionMeta, MultiValue } from "react-select";
import { toast } from "react-toastify";
import { createcEnterCurriculumVitae } from "../../redux/actions/enterCurriculumVitae.action";
import Config from "../../shared/models/config";
import { EnterCurriculumVitae, IEnterCurriculumVitae } from "../../shared/models/enterCurriculumVitae";
import Url from "../../util/url";
import "./index.scss";

type OptionType = {
    value: string;
    label: string;
};
const optionLanguage: OptionType[] = [
    { value: "Javascript", label: "Javascript" },
    { value: "Flutter", label: "Flutter" },
    { value: "Native IOS", label: "Native IOS" },
    { value: "Native Android", label: "Native Android" },
    { value: "Python", label: "Python" },
    { value: "ReactJS", label: "ReactJS" },
    { value: "NodeJS", label: "NodeJS" },
    { value: "Mongo(CRUD)", label: "Mongo(CRUD)" },
    { value: "ElasticSearch", label: "ElasticSearch" },
    {
        value: "Gcloud Datastore Gcloud Bigquery",
        label: "Gcloud Datastore Gcloud Bigquery",
    },
    { value: "MySQL", label: "MySQL" },
    { value: "Nginx", label: "Nginx" },
    { value: "HTML/CSS", label: "HTML/CSS" },
    { value: "Git", label: "Git" },
];

const InfoRecuimentPage = () => {
    const titleRef = useRef<HTMLInputElement | null>(null);
    const incomeRef = useRef<HTMLInputElement | null>(null);
    const rankRef = useRef<HTMLSelectElement | null>(null);
    const formRef = useRef<HTMLSelectElement | null>(null);
    const locationRef = useRef<String | null>("Hà Nội");
    const levelRef = useRef<HTMLTextAreaElement | null>(null);
    const responsibilityRef = useRef<HTMLInputElement | null>(null);
    const yearsExperienceRef = useRef<HTMLInputElement | null>(null);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (dateStart > dateEnd) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [dateStart, dateEnd]);

    const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<OptionType[]>([]);

    let language = selectedLanguageOptions.map((data) => {
        return data.value;
    });

    const handleSelectChange = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        if (selected) {
            setSelectedLanguageOptions(selected as OptionType[]);
            //   console.log(selected as OptionType[]);
        }
    };

    const toastSendInfo = (url: string) => {
        return (
            <div className="task-toastify">
                <p>Already sent!</p>
                <a href={url}>Mở</a>
            </div>
        );
    };

    const handleSendInfoCv = async () => {
        const newInfoCv: IEnterCurriculumVitae = new EnterCurriculumVitae({
            title: titleRef.current?.value ?? "",
            income: incomeRef.current?.value ?? "",
            rank: rankRef.current?.value ?? "",
            form: formRef.current?.value ?? "",
            language: language,
            location: locationRef.current ?? "",
            level: levelRef.current?.value ?? "",
            responsibility: responsibilityRef.current?.value ?? "",
            yearsExperience: yearsExperienceRef.current?.value ?? "",
            dateStart: dateStart,
            dateEnd: dateEnd,
        });

        if (!newInfoCv.title) {
            toast("You need to add title!");
            return;
        }
        if (!newInfoCv.income) {
            toast("You need to add income!");
            return;
        }
        if (!newInfoCv.form) {
            toast("You need to add form!");
            return;
        }
        if (!newInfoCv.rank) {
            toast("You need to add rank!");
            return;
        }
        if (!newInfoCv.language) {
            toast("You need to add language!");
            return;
        }
        if (showError) {
            toast("You need to choose an end date greater than a start date !");
            return;
        }
        dispatch(createcEnterCurriculumVitae(newInfoCv));
        const req = await axios.post(Url("enterCurrirecruitment/set-data"), newInfoCv);

        setTimeout(() => {
            const url = "/recruitment-page/";
            toast(toastSendInfo(url), {
                position: "top-right",
            });
        }, 100);
    };

    return (
        <div className="box-from-info-recuiment">
            <div className="container-from-info-recuiment">
                <h2> From nhập thông tin tuyển dụng </h2>
                <div className="container-input">
                    <h5>Title</h5>
                    <FormControl defaultValue="" className="input" ref={titleRef} type="text"></FormControl>
                </div>
                <div className="container-input">
                    <h5>Thu nhập</h5>
                    <IMaskInput
                        className="input-type-number"
                        mask=""
                        definitions={{
                            "#": /[1-9.,]/,
                        }}
                        inputRef={incomeRef}
                        overwrite
                        onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            if (!/[\d.,]/.test(keyValue)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className="container-input">
                    <h5>Cấp bậc</h5>
                    <Form.Select ref={rankRef} defaultValue={""} className="input">
                        {["", ...Object.values(Config.RANK_JOB)].map((p, index) => (
                            <option key={index} value={p}>
                                {p}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="container-input">
                    <h5>Hình thức</h5>
                    <Form.Select ref={formRef} defaultValue={""} className="input">
                        {["", ...Object.values(Config.FORM_JOB)].map((p, index) => (
                            <option key={index} value={p}>
                                {p}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="container-input">
                    <h5>Địa điểm</h5>
                    <div className="location">
                        <h6 className="text-location">Hà Nội</h6>
                    </div>
                </div>
                <div className="container-input-language">
                    <div className="box-input-language">
                        <h5>Ngôn Ngữ</h5>
                        <Select
                            className="input-language"
                            isMulti
                            options={optionLanguage}
                            isClearable={true}
                            isSearchable={true}
                            value={selectedLanguageOptions}
                            onChange={handleSelectChange}
                        />
                    </div>
                </div>
                <div></div>
                <div className="container-input-area">
                    <h5>Trình độ</h5>
                    <TextField className="input-area" inputRef={levelRef} multiline rows={4}></TextField>
                </div>
                <div className="container-input-area">
                    <h5>Trách nhiệm</h5>
                    <TextField className="input-area" inputRef={responsibilityRef} multiline rows={4}></TextField>
                </div>
                <div className="container-input">
                    <h5>Năm kinh nghiệm</h5>
                    <IMaskInput
                        className="input-type-number"
                        mask=""
                        definitions={{
                            "#": /[1-9.,]/,
                        }}
                        inputRef={yearsExperienceRef}
                        overwrite
                        onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            if (!/[\d.,]/.test(keyValue)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className="container-input">
                    <h5>Ngày bắt đầu</h5>
                    <FormControl
                        className="input"
                        type="date"
                        name="startDate"
                        value={moment(dateStart).format("YYYY-MM-DD")}
                        onChange={(e) => setDateStart(new Date(e.target.value))}
                    />
                </div>
                {showError && <div>Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu !</div>}
                <div className="container-input">
                    <h5>Ngày kết thúc</h5>
                    <FormControl
                        className="input"
                        type="date"
                        name="endDate"
                        value={moment(dateEnd).format("YYYY-MM-DD")}
                        onChange={(e) => setDateEnd(new Date(e.target.value))}
                    />
                </div>
                <Button className="btn-send" onClick={() => handleSendInfoCv()}>
                    Lưu
                </Button>
            </div>
        </div>
    );
};
export default InfoRecuimentPage;
