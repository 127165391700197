import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import { AuthTypes, IAuthAction } from "../actions/auth.action";

export type LoginStatus = "default" | "loading" | "success" | "failed";

export interface IAuthState {
    status: LoginStatus;
    user?: IUserInfo;
    error?: any;
    nextUrl?: string | null;
}

const initState: IAuthState = {
    status: "default",
};

const authState = (state = initState, action: IAuthAction): IAuthState => {
    switch (action.type) {
        case AuthTypes.LOGIN:
            return {
                ...state,
                ...{
                    status: "loading",
                },
            };
        case AuthTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...{
                    status: "success",
                    user: action.user,
                    nextUrl: action.nextUrl,
                },
            };
        case AuthTypes.LOGIN_FAILED:
            return {
                ...state,
                ...{
                    status: "failed",
                    error: action.error,
                },
            };
        case AuthTypes.LOGIN_FROM_SESSION:
            return {
                ...state,
                ...{
                    status: "loading",
                },
            };
        case AuthTypes.LOGIN_BY_TOKEN:
            return {
                ...state,
                ...{
                    status: "loading",
                },
            };
        default:
            return state;
    }
};

export default authState;
