import { IBasic } from "./basic";

export interface ICurriculumVitae extends IBasic {
    fullName: string
    email: string
    phoneNumber: string
    position: string
    cvUrl: string
    message: string
}

export class CurriculumVitae implements ICurriculumVitae {
    _id: any
    fullName: string
    email: string
    phoneNumber: string
    position: string
    cvUrl: string
    message: string

    constructor(props: any = {}) {
        this._id = props._id;
        this.fullName = props.fullName ?? "";
        this.email = props.email ?? "";
        this.phoneNumber = props.phoneNumber ?? "";
        this.position = props.position ?? "";
        this.cvUrl = props.cvUrl ?? "";
        this.message = props.message ?? "";
    }
}