import { useState, useEffect } from "react";
import axios from "axios";
import { getWeekFromDate } from "../../../../util/index";
import {
    Tooltip,
    XAxis,
    YAxis,
    Line,
    Bar,
    ResponsiveContainer,
    ComposedChart,
} from "recharts";
import { faStar, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionModal from "../question-modal";
import { Edit as EditIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import "./index.scss";
import CircularIndeterminate from "../../../common/icon/Loading";

const NPSAndReportMitstakes = ({
    showNPS,
    appID,
    startDate,
    endDate,
    appIDs = [],
}) => {
    const [
        reportedMistakesByQuestionWithNPS,
        setReportedMistakesByQuestionWithNPS,
    ] = useState([]);
    const [
        resolvedReportedMistakesByQuestionWithNPS,
        setResolvedReportedMistakesByQuestionWithNPS,
    ] = useState([]);
    const [reportedMistakesByQuestion, setReportedMistakesByQuestion] = useState([]);
    const [resolvedReportedMistakesByQuestion, setResolvedReportedMistakesByQuestion] = useState([]);
    const [reportedMistakesByWeek, setReportedMistakesByWeek] = useState([]);
    const [avgNPS, setAvgNPS] = useState(0);
    const [questionInModal, setQuestionInModal] = useState();
    const [modalType, setModalType] = useState();

    const [npsByWeek, setNPSByWeek] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchInfo();
    }, [appID, appIDs, startDate, endDate]);
    const fetchInfo = async () => {
        let newAppID = [];
        if (appIDs) {
            appIDs.forEach((appID) => {
                if (!newAppID.includes(appID)) {
                    newAppID.push(appID);
                }
            });
        } else {
            newAppID.push(appID);
        }

        setLoading(true);
        const questions = {};

        let npsByWeek = [];
        let reportedMistakesByWeek = [];

        const res = await axios.post(
            "https://test-dot-micro-enigma-235001.appspot.com/dataapi?type=get-report-mistake",
            {
                startTime: new Date(startDate).getTime(),
                endTime: new Date(endDate).getTime(),
                appIds: newAppID,
            }
        );

        if (res.data[appID] === undefined) {
            setLoading(false);
            return;
        }

        let npsScores = [];
        for (let j = 0; j < res.data[appID].length; j++) {
            const reportedMistake = res.data[appID][j];
            if (questions[reportedMistake.questionId] === undefined) {
                questions[reportedMistake.questionId] = {
                    id: reportedMistake.questionId,
                    reportedMistakes: [],
                    goodRatings: [],
                    mediumRatings: [],
                    badRatings: [],
                    hasNPS: false,
                    hasNPSDetail: false,
                    lastUpdateWithNPS: null,
                    lastUpdateWithoutNPS: null,
                    hasReportMistake: false,
                };
            }

            if (
                reportedMistake.reasons.length > 0 ||
                reportedMistake.reason !== -1
            ) {
                questions[reportedMistake.questionId]["reportedMistakes"].push(
                    reportedMistake
                );
                questions[reportedMistake.questionId][
                    "hasReportMistake"
                ] = true;
            }

            const lastUpdate = new Date(reportedMistake.lastUpdate);
            if (reportedMistake.ratingScore !== -1) {
                questions[reportedMistake.questionId]["hasNPS"] = true;
                npsScores.push(reportedMistake.ratingScore);
                if (reportedMistake.ratingScore >= 8) {
                    questions[reportedMistake.questionId]["goodRatings"].push(
                        reportedMistake
                    );
                } else if (reportedMistake.ratingScore >= 6) {
                    questions[reportedMistake.questionId]["mediumRatings"].push(
                        reportedMistake
                    );
                } else {
                    questions[reportedMistake.questionId]["badRatings"].push(
                        reportedMistake
                    );
                }
                if (reportedMistake.detail) {
                    questions[reportedMistake.questionId][
                        "hasNPSDetail"
                    ] = true;
                }
                if (
                    questions[reportedMistake.questionId].lastUpdateWithNPS ===
                        null ||
                    questions[
                        reportedMistake.questionId
                    ].lastUpdateWithNPS.getTime() < lastUpdate.getTime()
                ) {
                    questions[reportedMistake.questionId].lastUpdateWithNPS =
                        lastUpdate;
                }

                const npsIndex = npsByWeek.findIndex(
                    (week) =>
                        getWeekFromDate(lastUpdate.getDate()) === week.week &&
                        lastUpdate.getMonth() + 1 === week.month
                );
                if (npsIndex === -1) {
                    npsByWeek.push({
                        name:
                            "T" +
                            (lastUpdate.getMonth() + 1) +
                            " W" +
                            getWeekFromDate(lastUpdate.getDate()),
                        week: getWeekFromDate(lastUpdate.getDate()),
                        month: lastUpdate.getMonth() + 1,
                        nps: [reportedMistake.ratingScore],
                    });
                } else {
                    npsByWeek[npsIndex].nps.push(reportedMistake.ratingScore);
                }
            }

            if (
                questions[reportedMistake.questionId].lastUpdateWithoutNPS ===
                    null ||
                questions[
                    reportedMistake.questionId
                ].lastUpdateWithoutNPS.getTime() < lastUpdate.getTime()
            ) {
                questions[reportedMistake.questionId].lastUpdateWithoutNPS =
                    lastUpdate;
            }

            const reportedMistakeIndex = reportedMistakesByWeek.findIndex(
                (week) =>
                    getWeekFromDate(lastUpdate.getDate()) === week.week &&
                    lastUpdate.getMonth() + 1 === week.month
            );
            if (reportedMistakeIndex === -1) {
                reportedMistakesByWeek.push({
                    name:
                        "T" +
                        (lastUpdate.getMonth() + 1) +
                        " W" +
                        getWeekFromDate(lastUpdate.getDate()),
                    week: getWeekFromDate(lastUpdate.getDate()),
                    month: lastUpdate.getMonth() + 1,
                    nbResolvedReportedMistakes:
                        reportedMistake.status === 1 ? 1 : 0,
                    nbUnresolvedReportedMistakes:
                        reportedMistake.status === 1 ? 0 : 1,
                });
            } else {
                if (reportedMistake.status === 1) {
                    reportedMistakesByWeek[reportedMistakeIndex]
                        .nbResolvedReportedMistakes++;
                } else {
                    reportedMistakesByWeek[reportedMistakeIndex]
                        .nbUnresolvedReportedMistakes++;
                }
            }
        }

        const sumNPS = npsScores.reduce((total, nps) => total + nps, 0);
        setAvgNPS(sumNPS / npsScores.length);

        npsByWeek = npsByWeek.map((week) => {
            const total = week.nps.reduce((total, nps) => {
                return total + nps;
            }, 0);
            return {
                ...week,
                totalNPS: week.nps.length,
                avgNPS: total / week.nps.length,
            };
        });
        npsByWeek.sort((a, b) => {
            if (a.month < b.month) {
                return -1;
            } else if (a.month > b.month) {
                return 1;
            }

            if (a.week < b.week) {
                return -1;
            }
            return 1;
        });
        setNPSByWeek(npsByWeek);

        reportedMistakesByWeek.sort((a, b) => {
            if (a.month < b.month) {
                return -1;
            } else if (a.month > b.month) {
                return 1;
            }

            if (a.week < b.week) {
                return -1;
            }
            return 1;
        });
        setReportedMistakesByWeek(reportedMistakesByWeek);

        const reportedMistakesByQuestionWithNPS = Object.values(
            questions
        ).filter((question) => question.hasNPS);
        reportedMistakesByQuestionWithNPS.sort((a, b) => {
            if (a.badRatings.length < b.badRatings.length) {
                return 1;
            } else if (a.badRatings.length > b.badRatings.length) {
                return -1;
            }

            if (a.mediumRatings.length < b.mediumRatings.length) {
                return 1;
            } else if (a.mediumRatings.length > b.mediumRatings.length) {
                return -1;
            }

            if (a.goodRatings.length < b.goodRatings.length) {
                return -1;
            } else if (a.goodRatings.length > b.goodRatings.length) {
                return 1;
            }

            return 0;
        });

        const reportedMistakesWithNPSData = [];
        const resolvedReportedMistakesWithNPSData = [];

        for (let i = 0; i < reportedMistakesByQuestionWithNPS.length; i++) {
            let count = 0;
            reportedMistakesByQuestionWithNPS[i].reportedMistakes.map(
                (reportedMistake) => {
                    if (reportedMistake.status === 0) {
                        count++;
                    }
                }
            );
            if (count > 0) {
                reportedMistakesWithNPSData.push(
                    reportedMistakesByQuestionWithNPS[i]
                );
            } else {
                resolvedReportedMistakesWithNPSData.push(
                    reportedMistakesByQuestionWithNPS[i]
                );
            }
        }
        setReportedMistakesByQuestionWithNPS(reportedMistakesWithNPSData);
        setResolvedReportedMistakesByQuestionWithNPS(resolvedReportedMistakesWithNPSData);

        const reportedMistakesByQuestion = Object.values(questions).filter(
            (question) => question.hasReportMistake
        );

        reportedMistakesByQuestion.sort((a, b) => {
            if (a.reportedMistakes.length < b.reportedMistakes.length) {
                return 1;
            }
            return -1;
        });

        for (let i = 0; i < reportedMistakesByQuestion.length; i++) {
            reportedMistakesByQuestion[i].reportedMistakes.sort((a, b) => {
                if (a.lastUpdate < b.lastUpdate) {
                    return 1;
                }
                return -1;
            });
        }

        const reportedMistakesData = [];
        const resolvedReportedMistakesData = [];

        for (let i = 0; i < reportedMistakesByQuestion.length; i++) {
            let count = 0;
            reportedMistakesByQuestion[i].reportedMistakes.map(
                (reportedMistake) => {
                    if (reportedMistake.status === 0) {
                        count++;
                    }
                }
            );
            if (count > 0) {
                reportedMistakesData.push(reportedMistakesByQuestion[i]);
            } else {
                resolvedReportedMistakesData.push(reportedMistakesByQuestion[i]);
            }
        }
        setReportedMistakesByQuestion(reportedMistakesData);
        setResolvedReportedMistakesByQuestion(resolvedReportedMistakesData);
        setLoading(false);
    };

    const CustomBarTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            switch (payload[0]["dataKey"]) {
                case "nbUnresolvedReportedMistakes":
                case "nbResolvedReportedMistakes":
                    return (
                        <div className="custom-tooltip">
                            <div className="custom-tooltip-label">{label}</div>
                            <div className="custom-tooltip-value">
                                Đã xử lý:{" "}
                                {
                                    payload[0]["payload"][
                                        "nbResolvedReportedMistakes"
                                    ]
                                }
                            </div>
                            <div className="custom-tooltip-value">
                                Chưa xử lý:{" "}
                                {
                                    payload[0]["payload"][
                                        "nbUnresolvedReportedMistakes"
                                    ]
                                }
                            </div>
                        </div>
                    );
                case "totalNPS":
                    return (
                        <div className="custom-tooltip">
                            <div className="custom-tooltip-label">{label}</div>
                            <div className="custom-tooltip-value">
                                NPS trung bình:{" "}
                                {payload[1]["payload"]["avgNPS"].toFixed(1) +
                                    "/10"}
                            </div>
                            <div className="custom-tooltip-value">
                                Số câu hỏi có NPS:{" "}
                                {payload[0]["payload"]["totalNPS"]}
                            </div>
                        </div>
                    );
                default:
                    return null;
            }
        }

        return null;
    };

    if (loading) {
        return <CircularIndeterminate />;
    }
    return (
        <div className="main-nps-report">
            {showNPS ? (
                <div>
                    <ResponsiveContainer
                        height={400}
                        width="100%"
                        className="chart"
                    >
                        <ComposedChart data={npsByWeek}>
                            <Bar
                                type="monotone"
                                dataKey="totalNPS"
                                fill="#0c6efd"
                                yAxisId="left"
                            />
                            <Line
                                type="monotone"
                                dataKey="avgNPS"
                                stroke="#000"
                                yAxisId="right"
                            />
                            <XAxis dataKey="name" padding={{ left: 10 }} />
                            <YAxis yAxisId="left" />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                domain={[0, 10]}
                            />
                            <Tooltip
                                content={<CustomBarTooltip />}
                                cursor={{ fill: "rgba(0, 0, 0, 0)" }}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                    <div className="filter-and-summary">
                        <div className="filter"></div>
                        <div className="summary">
                            <div>NPS Average: {avgNPS.toFixed(1)}</div>
                        </div>
                    </div>
                    <div className="notice">
                        <span>
                            8 - 10{" "}
                            <FontAwesomeIcon icon={faStar} color="#ffb400" />
                        </span>
                        <span>
                            6 - 7{" "}
                            <FontAwesomeIcon icon={faStar} color="#ffb400" />
                        </span>
                        <span>
                            1 - 5{" "}
                            <FontAwesomeIcon icon={faStar} color="#ffb400" />
                        </span>
                    </div>
                    <div style={{ color: "rgb(12, 110, 253)" }}>UNRESOLVED QUESTIONS:</div>
                    <div className="questions">
                        {reportedMistakesByQuestionWithNPS.map(
                            (question, qIndex) => (
                                <div key={qIndex} className="question">
                                    <div style={{ width: "calc(100% - 50px)" }}>
                                        <h5
                                            onClick={() => {
                                                setModalType("nps");
                                                setQuestionInModal(question);
                                            }}
                                        >
                                            {question.id} (
                                            {question.goodRatings.length +
                                                question.mediumRatings.length +
                                                question.badRatings.length}
                                            )
                                            {question.hasNPSDetail && (
                                                <FontAwesomeIcon
                                                    icon={faCommentDots}
                                                />
                                            )}
                                        </h5>
                                        <div className="ratings">
                                            <span
                                                style={{
                                                    width:
                                                        (question.goodRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    width:
                                                        (question.mediumRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    width:
                                                        (question.badRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                        </div>
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            window.open(
                                                "https://micro-enigma-235001.appspot.com/?searchQuestion=" +
                                                    question.id +
                                                    "&reportedMistakeId=" +
                                                    question.reportedMistakes[0]
                                                        ?.id
                                            );
                                        }}
                                    >
                                        <EditIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </div>
                            )
                        )}

                        {reportedMistakesByQuestionWithNPS.length === 0 && (
                            <div style={{ marginBottom: "10px" }}>Empty</div>
                        )}
                    </div>
                    <div style={{ color: "rgb(12, 110, 253)" }}>RESOLVED QUESTIONS:</div>
                    <div className="questions">
                        {resolvedReportedMistakesByQuestionWithNPS.map(
                            (question, qIndex) => (
                                <div key={qIndex} className="question">
                                    <div style={{ width: "calc(100% - 50px)" }}>
                                        <h5
                                            onClick={() => {
                                                setModalType("nps");
                                                setQuestionInModal(question);
                                            }}
                                        >
                                            {question.id} (
                                            {question.goodRatings.length +
                                                question.mediumRatings.length +
                                                question.badRatings.length}
                                            )
                                            {question.hasNPSDetail && (
                                                <FontAwesomeIcon
                                                    icon={faCommentDots}
                                                />
                                            )}
                                        </h5>
                                        <div className="ratings">
                                            <span
                                                style={{
                                                    width:
                                                        (question.goodRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    width:
                                                        (question.mediumRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    width:
                                                        (question.badRatings
                                                            .length /
                                                            (question
                                                                .goodRatings
                                                                .length +
                                                                question
                                                                    .mediumRatings
                                                                    .length +
                                                                question
                                                                    .badRatings
                                                                    .length)) *
                                                            100 +
                                                        "%",
                                                }}
                                            ></span>
                                        </div>
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            {question.reportedMistakes.length > 0 ? (
                                                window.open(
                                                    "https://micro-enigma-235001.appspot.com/?searchQuestion=" +
                                                        question.id +
                                                        "&reportedMistakeId=" +
                                                        question.reportedMistakes[0]
                                                            ?.id
                                                )
                                            ) : (
                                                window.open(
                                                    "https://micro-enigma-235001.appspot.com/?searchQuestion=" +
                                                        question.id
                                                )
                                            )}
                                        }}
                                    >
                                        <EditIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </div>
                            )
                        )}

                        {resolvedReportedMistakesByQuestionWithNPS.length === 0 && (
                            <div style={{ marginBottom: "10px" }}>Empty</div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <ResponsiveContainer
                        height={400}
                        width="100%"
                        className="chart"
                    >
                        <ComposedChart data={reportedMistakesByWeek}>
                            <Bar
                                type="monotone"
                                dataKey="nbUnresolvedReportedMistakes"
                                fill="#0c6efd"
                                stackId="a"
                            />
                            <Bar
                                type="monotone"
                                dataKey="nbResolvedReportedMistakes"
                                fill="#198753"
                                stackId="a"
                            />
                            <XAxis dataKey="name" padding={{ left: 10 }} />
                            <YAxis />
                            <Tooltip
                                content={<CustomBarTooltip />}
                                cursor={{ fill: "rgba(0, 0, 0, 0)" }}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>

                    <div className="filter-and-summary">
                        <div className="filter"></div>
                        <div className="summary">
                            <div>
                                Total Reported Questions:{" "}
                                {reportedMistakesByQuestion.length}
                            </div>
                        </div>
                    </div>
                    <div style={{ color: "rgb(12, 110, 253)" }}>UNRESOLVED QUESTIONS:</div>
                    <div className="questions">
                        {reportedMistakesByQuestion.map((question, qIndex) => (
                            <div key={qIndex} className="question">
                                <h5
                                    onClick={() => {
                                        setModalType("report-mistake");
                                        setQuestionInModal(question);
                                    }}
                                >
                                    {question.id} (
                                    {question.reportedMistakes.length})
                                </h5>
                                <IconButton
                                    onClick={() => {
                                        window.open(
                                            "https://micro-enigma-235001.appspot.com/?searchQuestion=" +
                                                question.id + 
                                                "&reportedMistakeId=" +
                                                question.reportedMistakes[0]
                                                    .id
                                        )
                                    }}
                                >
                                    <EditIcon
                                        color="primary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            </div>
                        ))}

                        {reportedMistakesByQuestion.length === 0 && (
                            <div style={{ marginBottom: "10px" }}>Empty</div>
                        )}
                    </div>

                    <div style={{ color: "rgb(12, 110, 253)" }}>RESOLVED QUESTIONS:</div>
                    <div className="questions">
                        {resolvedReportedMistakesByQuestion.map((question, qIndex) => (
                            <div key={qIndex} className="question">
                                <h5
                                    onClick={() => {
                                        setModalType("report-mistake");
                                        setQuestionInModal(question);
                                    }}
                                >
                                    {question.id} (
                                    {question.reportedMistakes.length})
                                </h5>
                                <IconButton
                                    onClick={() => {
                                        window.open(
                                            "https://micro-enigma-235001.appspot.com/?searchQuestion=" +
                                                question.id +
                                                "&reportedMistakeId=" +
                                                question.reportedMistakes[0]?.id
                                        );
                                    }}
                                >
                                    <EditIcon
                                        color="primary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            </div>
                        ))}

                        {resolvedReportedMistakesByQuestion.length === 0 && (
                            <div style={{ marginBottom: "10px" }}>Empty</div>
                        )}
                    </div>
                </div>
            )}

            {questionInModal !== undefined && modalType !== undefined && (
                <QuestionModal
                    question={questionInModal}
                    type={modalType}
                    hideModal={() => setQuestionInModal(undefined)}
                />
            )}
        </div>
    );
};

export default NPSAndReportMitstakes;
