import "./style.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Header from "../Header";
// import axios from "axios";
// import Url from "../../util/url";
import { toast } from "react-toastify";
import RouterConfig from "../../routes";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import { updateUserInfo } from "../../redux/actions/userInfo.action";
import { loginSuccess } from "../../redux/actions/auth.action";

const ChangePassword = () => {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
    const [errorField, setErrorField] = useState("");
    let user = JSON.parse(localStorage.getItem("userInfo") ?? "{}");
    const userID: IUserInfo = user?._id;
    const userInfo: IUserInfo = useSelector((state: any) => state.authState?.user);

    const dispatch = useDispatch();

    useEffect(() => {
        const fectchUserInfo = async () => {
            // const res = await axios.get(Url("user/info/" + userID));
            // let activeSprintID;
            // let startDateSprint;
            // let endDateSprint;
            // const resSprint = await axios.get(Url("sprint"));
            // const sprints = resSprint.data.response;
            // for (let sprint of sprints) {
            //     if (sprint.activeSprint === true) {
            //         activeSprintID = sprint._id;
            //         startDateSprint = new Date(sprint.startDate).getTime();
            //         endDateSprint = new Date(sprint.endDate).getTime();
            //     }
            // }
            // const resReports = await axios.post(Url("sprint/reports"), {
            //     sprintID: activeSprintID,
            // });
            // const data = resReports.data.find((dt: any) => dt.user[0]?._id === userID) ?? null;
            // if (res.data.success) {
            let _user = new UserInfo(user);
            dispatch(loginSuccess(_user));

            // dispatch({
            //     type: "SET_HEADER_INFO",
            //     data: {
            //         user: res.data.response[0],
            //         sprintReport: data,
            //         sprintTime: {
            //             startDate: startDateSprint,
            //             endDate: endDateSprint,
            //         },
            //     },
            // });
            // }
        };
        if (userID) fectchUserInfo();
    }, []);

    useEffect(() => {
        if (userInfo?.hasOwnProperty("isChangePassword") && userInfo?.isChangePassword === false) {
            toast.warning("Bạn cần đổi mật khẩu trước khi tiếp tục", {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                draggable: false,
                progress: undefined,
                toastId: "ABC-E-LEARNING",
            });
        }
    }, [userInfo]);

    const change = async () => {
        if (passwordErrorMsg) return;
        if (password !== null && newPassword !== null && confirmPassword !== null) {
            if (userInfo?.password !== undefined && password === userInfo?.password) {
                if (newPassword !== password) {
                    if (confirmPassword === newPassword) {
                        dispatch(
                            updateUserInfo([
                                {
                                    ...userInfo,
                                    ...{
                                        password: newPassword,
                                        isChangePassword: true,
                                    },
                                },
                            ])
                        );
                        alert("Đổi mật khẩu thành công!!!");
                    } else {
                        alert("Xác nhận mật khẩu mới sai!!!");
                    }
                } else {
                    alert("Mật khẩu mới phải khác mật khẩu cũ!!!");
                }
            } else {
                alert("Mật khẩu cũ chưa chính xác!!!");
            }
        } else {
            alert("Mật khẩu không được để trống!!!");
        }
    };

    const handleChangePassword = (e: any) => {
        const passwordInputValue = e.target.value.trim();
        const passwordInputFieldName = e.target.name;
        if (passwordInputFieldName === "newPassword") {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            // const digitsRegExp      = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            // const digitsPassword =      digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            let errMsg = "";
            if (passwordInputValue === "") {
                errMsg = "";
            } else if (!uppercasePassword) {
                errMsg = "Cần có ít nhất một chữ in hoa";
            } else if (!lowercasePassword) {
                errMsg = "Cần có ít nhất một chữ in thường";
                // }else if(!digitsPassword){
                //  errMsg="At least one digit";
            } else if (!specialCharPassword) {
                errMsg = "Cần có ít nhất một ký tự đặc biệt";
            } else if (!minLengthPassword) {
                errMsg = "Cần có ít nhất 8 ký tự";
            } else {
                errMsg = "";
            }
            setPasswordErrorMsg(errMsg);
            if (errMsg !== "") {
                setErrorField(passwordInputFieldName);
            } else {
                setErrorField("");
            }
        }
    };

    return userID ? (
        <div className="header container">
            <Header />
            <div className="change-password container">
                <Box className="box-form" maxWidth="600px" textAlign="center">
                    <h2>Đổi mật khẩu</h2>
                    <div className="form">
                        <TextField
                            className="text-field"
                            type="password"
                            label="Mật khẩu cũ"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            className="text-field"
                            type="password"
                            label="Nhập mật khẩu mới"
                            name="newPassword"
                            onChange={(e) => {
                                handleChangePassword(e);
                                setNewPassword(e.target.value);
                            }}
                            helperText={passwordErrorMsg}
                            error={errorField === "newPassword"}
                        />
                        <TextField
                            className="text-field"
                            type="password"
                            label="Nhập lại mật khẩu mới"
                            name="confirmedPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />

                        <Box textAlign="center" padding="10px">
                            <Button variant="contained" onClick={change}>
                                Lưu thay đổi
                            </Button>
                        </Box>
                    </div>
                </Box>
            </div>
        </div>
    ) : (
        <Redirect to={{ pathname: RouterConfig.LOGIN }} />
    );
};

export default ChangePassword;
