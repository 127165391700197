import Grid from "@material-ui/core/Grid";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Url from "../../util/url";
// import DashboardSidebar from "../dashboard/sidebar/index";
import "./style.scss";
import Constants from "../../constant";
import Filter from "./filter/filter";
import FilterByAssignee from "./filter/filter-by-assignee";
import FilterByCampaign from "./filter/filter-by-campaign";
import { UserInfo, IUserInfo } from "../../shared/models/userInfo";
import { ITask } from "../../shared/models/task";
import { ISprint } from "../../shared/models/sprint";
import { useDispatch, useSelector } from "react-redux";

const ReleasesPage = () => {
    const isAdmin = true;
    if (!isAdmin) {
        return <Container style={{ paddingTop: "20px" }}>You doesn't have permission!</Container>;
    }
    return <ReleasesBody />;
};

let TasksfromCampaign: string[] = [];
const ReleasesBody = () => {
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const dispatch = useDispatch();
    const [chooseAssignee, setChooseAssignee] = useState(Constants.ALL_ASSIGNEE);
    const [assigneechosen, setAssigneeChosen] = useState();
    const [allAssignee, setAllAssignee] = useState([
        {
            _id: Constants.ALL_ASSIGNEE,
            firstName: Constants.ALL_ASSIGNEE,
            lastName: "",
        },
    ]);
    const [chooseCampaign, setChooseCampaign] = useState(Constants.ALL_CAMPAIGN);
    const [campaigns, setCampaigns] = useState([
        {
            _id: Constants.ALL_CAMPAIGN,
            title: Constants.ALL_CAMPAIGN,
        },
    ]);
    const [listTaskofCampaign, setListTaskofCampaign] = useState<string[]>([]);
    const [versions, setVersions] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [users, setUsers] = useState<IUserInfo[]>([]);
    const [totalPageOfSprint, setTotalPageOfSprint] = useState(1);
    const [pageOfSprint, setPageOfSprint] = useState(1);
    const [chooseSprint, setChooseSprint] = useState("");
    const allSprint: ISprint[] = useSelector((state: any) => state.sprintState.sprints?.reverse() ?? []);

    useEffect(() => {
        handleGetTotalPage();
        handleGetTotalInfoUser();
        handleGetCampaign();
    }, []);

    useEffect(() => {
        handleGetVersion();
    }, [pagination]);

    useEffect(() => {
        if (chooseSprint) {
            setPagination(1);
            setTotalPage(1);
            handleGetVersionOfSprint();
            handleGetPageOfSprint();
        } else {
            setPageOfSprint(1);
            setTotalPageOfSprint(1);
        }
    }, [chooseSprint]);

    useEffect(() => {
        if (chooseAssignee !== Constants.ALL_ASSIGNEE) {
            setPagination(1);
            setTotalPage(1);
            handleGetAssignee();
        } else {
            setPageOfSprint(1);
            setTotalPageOfSprint(1);
        }
    }, [chooseAssignee]);

    useEffect(() => {
        if (chooseCampaign !== Constants.ALL_CAMPAIGN) {
            setPagination(1);
            setTotalPage(1);
            TasksfromCampaign = [];
            handleGetListTaskOfCampaign();
        } else {
            setPageOfSprint(1);
            setTotalPageOfSprint(1);
        }
    }, [chooseCampaign]);

    useEffect(() => {
        if (chooseSprint) {
            handleGetVersionOfSprint();
        }
    }, [pageOfSprint]);

    const handleGetVersionOfSprint = async () => {
        try {
            const { data: response } = await axios.post(Url("releases/get-version-of-sprint"), {
                page: pageOfSprint,
                sprintId: chooseSprint,
            });
            if (response === Constants.NO_SUCCESS) {
                setVersions([]);
            } else {
                setVersions(response);
            }
        } catch (error) {
            console.log("Bug get version of sprint");
        }
    };

    const handleGetPageOfSprint = async () => {
        try {
            const { data: response } = await axios.post(Url("releases/get-page-of-sprint"), {
                sprintId: chooseSprint,
            });
            setTotalPageOfSprint(response.page);
        } catch (error) {
            console.log("Bug get page of sprint");
        }
    };

    const handleGetTotalInfoUser = async () => {
        try {
            const { data: response } = await axios.get(Url("user/not-disable"));
            const sortAssignee = response.response.sort((assignee1: IUserInfo, assignee2: IUserInfo) =>
                assignee1.firstName.localeCompare(assignee2.firstName)
            );
            const newAllAssignee = allAssignee.concat(sortAssignee);
            setAllAssignee(newAllAssignee);
            setUsers(response.response);
        } catch (error) {
            console.log("Bug get total info user");
        }
        setTotalPage(1);
    };

    const handleChangePagination = (event: any, value: any) => {
        if (chooseSprint) {
            setPageOfSprint(value);
        } else {
            setPagination(value);
        }
    };

    const handleGetTotalPage = async () => {
        try {
            const { data: response } = await axios.get(Url("releases/get-total-page"));
            setTotalPage(response.total);
        } catch (error) {
            console.log("Bug get total page");
        }
    };

    const handleGetVersion = async () => {
        try {
            const { data: response } = await axios.post(Url("releases/get-version"), {
                page: pagination,
            });
            setVersions(response);
        } catch (error) {
            console.log("Bug get version");
        }
    };

    const handleGetInfoUser = (id: any, type: any) => {
        let response: IUserInfo = new UserInfo({
            firstName: "",
            lastName: "",
            gender: "",
            startDate: new Date(),
            position: "",
            avatar: "",
            email: "",
            password: "",
            teamId: 0,
            teams: "",
            role: 0,
            point: 0,
            badge: 0,
            totalScore: 0,
            credit: 0,
            discordID: "",
            directManagerID: "",
            status: 0,
            onBoardingPass: false,
            birth: 0,
            emailActive: false,
            isChangePassword: false,
            address: "",
        });
        let filter: any = users.find((user: IUserInfo) => user._id === id);
        if (filter) {
            if (type === Constants.GET_FIELD_AVATAR) {
                response.avatar = filter.avatar;
            } else if (type === Constants.GET_FIELD_FIRSTNAME) {
                response.firstName = filter.firstName;
            }
        }
        return response;
    };

    const handleGetCampaign = async () => {
        try {
            const { data: response } = await axios.get(Url("campaign/getAll/" + userInfo._id));
            const allCampaign = response.response;
            // console.log(allCampaign)
            const newAllCampaign = campaigns.concat(allCampaign);
            setCampaigns(newAllCampaign);
        } catch (error) {
            console.log("Bug get campaign");
        }
    };

    const handleGetListTaskOfCampaign = async () => {
        try {
            const { data: response } = await axios.post(Url("campaign/list-task-report"), {
                campaignId: chooseCampaign,
            });
            if (response.success) {
                // console.log("hasdasdash", response.response)
                versions?.map((version: any) => {
                    version.tasks?.map((task: ITask, index: number) => {
                        response.response.map((taskofCampaign: ITask) => {
                            if (taskofCampaign._id === task._id) {
                                TasksfromCampaign.push(task._id);
                            }
                        });
                    });
                });
                setListTaskofCampaign(TasksfromCampaign);
            }
        } catch (error) {
            console.log("Bug get list task by campaign");
        }
    };

    const handleGetAssignee = async () => {
        try {
            const { data: response } = await axios.post(Url("user/get-Assignee"), {
                assigneeId: chooseAssignee,
            });
            if (response.success) {
                setAssigneeChosen(response.response._id);
            }
        } catch (error) {
            console.log("Bug getting assignee");
        }
    };

    const handleReturn = () => {
        if (chooseAssignee === Constants.ALL_ASSIGNEE && chooseCampaign === Constants.ALL_CAMPAIGN) {
            return <Filter versions={versions} Constants={Constants} handleGetInfoUser={handleGetInfoUser} />;
        } else if (chooseAssignee !== Constants.ALL_ASSIGNEE && chooseCampaign === Constants.ALL_CAMPAIGN) {
            return (
                <FilterByAssignee
                    versions={versions}
                    Constants={Constants}
                    handleGetInfoUser={handleGetInfoUser}
                    assigneechosen={assigneechosen}
                />
            );
        } else if (chooseCampaign !== Constants.ALL_CAMPAIGN) {
            return (
                <FilterByCampaign
                    versions={versions}
                    Constants={Constants}
                    handleGetInfoUser={handleGetInfoUser}
                    listTaskofCampaign={listTaskofCampaign}
                    assigneechosen={chooseAssignee}
                />
            );
        }
    };

    return (
        <Container className="releases-page">
            <Grid container spacing={10}>
                {/* <Grid item xs={12} sm={12} md={3}>
                    <DashboardSidebar currentSprintID={currentSprintId} backButton={false} />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} className="releases-form">
                    <div className="releases-page-content">
                        <table className="table table-releases">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Version</td>
                                    <td>Date</td>
                                    <td>Task</td>
                                    <td>Assignee</td>
                                </tr>
                            </thead>
                            <tbody>{handleReturn()}</tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <Grid item>
                            <Pagination
                                size="medium"
                                count={chooseSprint ? totalPageOfSprint : totalPage}
                                page={chooseSprint ? pageOfSprint : pagination}
                                onChange={handleChangePagination}
                                color="primary"
                            />
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <div className="filters">
                    <Form.Select
                        className="filter-form by Sprint"
                        value={chooseSprint}
                        onChange={(e) => setChooseSprint(e.target.value)}
                    >
                        {allSprint.map((sprint: ISprint, index) => {
                            if (sprint.name !== Constants.BACK_LOG)
                                return (
                                    <option key={index} value={sprint._id}>
                                        {sprint.name}{" "}
                                        {
                                            // dung xuong dong nhe
                                            sprint.activeSprint
                                                ? "(active)"
                                                : sprint.startDate && sprint.endDate && sprint.name !== Constants.ALL_SPRINT
                                                ? `(${moment(sprint.startDate).format("DD/MM")} - ${moment(
                                                      sprint.endDate
                                                  ).format("DD/MM/YYYY")})`
                                                : ""
                                        }
                                    </option>
                                );
                        })}
                    </Form.Select>
                    <Form.Select
                        className="filter-form by Assignee"
                        value={chooseAssignee}
                        onChange={(e) => setChooseAssignee(e.target.value)}
                    >
                        {allAssignee.map((assignee, index) => {
                            if (assignee._id) {
                                return (
                                    <option key={index} value={assignee._id}>
                                        {assignee.firstName + " " + (assignee.lastName ? assignee.lastName : "")}
                                    </option>
                                );
                            }
                        })}
                    </Form.Select>
                    <Form.Select
                        className="filter-form by Campaign"
                        value={chooseCampaign}
                        onChange={(e) => setChooseCampaign(e.target.value)}
                    >
                        {campaigns.map((campaign, index) => {
                            if (campaign._id)
                                return (
                                    <option key={index} value={campaign._id}>
                                        {campaign.title}
                                    </option>
                                );
                        })}
                    </Form.Select>
                </div>
            </Grid>
        </Container>
    );
};

export default ReleasesPage;
