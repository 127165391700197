import { callApi } from ".";
import Constants from "../constant";

export const getKeywordPerformance = (data) => {
    return callApi({
        method: "POST",
        url: "/indexing/get-keyword-performance",
        params: data,
        baseURl : Constants.API_DASHBOARD_URL
    });
};
export const getDailyCountWorksheetzoneApi = (data) => {
    return callApi({
        method: "POST",
        url: "/worksheetzone/get-daily-count",
        params: data,
        baseURl : Constants.API_DASHBOARD_URL
    });
}