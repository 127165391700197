import {
    GA4_PROPERTY_PASSEMALL,
    isWebPassemall,
    isWebWorksheet,
} from "./config_web";
import Constants from "./constant";
import { ICertificates } from "./models/certificates.model";
import { formatDateToDayAndMonth } from "./util";

export const TYPE_ANALYTIC_INFO = "infomation";
export const TYPE_ANALYTIC_CONTENT = "content";
export const TYPE_ANALYTIC_TRAFFIC = "traffic";
export const TYPE_REVENUE = "revenue";
export const TYPE_ANALYTIC_TRENDING_KEYWORD = "trending_keyword";
export const TYPE_ANALYTIC_INDEX_PROGRESS = "index_progress";
export const TYPE_ANALYTIC_KEYWORD_PERFORMANCE = "keyword_performance";
export const TYPE_ANALYTIC_DAILY_COUNT = "daily_count";

export const TYPE_ANALYTIC_AB_TEST = "ab_test";
export const TYPE_ANALYTIC_UX = "ux";
export const TYPE_ANALYTIC_INDEX = "index";
export const BACK = "";

export const AVG_SESSION = "avg_session";
export const AVG_PAGE_USER = "avg_page_user";
export const STICKINESS = "stickiness";
export const CHURN_RATE = "churn_rate";
export const RETENTION = "retention";
export const ACTIVE_USER = "active_user";
export const DOWNLOAD_USER = "download_user";
export const FIRST_DOWNLOAD_USER = "first_download_user";
export const TOTAL_STEP_TO_FIRST_DOWNLOAD = "total_step_to_first_download";

export const OPTIONS_PLATFORM = ["all", "android", "ios"];
export const OPTIONS_PLATFORM_FULL = [
    "web",
    "flutter android",
    "flutter ios",
    "native android",
    "native ios",
];
export const REVENUE_ALL = "REVENUE_ALL";
export const REVENUE_ADS_WEB = "REVENUE_ADS_WEB";
export const REVENUE_PRO_WEB = "REVENUE_PRO_WEB";
export const REVENUE_ADS_APP = "REVENUE_ADS_APP";
export const REVENUE_PRO_APP = "REVENUE_PRO_APP";

export const REVENUE_PRO_WEB_ONE_TIME = "REVENUE_PRO_WEB_ONE_TIME";
export const REVENUE_PRO_WEB_SUBSCRIPTION = "REVENUE_PRO_WEB_SUBSCRIPTION";
export const REVENUE_PRO_WEB_CARD_VISA = "REVENUE_PRO_WEB_CARD_VISA";
export const REVENUE_PRO_WEB_PAYPAL = "REVENUE_PRO_WEB_PAYPAL";
export const MENU_LIST = [
    //   {
    //     title: "Info",
    //     tab: "info",
    //   },
    {
        title: "Session ",
        tab: "avg_session",
    },
    {
        title: "Pages per user",
        tab: "avg_page_user",
    },
    {
        title: "Active Users",
        tab: "active_user",
    },
    {
        title: "Stickiness",
        tab: "stickiness",
    },
    {
        title: "Churn Rate",
        tab: "churn_rate",
    },
    {
        title: "Retention",
        tab: "retention",
    },
    // {
    //   title: "Avg Time Using App",
    //   tab: "abg_using_app",
    // },
];

export const MENU_LIST_WORKSHEET = [
    //   {
    //     title: "Info",
    //     tab: "info",
    //   },
    {
        title: "User Stickiness",
        tab: "stickiness",
    },
    {
        title: "Total download/ User",
        tab: "download_user",
    },
    {
        title: "Total step to first download/ User",
        tab: "first_download_user",
    },
];

export const ANALYTICS_DATA = [
    { name: "Analytics", type: TYPE_ANALYTIC_TRAFFIC },
    { name: "Information", type: TYPE_ANALYTIC_INFO },
    { name: "Content", type: TYPE_ANALYTIC_CONTENT },
    { name: "Revenue", type: TYPE_REVENUE },
    { name: "Ab test", type: TYPE_ANALYTIC_AB_TEST },
    { name: "Trending Keywords", type: TYPE_ANALYTIC_TRENDING_KEYWORD },
    { name: "Index Progress", type: TYPE_ANALYTIC_INDEX_PROGRESS },
    { name: "Keyword Performance", type: TYPE_ANALYTIC_KEYWORD_PERFORMANCE },
    { name: "Daily Count", type: TYPE_ANALYTIC_DAILY_COUNT },

    // { name: "UX", type: TYPE_ANALYTIC_UX },
    // { name: "Index", type: TYPE_ANALYTIC_INDEX },
];

export const getFlatForm = (id: number) => {
    switch (id) {
        case Constants.PLATFORM_WEB:
            return "web";
        case Constants.PLATFORM_FLUTTER_ANDROID:
            return "Flutter Android";
        case Constants.PLATFORM_FLUTTER_IOS:
            return "Flutter iOS";
        case Constants.PLATFORM_REACT_NATIVE_ANDROID:
            return "React Native Android";
        case Constants.PLATFORM_REACT_NATIVE_IOS:
            return "React Native iOS";
        default:
            return "";
    }
};

export const getCategoryName = (id: number) => {
    switch (id) {
        case Constants.CATEGORY_WEB:
            return "web";
        case Constants.CATEGORY_ANDROID:
            return "Android";
        case Constants.CATEGORY_IOS:
            return "IOS";
        default:
            return "";
    }
};

export const CATEGORY_TYPE = [
    Constants.CATEGORY_WEB,
    Constants.CATEGORY_ANDROID,
    Constants.CATEGORY_IOS,
];

export const PLATFORM_CONFIG = [
    {
        type: Constants.PLATFORM_WEB,
        name: "Web",
        category: Constants.CATEGORY_WEB,
        frameworkName: "Web",
    },
    {
        type: Constants.PLATFORM_FLUTTER_ANDROID,
        name: "Flutter Android",
        frameworkName: "Flutter",
        category: Constants.CATEGORY_ANDROID,
    },
    {
        type: Constants.PLATFORM_FLUTTER_IOS,
        name: "Flutter IOS",
        frameworkName: "Flutter",
        category: Constants.CATEGORY_IOS,
    },
    {
        type: Constants.PLATFORM_REACT_NATIVE_ANDROID,
        name: "React Native Android",
        frameworkName: "React Native",
        category: Constants.CATEGORY_ANDROID,
    },
    {
        type: Constants.PLATFORM_REACT_NATIVE_IOS,
        name: "React Native IOS",
        frameworkName: "React Native",
        category: Constants.CATEGORY_IOS,
    },
];

export const getPercentRealPrice = (osType: string) => {
    return osType == "ios" ? 0.7 : 1;
};

export const getPlatformName = (id: number) => {
    return PLATFORM_CONFIG.find((p) => p.type == id)?.name ?? "";
};

export const mergeData = (
    resData: any,
    platformIds: number[],
    onlyMonth?: boolean,
    childData?: boolean
) => {
    let newDate = new Date();
    if (resData && platformIds?.length) {
        let result: any = {};
        platformIds.forEach((platformId) => {
            if (resData[platformId]) {
                Object.keys(resData[platformId]).forEach((date) => {
                    let value = result[date] ?? 0;
                    let data = resData[platformId][date];
                    if (childData) {
                        Object.keys(data).forEach((key) => {
                            let obj: any = {};
                            obj[key] = data[key];
                            if (result[date]) {
                                result[date] = Object.assign(result[date], obj);
                            } else {
                                result[date] = obj;
                            }
                        });
                    } else {
                        result[date] = value + data;
                    }
                });
            }
        });
        return Object.keys(result).map((date) => {
            let dateFormat = onlyMonth
                ? new Date(
                      newDate.setMonth(Number(date) - 1)
                  ).toLocaleDateString("en-US", { month: "short" })
                : formatDateToDayAndMonth(date, false, true);

            return {
                date: dateFormat,
                value: result[date],
            };
        });
    }
};

export const formatDataCompare = (
    certificatesSelect: ICertificates[],
    result: any,
    platids: any
) => {
    if (result && platids && platids.length) {
        const dataValue: any = certificatesSelect.map((certificate, index) => {
            const data: any = certificate?.appInfos
                ?.map((app) => {
                    return [app.platformId, result[app.gaID] ?? 0];
                })
                .filter((item) => {
                    return item !== undefined;
                });

            const name = certificate?.name;
            return {
                ...Object.fromEntries(data),
                name,
            };
        });
        return dataValue?.map((dt: any, index: number) => {
            let resultWeb: any = {};
            let resultRn: any = {};
            let resultFl: any = {};
            platids.forEach((plats: any) => {
                plats.forEach((plat: any) => {
                    if (plat == Constants.PLATFORM_WEB) {
                        resultWeb["web"] = (
                            Number(resultWeb["web"] ?? 0) +
                            Number(dt[plat] ?? 0)
                        )?.toFixed(0);
                    } else if (
                        plat == Constants.PLATFORM_FLUTTER_ANDROID ||
                        plat == Constants.PLATFORM_FLUTTER_IOS
                    ) {
                        resultFl["flutter"] = (
                            Number(resultFl["flutter"] ?? 0) +
                            Number(dt[plat] ?? 0)
                        )?.toFixed(0);
                    } else if (
                        plat == Constants.PLATFORM_REACT_NATIVE_ANDROID ||
                        plat == Constants.PLATFORM_REACT_NATIVE_IOS
                    ) {
                        resultRn["native"] = (
                            Number(resultRn["native"] ?? 0) +
                            Number(dt[plat] ?? 0)
                        )?.toFixed(0);
                    }
                });
            });

            return {
                appName: dt.name,
                ...resultWeb,
                ...resultFl,
                ...resultRn,
            };
        });
    }
};

export const getPlatformIds = (optionValue: any) => {
    let platformIds: any = [];
    if (optionValue == OPTIONS_PLATFORM[0]) {
        platformIds = [
            [
                Constants.PLATFORM_FLUTTER_ANDROID,
                Constants.PLATFORM_FLUTTER_IOS,
            ],
            [
                Constants.PLATFORM_REACT_NATIVE_ANDROID,
                Constants.PLATFORM_REACT_NATIVE_IOS,
            ],
            [Constants.PLATFORM_WEB],
        ];
    } else if (optionValue == OPTIONS_PLATFORM[1]) {
        platformIds = [
            [Constants.PLATFORM_FLUTTER_ANDROID],
            [Constants.PLATFORM_REACT_NATIVE_ANDROID],
        ];
    } else {
        platformIds = [
            [Constants.PLATFORM_FLUTTER_IOS],
            [Constants.PLATFORM_REACT_NATIVE_IOS],
        ];
    }
    return platformIds;
};

export const getAppInfoGa = (
    certificates: ICertificates,
    platformIds: number[]
) => {
    // if (isWebPassemall(certificates.slug)) {
    //     return certificates.appInfos
    //         ?.filter((app) => platformIds.includes(app.platformId) && app.gaID)
    //         ?.map((app) => {
    //             return {
    //                 type: app.platformId,
    //                 streamId: GA4_PROPERTY_PASSEMALL + "",
    //             };
    //         });
    // } else {
    return certificates.appInfos
        ?.filter((app) => platformIds.includes(app.platformId))
        ?.map((app) => {
            let obj: any = { type: app.platformId, streamId: app.gaID + "" };
            if (
                certificates.parent?.length &&
                app.platformId == Constants.PLATFORM_WEB
            ) {
                obj.streamId = GA4_PROPERTY_PASSEMALL + "";
                obj.subApp = certificates.slug;
            }
            return obj;
        });
    // }
};
