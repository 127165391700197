import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import Constants from "../../../constant";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import Config from "../../../shared/models/config";
import { ISprint } from "../../../shared/models/sprint";
import { ITask } from "../../../shared/models/task";
import { IUserInfo, UserInfo } from "../../../shared/models/userInfo";
import { isAdmin } from "../../../util";
import { checkContainsArray } from "../sprint-columns/DashboardSprintColumnsV2";
import "./style.scss";
import { ITaskScores, TaskScore } from "../../../shared/models/taskScore";

const DashboardUsersFilter: FC<{
    currentUserIds: string[];
    onSelectedUser: (id: string) => void;
    showTooltip?: boolean;
    isFilterTask?: boolean;
    filterUsers?: (user: IUserInfo) => boolean;
    setMemberGroupIds?: Set<string>;
    currentSprintId?: string;
    userTaskScore?: any; //{userId: ITaskScores}
}> = ({
    currentUserIds = [],
    onSelectedUser,
    showTooltip = true,
    isFilterTask = true,
    filterUsers,
    setMemberGroupIds,
    currentSprintId,
    userTaskScore,
}) => {
    const tasks: ITask[] = useSelector((state: any) => state.taskState.tasks ?? []);
    const userInfo = useSelector((state: any) => state.authState.user);
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const ref = useRef<HTMLDivElement | null>(null);
    const [overflow, setOverflow] = useState(false);
    const params: any = useParams();
    const { id } = params;
    let sprintID: string = currentSprintId ?? id;
    if (!sprintID) {
        sprintID = sprints.find((s: any) => s.activeSprint)?._id ?? "";
    }
    // const currentSprint = sprints.find((sprint: any) => sprint._id === sprintID) ?? sprints[0];
    let usersRelatedWithMemberGroup = new Set<string>();
    //admin: all users
    //lead: all users in group and users related to group's users (task)
    tasks.forEach((task) => {
        // find all users related to group's members
        if (task.statusView !== Config.STATUS_PUBLIC) return;
        if (
            checkContainsArray(Array.from(setMemberGroupIds ?? []), [
                task.assigneeId,
                task.reporterId,
                ...task.testerIds,
                task.leadDevId,
                ...task.inform,
            ]) ||
            task.createdUserId == userInfo._id
        ) {
            let setuser = new Set<string>([
                task.assigneeId,
                task.reporterId,
                ...task.testerIds,
                task.leadDevId,
                ...task.inform,
            ]);
            setuser.forEach((i) => {
                if (i && !setMemberGroupIds?.has(i)) usersRelatedWithMemberGroup.add(i);
            });
        }
    });

    useEffect(() => {
        setOverflow(isOverflown());
    }, [usersRelatedWithMemberGroup]);
    let _users: IUserInfo[] = JSON.parse(JSON.stringify(users)).map((u: any) => new UserInfo(u));
    return (
        <div style={{ display: "flex", alignItems: "flex-start" }} id="filter-user-container">
            {overflow && (
                <IconButton onClick={() => ref.current?.scroll({ behavior: "smooth", left: 0 })} style={{ left: "8px" }}>
                    <ArrowBackIosIcon />
                </IconButton>
            )}
            <div className="users-filter" ref={ref} id="users-filter-element">
                {_users
                    .sort((a, b) => (a._id == userInfo?._id ? -1 : 1))
                    .filter(
                        filterUsers
                            ? filterUsers
                            : (user) => {
                                  //show user have related with
                                  return (
                                      user.status == Constants.STATUS_PUBLIC &&
                                      (isAdmin(userInfo)
                                          ? true
                                          : setMemberGroupIds?.has(user._id) || usersRelatedWithMemberGroup?.has(user._id))
                                  );
                              }
                    )
                    .map((user) => {
                        let countOtherRole = 0;
                        // let tasksDone = 0;
                        // let pointDone = 0;
                        // let totalPoint = 0;
                        let taskScores: ITaskScores = new TaskScore();
                        if (showTooltip) {
                            taskScores = userTaskScore[user._id] ?? taskScores;
                        }
                        let assignedTasks = tasks.filter((task) => {
                            // find all tasks which are assigned to a user
                            if (task.assignee?._id === user._id) {
                                //tat ca task con deu co task cha (da xu ly o getAllTaskAPI)
                                // totalPoint += task?.point ?? 0;
                                return true;
                            }
                            if (
                                task.reporter?._id === user._id || // else count report or tester role
                                task.testerIds?.indexOf(user._id) > -1 ||
                                task.leadDevId === user._id || //
                                task.inform.includes(user._id) //
                            ) {
                                countOtherRole++;
                            }
                            return false;
                        });
                        if (assignedTasks.length === 0 && countOtherRole === 0 && isFilterTask) {
                            return null; //khong co task thi khong hien
                        }
                        const avatar = !!user.avatar ? user.avatar : defaultAvatar;
                        return (
                            <div
                                key={user._id ?? user._id}
                                onClick={() => onSelectedUser(user._id)}
                                data-tip
                                data-for={user._id}
                                style={{ width: "44px" }}
                            >
                                <img src={avatar} className={currentUserIds?.indexOf(user._id) > -1 ? "active" : ""} />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p>{user.firstName}</p>
                                </div>

                                {showTooltip && (
                                    <ReactTooltip id={user._id} type="info">
                                        <div>
                                            {`${taskScores?.totalTasks?.label}: ` +
                                                (taskScores?.totalTasks?.value ?? 0) +
                                                `, ${taskScores?.totalTaskInSprint?.label}: ` +
                                                (taskScores?.totalTaskInSprint?.value ?? 0)}
                                        </div>
                                        <div>
                                            {`${taskScores?.tasksDone?.label}: ` +
                                                (taskScores?.tasksDone?.value ?? 0) +
                                                `, ${taskScores?.pointDone?.label}:  ` +
                                                (taskScores?.pointDone?.value ?? 0)}
                                        </div>
                                        <div>{`${taskScores.totalPoint?.label}: ${taskScores.totalPoint?.value ?? 0}`}</div>
                                    </ReactTooltip>
                                )}
                            </div>
                        );
                    })}
            </div>
            {overflow && (
                <IconButton
                    onClick={() =>
                        ref.current?.scroll({
                            behavior: "smooth",
                            left: 9999,
                        })
                    }
                    style={{ right: "2px" }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </div>
    );
};

function isOverflown() {
    let element = document.getElementById("users-filter-element");
    if (!!!element) return false;
    return element.scrollWidth > element.clientWidth;
}
export default DashboardUsersFilter;
