import { ArrowBackIos } from "@material-ui/icons";
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Constant from "../../../../../constant";
import { addTest, updateTest } from "../../../../../redux/actions/testCase.action";
import { ITestCase, TestCase } from "../../../../../shared/models/testCase";
import { ITestSection } from "../../../../../shared/models/testSection";
import { getPriorityColor } from "../../../../../util";
import Url from "../../../../../util/url";
import Editor from "../components/Editor";

interface IPriority {
    value: number;
    name: string;
}
const SELECT_ITEMS: IPriority[] = [
    {
        value: Constant.HIGHEST,
        name: "Highest",
    },
    {
        value: Constant.HIGH,
        name: "High",
    },
    {
        value: Constant.MEDIUM,
        name: "Medium",
    },
    {
        value: Constant.LOW,
        name: "Low",
    },
    {
        value: Constant.LOWEST,
        name: "Lowest",
    },
];

export const getLastTestIndex = (tests: ITestCase[]) => {
    if (tests.length === 0) return 0;

    let max = tests[0].testIndex;
    for (let i = 1; i < tests.length; i++) {
        if (tests[i].testIndex > max) {
            max = tests[i].testIndex;
        }
    }

    return max;
};

const AddEditAllTestCases: FC<{
    test: ITestCase | null;
    tests: ITestCase[];
    taskId: string;
    handleToggleEdit: any;
    sections: ITestSection[];
    section: ITestSection | null;
}> = ({ test, tests, taskId, handleToggleEdit, sections, section }) => {
    const [title, setTitle] = useState("");
    const [priority, setPriority] = useState(Constant.MEDIUM);
    const [description, setDescription] = useState("");
    const [expectedResult, setExpectedResult] = useState("");
    const [sectionID, setSectionID] = useState("other");
    const dispatch = useDispatch();
    const isEdit = Boolean(test);

    useEffect(() => {
        if (test) {
            setTitle(test.title);
            setPriority(test.priority);
            setSectionID(test.sectionID);
        }
    }, [test]);

    useEffect(() => {
        if (section) {
            if (section._id) {
                setSectionID(section._id);
            } else {
                setSectionID("other");
            }
        }
    }, [section]);

    const handleSubmit = async () => {
        const { _id } = JSON.parse(localStorage.getItem("userInfo") ?? "");
        let newTest = new TestCase({
            title,
            priority,
            sectionID,
            steps: description,
            expectedResult,
            taskID: taskId,
            userID: _id,
            lastUpdate: new Date().getTime(),
            note: "",
            testIndex: getLastTestIndex(tests) + 1,
        });

        if (test) {
            newTest = {
                ...newTest,
                _id: test._id,
                testIndex: test.testIndex,
            };
        }

        try {
            if (isEdit) {
                await axios.post(Url("test-case/updates-test-cases"), [
                    newTest,
                ]);
                dispatch(updateTest(newTest));
            } else {
                const { data } = await axios.post(
                    Url("test-case/inserts-test-cases"),
                    [newTest]
                );
                dispatch(addTest(data[0]));
            }

            handleToggleEdit();
        } catch (error) {
            // console.log(error);
        }
    };

    return (
        <div className="edit-cases">
            <div className="edit-cases__title">
                <IconButton color="primary" onClick={handleToggleEdit}>
                    <ArrowBackIos />
                </IconButton>
                <span>{isEdit ? `Edit Case` : "New Case"}</span>
            </div>
            <div className="edit-cases__cases">
                <div className="row_1">
                    <TextField
                        className="row_1__title"
                        label="Title"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="row_2">
                    <FormControl className="row_2__section">
                        <InputLabel id="Section">Section</InputLabel>
                        <Select
                            labelId="Section"
                            label="Section"
                            value={sectionID}
                            onChange={(e) => setSectionID(e.target.value)}
                        >
                            <MenuItem value="other">Default Section</MenuItem>
                            {sections.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className="row_2__priority">
                        <InputLabel id="Priority">Priority</InputLabel>
                        <Select
                            labelId="Priority"
                            label="Priority"
                            value={priority}
                            onChange={(e: any) => setPriority(e.target.value)}
                        >
                            {SELECT_ITEMS.map((item) => (
                                <MenuItem
                                    key={item.value}
                                    sx={{ color: getPriorityColor(item.value) }}
                                    value={item.value}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="row_3">
                    <InputLabel className="row_3__label" focused>
                        Description
                    </InputLabel>
                    <Editor
                        data={description}
                        onReady={() => {
                            if (test) {
                                setDescription(test.steps);
                            }
                        }}
                        onChange={(e: any, editor: any) =>
                            setDescription(editor.getData())
                        }
                    />
                </div>

                <div className="row_4">
                    <InputLabel className="row_4__label" focused>
                        Expected result
                    </InputLabel>
                    <Editor
                        data={expectedResult}
                        onReady={() => {
                            if (test) {
                                setExpectedResult(test.expectedResult);
                            }
                        }}
                        onChange={(e: any, editor: any) =>
                            setExpectedResult(editor.getData())
                        }
                    />
                </div>
            </div>
            <div className="edit-cases__btn">
                <Button variant="contained" onClick={handleSubmit} size="large">
                    {isEdit ? "Update" : "Create"}
                </Button>
            </div>
        </div>
    );
};

export default AddEditAllTestCases;
