import { Avatar, IconButton } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ITaskComment, TaskComment } from "../../../../../../../shared/models/task";
import { IUserInfo } from "../../../../../../../shared/models/userInfo";
import "./index.scss";
import defaultAvatar from "../../../../../../../assets/images/default-avatar.png";

const CommentItem: FC<{
    comment: ITaskComment;
    onEditComment: (comment: ITaskComment) => void;
    onDeleteComment: (comment: ITaskComment) => void;
}> = ({ comment, onEditComment, onDeleteComment }) => {
    const userInfo: IUserInfo = useSelector((state: any) =>
        state.userInfoState.userInfos.find((u: any) => u._id == comment.userID)
    );
    const [editState, setEditState] = useState(false);
    const [value, setValue] = useState("");
    useEffect(() => {
        setEditState(false);
        setValue(comment.content);
    }, [comment]);

    const ref = useRef<HTMLTextAreaElement | null>(null);
    if (!userInfo) {
        return <div>{comment.content}</div>;
    }
    const avatar = userInfo?.avatar ? userInfo?.avatar : defaultAvatar;

    const onUpdate = () => {
        if (!value) {
            // toast("Content empty!");
            return;
        }
        const newComment: ITaskComment = new TaskComment({
            ...comment,
            content: value,
        });
        onEditComment(newComment);
    };

    return (
        <div className="comment-item">
            <Avatar alt={userInfo?.firstName} src={avatar} />
            <div className="content">
                <div className="content_top">
                    <div className="user_name">{userInfo.firstName + " " + userInfo.lastName}</div>
                    {editState ? (
                        <div className="edit">
                            <textarea
                                ref={ref}
                                name={"comment_" + comment._id}
                                placeholder="Edit a comment ..."
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                }}
                                onKeyDown={(event) => {
                                    if (event.code == "Enter" && !event.shiftKey) {
                                        event.preventDefault();
                                        onUpdate();
                                    }
                                }}
                            />
                            <div className="button">
                                <IconButton color="primary" onClick={() => onUpdate()}>
                                    <SendIcon />
                                </IconButton>
                            </div>
                        </div>
                    ) : (
                        <TextContent text={comment.content} />
                    )}
                </div>
                <div className="tools">
                    <span onClick={() => setEditState(true)}>Edit</span>
                    <span onClick={() => onDeleteComment(comment)}>Delete</span>
                    <span className="time">
                        {moment(comment.lastUpdate ?? comment.createdDate).format("HH:mm - DD/MM/YYYY")}
                    </span>
                </div>
            </div>
        </div>
    );
};

const TextContent: FC<{ text: string }> = ({ text = "" }) => {
    const content = document.createElement("div");
    content.innerHTML = text;
    return (
        <div
            className="msg"
            dangerouslySetInnerHTML={{
                __html: content.innerText.replaceAll("\n", "<br/>"),
            }}
        ></div>
    );
};

export default CommentItem;
