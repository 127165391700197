import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import "./style.scss";

function EventModal({ showModal, handleShowModal, externalData }) {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [data, setData] = useState({
        userId: externalData?.userId ?? id,
        date: externalData?.date ?? moment(new Date()).format("YYYY-MM-DD"),
        event: externalData?.event ?? "",
    });

    useEffect(() => {
        if (externalData) {
            setData(externalData);
        }
    }, [externalData]);

    const fields = ["date", "event"];

    const headers = ["Thời gian", "Sự kiện"];

    const resetState = () => {
        setData({
            date: new Date(),
            event: "",
        });
    };

    const onChangeTextField = (e, field) => {
        setData((prevState) => ({
            ...prevState,
            [field]: e.target.value,
        }));
    };

    const onChangeDate = (e, field) => {
        setData((prevState) => ({
            ...prevState,
            [field]: e.target.value,
        }));
    };

    const handleSubmitForm = () => {
        if (data._id) {
            dispatch({ type: "UPDATE_CAREER_EVENT", data });
        } else {
            dispatch({ type: "CREATE_CAREER_EVENT", data });
        }
        handleShowModal();
        resetState();
        const scrollToEvent = setTimeout(() => {
            const el = document.getElementById("scroll-to-event");
            el.scrollIntoView({ behavior: "smooth" });
            return clearTimeout(scrollToEvent);
        }, 1000);

        // const el = document.getElementsByClassName("career-event-container");
        // el.scrollIntoView();
        // console.log(el);
        // scrollToEvent();
    };

    const handleDeleteForm = () => {
        dispatch({ type: "DELETE_CAREER_EVENT", data });
        handleShowModal();
        resetState();
    };

    return (
        <Modal show={showModal} onHide={handleShowModal} size="lg" className="event-modal">
            <Modal.Header closeButton>
                <Modal.Title>Form lộ trình công danh</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fields.map((field, index) => (
                    <div className="field" key={index}>
                        <h5>{headers[index]}</h5>
                        {field === "date" ? (
                            <FormControl
                                type="date"
                                name={field}
                                value={data[field]}
                                onChange={(e) => onChangeDate(e, field)}
                            />
                        ) : (
                            <FormControl
                                as="textarea"
                                rows={3}
                                name={field}
                                value={data[field]}
                                onChange={(e) => onChangeTextField(e, field)}
                            />
                        )}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleShowModal}>
                    Đóng
                </Button>
                <Button variant="success" onClick={handleSubmitForm}>
                    Lưu
                </Button>
                {data._id && (
                    <Button variant="danger" onClick={handleDeleteForm}>
                        Xoá
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default EventModal;
