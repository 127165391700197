import Config from "../../shared/models/config";
import OutOfficeRegistration from "../outoffice-registration/OutOfficeRegistration";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
const HolidayRequest = () => {
    return (
        <div className="wiki container">
            <div className="documentations">
                <div
                    style={{ position: "absolute", left: 0, top: "12px", cursor: "pointer" }}
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <ArrowBackRoundedIcon />
                </div>
                <OutOfficeRegistration type={Config.TYPE.holiday} />
            </div>
        </div>
    );
};

export default HolidayRequest;
