import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    AVG_PAGE_USER,
    CHURN_RATE,
    DOWNLOAD_USER,
    FIRST_DOWNLOAD_USER,
    getAppInfoGa,
    getPlatformName,
    RETENTION,
    STICKINESS,
    TOTAL_STEP_TO_FIRST_DOWNLOAD,
} from "../../../../config_data_analytic";
import { hasApp, isWebWorksheet } from "../../../../config_web";
import Constants from "../../../../constant";
import { Certificates } from "../../../../models/certificates.model";
import {
    getAVGUserPageViewAPI,
    getChurnRateAppAPI,
    getInfoWebAppByEventNameAPI,
    getRetentionAppAPI,
    getStickinessAPI,
    getTotalDownloadUserAPI,
    getTotalStepUserDownloadAPI,
} from "../../../../services/traffic.service";
import {
    formatDate,
    hasAppFlutterAndroid,
    hasAppFlutterIOS,
    hasAppRNAndroid,
    hasAppRNIOS,
    hasAppWeb,
} from "../../../../util";
import CircularIndeterminate from "../../../common/icon/Loading";
import "./index.scss";

const RetentionView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-retention">
            {hasAppWeb(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_WEB}
                    tab={tab}
                />
            )}
            {/* {hasAppFlutterAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_ANDROID}
                    tab={tab}
                />
            )}
            {hasAppFlutterIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_IOS}
                    tab={tab}
                />
            )}
            {hasAppRNAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_ANDROID}
                    tab={tab}
                />
            )}
            {hasAppRNIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_IOS}
                    tab={tab}
                />
            )} */}
        </div>
    );
};

const ChurnRateView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-retention">
            {hasAppWeb(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_WEB}
                    tab={tab}
                    unit={"%"}
                />
            )}
            {hasAppFlutterAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_ANDROID}
                    tab={tab}
                    unit={"%"}
                />
            )}
            {hasAppFlutterIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_IOS}
                    tab={tab}
                    unit={"%"}
                />
            )}

            {hasAppRNAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_ANDROID}
                    tab={tab}
                    unit={"%"}
                />
            )}
            {hasAppRNIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_IOS}
                    tab={tab}
                    unit={"%"}
                />
            )}
        </div>
    );
};

const StickinessView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    const isWS = isWebWorksheet(certificates.slug);
    return (
        <div className="main-retention">
            {isWS ? (
                <>
                    <ViewApp
                        certificates={certificates}
                        platformId={Constants.PLATFORM_WEB}
                        tab={tab}
                        unit={"%"}
                    />
                </>
            ) : (
                <>
                    {hasAppFlutterAndroid(certificates) && (
                        <ViewApp
                            certificates={certificates}
                            platformId={Constants.PLATFORM_FLUTTER_ANDROID}
                            tab={tab}
                            unit={"%"}
                        />
                    )}
                    {hasAppFlutterIOS(certificates) && (
                        <ViewApp
                            certificates={certificates}
                            platformId={Constants.PLATFORM_FLUTTER_IOS}
                            tab={tab}
                            unit={"%"}
                        />
                    )}
                    {hasAppRNAndroid(certificates) && (
                        <ViewApp
                            certificates={certificates}
                            platformId={Constants.PLATFORM_REACT_NATIVE_ANDROID}
                            tab={tab}
                            unit={"%"}
                        />
                    )}
                    {hasAppRNIOS(certificates) && (
                        <ViewApp
                            certificates={certificates}
                            platformId={Constants.PLATFORM_REACT_NATIVE_IOS}
                            tab={tab}
                            unit={"%"}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const PagesPerUserView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-retention">
            {hasAppFlutterAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_ANDROID}
                    tab={tab}
                />
            )}

            {hasAppFlutterIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_FLUTTER_IOS}
                    tab={tab}
                />
            )}
            {hasAppRNAndroid(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_ANDROID}
                    tab={tab}
                />
            )}
            {hasAppRNIOS(certificates) && (
                <ViewApp
                    certificates={certificates}
                    platformId={Constants.PLATFORM_REACT_NATIVE_IOS}
                    tab={tab}
                />
            )}
        </div>
    );
};

const TotalDownloadUserView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-retention">
            <ViewApp
                certificates={certificates}
                platformId={Constants.PLATFORM_WEB}
                tab={tab}
            />
        </div>
    );
};

const TotalStepToDownloadView = ({
    certificates,
    tab,
}: {
    certificates: Certificates;
    tab: string;
}) => {
    return (
        <div className="main-retention">
            <ViewApp
                certificates={certificates}
                platformId={Constants.PLATFORM_WEB}
                tab={tab}
            />
        </div>
    );
};

const ViewApp = ({
    certificates,
    platformId,
    tab,
    unit = "",
}: {
    certificates: Certificates;
    platformId: number;
    tab: string;
    unit?: string;
}) => {
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [appValues, setAppValues] = useState([]);
    useEffect(() => {
        async function getRetentionFC() {
            setAppValues([]);
            let result: any = [];
            let apps: any = getAppInfoGa(certificates, [platformId]);
            // certificates.appInfos
            //     ?.filter((appInfo) => appInfo.platformId == platformId)
            //     .map((app) => {
            //         return { type: app.platformId, streamId: app.gaID + "" };
            //     });
            if (apps?.length > 0) {
                let resData;
                switch (tab) {
                    case AVG_PAGE_USER:
                        resData = await getAVGUserPageViewAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            formatDate(new Date(endTime), "YYYY-MM-DD")
                        );
                        break;
                    case CHURN_RATE:
                        let currentDate = formatDate(new Date(), "YYYY-MM-DD");
                        let endDate = formatDate(
                            new Date(endTime),
                            "YYYY-MM-DD"
                        );
                        if (currentDate == endDate) {
                            endDate = formatDate(
                                new Date(
                                    endTime - 2 * Constants.TIMESTAMP_DATE
                                ),
                                "YYYY-MM-DD"
                            );
                        }
                        resData = await getChurnRateAppAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            endDate
                        );
                        break;
                    case STICKINESS:
                        resData = await getStickinessAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            formatDate(new Date(endTime), "YYYY-MM-DD")
                        );
                        break;
                    case RETENTION:
                        resData = await getRetentionAppAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            formatDate(new Date(endTime), "YYYY-MM-DD")
                        );
                        break;
                    case DOWNLOAD_USER:
                        resData = await getTotalDownloadUserAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            formatDate(new Date(endTime), "YYYY-MM-DD")
                        );
                        break;
                    case FIRST_DOWNLOAD_USER:
                        resData = await getTotalStepUserDownloadAPI(
                            apps,
                            formatDate(new Date(startTime), "YYYY-MM-DD"),
                            formatDate(new Date(endTime), "YYYY-MM-DD")
                        );
                        let result =
                            resData[0].totalStep / resData[0].totalUser;
                        resData = [
                            {
                                type: Constants.PLATFORM_WEB,
                                result,
                            },
                        ];
                        break;
                    // case FIRST_DOWNLOAD_USER:
                    //     resData = await getInfoWebAppByEventNameAPI(
                    //         apps,
                    //         "step_to_first_download",
                    //         formatDate(new Date(startTime), "YYYY-MM-DD"),
                    //         formatDate(new Date(endTime), "YYYY-MM-DD")
                    //     );

                    //     resData = [
                    //         {
                    //             type: Constants.PLATFORM_WEB,
                    //             result: resData[Constants.PLATFORM_WEB].result,
                    //         },
                    //     ];
                    //     break;
                    default:
                        break;
                }

                resData.forEach((data: any) => {
                    result.push({ type: data.type, value: data.result });
                });
                setAppValues(result);
            }
        }
        getRetentionFC();
    }, [startTime, endTime]);
    let appValue: any = appValues.find(
        (appValue: any) => appValue.type == platformId
    );
    let value = -1;
    if (appValue) {
        value = appValue?.value ?? 0;
        value = Math.round(value * 100) / 100;
        if (unit == "%") {
            value = Math.round(value * 100);
        }
    }
    let appInfo = certificates.appInfos?.find(
        (appInfo: any) => appInfo.platformId == platformId
    );
    const platformName = getPlatformName(platformId);
    return (
        <>
            <div className="main-retention-item" key={appInfo?.id}>
                <div>{appInfo?.name + " " + platformName}</div>
                <div>
                    {value > -1 ? (
                        value + " " + unit
                    ) : (
                        <CircularIndeterminate />
                    )}
                </div>
            </div>
        </>
    );
};

export {
    RetentionView,
    ChurnRateView,
    StickinessView,
    PagesPerUserView,
    TotalDownloadUserView,
    TotalStepToDownloadView,
};
