import { ISprint } from "../../shared/models/sprint";
import { IStatus } from "../../shared/models/status";
import { ITask, ITaskHistory } from "../../shared/models/task";

export enum TaskTypes {
    GET_ALL_TASK_BY_SPRINT = "GET_ALL_TASK_BY_SPRINT",
    GET_ALL_TASK_BY_SPRINT_SUCCESS = "GET_ALL_TASK_BY_SPRINT_SUCCESS",
    GET_ALL_TASK_BY_SPRINT_FAILED = "GET_ALL_TASK_BY_SPRINT_FAILED",
    UPDATE_TASK = "UPDATE_TASK",
    UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS",
    UPDATE_TASK_FAILED = "UPDATE_TASK_FAILED",
    DELETE_TASK = "DELETE_TASK",
    UPDATE_CHILD_TASK_SPRINT_SUCCESS = "UPDATE_CHILD_TASK_SPRINT_SUCCESS",
    UPDATE_ACTOR_CHANGE_TASK = "UPDATE_ACTOR_CHANGE_TASK",
    GET_TASKS_BY_IDS = "GET_TASKS_BY_IDS",
    UPDATE_CAMPAIGN_TASKS = "UPDATE_CAMPAIGN_TASKS",
    SET_TASK_SHOW = "SET_TASK_SHOW",
    PENDING_PREPROCESSING_TASK_UPDATE = "PENDING_PREPROCESSING_TASK_UPDATE",
    GET_TASK_BY_ID = "GET_TASK_BY_ID",
    GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS",
    UPDATE_PARENT_TASK = "UPDATE_PARENT_TASK",
    UPDATE_PARENT_TASK_SUCCESS = "UPDATE_PARENT_TASK_SUCCESS",
    UPDATE_PARENT_TASK_FAILED = "UPDATE_PARENT_TASK_FAILED",
}
export interface ITaskAction {
    type: TaskTypes;
    sprintId?: string;
    tasks?: ITask[];
    task?: any;
    addToCampaignIds?: string[];
    error?: any;
    taskId?: string;
    taskUpdate?: TaskUpdate[];
    actor?: string;
    taskIds?: string[];
    campaignIds?: [{ campaignId: string; listTask: any[] }];
    listParentId? : string[];
    parentId? : string;
    satusUpdate? : {success : boolean};
    historiesUpdate? : any;
    typeTask? : string; // dung trong chuyen task
}
export interface TaskUpdate {
    _id: string;
    status: IStatus;
    histories: ITaskHistory[];
    sprint: ISprint;
}

export const getAllTask = (sprintId: string): ITaskAction => {
    return {
        type: TaskTypes.GET_ALL_TASK_BY_SPRINT,
        sprintId,
    };
};

export const getAllTaskSuccess = (tasks: ITask[]): ITaskAction => {
    return {
        type: TaskTypes.GET_ALL_TASK_BY_SPRINT_SUCCESS,
        tasks,
    };
};

export const getAllTaskFailed = (error: any): ITaskAction => {
    return {
        type: TaskTypes.GET_ALL_TASK_BY_SPRINT_FAILED,
        error,
    };
};

export const updateTask = (task: any, addToCampaignIds?: string[]): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_TASK,
        task,
        addToCampaignIds,
    };
};

export const updateTaskSuccess = (task: ITask): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_TASK_SUCCESS,
        task,
    };
};

export const updateTaskFailed = (error: any): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_TASK_FAILED,
        error,
    };
};
export const updateParentTask = (listParentId: string[],parentId : string,historiesUpdate : any,typeTask : string): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_PARENT_TASK,
        listParentId,
        parentId,
        historiesUpdate,
        typeTask
    };
};

export const updateParentTaskSuccess = (listParentId: string[],parentId : string,historiesUpdate : any,typeTask : string): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_PARENT_TASK_SUCCESS,
        listParentId,
        parentId,
        historiesUpdate,
        typeTask
    };
};

export const updateParentTaskFailed = (error: any): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_PARENT_TASK_FAILED,
        error,
    };
};

export const deleteTaskAction = (taskId: string): ITaskAction => {
    return {
        type: TaskTypes.DELETE_TASK,
        taskId,
    };
};

export const updateChildTaskStatusSuccess = (taskUpdate: TaskUpdate[]): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_CHILD_TASK_SPRINT_SUCCESS,
        taskUpdate,
    };
};

export const updateActorChangeTask = (actor: string): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_ACTOR_CHANGE_TASK,
        actor,
    };
};
export const getTasksByIds = (taskIds: string[], campaignIds: any): ITaskAction => {
    return {
        type: TaskTypes.GET_TASKS_BY_IDS,
        taskIds,
        campaignIds,
    };
};

export const updateCampaignsTasks = (campaignIds: any): ITaskAction => {
    return {
        type: TaskTypes.UPDATE_CAMPAIGN_TASKS,
        campaignIds,
    };
};

export const setTaskShown = (task?: ITask): ITaskAction => {
    return {
        type: TaskTypes.SET_TASK_SHOW,
        task,
    };
};

export const pendingProcessingTaskUpdate = (): ITaskAction => {
    return {
        type: TaskTypes.PENDING_PREPROCESSING_TASK_UPDATE,
    };
};

export const getTaskById = (taskId: string): ITaskAction => {
    return {
        type: TaskTypes.GET_TASK_BY_ID,
        taskId,
    }
}

export const getTaskByIdSuccess = (task: ITask): ITaskAction => {
    return {
        type: TaskTypes.GET_TASK_BY_ID_SUCCESS,
        task,
    }
}
