import { Close, Add } from '@material-ui/icons'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Autocomplete } from '@mui/material'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import Url from '../../../util/url'
import { getTotalAmountUsed } from './util'
import { MANAGE_DEVICE_TYPE } from '../../../redux/reducers/manageDevice.reducer';
import TextFieldWithLabel from './Components/TextFieldWithLabel';
import DeleteDialog from './Components/DeleteDialog';

const AddEditManageDeviceModal = ({ open, onClose, usersProps, lastIndex, editItem, permission, typeOptions }) => {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [type, setType] = useState('');
    const [note, setNote] = useState('');
    const [users, setUsers] = useState([
        {
            userID: null,
            amountUsed: 0,
            note: ''
        }
    ]);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const usersFromRedux = useSelector(state => state.user);
    const dispatch = useDispatch();

    let usersOption = [];
    if(usersFromRedux){
        usersOption = usersFromRedux.map(user => user._id);
        const tempArr = users.map(user => user.userID)
        usersOption = usersOption.filter(userID => !tempArr.includes(userID))
    }

    useEffect(() => {
        if(editItem){
            setName(editItem.name);
            setQuantity(editItem.quantity);
            setType(editItem.type);
            setNote(editItem.note);

            const tempUsers = [...editItem.users].reduce((result, item) => {
                const user = usersProps.find(user => user._id === item.userID);
                if (user){
                    result.push(item)
                }
    
                return result;
            }, [])
            setUsers(tempUsers);
        }
    }, [editItem, usersProps])

    const handleDeleteField = (index) => {
        const newArrs = [...users]
        newArrs.splice(index, 1);
        setUsers(newArrs);
    }
    
    const handleAddField = () => {
        setUsers([
            ...users, 
            {
                userID: null,
                amountUsed: 0,
                note: ''
            }
        ])
    }

    const handleChange = (newValue, index) => {
        let tempArr = [...users];
        tempArr.splice(index, 1, newValue);
        setUsers(tempArr)
    }

    const handleSubmit = async () => {
        if(editItem && !permission){
            toast.error("Bạn không có quyền sửa", {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });

            return;
        }
        
        if(quantity - getTotalAmountUsed(users) < 0){
            toast.error("Số lượng còn lại không đủ", {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });

            return;
        }

        let itemSubmit = {
            name,
            quantity,
            users,
            type,
            note,
            lastUpdate: new Date().getTime(),
            index: lastIndex + 1, 
        }

        if(editItem){
            itemSubmit = {
                ...itemSubmit,
                index: editItem.index,
                _id: editItem._id
            }
        }

        try {
            if(editItem){
                await axios.post(Url('manage-device/updates-manage-devices'), [itemSubmit])
                dispatch({type: MANAGE_DEVICE_TYPE.UPDATE_MANAGE_DEVICES, data: [itemSubmit]});
                toast.success("Sửa thành công", {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
            } else {
                const { data } = await axios.post(Url('manage-device/inserts-manage-devices'), [itemSubmit])
                dispatch({type: MANAGE_DEVICE_TYPE.ADD_MANAGE_DEVICE, data: data[0]});
                toast.success("Thêm thành công", {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
            }

            onClose();
        } catch (e) {
            // console.log('error', e);
        }
    }

    const handleDelete = async () => {
        try {
            await axios.delete(Url('manage-device/deletes-manage-devices'),{data: [editItem._id]})
            dispatch({ type: MANAGE_DEVICE_TYPE.DELETE_MANAGE_DEVICE, data: editItem } )
            setOpenModalDelete(false);
            toast.success("Xóa thành công", {
                position: "top-right",
                autoClose: 1000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            onClose();
        } catch (error) {
            toast.error("Xóa thất bại", {
                position: "top-right",
                autoClose: 1000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            // console.log(error);
        }
    } 
 
    return (
        <>
            <Dialog className='add-edit-container' scroll='body' maxWidth='xl' open={open} onClose={onClose}>
                <DialogTitle className='title'>
                    <span>{editItem ? 'Sửa thiết bị' : 'Thêm thiết bị mới'}</span>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="content">
                    <Box className="container" sx={{ width: 950, minHeight: 200 }}>
                        <div className="row-input">
                            <TextFieldWithLabel
                                label='Tên thiết bị:'
                                placeholder='Nhập tên thiết bị'
                                permission={permission}
                                value={name}
                                handleChange={(e) => setName(e.target.value)} 
                            />
                        </div>
                        <div className="row-input">
                            <h5>Loại: </h5>
                            <Autocomplete
                                freeSolo
                                value={type}
                                onChange={(event, newValue) => setType(newValue)}
                                handleHomeEndKeys
                                options={typeOptions}
                                sx={{ width: '80%' }}
                                readOnly={!permission}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        onChange={e => setType(e.target.value)}
                                        placeholder="Nhập tên thiết bị"
                                        size='small'
                                        sx={{
                                            "& label.Mui-focused": {
                                                display: "none"
                                            },
                                            "& legend": {
                                                display: "none"
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="row-input">
                            <TextFieldWithLabel
                                label='Ghi chú:'
                                placeholder='Thêm ghi chú'
                                permission={permission}
                                value={note}
                                handleChange={(e) => setNote(e.target.value)} 
                                multiline
                                minRows={3}
                            />
                        </div>
                        <div className="row-input">
                            <TextFieldWithLabel
                                label='Số lượng:'
                                placeholder='Nhập số lượng thiết bị'
                                permission={permission}
                                value={quantity}
                                handleChange={(e) => setQuantity(e.target.value)}
                                type="number"
                            />
                        </div>
                        <div className="row-input">
                            <h5>Tồn kho: </h5>
                            <div className="inventory">
                                <h6>{quantity - getTotalAmountUsed(users)}</h6>
                            </div>
                        </div>
                    </Box>
                    <Box className="container">
                        <h5>Người phụ trách: </h5>
                        {users.map((item, index) => (
                            <div key={index} className='row-input response-person'>
                                <Autocomplete
                                    value={item.userID}
                                    onChange={(event, newValue) => {
                                        handleChange({ ...item, userID: newValue}, index);
                                    }}
                                    options={usersOption}
                                    getOptionLabel={(option) => {
                                        const user = usersFromRedux.find(device => device._id === option);
                                        if(user) {
                                            return user.lastName + ' ' + user.firstName
                                        }
                                        return 'No name'
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tên người phụ trách" variant="outlined" />
                                    )}
                                    sx={{ width: '30%'}}
                                    readOnly={!permission}
                                />
                                <TextField
                                    value={item.amountUsed}
                                    onChange={(e) => {
                                        handleChange({...item, amountUsed: e.target.value}, index);
                                    }}
                                    label="Số lượng quản lý"
                                    type="number"
                                    variant="outlined"
                                    sx={{ width: '14%'}}
                                    inputProps={{readOnly: !permission}}
                                />
                                <TextField
                                    value={item.note}
                                    onChange={(e) => {
                                        handleChange({ ...item, note: e.target.value}, index);
                                    }}
                                    label="ghi chú"
                                    type="text"
                                    variant="outlined"
                                    sx={{ width: '50%'}}
                                    inputProps={{readOnly: !permission}}
                                    multiline
                                />
                                <IconButton disabled={users.length === 1 || !permission} sx={{ width: '3%'}}  onClick={() => handleDeleteField(index)}>
                                    <Close color={users.length === 1|| !permission ? 'disabled' : 'error'}/>
                                </IconButton>
                            </div>
                        ))}
                        <Button disabled={!permission} variant="outlined" size="small" onClick={handleAddField} endIcon={<Add />}>
                            Thêm người phụ trách
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions className="actions">
                    <Button 
                        disabled={!permission}
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmit}
                    >
                        {editItem ? 'Sửa thiết bị' : 'Thêm thiết bị'}
                    </Button>
                    <Button variant="outlined" color="error" onClick={onClose}>Hủy</Button>
                    {
                        editItem &&
                        <Button 
                            disabled={!permission}
                            variant="contained" 
                            color="error" 
                            onClick={() => setOpenModalDelete(true)}
                        >
                            Xóa thiết bị
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            {editItem && 
                <DeleteDialog 
                    open={openModalDelete}
                    onClose={() => setOpenModalDelete(false)}
                    handleDelete={handleDelete}
                />    
            }
        </>
    )
}

export default AddEditManageDeviceModal