import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    getPlatformIds,
    OPTIONS_PLATFORM,
} from "../../../../config_data_analytic";
import Constants from "../../../../constant";
import { AppInfo, IAppInfo } from "../../../../models/appinfo.model";
import { ICertificates } from "../../../../models/certificates.model";
import {
    getAdsRevenueWebAPI,
    getProRevenueAppAPI,
    getTotalAdsRevenueAppAPI,
    getTotalProWebRevenueAPI,
} from "../../../../services/traffic.service";
import { formatDate } from "../../../../util";
import MenuTab from "../../analytics/menu";
import CompareAdsRevenue from "./ads-revenue";
import CompareProRevenue from "./pro-revenue";
import CompareTotalRevenue from "./total-revenue";

const MENU_LIST_REVENUE = [
    {
        title: "Total Revenue",
        tab: "total_revenue",
    },
    {
        title: "Ads Revenue",
        tab: "ads_revenue",
    },
    {
        title: "Pro Revenue",
        tab: "pro_revenue",
    },
];
const CompareRevenue = () => {
    const [tab, setTab] = useState(MENU_LIST_REVENUE[0].tab);
    const [isLoading, setIsLoading] = useState(false);
    const certificatesSelect: ICertificates[] = useSelector(
        (state: any) => state.dashBoardState.certificatesSelect
    );
    const startTime = useSelector(
        (state: any) => state.dashBoardState.startTime
    );
    const endTime = useSelector((state: any) => state.dashBoardState.endTime);
    const [optionValue, setOptionValue] = useState(OPTIONS_PLATFORM[0]);
    const [dataAds, setDataAds] = useState<any>(null);
    const [dataPro, setDataPro] = useState<any>(null);
    const [dataTotal, setDataTotal] = useState<any>(null);
    let platids: any = getPlatformIds(optionValue);
    useEffect(() => {
        let slugs: any = [];
        let platformWeb: any = [];
        let dupAppIds: any = [];
        const formatStartDate = formatDate(new Date(startTime), "YYYY-MM-DD");
        const formatEndDate = formatDate(new Date(endTime), "YYYY-MM-DD");
        certificatesSelect.forEach((certificate: ICertificates) => {
            slugs.push(String(certificate.slug) ?? "");
            const dataWeb = certificate.appInfos?.find((app) => {
                if (app.platformId == Constants.PLATFORM_WEB) {
                    return app.gaID;
                }
            });
            let appIds: any = certificate.appInfos
                ?.filter((app) => platids.flat().includes(app.platformId))
                ?.map((app) => {
                    return app.appID;
                });

            let uniqueChars = appIds?.filter((c: any, index: any) => {
                return appIds.indexOf(c) === index;
            });
            dupAppIds.push(uniqueChars);

            platformWeb.push({
                gaId: dataWeb?.gaID,
                slug: certificate.slug,
            });
        });
        const getData = async () => {
            dupAppIds = dupAppIds.flat();
            setIsLoading(true);
            const resultAdsApp = await getTotalAdsRevenueAppAPI(
                formatStartDate,
                formatEndDate
            );
            const resultAdsWeb = await getAdsRevenueWebAPI(
                slugs,
                formatStartDate,
                formatEndDate
            );
            let resultFormatAdsWeb: any = {};
            resultAdsWeb.forEach((ele: any) => {
                const find = platformWeb.find(
                    (item: any) => item.slug == ele.slug
                );
                const gaId = find.gaId;
                let sum = resultFormatAdsWeb[gaId] ?? 0;
                sum += Number(ele.value);
                resultFormatAdsWeb[gaId] = sum;
            });

            const resultAds = Object.assign(resultAdsApp, resultFormatAdsWeb);
            let valueTotal = { ...resultAds };

            let resultProWeb = await getTotalProWebRevenueAPI(
                slugs,
                formatStartDate,
                formatEndDate
            );
            let resultProApp = await getProRevenueAppAPI(
                dupAppIds,
                new Date(startTime).getTime(),
                new Date(endTime).getTime()
            );

            let valuePro: any = {};

            resultProWeb.forEach((obj: any, index: number) => {
                let appSelected: IAppInfo = new AppInfo();
                certificatesSelect.forEach((certificate: ICertificates) => {
                    const appInfos = certificate.appInfos;
                    const app = appInfos?.find(
                        (app: IAppInfo) => app.platformId == 1
                    );
                    if (certificate.slug == obj.slug && app) {
                        appSelected = app;
                    }
                });
                const gaId = appSelected?.gaID;
                valuePro[String(gaId)] = Number(obj.amount) ?? 0;
                let sumTotal = Number(valueTotal[gaId] ?? 0);
                sumTotal += valuePro[String(gaId)];
                valueTotal[gaId] = sumTotal;
            });
            Object.keys(resultProApp).forEach((key) => {
                const dataApp = resultProApp[key];
                dataApp.forEach((ele: any) => {
                    let gaId = 0;
                    certificatesSelect.forEach((certificate) => {
                        certificate.appInfos?.forEach((app) => {
                            const platformId = app.platformId;
                            const osType =
                                platformId == 2 || platformId == 4
                                    ? "android"
                                    : platformId == 3 || platformId == 5
                                    ? "ios"
                                    : "web";
                            if (
                                ele.osType == osType &&
                                app.appID == ele.appId
                            ) {
                                gaId = app.gaID;
                            }
                        });
                    });
                    const price = Number(ele.price);
                    let sum = valuePro[gaId] ?? 0;
                    sum += price;
                    valuePro[gaId] = sum;
                    let sumTotal = Number(valueTotal[gaId] ?? 0);
                    sumTotal += valuePro[gaId];
                    valueTotal[gaId] = sumTotal;
                });
            });

            setDataAds(resultAds);
            setDataPro(valuePro);
            setDataTotal(valueTotal);
            setIsLoading(false);
        };

        getData();
    }, [certificatesSelect, startTime, endTime]);

    return (
        <div>
            <div className="back-top">
                <p>Revenue </p>
            </div>

            <div className="question-quality-v2 web-app-index">
                <MenuTab
                    tab={tab}
                    setTab={setTab}
                    menuList={MENU_LIST_REVENUE}
                />
                {tab === "total_revenue" ? (
                    <>
                        <CompareTotalRevenue />
                    </>
                ) : tab === "ads_revenue" ? (
                    <>
                        <CompareAdsRevenue />
                    </>
                ) : tab === "pro_revenue" ? (
                    <>
                        <CompareProRevenue />
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default CompareRevenue;
