import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateTask } from "../../../../../redux/actions/task.action";
import Config from "../../../../../shared/models/config";
import { IStatus } from "../../../../../shared/models/status";
import { ITask, Task } from "../../../../../shared/models/task";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import Url from "../../../../../util/url";
import SelectUserWidget from "../../edit-task-modal/select-user";
import "./style.scss";
import { IProject } from "../../../../../shared/models/project";
import { ISprint } from "../../../../../shared/models/sprint";

const AddSubTask: FC<{
    parentTask: ITask;
    onHide: () => void;
}> = ({ parentTask, onHide }) => {
    const dispatch = useDispatch();
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const statuses: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    const [shortId, setShortId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [assigneeID, setAssigneeID] = useState<string>(parentTask.assigneeId);
    const [leadDevID, setLeadDevID] = useState<string>(parentTask.leadDevId);
    const [testerIds, setTesterIds] = useState(parentTask?.testerIds ? [...parentTask?.testerIds] : []);

    const handleShortId = async () => {
        const res = await axios.get(Url("task/quantity"));
        if (res.data.success) {
            const value = res.data?.response;
            setShortId("ABC-" + String(value + 1));
        }
    };

    useEffect(() => {
        handleShortId();
    }, [parentTask._id]);

    const getListUser = (ids: string[]) => {
        const users: IUserInfo[] = [];
        for (let id of ids) {
            const user = mapUser.get(id);
            user && users.push(user);
        }
        return users;
    };

    const createSubtask = async (e: any) => {
        e.preventDefault();
        if (title && assigneeID) {
            const task: ITask = new Task({
                shortId: shortId,
                type: "Sub Task",
                parentId: parentTask._id,
                parent: parentTask,
                title: title,
                description: "",
                assigneeId: assigneeID,
                assignee: users.find((u) => u._id === assigneeID),
                deadline: parentTask.deadline,
                priority: parentTask.priority,
                point: undefined,
                statusView: 1,
                histories: [],
                reporterId: parentTask.reporterId,
                reporter: parentTask.reporter ?? users.find((u) => u._id === parentTask.reporterId),
                sprintId: parentTask.sprintId,
                sprint: parentTask.sprint ?? sprints.find((u) => u._id === parentTask.sprintId),
                statusId: Config.COLUMN_STATUS.TO_DO.mongoId,
                status: statuses.find((status) => status._id == Config.COLUMN_STATUS.TO_DO.mongoId),
                projectId: parentTask.projectId,
                project: parentTask.project ?? projects.find((u) => u._id === parentTask.projectId),
                leadDevId: leadDevID,
                leadDev: users.find((u) => u._id === leadDevID),
                testerIds: testerIds,
                testers: getListUser(testerIds),
            });
            dispatch(updateTask(task));
            onHide();
        } else {
            alert("Title, assignee can not be null!!!");
        }
    };

    return (
        <div className="create-subtask">
            <div className="line-1">
                <FormControl
                    type="text"
                    name="title"
                    placeholder="Title"
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />

                <SelectUserWidget
                    ids={[assigneeID]}
                    onSelected={(value) => {
                        setAssigneeID(value[0]);
                    }}
                />
            </div>

            <div className="line-2">
                <Button
                    className="create-btn"
                    onClick={createSubtask}
                    style={{ background: "#4bb4ed", borderColor: "#4bb4ed" }}
                >
                    Tạo
                </Button>
                <Button
                    className="cancel-btn"
                    style={{
                        background: "rgb(108, 117, 125)",
                        borderColor: "rgb(108, 117, 125)",
                    }}
                    onClick={() => onHide()}
                >
                    Huỷ
                </Button>
            </div>
        </div>
    );
};

export default AddSubTask;
