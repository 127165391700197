import {IEnterCurriculumVitae} from "../../shared/models/enterCurriculumVitae";

export enum EnterCurriculumVitaeType{
    CREATE_ENTERCURRICULUMVITAE = "CREATE_ENTERCURRICULUMVITAE",
}

export interface EnterCurriculumVitaeAction{
    type : EnterCurriculumVitaeType,
    EnterCurriculumVitaeInfo : IEnterCurriculumVitae,
    error? : any,
}

export const createcEnterCurriculumVitae = (EnterCurriculumVitaeInfo: IEnterCurriculumVitae) : EnterCurriculumVitaeAction => {
    return {
        type: EnterCurriculumVitaeType.CREATE_ENTERCURRICULUMVITAE,
        EnterCurriculumVitaeInfo
    }
}