import { FC, useState } from "react";
import { IWfhDayOffDay } from "../../../shared/models/wfhDayoffDayRegister";
import icon6 from "../../../assets/icon/icon6.png";
import { WorkingTimeTable } from "../../working-time/working-time-detail";
import RatingItem from "./RatingItem";
import { getColoWithPercent } from "../../../utils";
export interface DetailDiscipline {
    offDaysWfhDay: any;
    score: number | null;
    currentSprint: any;
    discipline: any;
}
const DisciplineWidget: FC<{
    detailDiscipline: DetailDiscipline;
}> = ({ detailDiscipline }) => {
    const [open, setOpen] = useState(false);
    let offDaysWfhDay = detailDiscipline?.offDaysWfhDay;
    let workingTime = detailDiscipline?.discipline?.workingTime;
    let sprintStart = detailDiscipline?.currentSprint.startDate.getTime();
    let sprintEnd = detailDiscipline?.currentSprint.endDate.getTime();
    let discipline = detailDiscipline.discipline;
    let dataOff: IWfhDayOffDay[] | undefined = [];
    if (sprintEnd !== undefined && sprintStart !== undefined) {
        dataOff = offDaysWfhDay?.offDays.filter(
            (d: any) => new Date(d.date).getTime() >= sprintStart && new Date(d.date).getTime() <= sprintEnd
        );
    }
    let noCheckin = 0;
    let noCheckout = 0;
    let checkinLate = 0;
    let checkoutEarly = 0;
    workingTime.forEach((x: any) => {
        let reason = x.reason.toLowerCase();
        if (reason.indexOf("muộn") > -1) checkinLate++;
        if (reason.indexOf("checkout sớm") > -1) checkoutEarly++;
        if (reason.indexOf("không checkin") > -1) noCheckin++;
        if (reason.indexOf("không checkout") > -1) noCheckout++;
        return {
            ...x,
            reason: reason,
        };
    });
    return (
        <>
            <RatingItem
                title="Kỷ luật"
                color={getColoWithPercent((detailDiscipline?.score ?? 0) / 100)}
                value={detailDiscipline?.score ?? -1}
                total={100}
                open={open}
                icon={icon6}
                onCollapse={() => setOpen(!open)}
            />
            {open && (
                <div className="more-details">
                    <div>
                        <div>
                            <strong>Số ngày nghỉ:{` ${discipline.offDay}`}</strong>
                        </div>
                        <div>
                            <strong>
                                Số lần nghỉ vi phạm:
                                {` ${discipline?.offDaysUnauthorized?.length ?? 0}`}
                            </strong>
                        </div>
                        {dataOff?.length ? (
                            <WorkingTimeTable data={dataOff} inReport={true} />
                        ) : (
                            <div>- Không có thông tin</div>
                        )}
                    </div>
                    <div>
                        <div>
                            <strong>Đi muộn:{` ${checkinLate}`}</strong>
                        </div>
                        <div>
                            <strong>Không checkin:{` ${noCheckin}`}</strong>
                        </div>
                        <div>
                            <strong>Không checkout:{` ${noCheckout}`}</strong>
                        </div>
                        <div>
                            <strong>Checkout sớm: {` ${checkoutEarly}`}</strong>
                        </div>
                        {workingTime.length ? (
                            <WorkingTimeTable data={workingTime} inReport={true} />
                        ) : (
                            <div>- Không có thông tin</div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default DisciplineWidget;
