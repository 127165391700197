import ReactTooltip from "react-tooltip";
import Icon2 from "../../../assets/icon/icon2.png";
import Icon3 from "../../../assets/icon/icon3.png";
import Config from "../../../shared/models/config";
import { ISprint } from "../../../shared/models/sprint";
import { ICoefficients, IUserInfo } from "../../../shared/models/userInfo";
import { formatDate } from "../../../util";
import "./style.scss";
import Button from "@mui/material/Button";
import ShowGoals from "./ShowGoalModal";
import { IAuthState } from "../../../redux/reducers/auth.reducer";
import { ITaskScores } from "../../../shared/models/taskScore";
import { calculateExp } from "../../../utils";
import ExperienceProgress from "./ExperienceProgress";
import { useState } from "react";
import { useSelector } from "react-redux";
import NewProgressBar from "../expectation-and-notification/NewProgressBar";
import MyAvatar from "../../avatar";

const UserIndicator = ({
    user,
    sprint,
    isUserAuthStaff = false,
    disciplinePoints,
    taskScores,
    coefs,
}: {
    user: IUserInfo;
    sprint: ISprint;
    isUserAuthStaff?: boolean;
    disciplinePoints: any;
    taskScores: ITaskScores;
    coefs: ICoefficients;
}) => {
    const [showModalGoal, setShowModalGoal] = useState(false);
    const onHide = () => {
        setShowModalGoal(false);
    };
    const userInfoState: IAuthState = useSelector((state: any) => state.authState);
    const userInfo: any = userInfoState?.user ?? [];
    let doneRate = taskScores?.doneRate.value ?? 0;
    const exp = calculateExp(disciplinePoints, user, taskScores, coefs);
    return (
        <>
            <div className="container-completion-rate">
                <div className="container-content">
                    {!isUserAuthStaff && (
                        <div
                            className="avatar flex align-item-center"
                            style={{
                                flexDirection: "row-reverse",
                            }}
                        >
                            <div className="avatar-image align-item-center justify-content-center">
                                <MyAvatar url={user.avatar} />
                            </div>

                            <div
                                className="column"
                                style={{
                                    justifyContent: "space-between",
                                    marginRight: "10px",
                                    textAlign: "end",
                                }}
                            >
                                <div className="white first-name">{user.firstName}</div>
                                <div className="position">{user.position}</div>
                                <div className="level">{"Level: " + user.level}</div>
                            </div>
                        </div>
                    )}
                    {(userInfo.role === Config.USER_ROLE_ADMIN || userInfo.role === Config.USER_ROLE_LEADER) && (
                        <div>
                            <Button className="show-goal" variant="contained" onClick={() => setShowModalGoal(true)}>
                                Xem mục tiêu
                            </Button>
                            {showModalGoal && <ShowGoals onHide={onHide} user={user} />}
                        </div>
                    )}

                    <ExperienceProgress exp={exp} isUserAuthStaff={isUserAuthStaff} sprint={sprint} user={user} />
                    {/* {isUserAuthStaff && <RadarChartView />} */}
                    <div className="container-group">
                        <div className="group-content" data-tip data-for={"done-rate-" + user._id}>
                            <img className="all-icon" src={Icon2} />
                            <div className="container-ratio">
                                <span className="title-content">Tỷ lệ hoàn thành</span>
                                <div className="group-text">
                                    <div className="group-ratio">
                                        <span
                                            className="point-done flex"
                                            style={{
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10px",
                                                    color: "rgba(255, 255, 255, 0.32)",
                                                    lineHeight: "22px",
                                                    textAlign: "start",
                                                }}
                                            >
                                                {`Hoàn thành task: ${taskScores?.taskDonePercentage.value ?? 0}%`}
                                            </span>
                                            <span>{doneRate}%</span>
                                        </span>
                                        <NewProgressBar current={doneRate > 100 ? 100 : doneRate} />
                                    </div>
                                </div>
                            </div>
                            <ReactTooltip id={"done-rate-" + user._id} type="info">
                                <div>
                                    <div>
                                        {taskScores?.totalTasks.label}: {taskScores?.totalTasks.value ?? 0}
                                    </div>{" "}
                                    <div>
                                        {taskScores?.totalTaskInSprint.label}: {taskScores?.totalTaskInSprint.value ?? 0}
                                    </div>
                                    <div>
                                        {taskScores?.tasksDone.label}: {taskScores?.tasksDone.value ?? 0}
                                    </div>
                                    <div>
                                        {taskScores?.taskDoneInDeadline.label}: {taskScores?.taskDoneInDeadline.value ?? 0}
                                    </div>
                                    <div>
                                        {taskScores?.taskDonePercentage.label}{" "}
                                        <strong>A: {taskScores?.taskDonePercentage.value ?? 0}%</strong>
                                    </div>
                                    <div>
                                        {taskScores?.taskDoneInDeadlinePercentage.label}{" "}
                                        <strong>B: {taskScores?.taskDoneInDeadlinePercentage.value ?? 0}%</strong>
                                    </div>
                                    <div>
                                        {taskScores?.pointDone.label}: {taskScores?.pointDone.value ?? 0} {" point"}
                                    </div>
                                    <div>
                                        {taskScores?.pointDonePercentage.label}{" "}
                                        {`(${taskScores?.pointDone.value ?? 0}/${Config.DEFAULT_POINT})`}{" "}
                                        <strong>C : {taskScores?.pointDonePercentage.value ?? 0}%</strong>
                                    </div>
                                    <strong>
                                        {taskScores?.doneRate.label} (B + C)/2: {doneRate}%
                                    </strong>
                                </div>
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className="container-group">
                        <div className="group-content" data-tip data-for={"disciplineTip" + user._id}>
                            <img className="all-icon" src={Icon3} />
                            <div className="container-ratio">
                                <span className="title-content">Kỷ luật</span>
                                <div className="group-text">
                                    <span className="text-point">{100 - (disciplinePoints?.minusScore ?? 0)}</span>
                                    <div className="group-ratio discipline">
                                        <span className={"point-discipline " + (!disciplinePoints ? "point-done" : "")}>
                                            {disciplinePoints ? "-" + (disciplinePoints?.minusScore ?? 0) : 0}
                                        </span>
                                        <NewProgressBar
                                            current={
                                                100 - (disciplinePoints?.minusScore ?? 0) < 0
                                                    ? 0
                                                    : 100 - (disciplinePoints?.minusScore ?? 0)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <ReactTooltip id={"disciplineTip" + user._id} type="info">
                                <div style={{ display: "block" }}>
                                    <div>
                                        <strong>Số ngày lỗi chấm công: {disciplinePoints?.workingTime?.length ?? 0}</strong>
                                        {disciplinePoints?.workingTime?.map((d: any, index: number) => (
                                            <div key={index}>
                                                &#x2022;
                                                {`${formatDate(new Date(d.date), "dd/mm/yyyy")}: ${d.reason}`}
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <strong>
                                            Số lần nghỉ vi phạm: {disciplinePoints?.offDaysUnauthorized?.length ?? 0}
                                        </strong>
                                        {disciplinePoints?.offDaysUnauthorized?.length > 0 &&
                                            disciplinePoints?.offDaysUnauthorized?.map((d: any, index: number) => (
                                                <div key={index}>
                                                    &#x2022;
                                                    {`${formatDate(new Date(d.date), "dd/mm/yyyy")}: ${d.reason}`}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserIndicator;
