import React, { PureComponent } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from "recharts";

const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export const COLORS_LINE_CHART = [
    "#3366CC",
    "#FF9900",
    "#990099",

    "#dadafc",
    "#e3d6f5",
];

const CustomLineChart = ({
    data,
    dataKeyX = "date",
    lineKeys = ["value"],
}: {
    data: any;
    dataKeyX: string;
    lineKeys?: string[];
}) => {
    // function nFormatter(num: number) {
    //     if (num >= 1000000000) {
    //         return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    //     }
    //     if (num >= 1000000) {
    //         return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    //     }
    //     if (num >= 1000) {
    //         return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    //     }
    //     return num;
    // }
    // const dataFormat = data.map((item: any) => {
    //     return {
    //         ...item,
    //         value: nFormatter(item.value),
    //     };
    // });

    return (
        <div className="chart" style={{ height: "270px" }}>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                        // top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 1" vertical={false} />
                    <XAxis dataKey={dataKeyX} />
                    <YAxis />
                    <Tooltip />
                    {/* {title && (
                    <Legend
                        content={<div>{title}</div>}
                        layout="horizontal"
                        verticalAlign="top"
                        align="left"
                        style={{ marginBottom: "20px", marginLeft: "60px" }}
                    />
                )} */}
                    {lineKeys.map((lineKey, index) => {
                        return (
                            <Line
                                key={index}
                                type="monotone"
                                dataKey={lineKey}
                                stroke={COLORS_LINE_CHART[index]}
                                activeDot={{ r: 4 }}
                            >
                                {/* <LabelList content={<CustomizedLabel />} /> */}
                            </Line>
                        );
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill={stroke}
            fontSize={10}
            textAnchor="middle"
        >
            {value}
        </text>
    );
};

const CustomizedAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
};

// export default class Example extends PureComponent {
//   static demoUrl = 'https://codesandbox.io/s/line-chart-with-customized-label-hs5b7';

//   render() {
//     return (

//     );
//   }
// }
export default CustomLineChart;
