import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeMesage } from "../../redux/actions/toolAnalysis.action";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CustomizedSnackbar = () => {
    const message = useSelector(
        (state: any) => state.toolAnalysisState.message
    );
    const open = message.length > 0 ? true : false;
    const severity: AlertColor = useSelector(
        (state: any) => state.toolAnalysisState.severity
    );
    const dispatch = useDispatch();
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(removeMesage());
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            style={{ justifyContent: "center", alignItems: "center" }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{
                    width: "100%",
                    fontWeight: "bold",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    {message}
                </div>
            </Alert>
        </Snackbar>
    );
};
export default CustomizedSnackbar;
