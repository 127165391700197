import { IBasic } from "./basic";
import { ITask, Task } from "./task";
import { IUserInfo, UserInfo } from "./userInfo";

export interface ITestSection extends IBasic {
    name: string;
    description: string;
    userID: string;
    user?: IUserInfo;
    taskID: string;
    task?: ITask;
    lastUpdate: number;
}

export class TestSection implements ITestSection {
    _id: string;
    name: string;
    description: string;
    userID: string;
    user?: IUserInfo;
    taskID: string;
    task?: ITask;
    lastUpdate: number;
    note: string;
    constructor(object: any = {}) {
        this._id = object._id ?? object.id;
        this.name = object.name;
        this.description = object.description ?? "";
        let userID: string = "";
        if (typeof object.user === "string") {
            userID = object.user;
        } else if (typeof object.user === "object") {
            this.user = new UserInfo(object.user);
            userID = this.user._id;
        } else if (typeof object.userID === "string") {
            userID = object.userID;
        }
        this.userID = userID;
        let taskID: string = "";
        if (typeof object.task === "string") {
            taskID = object.task;
        } else if (typeof object.task === "object") {
            this.task = new Task(object.task);
            taskID = this.task._id;
        } else if (typeof object.taskID === "string") {
            taskID = object.taskID;
        }
        this.taskID = taskID;
        this.lastUpdate = object.lastUpdate;
        this.note = object.note ?? "";
    }
}
