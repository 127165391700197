import { FC } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../shared/models/userInfo";

export const SelectUserWidget: FC<{
    onlyActiveUsers: boolean;
    value: string;
    onChange: (value: string) => void;
}> = ({ value, onChange, onlyActiveUsers }) => {
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const sprintActiveUser = useSelector((state: any) => state.reportState.sprintActiveUsers);
    return (
        <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
            <option value="all">All users</option>
            {users.map((user) => {
                if (onlyActiveUsers ? !!sprintActiveUser[user._id] : true)
                    return (
                        <option value={user._id} key={user._id}>
                            {user.firstName} {user.lastName}
                        </option>
                    );
            })}
        </Form.Select>
    );
};

export const SelectTeamsWidget: FC<{
    value: string;
    onChange: (value: string) => void;
}> = ({ value, onChange }) => {
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const teams: string[] = [];
    for (let user of users) {
        if (user.teams && !teams.includes(user.teams)) {
            teams.push(user.teams);
        }
    }
    return (
        <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
            <option value="all">All Teams</option>
            {teams.map((team) => (
                <option value={team} key={team}>
                    {team.toUpperCase()}
                </option>
            ))}
        </Form.Select>
    );
};
